import React, { useEffect, useState } from 'react'
import {
  StudyBlocksContainer,
  StudyBlocksMessage,
  StudyBlocksSlideContainer
} from './styles'
import { SectionHeader, SectionLink, SectionTitle } from '../style'
import { getDaysAndBlocks, getStudyBlocksMessage } from '../../StudyBlocksDisplay/utils'
import api from '../../../api'
import { getSessionsArray } from '../../../utilities/studyBlocks'
import StudyBlocksSlide from './StudyBlocksSlide'
import { getStudyBlocksForNextFiveDays } from './utils'
import NoStudyBlocks from './NoStudyBlocks'

function StudyBlocks () {
  const [savedStudyBlocks, setSavedStudyBlocks] = useState([])
  const daysAndBlocks = getDaysAndBlocks(savedStudyBlocks)

  const blocksForNextFiveDays = getStudyBlocksForNextFiveDays(daysAndBlocks)
  const studyBlocksMessage = getStudyBlocksMessage(
    savedStudyBlocks,
    daysAndBlocks
  )

  useEffect(() => {
    const getSavedStudyBlocks = async () => {
      const { studyBlocks } = await api.getStudyBlocks()
      setSavedStudyBlocks(getSessionsArray(studyBlocks))
    }

    getSavedStudyBlocks()
  }, [])

  const hasStudyBlocks = daysAndBlocks.length > 0

  return (
    <StudyBlocksContainer>
      <SectionHeader>
        <SectionTitle>Study Blocks</SectionTitle>
        {hasStudyBlocks && (
          <SectionLink href='#/account/study-blocks'>Edit Schedule</SectionLink>
        )}
      </SectionHeader>
      <StudyBlocksSlideContainer>
        {hasStudyBlocks ? (
          <>
            <StudyBlocksSlide blocksForNextFiveDays={blocksForNextFiveDays} />
            <StudyBlocksMessage>{studyBlocksMessage}</StudyBlocksMessage>
          </>
        ) : (
          <NoStudyBlocks />
        )}
      </StudyBlocksSlideContainer>
    </StudyBlocksContainer>
  )
}

StudyBlocks.displayName = 'StudyBlocks'
export default StudyBlocks
