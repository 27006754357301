import styled, { createGlobalStyle } from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import InputIcon from '../../../assets/icons/date-input-icon.svg'
import { PrimaryButton, SecondaryButton } from '../../Buttons/style'
import BackBreadcrumb from '../../BackBreadcrumb/BackBreadcrumb'
import { LinkStyles } from '../../Links/style'

export const OverrideToolTipProperties = createGlobalStyle`
  .tooltip.show {
    opacity: 1;
  }
`

export const PageWrapper = styled.div`
  position: relative;
  .flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${mediaqueries.mobile`
      display: block;
    `}
  }
  .title-wrap{
    padding-top: 64px;
    padding-bottom: 36px;
    h1{
      font-weight: 300;
      font-size: 36px;
      line-height: 43px;
      margin: 0px;
      ${mediaqueries.tablet`
        margin-bottom: 12px;
  `}
    }
  }
`

export const PageContent = styled.div`
  margin-top: 36px;
`

export const Line = styled.div`
  width: 100%;
  background-color: #5c5c5d;
  height: 1px;
`

export const CostText = styled.p`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 36px;
  margin-bottom: 0;
`

export const ButtonsWrap = styled.div`
  &.margin-top{
    margin-top: 32px;
  }
  button {
    width: 140px;
    margin-right: 12px;
    ${mediaqueries.smallMobile`
      margin-bottom: 10px;
    `}
  }
  .update{
    margin-top: 12px;
    width: 108px;
    ${mediaqueries.smallMobile`
      margin-top: 24px;
      margin-bottom: 0px;
    `}
  }
`

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
  opacity: ${({ active }) => active ? 0.3 : 1};
  ${mediaqueries.smallMobile`
    display: block;
  `}
`

export const BoxWrap = styled.div`
  &.flex-container{
    display: flex;
    align-items: flex-start;
    ${mediaqueries.mobile`
      display: block;
    `}
  }
`

export const CardText = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  margin: 0px;
  &.margin-bottom{
    margin-bottom: 6px;
    margin-top: 24px;
  }
  img {
    margin-left: 12px;
    margin-top: -3px;
  }
`

export const EnrolText = styled.p`
  font-weight: normal;
  font-size: 16px;
  color: #fff;
  margin-bottom: 6px;
  &.no-margin{
    margin:0px;
  }
`

export const EnrollSpan = styled.span``

export const Button = styled.button`
  background-color: unset;
  border: none;
  outline: none !important;
  padding: 0px;
  margin-left: 16px;
  margin-top: 2px;
  ${mediaqueries.mobile`
    margin: unset;
    margin-right: 12px;
  `}
  ${LinkStyles}
`

export const ThisSelectionText = styled.p`
  font-weight: normal;
  font-style: italic;
  font-size: 16px;
  line-height: 19px;
  color: #b1bfc5;
  margin: 0 0;
  margin-top: 36px;
`

export const AlertModalParagraph = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 24px;
  margin-bottom: 0;
  color: #ffffff;
`

export const AlertModalFooterWrapper = styled.div`
  display: flex;
  padding: 0px 24px;
  padding-bottom: 24px;
  button {
    width: 113px;
    margin-right: 16px;
    height: 49px;
  }
`

export const ExceedText = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffa463;
`

export const TypeBox = styled.div`
  margin-right: 40px;
  margin-top: 0px;
  margin-bottom: ${({ isToken }) => isToken ? '16px' : '0px'};
  ${mediaqueries.mobile`
      margin-top: 12px;
  `}
`

export const TypeTextBox = styled.label`
  display: flex;
  align-items: center;
  margin:0px;
  input[type="text"] {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    margin: 10px 0;
    :focus {
      border: none;
      outline: none;
    }
  }
`

export const TypeText = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #98a4ae;
  margin-bottom: 12px;
`

export const Icon = styled.img`
  margin-left: 12px;
  width: 16px;
  height: 16px;
  margin-top: -11px;
`

export const CheckBox = styled.label`
  margin-top: ${({ marginTop }) => marginTop};
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 31px;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }
  span {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #161618;
    border: ${(props) =>
      props.disabled ? '1px solid #5E636B' : '1px solid #5FC4B8'};
    box-sizing: border-box;
    border-radius: 50%;
  }

  input:disabled ~ span {
    border-color: gray;
  }

  input:checked ~ span {
    background-color: #5fc4b8;
    border: 3px solid #5fc4b8;
    box-sizing: border-box;
    border-radius: 50%;
  }
  span::after {
    display: none;
    position: absolute;
  }
  input:checked ~ span::after {
    left: 2px;
    display: block;
  }
`

export const DateWrap = styled.div`
  margin-top: -9px;
  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #5fc4b8;
    color: #fff;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 6px 0px;
    outline: none !important;
    ::-webkit-calendar-picker-indicator {
      background-image: url(${InputIcon});
    }
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    margin-bottom: 0px;
    margin-top: 6px;
  }
`

export const EnrollText = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  line-height: 19px;
`

export const FlexContainer = styled.div`
  display: ${({ isToken }) => isToken ? 'block' : 'flex'};
  ${mediaqueries.mobile`
      display: block;
      margin-top: 0px;
    `}
`

export const ConfirmationWrap = styled.div`
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    canvas{
      position: absolute;
      height: 100%;
      left: 0px;
      top: 0px;
      z-index: -1;
      width: 100%;
    }
    button{
      width:170px;
      height:40px;
    }
`

export const ConfirmationText = styled.h4`
  font-weight: 300;
  font-size: 36px;
  line-height: 43px;
  color: #FFFFFF;
  margin-bottom:32px;
`

export const ConfirmButton = styled(PrimaryButton)`
  margin-bottom: 0px;
  background: #5fc4b8;
  width: 108px;
  height: 40px;
  &.update{
    height: 49px;
  }
  &.complete-signup {
    width: 191px;;
  }
`

export const TokenButton = styled(SecondaryButton)`
  border: 1px solid #5fc4b8;
  box-sizing: border-box;
  border-radius: 5px;
  color: #5fc4b8;
`

export const BottomWrap = styled.div`
  opacity: ${({ isEdit }) => isEdit ? 0.3 : 1};
`

export const ScheduleReviewContainer = styled.div`
  position: relative;
`

export const BreadCrumbComponent = styled(BackBreadcrumb)`
  position: absolute;
  left: -249px;
  top: -64px;
  padding: 0px;
  color: #5FC4B8;
  ${mediaqueries.tablet`
    position: unset;
    margin-top: 88px;
    margin-bottom: -30px;
  `}
  div{
    margin-left: 13px;
  }
`

export const Dot = styled.span`
  background: #98A4AE;
  width: 2px;
  height: 2px;
  display: inline-block;
  margin: 0px 6px;
  margin-bottom: 3px;
`

export const GetStartedButton = styled(PrimaryButton)`
  background: #5fc4b8;
`

export const AddtokenText = styled.p`
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin: 6px 0 0 0;
`
