import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  StyledListItem,
  StyledLinkText,
  HiddenInput,
  StyledFileName,
  StyledColumn,
  InputLabel,
  InputWrapper,
  StyledButtonWrapper
} from '../styled'
import { InputField } from '../../style'
import { FILE_FIELD_NAME, VOUCHER_STATUS } from '../../../../Constants/voucher'
import { PrimaryButton } from '../../../VoucherUploadModal/styled'
import api from '../../../../api'
import config from '../../../../config'
import { getAmazonVoucherUploadedEvent } from '../../../../utilities/voucher'

const { hasVoucherNumber } = config

const Certificate = props => {
  const inputRef = useRef()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [voucherNumber, setVoucherNumber] = useState(null)
  const { certificate, isFirstItem, voucherName, updateStatus } = props
  const { voucherStatus, voucherId, pdfVoucherNumber } = certificate

  const handleClick = () => {
    inputRef.current.click()
  }

  const handleFileUpload = event => {
    if (!event.target.files?.length) return

    const [selectedFile] = event.target.files
    setSelectedFile(selectedFile)
  }

  const handleVoucherNumberInput = event => {
    setVoucherNumber(event.target.value)
  }

  const handleSubmit = async () => {
    const haltCall = hasVoucherNumber
      ? !selectedFile || !voucherId || !voucherNumber
      : !selectedFile || !voucherId
    if (haltCall) return

    const formData = new FormData()
    formData.append(FILE_FIELD_NAME, selectedFile)

    setIsSubmitting(true)
    const result = await api.uploadTuitionVoucher(voucherId, formData, voucherNumber)
    setIsSubmitting(false)

    if (!result?.success) return
    updateStatus(voucherId, voucherNumber)
    api.submitTrackedEvent(getAmazonVoucherUploadedEvent())
  }

  const isOutstandingStatus = voucherStatus === VOUCHER_STATUS.OUTSTANDING_VOUCHER

  const isSubmitDisabled = hasVoucherNumber
    ? !selectedFile || isSubmitting || !voucherNumber
    : !selectedFile || isSubmitting

  return (
    <StyledListItem key={voucherName} borderTop={!isFirstItem}>
      <StyledColumn>{voucherName}</StyledColumn>
      {
        isOutstandingStatus
          ? (
            <>
              <StyledColumn width={240}>
                <StyledLinkText onClick={handleClick}>
                  Upload voucher here
                </StyledLinkText>
                {
                  selectedFile && (
                    <StyledFileName>
                      {selectedFile.name}
                    </StyledFileName>
                  )
                }
                <HiddenInput
                  ref={inputRef}
                  type='file'
                  accept='application/pdf'
                  onChange={handleFileUpload}
                />
              </StyledColumn>

              {hasVoucherNumber && (
                <InputWrapper>
                  <InputLabel>Enter voucher number here</InputLabel>
                  <InputField
                    autoFocus
                    disabled={isSubmitting}
                    value={voucherNumber}
                    onChange={handleVoucherNumberInput}
                    style={{ width: 260, height: 30, marginBottom: 0 }}
                  />
                </InputWrapper>
              )}

              <StyledButtonWrapper>
                <PrimaryButton
                  onClick={handleSubmit}
                  disabled={isSubmitDisabled}
                  width='120px'
                >
                  {
                    isSubmitting
                      ? <FontAwesomeIcon icon={faSpinner} spin />
                      : <span>Submit</span>
                  }
                </PrimaryButton>
              </StyledButtonWrapper>
            </>
          ) : (
            hasVoucherNumber
              ? (
                <>
                  <StyledColumn width={200}>{voucherStatus}</StyledColumn>
                  {(pdfVoucherNumber || voucherNumber) && (
                    <StyledColumn width={230}>
                      Voucher number: {pdfVoucherNumber || voucherNumber}
                    </StyledColumn>
                  )}
                </>
              ) : (
                <StyledColumn width={200}>{voucherStatus}</StyledColumn>
              )
          )
      }
    </StyledListItem>
  )
}

Certificate.propTypes = {
  voucherName: PropTypes.string,
  isFirstItem: PropTypes.bool,
  certificate: PropTypes.shape({
    certificateName: PropTypes.string,
    voucherStatus: PropTypes.string
  }).isRequired,
  updateStatus: PropTypes.func.isRequired
}

export default Certificate
