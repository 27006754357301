import createAuth0Client from '@auth0/auth0-spa-js'

let client

const getClient = async () => {
  if (client) return client

  client = await createAuth0Client({
    domain: process.env.REACT_APP_DOMAIN,
    client_id: process.env.REACT_APP_CLIENT_ID,
    responseType: 'token id_token'
  })

  client.getIdToken = async function () {
    await client.getTokenSilently()
    const claims = await client.getIdTokenClaims()
    return claims.__raw
  }

  return client
}

export default getClient
