import React from 'react'
import {
  StateCTA,
  StateDescription,
  StatePrimaryButton,
  StateSecondaryButton, WidgetStateContainer
} from './style'

const WidgetState = ({
  description,
  primaryBtnTxt,
  primaryBtnFunction,
  secondaryBtnTxt,
  secondaryBtnFunction
}) => {
  return (
    <WidgetStateContainer>
      <div
        className='d-md-flex align-items-md-center flex-md-row flex-sm-column'
      >
        <StateDescription>
          {description}
        </StateDescription>
        <StateCTA
          className='d-md-flex ml-md-auto flex-md-row flex-sm-column'
        >
          {primaryBtnTxt &&
            <StatePrimaryButton
              data-testid='state-primary-button'
              className='btn btn-primary btn-large'
              onClick={primaryBtnFunction}
            >
              {primaryBtnTxt}
            </StatePrimaryButton>}
          {secondaryBtnTxt &&
            <StateSecondaryButton
              data-testid='state-secondary-button'
              className='btn btn-secondary btn-large'
              onClick={secondaryBtnFunction}
            >
              {secondaryBtnTxt}
            </StateSecondaryButton>}
        </StateCTA>
      </div>
    </WidgetStateContainer>
  )
}

export default WidgetState
