import React, { useContext, useEffect, useState } from 'react'
import { getStudentAge } from '../../utilities/user'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  Container,
  Description
} from '../OnboardingForm/style'
import { dateToSecondsSinceEpoch } from '../../utilities/dateTime'
import api from '../../api'

const Under13 = () => {
  const { studentData } = useContext(AppContext)
  const [joinDate, setJoinDate] = useState('')

  useEffect(() => {
    if (!studentData) return

    const { dateOfBirth } = studentData
    const studentAge = getStudentAge(dateOfBirth)
    if (studentAge >= 13) return setJoinDate('')

    const rejoinDate = dateToSecondsSinceEpoch(new Date(dateOfBirth)) +
      (13 * 365 * 24 * 60 * 60)
    const formattedJoinDate = new Date(rejoinDate * 1000).toLocaleDateString(
      'en-US', { year: 'numeric', month: 'long' }
    )
    setJoinDate(formattedJoinDate)

    api.deleteStudentData()
    // eslint-disable-next-line
  }, [studentData])

  if (!joinDate) return <></>

  return (
    <Container>
      <div className='content' style={{ marginTop: '213px' }}>
        <Description data-testid='under13-description'>
          We are so impressed that you are already interested in higher education,
          but you must be at least 13 years old to use our site. Your parent or
          guardian can find this requirement in our&nbsp;
          <a href='#/terms-of-use' target='_blank' rel='noopener noreferrer'>
            Terms of Service
          </a>. If they need
          more information, they can contact our success team at&nbsp;
          <a
            href='mailto:success@outlier.org?subject=U13 Question'
            target='_blank'
            rel='noopener noreferrer'
          >
            success@outlier.org
          </a>.
          We’ll issue a full refund for your eligible purchases and delete your
          personal information to protect your privacy.
        </Description>
        <Description data-testid='under13-join-date' style={{ fontWeight: 400 }}>
          You can join us in {joinDate}!
        </Description>
      </div>
    </Container>
  )
}

Under13.displayName = 'Under13'
export default Under13
