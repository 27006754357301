import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'qs'
import { Dropdown } from 'react-bootstrap'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  Header,
  Nav,
  ChecklistIcon,
  HamBurger,
  DropdownMenuTitle,
  DropdownMenuItem,
  DropdownMenuItemLabel,
  DropdownMenuItemIcon,
  Logo,
  LogoImage,
  HeaderContainer,
  BrandWrap,
  MailContainer,
  TokenToast,
  TokenToastHeader
} from './style'
import { COURSE_DISPLAY_LIMIT } from '../Dashboard/Dashboard'
import { getActiveTokens } from '../../utilities/tokenUtils'
import { usePreviousState } from '../../utilities/stateUtils'
import UpdatedTermsNotificationBanner from './UpdatedTermsNotificationBanner'
import NeedsReviewBanner from '../NeedsReviewBanner/NeedsReviewBanner'
import DropdownMenuComponent from './DropdownMenuComponent'
import NotificationsTool from '../Announcements/NotificationsTool'
import api from '../../api'
import config from '../../config'
import Checklist from '../Checklist/Checklist'
import Notifications from './Notifications'
import {
  isOutlierAccount,
  onboardingFormSubmitted
} from '../../utilities/user'
import { EMAIL_VERIFIED, HIDE_DASHBOARD_ITEM } from '../../Constants'
import { segmentEventUrl } from '../AchievementsTracker/constants'
import { ACADEMIC_ACHIEVEMENTS_CLICK_THROUGH } from '../../Constants/eventType'
import { hasCourseProgress } from '../../utilities/course'
import { getIsPurchasedCertificate } from '../../utilities/certificate'
import DegreeRegistrationBanner from '../DegreeRegistrationBanner/DegreeRegistrationBanner'
import { FORM_LINKS } from '../../data/constants'
import {
  shouldRedirectToRecommendedCourses
} from '../../utilities/gguDegreeUtils'
import ServerSwitch from '../ServerSwitch/ServerSwitch'

const MenuItem = ({ children, ...rest }) => (
  <DropdownMenuItem {...rest}>
    <DropdownMenuItemIcon width='7' height='13'>
      <use xlinkHref='images/icons/icon-angle-arrow.svg#icon-angle-arrow' />
    </DropdownMenuItemIcon>
    <DropdownMenuItemLabel>{children}</DropdownMenuItemLabel>
  </DropdownMenuItem>
)

const shouldShowToast = (instrideId) => {
  const assignTokens = window.location.href.includes('assignTokens=true')
  return assignTokens || instrideId ? false : !localStorage.getItem('tokenToastSeen')
}

const AppHeader = () => {
  const params = qs.parse(window.location.search?.slice(1))
  const isRegistrationRedesign = params?.gguEnrollmentV2 === 'true'
  const [showToast, setShowToast] = useState(false)
  const [pageScroll, setPageScroll] = useState(window.pageYOffset)
  const [isBrand, setShowBrand] = useState(() => !localStorage.getItem('brand'))
  const [isAchievementsBrand, setIsAchievementsBrand] = useState(
    () => !localStorage.getItem('achievementsBrand')
  )
  const [showCheckList, setShowCheckList] = useState(false)
  const { logout, user } = useContext(Auth0Context)
  const {
    showGGUEnrollment,
    quitGGUEnrolment,
    isGGUStudentEnrolled,
    isGGUDegreeDataLoading,
    isContextLoading,
    courses,
    studentData,
    isStudioCohort,
    isGGUStudent,
    coursesProgress,
    tokens,
    certificates,
    prospects,
    hideCatalogMenu
  } = useContext(AppContext) || {}

  const history = useHistory()

  const {
    [EMAIL_VERIFIED]: studentDataEmailVerified,
    instrideId,
    under13
  } = studentData || {}

  const activePath = history?.location.pathname
  const isAudienceConsent = activePath === FORM_LINKS.audienceConsent

  const handleScroll = () => {
    const currentScroll = window.pageYOffset
    if (currentScroll < 0) return
    setPageScroll(currentScroll)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    setShowToast(shouldShowToast(instrideId))
  }, [instrideId])

  const prevScroll = usePreviousState(pageScroll)
  const showHeader = !prevScroll || prevScroll > pageScroll
  const {
    hasNotificationFlag,
    hasAchievementsFlag,
    hasPracExerciseFlag,
    isCollegeSuccessCourse
  } = config

  const { email, email_verified: auth0EmailVerified } = user || {}
  const isAdmin = email && isOutlierAccount(email)
  const hasOnlyCollegeSuccessCourse = courses?.length === 1 &&
    isCollegeSuccessCourse(courses[0].id)
  const studentEmailVerified = auth0EmailVerified || studentDataEmailVerified ||
  hasOnlyCollegeSuccessCourse
  const overrideMenuAccessCheck = hasCourseProgress(coursesProgress)
  const hasAccessToMenu = isAdmin || overrideMenuAccessCheck ||
    isGGUStudent || instrideId ||
    (studentEmailVerified && onboardingFormSubmitted(studentData) && !under13)
  const toggleCheckList = () => {
    setShowCheckList(!showCheckList)
  }
  const activeTokens = getActiveTokens(tokens)
  const noOfTokens = activeTokens?.length || 0

  const isPurchasedCertificate = getIsPurchasedCertificate(certificates)
  const shouldShowAchievements =
    hasAccessToMenu && hasAchievementsFlag && isPurchasedCertificate
  const isDegreeRegistrationRedesignPage = !isGGUDegreeDataLoading && !isContextLoading &&
    isRegistrationRedesign && showGGUEnrollment && !quitGGUEnrolment && !isGGUStudentEnrolled
  const showCatalog = !isDegreeRegistrationRedesignPage && hasAccessToMenu && courses
  const redirectToRecommendedCourses = hideCatalogMenu || shouldRedirectToRecommendedCourses(showGGUEnrollment, prospects)

  const onAchievementsClick = () => {
    localStorage.setItem('achievementsBrand', true)
    setIsAchievementsBrand(false)
    api.newSubmitTrackedEvent({
      event: ACADEMIC_ACHIEVEMENTS_CLICK_THROUGH
    }, segmentEventUrl)
  }

  const hideDashboardItem = localStorage.getItem(HIDE_DASHBOARD_ITEM)

  const { isOutlierEngineer } = config
  const showServerSwitch = isOutlierEngineer(user?.email) || localStorage.getItem('showServerSwitch') === 'true'
  return (
    <>
      <Header
        data-testid='header'
        showHeader={showHeader}
      >
        <HeaderContainer>
          <Nav>
            <Logo href='#'>
              <LogoImage
                src={hasPracExerciseFlag
                  ? 'images/new-outlier-logo.svg'
                  : 'images/logo.png'}
                alt='Logo'
              />
            </Logo>
            {(user && !isAudienceConsent) && (
              <HamBurger>
                {showServerSwitch && (
                  <ServerSwitch />
                )}
                <MailContainer
                  data-testid='email'
                  className='d-none d-sm-block fs-exclude'
                  hasPracExerciseFlag={hasPracExerciseFlag}
                >
                  {user && user.email}
                </MailContainer>
                <ChecklistIcon
                  onClick={() => toggleCheckList()}
                  alt='checklist'
                  data-testid='checklist-icon'
                  src='images/icons/icon-checklist-feature-updated.svg'
                />
                {config.hasAnnouncementsFlag() && (
                  <NotificationsTool />
                )}
                {hasNotificationFlag && (
                  <DropdownMenuComponent
                    showDropDownIcon={hasNotificationFlag}
                    imgSrc='images/icons/read-notification-bell.svg'
                    iconRightMargin='30'
                    iconLeftMargin='10'
                    dropDownOffset='19'
                    maxHeight='450'
                  >
                    <Notifications studentCourses={courses} />
                  </DropdownMenuComponent>
                )}
                <DropdownMenuComponent
                  imgSrc='images/icons/icon-new-hamburger.svg'
                  showDropDownIcon
                  dropDownOffset='8'
                  paddingBottom='0.5rem'
                >
                  <DropdownMenuTitle>Outlier</DropdownMenuTitle>
                  {hasAccessToMenu && !hideDashboardItem && (
                    <Dropdown.Item
                      onClick={() => history.push({
                        pathname: '/'
                      })}
                      as={MenuItem}
                    >
                    Student Dashboard
                    </Dropdown.Item>
                  )}
                  {hasAccessToMenu && courses && courses.length > COURSE_DISPLAY_LIMIT && (
                    <Dropdown.Item href='#my-courses' as={MenuItem}>
                      My Courses
                    </Dropdown.Item>
                  )}
                  {(showCatalog && !redirectToRecommendedCourses) && (
                    <Dropdown.Item
                      href='#catalog' as={MenuItem}
                      onClick={() => {
                        localStorage.setItem('brand', true)
                        setShowBrand(false)
                      }}
                    >
                      Catalog {isBrand && <BrandWrap>New</BrandWrap>}
                    </Dropdown.Item>
                  )}
                  {shouldShowAchievements && (
                    <Dropdown.Item
                      href='#achievements' as={MenuItem}
                      onClick={onAchievementsClick}
                    >
                      Achievements {isAchievementsBrand && <BrandWrap>New</BrandWrap>}
                    </Dropdown.Item>
                  )}
                  {hasAccessToMenu && (
                    <Dropdown.Item href='#account' as={MenuItem}>
                      Account
                    </Dropdown.Item>
                  )}
                  {hasAccessToMenu && !isStudioCohort && (
                    <Dropdown.Item
                      href={isGGUStudent ? '#contact' : 'https://help.outlier.org/hc/en-us/requests/new'}
                      {...(!isGGUStudent && { rel: 'noopener noreferrer', target: '_blank' })}
                      as={MenuItem}
                    >
                    Contact
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item href='#logout' as={MenuItem} onClick={logout}>
                    Logout
                  </Dropdown.Item>
                </DropdownMenuComponent>
              </HamBurger>
            )}

          </Nav>
        </HeaderContainer>
      </Header>
      {showCheckList && (
        <Checklist
          toggleCheckList={toggleCheckList}
        />
      )}
      <DegreeRegistrationBanner />
      <NeedsReviewBanner />
      <UpdatedTermsNotificationBanner />
      {showToast && !!noOfTokens &&
        <TokenToast
          onClose={() => {
            setShowToast(false)
            localStorage.setItem('tokenToastSeen', true)
          }}
        >
          <TokenToastHeader>
            <div data-testid='toastText'>
              You have {noOfTokens} new token{noOfTokens > 1 ? 's' : ''}!
              Use it to redeem a course in the Catalog.
            </div>
          </TokenToastHeader>
        </TokenToast>}
    </>
  )
}

export default AppHeader
