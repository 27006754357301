import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '../../assets/icons/close-icon.svg'
import {
  ToastWrapper,
  ToastHeader,
  ToastHeaderData,
  HeaderText,
  ToastBody,
  HorizontalLine
} from './style'

function Toast ({
  show,
  remove,
  title,
  getBody,
  hasBreak,
  headerIcon,
  toastWrapperStyle
}) {
  if (!show) return null

  return (
    <ToastWrapper
      data-testid='toast'
      hasBreak={hasBreak}
      style={toastWrapperStyle}
    >
      <ToastHeader>
        <ToastHeaderData>
          {headerIcon && (
            <img
              alt='check-icon'
              id='header-icon'
              src={headerIcon}
            />
          )}
          <HeaderText data-testid='title'>
            {title}
          </HeaderText>
        </ToastHeaderData>

        <img
          data-testid='close-icon'
          id='close-icon'
          src={CloseIcon}
          alt='Close Toast'
          onClick={remove}
        />
      </ToastHeader>

      <HorizontalLine />

      <ToastBody
        data-testid='body'
        hasBreak={hasBreak}
      >
        {getBody()}
      </ToastBody>
    </ToastWrapper>
  )
}

Toast.propTypes = {
  show: PropTypes.bool.isRequired,
  remove: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  getBody: PropTypes.func.isRequired,
  hasBreak: PropTypes.bool,
  headerIcon: PropTypes.string,
  toastWrapperStyle: PropTypes.object
}

Toast.displayName = 'Toast'
export default Toast
