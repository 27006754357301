export const announcements = [
  {
    id: 1,
    date: 'May 1, 2020',
    title: 'Introducing Our New Feature!',
    body: 'We\'re excited to announce our new online learning portal! Visit our website and click the "Online Learning" tab to access course materials, lecture videos, discussion boards, and more.',
    course: 'Intro to Managerial Accounting',
    name: 'Harry Potter',
    seen: true
  },
  {
    id: 2,
    date: 'May 1, 2020',
    title: 'Introducing Our New Feature!',
    body: 'We\'re excited to announce our new online learning portal! Visit our website and click the "Online Learning" tab to access course materials, lecture videos, discussion boards, and more.',
    course: 'Intro to Managerial Accounting',
    link: {
      text: 'Join Discussion',
      href: 'https://www.google.com'
    },
    name: 'Harry Potter',
    seen: true
  },
  {
    id: 3,
    date: 'May 1, 2020',
    title: 'Introducing Our New Feature!',
    body: 'We\'re excited to announce our new online learning portal! Visit our website and click the "Online Learning" tab to access course materials, lecture videos, discussion boards, and more. We\'re excited to announce our new online learning portal! Visit our website and click the "Online Learning" tab to access course materials, lecture videos, discussion boards, and more. We\'re excited to announce our new online learning portal! Visit our website and click the "Online Learning" tab to access course materials, lecture videos, discussion boards, and more. ',
    course: 'Intro to Managerial Accounting',
    name: 'Harry Potter',
    seen: false
  },
  {
    id: 4,
    date: 'May 1, 2020',
    title: 'Introducing Our New Feature!',
    body: 'We\'re excited to announce our new online learning portal! Visit our website and click the "Online Learning" tab to access course materials, lecture videos, discussion boards, and more.',
    course: 'Intro to Managerial Accounting',
    name: 'Harry Potter',
    seen: false
  },
  {
    id: 5,
    date: 'May 1, 2020',
    title: 'Introducing Our New Feature!',
    body: 'We\'re excited to announce our new online learning portal! Visit our website and click the "Online Learning" tab to access course materials, lecture videos, discussion boards, and more. We\'re excited to announce our new online learning portal! Visit our website and click the "Online Learning" tab to access course materials, lecture videos, discussion boards, and more. We\'re excited to announce our new online learning portal! Visit our website and click the "Online Learning" tab to access course materials, lecture videos, discussion boards, and more. ',
    course: 'Intro to Managerial Accounting',
    name: 'Harry Potter',
    seen: false
  },
  {
    id: 6,
    date: 'May 1, 2020',
    title: 'Introducing Our New Feature!',
    body: 'We\'re excited to announce our new online learning portal! Visit our website and click the "Online Learning" tab to access course materials, lecture videos, discussion boards, and more.',
    course: 'Intro to Managerial Accounting',
    name: 'Harry Potter',
    seen: true
  }
]
