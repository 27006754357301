export function attempts (courseIds, studentAttempts) {
  if (!studentAttempts?.length) return

  const enrolledAttempts = []
  const completedAttempts = []

  studentAttempts.forEach((attempt) => {
    const {
      fields: {
        course: {
          fields: { id } = {}
        } = {},
        studentStatus: { fields: { Name = '' } = {} } = {}
      }
    } = attempt

    if (courseIds?.length && !courseIds.includes(id)) return

    const name = Name.toLowerCase()

    if (name.includes('completed')) {
      completedAttempts.push(attempt)
    }

    if (name.includes('enrolled') ||
    name.includes('signed up') ||
    name.includes('predrop') ||
    name.includes('audit')) {
      enrolledAttempts.push(attempt)
    }
  })

  return {
    enrolledAttempts,
    completedAttempts
  }
}

export function hasStudentAuditedCourseBefore (courseId, studentAttempts) {
  if (!courseId || !studentAttempts) return
  const { completedAttempts } = attempts([courseId], studentAttempts) || {}

  if (!completedAttempts?.length) return false

  const completedAuditAttempt = completedAttempts.find(attempt => {
    const {
      fields: {
        cohort: {
          fields: {
            name = ''
          } = {}
        } = {}
      } = {}
    } = attempt

    return name.toLowerCase().includes('audit')
  })

  return !!completedAuditAttempt
}
