import { Separator } from '../style'
import WidgetState from '../WidgetState'
import React from 'react'
import { getDateTimeWithShortTimezone } from '../../../utilities/dateTime'
import { Title } from './style'
import config from '../../../config'
import ExtensionState from '../ExtensionState'
import { ASSIGNMENT } from '../../../Constants/examType'

const OpenUnsubmittedCard = ({
  course,
  assignments
}) => {
  let openUnsubmittedAssignments = []
  const { id } = course
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  assignments.forEach(assignment => {
    const isOpenUnsubmittedAssignment =
      assignment.isSubmitted === false && assignment.isOpen
    if (isOpenUnsubmittedAssignment) {
      openUnsubmittedAssignments.push(assignment)
    }
  })

  openUnsubmittedAssignments = openUnsubmittedAssignments.sort(
    (assignment1, assignment2) =>
      assignment1.lockTime - assignment2.lockTime)
  openUnsubmittedAssignments.forEach(activeAssignment => {
    const lockDate = getDateTimeWithShortTimezone(activeAssignment.lockTime)
    activeAssignment.url =
      `${courseBaseUrl}/#/${activeAssignment.chapter_uuid}/writing_assignment`
    activeAssignment.description =
      <>
        {'The assignment '}
        <Title>{activeAssignment.title}</Title>
        {` is open until ${lockDate}`}.
      </>
  })

  return (
    openUnsubmittedAssignments.map((assignment, index) => {
      const { url, description } = assignment
      return (
        <div key={index} data-testid='open-unsubmitted-description'>
          <Separator />
          <WidgetState
            description={description}
            primaryBtnTxt='Start'
            primaryBtnFunction={() => { window.location.href = url }}
          />
          <ExtensionState
            exam={assignment}
            course={course}
            type={ASSIGNMENT}
          />
        </div>
      )
    })
  )
}

export default OpenUnsubmittedCard
