import React from 'react'
import WidgetState from '../WidgetState'
import {
  getMultipleAssignmentsText
} from '../../../utilities/writingAssignmentsUtils'
import config from '../../../config'

const UnsubmittedCard = ({
  course,
  assignments,
  dismissUnsubmittedCard
}) => {
  let description
  let unsubmittedClosedAssignments = []
  const { id, cohort } = course

  const { courseInfoUrl, isPartnerCohort } = cohort
  const internalResourcesPage = config.courseBaseUrlById(id) + '/#/resources'
  const resourcesUrl = isPartnerCohort ? courseInfoUrl : internalResourcesPage

  unsubmittedClosedAssignments = assignments.filter(assignment =>
    assignment.isSubmitted === false && assignment.isClosed
  )

  if (unsubmittedClosedAssignments.length === 0) { return null }

  const handleDismiss = () => {
    dismissUnsubmittedCard(unsubmittedClosedAssignments)
  }

  if (unsubmittedClosedAssignments.length > 1) {
    const multipleAssignmentsText =
      getMultipleAssignmentsText(unsubmittedClosedAssignments)
    description =
      <div data-testid='unsubmitted-description'>
        {'You did not submit the assignments '}
        {multipleAssignmentsText}
        {' before their deadlines and received grades of 0%. '}
        <a href={resourcesUrl} rel='noopener noreferrer'>View the Toolkit</a>
        {' for more information on assessment windows.'}
      </div>
  }

  if (unsubmittedClosedAssignments.length === 1) {
    description =
      <div data-testid='unsubmitted-description'>
        {'You did not submit the assignment '}
        {unsubmittedClosedAssignments[0].title}
        {' before its deadline and received a grade of 0%. '}
        <a href={resourcesUrl} rel='noopener noreferrer'>View the Toolkit</a>
        {' for more information on assessment windows.'}
      </div>
  }

  return (
    <WidgetState
      description={description}
      secondaryBtnTxt='Dismiss'
      secondaryBtnFunction={handleDismiss}
    />
  )
}

export default UnsubmittedCard
