import React from 'react'
import config from '../../../config'
import some from 'lodash/some'
import { chaptersVisitedSections, allSectionsCompleted }
  from '../../../utilities/chapterUtils'
import {
  dateToSecondsSinceEpoch,
  secondsSinceEpoch,
  getDateTimeWithShortTimezone
} from '../../../utilities/dateTime'
import OpenCard from './OpenCard'
import ClosedCard from './ClosedCard'
import SubmittedCard from './SubmittedCard'
import UnsubmittedCard from './UnsubmittedCard'
import GradedCard from './GradedCard'
import { toWords } from 'number-to-words'
import { checkCourseNames, getLatestCohort } from '../../../utilities/course'
import {
  getCohortStartSecondsSinceEpoch
} from '../../../utilities/cohort'

const WritingAssignmentsCard = ({
  course,
  courseProgress,
  assignmentsData,
  examOpenData,
  handleDefaultState
}) => {
  const { id, chapters } = course

  const latestCohort = getLatestCohort(course)
  const cohortStartDate = getCohortStartSecondsSinceEpoch(latestCohort)

  const courseBaseUrl = config.courseBaseUrlById(id)
  const { studentProgress, sectionData } = courseProgress
  const chapterVisitedSections = chaptersVisitedSections(chapters, studentProgress)
  const currentTime = secondsSinceEpoch()
  let activeAssignmentsCount = 0
  let hasOpenAssignment = false
  let hasSubmittedAssignment = false
  let hasAlmostClosingAssignment = false
  let hasClosedAssignment = false
  let hasGradedAssignment = false
  let activeAssignment = null
  let sectionName
  let sectionUrl
  let showSectionCard = false
  let note = ''
  const assignments = chapters
    .filter((chapter) => {
      const { hideFileUploadField, hideTextEntryField } = chapter
      const isOptionalAssignment = hideFileUploadField && hideTextEntryField
      return !isOptionalAssignment && chapter.type === 'WritingAssignmentChapterRecord'
    })
    .map((chapter, index) => {
      return { ...chapter, chapterNumber: index + 1 }
    })
    .map(chapter => {
      const isPhilosophy = checkCourseNames(
        ['philosophy', 'philosophy.plus'],
         course?.id
      )
      const isNewGradingReleased = cohortStartDate >= config.philosophyUpdatedGradingReleaseDate

      const isPhilosophyNewGrading = isPhilosophy && isNewGradingReleased

      if (!isPhilosophyNewGrading) return chapter

      const {
        title,
        description,
        updateddescription,
        updateddisplaytitle
      } = chapter

      return {
        ...chapter,
        title: updateddisplaytitle || title,
        description: updateddescription || description
      }
    })

  const exams = chapters.filter(chapter => chapter.type === 'exam')

  const progressData = studentProgress['assignment-progress']

  const addMilestonesData = assignment => {
    const { chapter_uuid: assignmentUUID } = assignment
    const deadlineData = assignmentsData.find(currentAssignment =>
      currentAssignment.datoAssignmentUUID === assignmentUUID)
    if (!deadlineData) return

    const unlockTime = dateToSecondsSinceEpoch(new Date(deadlineData.unlockTime))
    const lockTime = dateToSecondsSinceEpoch(new Date(deadlineData.lockTime))
    const closeAlertInterval = 48 * 60 * 60
    const isUpcomingAssignment = currentTime < unlockTime
    const isOpenAssignment = (!unlockTime || currentTime > unlockTime) &&
      currentTime < lockTime
    const isClosedAssignment = (!unlockTime || currentTime > unlockTime) &&
      currentTime > lockTime
    const isAlmostClosingAssignment = isOpenAssignment &&
      currentTime > (lockTime - closeAlertInterval)

    assignment.name = deadlineData.name
    assignment.airtableDescription = assignment.description
    assignment.assignmentType = deadlineData.assignmentType
    assignment.unlockTime = unlockTime
    assignment.lockTime = lockTime
    assignment.isUpcoming = isUpcomingAssignment
    assignment.isOpen = isOpenAssignment
    hasOpenAssignment = hasOpenAssignment || isOpenAssignment
    assignment.isAlmostClosing = isAlmostClosingAssignment
    hasAlmostClosingAssignment = hasAlmostClosingAssignment ||
      isAlmostClosingAssignment
    assignment.isClosed = isClosedAssignment
    hasClosedAssignment = hasClosedAssignment || isClosedAssignment
  }

  const addProgressData = assignment => {
    const assignmentProgress = progressData[assignment.chapter_uuid]
    if (!assignmentProgress) return

    assignment.grade = assignmentProgress.grade
    assignment.isGraded = !!assignment.grade
    hasGradedAssignment = hasGradedAssignment || assignment.isGraded
    assignment.isSubmitted = assignmentProgress.status === 'submitted'
    hasSubmittedAssignment = hasSubmittedAssignment || assignment.isSubmitted
    const lateSubmission = assignment.isSubmitted &&
      assignment.lockTime < assignmentProgress.submissionTime
    assignment.lateSubmission = lateSubmission
  }

  const enrichAssignments = () => {
    assignments.forEach(assignment => {
      addMilestonesData(assignment)
      addProgressData(assignment)
    })
  }

  enrichAssignments()
  const hasUnsubmittedAssignment = hasOpenAssignment && !hasSubmittedAssignment

  chapterVisitedSections.forEach(visitedChapter => {
    const { visitedSections, chapterNo } = visitedChapter
    visitedSections.forEach(visitedSection => {
      const { sectionNo, sectionUuid } = visitedSection

      if (sectionData.section_uuid !== sectionUuid) return
      sectionName = `${chapterNo}.${sectionNo}`
      sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`
    })
  })

  const setUnsubmittedAssignment = () => {
    assignments.forEach(assignment => {
      if (!assignment.isOpen || assignment.isSubmitted) return
      activeAssignmentsCount++
      if (activeAssignment &&
        assignment.lockTime > activeAssignment.lockTime) return
      activeAssignment = assignment
    })
  }

  const checkForSectionsAfterAssignments = () => {
    let sectionChapterNumber = 0

    for (let i = 0; i < chapters.length; i++) {
      /* Exclude exams and assignments */
      const isChapter = chapters[i].type === 'chapter'
      const hasSection = isChapter && some(chapters[i]?.sections, {
        section_uuid: sectionData?.['section_uuid']
      })

      if (!hasSection) continue
      sectionChapterNumber = i + 1
      break
    }
    assignments.forEach(assignment => {
      showSectionCard = sectionChapterNumber > assignment.chapterNumber
    })
  }

  /***
   * @description counts the number of assignments in the given status;
   * Set the assignment with the earliest deadline as activeAssignment.
   */
  const setActiveAssignment = status => {
    activeAssignmentsCount = 0
    assignments.forEach(assignment => {
      if (!assignment[status]) return
      activeAssignmentsCount++
      if (activeAssignment &&
        assignment.lockTime > activeAssignment.lockTime) return
      activeAssignment = assignment
    })
  }

  checkForSectionsAfterAssignments()

  if (hasOpenAssignment) {
    setActiveAssignment('isOpen')
    let formattedLockedDate = ''
    if (activeAssignmentsCount === 1) {
      formattedLockedDate = getDateTimeWithShortTimezone(activeAssignment.lockTime)
      note = `${activeAssignment.title} is open until ${formattedLockedDate}.`
    } else {
      const openAssignments = assignments.filter(assignment => assignment.isOpen)
      // sort by deadline
      const sortedAssignments = openAssignments.sort((assignment1, assignment2) =>
        assignment1.lockTime - assignment2.lockTime)
      const earliestLockTime = sortedAssignments[0].lockTime
      const furthestLockTime = sortedAssignments[sortedAssignments.length - 1].lockTime
      formattedLockedDate = getDateTimeWithShortTimezone(earliestLockTime) +
        ' - ' + getDateTimeWithShortTimezone(furthestLockTime)
      note = `You have ${toWords(activeAssignmentsCount)} writing assignments due between ${formattedLockedDate}.`
    }
  }

  if (showSectionCard) return handleDefaultState(note)

  const isAllSectionsComplete = allSectionsCompleted(chapters, studentProgress)
  const isNoExamOpen = !examOpenData?.examOpen
  if (isAllSectionsComplete && isNoExamOpen &&
      hasUnsubmittedAssignment) {
    setUnsubmittedAssignment()
    return (
      <UnsubmittedCard
        course={course}
        note={note}
        assignments={assignments.filter(assignment =>
          assignment.isOpen && !assignment.isSubmitted)}
        assignmentsCount={activeAssignmentsCount}
        assignment={activeAssignment}
        courseBaseUrl={courseBaseUrl}
      />
    )
  }

  if (hasAlmostClosingAssignment) {
    setActiveAssignment('isAlmostClosing')
    return (
      <ClosedCard
        course={course}
        note={note}
        almostClosing={hasAlmostClosingAssignment}
        assignmentsCount={activeAssignmentsCount}
        assignment={activeAssignment}
        courseBaseUrl={courseBaseUrl}
        sectionUrl={sectionUrl}
        sectionName={sectionName}
      />
    )
  }

  if (hasGradedAssignment) {
    setActiveAssignment('isGraded')
    return (
      <GradedCard
        course={course}
        note={note}
        assignment={activeAssignment}
        courseBaseUrl={courseBaseUrl}
        sectionUrl={sectionUrl}
        sectionName={sectionName}
      />
    )
  }

  if (hasSubmittedAssignment) {
    return (
      <SubmittedCard
        course={course}
        note={note}
        assignments={assignments}
        exams={exams}
        courseBaseUrl={courseBaseUrl}
        sectionUrl={sectionUrl}
        sectionName={sectionName}
        examOpenData={examOpenData}
        studentProgress={studentProgress}
      />
    )
  }

  if (hasOpenAssignment) {
    return (
      <OpenCard
        course={course}
        note={note}
        openAssignmentsCount={activeAssignmentsCount}
        openAssignment={activeAssignment}
        courseBaseUrl={courseBaseUrl}
        sectionUrl={sectionUrl}
        sectionName={sectionName}
      />
    )
  }

  if (hasClosedAssignment) {
    setActiveAssignment('isClosed')
    return (
      <ClosedCard
        course={course}
        note={note}
        almostClosing={hasAlmostClosingAssignment}
        assignmentsCount={activeAssignmentsCount}
        assignment={activeAssignment}
        courseBaseUrl={courseBaseUrl}
        sectionUrl={sectionUrl}
        sectionName={sectionName}
      />
    )
  }

  return handleDefaultState(note)
}

export default WritingAssignmentsCard
