import get from 'lodash/get'
import {
  diffDays,
  changeTimezone,
  getPTTimeSinceEpoch,
  parseDate,
  millisecondsToSeconds,
  dateToSecondsSinceEpoch,
  convertEasternTimeToLocalTime,
  changePTDateToLocalTime,
  secondsSinceEpoch
} from './dateTime'
import {
  isExamClosed,
  isExamOpen,
  isExamUpcoming,
  isRealExam
} from './chapterUtils'
import { COHORT_DURATIONS, cohortTypes, durationTypes } from '../Constants/cohort'
import { PROGRESS_TYPE } from '../Constants/courseCard'
import {
  getCohortStartSecondsSinceEpoch
  as
  getCohortStartMilliSecondsSinceEpoch
} from './course'
import config from '../config'
const { AUDIT, SHORT, LONG } = cohortTypes

export {
  processCohortData,
  getCohortStartSecondsSinceEpoch,
  getCohortSpecialDays,
  getCohortModifier,
  getCohortExamDates,
  getCohortDeadlinesTime,
  getCohortExamDateSecondsSinceEpoch
}

// Logic of this file was taken from calculus-static, courseUtils.js
// Minor adaptations were made

function processCohortData (cohort) {
  return {
    cohortStartDate: getCohortStartSecondsSinceEpoch(cohort),
    cohortModifier: getCohortModifier(cohort),
    cohortSpecialDays: getCohortSpecialDays(cohort),
    cohortExamDates: getCohortExamDates(cohort)
  }
}

function getCohortStartSecondsSinceEpoch (cohort) {
  if (!cohort || !cohort.dateStart) return null

  // Use the string date part and set the time to 00:00 AM UTC
  const date = new Date(cohort.dateStart + 'T00:00:00')

  // Convert date to PST time.
  const datePST = changeTimezone(date, 'America/Los_Angeles')

  return Math.floor(datePST.getTime() / 1000)
}

function getCohortSpecialDays (cohort) {
  if (!cohort) return

  const { specialDaysDates, dateStart } = cohort
  if (!specialDaysDates || !dateStart) return

  const cohortStartDate = dateToSecondsSinceEpoch(new Date(dateStart))
  const cohortSpecialDays = []
  specialDaysDates.forEach(({ dayStart, dayEnd }) => {
    const startDate = dateToSecondsSinceEpoch(new Date(dayStart))
    const endDate = dateToSecondsSinceEpoch(new Date(dayEnd))
    if (startDate < cohortStartDate) return
    const specialDays = diffDays(cohortStartDate, startDate)
    cohortSpecialDays.push({ startDate, endDate, specialDays })
  })
  return cohortSpecialDays
}

function getCohortModifier (chapters, cohortDuration) {
  if (!chapters || !cohortDuration) return
  const exams = chapters.filter((elem) => elem.type === 'exam')
  if (!exams.length) return
  const finalExam = exams[exams.length - 1]
  const { unlock_at_week: courseLength } = finalExam
  return courseLength ? cohortDuration / courseLength : cohortDuration
}

export const getExamDatesFromCohortExamDates = ({
  exam = {},
  cohortExamDates,
  currentTime = secondsSinceEpoch()
}) => {
  const {
    midTerm1StartDate,
    midTerm1EndDate,
    midTerm2StartDate,
    midTerm2EndDate,
    finalExamStartDate,
    courseEndDate
  } = cohortExamDates || {}

  if (!isRealExam(exam)) {
    return {
      startDate: null,
      endDate: null
    }
  }

  const { examNumber, isFinalExam } = exam

  if (isFinalExam) {
    return {
      startDate: finalExamStartDate,
      endDate: courseEndDate,
      isExamUpcoming: isExamUpcoming(currentTime, finalExamStartDate),
      isFinalExam
    }
  }

  // Existing Midterm 1
  if (examNumber === 1) {
    return {
      startDate: midTerm1StartDate,
      endDate: midTerm1EndDate,
      isExamUpcoming: isExamUpcoming(currentTime, midTerm1StartDate)
    }
  }

  // Existing Midterm 2
  if (examNumber === 2) {
    return {
      startDate: midTerm2StartDate,
      endDate: midTerm2EndDate,
      isExamUpcoming: isExamUpcoming(currentTime, midTerm2StartDate)
    }
  }

  // Other exams template will be: exam{examNumber}StartDate and exam{examNumber}EndDate
  // Example: exam3StartDate and exam3EndDate
  const examStartDateKey = `exam${examNumber}StartDate`
  const examEndDateKey = `exam${examNumber}EndDate`

  return {
    startDate: cohortExamDates?.[examStartDateKey],
    endDate: cohortExamDates?.[examEndDateKey],
    isExamUpcoming: isExamUpcoming(currentTime, cohortExamDates?.[examStartDateKey])
  }
}

export const getCurrentExamDate = (examSchedule, cohortExamDates) => {
  const {
    title,
    assignmentType,
    examNumber,
    isFinalExam
  } = examSchedule

  const exam = {
    title,
    type: assignmentType,
    examNumber,
    isFinalExam
  }

  const {
    startDate,
    endDate
  } = getExamDatesFromCohortExamDates({
    exam,
    cohortExamDates
  })

  return {
    start: startDate,
    stop: endDate
  }
}

function getCohortExamDates (cohort) {
  if (!cohort) return

  const {
    midTerm1StartTime,
    midTerm1EndTime,
    midTerm2StartTime,
    midTerm2EndTime,
    exam3StartTime,
    exam3EndTime,
    exam4StartTime,
    exam4EndTime,
    exam5StartTime,
    exam5EndTime,
    finalExamStartTime,
    finalExamEndTime
  } = cohort

  return {
    midTerm1StartDate: getCohortExamDateSecondsSinceEpoch(midTerm1StartTime),
    midTerm1EndDate: getCohortExamDateSecondsSinceEpoch(midTerm1EndTime),
    midTerm2StartDate: getCohortExamDateSecondsSinceEpoch(midTerm2StartTime),
    midTerm2EndDate: getCohortExamDateSecondsSinceEpoch(midTerm2EndTime),
    exam3StartDate: getCohortExamDateSecondsSinceEpoch(exam3StartTime),
    exam3EndDate: getCohortExamDateSecondsSinceEpoch(exam3EndTime),
    exam4StartDate: getCohortExamDateSecondsSinceEpoch(exam4StartTime),
    exam4EndDate: getCohortExamDateSecondsSinceEpoch(exam4EndTime),
    exam5StartDate: getCohortExamDateSecondsSinceEpoch(exam5StartTime),
    exam5EndDate: getCohortExamDateSecondsSinceEpoch(exam5EndTime),
    finalExamStartDate: getCohortExamDateSecondsSinceEpoch(finalExamStartTime),
    courseEndDate: getCohortExamDateSecondsSinceEpoch(finalExamEndTime),
    finalExamEndDate: getCohortExamDateSecondsSinceEpoch(finalExamEndTime)
  }
}

function getCohortExamDateSecondsSinceEpoch (date) {
  return date ? dateToSecondsSinceEpoch(new Date(date)) : undefined
}

// time is adjusted to 11:59pm PT
function getCohortDeadlinesTime (cohort) {
  if (!cohort || typeof cohort !== 'object') return {}

  const { dateStart, finalDropDate, finalWithdrawalDate } = cohort

  const dayMilliSeconds = 24 * 60 * 60 * 1000 - (60 * 1000) // minus a minute
  const durationMilliSeconds = (cohort.duration * 7 * 24 * 60 * 60 * 1000)

  return {
    startTimePT: getPTTimeSinceEpoch(dateStart) + dayMilliSeconds || undefined,
    endTimePT: getPTTimeSinceEpoch(dateStart) + dayMilliSeconds + durationMilliSeconds || undefined,
    dropTimePT: getPTTimeSinceEpoch(finalDropDate) + dayMilliSeconds || undefined,
    withdrawalTimePT: getPTTimeSinceEpoch(finalWithdrawalDate) + dayMilliSeconds || undefined
  }
}

export const getCohortOpenExamDates = (cohort, currentTime) => {
  if (!cohort) return

  const {
    midTerm1StartTime,
    midTerm1EndTime,
    midTerm2StartTime,
    midTerm2EndTime,
    exam3StartTime,
    exam3EndTime,
    exam4StartTime,
    exam4EndTime,
    exam5StartTime,
    exam5EndTime,
    finalExamStartTime,
    finalExamEndTime
  } = cohort

  const midterm1Dates = {
    startDate: midTerm1StartTime ? Date.parse(new Date(midTerm1StartTime)) : '',
    endDate: midTerm1EndTime ? Date.parse(new Date(midTerm1EndTime)) : ''
  }

  const midterm2Dates = {
    startDate: midTerm2StartTime ? Date.parse(new Date(midTerm2StartTime)) : '',
    endDate: midTerm2EndTime ? Date.parse(new Date(midTerm2EndTime)) : ''
  }

  const exam3Dates = {
    startDate: exam3StartTime ? Date.parse(new Date(exam3StartTime)) : '',
    endDate: exam3EndTime ? Date.parse(new Date(exam3EndTime)) : ''
  }

  const exam4Dates = {
    startDate: exam4StartTime ? Date.parse(new Date(exam4StartTime)) : '',
    endDate: exam4EndTime ? Date.parse(new Date(exam4EndTime)) : ''
  }

  const exam5Dates = {
    startDate: exam5StartTime ? Date.parse(new Date(exam5StartTime)) : '',
    endDate: exam5EndTime ? Date.parse(new Date(exam5EndTime)) : ''
  }

  const finalExamDates = {
    startDate: finalExamStartTime ? Date.parse(new Date(finalExamStartTime)) : '',
    endDate: finalExamEndTime ? Date.parse(new Date(finalExamEndTime)) : ''
  }

  // Order of exams is important here, so that we can return
  // the highest priority exam that is open
  const examDates = [
    finalExamDates,
    exam5Dates,
    exam4Dates,
    exam3Dates,
    midterm2Dates,
    midterm1Dates
  ]

  for (let i = 0; i < examDates.length; i++) {
    const { startDate, endDate } = examDates[i] || {}
    const examIsOpen = isExamOpen(currentTime, startDate, endDate)

    if (examIsOpen) {
      return {
        unlockDate: startDate,
        lockDate: endDate,
        examOpen: examDates.length - i,
        isFinalExam: i === 0
      }
    }
  }
}

export const getCohortUpcomingExamDates = (cohort, currentTime, exam) => {
  if (!cohort) return

  const { examNumber } = exam
  if (!examNumber) return

  const cohortExamDates = getCohortExamDates(cohort)

  const {
    startDate,
    endDate,
    isExamUpcoming,
    isFinalExam
  } = getExamDatesFromCohortExamDates({
    exam,
    currentTime,
    cohortExamDates
  })

  if (isExamUpcoming) {
    return {
      startDate,
      endDate,
      isFinalExam
    }
  }
}

export const isAuditCohort = cohortName => {
  return !!cohortName?.trim().toLowerCase().endsWith(' audit')
}

export const isVIPCohort = cohortName => {
  return !!cohortName?.trim().toLowerCase().endsWith(' vip')
}

export const getCohortClosestExam = (cohort) => {
  if (!cohort) return

  const {
    midTerm1StartTime,
    midTerm2StartTime,
    exam3StartTime,
    exam4StartTime,
    exam5StartTime,
    finalExamStartTime
  } = cohort

  const currentTime = Date.now()

  const midTerm1StartDate = parseDate(midTerm1StartTime)
  const midTerm2StartDate = parseDate(midTerm2StartTime)
  const exam3StartDate = parseDate(exam3StartTime)
  const exam4StartDate = parseDate(exam4StartTime)
  const exam5StartDate = parseDate(exam5StartTime)
  const finalExamStartDate = parseDate(finalExamStartTime)

  // Order of exams is important, so that we can return the closest upcoming exam to the current time
  const examDates = [
    midTerm1StartDate,
    midTerm2StartDate,
    exam3StartDate,
    exam4StartDate,
    exam5StartDate,
    finalExamStartDate
  ]

  for (let i = 0; i < examDates.length; i++) {
    const examDate = examDates[i]
    const examIsUpcoming = isExamUpcoming(currentTime, examDate)

    if (examIsUpcoming) {
      return {
        startDate: millisecondsToSeconds(examDate),
        examNumber: i + 1,
        isFinalExam: i === examDates.length - 1
      }
    }
  }

  return {
    startDate: null,
    examNumber: null,
    isFinalExam: null
  }
}

export const getExamFromTitle = (course, examTitle) => {
  if (!course) return
  const { chapters } = course
  return chapters?.find(({ title }) => title === examTitle)
}

export function filterCohorts (cohorts, criteria) {
  if (!cohorts?.length || criteria?.length !== 2) return

  const [audit, intensive] = criteria

  return cohorts.filter((cohort) => {
    let { duration, name } = cohort

    const nameCriterion = audit
      ? name.toLowerCase().includes(AUDIT)
      : !name.toLowerCase().includes(AUDIT)

    duration = duration?.toString()

    const durationCriterion = intensive
      ? duration === SHORT
      : duration === LONG
    const durationCriterion2 = intensive
      ? duration === '8'
      : duration === '15'

    return (durationCriterion || durationCriterion2) && nameCriterion
  })
}

export const getAllCohortStartDates = ({
  availableCohorts,
  isAuditCohort,
  isStandardCohort
}) => {
  if (!availableCohorts?.length) return []

  const cohorts = filterCohorts(availableCohorts, [isAuditCohort, !isStandardCohort])
  return getCohortsStartDates(cohorts) || []
}

export const getCohortType = cohortDuration => {
  return cohortDuration >= 14 ? 'Standard' : 'Intensive'
}

export const getCommitment = cohortDuration => {
  return cohortDuration >= 14 ? 10 : 20
}

export function getAvailableCohorts ({
  courses,
  courseSelected,
  currentSemester,
  isActiveGGUStudent
}) {
  if (!courseSelected || !courses?.length) return

  const { closeEnrollment } = currentSemester || {}

  let cohorts = []
  const additionalIds = config.getAdditionalCourseIdsById(courseSelected.uuid.uuid)
  if (additionalIds?.length > 1) {
    courses.forEach((course) => {
      if (additionalIds.includes(course.id)) {
        cohorts = cohorts.concat(course.cohorts.map(cohort =>
          ({ ...cohort, course: course.id })))
      }
    })
  } else {
    cohorts = courses.find((course) => {
      return course.id === courseSelected.uuid.uuid
    })?.cohorts || []
  }

  return cohorts
    .filter((cohort) => {
      if (cohort?.testCohort) return false

      const now = new Date()
      const { finalRegistrationDate, finalExamEndTime } = cohort

      if (!finalRegistrationDate) return false

      const registrationDateEnd = convertEasternTimeToLocalTime(
        finalRegistrationDate, 'T15:00:00'
      ).getTime()

      if (!isActiveGGUStudent) return registrationDateEnd >= now.getTime()

      const UPJExamEndTime = new Date(finalExamEndTime).getTime()
      const gguEnrollmentCloseDate = new Date(closeEnrollment).getTime()

      return registrationDateEnd >= now.getTime() &&
       UPJExamEndTime <= gguEnrollmentCloseDate
    })
    .sort((a, b) => {
      const aFinalRegistrationDate = new Date(
        a.finalRegistrationDate
      ).getTime()
      const bFinalRegistrationDate = new Date(
        b.finalRegistrationDate
      ).getTime()

      return aFinalRegistrationDate - bFinalRegistrationDate
    })
}

export function getCohortsStartDates (cohorts) {
  if (!cohorts) return
  return cohorts.map(cohort => {
    return changePTDateToLocalTime(cohort.dateStart)
  })
}

export const getCohortStartEndTime = (cohort) => {
  if (!cohort) return {}

  const date = get(cohort, 'dateStart')
  const startTime = Date.parse(date)
  const duration = get(cohort, 'duration', 0)
  const endTime = startTime + duration * 7 * 24 * 60 * 60 * 1000
  return {
    startTime, endTime
  }
}

export function progressBeforeCohortStart (cohort, courseProgress) {
  if (!cohort || !courseProgress) return

  const { LAST_PROGRESS_TIMESTAMP } = PROGRESS_TYPE
  const startDateSinceEpoch = getCohortStartMilliSecondsSinceEpoch(cohort)
  const { studentProgress } = courseProgress

  if (!studentProgress[LAST_PROGRESS_TIMESTAMP]) return

  return studentProgress[LAST_PROGRESS_TIMESTAMP] < startDateSinceEpoch
}

export function getLatestCohortClosedExam (cohort) {
  if (!cohort) return

  const {
    midTerm1EndTime,
    midTerm2EndTime,
    exam3EndTime,
    exam4EndTime,
    exam5EndTime,
    finalExamEndTime
  } = cohort

  const currentTime = Date.now()

  const midTerm1EndDate = parseDate(midTerm1EndTime)
  const midTerm2EndDate = parseDate(midTerm2EndTime)
  const exam3EndDate = parseDate(exam3EndTime)
  const exam4EndDate = parseDate(exam4EndTime)
  const exam5EndDate = parseDate(exam5EndTime)
  const finalExamEndDate = parseDate(finalExamEndTime)

  // Order of exams is important, so that we can return
  // the closest closed exam to the current time
  // that means if both final exam and exam 5 are closed, we return final exam
  const examDates = [
    finalExamEndDate,
    exam5EndDate,
    exam4EndDate,
    exam3EndDate,
    midTerm2EndDate,
    midTerm1EndDate
  ]

  for (let i = 0; i < examDates.length; i++) {
    const examDate = examDates[i]
    const examIsClosed = isExamClosed(currentTime, examDate)

    if (examIsClosed) {
      return {
        closedExam: examDates.length - i,
        isFinalExam: i === 0
      }
    }
  }
}

export function getLatestStudentAttempt (attempts, courseId) {
  // Attempts for the same course
  const courseAttempts = attempts.filter(attempt => {
    return attempt?.fields?.course?.fields?.id === courseId
  })

  // Sorted attempts by date start
  const sortedAttempts = courseAttempts.sort((a, b) => {
    const aDateStart = new Date(a?.fields?.cohort?.fields?.dateStart).getTime()
    const bDateStart = new Date(b?.fields?.cohort?.fields?.dateStart).getTime()

    return bDateStart - aDateStart
  })

  return sortedAttempts[0]
}

export function getCohortDurationType (duration) {
  const { INTENSIVE, STANDARD, EXTENDED } = durationTypes
  if (COHORT_DURATIONS[INTENSIVE].includes(duration)) return INTENSIVE
  if (COHORT_DURATIONS[STANDARD].includes(duration)) return STANDARD
  if (COHORT_DURATIONS[EXTENDED].includes(duration)) return EXTENDED
}
