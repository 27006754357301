import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

const RedirectComponent = () => {
  const { slug } = useParams()
  const redirectUrl = `https://api.outlier.org/course-download-redirect/${slug}`

  useEffect(() => {
    window.location.replace(redirectUrl)
  }, [redirectUrl])

  return null
}

export default RedirectComponent
