import * as studentStatus from '../Constants/studentStatus'

export const getLetterGrade = (gradeNumber) => {
  if (isNaN(gradeNumber)) return 'N/A'
  if (gradeNumber >= 92.5) return 'A'
  if (gradeNumber >= 89.5) return 'A-'
  if (gradeNumber >= 86.5) return 'B+'
  if (gradeNumber >= 82.5) return 'B'
  if (gradeNumber >= 79.5) return 'B-'
  if (gradeNumber >= 76.5) return 'C+'
  if (gradeNumber >= 72.5) return 'C'
  if (gradeNumber >= 69.5) return 'C-'
  if (gradeNumber >= 59.5) return 'D'
  return studentStatus.F
}
