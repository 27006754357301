import React, { useContext, useState } from 'react'
import PageItemContent from './PageItem'
import { AppContext } from '../ContextProvider/ContextProvider'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { Spinner } from 'react-bootstrap'
import {
  EditButton, EditSection, InputField, InputLabel, ItemContainer,
  ItemContentContainer, SaveButton, ErrorMessage
} from './style'
import {
  LEGAL_NAME, PHONE_NUMBER, PREFERRED_NAME
} from '../../Constants/accountArea'
import api from '../../api'
import utils from '../../utilities'

function PersonalInfoPage () {
  const {
    studentData, reloadContext, isContextLoading
  } = useContext(AppContext) || {}
  const {
    firstName, lastName, preferredName, phoneNumber
  } = studentData || {}

  const [activeEditingItem, setActiveEditingItem] = useState('')
  const [isWritingData, setIsWritingData] = useState(false)

  const [preferredNameProps, setPreferredNameProps] = useState({
    isActive: false,
    value: ''
  })
  const [preferredNameError, setPreferredNameError] = useState(false)

  const [phoneNumberProps, setPhoneNumberProps] = useState({
    isActive: false,
    value: ''
  })
  const [phoneNumberError, setPhoneNumberError] = useState(false)

  const handleEditButton = (itemName) => {
    setActiveEditingItem(itemName)

    if (itemName === PREFERRED_NAME) {
      setPreferredNameError(false)
      setPreferredNameProps({
        isActive: !preferredNameProps.isActive,
        value: ''
      })
    }

    if (itemName === PHONE_NUMBER) {
      setPhoneNumberError(false)
      setPhoneNumberProps({
        isActive: !phoneNumberProps.isActive,
        value: ''
      })
    }
  }

  const isEditingActive = (
    preferredNameProps.isActive ||
    phoneNumberProps.isActive
  )

  const getItemOpacity = (itemName) => {
    if (isEditingActive) {
      if (itemName === activeEditingItem) return '1'
      return '.3'
    }

    return '1'
  }

  const shouldShowEditButton = (itemName) => {
    if (!isEditingActive) return true
    return itemName === activeEditingItem
  }

  const handlePreferredNameInput = e => {
    setPreferredNameError(false)
    setPreferredNameProps({ ...preferredNameProps, value: e.target.value })
  }

  const handlePhoneNumberInput = e => {
    setPhoneNumberError(false)
    setPhoneNumberProps({ ...phoneNumberProps, value: e.target.value })
  }

  const handleSaveButton = async (itemName) => {
    let studentData

    if (itemName === PREFERRED_NAME) {
      if (!utils.validateInput(preferredNameProps.value)) {
        return setPreferredNameError(true)
      }
      studentData = {
        preferredName: preferredNameProps.value
      }
    }

    if (itemName === PHONE_NUMBER) {
      if (!utils.validateInput(phoneNumberProps.value)) {
        return setPhoneNumberError(true)
      }
      studentData = {
        phoneNumber: phoneNumberProps.value
      }
    }

    try {
      setIsWritingData(true)
      await api.updateStudent(studentData)
      reloadContext()
    } catch (error) {
    } finally {
      handleEditButton(itemName)
      setIsWritingData(false)
    }
  }

  return (
    <div
      data-testid='personal-info-page'
    >
      {isContextLoading && <LoadingSpinner />}

      <ItemContainer
        opacity={getItemOpacity(LEGAL_NAME)}
      >
        <ItemContentContainer>
          <PageItemContent
            title={LEGAL_NAME}
            content={firstName ? (firstName + ' ' + lastName) : ''}
            tooltipText='Your legal name is what’s on your driver’s license or passport. This field will be locked one week after the Drop Deadline.'
          />
        </ItemContentContainer>
      </ItemContainer>

      <ItemContainer
        opacity={getItemOpacity(PREFERRED_NAME)}
      >
        <ItemContentContainer>
          <PageItemContent
            title={PREFERRED_NAME}
            content={preferredName || firstName}
            tooltipText='Your preferred name is what you would like for us to call you.'
          />

          {shouldShowEditButton(PREFERRED_NAME) && (
            <EditButton
              disabled={isContextLoading}
              onClick={() => handleEditButton(PREFERRED_NAME)}
              data-testid='preferred-name-edit-button'
            >
              {preferredNameProps.isActive ? 'cancel' : !preferredName ? 'add' : 'edit'}
            </EditButton>
          )}
        </ItemContentContainer>

        {preferredNameProps.isActive && (
          <EditSection
            data-testid='preferred-name-edit-section'
          >
            <InputLabel>First name</InputLabel>
            <InputField
              autoFocus
              disabled={isWritingData}
              value={preferredNameProps.value}
              hasError={preferredNameError}
              data-testid='preferred-name-input'
              onChange={handlePreferredNameInput}
            />
            {preferredNameError && (
              <ErrorMessage>Please enter a valid name.</ErrorMessage>
            )}

            <SaveButton
              disabled={isWritingData}
              data-testid='preferred-name-save-button'
              onClick={() => handleSaveButton(PREFERRED_NAME)}
            >
              {isWritingData ? <Spinner animation='border' /> : 'Save'}
            </SaveButton>
          </EditSection>
        )}
      </ItemContainer>

      <ItemContainer
        opacity={getItemOpacity(PHONE_NUMBER)}
      >
        <ItemContentContainer>
          <PageItemContent
            title={PHONE_NUMBER}
            content={phoneNumber}
          />

          {shouldShowEditButton(PHONE_NUMBER) && (
            <EditButton
              disabled={isContextLoading}
              onClick={() => handleEditButton(PHONE_NUMBER)}
              data-testid='phone-number-edit-button'
            >
              {phoneNumberProps.isActive ? 'cancel' : !phoneNumber ? 'add' : 'edit'}
            </EditButton>
          )}
        </ItemContentContainer>

        {phoneNumberProps.isActive && (
          <EditSection
            data-testid='phone-number-edit-section'
          >
            <InputLabel>Updated number</InputLabel>
            <InputField
              autoFocus
              disabled={isWritingData}
              value={phoneNumberProps.value}
              hasError={phoneNumberError}
              onChange={handlePhoneNumberInput}
            />
            {phoneNumberError && (
              <ErrorMessage lastItem>Please enter a valid phone number.</ErrorMessage>
            )}

            <SaveButton
              disabled={isWritingData}
              onClick={() => handleSaveButton(PHONE_NUMBER)}
            >
              {isWritingData ? <Spinner animation='border' /> : 'Save'}
            </SaveButton>
          </EditSection>
        )}
      </ItemContainer>
    </div>
  )
}

export default PersonalInfoPage
