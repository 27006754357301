import styled from 'styled-components'

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #161618;
  color: #FFFFFF;
  height: calc(100vh - 64px);
`

export const HeaderText = styled.h1`
  font-family: Lato,serif;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;
`

export const SubHeaderText = styled.p`
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
`

export const BackButton = styled.button`
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #5FC4B8;
  background-color: #0A2A2D;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #0A2A2D;
    opacity: 0.8;
  }
  &:focus {
    background-color: #005A5A;
    outline: 2px solid #FFFFFF;
    outline-offset: 4px;
  }
`
