import React from 'react'
import { DropdownWrap, DropdownItem, Overlay } from './styles'

const ActivityDropdown = ({ option, show, toggle, isActiveLearning, onClick }) => {
  return (
    <>
      <Overlay show={show} onClick={toggle} data-testid='overlay' />
      <DropdownWrap show={show} data-testid='dropdownWrap'>
        {option?.map(({
          instructorName,
          instructor_uuid: instructorUUID,
          lecturevideosUUID
        }, index) => (
          <DropdownItem
            key={index}
            onClick={() =>
              onClick(
                instructorUUID,
                instructorName,
                lecturevideosUUID)}
          >
            {instructorName} {isActiveLearning && 'Theme'}
          </DropdownItem>
        ))}
      </DropdownWrap>
    </>
  )
}

export default ActivityDropdown
