import React from 'react'
import { useHistory } from 'react-router-dom'
import { BackButton } from './style'

const BackBreadcrumb = ({
  className,
  title,
  path,
  search,
  state = {},
  clickHandler
}) => {
  const history = useHistory()
  const goBackHandler = () => {
    history.push({
      pathname: path,
      state,
      search
    })
  }
  return (
    <BackButton
      data-testid='btn-back'
      className={className}
      onClick={clickHandler || goBackHandler}
    >
      <img alt='back-button' src='images/icons/back.svg' />{' '}
      <div>{title}</div>
    </BackButton>
  )
}

export default BackBreadcrumb
