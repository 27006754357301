import React from 'react'
import {
  Container,
  IntroText,
  IntroHeader,
  Title,
  TitleIcon,
  CustomButton
} from './styled'

const ChecklistIntro = ({ setViewChecklist }) => {
  return (
    <Container>
      <IntroHeader>
        <TitleIcon
          alt='checklist'
          src='images/icons/icon-checklist-feature-updated.svg'
        />
        <Title>Welcome to your Activities list!</Title>
      </IntroHeader>
      <IntroText>
        <p>
          We designed this tool to make staying on top of your course as simple
          and easy as can be. It matches the recommended pace that's outlined in
          your course syllabus to keep your workload balanced for the duration
          of your course.
        </p>
        <p>
          Keeping up with your Activities list is totally optional and doesn't
          impact your grades, but our data shows that students who keep up with
          this list are much more likely to pass the course.
        </p>
        <p>
          (And we know from personal experience that playing catch-up after
          falling behind in any course is rarely fun.)
        </p>
      </IntroText>
      <CustomButton
        data-testid='checklist-intro-button'
        onClick={setViewChecklist}
      >
        Let me see it
      </CustomButton>
    </Container>
  )
}

export default ChecklistIntro
