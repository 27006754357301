import { useEffect, useRef } from 'react'

const useSkipFirstEffect = (callback, dependencies) => {
  const hasRun = useRef(false)

  useEffect(() => {
    if (hasRun.current) {
      callback()
    }

    hasRun.current = true
  // eslint-disable-next-line
  }, dependencies)
}

export default useSkipFirstEffect
