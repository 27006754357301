import styled, { createGlobalStyle, keyframes } from 'styled-components'
import media from '../../../mediaQueries'
import CheckMark from '../../../assets/icons/check-mark-small.svg'
import { PrimaryButton, SecondaryButton } from '../../Buttons/style/index'
import { LinkStyles } from '../../Links/style'

const fadeIn = keyframes`
  from {
    top: 0px;
    opacity: 0;
  }
  to {
    top: 75px;
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    top: 75px;
    opacity: 1;
  }
  to {
    top: 0px;
    opacity: 0;
  }
`

export const GlobalStyle = createGlobalStyle`
  .modal-dialog {
    max-width: none;
  }
  
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-body {
    padding: 0;
  }

  .modal-content {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
    background-color: #25272B;
    border-radius: 5px;
    width: 500px;
    display: flex;
    align-items: flex-start;
    margin: auto;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  
    @media (max-width: 575px) {
      width: 324px;
    }
  
    @media (max-width: 320px) {
      width: 250px;
    }

    & > div {
      display: flex;
      padding-top: 24px;
      width: 100%;
      justify-content: space-between;
      padding-left: 24px;
      padding-right: 24px;

      & > p {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      }

      & img {
        cursor: pointer;
      }
    }

    a {
      text-decoration: underline;
      color: #5FC4B8;
    }

    p {
      margin-bottom: 0 !important;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;

      &.no-bottom-padding {
        padding-bottom: 0;
      }
    }

    ul {
      margin-bottom: 24px;
      margin-top: 12px;
      margin-right: 24px;
    }
  }
`

export const TokensContainer = styled.div`
  display: flex;
  flex-direction: column;

  & .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 48px;
  }
`

const StandardText = `
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
`

export const SectionHeader = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
`

export const NoTokens = styled.p`
  ${StandardText}
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 24px;
`

export const DetailsContainer = styled.div`
  display: flex;
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  & > p {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 0;
  }

  & > div.details {
    display: flex;
    width: 100%;

    ${media.smallMobile`
      flex-direction: column-reverse;
      margin-left: auto;
      width: auto;
    `}

    & .relationship {
      background-color: #25272B;
      border-radius: 5px;
      color: #B1BFC5;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      padding: 3px 12px;
      margin-left: 12px;
      margin-bottom: 0;
    }

    & .expiration {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: right;
      color: #FFFFFF;
      margin-left: auto;
      margin-bottom: 0;

      ${media.smallMobile`
        margin-bottom: 7px;
      `}
    }
  }
`

export const RefundSection = styled.div`
  background-color: #1D1D1F;
  padding: 24px 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  & p {
    margin-bottom: 4px;
  }

  & span {
    color: #5FC4B8;
    cursor: pointer;
    text-decoration: underline;
  }
`

export const RefundContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 88px 103px 0 103px;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;

  ${media.mobile`
      margin-left: 32px;
      margin-right: 32px;
   `}

  ${media.smallMobile`
      margin-left: 24px;
      margin-right: 24px;
   `}

  > div:first-of-type{
    align-self: flex-start;
  }
  
  & p {
    margin-bottom: 0;
  }

  & > div {
    display: flex;
    justify-content: center;
  }

  & div.refund-container {
    display: flex;
    flex-direction: column;
    width: 600px;
    margin-top: 33px;

    ${media.mobile`
      width: 525px;
    `}

    ${media.smallMobile`
      width: 324px;
    `}
  }
`

export const Header = styled.p`
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  color: #FFFFFF;
`

export const BackToLink = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8;
  cursor: pointer;
  &:disabled {
    color: #5fc4b8;
    opacity: 0.3;
    pointer-events: none;
    svg {
      stroke: #5fc4b8;
      pointer-events: none;
      path{
        stroke: #5fc4b8;
      }
    }
  }
  &:hover {
    color: #b8fff7;
    svg {
      stroke: #b8fff7;
      path{
        stroke: #b8fff7;
      }
    }
  }
  &:active {
    color: #b8fff7;
    svg {
      stroke: #b8fff7;
    }
    opacity: 0.3;
  }
  > span {
    margin-left: 16px;
    display: inline-block;
  }
`

export const Instruction = styled.p`
  ${StandardText}
  margin-bottom: ${props => props.marginBottom || '24px'} !important;
  font-weight: ${props => props.fontWeight || 'normal'};
`

export const MissingTokensLink = styled.span`
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #5FC4B8;
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
`

const listItem = `
  & > div.label {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    & > p.refund-expiry {
      color: #98A4AE;
    }
  }
`

export const ConfirmTokensList = styled.ul`
  margin-bottom: 0;

  li {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    ${listItem}
    margin-top: 0px;
  }
`

export const CheckBox = styled.label`
  margin-top: 0;
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: ${props => props.marginBottom || '16px'};
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  ${listItem}

  & > span {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #161618;
    border: 1px solid #5FC4B8;
    box-sizing: border-box;
    border-radius: 3px;
  }

  input:checked ~ span {
    background-color: #5FC4B8;
    box-sizing: border-box;
    border-radius: 3px;
  }

  & > span::after {
    display: none;
    position: absolute;
    content: url(${CheckMark});
  }

  input:checked ~ span::after {
    left: 2px;
    top: -5px;
    display: block;
  }
`

export const Button = styled(PrimaryButton)`
  margin-top: ${({ marginTop }) => marginTop || '36px'};
  width: 120px;
`

export const Snackbar = styled.div`
  visibility: hidden;
  width: 378px;
  margin-right: 24px;
  background-color: #25272B;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 24px;
  position: fixed;
  z-index: 1;
  top: 87px;
  font-size: 16px;
  line-height: 19px;
  right: 0px;

  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    & > img {
      &:last-child {
        width: 12px;
        cursor: pointer;
      }
    }

    & > span {
      margin-left: 12px;
      width: 100%;
      font-weight: bold;
    }
  }

  & > p {
    font-weight: normal;
    margin-bottom: 0;
  }

  &.show {
    visibility: visible;
    -webkit-animation: ${fadeIn} 1s, ${fadeOut} 1s 9s;
    animation: ${fadeIn} 1s, ${fadeOut} 1s 9s;
  }

  @media (max-width: 575px) {
    width: 324px;
  }

  @media (max-width: 320px) {
    width: 250px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 32px;
`

export const LoadMoreButton = styled(SecondaryButton)`
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  margin-top: ${({ marginTop }) => marginTop || '36px'};
  width: ${({ width }) => width || '160px'};;
`

export const LogItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;

  & > div {
    display: flex;

    &:last-child {
      font-size: 18px;
      line-height: 22px;
      font-weight: 300;

      p {
        padding-top: 21px;
      }
    }

    & > p {
      margin-bottom: 0;

      &.count {
        border: 1px solid rgba(255, 255, 255, 0.3);
        box-sizing: border-box;
        border-radius: 5px;
        padding: 2px 11px;
        margin-right: 14px;

        &.minus {
          border: 1px solid #FFFFFF;
        }
      }

      &.label {
        font-size: 18px;
        line-height: 22px;
        margin-right: 7px;
      }

      &.dot {
        margin-right: 7px;
        margin-top: -1px
      }

      &.date {
        color: #B1C3C5;
        margin-top: 3px;
      }
    }

    & > img {
      margin-left: auto;
      cursor: pointer;
    }
  }
`

export const OverviewContainer = styled.div`
  display: flex;
  margin-top: 18px;

  ${media.smallMobile`
    flex-direction: column;
  `}
`

export const Overview = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 24px;
  flex: 1;

  &:last-child {
    margin-left: 12px;

    ${media.smallMobile`
      margin-left: 0;
      margin-top: 12px;
    `}
  }

  & > p {
    margin-bottom: 0;

    &.count {
      font-weight: 300;
      font-size: 64px;
      line-height: 77px;
      color: #FFFFFF;
  
      & span {
        font-weight: 300;
        font-size: 36px;
        line-height: 43px;
      }
    }

    &.title {
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #B1BFC5;
      margin-bottom: 12px;
    }

    &.link {
      ${LinkStyles}
    }
  }
`
