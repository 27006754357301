import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ notEnrolled }) => notEnrolled
      ? 'linear-gradient(180deg, #232327 0%, #000000 100%) !important'
      : 'linear-gradient(180deg, #191631 0%, #000000 100%) !important'
    };
  }
`

export const MainWrapper = styled.div`
  min-height: 100vh;
  margin: 64px;
`

export const MainTitle = styled.h1`
  font-size: 36px;
  font-weight: 300;
  line-height: 48px;
  margin-top: 144px;
  margin-bottom: 0;
  ${({ border }) => border && 'border-bottom: 1px solid rgba(255, 255, 255, 0.3)'};
`

export const SectionTitle = styled.h2`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  margin-top: 32px;
  margin-bottom: 14px;
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: ${({ gap }) => `${gap + 2}px`};
  column-gap: ${({ gap }) => `${gap}px`};
`

export const BackwardLink = styled.div`
  display: flex;
  align-items: center;

  color: #5FC4B8;
  cursor: pointer;

  margin-top: 88px;
  margin-bottom: 24px;
  width: fit-content;

  > p {
    margin-left: 13px;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  > img {
    width: 8px;
    height: 14px;
  }
`

export const PageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: ${({ color }) => color};
  }

  #not-enrolled-text {
    color: #FFFFFF;
    margin-bottom: 8px;
  }

  #not-enrolled-sub-text {
    color: #FFFFFF;
    font-weight: 300;
    margin-bottom: 32px;
  }
`

// MAIN PAGE

export const CertificateContainer = styled.div`
  width: 250px;
  height: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px;
  gap: 8px;

  cursor: pointer;
  background-color: #0E0F11;
  border-radius: 5px;
  border: 1px solid ${({ color }) => color || '#98A4AE'};

  h3 {
    width: 201px;
    height: 48px;
    margin: 0;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  #courses-wrapper {
    width: 201px;
    height: 120px;

    display: flex;
    flex-direction: column;
    align-self: stretch;
    flex-grow: 0;
  }
`

export const CourseNameWrapper = styled.div`
  display: flex;

  span {
    width: 6px;
    height: 6px;
    display: block;
    position: relative;
    top: 9px;
    margin: 0 10px;
    border-radius: 50%;
    background-color: ${({ color }) => color || '#98A4AE'};
  }
`

export const CourseName = styled.p`
  max-width: 175px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: ${({ color }) => color || '#98A4AE'};
`

export const CourseContainer = styled.div`
  width: 198px;
  height: 224px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px 24px;
  gap: 16px;

  background-color: #0E0F11;
  border-radius: 5px;
  border: 1px solid ${({ color }) => color || '#98A4AE'};
  cursor: pointer;

  #course-name {
    width: 150px;
    height: 48px;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    align-self: stretch;
    flex-grow: 0;

    p {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }
  }
`

// CERTIFICATE PAGE

export const CertificateCourseWrapper = styled.div`
  width: 600px;
  height: 100px;

  display: flex;
  margin-bottom: 16px;

  background-color: #0E0F11;
  border-radius: 5px;
  border: 1px solid ${({ color }) => `${color}`};
  ${({ pointer }) => pointer && 'cursor: pointer'};

  font-weight: 400;
  line-height: 24px;

  #icon-wrapper {
    width: 100px;
    padding: 18px 23px;
    display: flex;
    align-items: center;
    border-right: 1px solid ${({ color }) => `${color}`};

    img {
      width: 57px;
    }
  }

  #info-wrapper {
    width: 500px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  #name-wrapper {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    gap: 4px;
    
    #course-name {
      font-size: 18px;
      margin-bottom: 0;
    }

    #course-type {
      font-size: 14px;
      color: #98A4AE;
      margin-bottom: 0;

      ::first-letter {
        text-transform: capitalize;
      }
    }
  }

  #date-text {
    font-size: 16px;
    color: ${({ color }) => `${color}`};
    margin-bottom: 0;
  }
`

export const EnrollButton = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5FC4B8;
  cursor: pointer;
`

export const ViewButton = styled.div`
  padding: 16px 24px;
  margin-bottom: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #5FC4B8;
  border-radius: 5px;

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #161618;
  cursor: pointer;
`
