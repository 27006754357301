import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Toast from '../../../Toast'
import OpenBookSvg from '../../../../assets/icons/open-book.svg'
import {
  getSessionsArray,
  getRecentPastSessions
} from '../../../../utilities/studyBlocks'

function EditStudyBlocksToast ({ studyBlocksData }) {
  const [show, setShow] = useState(false)
  const remove = () => setShow(false)

  useEffect(() => {
    const { createdAt, updatedAt } = studyBlocksData?.metadata || {}
    const studyBlocksLatestUpdate = updatedAt || createdAt
    const savedStudyBlocks = getSessionsArray(studyBlocksData?.studyBlocks)
    const recentPast3Blocks = getRecentPastSessions(savedStudyBlocks, 3)
    const isRecentBlocksMissed = recentPast3Blocks.every(block => {
      const isAfterLatestUpdate = block?.end > studyBlocksLatestUpdate
      return !block?.isAttended && isAfterLatestUpdate
    })

    const shouldShowWarning = isRecentBlocksMissed && recentPast3Blocks?.length === 3
    setShow(shouldShowWarning)
  }, [studyBlocksData])

  const title = 'Adjust your study blocks'
  const toastWrapperStyle = { margin: '24px 64px 0', width: '448px' }
  const getBody = () => (
    <p>
      Looks like you missed three study blocks. That’s alright! You can{' '}
      <a href='#/account/study-blocks' onClick={remove}>
        adjust your study blocks
      </a>{' '}
      to a time that better suits your schedule.
    </p>
  )

  return (
    <Toast
      show={show}
      remove={remove}
      title={title}
      getBody={getBody}
      headerIcon={OpenBookSvg}
      toastWrapperStyle={toastWrapperStyle}
    />
  )
}

EditStudyBlocksToast.propTypes = {
  studyBlocksData: PropTypes.shape({
    studyBlocks: PropTypes.object
  })
}

EditStudyBlocksToast.displayName = 'EditStudyBlocksToast'
export default EditStudyBlocksToast
