import React, { useEffect } from 'react'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import { GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import { Description, Page, PrimaryButton, Title } from './style'
import { COURSE_REGISTRATION_PAGEVIEW, COURSE_REGISTRATION_PAGE_SUBMITTED } from '../../Constants/eventType'
import { isSegmentEventInLS, removeSegmentEventFromLS, setFiredSegmentEventsInLS } from '../../utilities/gguDegreeUtils'

const GetReady = ({ setCurrentPage }) => {
  const { sendEvent } = useSegmentEvents()

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: '2-2'
  }

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    // in case this page is visited from /expected-graduation
    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: '1-2'
    })
    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: '2-1'
    })

    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'Get Ready',
        page_number: '2-2'
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  return (
    <Page alignItems='flex-start'>
      <Title textAlign='left'>
        Now for the fun part: choose your courses!
      </Title>
      <Description>
        If you have any questions during this process, you can schedule a&nbsp;
        call with your Academic Advisor at the end of registration. They're&nbsp;
        here to help you with your course schedule, transfer credits, or prerequisites.
      </Description>
      <Description marginTop='8px'>
        Let's continue with your personal recommendations for the next term.
      </Description>
      <PrimaryButton
        onClick={() => {
          sendEvent({
            eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
            properties: {
              page_name: 'Get Ready',
              page_number: '2-2'
            }
          })
          setCurrentPage(GGU_V2_ENROLLMENT_PAGES.COURSE_QUANTITY)
        }}
      >
        Continue
      </PrimaryButton>
    </Page>
  )
}

GetReady.displayName = 'GetReady'

export default GetReady
