import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  formatDateWithDay,
  convertEasternTimeToLocalTime
} from '../../utilities/dateTime'
import { CardWrapper, CustomLink, ManageCourseLink } from './style'
import { attempts } from '../../utilities/attemptUtils'
import { getLatestCohort } from '../../utilities/course'
import { ENROLLED_STATUSES } from '../../Constants/studentStatus'
import { getGGUCourseStatus } from '../../utilities/gguDegreeUtils'
import { COMPLETED_NOT_REPEATABLE } from '../../Constants/CourseStatus'

export default function AttemptRelatedStatesComponent ({
  history,
  studentCourses,
  studentAttempts,
  showAlternateStates,
  showPretestStates,
  additionalIds,
  isActiveGGUStudent,
  cohortStartDates,
  prospects
}) {
  const [stateType, setStateType] = useState(null)
  const [showGGUCatalogLink, setShowGGUCatalogLink] = useState(false)
  const [cohortEndDate, setCohortEndDate] = useState()
  const [cohortDropDate, setCohortDropDate] = useState()
  const assignedCourse = studentCourses?.find(course => additionalIds.includes(course.id))

  useEffect(() => {
    if (!studentCourses) return

    const attemptsObj = attempts(additionalIds, studentAttempts)

    if (isActiveGGUStudent) {
      const gguCourseStatus = getGGUCourseStatus({
        studentCourses,
        studentAttempts,
        uuid: additionalIds?.[0],
        prospects
      })
      if (gguCourseStatus === COMPLETED_NOT_REPEATABLE) {
        showAlternateStates()
        setShowGGUCatalogLink(true)
        return setStateType('type-3')
      }
    }
    const { completedAttempts } = attemptsObj || {}

    if (completedAttempts?.length >= 3) {
      showAlternateStates()
      return setStateType('type-3')
    }

    const latestCohort = assignedCourse && getLatestCohort(assignedCourse)
    const { studentStatus, finalDropDate, finalExamEndTime } = latestCohort || {}

    if (!ENROLLED_STATUSES.includes(studentStatus)) return

    showAlternateStates()
    const now = new Date()
    const dropDateET = convertEasternTimeToLocalTime(finalDropDate)
    const dropDate = dropDateET?.toISOString()

    if (dropDateET?.getTime() > now.getTime() && !isActiveGGUStudent) {
      setStateType('type-2')
      return setCohortDropDate(dropDate)
    }

    setStateType('type-1')
    setCohortEndDate(finalExamEndTime)
    // eslint-disable-next-line
  }, [studentAttempts, cohortStartDates, additionalIds?.length, studentCourses])

  if (!stateType) return null

  const { displayName } = assignedCourse || {}

  return (
    <CardWrapper
      showPretestStates={showPretestStates}
    >
      {stateType === 'type-1' && (
        <>
          <h2>You're currently taking this course</h2>
          <p>
            Sign up for a future cohort when your current cohort ends on{' '}
            <span>{formatDateWithDay(cohortEndDate)}</span>.
          </p>
        </>
      )}
      {stateType === 'type-2' && (
        <>
          <h2>You're currently taking this course</h2>
          <p>
            You have until <span>{formatDateWithDay(cohortDropDate)}</span> to
            drop your active cohort or transfer to a new start date.
          </p>
          <ManageCourseLink
            onClick={() => {
              history.push({
                pathname: `/account/manage-courses/${displayName}`,
                state: {
                  linkLabel: displayName,
                  origin: window.location.hash.slice(1)
                }
              })
            }}
          >
            Manage Course
          </ManageCourseLink>
        </>
      )}
      {stateType === 'type-3' && (
        <>
          <h2>Course Completed</h2>
          <p>
            We admire your dedication, but you have repeated this course the
            maximum number of times.
          </p>
          {showGGUCatalogLink ? (
            <p>
              Learn more in the "Course Repeat" section of our{' '}
              <CustomLink
                href='https://catalog.ggu.edu/content.php?catoid=11&navoid=556&hl=attempt&returnto=search'
                rel='noopener noreferrer'
                target='_blank'
              >
                GGU Catalog
              </CustomLink>.
            </p>
          )
            : (
              <p>
                Learn more in the Enrollment section of the{' '}
                <button>Refund Policy</button>.
              </p>
            )}
        </>
      )}
    </CardWrapper>
  )
}

AttemptRelatedStatesComponent.propTypes = {
  studentCourses: PropTypes.arrayOf(PropTypes.object),
  studentAttempts: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  showAlternateStates: PropTypes.func,
  additionalIds: PropTypes.arrayOf(PropTypes.string)
}

AttemptRelatedStatesComponent.displayName = 'AttemptRelatedStatesComponent'
