import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Link, CustomButton } from './style'

const AddTokensButton = ({
  label = 'add tokens',
  availableTokens,
  coursesCount,
  tokensCount,
  backToPageName,
  redirectToPath,
  onBackToClick,
  type = 'link'
}) => {
  const history = useHistory()

  const buttonClick = () => {
    history.push({
      pathname: '/add-tokens',
      state: {
        availableTokens,
        coursesCount,
        tokensCount,
        backToPageName,
        redirectToPath,
        onBackToClick
      }
    })
  }
  const Button = type === 'button' ? CustomButton : Link
  return (
    <Button
      onClick={buttonClick}
    >
      {label}
    </Button>
  )
}

AddTokensButton.propTypes = {
  label: PropTypes.string,
  availableTokens: PropTypes.number,
  coursesCount: PropTypes.number,
  tokensCount: PropTypes.number,
  backToPageName: PropTypes.string,
  redirectToPath: PropTypes.string,
  onBackToClick: PropTypes.func
}

AddTokensButton.displayName = 'AddTokensButton'

export default AddTokensButton
