// access to the course
export const SIGNED_UP = 'Signed Up'
export const PRE_DROP = 'PreDrop'
export const ENROLLED = 'Enrolled'
export const COMPLETED = 'Completed'
export const INCOMPLETE = 'Incomplete'
export const A = 'A'
export const A_MINUS = 'A-'
export const B_PLUS = 'B+'
export const B = 'B'
export const B_MINUS = 'B-'
export const C_PLUS = 'C+'
export const C = 'C'
export const C_MINUS = 'C-'
export const D = 'D'
export const F = 'F'

// no access to any assessments
export const AUDIT = 'Audit'
export const WITHDRAW = 'Withdraw'
export const AUDIT_TRANSCRIPT = 'Audit Transcript'

// no access to the course
export const MELT = 'Melt'
export const DROP = 'Drop'
export const ADMINISTRATIVE_DROP = 'Administrative Drop'
export const NEEDS_REVIEWING = 'Needs Reviewing'
export const NEEDS_REVIEWING_DEGREE = 'Needs Reviewing - Degree'
export const UNOFFICIAL_WITHDRAW = 'Unofficial Withdraw'

export const ENROLLED_STATUSES = [SIGNED_UP, PRE_DROP, ENROLLED]
export const ACTIVE_STATUSES = [SIGNED_UP, PRE_DROP, AUDIT, AUDIT_TRANSCRIPT, ENROLLED]
