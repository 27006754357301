import React, { useContext } from 'react'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  Heading,
  DegreesPlusContainer,
  DegreesPlusItem
} from './DegreesPlus.styled'
import VirtualCampus from '../../assets/icons/virtual-campus.svg'
import GGUResources from '../../assets/icons/ggu-resources.svg'
import PlusCircle from '../../assets/icons/plus-circle-grey.svg'

const DEGREES_PLUS_DATA = [
  {
    title: 'virtual campus',
    link: 'https://discord.gg/XsAb4k7FBd',
    icon: VirtualCampus
  },
  {
    title: 'ggu student resources',
    link: '#contact',
    icon: GGUResources
  },
  {
    title: 'degrees+ orientation',
    link: '#contact?section=orientation',
    icon: PlusCircle
  }
]

const DegreesPlus = () => {
  const { isActiveGGUStudent, isContextLoading, isUnder18 } = useContext(AppContext) || {}

  if (isContextLoading || !isActiveGGUStudent) return null

  return (
    <>
      <Heading>degrees +</Heading>
      <DegreesPlusContainer>
        {
          DEGREES_PLUS_DATA.map((data, index) => {
            const { title, link, icon } = data

            if (isUnder18 && title === 'virtual campus') return null

            return (
              <DegreesPlusItem key={`${title.replace(/ /g, '') || ''}_${index}`}>
                <img src={icon} alt={title} />
                {
                  link
                    ? <a href={link}>{title}</a>
                    : <p>{title}</p>
                }
              </DegreesPlusItem>
            )
          })
        }
      </DegreesPlusContainer>
    </>
  )
}

DegreesPlus.displayName = 'DegreesPlus'

export default DegreesPlus
