import styled from 'styled-components'

export const HeaderContents = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
`

export const HeaderBreadCrumb = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  margin: 0;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: default;
  color: ${({ showCaret }) =>
          showCaret ? '#5FC4B8' : '#ffffff'};
  ${({ showCaret }) =>
    showCaret &&
    `
  cursor: pointer;
  color: #5FC4B8;
  &::before {
    content: url('images/icons/icon-hd-caret.svg');
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
  &:hover {
    filter: opacity(0.5);
  }
  `}
`

export const CloseSideDrawerIcon = styled.img`
  width: 14px;
  cursor: pointer;
  height: 14px;
  &:hover {
    filter: opacity(0.5);
  }
`

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: #24272b; 
  z-index: 9;
  border-bottom: ${({ borderSeparated }) =>
    borderSeparated ? '1px solid rgb(255 255 255/10%)' : 'none'};
`
