import styled, { css } from 'styled-components'
import media from '../../../mediaQueries'
import { ReactComponent as UserSVG } from '../../../assets/icons/user.svg'
import { ReactComponent as SecuritySVG } from '../../../assets/icons/security.svg'
import { ReactComponent as CertificateSVG } from '../../../assets/icons/certificate.svg'
import { ReactComponent as CoinsSVG } from '../../../assets/icons/coins.svg'
import { ReactComponent as CaretSVG } from '../../../assets/icons/caret.svg'
import { ReactComponent as TooltipSVG } from '../../../assets/icons/tooltip-i.svg'
import { ReactComponent as BookSVG } from '../../../assets/icons/book-icon.svg'
import { ReactComponent as CalendarSVG } from '../../../assets/icons/calendar-1.svg'
import { ReactComponent as WalletSVG } from '../../../assets/icons/wallet.svg'
import { ReactComponent as VoucherSVG } from '../../../assets/icons/voucher.svg'
import { ReactComponent as InfoSVG } from '../../../assets/icons/info.svg'
import { ReactComponent as ArrowSVG } from '../../../assets/icons/arrow.svg'
import { ReactComponent as SubscriptionSVG } from '../../../assets/icons/subscription.svg'
import { ReactComponent as SubscriptionWhiteSVG } from '../../../assets/icons/subscription-white.svg'

import { LinkStyles } from '../../Links/style'

const hoverColor = '#B8FFF7'

export const Container = styled.div`
  display: flex;
  margin-bottom: 100px;

  ${media.tablet`
    flex-direction: column;
  `}

  ${media.mobile`
    margin: 0 32px;
  `}

  ${media.smallMobile`
    margin: 0 12px;
  `}
`

// NAVIGATION

// Sidebar

export const SidebarContainer = styled.div`
  min-width: 367px;
  padding-left: 103px;

  ${media.tablet`
    width: 100%;
  `}

  ${media.mobile`
    padding-left: 12px;
  `}
`

export const Header = styled.h1`
  color: #FFF;
  font-size: 36px;
  font-weight: 300;
  line-height: 43px;
  font-style: normal;

  margin-top: 64px;
  margin-bottom: 57px;

  ${media.tablet`
    margin-bottom: 24px;
  `}
`

export const Sidebar = styled.div`
  ${media.tablet`
    display: none;
  `}
`

export const Button = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: ${props => props.color};
  cursor: pointer;
  margin-bottom: 5px;
  display: block;

  ${props => !props.isActive && `
    :hover {
      color: ${hoverColor};
      svg {
        ${!props.stopHover && `fill: ${hoverColor}`};
      }
    }
    :active {
      color: #b8fff7;
      opacity: 0.3;
    }
  `}
`

export const NewBadge = styled.span`
  background: #30303A;
  border-radius: 3px;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;

  padding:4px 12px 3px;
  margin-left:8px;
  vertical-align: middle;
  position: relative;
  top: -1px;
`

const svgProps = `
  position: relative;
  top: -2.5px;
  margin-right: 12px;
`
export const UserIcon = styled(UserSVG)`
  fill: ${props => props.color};
  ${svgProps}
`

export const VoucherIcon = styled(VoucherSVG)`
  fill: ${props => props.color};
  ${svgProps}
`

export const BookIcon = styled(BookSVG)`
  fill: ${props => props.color};
  ${svgProps}
`

export const CalendarIcon = styled(CalendarSVG)`
  fill: ${props => props.color};
  ${svgProps}
`

export const SubscriptionIcon = styled(SubscriptionSVG)`
  ${svgProps}
`

export const SubscriptionWhiteIcon = styled(SubscriptionWhiteSVG)`
  ${svgProps}
`

export const SecurityIcon = styled(SecuritySVG)`
  fill: ${props => props.color};
  ${svgProps}
`

export const CoinsIcon = styled(CoinsSVG)`
  fill: ${props => props.color};
  ${svgProps}
`

export const CertificateIcon = styled(CertificateSVG)`
  fill: ${props => props.color};
  ${svgProps}
`

export const WalletIcon = styled(WalletSVG)`
  fill: ${props => props.color};
  ${svgProps}
`

export const CaretIcon = styled(CaretSVG)`
`

export const ArrowIcon = styled(ArrowSVG)`
  fill: ${props => props.color};
`

export const TooltipIcon = styled(TooltipSVG)`
`

// Dropdown

export const DropdownContainer = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  @media (min-width: 991px) {
    display: none;
  }

  ${media.tablet`
    display: block;
    width: 288px;
  `}

  ${media.smallMobile`
    width: 100%;
    padding-right: 12px;
  `}
`

export const DropdownButton = styled.div`
  background: #25272B;
  padding: 10px 16px 10px 13px;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
`

export const DropdownMenu = styled.ul`
  background: #25272B;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  padding: 12px;
  border-radius: 5px;

  position: absolute;
  top: 172px;
  left: 103px;
  z-index: 1;
  width: 288px;

  ${media.mobile`
    left: 12px;
  `}

  ${media.smallMobile`
    width: calc(100% - 24px);
  `}
`

export const DropdownItem = styled.li`
  list-style: none;
  cursor: pointer;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 6px;
  :hover {
    background: rgba(255, 255, 255, 0.07);
  }
  svg {
    position: relative;
    top: .2px;
  }
`

// BUTTONS

export const TransparentButton = styled.button`
  height: 17px;
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;

  ${LinkStyles}

  ${({ hideOnMob }) => hideOnMob && media.smallMobile`
    display: none;
  `}

  ${({ showOnMob }) => showOnMob && `
    display: none;
  `}

  ${({ showOnMob }) => showOnMob && media.smallMobile`
    display: block;
    top: auto;
    margin-top: 6px;
  `}
`

export const MainButtonStyles = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  :focus {
    outline: none;
  }

  &:active {
    opacity: 0.3;
  }
`

export const PrimaryButton = styled.button`
  color: #161618;
  background-color: #5FC4B8;
  border: none;
  width: 86px;
  height: 48px;

  :hover {
    background-color: ${hoverColor};
  }

  &:disabled {
    background-color: rgba(255, 255, 255, 0.3);
    pointer-events: none;
  }

  ${MainButtonStyles}
`

export const SecondaryButton = styled.button`
  color: #5FC4B8;
  border: 1px solid #5FC4B8;
  background: transparent;
  height: 48px;

  :hover {
    color: ${hoverColor};
    border-color: ${hoverColor};
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.3);
    border-color: rgba(255, 255, 255, 0.3);
    pointer-events: none;
  }

  ${MainButtonStyles}
`

// PAGE

export const PageContainer = styled.div`
  flex-grow: 1;
  margin-top: 142px;
  margin-right: 103px;

  ${media.tablet`
    margin-top: 24px;
    margin-left: 103px;
  `}

  ${media.mobile`
    margin-left: 12px;
    margin-right: 12px;
  `}
`

export const ItemContainer = styled.div`
  opacity: ${props => props.opacity};
`

export const ItemContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ItemContentArea = styled.span`
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
`

export const ItemTitle = styled.div`
  display: inline-block;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  margin-top: 24px;
  margin-bottom: 6px;
`

export const ItemTooltip = styled(TooltipIcon)`
  display: inline-block;
  margin-left: 13px;
  position: relative;
  top: -1px;
`

export const ItemContent = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  min-height: 22px;
  margin-bottom: ${props => props.marginBottom || '25px'};
`

export const EditButton = styled(TransparentButton)`
  position: relative;
  top: 29px;
`

// Edit

export const EditSection = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #B1BFC5;
`

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 6px;
`

export const InputField = styled.input`
  width: 351px;
  height: 40px;
  padding: 0px 10px;
  color: #fff;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  border: ${props => props.hasError
    ? '1px solid #E1774F' : '1px solid rgba(255, 255, 255, 0.3)'
  };
  border-radius: 5px;
  margin-bottom: ${props => props.hasError ? '6px' : '12px'};

  &:focus {
    outline: none;
    border: ${props => props.hasError
      ? '1px solid #E1774F' : '1px solid #5FC4B8'
    };
  }

  ${media.smallMobile`
    width: 100%;
  `}
`

export const SaveButton = styled(PrimaryButton)`
  height: 40px;
  width: 120px;
  margin-top: 12px;
  margin-bottom: 25px;
  font-weight: bold;
`

// Transcripts

export const CustomTitle = styled.h2`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  margin-top: 24px;
  margin-bottom: 0;
`

export const ContinueButton = styled(EditButton)`
`

export const TranscriptContainer = styled.div`
  margin-bottom: 25px;
`

export const TranscriptItem = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #B1BFC5;
  margin-bottom: 12px;
  display: flex;
  align-items: center;

  p {
    display: inline-block;
    margin-bottom: 0;
  }

  span {
    display: inline-block;
    margin-right: 9px;
    width: 2px;
    height: 2px;
    background: #B1BFC5;
    border-radius: 50%;
  }
`

export const EditTitle = styled.h3`
  color: #fff;
  font-size: 18px;
  margin-bottom: 6px;
`

export const ErrorMessage = styled.p`
  color: #E1774F;
  margin-bottom: ${props => props.lastItem ? '24px' : '12px'};
`

export const CourseItemContainer = styled.div`
 padding-bottom: 22px;
 padding-top: 24px;
 border-bottom: 1px solid rgba(255, 255, 255, 0.3);

 img {
   width: 20px;
   height: 20px;
 }
`

export const CourseItemHeader = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;

 > button {
   position: relative;
   top: auto;
 }

 >div {
   display: flex;
   align-items: center;
   font-size: 18px;
   font-style: normal;
   font-weight: 400;
   line-height: 22px;

   > img {
     filter: brightness(0) invert(1);
   }

   > span {
     margin-left: 12px;
   }
 }
`
export const CourseItemDescription = styled.div`
  > p {
    font-size: 16px;
    font-weight: 400;
    color: #B1BFC5;
    line-height: 19px;
    margin:0;

    > span {
      > img {
        width: 2px;
        height: 2px;
      }
    }
  }

  display: flex;
  justify-content: space-between;
  margin-top: 4px;

  ${media.mobile`
  display:block;

  > p {
    margin-top: 6px;
  }
  `}
`

export const StyledCourseStatusText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #B1BFC5;
  line-height: 19px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const StyledDot = styled.img`
  width: 2px !important;
  height: 2px !important;
  margin-left: 6px;
  margin-right: 6px;
`

export const NoCoursesText = styled.div`
   font-weight: 300;
   font-size: 18px;
   display: inline-block;
`

export const Link = styled.h3`
  display: inline-block;
  margin-left: 12px;
  ${LinkStyles}
`

// Amazon refund page

export const MainText = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin-top: 24px;
`
export const CustomLink = styled.a`
  color: #5FC4B8 !important;
  border-bottom: 1px solid #5FC4B8;

  :hover {
    text-decoration: none;
  }
`

export const BackLink = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #5FC4B8;
  text-transform: uppercase;
  cursor: pointer;

  .back-arrow {
    margin-right: 12px;
  }
`

export const TranscriptItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;

  & h2 {
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 24px;
  }

  & h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-right: 0;
    margin-bottom: 0;
  }

  & p {
    &.description {
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  & div {
    &.start-transcript {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;

      & img {
        cursor: pointer;
      }

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:first-child {
          flex: 1;
        }

        & p {
          margin-bottom: 0;

          &.link {
            span { margin-right: 8px; }
            ${LinkStyles}
          }
        }
      }
    }
  }
`

export const TranscriptListItem = styled.div`
  border-left: 1px solid #5FC4B8;
  padding-left: 12px;

  & div {
    &.item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      & p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        color: #B4BFC4;

        &:first-child {
          flex: 1;
        }

        &:last-child {
          color: #98A4AE;
        }
      }
    }
  }
`

export const Info = styled.div`
  cursor: pointer;
  display: inherit;

  & .tooltiptext {
    display: none;
    width: 255px;
    background-color: #25272B;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    border-radius: 5px;
    padding: 12px;
    position: absolute;
    z-index: 1;
    font-size: 16px;
    line-height: 19px;
    margin-left: 25px;
    margin-top: -30px;
  }

  &:hover {
    & .tooltiptext {
      display: block;
    }
  }
`

export const InfoIcon = styled(InfoSVG)`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-top: 3px;
`

export const GGUPortalLogin = styled.p`
  ${media.mobile`width: 165px;`}
`
