import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import React from 'react'
import {
  DayContainer,
  DayHeader,
  Session,
  SessionIcon,
  SessionTime,
  SessionsContainer,
  TodayText,
  WeekDay
} from './styles'
import { getBlockTime } from '../../StudyBlocksDisplay/utils'
import { getBlockIcon } from './utils'

function StudyBlocksSlide ({ blocksForNextFiveDays }) {
  return (
    <Splide
      aria-label='Study Blocks Section Slide'
      hasTrack={false}
      options={{
        type: 'slide',
        pagination: false,
        arrows: false,
        gap: '8px',
        autoWidth: true,
        focus: 0,
        omitEnd: true,
        padding: 'var(--mobile-layout-padding)',
        start: 1
      }}
    >
      <SplideTrack>
        {blocksForNextFiveDays?.map((day, dayIndex) => {
          const { date, blocks } = day
          // second card will always be today
          const isToday = dayIndex === 1

          return (
            <SplideSlide key={date}>
              <DayContainer isToday={isToday} data-testid='study-blocks-day'>
                <DayHeader>
                  <WeekDay>{date.split(' ')[0]}</WeekDay>
                  {isToday && <TodayText>TODAY</TodayText>}
                </DayHeader>
                {blocks && (
                  <SessionsContainer>
                    {blocks.map((block) => {
                      const blockTime = getBlockTime(block)
                      const { icon, isActive } = getBlockIcon(date, block)

                      return (
                        <Session key={block.id}>
                          <SessionIcon>{icon}</SessionIcon>
                          <SessionTime isActive={isActive}>
                            {blockTime}
                          </SessionTime>
                        </Session>
                      )
                    })}
                  </SessionsContainer>
                )}
              </DayContainer>
            </SplideSlide>
          )
        })}
      </SplideTrack>
    </Splide>
  )
}

StudyBlocksSlide.displayName = 'StudyBlocksSlide'
export default StudyBlocksSlide
