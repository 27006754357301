import React, { useContext } from 'react'
import { DEGREE_REGISTRATION_INCOMPLETE } from '../../Constants'
import { getDateStringWithYear } from '../../utilities/dateTime'
import { AppContext } from '../ContextProvider/ContextProvider'
import { BannerContainer, Message } from './styles'

const DegreeRegistrationBanner = () => {
  const {
    isContextLoading,
    isGGUDegreeDataLoading,
    currentSemester,
    studentData,
    showGGUEnrollment,
    quitGGUEnrolment,
    updateContext
  } = useContext(AppContext) || {}

  if (isContextLoading || isGGUDegreeDataLoading) return null
  if (!studentData?.[DEGREE_REGISTRATION_INCOMPLETE]) return null
  if (!showGGUEnrollment) return null
  if (!quitGGUEnrolment) return null

  const closeEnrollmentDate = currentSemester?.closeEnrollment
    ? getDateStringWithYear(currentSemester?.closeEnrollment)
    : ''

  const continueEnrollment = () => {
    updateContext({ quitGGUEnrolment: false })
  }

  return (
    <BannerContainer>
      <Message>
          ACTION REQUIRED! You must enroll in courses for next term by {closeEnrollmentDate}.&nbsp;
        <span onClick={continueEnrollment}>enroll now</span>
      </Message>
    </BannerContainer>
  )
}

DegreeRegistrationBanner.displayName = 'DegreeRegistrationBanner'

export default DegreeRegistrationBanner
