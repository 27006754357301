import config from '../config'
import qs from 'qs'
import { STUDENT_REFUNDABLE } from '../Constants/tokens'
import {
  convertEasternTimeToLocalTime,
  dateToSecondsSinceEpoch,
  getDateStringWithYear,
  secondsSinceEpoch
} from './dateTime'
import {
  AMAZON_RELATIONSHIP,
  GGU_RELATIONSHIP
} from '../Constants/relationships'

export const isTokenActive = token => {
  const {
    usedStatus,
    tokenExpirationDate
  } = token
  if (usedStatus || !tokenExpirationDate) return false

  const expiryDate = dateToSecondsSinceEpoch(new Date(tokenExpirationDate))
  const currentDate = secondsSinceEpoch()
  return currentDate < expiryDate
}

export const getActiveTokens = tokens => {
  if (!tokens || !tokens.length) return []

  return tokens
    .filter(token => isTokenActive(token))?.map(token => {
      return {
        ...token,
        at_id: token.id,
        id: token.tokenNumber
      }
    })
}

export const getTokenDatesFormatted = (token = {}) => {
  const {
    tokenIssueDate,
    tokenExpirationDate
  } = token

  return {
    tokenIssueDate: getDateStringWithYear(tokenIssueDate),
    tokenExpirationDate: getDateStringWithYear(tokenExpirationDate)
  }
}

export const getRefundExpiryDate = (
  tokenIssueDate,
  refundEligibilityWindow
) => {
  if (!tokenIssueDate || isNaN(refundEligibilityWindow)) return null

  const issueDate = new Date(
    new Date(tokenIssueDate).setHours(0, 0, 0, 0)
  )
  if (issueDate.toString() === 'Invalid Date') return null

  const expiryDate = new Date(
    issueDate.setDate(issueDate.getDate() + refundEligibilityWindow + 1)
  )
  if (expiryDate.toString() === 'Invalid Date') return null

  return expiryDate
}

export const getTokensAndCertificates = tokens => {
  if (!tokens?.length) return

  const certificateTokens = tokens.reduce((acc, token) => {
    const { certificate, originalCertificate } = token
    if (!certificate || !originalCertificate) {
      acc.push(token)
      return acc
    }

    const certificateIndex = acc.findIndex(currentCertificate => {
      return originalCertificate.id === currentCertificate.id
    })
    if (certificateIndex === -1) {
      acc.push({ ...originalCertificate, tokens: [token] })
      return acc
    }

    acc[certificateIndex].tokens.push(token)
    return acc
  }, [])

  return certificateTokens
}

export const isTokenRefundable = token => {
  const {
    refundability,
    tokenIssueDate,
    refundEligibilityWindow,
    relationship
  } = token
  const isStudentRefundable = refundability === STUDENT_REFUNDABLE &&
    relationship?.[0]?.fields?.relationshipName !== AMAZON_RELATIONSHIP &&
    relationship?.[0]?.fields?.relationshipName !== GGU_RELATIONSHIP

  if (!tokenIssueDate || isNaN(refundEligibilityWindow)) return isStudentRefundable

  const expiryByIssueDate = getRefundExpiryDate(
    tokenIssueDate,
    refundEligibilityWindow
  )
  if (!expiryByIssueDate) return isStudentRefundable

  const expiryDate = dateToSecondsSinceEpoch(expiryByIssueDate)
  const currentDate = secondsSinceEpoch()
  return isStudentRefundable && currentDate <= expiryDate
}

export const getRefundableTokens = (tokens) => {
  if (!tokens?.length) return []

  const certificatesAndTokens = getTokensAndCertificates(tokens)
  const refundableTokens = certificatesAndTokens.filter(value => {
    const { tokens } = value
    if (!tokens) {
      if (!isTokenActive(value)) return false
      return isTokenRefundable(value)
    }

    const refundableCertificateTokens = tokens?.filter(token => {
      if (!isTokenActive(token)) return false
      return isTokenRefundable(token)
    })

    return refundableCertificateTokens?.length === tokens?.length
  })

  return refundableTokens
}

export function getNonRegularTokens (tokens) {
  if (!tokens?.length) return []
  const filteredTokens = tokens.filter(token => {
    return !!token?.relationship?.length ||
      !!token.purchases?.[0]?.fields?.certificateName?.length
  })
  return filteredTokens
}

export function getGroupedRelationshipTokens (tokens) {
  if (!tokens?.length) return {}
  return tokens.reduce((acc, token) => {
    const {
      originalCertificate: { name, id } = {},
      relationship,
      purchases
    } = token || {}

    const certificateId = purchases?.[0]?.fields?.certificateName?.[0] || ''
    const certificateName = id === certificateId ? name : null
    const key = relationship?.[0]?.fields?.relationshipName || certificateName

    if (!key) return acc

    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(token.id)
    return acc
  }, {})
}

export const getActivityLogs = tokens => {
  const activityLogs = tokens.reduce((previousValue, token) => {
    return formatActivityLogs(previousValue, token.activity)
  }, [])

  return activityLogs.sort((a, b) => {
    return b.timestamp - a.timestamp
  })
}

export const formatActivityLogs = (previousValue, activity) => {
  if (!activity?.length) return previousValue

  return activity.reduce((acc, log) => {
    const {
      timestamp,
      event,
      desc,
      changelog: { refundValue = 0 }
    } = log
    const dateString = getDateStringWithYear(timestamp)

    const existingLog = acc.find(log => {
      const { date, event: currentEvent } = log
      return date === dateString && event === currentEvent
    })

    if (existingLog) {
      existingLog.description = getEventText(
        { description: desc, refundValue, event }, existingLog
      )
      existingLog.count++
    } else {
      acc.push({
        refundValue,
        timestamp,
        date: dateString,
        event,
        description: getEventText({ event, description: desc, refundValue }),
        count: 1
      })
    }

    return acc
  }, previousValue)
}

export const getEventText = (newLog, existingLog = {}) => {
  const { description = '' } = existingLog
  const { event, description: newDescription, refundValue } = newLog

  switch (event.toLowerCase()) {
    case 'token added':
    case 'token expired':
      return newDescription
    case 'token used': {
      const suffix = ' added to schedule'
      const currentDescription = description.replace(suffix, '')
      return `${currentDescription ? currentDescription + ', ' : ''}${newDescription.replace(suffix, '')}${suffix}`
    }
    case 'token returned':
    case 'token pending refund': {
      const currentPrice = getPriceFromDescription(description)
      return `Total return value: $${currentPrice + Number(refundValue)}`
    }
    default:
      return newDescription
  }
}

export const getPriceFromDescription = description => {
  if (!description) return 0

  const matchRegex = /\d+(.\d+)?/g
  const price = description.match(matchRegex, '')
  if (!price?.length) return 0

  return Number(price[0])
}

export const getCheckoutUrl = checkoutParams => {
  const { variantId } = checkoutParams

  if (!variantId) return null

  const { email, firstName, lastName, tokensCount, redirectToPath } = checkoutParams
  const query = {
    'checkout[email]': email,
    'checkout[shipping_address][first_name]': firstName,
    'checkout[shipping_address][last_name]': lastName,
    'attributes[redirectTo]': redirectToPath
  }
  const queryString = qs.stringify(query, { encodeValuesOnly: true })

  return `${config.shopifyUrl}cart/${variantId}:${tokensCount}?${queryString}`
}

export const isAfterTokensLaunch = (attempt, tokens) => {
  const { createdAt: date, attemptID, hasPurchaseRecord } = attempt || {}
  if (hasPurchaseRecord) return false

  const linkedToken = Array.isArray(tokens) && !!tokens.find(token => {
    const { usedAttempt } = token
    const attempt = usedAttempt?.[0]?.fields || {}
    return attempt.attemptID === attemptID
  })

  if (linkedToken) return true

  if (!date) return true

  const launchDateInET = convertEasternTimeToLocalTime(
    config.tokensLaunchDate, 'T12:00:00'
  )
  return new Date(date) >= launchDateInET
}

export const getFilteredTokens = tokens => {
  if (!tokens?.length) return []

  return tokens.filter((token) => {
    const relationship = token?.relationship?.[0]
    return ![AMAZON_RELATIONSHIP, GGU_RELATIONSHIP].includes(
      relationship?.fields?.relationshipName
    )
  }
  )
}
