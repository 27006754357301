import React from 'react'
import {
  Container,
  Header,
  Content
} from './styles'
import { PrimaryButton } from '../Buttons/style'
import { PERMISSION_REQUIRED } from './permissionStates'

const AwaitingPermission = ({
  guardianEmail,
  setEmail,
  setPermissionState
}) => {
  const resendEmail = () => {
    setEmail('')
    setPermissionState(PERMISSION_REQUIRED)
  }

  return (
    <Container>
      <div className='content'>
        <Header marginBottom='20px'>Awaiting permission</Header>
        <Content>
          It looks like your parent/guardian has not agreed to our Terms of
          Service and Privacy Policy yet. We reached out to them at
          <span> {guardianEmail}</span> with instructions on how to agree to these for you.
        </Content>
        <Content marginBottom='24px'>
          You can resend the email to them below or reach out to
          <span className='success'> success@outlier.org</span> if
          you need additional help.
        </Content>
        <PrimaryButton
          data-testid='btn-resend-email'
          onClick={resendEmail}
        >
            resend
        </PrimaryButton>
      </div>
    </Container>
  )
}

AwaitingPermission.displayName = 'AwaitingPermission'
export default AwaitingPermission
