import styled, { css } from 'styled-components'
import { Card } from 'react-bootstrap'
import media from '../../../mediaQueries'
import { ActionLink, LinkStyles } from '../../Links/style'
import { SectionTitle } from '../../../styles/text'

export const CardWrapper = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 119px;
  background: #1D1D1F;
  border-radius: 5px;
  margin-top: 12px;
  padding: 24px;
`

export const CardImgWrapper = styled.div`
  position: absolute;
  top: 2%;
  @media (max-width: 767px) {
    position: absolute;
    top: 0%;
  }
`

export const CardImg = styled.img`
  height: 46px;
  width: 46px;
  filter: brightness(0)
          saturate(100%)
          invert(42%)
          sepia(8%)
          saturate(484%)
          hue-rotate(178deg)
          brightness(86%)
          contrast(89%);
  @media (max-width: 767px) {
    height: 24px;
    width: 24px;
  }
`

export const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 17px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const CardTextAndNoteWrapper = styled.div``

export const CardTextWrapper = styled.div`
  position: relative;
  width: 86%;
  @media (max-width: 992px) {
    width: 82%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`

export const CardTitle = styled(ActionLink)`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 70px;
  overflow: hidden;
  display: block;
  @media (max-width: 767px) {
    margin-left: 32px;
  }
  ${({ isTitleUpdates }) => isTitleUpdates && `
      text-transform: none;
      font-weight: 400;
      font-size: 18px;
      font-style: normal;
  `}
  ${({ disabled }) => disabled && `
    pointer-events: none !important;
    color: #FFFFFF !important;
    `}
`

export const CardHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const CardDescription = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  width: 100%;
  margin-left: 70px;
  margin-top: 12px;
  padding-right: 24px;
  @media (max-width: 767px) {
    margin-left: 0px;
    margin-top: 15px;
    padding-right: 0px;
  }
  a, span {
    color: #5fc4b8;
    text-underline-offset: 4.5px;
    font-weight: normal;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: #b8fff7;
    }
    &:active {
      color: #b8fff7;
      opacity: 0.3;
    }
  }
`

export const CardNote = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  margin-left: 70px;
  margin-top: 5px;
  @media (max-width: 767px) {
    margin-left: 0px;
  }
`

export const CardButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${media.mobile`
    margin-top: 24px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  `}
`

export const CardLeftButton = styled.div`
  min-width: 120px;
  ${props => props.margin && css`
    margin-right: 12px;
  `}
  ${media.mobile`
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
  `}
`

export const CardRightButton = styled.div`
  min-width: 120px;
  ${media.mobile`
    width: 100%;
  `}
`

export const CrossImg = styled.img`
  position: absolute;
  right: 24px;
  height: 24px;
  width: 24px;
  cursor: pointer;
`
export const CourseIsNewTag = styled.div`
  width: 50px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #30303A;
  border-radius: 3px;
  margin-left: 12px;

  ::before {
    content: 'NEW';
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`

export const ExamLink = styled.span`
  ${props => props.disable && css`
    pointer-events: none;
  `}
`

export const ExtensionRowsWrapper = styled.div`
  &:not(:empty) {
    margin-top: 24px;
  }
`

export const WhatsNextHeading = styled(SectionTitle)`
  margin-bottom: 24px;
`

export const HideLink = styled.p`
  text-underline-offset: 3.5px;
  font-weight: normal;
  text-decoration: underline;
  font-size: 18px;
  line-height: 22px;
  word-wrap: break-word;
  margin-left: 70px;
  margin-top: 12px;
  display: block;
  margin-bottom: 0px;
  cursor: pointer;
  width: 103px;
  ${LinkStyles}
`
