import React, { useEffect, useRef, useState } from 'react'
import api from '../../api'
import CaretIcon from '../../assets/icons/caret.svg'
import { SectionTitle } from '../../styles/text'
import {
  DisplayWrapper,
  DisplayHeader,
  CustomCollapse,
  TextCollapse,
  WeekWrapper,
  DayWrapper,
  SessionsWrapper,
  SessionContainer
} from './style'
import {
  getBlockIcon,
  getBlockTime,
  getDayDatesInfo,
  getDaysAndBlocks,
  getStudyBlocksMessage
} from './utils'
import { getHoursFromDate } from '../../utilities/dateTime'
import { getActiveDay, getSessionsArray } from '../../utilities/studyBlocks'

function StudyBlocksDisplay () {
  const displayCount = useRef(0)
  const [isOpen, setIsOpen] = useState(true)

  const [savedStudyBlocks, setSavedStudyBlocks] = useState([])
  const daysAndBlocks = getDaysAndBlocks(savedStudyBlocks)
  const studyBlocksMessage = getStudyBlocksMessage(savedStudyBlocks, daysAndBlocks)

  useEffect(() => {
    const getSavedStudyBlocks = async () => {
      const { studyBlocks } = await api.getStudyBlocks()
      setSavedStudyBlocks(getSessionsArray(studyBlocks))
    }

    getSavedStudyBlocks()
  }, [])

  return (
    <DisplayWrapper>
      <DisplayHeader open={isOpen}>
        <SectionTitle>Study blocks</SectionTitle>
        <a href='#/account/study-blocks'>edit</a>
        <div
          data-testid='toggle-collapse'
          onClick={() => setIsOpen(!isOpen)}
          aria-controls='study-blocks-collapse'
        >
          <img
            src={CaretIcon}
            alt='toggle collapse'
          />
        </div>
      </DisplayHeader>

      {!savedStudyBlocks?.length && isOpen && (
        <TextCollapse in={isOpen}>
          <p>
            Add your study blocks to help you stay on track with the coursework.
          </p>
        </TextCollapse>
      )}

      {!!savedStudyBlocks?.length && isOpen && (
        <CustomCollapse in={isOpen}>
          <div id='study-blocks-collapse'>
            <WeekWrapper>
              {daysAndBlocks.map((day, dayIndex) => {
                if (dayIndex === 0) displayCount.current = 0

                const {
                  isToday, isYesterday, isTomorrow, dayLong
                } = getDayDatesInfo(day?.date)
                const title = isToday ? 'today' : isYesterday ? 'yesterday'
                  : isTomorrow ? 'tomorrow' : dayLong
                const isNotLatestDay = [false, true]
                  .includes(daysAndBlocks[dayIndex + 1]?.blocks?.[0]?.isAttended)
                const activeDay = getActiveDay(savedStudyBlocks)
                const isActiveDay = day?.date === activeDay

                if (
                  (isNotLatestDay && !day.isLastDays) || displayCount.current >= 4
                ) return null

                return (
                  <DayWrapper
                    key={day.date}
                    isActiveDay={isActiveDay}
                    isFirst={displayCount.current === 0}
                    separator={displayCount.current < 3}
                  >
                    <h3>{title}</h3>

                    <SessionsWrapper>
                      {day?.blocks?.map((block, blockIndex) => {
                        const currentHours = getHoursFromDate(new Date())

                        const blockTime = getBlockTime(block)
                        const isActive = isToday && block?.start <= currentHours &&
                          block?.end >= currentHours
                        const isPast = !isActive && [false, true].includes(block?.isAttended)

                        const isNotTheLatestBlock =
                          [false, true].includes(day.blocks[blockIndex + 1]?.isAttended) ||
                          [false, true].includes(daysAndBlocks[dayIndex + 1]?.blocks?.[0]?.isAttended)
                        const shouldNotShow =
                          (isNotTheLatestBlock && !block.isLastBlocks) || displayCount.current >= 4

                        if (shouldNotShow) return null
                        displayCount.current++

                        return (
                          <SessionContainer
                            key={block.id}
                            isPast={isPast}
                            isActive={isActive}
                          >
                            {getBlockIcon(day?.date, block)}
                            <p>{blockTime}</p>
                          </SessionContainer>
                        )
                      })}
                    </SessionsWrapper>
                  </DayWrapper>
                )
              })}
            </WeekWrapper>

            <p className='grey'>{studyBlocksMessage}</p>
          </div>
        </CustomCollapse>
      )}
    </DisplayWrapper>
  )
}

StudyBlocksDisplay.displayName = 'StudyBlocksDisplay'
export default StudyBlocksDisplay
