import React from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import {
  CourseCTAContainer,
  CourseProgress,
  CourseProgressContainer,
  CourseTag,
  CourseTile,
  CourseTitle,
  MyCoursesSlideArrows,
  OpeningClosingCountdown
} from './style'
import { getCourseAssets } from '../../../utilities/course'
import WhatsNext from '../WhatsNext/WhatsNext'
import { getCourseCountdown, getCourseProgress, getCourseTag } from '../utils'

function ActiveCoursesSlide ({ coursesToDisplay, catalogCourses }) {
  return (
    <Splide
      aria-label='My Courses Section Slide'
      hasTrack={false}
      options={{
        type: 'slide',
        pagination: false,
        gap: '17px',
        drag: false,
        autoWidth: true,
        slideFocus: true,
        focus: 0,
        omitEnd: true
      }}
    >
      <MyCoursesSlideArrows className='splide__arrows'>
        <button className='splide__arrow splide__arrow--prev' />
        <button className='splide__arrow splide__arrow--next' />
      </MyCoursesSlideArrows>
      <SplideTrack>
        {coursesToDisplay.map((course) => {
          const { id, displayName } = course
          const { courseImage } = getCourseAssets(catalogCourses, id)
          const courseTag = getCourseTag(course)
          const { countdownText, warningType } = getCourseCountdown(course)
          const courseProgress = getCourseProgress(course)

          return (
            <SplideSlide key={id}>
              <CourseTile courseImage={courseImage}>
                {courseTag && (
                  <CourseTag className='course-tag'>{courseTag}</CourseTag>
                )}
                <CourseTitle>{displayName}</CourseTitle>
                {countdownText && (
                  <OpeningClosingCountdown
                    warningType={warningType}
                    className='course-countdown'
                  >
                    {countdownText}
                  </OpeningClosingCountdown>
                )}
                <CourseCTAContainer className='course-ctas'>
                  <WhatsNext course={course} />
                </CourseCTAContainer>
                <CourseProgressContainer className='course-progress'>
                  <CourseProgress courseProgress={courseProgress} />
                </CourseProgressContainer>
              </CourseTile>
            </SplideSlide>
          )
        })}
      </SplideTrack>
    </Splide>
  )
}

ActiveCoursesSlide.displayName = 'ActiveCoursesSlide'
export default ActiveCoursesSlide
