export function getSessionsArray (sessionsObject) {
  if (!sessionsObject || typeof sessionsObject !== 'object') return []
  const sessionsArray = Object.entries(sessionsObject)
    .map(entry => ({ id: entry?.[0], ...entry?.[1] }))
    .sort((a, b) => new Date(a?.start) - new Date(b?.start))
  if (!sessionsArray?.[0]?.start || !sessionsArray?.[0]?.end) return []

  const currentTime = new Date().getTime()

  sessionsArray.forEach(session => {
    const isActiveSession = session?.start < currentTime && currentTime < session?.end
    const sessionAttendance = session?.isAttended !== undefined
      ? session?.isAttended : currentTime > session?.end ? false : undefined
    session.isAttended = isActiveSession || sessionAttendance
  })

  return sessionsArray
}

export function getLatestSession (sessionsArray = [], date) {
  const currentDate = new Date(date)?.getTime() || Date.now()

  const latestSession = sessionsArray?.find((session, index) => {
    const sessionStart = new Date(session?.start)?.getTime()
    const nextSession = sessionsArray[index + 1]
    const nextSessionStart = new Date(nextSession?.start)?.getTime()

    const isLatestSession = !nextSession ||
      (sessionStart <= currentDate && currentDate < nextSessionStart)
    return isLatestSession
  })

  return latestSession
}

export const getUpcomingActiveSession = (sessionsArray = [], date) => {
  const currentDate = date || new Date()
  const upcomingSession = sessionsArray?.find(session => {
    const sessionStart = new Date(session?.start)
    const sessionEnd = new Date(session?.end)
    return currentDate < sessionStart || currentDate < sessionEnd
  })

  return upcomingSession
}

export const getRecentPastSessions = (sessionsArray = [], noOfSessions, date) => {
  const currentDate = date || new Date()
  const pastSessions = sessionsArray?.filter(session => {
    const sessionEnd = new Date(session?.end)
    return currentDate > sessionEnd
  })

  const recentPastSessions = pastSessions?.slice(-noOfSessions)
  return recentPastSessions
}

export function getActiveDay (sessionsArray = [], date) {
  const currentDate = date || new Date()
  const latestSession = getLatestSession(sessionsArray, currentDate)
  if (!latestSession) return null

  const isPastLatestSession = new Date(latestSession?.end) < currentDate
  const latestSessionIndex = sessionsArray.findIndex(
    session => session?.id === latestSession?.id)
  const nextSession = sessionsArray[latestSessionIndex + 1]
  const isAttended = isPastLatestSession && !nextSession

  const activeDate = isPastLatestSession && nextSession
    ? new Date(nextSession?.start) : new Date(latestSession?.start)

  return !isAttended && activeDate.toDateString()
}

/**
 * Note: streaks start from second attended session
 */
export function getStreaks (sessionsArray = [], latestSession = {}) {
  if (!sessionsArray?.length) return {}
  const streaks = []

  sessionsArray.forEach(session => {
    const lastStreak = streaks.slice(-1)[0]
    const lastStreakIndex = streaks.findIndex(streak => streak.id === lastStreak?.id)
    const isCurrentStreak = latestSession?.id === session?.id

    if (session?.isAttended) {
      if (!lastStreak) streaks.push({ id: 1, value: 1, isCurrentStreak })
      else {
        streaks[lastStreakIndex].value += 1
        const currentStreak = streaks[lastStreakIndex].isCurrentStreak
        if (!currentStreak) streaks[lastStreakIndex].isCurrentStreak = isCurrentStreak
      }
    }
    if (session?.isAttended === false) {
      if (lastStreak) streaks.push({ id: lastStreak.id + 1, value: 0, isCurrentStreak })
    }
  })

  const filteredStreaks = streaks.filter(streak => streak.value > 1)

  const currentStreak = filteredStreaks.find(streak => streak?.isCurrentStreak)?.value
  const longestStreak = !!filteredStreaks.length &&
    Math.max(...filteredStreaks.map(streak => streak.value))

  return {
    currentStreak,
    longestStreak
  }
}
