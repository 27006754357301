import React, { useState } from 'react'

import {
  Line,
  Box,
  BoxWrap,
  CardText,
  EnrolText,
  EnrollSpan,
  Button,
  PageWrapper,
  Dot
} from './style'
import {
  getDateStringWithYearLongMonth,
  getMonthDayFromDate,
  toDateString
} from '../../utilities/dateTime'
import EditCard from './EditCard'
import { TYPE } from '../../Constants/courseCard'
import { cohortTypes, durationTypes } from '../../Constants/cohort'

const ReviewCard = (props) => {
  const { data, onRemove, onUpdate, isEdit, setIsEdit } = props

  const { SHORT, LONG } = cohortTypes
  const { INTENSIVE, STANDARD } = durationTypes

  const [isEditData, setEditData] = useState(null)
  const [type, setType] = useState(data.status)
  const [week, setWeek] = useState(data.duration === SHORT ? INTENSIVE : STANDARD)
  const [startDate, setStartDate] = useState(new Date(data.dateStart))

  const setCourseState = (value, courseState) => {
    switch (courseState) {
      case 'type':
        return setType(value)
      case 'duration':
        return setWeek(value)
      default:
        return false
    }
  }

  return (
    <PageWrapper data-testid='page-wrapper'>
      <Box active={isEdit && data?.id !== isEditData} data-testid='box'>
        <BoxWrap data-testid='box-wrap'>
          <CardText
            className={isEdit ? '' : 'margin-bottom'}
            data-testid='card-name'
          >
            {data.name}
          </CardText>
          {isEdit && data?.id === isEditData ? null : (
            <>
              <EnrolText>
                {TYPE[data.status]} <Dot />{' '}
                <EnrollSpan>
                  {data.duration === LONG
                    ? '14wk Standard'
                    : '7wk Intensive'}
                </EnrollSpan>
              </EnrolText>
              <EnrolText>
                {getMonthDayFromDate(toDateString(startDate), '', 'short')} -{' '}
                {getDateStringWithYearLongMonth(
                  data.finalExamEndTime, 'short')}
              </EnrolText>
              <EnrolText className='no-margin'>
                {data.duration === LONG ? '10hr' : '20hr'}/wk Commitment
              </EnrolText>
            </>
          )}
        </BoxWrap>
        <BoxWrap className='flex-container'>
          <Button
            onClick={() => {
              if (isEdit && data?.id === isEditData) {
                setEditData('')
                setIsEdit(false)
              } else {
                setEditData(data.id)
                setIsEdit(true)
              }
            }}
            disabled={isEdit && data?.id !== isEditData}
          >
            {isEdit && data?.id === isEditData ? 'Cancel' : 'Edit'}
          </Button>
          <Button onClick={() => onRemove(data.id)}>Remove</Button>
        </BoxWrap>
      </Box>
      {isEdit && data?.id === isEditData ? (
        <>
          <EditCard
            data={data}
            setStartDate={setStartDate}
            startDate={startDate}
            courseStates={{ duration: week, type }}
            type={type}
            setType={setType}
            week={week}
            setWeek={setWeek}
            setIsEdit={setIsEdit}
            removeEditId={() => setEditData('')}
            onUpdate={onUpdate}
            setCourseState={setCourseState}
          />
        </>
      ) : null}
      <Line />
    </PageWrapper>
  )
}

export default ReviewCard
