export const levelUpCourses = {
  calculus: ['ckp356yiy00003h5vpz3fbu1h', 'b227c462-332c-40e0-8735-ea3af6f11661']
}

export const recommendedCourses = {
  calculus: ['ckw6tiqy100003e5xexynd0dz', 'ckdampe3b00003g5m6l85b1s5']
}

export const captionByCourse = {
  ckp356yiy00003h5vpz3fbu1h: 'Prepare for Calculus I',
  'b227c462-332c-40e0-8735-ea3af6f11661': { audit: 'Ungraded . No Credits' }
}

export const headerByCourse = {
  ckp356yiy00003h5vpz3fbu1h: 'Recommended'
}
