import React from 'react'
import {
  VideoWrapper,
  KalturaVideoPlayer,
  TileImage,
  LongDescription,
  SeeAllDetailsLink,
  DetailsLinkIcon
} from './style'

function CatalogVideo (props) {
  const {
    descriptionText: longDescription,
    marketingPageUrl,
    tileImage,
    trailerVideo
  } = props

  function handleLinkClick (link) {
    if (!link) return
    window.open(link)
  }

  const descriptionText = longDescription?.replace(/[\n]/g, '<br>')

  return (
    <VideoWrapper data-testid='catalog-video-wrapper'>
      {trailerVideo
        ? <KalturaVideoPlayer id='kaltura_video_player' />
        : <TileImage data-testid='tile-image-wrapper' src={tileImage} alt='tileImage' />}
      <LongDescription>
        <div>
          {descriptionText && <p dangerouslySetInnerHTML={{ __html: descriptionText }} />}
          {marketingPageUrl &&
            <SeeAllDetailsLink
              disabled={!marketingPageUrl}
              onClick={() => handleLinkClick(marketingPageUrl)}
            >
              see all details <DetailsLinkIcon />
            </SeeAllDetailsLink>}
        </div>
      </LongDescription>
    </VideoWrapper>
  )
}

CatalogVideo.displayName = 'CatalogVideo'
export default CatalogVideo
