import React, { useEffect, useMemo } from 'react'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import { GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import { PrimaryButton, Description, Title, Page } from './style'
import { getDateStringWithYear } from '../../utilities/dateTime'
import {
  isSegmentEventInLS,
  removeSegmentEventFromLS,
  setFiredSegmentEventsInLS
} from '../../utilities/gguDegreeUtils'
import { COURSE_REGISTRATION_PAGEVIEW, COURSE_REGISTRATION_PAGE_SUBMITTED } from '../../Constants/eventType'

const ExpectedGraduation = ({ degree, semesterStart, setCurrentPage }) => {
  const { sendEvent } = useSegmentEvents()
  const formattedSemesterStart = useMemo(() => {
    return getDateStringWithYear(semesterStart)
  }, [semesterStart])

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: '1-2'
  }

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: '1-1'
    })
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'Welcome',
        page_number: '1-2'
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  return (
    <Page alignItems='flex-start'>
      <Title textAlign='left'>
        You're on your way to an associate degree in {degree}!
      </Title>
      <Description>
        Now let's keep you on track towards your goal. We'll review any credits you've already earned,&nbsp;
        then explore what courses make sense for you next.
      </Description>
      <Description marginTop='8px'>
        The courses you choose today will begin on {formattedSemesterStart}.&nbsp;
        And we can't wait for you to get started!
      </Description>
      <PrimaryButton
        onClick={() => {
          sendEvent({
            eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
            properties: {
              page_name: 'Welcome',
              page_number: '1-2'
            }
          })
          setCurrentPage(GGU_V2_ENROLLMENT_PAGES.TRANSFER_CREDIT_CONFIRMATION)
        }}
      >
        Continue
      </PrimaryButton>
    </Page>
  )
}

ExpectedGraduation.displayName = 'ExpectedGraduation'

export default ExpectedGraduation
