import React from 'react'
import { getLatestCohort } from '../../../../utilities/course'
import {
  dateToSecondsSinceEpoch,
  getTimezoneShort,
  secondsSinceEpoch,
  secondsToDateString,
  secondsToFormattedDateTimeShort
} from '../../../../utilities/dateTime'
import WhatsNextWidget from '../WhatsNextWidget'
import config from '../../../../config'
import { ASSIGNMENT_PATH_NAME } from '../../../../Constants'
import OpenAssignment from './OpenAssignment'

function Assignment ({ course, courseProgress, desktopHero }) {
  const { id: courseId, chapters } = course
  const latestCohort = getLatestCohort(course)
  const { milestones: assignmentsData } = latestCohort || {}
  const { studentProgress } = courseProgress
  const currentTime = secondsSinceEpoch()
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(courseId)

  let hasClosedAssignment = false

  const assignmentsDatoIds = assignmentsData.map(
    (assignment) => assignment.datoAssignmentUUID
  )

  const assignmentPrefixRegEx = /Assignment [0-9]+: /
  const assignments = chapters
    .map((chapter, index) => {
      return { ...chapter, chapterNumber: index + 1 }
    })
    .filter((chapter) => {
      const { hideFileUploadField, hideTextEntryField } = chapter
      const isOptionalAssignment = hideFileUploadField && hideTextEntryField
      return (
        !isOptionalAssignment &&
        chapter.type === 'WritingAssignmentChapterRecord' &&
        assignmentsDatoIds.includes(chapter.chapter_uuid)
      )
    })
    .map((chapter) => {
      let { title } = chapter
      const { updateddisplaytitle } = chapter
      title = updateddisplaytitle || title

      return {
        ...chapter,
        title: title.replace(assignmentPrefixRegEx, '')
      }
    })

  const progressData = studentProgress['assignment-progress']

  const addMilestonesData = (assignment) => {
    const { chapter_uuid: assignmentUUID } = assignment
    const deadlineData = assignmentsData.find(
      (currentAssignment) =>
        currentAssignment.datoAssignmentUUID === assignmentUUID
    )
    if (!deadlineData) return

    const unlockTime = dateToSecondsSinceEpoch(
      new Date(deadlineData.unlockTime)
    )
    const lockTime = dateToSecondsSinceEpoch(new Date(deadlineData.lockTime))
    const isOpenAssignment =
      (!unlockTime || currentTime > unlockTime) && currentTime < lockTime
    const isClosedAssignment =
      (!unlockTime || currentTime > unlockTime) && currentTime > lockTime
    assignment.id = deadlineData.id
    assignment.lockTime = lockTime
    assignment.isOpen = isOpenAssignment
    assignment.isClosed = isClosedAssignment
    assignment.unlockTime = unlockTime
    hasClosedAssignment = hasClosedAssignment || isClosedAssignment
  }

  const enrichAssignments = () => {
    assignments.forEach((assignment) => {
      addMilestonesData(assignment)
    })
  }

  enrichAssignments()

  const openUnsubmittedAssignments = assignments.filter((assignment) => {
    const { chapter_uuid: assignmentUUID } = assignment
    const deadlineData = assignmentsData.find(
      (currentAssignment) =>
        currentAssignment.datoAssignmentUUID === assignmentUUID
    )
    if (!deadlineData) return false

    const unlockTime = dateToSecondsSinceEpoch(
      new Date(deadlineData.unlockTime)
    )
    const lockTime = dateToSecondsSinceEpoch(new Date(deadlineData.lockTime))
    const isOpenAssignment =
      (!unlockTime || currentTime > unlockTime) && currentTime < lockTime
    const assignmentProgress = progressData[assignment.chapter_uuid]
    const submittedAssignemnt = assignmentProgress?.status === 'submitted'

    return isOpenAssignment && !submittedAssignemnt
  })

  if (openUnsubmittedAssignments.length) {
    return (
      <OpenAssignment
        assignments={openUnsubmittedAssignments}
        desktopHero={desktopHero}
        courseBaseUrl={courseBaseUrl}
      />
    )
  }

  const openingSoonAssignment = assignments.find((assignment) => {
    const { chapter_uuid: assignmentUUID } = assignment
    const deadlineData = assignmentsData.find(
      (currentAssignment) =>
        currentAssignment.datoAssignmentUUID === assignmentUUID
    )
    if (!deadlineData) return false
    const unlockTime = dateToSecondsSinceEpoch(
      new Date(deadlineData.unlockTime)
    )

    return currentTime < unlockTime
  })

  if (openingSoonAssignment) {
    const {
      title,
      lockTime,
      chapter_uuid: assignmentUUID,
      type: chapterType
    } = openingSoonAssignment

    const pathname = ASSIGNMENT_PATH_NAME[chapterType]
    const formattedLockTime = secondsToFormattedDateTimeShort(
      lockTime,
      'short'
    ).replace(', ', ' at ')
    const timezone = getTimezoneShort(secondsToDateString(lockTime))

    return (
      <div data-testid='opening-soon-assignment'>
        <WhatsNextWidget
          description={`The assignment ${title} will open on ${formattedLockTime} ${timezone}. 
          Visit the Course Home to begin reviewing the content.`}
          primaryButtonText='Preview'
          primaryButtonFunction={() =>
            (window.location.href = `${courseBaseUrl}/#/${assignmentUUID}/${pathname}`)}
          secondaryButtonText='Visit Home'
          secondaryButtonFunction={() => (window.location.href = courseBaseUrl)}
          desktopHero={desktopHero}
          caughtUpText='You’re caught up on coursework!'
        />
      </div>
    )
  }
}

Assignment.displayName = 'Assignment'
export default Assignment
