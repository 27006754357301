import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { StyledTooltip, WiderStyledTooltip } from './style/index'

export default function ToolTip ({
  tooltipText,
  wideTooltip,
  placement,
  children,
  width,
  dataTestId
}) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        wideTooltip
          ? (
            <WiderStyledTooltip
              width={width}
            >{tooltipText}
            </WiderStyledTooltip>)
          : (<StyledTooltip>{tooltipText}</StyledTooltip>)
      }
    >
      {children || <span data-testid={dataTestId || 'tooltip'} />}
    </OverlayTrigger>
  )
}
