import styled, { createGlobalStyle } from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 88px 103px 0 103px;
  
  ${mediaqueries.mobile`
      margin-left: 32px;
      margin-right: 32px;
   `}

  ${mediaqueries.smallMobile`
      margin-left: 24px;
      margin-right: 24px;
   `}
`

export const Content = styled.div`
  display: flex;
  justify-content: center;

  & p {
    margin-bottom: 0px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 600px;
    padding-top: 144px;

    @media (max-width: 767px) {
      width: 525px;
    }

    @media (max-width: 575px) {
      width: 324px;
    }

    & > p:first-child {
      margin-bottom: 6px;
    }
  }
`

export const ScoreHeader = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 12px !important;
`

export const Description = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  margin-top: 12px;
  margin-bottom: 32px !important;
`

export const GlobalStyle = createGlobalStyle`
  .modal-backdrop.show {
    opacity: 0.5;
  }

  .modal-dialog {
    max-width: none;
  }
  
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-body {
    padding: 24px;
    & > p {
      margin-bottom: 24px;
    }
  }

  .modal-content {
    background-color: #25272B;
    width: 600px;
    display: flex;
    align-items: flex-start;
    margin: auto;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    ${mediaqueries.mobile`
      width: 525px;
    `}
  
    ${mediaqueries.smallMobile`
      width: 324px;
    `}
  
    ${mediaqueries.foldable`
      width: 250px;
    `}
  }
`

export const ExitModalHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px;

  & > p {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    flex: 1;
    margin-bottom: 0;
  }

  & > img {
    cursor: pointer;
    width: 14px;
    height: 14px;
  }
`

export const Separator = styled.hr`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  border: 0 none;
  margin: 0;
`

export const OptionsLink = styled.a`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #5FC4B8 !important;

  & img {
    margin-top: -3px;
    margin-left: 13px;
  }
`

export const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;

  ${mediaqueries.mobile`
    flex-direction: column;

    & > button:first-child {
      margin-bottom: 10px;
    }
  `}

`
