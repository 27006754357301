import React, { useContext } from 'react'
import { AppContext } from '../../ContextProvider/ContextProvider'
import { getActiveCourses } from '../utils'
import ActiveCoursesSlide from './ActiveCoursesSlide'
import useWindowSize from '../../../hooks/useWindowSize'
import { BREAKPOINTS } from '../../../mediaQueries'
import MobileActiveCourses from './MobileActiveCourses'
import { SectionTitle } from '../style'
import { CoursesContainer } from './style'

function ActiveCourses () {
  const { courses, catalogCourses } = useContext(AppContext)
  const [windowWidth] = useWindowSize()

  const coursesToDisplay = getActiveCourses(courses)
  const isLargeScreen = windowWidth > BREAKPOINTS.mobile

  return (
    <>
      <SectionTitle>
        My Courses <span>({coursesToDisplay.length})</span>
      </SectionTitle>
      <CoursesContainer>
        {isLargeScreen ? (
          <ActiveCoursesSlide
            coursesToDisplay={coursesToDisplay}
            catalogCourses={catalogCourses}
          />
        ) : (
          <MobileActiveCourses
            coursesToDisplay={coursesToDisplay}
            catalogCourses={catalogCourses}
          />
        )}
      </CoursesContainer>
    </>
  )
}

ActiveCourses.displayName = 'ActiveCourses'
export default ActiveCourses
