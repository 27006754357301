import React, { useContext, useEffect, useState } from 'react'
import ChangeEmail from './ChangeEmail'
import CodeVerification from './CodeVerification'
import { Auth0Context } from '../../Auth0Provider/Auth0Provider'
import { EDIT_EMAIL_MODES } from '../../../Constants/accountArea'
import { EditSection, EditTitle, SaveButton } from '../style'

const {
  EMAIL_PASSWORD_ENTRY, CODE_VERIFICATION, EMAIL_UPDATE_SUCCESS
} = EDIT_EMAIL_MODES

function EditEmailSection ({ userEmail }) {
  const { logout } = useContext(Auth0Context)
  const [newEmail, setNewEmail] = useState('')
  const [password, setPassword] = useState('')
  const [currentMode, setCurrentMode] = useState(EMAIL_PASSWORD_ENTRY)

  useEffect(() => {
    return () => {
      if (currentMode === EMAIL_UPDATE_SUCCESS) logout()
    }
  // eslint-disable-next-line
  }, [currentMode])

  return (
    <EditSection
      data-testid='edit-email-section'
    >
      {currentMode === EMAIL_PASSWORD_ENTRY && (
        <ChangeEmail
          userEmail={userEmail}
          newEmail={newEmail}
          setNewEmail={setNewEmail}
          password={password}
          setPassword={setPassword}
          setCurrentMode={setCurrentMode}
        />
      )}

      {currentMode === CODE_VERIFICATION && (
        <CodeVerification
          newEmail={newEmail}
          password={password}
          setCurrentMode={setCurrentMode}
        />
      )}

      {currentMode === EMAIL_UPDATE_SUCCESS && (
        <>
          <EditTitle>Your email is updated!</EditTitle>
          <p className='fs-exclude'>
            This account has lost access to your enrolled course(s).
            Please logout and then log back in with {newEmail}
          </p>
          <SaveButton
            onClick={logout}
          >
            logout
          </SaveButton>
        </>
      )}
    </EditSection>
  )
}

export default EditEmailSection
