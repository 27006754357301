import DOMPurify from 'dompurify'
import { MATHJAX_SCRIPT_LINK } from '../../../../Constants'

const configureDOMPurify = () => {
  DOMPurify.addHook('afterSanitizeAttributes', (node, data) => {
    const { nodeName } = node
    if (nodeName.toLowerCase() === 'a') {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener noreferrer')
    }
  })
}

export const renderMathJax = ({
  onMathJaxWillRender,
  onMathJaxDidRender,
  math,
  mathjaxContainer
}) => {
  if (!window.MathJax) return

  const { Hub } = window.MathJax
  onMathJaxWillRender && Hub.Queue([onMathJaxWillRender])

  mathjaxContainer.innerHTML = DOMPurify.sanitize(math, {
    ADD_TAGS: ['iframe', 'semantics', 'annotation'],
    ADD_ATTR: ['allow', 'autoplay', 'gyroscope', 'allowFullScreen',
      'frameborder', 'scrolling', 'encoding']
  })

  // Render MathJax content inside the element asynchronously, using Queue
  // https://docs.mathjax.org/en/v2.7-latest/advanced/queues.html#the-mathjax-processing-queue
  Hub.Queue(['Typeset', Hub, mathjaxContainer])

  onMathJaxDidRender && Hub.Queue([onMathJaxDidRender])
}

export const loadMathJax = callBack => {
  if (window.MathJax) return

  const existingScript = document.querySelector('#mathJaxScript')
  if (existingScript) return

  configureDOMPurify()

  const mathJaxConfigScript = document.createElement('script')
  mathJaxConfigScript.type = 'text/x-mathjax-config'
  const mathJaxConfig =
    `MathJax.Hub.Config({
      jax: ['input/TeX', 'input/MathML', 'input/AsciiMath', 'output/SVG'],
      menuSettings: {
        explorer: true
      },
      explorer: {
        speech: true,
        background: 'yellow',
        foreground: 'white'
      },
      showMathMenu: false,
      styles: {
        '#MathJax_Message': {
          display: 'none'
        }
      }
    });
    MathJax.Hub.processSectionDelay = 0;
    MathJax.Hub.processUpdateDelay = 0;`
  mathJaxConfigScript[(window.opera ? 'innerHTML' : 'text')] = mathJaxConfig
  document.body.appendChild(mathJaxConfigScript)

  const mathJaxScript = document.createElement('script')
  mathJaxScript.id = 'mathJaxScript'
  mathJaxScript.src = MATHJAX_SCRIPT_LINK
  mathJaxScript.onload = () => {
    callBack(false)
  }
  document.body.appendChild(mathJaxScript)

  callBack(true)
}
