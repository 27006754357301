import {
  CALCULUS,
  COLLEGE_ALGEBRA,
  COLLEGE_WRITING_II,
  MACROECONOMICS,
  MICROECONOMICS,
  PRECALCULUS,
  PRINCIPLES_OF_ECONOMICS
} from './courseNames'

export const PRECALCULUS_SUBSTITUTES = [CALCULUS, COLLEGE_ALGEBRA]
export const COLLEGE_ALGEBRA_SUBSTITUTES = [CALCULUS, PRECALCULUS]
export const COLLEGE_WRITING_SUBSTITUTES = [COLLEGE_WRITING_II]
export const PRINCIPLE_OF_ECONOMICS_SUBSTITUTES = [MICROECONOMICS, MACROECONOMICS]
export const MICROECONOMICS_SUBSTITUTES = [PRINCIPLES_OF_ECONOMICS]
export const MACROECONOMICS_SUBSTITUTES = [PRINCIPLES_OF_ECONOMICS]
export const COURSE_SUBSTITUTES = {
  [PRECALCULUS]: PRECALCULUS_SUBSTITUTES,
  [COLLEGE_ALGEBRA]: COLLEGE_ALGEBRA_SUBSTITUTES,
  [COLLEGE_WRITING_II]: COLLEGE_WRITING_SUBSTITUTES,
  [PRINCIPLES_OF_ECONOMICS]: PRINCIPLE_OF_ECONOMICS_SUBSTITUTES,
  [MICROECONOMICS]: MICROECONOMICS_SUBSTITUTES,
  [MACROECONOMICS]: MACROECONOMICS_SUBSTITUTES
}
