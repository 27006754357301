import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { OverrideCSSProperties, TransferContent } from './style'
import { PrimaryButton } from '../Buttons/style'
import { TRANSFER_COHORT_STATES } from '../../Constants/accountArea'
import { formatDateWithDay, getMonthDayFromDate } from '../../utilities/dateTime'
import {
  getAvailableCohorts,
  getAllCohortStartDates,
  filterCohorts
} from '../../utilities/cohort'
import ToolTip from '../CatalogDetailPage/Tooltip'
import { DURATION_TEXT } from '../CatalogDetailPage/utilities/tooltipTexts'
import { StyledLabel, EndDate } from '../CatalogDetailPage/style'
import { AUDIT } from '../../Constants/studentStatus'
import DatePicker from './DatePicker'

const CohortSelection = ({
  cohort,
  prospects,
  currentSemester,
  isActiveGGUStudent,
  setPageState,
  outlierCourses,
  courseId,
  newCohortDetails,
  setNewCohortDetails
}) => {
  const {
    dateStart,
    finalExamEndTime,
    studentStatus,
    duration: cohortDuration
  } = cohort || {}
  const isAuditCohort = studentStatus === AUDIT
  const cohortType = cohortDuration >= 14 ? 'Standard' : 'Intensive'

  const availableCohorts = useMemo(() => {
    return (getAvailableCohorts({
      courses: outlierCourses,
      courseSelected: { uuid: { uuid: courseId } },
      prospects,
      currentSemester,
      isActiveGGUStudent
    }) || []).filter(currentCohort => cohort.id !== currentCohort.id)

    // eslint-disable-next-line
  }, [courseId])

  const [isStandardCohort, setIsStandardCohort] = useState(true)
  const [cohortStartDates, setCohortStartDates] = useState(
    getAllCohortStartDates({
      availableCohorts,
      isAuditCohort,
      isStandardCohort
    })
  )

  const cohortStartDate = getMonthDayFromDate(dateStart, null, 'short')
  const cohortEndDate = getMonthDayFromDate(finalExamEndTime?.split('T')[0], null, 'short')

  const setCohortDetails = ({ date, duration, selectedCohort }) => {
    setNewCohortDetails({
      ...newCohortDetails,
      ...(date || date === null ? { date } : {}),
      ...(duration ? { duration } : {}),
      ...(selectedCohort ? { selectedCohort } : {})
    })
  }

  const getSelectedCohort = date => {
    if (!date) return null

    const startDate = date?.toISOString()?.slice(0, 10)
    const cohorts = filterCohorts(
      availableCohorts,
      [isAuditCohort, !isStandardCohort]
    )
    return cohorts?.find(cohort => {
      return cohort.dateStart === startDate
    })
  }

  useEffect(() => {
    if (!cohortStartDates?.length) {
      setCohortDetails({ date: null, selectedCohort: {} })
      return
    }

    setCohortDetails({
      date: cohortStartDates[0],
      selectedCohort: getSelectedCohort(cohortStartDates[0])
    })

    // eslint-disable-next-line
  }, [cohortStartDates])

  useEffect(() => {
    const { date } = newCohortDetails || {}
    if (!date) return

    const cohort = getSelectedCohort(date)
    setCohortDetails({ selectedCohort: cohort })

    // eslint-disable-next-line
  }, [newCohortDetails.date])

  const durationChangeHandler = standardSelected => {
    setIsStandardCohort(standardSelected)
    setCohortDetails({ duration: standardSelected ? 14 : 7 })
    setCohortStartDates(getAllCohortStartDates({
      availableCohorts,
      isAuditCohort,
      isStandardCohort: standardSelected
    }))
  }

  const {
    date,
    selectedCohort,
    selectedCohort: { finalExamEndTime: selectedCohortEndDate } = {}
  } = newCohortDetails || {}

  return (
    <>
      <OverrideCSSProperties />
      <TransferContent>
        <p>
          Your current cohort runs from {cohortStartDate} - {cohortEndDate} ({cohortType}).
          Select the duration and start date for your new cohort.
        </p>
        <div className='cohort-selection'>
          <div>
            <p className='field-label'>
              Duration{' '}
              <ToolTip
                data-testid='duration-tooltip'
                placement='right'
                wideTooltip
                tooltipText={DURATION_TEXT}
              />
            </p>
            <StyledLabel htmlFor='standard'>
              14 Weeks (Standard)
              <input
                type='radio'
                name='duration'
                id='standard'
                checked={isStandardCohort}
                readOnly
                onClick={() => durationChangeHandler(true)}
              />
              <span />
            </StyledLabel>
            <StyledLabel htmlFor='intensive'>
              7 Weeks (Intensive)
              <input
                type='radio'
                name='duration'
                id='intensive'
                checked={!isStandardCohort}
                readOnly
                onClick={() => durationChangeHandler(false)}
              />
              <span />
            </StyledLabel>
          </div>
          <div>
            <p className='field-label start-date'>Start date</p>
            <DatePicker
              date={date}
              includeDates={cohortStartDates}
              changeHandler={date => {
                setCohortDetails({ date })
              }}
            />
            <EndDate data-testid='end-date'>
              {selectedCohortEndDate
                ? `Ends ${formatDateWithDay(selectedCohortEndDate)}`
                : ' '}
            </EndDate>
          </div>
        </div>
        <PrimaryButton
          width='140px'
          data-testid='btn-next'
          disabled={isEmpty(selectedCohort)}
          onClick={() => setPageState(TRANSFER_COHORT_STATES.REVIEW)}
        >
          next
        </PrimaryButton>
      </TransferContent>
    </>
  )
}

CohortSelection.displayName = 'CohortSelection'

CohortSelection.propTypes = {
  cohort: PropTypes.object,
  setPageState: PropTypes.func,
  outlierCourses: PropTypes.array,
  courseId: PropTypes.string,
  newCohortDetails: PropTypes.object,
  setNewCohortDetails: PropTypes.func
}

export default CohortSelection
