import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner'
import { loadDesmos, getDesmosCalculator, resetExpression } from './desmos'
import { Desmos, GraphNote } from '../style'

const DesmosGraph = ({ expression }) => {
  const [isDesmosLoading, setIsDesmosLoading] = useState(false)
  const calculator = useRef()

  const destroyCalculator = calculator => {
    const { current } = calculator || {}

    if (!current) return

    current.destroy()
  }

  useEffect(() => {
    loadDesmos(setIsDesmosLoading)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isDesmosLoading || !expression) return

    let desmosCalculator = calculator.current

    try {
      destroyCalculator(desmosCalculator)
      resetExpression()
      desmosCalculator = getDesmosCalculator(JSON.parse(expression))
    } catch (error) {
      console.error('Error when setting Desmos: ', error.message)
    }

    return () => { destroyCalculator(desmosCalculator) }

    // eslint-disable-next-line
  }, [expression, isDesmosLoading])

  if (isDesmosLoading) return <LoadingSpinner />

  return (
    <Desmos>
      <div data-testid='desmos' id='calculator' />
      <GraphNote />
    </Desmos>
  )
}

DesmosGraph.displayName = 'DesmosGraph'

DesmosGraph.propTypes = {
  expression: PropTypes.string
}

export default DesmosGraph
