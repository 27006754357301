import React from 'react'
import startCase from 'lodash/startCase'
import MESSAGE_LIST from './MessageList'
import { WelcomeMsg } from './style'

const getRandomWelcomeNote = () => {
  const dayOfWeek = new Date().toLocaleString('en-us', { weekday: 'long' })
  const message = MESSAGE_LIST[Math.floor(Math.random() * MESSAGE_LIST.length)]
    .replace('[day of the week]', dayOfWeek)
  return message
}

const WelcomeMessage = props => {
  const { userName } = props
  if (!userName) return null

  const welcomeNote = getRandomWelcomeNote()
  return (
    <WelcomeMsg data-testid='welcome-message'>
      {welcomeNote},&nbsp;
      <span className='fs-exclude'>{startCase(userName)}</span>!
    </WelcomeMsg>
  )
}
export default WelcomeMessage
