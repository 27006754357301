import { get, isEmpty } from 'lodash'
import config from '../config'
import qs from 'qs'
import {
  PERSONAL_INFO, ACCOUNT_MENU, COLLEGE_PATHWAYS, COLLEGE_PATHWAYS_CERTIFICATE,
  AMAZON_REFUND_FORM_ID, ACCOUNT_PATH
} from '../Constants/accountArea'
import { getLatestCohort } from './course'
import {
  addDaysToDateInSecs, changeTimezone, dateToSecondsSinceEpoch, daysToSeconds,
  getDateMMDDYYFormat
} from './dateTime'
import api from '../api'
import { AUDIT, AUDIT_TRANSCRIPT, COMPLETED } from '../Constants/studentStatus'
import { PITTSBURGH_UNIVERSITY } from '../Constants/relationships'

export const getTransferableCohorts = courses => {
  if (!courses) return []

  const { nonPittsburghCohortNames } = config
  // For admin user, sometimes a course is returned with no cohort name, filtering such cases
  const cohorts =
    courses.map(course => {
      const latestCohort = getLatestCohort(course)
      if (!latestCohort) return ''
      latestCohort.courseName = course.name
      return latestCohort
    }).filter(Boolean)

  const filteredCohorts = cohorts.filter(cohort => {
    const { gradesSubmittedToPitt, dateGradesSubmitted } = cohort
    const isPittsburghCohort = !nonPittsburghCohortNames.includes(cohort.name)

    if (!gradesSubmittedToPitt || !isPittsburghCohort) return null

    const date = new Date(dateGradesSubmitted + 'T00:00:00')
    // Convert date to PST time.
    const datePST = changeTimezone(date, 'America/Los_Angeles')

    /* It adds seven days to the date to account for the time
      that Pitt has needed to process the grades */
    datePST.setTime(datePST.getTime() + (daysToSeconds(7) * 1000))
    const currentTime = Date.now()

    return datePST.getTime() <= currentTime
  })

  return filteredCohorts
}

export const getCourseDate = cohort => {
  const date = get(cohort, 'dateStart')
  if (isEmpty(date)) return ''

  const dateStart = new Date(date)
  const duration = get(cohort, 'duration', 0)

  let dateEnd = new Date(dateStart)
  dateEnd = new Date(dateEnd.setDate(dateStart.getDate() + 7 * duration))

  const dateStartString =
    dateStart.toLocaleString('en-us', { month: 'short', day: '2-digit' })
  const dateEndString =
    dateEnd.toLocaleString('en-us', { month: 'short', day: '2-digit' })

  return `(${dateStartString} - ${dateEndString}, ${dateEnd.getFullYear()})`
}

export const getPageNameFromTabName = tabName => {
  if (tabName === ACCOUNT_PATH.SUBSCRIPTION_CANCELLATION) return null
  return ACCOUNT_MENU[tabName] || PERSONAL_INFO
}

// Amazon Refund

export const getCollegePathwaysCourses = (studentCourses = []) => {
  if (!studentCourses?.length) return

  const collegePathwaysCourses = studentCourses.filter(course => {
    const latestCohort = getLatestCohort(course)
    return latestCohort?.token?.certificate?.name === COLLEGE_PATHWAYS
  })

  return collegePathwaysCourses
}

export const getCollegePathwaysCoursesNames = (collegePathwaysCourses = []) => {
  if (!collegePathwaysCourses?.length) return []

  return collegePathwaysCourses.map(course => course?.name)
}

export const getLatestRefundDate = (latestCohort) => {
  if (!latestCohort) return

  const certificatePurchaseDate = latestCohort?.token?.certificate?.purchaseDate
  if (!certificatePurchaseDate) return

  const purchaseDateInSecs =
    dateToSecondsSinceEpoch(new Date(certificatePurchaseDate))

  const latestRefundDateInSec = addDaysToDateInSecs(purchaseDateInSecs, 183)

  return new Date(latestRefundDateInSec * 1000).toISOString()
}

export const getEarliestDropDate = (collegePathwaysCourses = []) => {
  if (!collegePathwaysCourses?.length) return

  let latestCohort = getLatestCohort(collegePathwaysCourses[0])

  collegePathwaysCourses.forEach(course => {
    const courseLatestCohort = getLatestCohort(course)
    const earlierCohort =
      latestCohort?.finalDropDate > courseLatestCohort?.finalDropDate
    if (earlierCohort) latestCohort = courseLatestCohort
  })

  return latestCohort?.finalDropDate
}

export const getCertificatePaymentStatus = (latestCohort) => {
  const purchase = latestCohort?.token?.purchases?.[0]
  return purchase?.fields?.paymentStatus
}

export const getSelectedCourses = (coursesNames = []) => {
  const {
    requiredCourse, mathElectives, liberalArtsElectives, businessElectives
  } = COLLEGE_PATHWAYS_CERTIFICATE

  return {
    requiredCourse,
    mathElective: coursesNames.find(course => mathElectives.includes(course)) || '',
    liberalArtsElective: coursesNames.find(course => liberalArtsElectives.includes(course)) || '',
    businessElective: coursesNames.find(course => businessElectives.includes(course)) || ''
  }
}

/**
 * @param {String} latestRefundDate
 * @param {String} earliestDropDate
 * @param {String} paymentStatus
 */

export const getRefundEligibility = ({ latestRefundDate, earliestDropDate, paymentStatus }) => {
  const currentDate = new Date().toISOString()
  const isRefundEligible = (
    currentDate < latestRefundDate && currentDate < earliestDropDate &&
    paymentStatus !== 'Refunded'
  )

  return isRefundEligible ? 'Yes' : 'No'
}

export const getAmazonRefundFormFields = (studentCourses = []) => {
  const certificateCourses = getCollegePathwaysCourses(studentCourses)
  const latestCohort = getLatestCohort(certificateCourses?.[0] || {})
  const latestRefundDate = getLatestRefundDate(latestCohort)
  const earliestDropDate = getEarliestDropDate(certificateCourses)
  const coursesNames = getCollegePathwaysCoursesNames(certificateCourses)
  const {
    requiredCourse, mathElective, liberalArtsElective, businessElective
  } = getSelectedCourses(coursesNames)
  const paymentStatus = getCertificatePaymentStatus(latestCohort)
  const refundEligibility = getRefundEligibility({
    latestRefundDate, earliestDropDate, paymentStatus
  })

  return {
    latestRefundDate,
    earliestDropDate,
    requiredCourse,
    mathElective,
    liberalArtsElective,
    businessElective,
    refundEligibility
  }
}

export const getAmazonRefundFormUrl = ({
  studentCourses, studentName, studentEmail
}) => {
  const {
    latestRefundDate, earliestDropDate, requiredCourse, mathElective,
    liberalArtsElective, businessElective, refundEligibility
  } = getAmazonRefundFormFields(studentCourses)

  const formattedLatestRefundDate = getDateMMDDYYFormat(latestRefundDate)
  const formattedEarliestRefundDate = getDateMMDDYYFormat(earliestDropDate)

  const queryParams = {
    student_name: studentName,
    student_email: studentEmail,
    refund_amount: '$1260',
    latest_refund_date: formattedLatestRefundDate,
    earliest_drop_date: formattedEarliestRefundDate,
    required_course: requiredCourse,
    math_elective: mathElective,
    liberal_arts_elective: liberalArtsElective,
    business_elective: businessElective,
    refund_eligible: refundEligibility
  }

  const queryString = qs.stringify(queryParams, { encodeValuesOnly: true })

  return `https://form.typeform.com/to/${AMAZON_REFUND_FORM_ID}?${queryString}`
}

export const getInStrideCancellationFormUrl = ({ studentId, instrideId }) => {
  const queryParams = {
    student_id: studentId,
    instride_id: instrideId
  }

  const queryString = qs.stringify(queryParams, { encodeValuesOnly: true })

  return `https://form.typeform.com/to/Yq58kteY?${queryString}`
}

const updateResult = async ({ result, field, attempt, email }) => {
  if (email) {
    const { course, cohort } = attempt?.fields || {}
    const grade = await api.getProjectedGrade(
      course?.fields?.id, cohort?.id, email
    )
    attempt.grade = grade
  }

  if (!result[field]) result[field] = []
  result[field].push(attempt)
}

export const oneWeekAfterGradesSubmitted = (dateGradesSubmitted) => {
  if (!dateGradesSubmitted) return false
  const date = new Date(dateGradesSubmitted + 'T00:00:00')
  const datePST = changeTimezone(date, 'America/Los_Angeles')

  const timeAfterOneWeek = datePST.getTime() + (daysToSeconds(7) * 1000)
  return timeAfterOneWeek <= Date.now()
}

export const getTranscripts = async (attempts, email) => {
  const transcripts = {
    ggu: [],
    pittsburgh: [],
    excluded: []
  }
  if (!attempts?.length) return transcripts

  for (let i = 0; i < attempts.length; i++) {
    const attempt = attempts[i]
    if (!attempt) continue

    const {
      studentStatus,
      relationshipName,
      creditGrantingInstitution,
      cohort,
      course
    } = attempt.fields || {}
    const { Name: attemptStatus } = studentStatus?.fields || {}

    const relationshipHasNoInstitution = relationshipName && !creditGrantingInstitution
    const isExcludedTranscript = relationshipHasNoInstitution ||
      [AUDIT, AUDIT_TRANSCRIPT].includes(attemptStatus)

    if (isExcludedTranscript) {
      attempt.showTranscript = true
      await updateResult({ result: transcripts, field: 'excluded', attempt })
      continue
    }

    const { dateGradesSubmitted } = cohort?.fields || {}
    if (attemptStatus !== COMPLETED || !dateGradesSubmitted) continue

    const { name: courseName } = course?.fields || {}
    const isGGUCourse = courseName?.includes('GGU')
    const isPittsburghRelationship = creditGrantingInstitution === PITTSBURGH_UNIVERSITY
    const showTranscript = oneWeekAfterGradesSubmitted(dateGradesSubmitted)
    attempt.showTranscript = showTranscript

    if (isGGUCourse) {
      await updateResult({ result: transcripts, field: 'ggu', attempt, email })
      continue
    }

    if (isPittsburghRelationship || !isGGUCourse) {
      await updateResult({ result: transcripts, field: 'pittsburgh', attempt, email })
    }
  }

  return transcripts
}
