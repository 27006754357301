import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { formatDateWithDay } from '../../utilities/dateTime'
import { checkCourseNames } from '../../utilities/course'
import {
  PretestStateWrapper,
  PretestSectionButton,
  OtherOptionsWrapper
} from './style'
import {
  hasStudentAuditedCourseBefore,
  attempts
} from '../../utilities/attemptUtils'
import {
  passedPrerequisite,
  hasPretestContent,
  hasStudentPassedPretest,
  getPretestRetakeDate,
  getRecentAttempt,
  hasIncompletePretest
} from '../../utilities/pretestUtils'
import { ArrowIcon } from '../AccountArea/style'
export default function PretestRelatedStatesComponent ({
  courseProjectedGrades,
  studentCourses,
  showPretestStates,
  pretestAttempts,
  pretestOverrides,
  courseData,
  courseName,
  studentAttempts,
  setStudentAuditedCourse,
  additionalIds,
  url
}) {
  const [available, setAvailable] = useState()
  const history = useHistory()

  const { minimumPretestScore, prerequisite = {} } = courseData || {}
  const hasPassedPrerequisite = passedPrerequisite({
    courseProjectedGrades,
    studentCourses,
    minimumPretestScore,
    prerequisite
  })
  let pretestRetakeDate = getPretestRetakeDate(pretestAttempts, additionalIds)
  const overridePretest = (pretestOverrides || [])
    .some(override => additionalIds.includes(override.courseId))
  const studentPassedPretest = hasPassedPrerequisite ||
    hasStudentPassedPretest(pretestAttempts, additionalIds)
  const recentAttempt = getRecentAttempt(pretestAttempts, additionalIds)
  const studentAttemptedPretest = hasIncompletePretest(recentAttempt)
  const isCourseCalculus = additionalIds?.some(courseId =>
    checkCourseNames(['calculus', 'calculus.plus'], courseId)
  )

  useEffect(() => {
    if (hasStudentAuditedCourseBefore(additionalIds, studentAttempts)) {
      setStudentAuditedCourse()
    }

    const courseRequiresPretest = hasPretestContent(courseData)
    const { enrolledAttempts } = attempts(additionalIds, studentAttempts) || {}
    const hasEnrolledCourse = enrolledAttempts?.find(attempt => {
      const {
        fields: {
          cohort: { fields: { name } = {} } = {}
        } = {}
      } = attempt
      return name && !name?.toLowerCase().includes('audit')
    })

    if (overridePretest || !courseRequiresPretest ||
      studentPassedPretest || hasEnrolledCourse) return showPretestStates(false)
    const isAvailable = !pretestRetakeDate || new Date(pretestRetakeDate) <= new Date()
    setAvailable(isAvailable)
    // eslint-disable-next-line
  }, [additionalIds?.length])

  pretestRetakeDate = formatDateWithDay(pretestRetakeDate)

  const pretestContent = {
    pretestAvailable: {
      title: 'Pass a pretest to enroll',
      description: `Test your ${
        isCourseCalculus ? 'math' : ''
      } skills and find out if you're ready to take on ${courseName} for credit`
    },
    pretestNotAvailable: {
      title: 'Pretest not available',
      description: `You can take the pretest again on ${pretestRetakeDate}.`
    }
  }
  const { title, description } = pretestContent[
    available ? 'pretestAvailable' : 'pretestNotAvailable'
  ]

  return (
    <>
      <PretestStateWrapper>
        <h1>{title}</h1>
        <p>{description}</p>
        {available && (
          <PretestSectionButton onClick={() => history.push(url + '/pretest')}>
            <span>take the test</span>
            <ArrowIcon />
          </PretestSectionButton>
        )}
      </PretestStateWrapper>
      {(pretestRetakeDate || studentAttemptedPretest) && (
        <OtherOptionsWrapper>
          <h1>See what else you can do</h1>
          <p>
            There are other options besides enrolling in {courseName} for
            credit.
          </p>
          <PretestSectionButton onClick={() => history.push(url + '/alternatives')}>
            see options
          </PretestSectionButton>
        </OtherOptionsWrapper>
      )}
    </>
  )
}

PretestRelatedStatesComponent.propTypes = {
  courseProjectedGrades: PropTypes.object,
  studentCourses: PropTypes.arrayOf(PropTypes.object),
  studentAttempts: PropTypes.arrayOf(PropTypes.object),
  additionalIds: PropTypes.arrayOf(PropTypes.string),
  courseName: PropTypes.string,
  url: PropTypes.string,
  showAlternateStates: PropTypes.func,
  showPretestStates: PropTypes.func,
  pretestAttempts: PropTypes.arrayOf(PropTypes.object),
  courseData: PropTypes.object,
  setStudentAuditedCourse: PropTypes.func
}
PretestRelatedStatesComponent.displayName = 'PretestRelatedStatesComponent'
