import React, { useContext, useEffect } from 'react'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import { useHistory, useLocation } from 'react-router-dom'
import {
  OverrideContainerProps,
  ContinueButton,
  PageWrapper,
  SectionTitle
} from './styles'
import { AppContext } from '../ContextProvider/ContextProvider'
import { getDateStringWithYearLongMonth } from '../../utilities/dateTime'
import {
  getNextPage,
  isSegmentEventInLS,
  removeSegmentEventFromLS,
  setFiredSegmentEventsInLS,
  setVisitedPages
} from '../../utilities/gguDegreeUtils'
import { COURSE_REGISTRATION_PAGEVIEW, COURSE_REGISTRATION_PAGE_SUBMITTED } from '../../Constants/eventType'

const ExpectedGraduation = () => {
  const { sendEvent } = useSegmentEvents()
  const { prospects, currentSemester } = useContext(AppContext)
  const history = useHistory()
  const currentProspect = prospects[0]
  const {
    pathname,
    state: { overrideVisitedPage } = {}
  } = useLocation()

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: '2-1'
  }

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'On Track',
        page_number: '2-1'
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (overrideVisitedPage) return

    const nextPage = getNextPage(pathname)
    if (!nextPage) return

    history.push(nextPage)
    // eslint-disable-next-line
  }, [])

  const handleClick = () => {
    setVisitedPages(pathname)
    history.push('/transfer-credit-confirmation')
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
      properties: {
        page_name: 'On Track',
        page_number: '2-1'
      }
    })
    removeSegmentEventFromLS(segmentEvent)
  }

  const closeEnrollment = getDateStringWithYearLongMonth(currentSemester?.closeEnrollment, 'short')
  const semesterStart = getDateStringWithYearLongMonth(currentSemester?.semesterStart, 'short')

  return (
    <PageWrapper center>
      <OverrideContainerProps />
      <SectionTitle className='mb-5'>
        You’re on track to graduate with an associate degree in<br /><b>{currentProspect.degree}!</b>
        <br /><br />
        The semester starts on {semesterStart}, so please review your courses and enroll
        by {closeEnrollment}.
      </SectionTitle>
      <ContinueButton onClick={handleClick}>Continue</ContinueButton>
    </PageWrapper>
  )
}

export default ExpectedGraduation
