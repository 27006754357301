import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  getNextPage,
  isSegmentEventInLS,
  removeSegmentEventFromLS,
  setFiredSegmentEventsInLS,
  setVisitedPages
} from '../../utilities/gguDegreeUtils'
import {
  ContinueButton,
  CreditWrapper,
  Description,
  PageWrapper,
  SectionTitle
} from './styles'
import { COURSE_REGISTRATION_PAGEVIEW, COURSE_REGISTRATION_PAGE_SUBMITTED } from '../../Constants/eventType'

const TransferCreditConfirmation = () => {
  const { sendEvent } = useSegmentEvents()
  const { prospects } = useContext(AppContext)
  const history = useHistory()

  const {
    pathname,
    state: { overrideVisitedPage } = {}
  } = useLocation()

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: '3-1'
  }

  const { transferredCredit, generalElectiveCredits } = prospects?.[0] || {}

  useEffect(() => {
    if (!transferredCredit?.length && !generalElectiveCredits) return
    if (isSegmentEventInLS(segmentEvent)) return

    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'Transfer Credit',
        page_number: '3-1',
        transfer_credits_available: transferredCredit.map((course, index) => {
          return {
            [`Course ${index + 1}`]: {
              'Course Name': course?.displayName,
              'Course Credit': 4
            }
          }
        })
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (overrideVisitedPage) return

    const nextPage = getNextPage(pathname)
    if (!nextPage) return

    history.push(nextPage)
    // eslint-disable-next-line
  }, [])

  if (!transferredCredit?.length && !generalElectiveCredits) {
    setVisitedPages(pathname)
    history.push({
      pathname: overrideVisitedPage ? '/expected-graduation' : '/recommended-courses',
      state: { overrideVisitedPage }
    })
  }

  const handleClick = () => {
    setVisitedPages(pathname)
    history.push('/recommended-courses')
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
      properties: {
        page_name: 'Transfer Credit',
        page_number: '3-1'
      }
    })
    removeSegmentEventFromLS(segmentEvent)
  }

  return (
    <PageWrapper>
      <SectionTitle className='mt-1 mb-5'>
        Here is a list of which courses were accepted as transfer credits.
      </SectionTitle>
      <CreditWrapper data-testid='creditWrapper' className='mb-5'>
        {transferredCredit.map(({ displayName }) => (
          <div className='element' key={displayName}>
            <p className='course-name'>{displayName}</p>
            <p className='credits'>4 credits</p>
          </div>
        ))}
        {!!generalElectiveCredits && (
          <div className='element' key='generalElectives' data-testid='generalElectives'>
            <p className='course-name'>General Electives</p>
            <p className='credits'>{generalElectiveCredits} credits</p>
          </div>
        )}
      </CreditWrapper>
      <Description className='mb-5'>Your Academic Advisor will answer any questions about your transfer credits and
        prerequisites you have satisfied during your scheduled call later in the process. Let’s continue to your
        personalized recommended courses for the first semester.
      </Description>
      <ContinueButton onClick={handleClick}>Continue</ContinueButton>
    </PageWrapper>
  )
}

export default TransferCreditConfirmation
