import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { getCohortType, getCommitment } from '../../utilities/cohort'
import { formatDateWithDay, changePTDateToLocalTime } from '../../utilities/dateTime'
import { PrimaryButton } from '../Buttons/style'
import {
  ReviewCohort,
  TransferContent,
  CohortDetailsContainer
} from './style'
import api from '../../api'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { AUDIT, SIGNED_UP } from '../../Constants/studentStatus'

const CohortDetails = ({
  newCohort,
  title,
  cohort
}) => {
  const {
    duration,
    dateStart,
    finalExamEndTime
  } = cohort || {}

  const convertedDateStart = changePTDateToLocalTime(dateStart)
  const convertedEndTime = changePTDateToLocalTime(
    finalExamEndTime?.split('T')[0]
  )

  return (
    <CohortDetailsContainer data-testid='cohort-details' newCohort={newCohort}>
      <p className='title'>
        {title}
      </p>
      <p>{`${duration}wk ${getCohortType(duration)}`}</p>
      <p>
        {`${formatDateWithDay(convertedDateStart)} - ${formatDateWithDay(convertedEndTime)}`}
      </p>
      <p>{`${getCommitment(duration)}hr/wk Commitment`}</p>
    </CohortDetailsContainer>
  )
}

const TransferReview = ({
  courseId,
  originalCohort,
  newCohort,
  reloadContext,
  history
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const transferCohort = async () => {
    const { id, studentStatus } = originalCohort

    setIsLoading(true)
    const response = await api.transferCohort(
      courseId,
      {
        newCohortId: newCohort.id,
        oldCohortId: id,
        statusName: studentStatus === AUDIT ? AUDIT : SIGNED_UP
      }
    )
    setIsLoading(false)

    const { success, message } = response
    if (!success) {
      console.error('Error when transferring student cohort: ', message)
      return
    }

    reloadContext()
    history.push('/confirmation')
  }

  if (isLoading) return <LoadingSpinner />

  return (
    <TransferContent>
      <ReviewCohort>
        <CohortDetails
          newCohort
          title='new cohort'
          cohort={newCohort}
        />
        <CohortDetails
          title='original cohort'
          cohort={originalCohort}
        />
      </ReviewCohort>
      <PrimaryButton
        width='140px'
        data-testid='btn-confirm'
        onClick={transferCohort}
      >
        confirm
      </PrimaryButton>
    </TransferContent>
  )
}

TransferReview.displayName = 'TransferReview'

TransferReview.propTypes = {
  newCohort: PropTypes.object,
  originalCohort: PropTypes.object,
  reloadContext: PropTypes.func,
  history: PropTypes.object,
  courseId: PropTypes.string
}

export default TransferReview
