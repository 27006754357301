import styled, { createGlobalStyle } from 'styled-components'
import { Modal } from 'react-bootstrap'
import { PrimaryButton } from '../../Buttons/style/index'

export const GlobalStyle = createGlobalStyle`
  .modal-dialog.tiny-modal {
    width: 280px;
    display: flex;
    align-items: center;
    margin: auto;
  }

  .modal-content {
    background-color: #25272B;
  }
`

export const AlertModalHeaderWrapper = styled(Modal.Header)`
  border-bottom: none;
  padding-top: 40px;
  padding-bottom: 0px;
`

export const AlertModalTitleWrapper = styled(Modal.Title)`
  font-weight: bold;
  font-size: 15px;
  line-height: 13px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`

export const AlertIcon = styled.img`
  margin-right: 10px;
`

export const AlertModalParagraph = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  padding: 0 30px;
  margin-bottom: 0;
`

export const AlertModalFooterWrapper = styled(Modal.Footer)`
  border-top: none;
  justify-content: center;
  padding-bottom: 35px;
  padding-top: 10px;
`

export const AlertModalButton = styled(PrimaryButton)`
  min-width: 120px;
  width: auto;
`
