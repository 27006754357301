import React from 'react'
import WhatsNextWidget from '../WhatsNextWidget'
import { ASSIGNMENT_PATH_NAME } from '../../../../Constants'
import {
  getTimezoneShort,
  secondsToDateString,
  secondsToFormattedDateTimeShort
} from '../../../../utilities/dateTime'

function OpenAssignment ({ assignments, desktopHero, courseBaseUrl }) {
  if (assignments.length > 1) {
    return (
      <div data-testid='open-multiple-assignments'>
        <WhatsNextWidget
          description={`Visit the Course Homepage to view the ${assignments.length} open assignments.`}
          primaryButtonText='Visit Home'
          primaryButtonFunction={() => (window.location.href = courseBaseUrl)}
          desktopHero={desktopHero}
          caughtUpText='You’re caught up on coursework!'
        />
      </div>
    )
  }

  const {
    title,
    lockTime,
    chapter_uuid: assignmentUUID,
    type: chapterType
  } = assignments[0]

  const pathname = ASSIGNMENT_PATH_NAME[chapterType]
  const formattedLockTime = secondsToFormattedDateTimeShort(
    lockTime,
    'short'
  ).replace(', ', ' at ')
  const timezone = getTimezoneShort(secondsToDateString(lockTime))

  return (
    <div data-testid='open-unsubmitted-assignment'>
      <WhatsNextWidget
        description={`The assignment ${title} is open until ${formattedLockTime} ${timezone}.`}
        primaryButtonText='Start'
        primaryButtonFunction={() =>
          (window.location.href = `${courseBaseUrl}/#/${assignmentUUID}/${pathname}`)}
        desktopHero={desktopHero}
        caughtUpText='You’re caught up on coursework!'
      />
    </div>
  )
}

OpenAssignment.displayName = 'OpenAssignment'
export default OpenAssignment
