import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import { PrimaryButton, SecondaryButton } from '../../Buttons/style'
import { LinkStyles } from '../../Links/style'

export const PageWrapper = styled.div`
  position: relative;
`
export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
  ${mediaqueries.mobile`
    display: block;
  `}
`

export const BoxWrap = styled.div``

export const CardText = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: #fff;
`

export const EnrolText = styled.p`
  font-weight: normal;
  font-size: 16px;
  color: #fff;
  text-transform: capitalize;
`
export const EnrollSpan = styled.span``

export const Button = styled.button`
  background-color: unset;
  border: none;
  margin: 8px 24px 24px 0;
  outline: none !important;
  padding: 0;
  ${LinkStyles}
`

export const ThisSelectionText = styled.p`
  font-weight: normal;
  font-style: italic;
  font-size: 16px;
  line-height: 19px;
  color: #b1bfc5;
  margin: 24px 0;
`

export const Line = styled.div`
  width: 100%;
  background-color: #5c5c5d;
  height: 1px;
`

export const AddCourseBox = styled.div``

export const AddCourseText = styled.p`
  color: #fff;
  font-size: 30px;
  font-weight: 300;
`

export const EnrollText = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
`

export const PreviewBox = styled.div`
  display: flex;
  align-items: center;
`

export const PreviewText = styled.p`
  font-size: 20px;
  color: #5fc4b8;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
`

export const PreviewIcon = styled.img`
  width: 25px;
  margin-left: 20px;
`

export const CostText = styled.p`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 36px;
  margin-bottom: 32px;
`

export const AddCourseButton = styled.button`
  background-color: #5fc4b8;
  color: #161618;
  padding: 20px 30px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 24px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  outline: none !important;
  margin-top: 0px;
`

export const QuestionIcon = styled.img`
  position: fixed;
  right: 33px;
  bottom: 15px;
  width: 35px;
  background-color: #000;
  padding: 10px;
  border-radius: 50%;
  @media screen and (max-width: 767px) {
    bottom: 10px;
    right: 7px;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    right: 9px;
    bottom: 13px;
  }
`

// Sidebar
export const SidebarWrap = styled.div`
  position: fixed;
  top: 0px;
  z-index: 99;
  right: ${props => props.slideIn ? '0px' : '-400px'};
  background: #25272b;
  max-width: 375px;
  height: 100vh;
  transition: all 0.5s;
  min-width: 375px;
  transition: right 0.3s;
  &:before {
    content: "";
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: -1;
  }

  ${mediaqueries.smallMobile`
    max-width: unset;
    width: 100vw;
  `}
`

export const SidebarHeader = styled.div`
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  h5 {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0px;
  }
  img {
    cursor: pointer;
    width: 14px;
    height: 14px;
  }
`

export const SidebarBody = styled.div`
  padding: 0px 24px;
  height: ${({ isEdit }) => isEdit ? '93%' : 'calc(100% - 201px)'};
  overflow: auto;
  p {
    margin-bottom: 4px;
    margin-left: 0px;
  }
  span{
    top: 8px !important;
  }
  .date-wrap{
    margin-top: 2px;
  }
`
export const BodyItem = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  opacity: ${({ active }) => active ? 0.3 : 1};
  touch-action: ${({ active }) => active ? 'none' : 'auto'};
  span{
    margin-left: 0px;
  }
  img{
    width: 16px !important;
    height: 16px !important;
    margin-top: 0px !important;
  }
`
export const TotalText = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #b1bfc5;
  margin-top: 12px;
`

export const SidebarFooter = styled.div`
  padding: 24px;
`

export const TokenWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #b1bfc5;
  position: absolute;
  top: 4rem;
  right: 7rem;
  ${mediaqueries.mobile`
    width: 100%;
    position: unset;
  `}
  span {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #5fc4b8;
    margin-left: 6px;
    margin-top: 3px;
    cursor: pointer;
  }
  img {
    color: #5fc4b8;
    width: 20px;
    height: 24px;
    cursor: pointer;
  }
  .bag-wrap {
    margin-left: 24px;
    position: relative;
    ${mediaqueries.mobile`
      margin-left: auto;
    `}
    span {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #5fc4b8;
      margin: 0px;
      position: absolute;
      left: 7.5px;
      top: 9px;
    }
  }
`

export const CoursesNotFound = styled.h5`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  padding: 24px;
`

export const AlertModalParagraph = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 24px;
  margin-bottom: 0;
  color: #ffffff;
`

export const AlertModalFooterWrapper = styled.div`
  display: flex;
  padding: 0px 24px;
  padding-bottom: 24px;
  ${mediaqueries.mobile`
    display:block;
  `}
  button {
    width: 113px;
    margin-right: 16px;
    height: 49px;
    ${mediaqueries.mobile`
        width:100%;
        margin-right:0px;
        margin-bottom:16px;
  `}
  }
`
export const ReviewButton = styled(PrimaryButton)`
  background: #5fc4b8;
`

export const ExploreButton = styled(SecondaryButton)`
  border: 1px solid #5fc4b8;
  box-sizing: border-box;
  border-radius: 5px;
  color: #5fc4b8;
  margin-bottom: 12px;
`
