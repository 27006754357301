import React, { useContext, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import {
  CardWrapper,
  CardImageWrapper,
  CardImageTextWrapper,
  CourseTextWrapper,
  CardCourseDescriptionWrapper,
  CourseDescription,
  CourseIcon,
  CardCol,
  CourseDescriptionWrapper,
  FinalGradeText,
  Caption,
  CaptionContainer
} from './style'
import PropTypes from 'prop-types'
import { getCourseAssets } from '../../utilities/course'
import { AppContext } from '../ContextProvider/ContextProvider'
import checkIcon from '../../assets/icons/check.svg'
import { AUDIT } from '../../Constants/studentStatus'
import { captionByCourse, headerByCourse } from '../../Constants/enrollment'
import config from '../../config'

const CourseCard = ({
  course,
  activeCard,
  setActiveCard,
  className,
  alternatives,
  history
}) => {
  const { displayName = '', description = '', id, image = '', type } = course
  const { localCourses, catalogCourses } = useContext(AppContext)
  const userCart = useMemo(
    () => JSON.parse(localStorage.getItem('userCart')) || [],
    // eslint-disable-next-line
    [localCourses?.length]
  )
  const additionalIds = config.getAdditionalCourseIdsById(id)
  const alreadySelected = userCart?.find((item) => additionalIds?.includes(item?.id))

  if (isEmpty(id)) return null
  const handleCourseClick = (e) => {
    e.preventDefault()
    setActiveCard && setActiveCard(id)
    history.push({
      pathname: `/catalog/${id}`,
      state: { type }
    })
  }

  const { courseImage, courseIcon } = getCourseAssets(catalogCourses, id)
  const courseEnrollmentStatus = alternatives ? type === AUDIT ? ' (Audit)' : '' : ''
  const captionByCourseId = alternatives ? captionByCourse?.[id] || '' : ''
  const caption = typeof captionByCourseId === 'string'
    ? captionByCourseId : captionByCourseId[type.toLowerCase()]?.split(' . ')
  const optionsHeader = headerByCourse?.[id] || ''

  return (
    <CardCol className={className}>
      <CardWrapper
        data-testid='courseCard'
        onClick={handleCourseClick}
      >
        <CardImageWrapper
          showBorder={alternatives && optionsHeader.toLowerCase() === 'recommended'}
          src={course.previous || alternatives ? courseImage : image}
          className={
            activeCard === id || (!course.previous && !alternatives && alreadySelected)
              ? 'active'
              : ''
          }
        >
          <FinalGradeText data-testid='finalGrade'>
            {alternatives && <span>{optionsHeader}</span>}
            {activeCard === id || (!course.previous && !alternatives && alreadySelected) ? (
              <div>
                <img src={checkIcon} alt='' className='checkIcon' />
                Selected
              </div>
            ) : course.previous ? (
              course?.completedDate ? (
                <span className='taken'>Taken {course?.completedDate}</span>
              ) : (
                course?.status
              )
            ) : null}
          </FinalGradeText>
          <CourseIcon src={courseIcon} />
          <CardImageTextWrapper>
            <CardCourseDescriptionWrapper className='d-flex'>
              <CourseDescription>
                <CourseTextWrapper>{`${displayName}${courseEnrollmentStatus}`}</CourseTextWrapper>
                <CourseDescriptionWrapper>
                  <span dangerouslySetInnerHTML={{ __html: description }} />
                </CourseDescriptionWrapper>
                {alternatives && caption &&
                  typeof caption === 'string'
                  ? (
                    <Caption>{caption}</Caption>
                  ) : Array.isArray(caption) ? (
                    <CaptionContainer>
                      <Caption>{caption?.[0]}</Caption>
                      <Caption className='dot'>.</Caption>
                      <Caption>{caption?.[1]}</Caption>
                    </CaptionContainer>
                  ) : null}
              </CourseDescription>
            </CardCourseDescriptionWrapper>
          </CardImageTextWrapper>
        </CardImageWrapper>
      </CardWrapper>
    </CardCol>
  )
}

CourseCard.propTypes = {
  course: PropTypes.object.isRequired,
  activeCard: PropTypes.string
}

CourseCard.displayName = 'CourseCard'
export default CourseCard
