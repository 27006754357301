import React, { useContext, useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import utils from '../../utilities'

import {
  CardWrapper,
  CardImageWrapper,
  CardImageTextWrapper,
  CourseTextWrapper,
  CourseDescriptionWrapper,
  CourseLearnMoreLink,
  StyledRow,
  CourseIcon,
  CourseDescription,
  CardCol
} from '../MyCourses/style'
import { AppContext } from '../ContextProvider/ContextProvider'
import { getCourseAssets } from '../../utilities/course'
import { courseUTMKeys } from '../../Constants/courseUTMKey'

const handleCourseClick = (link, displayName) => {
  const courseKey = courseUTMKeys[`${displayName}`]
  const UTMParams = `?utm_source=website&utm_medium=lifecycle&utm_campaign=studentdash&utm_term=${courseKey}&utm_content=recs`
  window.location.href = `${link}${UTMParams}`
}

const ExploreMoreCourses = ({ courses }) => {
  const { catalogCourses } = useContext(AppContext)
  const coursesToDisplay = useMemo(() => {
    return utils.shuffleArray(
      courses?.filter(course => !isEmpty(course.id))
    ).slice(0, 2)
  }, [courses])

  return (
    <StyledRow>
      {coursesToDisplay?.map(function (course, key) {
        const { id, displayName, link, description } = course
        const {
          courseImage,
          courseIcon
        } = getCourseAssets(catalogCourses, id)

        return (
          <CardCol key={key} xs={12} sm={6} md={6} lg={6}>
            <CardWrapper
              data-testid='explore-more-courses'
              onClick={() => handleCourseClick(link, displayName)}
            >
              <CardImageWrapper src={courseImage}>
                <CardImageTextWrapper className='d-flex'>
                  <CourseIcon src={courseIcon} />
                  <CourseDescription>
                    <CourseTextWrapper>{displayName}</CourseTextWrapper>
                    <CourseDescriptionWrapper dangerouslySetInnerHTML={{ __html: description }} />
                    <CourseLearnMoreLink>Learn More</CourseLearnMoreLink>
                  </CourseDescription>
                </CardImageTextWrapper>
              </CardImageWrapper>
            </CardWrapper>
          </CardCol>
        )
      })}
    </StyledRow>
  )
}

export default ExploreMoreCourses
