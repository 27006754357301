import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  ItemContent, ItemContentArea, ItemTitle, ItemTooltip
} from './style'

function PageItemContent ({ title, content, tooltipText }) {
  return (
    <ItemContentArea>
      <ItemTitle>
        {title}
      </ItemTitle>

      {tooltipText && (
        <OverlayTrigger
          placement='right'
          overlay={(props) => (
            <Tooltip {...props}>
              {tooltipText}
            </Tooltip>
          )}
        >
          <ItemTooltip />
        </OverlayTrigger>
      )}

      <ItemContent>
        {content || ''}
      </ItemContent>
    </ItemContentArea>
  )
}

export default PageItemContent
