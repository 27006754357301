import { DESMOS_SCRIPT_LINK } from '../../../../Constants'

export const loadDesmos = callBack => {
  if (window.Desmos) return

  const script = document.createElement('script')
  script.id = 'desmosScript'
  script.src = DESMOS_SCRIPT_LINK
  script.onload = () => {
    callBack(false)
  }
  document.body.appendChild(script)

  callBack(true)
}

let calculator = null

export const getDesmosCalculator = expression => {
  const element = document.getElementById('calculator')
  if (!element) return calculator

  if (!window.Desmos) return calculator

  calculator = window.Desmos.GraphingCalculator(element)
  const { settings, state } = expression
  calculator.updateSettings(settings)
  calculator.setState(state)
  calculator.updateSettings({
    settingsMenu: false,
    zoomButtons: false,
    keypad: false,
    expressions: false,
    backgroundColor: '#4f515a'
  })

  return calculator
}

export const resetExpression = () => {
  const calculator = document.querySelector('#calculator')
  calculator.innerHTML = ''
}
