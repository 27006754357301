import styled, { css } from 'styled-components'
import mediaqueries from '../../mediaQueries'

export const DashboardContainer = styled.div`
  --layout-max-width: 1312px;
  --mobile-layout-padding: 24px;
  --desktop-layout-padding: 64px;
  height: 100%;

  ${mediaqueries.mobile`
    position: relative;
    padding: 16px var(--mobile-layout-padding) 0;
  `}
`

const buttonStyles = css`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  display: flex;
  height: 57px;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: ${(props) => props.width || '100%'};

  ${mediaqueries.mobile`
    height: 42px;
    font-size: 12px;
    line-height: 15px;
  `}
    :active {
    opacity: 0.3;
  }

  :focus {
    outline: none;
  }
  :focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
`

export const PrimaryButton = styled.button`
  ${buttonStyles}
  background-color: #5fc4b8;
  color: #1c1d21;

  &:hover {
    background-color: #b8fff7;
  }
`

export const SecondaryButton = styled.button`
  ${buttonStyles}
  color: #5FC4B8;
  border: 1px solid #5fc4b8;
  background: transparent;

  &:hover {
    color: #b8fff7;
    border-color: #b8fff7;
  }
`
export const SlideArrowsContainer = styled.div`
  & .splide__arrow--prev,
  .splide__arrow--next {
    display: none;
    background-color: transparent;
    border: none;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    z-index: 1;

    :active {
      opacity: 0.3;
    }

    :focus {
      outline: none;
    }
    :focus-visible {
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
  & .splide__arrow--prev {
    left: 0;
  }

  & .splide__arrow--next {
    right: 0;
  }

  & .splide__arrow--prev::before,
  .splide__arrow--next::before {
    content: '';
    display: block;
    height: 48px;
    width: 48px;
    background-repeat: no-repeat;
    background-position: center;
  }

  & .splide__arrow--prev::before {
    background-image: url('/images/icons/arrow-up.svg');
    transform: rotate(-90deg);
  }
  & .splide__arrow--next::before {
    background-image: url('/images/icons/arrow-up.svg');
    transform: rotate(90deg);
  }
`
export const CourseDescription = styled.p`
  color: #bababb;
  margin-top: 16px;
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  max-width: 601px;

  & span {
    color: #ffffff;
    margin: 0;
    display: flex;
    align-items: center;
  }
  img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
`
export const CourseCTAContainer = styled.div`
  display: flex;
  gap: 16px;
  max-width: 601px;

  ${mediaqueries.mobile`
    flex-direction: column;
    gap: 12px;
    max-width: revert;
  `}
`
export const LargeCelebratoryMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 16px;
  border-radius: 5px;
  padding: 12px 24px;
  background-color: #191631;
  color: #969bff;
  line-height: 19px;
  margin-top: 25px;
  height: 72px;
  max-width: 601px;

  strong {
    color: #ffffff;
  }

  p {
    margin: 0;
    margin-top: 8px;
  }

  img {
    width: 24px;
    height: 24px;
  }
`
export const SectionTitle = styled.h2`
  display: inline-block;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;

  & span {
    color: #bababb;
  }

  ${mediaqueries.mobile`
    font-size: 16px;
    line-height: 26px;
  `}
`
export const SectionLink = styled.a`
  color: #5fc4b8;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  display: inline-block;
  text-decoration: underline;
  text-underline-offset: 4px;

  ${mediaqueries.mobile`
    font-size: 16px;
    line-height: 24px;
  `}
`

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  ${mediaqueries.mobile`
    gap: 38px;
  `}
`
