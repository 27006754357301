import {
  weeksToSeconds,
  daysToSeconds,
  changeTimezone
} from './dateTime'

export {
  calculateLockDate,
  calculateUnlockDate
}

function calculateLockDate (unlockAfterDays, unlockDuration, cohortStartDate) {
  const unlockDatePST = calculateUnlockDate(unlockAfterDays, cohortStartDate)

  // Lock duration of zero means never unlock, so we make the unlock and lock
  // dates the same.
  if (unlockDuration === 0) return unlockDatePST

  // Calculate the relative lock date
  const seconds = weeksToSeconds(unlockDuration)
  unlockDatePST.setTime(unlockDatePST.getTime() + (seconds * 1000))

  // Convert date to UTC with time set to 12pm
  const lockDateUTC = new Date(unlockDatePST.getUTCFullYear(), unlockDatePST.getUTCMonth(), unlockDatePST.getUTCDate(), 12, 0)

  // Subtract 1 minute so we land on 11:59am
  lockDateUTC.setTime(lockDateUTC.getTime() - (60 * 1000))

  // Convert UTC back to PST
  const lockDatePST = changeTimezone(lockDateUTC, 'America/Los_Angeles')

  return lockDatePST
}

function calculateUnlockDate (unlockAfterDays, cohortStartDate) {
  // Sets the time to 1:01am to account for daylight savings. Without doing
  // this when we calculate the number of weeks it might end up landing
  // at 11am. This ensures that if we lose an hour due to daylight
  // savings it will be the same date, but at 12:01pm.
  const date = new Date(cohortStartDate)
  date.setUTCHours(1, 1)

  // Convert date to PST time.
  const datePST = changeTimezone(date, 'America/Los_Angeles')

  // Calculate the relative unlock date
  const seconds = daysToSeconds(unlockAfterDays)
  datePST.setTime(datePST.getTime() + (seconds * 1000))

  // Convert date to UTC with time set to 09:00am
  const unlockDateUTC = new Date(datePST.getUTCFullYear(), datePST.getUTCMonth(), datePST.getUTCDate(), 9, 0)

  // Convert UTC back to PST
  const unlockDatePST = changeTimezone(unlockDateUTC, 'America/Los_Angeles')

  return unlockDatePST
}
