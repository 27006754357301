import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import StudyMode from './StudyMode'
import { BackButton, Container, GlobalStyle } from './styles'
import ExpectedGraduation from './ExpectedGraduation'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import TransferCreditConfirmation from './TransferCreditConfirmation'
import RecommendedCourses from './RecommendedCourses'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  getCurrentProspect,
  hasActiveCourses as hasActiveCoursesFunction,
  isSegmentEventInLS,
  setFiredSegmentEventsInLS
} from '../../utilities/gguDegreeUtils'
import api from '../../api'
import { DEGREE_REGISTRATION_INCOMPLETE } from '../../Constants'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import { COURSE_REGISTRATION_STARTED } from '../../Constants/eventType'

const GGUEnrollmentFlow = () => {
  const { sendEvent } = useSegmentEvents()
  const [isLoading, setIsLoading] = useState(false)
  const {
    courses,
    studentData,
    updateContext,
    prospects
  } = useContext(AppContext) || {}
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location
  const shouldShowBackButton = pathname !== '/'
  const hasActiveCourses = hasActiveCoursesFunction(courses)

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_STARTED,
    pageNumber: '1-1'
  }

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    sendEvent({ eventName: COURSE_REGISTRATION_STARTED })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (studentData?.[DEGREE_REGISTRATION_INCOMPLETE]) return
    const currentProspect = getCurrentProspect(prospects)
    if (currentProspect?.tempCart?.length) return

    updateContext({
      studentData: {
        ...studentData,
        [DEGREE_REGISTRATION_INCOMPLETE]: true
      }
    })
    api.setStudentData(
      DEGREE_REGISTRATION_INCOMPLETE,
      { [DEGREE_REGISTRATION_INCOMPLETE]: true }
    )
  }, [studentData, updateContext, prospects])

  const getGoBackPath = () => {
    switch (pathname) {
      case '/expected-graduation':
        return '/'
      case '/transfer-credit-confirmation':
        return '/expected-graduation'
      case '/recommended-courses':
        return '/transfer-credit-confirmation'
      default: return null
    }
  }

  return (
    <Container className='page-container'>
      <GlobalStyle />
      {(isLoading) ? <LoadingSpinner /> : (
        <>
          {shouldShowBackButton && (
            <BackButton
              title='Go Back'
              clickHandler={() => {
                history.push({
                  pathname: getGoBackPath(),
                  state: { overrideVisitedPage: true }
                })
              }}
            />
          )}
          <Switch>
            <Route
              exact
              path='/'
              component={() => (
                <StudyMode
                  setIsLoading={setIsLoading}
                  showReturnToDashboard={hasActiveCourses}
                />
              )}
            />
            <Route exact path='/expected-graduation' component={ExpectedGraduation} />
            <Route exact path='/transfer-credit-confirmation' component={TransferCreditConfirmation} />
            <Route
              exact
              path='/recommended-courses'
              component={() => (
                <RecommendedCourses
                  setIsLoading={setIsLoading}
                  showReturnToDashboard={hasActiveCourses}
                />
              )}
            />
          </Switch>
        </>)}
    </Container>
  )
}

GGUEnrollmentFlow.displayName = 'GGUEnrollmentFlow'
export default GGUEnrollmentFlow
