export const APPLIED_COMPUTING = 'Applied Computing'
export const BUSINESS_ADMINISTRATION = 'Business Administration'
export const LIBERAL_STUDIES = 'Liberal Studies'

export const APPLIED_COMPUTING_INFO_LINK = 'https://catalog.ggu.edu/preview_program.php?catoid=11&poid=1234&returnto=547'
export const BUSINESS_ADMINISTRATION_INFO_LINK = 'https://catalog.ggu.edu/preview_program.php?catoid=11&poid=1233&returnto=547'
export const LIBERAL_STUDIES_INFO_LINK = 'https://catalog.ggu.edu/preview_program.php?catoid=11&poid=1235&returnto=547'

export const DEGREE_PLAN_WORKSHEET_LINK = 'https://drive.google.com/drive/folders/1woxovPsQqcuoIwJVIX3EKfGHhWMrSga9'

export const DEGREE_PLAN_WORKSHEETS = {
  [APPLIED_COMPUTING]: 'https://drive.google.com/file/d/1eYu1_lmr4QPfOhL-RfS7J_zXt7FKqosw/view?usp=share_link',
  [BUSINESS_ADMINISTRATION]: 'https://drive.google.com/file/d/1Do5JaXOtgJlYJHJwnJXbhZ9iUnIqWyWH/view?usp=share_link',
  [LIBERAL_STUDIES]: 'https://drive.google.com/file/d/1C_CXmFWuvRcpHqC1Rul5JrgrjWy36gF0/view?usp=share_link'
}

export const STUDY_MODE = {
  FULL_TIME: 'Full-time',
  PART_TIME: 'Part-time'
}
export const FULL_TIME_SLOT = 3
export const PART_TIME_SLOT = 2

export const COURSE_REQUIREMENT_APP_COMP = {
  Major: 6,
  Mathematics: 1,
  'Social Science': 1,
  Communication: 3,
  'Problem Solving': 1,
  Elective: 3
}

export const COUSE_REQUIREMENT_BUS_ADMIN = {
  Major: 6,
  Mathematics: 1,
  'Social Science': 1,
  Communication: 3,
  'Problem Solving': 1,
  Elective: 3
}

export const COURSE_REQUIREMENT_LIB_STUD = {
  Mathematics: 1,
  'Social Science': 1,
  Communication: 3,
  'Problem Solving': 1,
  Elective: 9
}

export const COURSE_QUANTITY_TOOLTIP = 'The amount of financial aid you may be eligible for depends on the number of credits you\'re taking. You must be enrolled in at least 2 courses to be eligible for any financial aid.'

export const SEGMENT_EVENTS_V1_LS_KEY = 'existingSegmentEvents'
export const SEGMENT_EVENTS_V2_LS_KEY = 'redesignedFlowSegmentEvents'

export const COURSE_QUANTITY_LS_KEY = 'degreeRegistrationCourseQuantity'
export const SELECTED_COURSE_LS_KEY = 'currentSelectedCourseId'
export const RECOMMENDED_COURSES_LS_KEY = 'recommendedCourses'

export const RECOMMENDED_COURSES_BUTTON_STATE_IDENTIFIER = 'RECOMMENDED_COURSES_BUTTON_STATE_PROSPECT'
