import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { PrimaryButton, SecondaryButton } from './QuestionComponent/style'
import {
  GlobalStyle,
  ExitModalHeader,
  Separator,
  ButtonsContainer
} from './style'
import CloseIcon from '../../assets/icons/close-icon.svg'
import utils from '../../utilities'

const StudyBreakModal = ({ show, setShow }) => {
  const closeModal = () => {
    setShow(false)
  }

  const gotoDetailsPage = () => {
    window.location.hash = utils.getDetailsPagePath()
  }

  return (
    <Modal show={show} centered>
      <GlobalStyle />
      <ExitModalHeader>
        <p>Looks like you need a refresher!</p>
        <img
          data-testid='img-close'
          src={CloseIcon}
          alt='Close Exit Modal'
          onClick={closeModal}
        />
      </ExitModalHeader>
      <Separator />
      <Modal.Body>
        <p>
          We suggest you take a study break and come back for a fresh start
          when you feel ready.
        </p>
        <ButtonsContainer>
          <PrimaryButton
            data-testid='btn-take-break'
            onClick={gotoDetailsPage}
          >
            take a break
          </PrimaryButton>
          <SecondaryButton
            data-testid='btn-keep-going'
            onClick={closeModal}
          >
            keep going
          </SecondaryButton>
        </ButtonsContainer>
      </Modal.Body>
    </Modal>
  )
}

StudyBreakModal.diaplayName = 'StudyBreakModal'

export default StudyBreakModal
