import React, { useState } from 'react'
import PermissionRequested from './PermissionRequested'
import PermissionRequired from './PermissionRequired'
import AwaitingPermission from './AwaitingPermission'
import {
  PERMISSION_REQUIRED,
  PERMISSION_REQUESTED,
  PERMISSION_AWAITED,
  PERMISSION_GRANTED
} from './permissionStates'
import WelcomePage from '../WelcomeMessage/WelcomePage'

const GuardianPermission = ({
  initialState
}) => {
  const [permissionState, setPermissionState] = useState(initialState ||
    PERMISSION_REQUIRED)
  const [email, setEmail] = useState('')

  if (permissionState === PERMISSION_REQUIRED) {
    return (
      <PermissionRequired
        email={email}
        setEmail={setEmail}
        setPermissionState={setPermissionState}
      />
    )
  }

  if (permissionState === PERMISSION_REQUESTED) {
    return (
      <PermissionRequested
        guardianEmail={email}
        setEmail={setEmail}
        setPermissionState={setPermissionState}
      />
    )
  }

  if (permissionState === PERMISSION_AWAITED) {
    return (
      <AwaitingPermission
        guardianEmail={email}
        setEmail={setEmail}
        setPermissionState={setPermissionState}
      />
    )
  }

  if (permissionState === PERMISSION_GRANTED) {
    return <WelcomePage />
  }

  return null
}

GuardianPermission.displayName = 'GuardianPermission'
export default GuardianPermission
