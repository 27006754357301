import React, { useContext, useEffect } from 'react'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import api from '../../api'
import { GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import { getDateStringWithYearLongMonth } from '../../utilities/dateTime'
import { getCurrentProspect, getRecommendedCohorts, isSegmentEventInLS, removeSegmentEventFromLS, setFiredSegmentEventsInLS } from '../../utilities/gguDegreeUtils'
import { AppContext } from '../ContextProvider/ContextProvider'
import CourseSlot from './CourseSlot'
import {
  Page, PrimaryButton, Term, Title
} from './style'
import { COURSE_REGISTRATION_PAGEVIEW, COURSE_REGISTRATION_PAGE_SUBMITTED, REGISTERED_FOR_COURSES } from '../../Constants/eventType'

const Review = ({
  currentCourseNumber,
  courseQuantity,
  recommendedCourses,
  setCurrentPage,
  setIsLoading
}) => {
  const { sendEvent } = useSegmentEvents()
  const {
    currentSemester,
    updateContext,
    outlierCourses,
    catalogCourses,
    prospects
  } = useContext(AppContext)

  const currentProspect = getCurrentProspect(prospects)
  const { semesterStart, term } = currentSemester || {}

  const formattedSemesterStart = getDateStringWithYearLongMonth(
    semesterStart, 'short'
  )

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: '5-1'
  }

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: `4-${currentCourseNumber + 1}`
    })
    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: `4-${(currentCourseNumber * 2) + courseQuantity + 1}`
    })
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'Final Review',
        page_number: '5-1'
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  const submitForReview = async () => {
    setIsLoading(true)
    await api.updateProspectData(
      currentProspect?.prospectId, {
        tempCart: getRecommendedCohorts({
          recommendedCourses,
          outlierCourses,
          semesterStart
        })
      }
    )
    const newProspects = await api.getProspectsData()
    setIsLoading(false)

    sendEvent({
      eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
      properties: {
        page_name: 'Final Review',
        page_number: '5-1'
      }
    })
    sendEvent({
      eventName: REGISTERED_FOR_COURSES,
      properties: {
        registeredCourse1: recommendedCourses?.[0]?.courseName || '',
        registeredCourse2: recommendedCourses?.[1]?.courseName || '',
        registeredCourse3: recommendedCourses?.[2]?.courseName || ''
      }
    })
    updateContext({ prospects: newProspects })
    setCurrentPage(GGU_V2_ENROLLMENT_PAGES.SCHEDULE_CALL)
  }

  return (
    <Page>
      <Title>
        Great work! Here's your course schedule for next term starting on {formattedSemesterStart}.
      </Title>
      <Term>{term}</Term>
      {recommendedCourses?.map((course, index) => {
        const { courseId } = course

        return (
          <CourseSlot
            key={courseId}
            index={index}
            course={course}
            semesterStart={formattedSemesterStart}
            catalogCourses={catalogCourses}
          />
        )
      })}
      <PrimaryButton onClick={submitForReview}>Submit</PrimaryButton>
    </Page>
  )
}

Review.displayName = 'Review'

export default Review
