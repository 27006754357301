import styled from 'styled-components'

export const Card = styled.div`
  background: #33373e;
  width: 327px;
  height: ${({ hasProgressBar }) => (hasProgressBar ? '94px' : '112px')};
  border-radius: 5px;
  margin: auto;
  margin-bottom: 18px;
  padding: 24px;
  cursor: pointer;
  :first-child {
    margin-top: 28px;
  }
  & > h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 19px;
    color: #ffffff;
  }
`

export const CourseListInfoContainer = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #b1bfc5;

  & .activityCount::after {
    content: "";
    width: 2px;
    display: ${({ hasAssessment }) => (hasAssessment ? 'block' : 'none')};
    height: 2px;
    background: #98a4ae;
    border-radius: 100%;
    margin-right: 8px;
    margin-left: 8px;
  }
`

export const CourseListInfoChildContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
`

export const CourseListContainer = styled.div`
  display: inherit;
  align-items: center;
`

export const ChecklistIconWrapper = styled(CourseListContainer)`
  color: ${({ color }) => color};
`

export const ChecklistIcon = styled.div`
  background-color: ${({ color }) => (color ? `${color}` : '#ffffff')};
  -webkit-mask-image: url("${({ src }) => src}");
  -webkit-mask-position: right;
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  ${({ flex }) => flex && 'flex: 1;'}
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  mask-image: url("${({ src }) => src}");
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`};
`

export const ProgressBarContainer = styled.div`
  height: 3px;
  width: 100%;
  position: relative;
`

export const BaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  transition: width 1s ease-in-out;
`
export const ProgressBarBackground = styled(BaseBox)`
  background: #5E636B;
  width: 100%;
`
export const ProgressBar = styled(BaseBox)`
  background: ${({ color }) => color};
  width: ${({ percent }) => percent}%;
`

export const CourseStartDate = styled.p`
  color: #b1bfc5;
  font-weight: normal;
  margin: 0px;
  line-height: 19px;
  font-size: 16px;
`

export const IsCaughtUp = styled.p`
  display: flex;
  gap: 8px;
  font-size: 16px;
  font-weight: normal;
  margin: 0px;
  line-height: 19px;
  color: #969bff;
`
