import React, { useEffect, useState } from 'react'

import {
  DetailHeader,
  CheckListWrap,
  CourseStatus,
  CaughtUpWrap, FinalNoteTitle, FinalNoteDescription, DetailWrapper
} from './styles'
import { ChecklistIcon, CourseStartDate } from '../CourseListCard/styles'
import {
  getAssessments,
  getDetailHeaderNote,
  getFinalExamNoteText
} from './ActivitiesDetailHeaderUtils'
import { VIOLET } from '../checklistColors'
import ActivitiesMessageCard from '../CourseActivities/ActivitiesMessageCard'
import { getLatestCohort } from '../../../utilities/course'
import { isUserAuditor } from '../../../utilities/user'

const ActivitiesDetailHeader = ({
  course,
  previousSection,
  hasPastOneWeekActivities,
  hasPastTwoWeeksActivities,
  hasCohortStarted,
  scheduleSection1,
  studentData,
  isIntensive,
  twoWeeksActivitiesClearedButNotOneWeek,
  allPastActivitiesCleared,
  isGGUCohort,
  isFinalExamCompleted
}) => {
  const [detailHeaderNote, setDetailHeaderNote] = useState(null)

  const isTwoWeeksBehindRecommendedActivities = hasPastTwoWeeksActivities
  const isOneWeekBehindRecommendedActivities = hasPastOneWeekActivities

  const {
    id: courseId,
    isWorkingAhead,
    hasWorkedAheadUntilExam,
    hasAssessmentAfterTermBreak,
    assessmentAfterTermBreakType,
    weekProgress,
    isCaughtUp
  } = course

  const latestCohort = getLatestCohort(course)
  const isAudit = isUserAuditor(latestCohort)

  useEffect(() => {
    let headerNote
    if (isFinalExamCompleted) {
      headerNote = getFinalExamNote()
    } else {
      headerNote = getDetailHeaderNote({
        course,
        hasCohortStarted,
        previousSection,
        scheduleSection1,
        studentData,
        isIntensive
      })
    }
    setDetailHeaderNote(headerNote)
    return () => {
      if (!isFinalExamCompleted) return
      const finalNoteKey = `${courseId}_displayFinalNote`
      const displayFinalNote = localStorage.getItem(finalNoteKey)
      if (displayFinalNote === 'true') {
        localStorage.setItem(finalNoteKey, false)
      }
    }
    // eslint-disable-next-line
  }, [])

  const getFinalExamNote = () => {
    const [finalNoteTitle, finalNoteDescription] =
    getFinalExamNoteText(courseId, isCaughtUp) || []
    if (!finalNoteTitle) { return null }
    const finalNote =
      <>
        <FinalNoteTitle>
          {finalNoteTitle}
        </FinalNoteTitle>
        {finalNoteDescription && (
          <FinalNoteDescription>
            {finalNoteDescription}
          </FinalNoteDescription>
        )}
      </>
    return finalNote
  }
  const { startDate } = course?.courseResourcesSchedule?.[0] || {}
  const hasWorkedAhead =
    localStorage.getItem(`${courseId}_hasWorkedAhead`)
  const hasWorkedAheadAndNotCaughtUp = hasWorkedAhead && !isCaughtUp
  const showActivitiesCard = (
    isCaughtUp ||
    hasWorkedAheadAndNotCaughtUp ||
    isOneWeekBehindRecommendedActivities ||
    isTwoWeeksBehindRecommendedActivities ||
    twoWeeksActivitiesClearedButNotOneWeek ||
    allPastActivitiesCleared
  ) && !isFinalExamCompleted
  const assessments = getAssessments(course)
  const checklistIcon = () => (
    <CheckListWrap data-testid='checklistIcon'>
      <ChecklistIcon
        width='14px'
        height='10.5px'
        color='#B1BFC5'
        marginRight='0px'
        src='images/icons/checklist.svg'
      />
      <p>{course?.activityCount || 0}</p>
    </CheckListWrap>
  )
  const caughtUpIcon = () => (
    <CaughtUpWrap data-testid='caughtUpIcon'>
      <ChecklistIcon
        width='14px'
        height='10.5px'
        color={VIOLET}
        marginRight='6px'
        src='images/icons/icon-checkmark-md.svg'
      />
      <p>Caught Up</p>
    </CaughtUpWrap>
  )

  const activitiesMessageCard = () => (
    <>
      {showActivitiesCard && (
        <ActivitiesMessageCard
          courseId={courseId}
          weekProgress={weekProgress}
          hasWorkedAheadAndNotCaughtUp={hasWorkedAheadAndNotCaughtUp}
          hasWorkedAheadUntilExam={hasWorkedAheadUntilExam}
          hasAssessmentAfterTermBreak={hasAssessmentAfterTermBreak}
          assessmentAfterTermBreakType={assessmentAfterTermBreakType}
          isTwoWeeksBehindRecommendedActivities={isTwoWeeksBehindRecommendedActivities}
          isOneWeekBehindRecommendedActivities={isOneWeekBehindRecommendedActivities}
          twoWeeksActivitiesClearedButNotOneWeek={twoWeeksActivitiesClearedButNotOneWeek}
          allPastActivitiesCleared={allPastActivitiesCleared}
          isWorkingAhead={isWorkingAhead}
          isCaughtUp={isCaughtUp}
          assessments={assessments}
        />
      )}
    </>
  )
  const courseStartDate = () => (
    <CourseStartDate data-testid='courseStartDate'>
      Begins {startDate}
    </CourseStartDate>
  )

  const showDetailHeaderNote = isGGUCohort
    ? hasCohortStarted && detailHeaderNote
    : detailHeaderNote

  const showActivitiesMessageCard = hasCohortStarted && !isAudit

  return (
    <DetailWrapper>
      <DetailHeader>
        <h6 data-testid='courseName'>{course?.displayName}</h6>
        {hasCohortStarted
          ? isCaughtUp && !isAudit ? caughtUpIcon() : checklistIcon()
          : null}
      </DetailHeader>

      {showActivitiesMessageCard && activitiesMessageCard()}

      {!hasCohortStarted && courseStartDate()}

      {showDetailHeaderNote ? (
        <CourseStatus data-testid='courseStatus'>
          {detailHeaderNote}
        </CourseStatus>)
        : null}
    </DetailWrapper>
  )
}

ActivitiesDetailHeader.displayName = 'ActivitiesDetailHeader'
export default ActivitiesDetailHeader
