import styled from 'styled-components'
import { PrimaryButton } from '../../../style'
import mediaqueries from '../../../../../mediaQueries'
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close-icon.svg'

export const LargePopupContainer = styled.div`
  position: fixed;
  bottom: 40px;
  width: 100%;
  padding: 0 8px;
  z-index: 99999;
  ${mediaqueries.mobile`
    bottom: 8px;
   `}
  & > div {
    position: relative;
    padding: 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1090px;
    min-height: 126px;
    background: #25272b;
    border-radius: 5px;
    margin: 0 auto;
    gap: 16px;
    ${mediaqueries.mobile`
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
    `}
  }
`
export const PopupTitle = styled.h5`
  font-size: 23px;
  font-weight: 400;
  line-height: 33px;
  ${mediaqueries.mobile`
    font-size: 17px;
    line-height: 24px;
  `}
`
export const PopupSubtitle = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  color: #bababb;
  ${mediaqueries.mobile`
    display: none;
  `}
`
export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  ${mediaqueries.mobile`
    gap: 20px;
    flex-direction: row-reverse;
  `}
`
export const ButtonAsLink = styled.button`
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  padding: 0;
  color: #fff;
  &:hover {
    color: #fff;
  }
  &:active {
    opacity: 0.3;
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
  ${mediaqueries.mobile`
    letter-spacing: 1.7px;
    font-size: 12px;
    line-height: 15px;
  `}
`
export const CTAButton = styled(PrimaryButton)`
  width: 241px;
  ${mediaqueries.mobile`
    width: 144px;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    height: 42px;
    padding: 16px 24px 16px 24px;
  `}
`
export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  display: none;
  ${mediaqueries.mobile`
    display: block;
    position: absolute;
    top: 13px;
    right: 13px;
  `}
`
