import config from '../config'
import { PROCTORIO } from '../Constants'
import { is39WeekCohort } from './course'

export const formatTag = str => {
  return str.replace(/\s+/g, '')
}

export const getProctorioExamTag = (cohortName, chapter, cohortStartDate) => {
  const {
    title,
    chapter_uuid: uuid
  } = chapter

  const shouldUseUUID = shouldUseExamUUIDInExamTag(cohortStartDate)
  const examTitle = shouldUseUUID ? uuid : title

  return `Outlier${formatTag(cohortName)}${formatTag(examTitle)}`
}

const shouldUseExamUUIDInExamTag = cohortStartDate => {
  return cohortStartDate >= config.proctorioExamUUIDReleaseDate
}

export const getProctorioExamTake = (courseBaseUrl, examUUID, firstQuestionUUID) => {
  return `${courseBaseUrl}/?${PROCTORIO}=true#/${examUUID}/${firstQuestionUUID}`
}

export const getProctorioCourseHomeUrl = (courseBaseUrl, token, launchUrl) => {
  return `${courseBaseUrl}/?authToken=${encodeURIComponent(token)}&launchUrl=${encodeURIComponent(launchUrl)}`
}

export const allowLiveProctoring = (course) => {
  const liveProctoring = course?.statusData?.[0]?.relationship?.fields?.liveProctoring
  const is39WKCohort = is39WeekCohort(course)

  return is39WKCohort && liveProctoring
}
