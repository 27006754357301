import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import '../style/mathjax.css'
import { renderMathJax } from './mathjaxScript'

const MathJax = props => {
  const updateMathString = (rawMath = '') => {
    if (isEmpty(rawMath)) return rawMath
    // questions coming from backend were wrapped in p tags
    // Replacing them with div, to allow nested divs
    // <p> might contain attributes which must be preserved
    // <p> might contain a class, we need to merge our class with it.
    let math = rawMath.replace(/<p(.*?)>/g, (match, p1 = '') => {
      const [matchString, className] = (p1.match(/class=['|"](.*?)['|"]/i) || ['', ''])
      return `<div class='paragraph ${className}' ${p1.replace(matchString, '')}>`
    })
    math = math.replace(/<\/p>/g, '</div>')
    math = math.replace(/(<\/math>)?<math/g, (match, p1) => {
      return p1 ? match : '<div class=\'single_equation\'><math'
    })
    math = math.replace(/(<\/em>)?<em/g, (match, p1) => {
      return p1 ? match : '<span><em'
    })
    math = math.replace(/<\/em>(\s*(\.|\?|,|;|:|!))?(<em)?/g, (match, p1 = '', p2, p3) => {
      return p3 ? match : `</em>${p1}</span>`
    })
    math = math.replace(/<\/math>(\s*(\.|\?|,|;|:|!))?(<math)?/g, (match, p1 = '', p2, p3) => {
      return p3 ? match : `</math>${p1}</div>`
    })
    return math
  }

  const { math: rawMath, onMathJaxWillRender, onMathJaxDidRender } = props
  const previewRef = useRef()
  const math = updateMathString(rawMath)

  useEffect(() => {
    renderMathJax({
      math,
      onMathJaxDidRender,
      onMathJaxWillRender,
      mathjaxContainer: previewRef?.current
    })
  }, [math, onMathJaxDidRender, onMathJaxWillRender, previewRef])

  return (
    <div id='react-mathjax-preview'>
      <div id='react-mathjax-preview-result' ref={previewRef} />
    </div>
  )
}

MathJax.displayName = 'MathJax'

MathJax.propTypes = {
  math: PropTypes.string,
  onMathJaxWillRender: PropTypes.func,
  onMathJaxDidRender: PropTypes.func
}

export default MathJax
