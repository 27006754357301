import { WEEKDAYS } from '../../../../Constants/accountArea'
import { durationTypes } from '../../../../Constants/cohort'
import { ACTIVE_STATUSES } from '../../../../Constants/studentStatus'
import config from '../../../../config'
import utilities from '../../../../utilities'
import { getCohortDurationType } from '../../../../utilities/cohort'
import { getLatestCohort } from '../../../../utilities/course'
import {
  diffDays,
  getDateAtTime,
  dateToSecondsSinceEpoch,
  getHoursFromDate
} from '../../../../utilities/dateTime'

export function getActiveCourses (courses = []) {
  const filteredCourses = courses.filter(
    course => !config.isCollegeSuccessCourse(course?.id))

  const activeCourses = filteredCourses.filter(course => {
    const latestCohort = getLatestCohort(course) || {}
    const {
      dateStart, cohortEndTime, finalExamEndTime, studentStatus = ''
    } = latestCohort

    const currentDate = Date.now()
    const startDate = new Date(dateStart).getTime()
    const endDate = new Date(cohortEndTime || finalExamEndTime).getTime()
    const isActiveCohort = currentDate >= startDate && currentDate <= endDate

    const isActiveStudent = ACTIVE_STATUSES.includes(studentStatus)

    return isActiveCohort && isActiveStudent
  })

  return activeCourses
}

export function getLatestEnrolledCourse (courses = [], latestSBUpdate) {
  const latestEnrolledCourse = courses?.find(course => {
    const latestCohort = getLatestCohort(course)
    const isAfterLatestUpdate =
      new Date(latestCohort?.dateStart) > new Date(parseInt(latestSBUpdate))
    return isAfterLatestUpdate
  })

  return latestEnrolledCourse
}

export function getLatestScheduledCourse (courses = [], latestSBUpdate) {
  const latestScheduledCourses = courses?.filter(course => {
    const latestCohort = getLatestCohort(course)
    const isBeforeLatestUpdate =
      new Date(latestCohort?.dateStart) < new Date(parseInt(latestSBUpdate))
    return isBeforeLatestUpdate
  })

  const orderedCourses = latestScheduledCourses?.sort((a, b) => {
    const cohortA = getLatestCohort(a)
    const cohortB = getLatestCohort(b)
    return new Date(cohortB?.cohortEndTime) - new Date(cohortA?.cohortEndTime)
  })

  return orderedCourses?.[0]
}

export function getRecommendedHours (courses = []) {
  const recommendedHours = courses?.reduce((acc, course) => {
    const latestCohort = getLatestCohort(course)
    const cohortDurationType = getCohortDurationType(latestCohort?.duration)

    if (cohortDurationType === durationTypes.INTENSIVE) return acc + 20
    if (cohortDurationType === durationTypes.STANDARD) return acc + 10
    if (cohortDurationType === durationTypes.EXTENDED) return acc + 5

    return acc
  }, 0)

  return recommendedHours
}

export function distributeHours (hours, isFullTime = true) {
  const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']
  const weekends = ['Sat', 'Sun']
  const dayHours = isFullTime ? 8 : 6

  const totalWeekdayHours = weekdays.length * dayHours
  const maxWeekendHours = weekends.length * dayHours

  const weekdayHours = Math.min(hours, totalWeekdayHours)
  const weekdayDistribution = Array(weekdays.length).fill(
    Math.floor(weekdayHours / weekdays.length))
  const remainingWeekDayHours = weekdayHours % weekdays.length
  for (let i = 0; i < remainingWeekDayHours; i++) {
    weekdayDistribution[i]++
  }

  const remainingWeekHours = Math.max(hours - totalWeekdayHours, 0)

  const weekendHours = Math.min(remainingWeekHours, maxWeekendHours)
  const weekendDistribution = Array(weekends.length).fill(
    Math.floor(weekendHours / weekends.length))
  const remainingWeekendHours = weekendHours % weekends.length
  for (let i = 0; i < remainingWeekendHours; i++) {
    weekendDistribution[i]++
  }

  const overflowHours = hours - totalWeekdayHours - maxWeekendHours
  if (overflowHours > 0) {
    for (let i = 0; i < overflowHours; i++) {
      weekdayDistribution[i % weekdays.length]++
    }
  }

  return [
    ...weekdays.map((day, index) => ({ day, hours: weekdayDistribution[index] || 0 })),
    ...weekends.map((day, index) => ({ day, hours: weekendDistribution[index] || 0 }))
  ]
}

export function createStudyBlocks (hours, isFullTime = true) {
  if (!hours || hours <= 0 || typeof hours !== 'number') return []

  const startWorkHour = isFullTime ? 9 : 18
  const breakDuration = 1

  const distributedHours = []

  if (hours <= 4) {
    distributedHours.push({
      start: startWorkHour,
      end: startWorkHour + hours
    })
    return distributedHours
  }

  const halfHours = hours / 2
  distributedHours.push({
    start: startWorkHour,
    end: startWorkHour + halfHours
  })
  distributedHours.push({
    start: startWorkHour + halfHours + breakDuration,
    end: startWorkHour + hours + breakDuration
  })

  return distributedHours
}

export function getActiveDays (studyBlocks) {
  if (!Array.isArray(studyBlocks)) return []

  return studyBlocks?.reduce((acc, day) => {
    if (day?.blocks?.length) {
      acc.push({ name: day?.day })
    }
    return acc
  }, [])
}

/**
 * @param {Array} schedule the selected study blocks in the form of
 *  { day: String, blocks: [{ start: Num, end: Num }]
 * @returns {Array} studyBlocksSessions the selected study blocks in the form of
 *  { start: ISOString, end: ISOString } enumerated from the current day to the
 *  the end time provided which should be a valid date after current day
 */
export function enumerateStudyBlocksSessions (schedule = [], startTime = '', endTime = '') {
  const daysOfWeek = WEEKDAYS.map(day => day.name)

  const today = new Date()
  const startDate = new Date(startTime)
  const endDate = new Date(endTime)

  if (endDate.toString() === 'Invalid Date' || startDate > today || today > endDate) return []

  const todaySeconds = dateToSecondsSinceEpoch(today)
  const endDateSeconds = dateToSecondsSinceEpoch(endDate)
  const numberOfDays = diffDays(todaySeconds, endDateSeconds)

  const todayName = today.toDateString().split(' ')[0]
  const startIndex = daysOfWeek.indexOf(todayName)

  const studyBlocksSessions = []

  for (let i = 0; i <= numberOfDays; i++) {
    const dayIndex = (startIndex + i) % daysOfWeek.length
    const dayName = daysOfWeek[dayIndex]
    const dayDate = new Date(
      today.getFullYear(), today.getMonth(), today.getDate() + i
    )

    const dayBlocks = schedule.find(day => day.day === dayName)?.blocks
    if (!dayBlocks?.length) continue

    dayBlocks.forEach(block => {
      const startTime = getDateAtTime(block.start, dayDate).getTime()
      const endTime = getDateAtTime(block.end, dayDate).getTime()

      studyBlocksSessions.push({ start: startTime, end: endTime })
    })
  }

  return studyBlocksSessions
}

export function getStudyBlocksFromSessions (sessionsObject = {}) {
  const emptyStudyBlocks = WEEKDAYS.map(day => ({ day: day.name, blocks: [] }))
  if (!sessionsObject || typeof sessionsObject !== 'object') return emptyStudyBlocks
  const sessionsArray = Object.values(sessionsObject)
    .sort((a, b) => new Date(b?.start) - new Date(a?.start))
  if (!sessionsArray?.length || !sessionsArray?.[0]?.start) return emptyStudyBlocks

  const groupedSessions = {}
  const daysOfWeek = WEEKDAYS.map(day => day.name)
  let [sessionIndex, startDate, isFullCycle] = [0, '', false]

  while (!isFullCycle) {
    const session = sessionsArray[sessionIndex]
    const day = new Date(session.start).toDateString()
    const dayName = day.split(' ')[0]
    if (sessionIndex === 0) startDate = day

    const isFullWeek = dayName === startDate.split(' ')[0] &&
      sessionIndex !== 0 && day !== startDate
    if (isFullWeek) break

    if (!groupedSessions[dayName]) {
      groupedSessions[dayName] = []
    }
    groupedSessions[dayName].push(session)

    isFullCycle = sessionIndex === sessionsArray.length - 1
    sessionIndex++
  }

  const studyBlocks = daysOfWeek.map(day => {
    const sessionsForDay = groupedSessions[day] || []

    const blocks = sessionsForDay.map(session => ({
      start: getHoursFromDate(session.start),
      end: getHoursFromDate(session.end)
    })).sort((a, b) => a.start - b.start)

    return { day, blocks }
  })

  return studyBlocks
}

/**
 * @param {Object} oldStudyBlocksSessions containing the old study blocks as id: session
 *  where the id is the sessions start time in unix timestamp
 * @param {Array} newStudyBlocksSessionsIDs is the new study blocks IDs that should be created
 * @returns {Array} of the study blocks that should be deleted, without the blocks
 *  that are in the new IDs as these should be preserved or updated
 */
export function getInactiveStudyBlocksIDs (oldStudyBlocksSessions = {}, newStudyBlocksSessions = []) {
  const oldStudyBlocksIDs = Object.keys(oldStudyBlocksSessions || {})
    .sort((a, b) => new Date(a?.start) - new Date(b?.start))
  if (!oldStudyBlocksIDs?.length) return []

  const currentTime = new Date().getTime()
  const upcomingSession = oldStudyBlocksIDs.findIndex(id => id > currentTime)

  const inactiveStudyBlocksIDs = oldStudyBlocksIDs.slice(upcomingSession)
    .filter(id => !newStudyBlocksSessions.find(session => session?.start === parseInt(id)))

  return inactiveStudyBlocksIDs
}

export const getSBSessionsObj = (studyBlocksSessions = []) => {
  return studyBlocksSessions?.reduce((acc, session) => {
    acc[session?.start] = session
    return acc
  }, {})
}

export const getAreRemindersEqual = (oldReminders = {}, newReminders = {}) => {
  const filterFunction = ([_, value]) => !!value
  const filteredOldReminders = utilities.filterObject(oldReminders, filterFunction)
  const filteredNewReminders = utilities.filterObject(newReminders, filterFunction)
  const areRemindersEqual =
    JSON.stringify(filteredOldReminders) === JSON.stringify(filteredNewReminders)

  return areRemindersEqual
}

export const calculateTotalHours = (studyBlocks) => {
  const totalHours = studyBlocks?.reduce((acc, { blocks }) => {
    const dayHours = blocks?.reduce((acc, { start, end }) => {
      const endHours = end < start ? end + 24 : end
      return acc + (endHours - start)
    }, 0)

    return acc + dayHours
  }, 0)

  return totalHours
}
