import React from 'react'
import { FAILURE, UNDER_13 } from './errorType'
import { Container } from './styled'

const FailureMessage = () => (
  <p data-testid='failure-message'>
    Something went wrong ! Unable to access the site data
  </p>
)

const StudentUnder13 = () => (
  <p data-testid='restrict-message'>
    Our records show that you are under 13, which is below the minimum age required to access our site.
    Our student success team will be reaching out to you soon regarding next steps. If you think you are
    receiving this message by mistake, please reach out to
    <a href='mailto:success@outlier.org'> success@outlier.org</a>
  </p>
)

function renderComponent (errorType) {
  switch (errorType) {
    case FAILURE:
      return <FailureMessage />
    case UNDER_13:
      return <StudentUnder13 />
    default:
      throw new Error('Invalid Error Type')
  }
}

function ErrorMessage ({ errorType }) {
  return (
    <Container>
      {renderComponent(errorType)}
    </Container>
  )
}

export default ErrorMessage
