import styled from 'styled-components'
import { ReactComponent as CertificateRibbonSVG } from '../../../../assets/icons/certificate-ribbon.svg'
import media from '../../../../mediaQueries'

export const CertificateRibbonIcon = styled(CertificateRibbonSVG)`
`

export const RequestPageContainer = styled.div`
  margin-top: 128px;
  padding: 0 300px;
  ${media.tablet`
    padding: 0 32px;
  `}
`

export const FormPageContainer = styled.div`
  margin-top: 64px;
`

export const ReceivalPageContainer = styled.div`
  margin: 279px auto;
  width: fit-content;
  text-align: center;
`

export const PageHeader = styled.h1`
  font-weight: 300;
  font-size: 36px;
  line-height: 43px;
  font-style: normal;
`

export const MainText = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
`

export const HorizontalLine = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 32px;
  margin-bottom: 32px;
`

export const CertificateBox = styled.div`
  width: fit-content;
  padding: 24px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: row;

  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0;
  }
`

export const MainButton = styled.button`
  height: 48px;
  width: fit-content;
  padding: 0 24px;
  background-color: #5FC4B8;
  color: #161618;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;  
  border-radius: 5px;
  border: none;

  :hover {
    background-color: #B8FFF7;
  }

  :focus {
    outline: none;
  }
`

export const FormWrapper = styled.div`
  height: 500px;
  width: 100%;
`
