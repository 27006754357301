import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledRow, PreviousTitle } from './style'
import { getPreviouslyScheduled } from '../../utilities/course'
import CourseCard from './CourseCard'

const PreviouslyScheduled = ({ activeCourses, courses, history }) => {
  const [activeCard, setActiveCard] = useState(null)

  const previouslyScheduledCourses = useMemo(() => {
    return getPreviouslyScheduled(courses, activeCourses)
  }, [activeCourses, courses])

  if (!previouslyScheduledCourses?.length) return null
  return (
    <div>
      <PreviousTitle data-testid='previousTitle'>
        Previously scheduled
      </PreviousTitle>
      <StyledRow>
        {previouslyScheduledCourses.map(course => (
          <CourseCard
            course={course}
            activeCard={activeCard}
            setActiveCard={setActiveCard}
            key={course.id}
            history={history}
          />
        ))}
      </StyledRow>
    </div>
  )
}

PreviouslyScheduled.propTypes = {
  list: PropTypes.array,
  courses: PropTypes.array
}
PreviouslyScheduled.displayName = 'PreviouslyScheduled'

export default PreviouslyScheduled
