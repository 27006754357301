import React, { useEffect, useState, useRef } from 'react'
import {
  GlobalStyle,
  ModalWrapper,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonsWrapper,
  PrimaryButton,
  SecondaryButton
} from './style'

const WarningModal = ({
  title,
  text1,
  text2,
  primaryButtonText,
  secondaryButtonText,
  onConfirm,
  onCancel
}) => {
  const [showModal, setShowModal] = useState(false)
  const timeoutRef = useRef(null)

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setShowModal(true)
    }, 10)

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <>
      <GlobalStyle />
      <ModalWrapper data-testid='warning-modal'>
        <Modal showModal={showModal}>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>
            <p data-testid='modal-text-1'>{text1}</p>
            <p data-testid='modal-text-2'>{text2}</p>
            <ButtonsWrapper>
              <PrimaryButton onClick={onCancel}>
                {primaryButtonText}
              </PrimaryButton>
              <SecondaryButton onClick={onConfirm}>
                {secondaryButtonText}
              </SecondaryButton>
            </ButtonsWrapper>
          </ModalBody>
        </Modal>
      </ModalWrapper>
    </>
  )
}

WarningModal.displayName = 'WarningModal'
export default WarningModal
