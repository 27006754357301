import React, { useContext } from 'react'
import { AppContext } from '../../ContextProvider/ContextProvider'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { getTokenDatesFormatted } from '../../../utilities/tokenUtils'
import {
  PageWrapper,
  PageTitle,
  SubTitle,
  Dot,
  customStyles,
  NormalText,
  PageBlock,
  TextBlock,
  BackgroundText
} from './style'
import { PrimaryButton, SecondaryButton } from '../style'
import { getDateStringWithYear } from '../../../utilities/dateTime'
import { OUTLIER_SUBSCRIPTIONS_QA_LINK } from '../../../Constants/accountArea'

const {
  primaryBtn, secondaryBtn
} = customStyles

function Subscription () {
  const {
    tokens = [],
    studentData: {
      instrideSubscriptionStatus, dateSubscriptionCancelled
    } = {}
  } = useContext(AppContext)
  const history = useHistory()

  const {
    tokenIssueDate, tokenExpirationDate
  } = getTokenDatesFormatted(tokens?.[0])

  const isCancellationRequested = instrideSubscriptionStatus === 'requested'
  const isSubscriptionCancelled = instrideSubscriptionStatus === 'cancelled'
  const cancellationDate = getDateStringWithYear(dateSubscriptionCancelled)

  const getSubscriptionText = () => {
    if (isCancellationRequested) {
      return (
        <>Visit the <a href='#/'>Student Dashboard </a> to view your courses.</>
      )
    }
    if (isSubscriptionCancelled) {
      return (
        <>
          You do not have an active 1-year subscription with Outlier.
          You’re not enrolled in any courses, but you still have access to{' '}
          <a href='#/my-courses'>College Success</a>.
        </>
      )
    }
    return (
      <>Explore the course catalog to sign up!</>
    )
  }

  const getCancellationText = () => {
    if (isCancellationRequested) {
      return (
        <>
          You have successfully submitted the request form to cancel your 1-year subscription
          with Outlier. You will receive a confirmation email in 3-5 business days.
        </>
      )
    }
    return (
      <>
        If you would like to cancel your subscription to Outlier.org,
        you can do so at any time. Complete the request form and we will be
        happy to help you with the cancellation process.
      </>
    )
  }

  const viewCatalog = () => {
    history.push('/catalog')
  }

  const viewCancellationPage = () => {
    history.push('/account/subscription-cancellation')
  }

  return (
    <PageWrapper>
      <PageBlock>
        <TextBlock>
          <PageTitle>
            Subscription
          </PageTitle>

          <SubTitle>
            {isSubscriptionCancelled ? (
              <>Cancelled on {cancellationDate}</>
            ) : (
              <>1-year subscription <Dot /> {tokenIssueDate} - {tokenExpirationDate}</>
            )}
          </SubTitle>

          <NormalText>
            {getSubscriptionText()}
          </NormalText>
        </TextBlock>

        {!instrideSubscriptionStatus && (
          <PrimaryButton
            style={primaryBtn}
            onClick={viewCatalog}
          >
            view catalog
          </PrimaryButton>

        )}
      </PageBlock>

      {!isSubscriptionCancelled && (
        <PageBlock>
          <TextBlock>
            <PageTitle>Cancel your subscription</PageTitle>

            {isCancellationRequested && (
              <SubTitle>
                Cancellation requested <Dot /> Cancellation pending
              </SubTitle>
            )}

            <NormalText>
              {getCancellationText()}
            </NormalText>
          </TextBlock>

          {!isCancellationRequested && (
            <SecondaryButton
              style={secondaryBtn}
              onClick={viewCancellationPage}
            >
              request to cancel
            </SecondaryButton>
          )}
        </PageBlock>
      )}

      {!instrideSubscriptionStatus && (
        <BackgroundText>
          <h3>Refund policy</h3>

          <p>
            We offer a full refund within 30 days of your account creation, or the
            drop deadline of the course you’re enrolled in, whichever is earlier.
            To be eligible for a refund, users must request to cancel the subscription
            before the final cancellation date or before the course’s drop deadline.
            Refunds will be credited to your learning budget. If you have any questions
            regarding our refund policies, please refer to the{' '}
            <a href={OUTLIER_SUBSCRIPTIONS_QA_LINK} target='_blank' rel='noopener noreferrer'>
              Refund Policy Q&A.
            </a>
          </p>
        </BackgroundText>

      )}
    </PageWrapper>
  )
}

Subscription.displayName = 'Subscription'
export default Subscription
