import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  margin: 245px auto 0px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  @media (max-width: 575px) {
    width: 318px;
  }

  @media (max-width: 300px) {
    width: 250px;
  }

  button {
    width: 140px;
    line-height: 17px;
    height: 40px;
  }
`

export const Header = styled.h2`
  font-size: 36px;
  line-height: 43px;
  margin-bottom: 12px;
  font-weight: 300;
  word-break: break-word;
`

export const Content = styled.div`
  font-size: 18px;
  line-height: 22px;
  min-height: 122px;
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
