import styled, { createGlobalStyle, css } from 'styled-components'
import { Button } from 'react-bootstrap'
import media from '../../../mediaQueries'
import BackBreadcrumb from '../../BackBreadcrumb/BackBreadcrumb'
import { ReactComponent as ChevronsSVG } from '../../../assets/icons/chevrons-icon.svg'
import { ReactComponent as ArrowSvg } from '../../../assets/icons/arrow.svg'

export const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
  }
`
export const OverrideContainerProps = createGlobalStyle`
  .page-container {
    justify-content: center;
  }
`
export const OverrideCSSprops = createGlobalStyle`
  body {
    height: 100vh;
    overflow: hidden;
  }

  #content {
    position: relative;
    top: -64px;
    max-width: 100%!important;
    margin: 64px 0 0 0;
    padding: 0px;
  }
`
export const EnrollmentContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #211631 0%, #000000 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.mobile`
    padding: 0 24px;
  `}
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding-top: 148px;
  padding-bottom: 75px;
  align-items: center;
  flex-direction: column;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  background: linear-gradient(180deg, #211631 0%, #000000 100%);
  color: #FFFFFF;
`

export const PageWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin-left: 24px;
  margin-right: 24px;

  ${props => props.center && css`
    position: relative;
    top: -84px;
  `}
`

export const RecommendedPageWrapper = styled(PageWrapper)`
  max-width: 798px;
  ${media.mobile`
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${media.smallMobile`
    margin-left: 24px;
    margin-right: 24px;
  `}
`

export const PageTitle = styled.h2`
  font-style: normal;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  font-weight: 300;
`
export const EnrollmentTitle = styled(PageTitle)`
  width: 500px;
  ${media.mobile`width: 100%;`}
`

export const SectionTitle = styled.p`
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  font-weight: 300;
`

export const SemesterTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 12px;
`

export const CourseSlotWrapper = styled.div`
  width: 600px;

  ${media.mobile`
    max-width: 600px;
    width: 100%;
  `}

  ${media.smallMobile`
    width: 100%;
  `}
`

export const GroupHeaderMessageContainer = styled.div`
  display: flex;
  width: 600px;
  padding: 19px 16px;
  margin-bottom: 16px;
  align-items: center;
  gap: 16px;
  align-self: center;
  border-radius: 5px;
  background-color: #1D1D1F;
`

export const GroupHeaderMessage = styled.span`
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const GroupedCourseSlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100px;
  border: 1px solid white;
  border-radius: 5px;
  margin-bottom: 16px;
  background: rgba(0,0,0,0.25);
`

export const SelectedCourseContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #5B6067;
`

export const CourseSlotContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100px;
  border: 1px solid white;
  border-radius: 5px;
  margin-bottom: 16px;
  background: rgba(0,0,0,0.25);
`
export const WarningSlotContainer = styled(CourseSlotContainer)`
  flex-direction: column;
  align-items: start;
  justify-content: center;
  min-height: 84px;
  padding: 16px;
  border: 1px solid #98a4ae;
`
export const WarningSlotTitle = styled.div`
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
`
export const WarningText = styled.div`
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
`
export const CatalogLink = styled.div`
  cursor: pointer;
  div {
    display: inline-block;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #5FC4B8;
    margin-right: 9px;
  }
`
export const Arrow = styled(ArrowSvg)`
  display: inline-block;
`

export const CourseImageContainer = styled.div`
  border-right: 1px solid white;
  flex-shrink: 0;
  height: 100px;
  width: 102px;
  display: flex;
  align-items: center;
  justify-content: center;

  .course-icon {
    width: 42px!important;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
  }

  ${media.smallMobile`
    border-right: unset;
    width: unset;
    margin-left: 24px;
  `}
`

export const GroupedCourseImageContainer = styled(CourseImageContainer)`
  border-right: 1px solid #5B6067;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;

  .course-icon {
    width: 42px!important;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
  }
`

export const CourseIcon = styled.div`
  position: relative;
  z-index: 1;
  width: 42px;
  height: 42px;
  mask: ${({ src }) => `url(${src})`};
  mask-size: contain;
  mask-repeat: no-repeat;
  background: #CCD4D8;
`

export const CourseMainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  line-height: 24px;
  padding-left: 24px;

  ${media.smallMobile`
    flex-direction: column;
    align-items: stretch;
  `}
`

export const CourseNameContainer = styled.div`
  font-size: 18px;
  margin-bottom: 6px;
`
export const SlotOptionsWrapper = styled.div`
  position: relative;
  cursor: pointer;
`

export const ChoiceDescription = styled.div`
  margin-top: 4px;
  font-size: 14px;
  line-height: 17px;
  margin-right: 5px;
`

export const StartDateContainer = styled.div`
  flex-shrink: 0;
  margin-left: 5px;
`

export const OptionsIcon = styled.img`
  padding-left: 18.5px;
  padding-right: 22.5px;
`
export const OptionsDropDownWrapper = styled.div`
  position: relative;
`
export const BackGroundOverlay = styled.div`
  width: 100vw;
  top: 0px;
  left: 0px;
  height: 100vh;
  position: fixed;
  z-index: 10;
  background: rgba(0,0,0,0.5);
  display: none;

  ${media.mobile`
    display: block;
  `}
`
export const OptionsDropdown = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% - 12.5px);
  width: 200px;
  padding: 8px 0px;
  background: #25272B;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;

  ${media.desktop`
    left: unset;
    right: calc(100% - 12.5px);
  `}

  ${media.mobile`
    position: fixed;
    top: calc(50% - 104px);
    left: 0%;
    width: 100%;
    z-index: 100;
    padding: 16px 38px;
  `}

  ${media.smallMobile`
    padding: 16px 12.5px;
  `}
`
export const CourseName = styled.h2`
  padding: 12px 16px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  display: none;

  ${media.mobile`display: block;`}
`
export const MenuOption = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;

  ${props => props.disabled
    ? css`
      opacity: 0.5;
    `
    : css`
      :hover {
        border-color: #5FC4B8;
      }
    `}
`
export const MenuOptionText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
`
export const Chevrons = styled(ChevronsSVG)``

export const GroupOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 16px 16px 24px;
`

export const OptionHeader = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  .radio {
    appearance: none;
    -webkit-appearance: none;
    border: 2.3px solid rgba(0, 0, 0, 1);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #5FC4B8;
    margin-right: 8px;
  }

  .radio:disabled {
    border: none!important;
    box-shadow: 0 0 0 1px gray!important;
  }

  .radio:checked {
    background: #5FC4B8;
  }
`

export const OptionDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #98A4AE;

  a {
    color: #5FC4B8;
    text-decoration: underline;
  }
`

export const Instructions = styled.p`
  max-width: 640px;
  margin-bottom: ${({ marginBottom }) => marginBottom || '32px'};
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: white;

  a {
    color: #5FC4B8;
    font-weight: 700;
    text-decoration: underline;
  }
  p{
    margin: 0px;
  }
`

export const Description = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`

export const ContinueButton = styled(Button)`
  width: 128px;
  height: 48px;
`

export const ScheduleCallButton = styled.button`
  padding: 16px 24px;
  width: unset;
  margin-top: ${({ marginTop }) => marginTop || 'auto'};

  ${props => props.advisorApproved && `
    opacity: 0.5;
  `}
`

export const RadioWrapper = styled.div`
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid #5B6067;
  border-radius: 5px;
  max-width: 449px;

  label {
    font-weight: 400;
    font-size: 16px;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 16px 16px 16px 24px;
    cursor: pointer;
  }

  .subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #98A4AE;
    margin: 4px 0 0 0;
  }

  span {
    line-height: 24px;
  }

  .radio {
    appearance: none;
    -webkit-appearance: none;
    border: 2.3px solid rgba(0, 0, 0, 1);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #5FC4B8;
  }

  .radio:checked {
    background: #5FC4B8;
  }
`

export const BackButton = styled(BackBreadcrumb)`
  position: absolute;
  top: 0;
  left: 24px;
`

export const CreditWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop || '0'};
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid #5B6067;
  border-radius: 5px;
  max-width: 449px;
  padding: 16px 16px 16px 16px;
  .element {
    display: flex;
    justify-content: space-between;
    .course-name {
      margin: 0 12px 0 0;
      width: 208px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
    }
    .credits {
      margin: 0;
      width: 74px;
      text-align: right;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #CCD4D8;
    }
  }
`
export const LetsGetStartedBtn = styled.button`
  display: block;
  width: 191px;
  height: 48px;
  background: #5FC4B8;
  border: none;
  border-radius: 5px;
  outline: none!important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  :hover {
    background-color: #b8fff7;
  }

  :active {
    background-color: white;
  }
`
