import React, { useContext, useEffect, useRef, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { AppContext } from '../ContextProvider/ContextProvider'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { BackToLink } from '../Tokens/style'
import { Container } from './style'
import Introduction from './Introduction'
import { pretestPageStates } from '../../Constants/pretest'
import QuestionComponent from './QuestionComponent'
import ScorePage from './ScorePage'
import utils from '../../utilities'
import ExitModal from './ExitModal'
import api from '../../api'
import {
  hasPretestEligibility,
  getRecentAttempt,
  getCourseWithPretestDetails
} from '../../utilities/pretestUtils'
import BackIcon from '../../assets/icons/back-arrow-caret.svg'

const { DEFAULT, TEST, COMPLETE } = pretestPageStates

const Pretest = () => {
  const { courses, activeCourses, courseProjectedGrades, updateContext } = useContext(AppContext)
  const [currentCourse, setCurrentCourse] = useState({})
  const [currentState, setCurrentState] = useState(DEFAULT)
  const [component, setComponent] = useState(null)
  const [score, setScore] = useState(null)
  const [showExitModal, setShowExitModal] = useState(false)
  const wrapperRef = useRef(null)
  const currentStateRef = useRef(currentState)
  const [isLoading, setIsLoading] = useState(false)
  const [attempts, setAttempts] = useState([])
  const questionSetIndex = useRef(0)

  const goToCourseDetails = () => {
    if (currentStateRef.current !== TEST) {
      window.location.hash = utils.getDetailsPagePath()
      return
    }

    setShowExitModal(true)
  }

  const setScorePage = pretestScore => {
    setCurrentState(COMPLETE)
    setScore(pretestScore)
  }

  const unloadHandler = event => {
    if (currentStateRef.current !== TEST) return

    event.preventDefault()
    event.returnValue = ''
  }

  const isPretestComponent = event => {
    const parent = wrapperRef.current
    const child = event.target

    if (parent && parent.contains(child)) return true

    /* Submit and Next buttons change to primary and secondary upon clicking; so
    wrapperRef.contains() returns false for them apart from the exit modal buttons. */
    const pretestChildren = [
      'btn-submit-answer',
      'btn-next',
      'btn-prev',
      'img-close',
      'link-show-options',
      'btn-keep-going',
      'btn-exit-pretest'
    ]
    const targetId = child.getAttribute('data-testid')
    return pretestChildren.includes(targetId)
  }

  const isClickable = element => {
    return element instanceof HTMLButtonElement ||
      element instanceof HTMLAnchorElement ||
      element.parentElement instanceof HTMLAnchorElement
  }

  const clickOutsideHandler = event => {
    if (isPretestComponent(event)) return
    if (!isClickable(event.target)) return

    if (currentStateRef.current !== TEST) return

    event.preventDefault()
    setShowExitModal(true)
  }

  useEffect(() => {
    window.addEventListener('beforeunload', unloadHandler)
    document.addEventListener('click', clickOutsideHandler, false)

    return () => {
      (async function () {
        updateContext({
          pretestAttempts: null
        })
        const updatedPretestAttempts = await api.getPretestAttempts('attempts')
        updateContext({
          pretestAttempts: updatedPretestAttempts
        })
      })()

      window.removeEventListener('beforeunload', unloadHandler)
      document.removeEventListener('click', clickOutsideHandler, false)
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const getPretestAttempts = async () => {
      setIsLoading(true)
      const fetchedAttempts = await Promise.all([
        api.getPretestAttempts('attempts'),
        api.getPretestAttempts('overrides')
      ])
      setIsLoading(false)

      setAttempts(fetchedAttempts)
    }

    getPretestAttempts()
  }, [])

  useEffect(() => {
    if (!courses || isEmpty(activeCourses)) return

    const enrichCurrentCourseWithPretestContent = async () => {
      setIsLoading(true)
      const courseWithPretest = await getCourseWithPretestDetails(courses, activeCourses)
      setIsLoading(false)

      if (!courseWithPretest) return goToCourseDetails()
      setCurrentCourse(courseWithPretest)
    }

    enrichCurrentCourseWithPretestContent()

    // eslint-disable-next-line
  }, [courses, activeCourses])

  useEffect(() => {
    if (isEmpty(currentCourse) || !courseProjectedGrades || !attempts?.length) return

    if (hasPretestEligibility({
      studentCourses: courses,
      courseProjectedGrades,
      currentCourse,
      attempts
    })) return

    goToCourseDetails()

    // eslint-disable-next-line
  }, [currentCourse, courseProjectedGrades, attempts])

  const [testAttempts] = attempts || []
  const recentAttempt = testAttempts?.length
    ? getRecentAttempt(testAttempts, currentCourse?.id)
    : null

  useEffect(() => {
    currentStateRef.current = currentState

    if (isEmpty(currentCourse) || !currentCourse.pretest) return

    const { id, displayName, pretest } = currentCourse
    const { minimumPretestScore, pretestLockoutDays } = pretest || {}

    if (currentState === DEFAULT) {
      return setComponent(
        <Introduction
          courseName={displayName}
          pretest={pretest}
          setCurrentState={setCurrentState}
        />
      )
    }

    if (currentState === TEST) {
      questionSetIndex.current = recentAttempt
        ? utils.getRandomIntegerWithExclusion(
          pretest?.pretestExamContent?.length, recentAttempt?.questionSetIndex
        )
        : 0

      return setComponent(
        <QuestionComponent
          courseId={id}
          courseName={displayName}
          pretest={pretest}
          setScorePage={setScorePage}
          questionSetIndex={questionSetIndex.current}
          recentAttempt={recentAttempt}
        />
      )
    }

    if (isNaN(score)) return

    if (currentState === COMPLETE) {
      return setComponent(
        <ScorePage
          score={score}
          courseName={displayName}
          minimumPretestScore={minimumPretestScore}
          pretestLockoutDays={pretestLockoutDays}
        />
      )
    }

    // eslint-disable-next-line
  }, [currentState, currentCourse, score])

  if (isLoading || !courseProjectedGrades) return <LoadingSpinner />

  return (
    <Container ref={wrapperRef}>
      <BackToLink
        data-testid='back-to-catalog'
        onClick={goToCourseDetails}
      >
        <img alt='back-button' src={BackIcon} />
        <span>catalog</span>
      </BackToLink>
      {component}
      <ExitModal
        show={showExitModal}
        setShow={setShowExitModal}
        opts={{
          courseId: currentCourse?.id,
          recentAttempt: recentAttempt,
          questionSetIndex: questionSetIndex.current
        }}
      />
    </Container>
  )
}

Pretest.displayName = 'Pretest'
export default Pretest
