import React, { useEffect, useState } from 'react'
import api from '../../../api'
import { NotificationHeader, NotificationDetailsWrapper } from '../style'
import NotificationList from './NotificationList'

const Notifications = ({ studentCourses }) => {
  const [notifications, setNotifications] = useState({})

  useEffect(() => {
    const getAllNotifications = async () => {
      const allNotifications = await api.getAllNotifications()
      if (!allNotifications) return

      setNotifications(allNotifications)
    }

    getAllNotifications()
  }, [])

  return (
    <>
      <NotificationHeader>Notifications</NotificationHeader>
      <NotificationDetailsWrapper>
        <NotificationList
          notifications={notifications}
          studentCourses={studentCourses}
        />
      </NotificationDetailsWrapper>
    </>
  )
}

export default Notifications
