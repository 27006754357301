import React from 'react'
import ConfettiCanvas from 'react-confetti-canvas'

const CONFETTI_CANVAS_DURATION = 0.01
const CONFETTI_CANVAS_RIBBON_COUNT = 30
const CONFETTI_CANVAS_COLORS = [['#78BCB8', '#FFFFFF']]

const ConfettiCanvasComponent = () => {
  return (
    <ConfettiCanvas
      duration={CONFETTI_CANVAS_DURATION}
      ribbonCount={CONFETTI_CANVAS_RIBBON_COUNT}
      colors={CONFETTI_CANVAS_COLORS}
      ribbonParticleThick={15.0}
      ribbonParticleDist={2.0}
      ribbonParticleAngle={90}
      paperCount={50}
    />
  )
}

export default ConfettiCanvasComponent
