import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import {
  GlobalStyle,
  ExitModalHeader,
  Separator,
  ButtonsContainer,
  ClickableText,
  ClickableLink
} from './style'
import { BackLink } from '../style'
import CloseIcon from '../../../assets/icons/close-icon.svg'
import BackIcon from '../../../assets/icons/back-arrow.svg'
import { PrimaryButton, SecondaryButton } from '../../Buttons/style'
import { ExitOptions } from '../../../Constants/accountArea'

const MODAL_STATES = {
  main: 'main',
  aid: 'aid-office'
}

const ADVISOR_FORM_LINK = 'https://share.hsforms.com/1Ba8VgLiTTH-xHCxiXyY47Adf3xv'

const WarningModal = ({ GGUWarning, setGGUWarning, navigateToFormPage }) => {
  const [modalState, setModalState] = useState(MODAL_STATES.main)
  const closeModal = () => {
    setGGUWarning({ show: false, option: '' })
  }

  const handleContinue = () => {
    setGGUWarning({ show: false, option: '' })
    navigateToFormPage(GGUWarning.option)
  }

  const isMainModal = modalState === MODAL_STATES.main
  const isDrop = GGUWarning.option === ExitOptions.DROP
  const isWithdraw = GGUWarning.option === ExitOptions.WITHDRAW
  const isAidModal = modalState === MODAL_STATES.aid

  const navigateToAdvisorForm = () => {
    window.open(ADVISOR_FORM_LINK, '_blank', 'noopener,noreferrer')
  }

  return (
    <Modal show={GGUWarning.show} centered>
      <GlobalStyle />
      <ExitModalHeader>
        <p>
          {isMainModal && isDrop &&
            'Are you sure you want to make this change?'}
          {isMainModal && isWithdraw &&
            'Are you sure you want to do this?'}
          {isAidModal &&
            'Golden Gate University Financial Aid Office'}
        </p>

        <img
          src={CloseIcon}
          alt='Close Warning Modal'
          onClick={closeModal}
        />
      </ExitModalHeader>
      <Separator />

      <Modal.Body>
        <p style={{ marginBottom: '12px' }}>
          {isMainModal && isDrop &&
            'Dropping this course may affect your financial aid and degree timeline.'}
          {isMainModal && isWithdraw &&
            'Withdrawing from this course will affect your financial aid and degree timeline.'}
          {isAidModal && 'The experts for all of your financial aid questions.'}
        </p>

        {isMainModal && (
          <p>
            <ClickableText onClick={navigateToAdvisorForm}>
              Speak with an Advisor
            </ClickableText>{' '}
            to understand how your degree timeline might change, or contact the{' '}
            <ClickableText onClick={() => setModalState(MODAL_STATES.aid)}>
              Financial Aid Office
            </ClickableText>{' '}
            to see how your financial aid may be impacted.
          </p>
        )}

        {isAidModal && (
          <p>
            Get in touch by phone at{' '}
            <ClickableLink
              href='tel:+1-415-442-7800' target='_blank' rel='noopener noreferrer'
            >
              1-415-442-7800
            </ClickableLink>{' '}
            or by emailing{' '}
            <ClickableLink
              href='mailto:info@ggu.edu' target='_blank' rel='noopener noreferrer'
            >
              info@ggu.edu
            </ClickableLink>.
          </p>
        )}

        <ButtonsContainer>
          {isMainModal && (
            <>
              <PrimaryButton
                onClick={closeModal}
                style={{ height: '48px', width: 'fit-content', marginRight: '16px' }}
              >
                go back
              </PrimaryButton>

              <SecondaryButton
                onClick={handleContinue}
                style={{ height: '48px', width: 'fit-content' }}
              >
                continue dropping
              </SecondaryButton>
            </>
          )}

          {isAidModal && (
            <BackLink
              onClick={() => setModalState(MODAL_STATES.main)}
            >
              <img
                src={BackIcon}
                className='back-arrow'
                alt='back button'
              />
              <span>back</span>
            </BackLink>
          )}
        </ButtonsContainer>
      </Modal.Body>
    </Modal>
  )
}

WarningModal.displayName = 'WarningModal'

export default WarningModal
