import React from 'react'

import config from '../../config'
import CardInfo from './CardInfo'

export const StudioCard = ({ course }) => {
  const { id } = course
  const courseUrl = config.courseBaseUrlById(id)

  return (
    <CardInfo
      course={course}
      description='Check with your instructor for your assignments
        for this course.'
      dataTestId='studio-card-id'
      secondaryBtnTxt='View Course'
      handleSecondaryButton={() => { window.location.href = courseUrl }}
    />
  )
}

export default StudioCard
