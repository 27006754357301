import React, { useEffect, useMemo, useState } from 'react'
import qs from 'qs'
import {
  MajorsIcon,
  MathematicsIcon,
  SocialSciencesIcon,
  CommunicationIcon,
  ProblemSolvingIcon,
  ElectivesIcon,
  CollapseWrapper,
  ViewMore
} from './style'
import CourseGroup from './CourseGroup'

const CourseGroups = ({ setCurrentPage, currentCourseNumber, courseQuantity }) => {
  const params = qs.parse(window.location.search?.slice(1))
  const isRegistrationRedesign = params?.gguEnrollmentV2 === 'true'
  const [showViewMore, setShowViewMore] = useState(isRegistrationRedesign)
  const [showNonMajorCourses, setShowNonMajorCourses] = useState(!isRegistrationRedesign)

  const groups = useMemo(() => {
    const defaultGroups = [
      {
        icon: <MajorsIcon />,
        title: 'Major courses',
        key: 'Major'
      },
      {
        icon: <MathematicsIcon />,
        title: 'Mathematics',
        key: 'Mathematics'
      },
      {
        icon: <SocialSciencesIcon />,
        title: 'Social Sciences',
        key: 'Social Science'
      },
      {
        icon: <CommunicationIcon />,
        title: 'Communication',
        key: 'Communication'
      },
      {
        icon: <ProblemSolvingIcon />,
        title: 'Problem Solving',
        key: 'Problem Solving'
      },
      {
        icon: <ElectivesIcon />,
        title: 'Electives',
        key: 'Elective'
      }
    ]

    if (isRegistrationRedesign) return defaultGroups

    defaultGroups.push({
      title: 'Other courses',
      descriptionText: 'You can explore taking these other courses, but they may not contribute to your degree progress.'
    })
    return defaultGroups
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isRegistrationRedesign) return
    if (showViewMore) return

    setShowNonMajorCourses(true)
    // eslint-disable-next-line
  }, [showViewMore])

  return (
    <>
      <CourseGroup
        group={groups?.[0]}
        index={0}
        setShowViewMore={setShowViewMore}
        setShowNonMajorCourses={setShowNonMajorCourses}
        setCurrentPage={setCurrentPage}
        isRegistrationRedesign={isRegistrationRedesign}
        currentCourseNumber={currentCourseNumber}
        courseQuantity={courseQuantity}
      />
      <CollapseWrapper open={showNonMajorCourses}>
        {showViewMore && (
          <ViewMore onClick={() => setShowNonMajorCourses(show => !show)}>
            <img
              src={showNonMajorCourses
                ? '/images/icons/arrow-up.svg' : '/images/icons/arrow-down.svg'}
              alt='arrow-down'
            />
            {showNonMajorCourses ? 'Hide' : 'View'} More Options
          </ViewMore>
        )}
        {showNonMajorCourses && groups?.slice(1).map((group, index) => {
          return (
            <CourseGroup
              key={index + 1}
              group={group}
              index={index + 1}
              setCurrentPage={setCurrentPage}
              isRegistrationRedesign={isRegistrationRedesign}
              currentCourseNumber={currentCourseNumber}
              courseQuantity={courseQuantity}
            />
          )
        })}
      </CollapseWrapper>
    </>
  )
}

CourseGroups.displayName = 'CourseGroups'
export default CourseGroups
