import React, { useContext } from 'react'
import Certificate from './Certificate'
import {
  StyledWrapper,
  StyledHeaderWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledContentWrapper
} from './styled'
import { AppContext } from '../../ContextProvider/ContextProvider'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import config from '../../../config'
import { shouldShowVoucherUpload } from '../../../utilities/voucher'

const { hasVoucherNumber } = config

const VoucherUpload = () => {
  const { vouchers, updateVoucherStatus } = useContext(AppContext)
  const { isLoading, records } = vouchers || {}

  if (isLoading) return <LoadingSpinner />

  if (!shouldShowVoucherUpload(vouchers)) return null

  const renderCertificates = () => {
    return records.map((certificate, index) => {
      const { certificateName, courseName } = certificate
      const name = certificateName || courseName
      const isFirstItem = index === 0

      return (
        <Certificate
          key={name || index}
          voucherName={name}
          certificate={certificate}
          isFirstItem={isFirstItem}
          updateStatus={updateVoucherStatus}
        />
      )
    })
  }

  const subTitleText = hasVoucherNumber
    ? `
      Please upload your Amazon Career Choice Tuition Vouchers below.
      Please also include your voucher number in the provided text box.
      Only 1 voucher is required per certificate program.
    ` : `
      Please upload your Amazon Career Choice Tuition Vouchers below.
      Only 1 voucher is required per certificate program.
    `

  return (
    <StyledWrapper data-testid='voucher-upload-page'>
      <StyledHeaderWrapper>
        <StyledTitle>Amazon Voucher Upload</StyledTitle>
        <StyledSubTitle>
          {subTitleText}
        </StyledSubTitle>
      </StyledHeaderWrapper>
      <StyledContentWrapper data-testid='certificates'>
        {renderCertificates()}
      </StyledContentWrapper>
    </StyledWrapper>
  )
}

export default VoucherUpload
