import React, { useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import { PrimaryButton, SecondaryButton } from '../Buttons/style'
import {
  ModalWrapper, ModalContent, ModalFooter, GlobalStyle
} from './style'
import { AppContext } from '../ContextProvider/ContextProvider'
import { VOUCHER_STATUS } from '../../Constants/voucher'

const TuitionPaymentRequestModal = () => {
  const {
    showTuitionPaymentRequestModal,
    setShowTuitionPaymentRequestModal,
    vouchers
  } = useContext(AppContext)

  const closeModal = () => {
    setShowTuitionPaymentRequestModal(false)
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const outstandingPaymentRequest =
    vouchers?.records?.find(record =>
      record.voucherStatus === VOUCHER_STATUS.OUTSTANDING_PAYMENT_REQUEST)

  const is24HoursAwayFromCohortStart =
    Math.ceil((new Date(outstandingPaymentRequest
      ?.dateStart) - Date.now())) / 1000 / 60 / 60 > 24

  if (!outstandingPaymentRequest || !showTuitionPaymentRequestModal) return null

  return (
    <Modal
      show={showTuitionPaymentRequestModal} hideClose
      data-testid='tuition-payment-request-modal'
    >
      <GlobalStyle />
      <ModalWrapper>
        <ModalContent>
          <span>
            <p>Action Required: Submit your Payment Request</p>
            <p>Amazon Career Choice needs you to submit a Payment Request to the Amazon Career Choice Portal to approve your enrollment. Before your course starts:</p>
            <ol>
              <li>
                <p>Go to the Amazon Career Choice Dashboard and submit a payment request.</p>
              </li>
              <li>
                <p>Wait for our team to approve your payment request so you can continue to access your coursework.</p>
              </li>
            </ol>
            <p>Note: If you do not submit your Payment Request before your course starts, we will have to drop you from the program.</p>
          </span>
        </ModalContent>
        <ModalFooter>
          {is24HoursAwayFromCohortStart &&
            <SecondaryButton
              className='modal-button'
              style={{
                marginRight: '15px'
              }}
              onClick={closeModal}
            >
              Dismiss
            </SecondaryButton>}
          <PrimaryButton
            className='modal-button'
            onClick={() => openInNewTab('https://www.amazoncareerchoice.com/home')}
          >
            Submit Request
          </PrimaryButton>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  )
}

export default TuitionPaymentRequestModal
