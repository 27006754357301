import { css } from 'styled-components'

export const font32x48x300 = css`
  font-size: 32px;
  line-height: 48px;
  font-weight: 300;
`

export const font24x32x300 = css`
  font-size: 24px;
  line-height: 32px;
  font-weight: 300;
`

export const font24x29x300 = css`
  font-size: 24px;
  line-height: 29px;
  font-weight: 300;
`

export const font18x24x300 = css`
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
`

export const font18x24x400 = css`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
`

export const font14x24x400 = css`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
`

export const font16x19x400 = css`
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
`

export const font16x24x400 = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`

export const font16x24x700 = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`

export const font14x16x700 = css`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
`

export const font14x17x700 = css`
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
`
