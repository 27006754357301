import styled, { createGlobalStyle } from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import BackBreadcrumb from '../../BackBreadcrumb/BackBreadcrumb'

export const OverrideCSSProperties = createGlobalStyle`
    body {
        ${({ displayModal }) =>
          displayModal && 'height: 100%; overflow: hidden;'}
        
        ::-webkit-scrollbar {
          width: 0px;
        }
    }
    footer {
      background-color: #000000!important;
    }
    #content {
        padding: 0px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1440px;
    }
`
export const BreadCrumbComponent = styled(BackBreadcrumb)`
  margin: 88px 64px 0px 64px;
  padding: 0px;
  height: 24px;
  color: #5fc4b8;

  ${mediaqueries.mobile`
    margin-right: 24px;
    margin-left: 24px;
  `}
`

export const RelationshipPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 360px;
  width: fit-content;
  font-family: Lato;
  align-self: flex-start;
  margin: 102px auto 0px auto;
  padding: 0px 64px 0px 64px;

  ${mediaqueries.mobile`
    margin-top: 32px;
    padding: 0px 24px 0px 24px;
  `}

  & > div {
    max-width: 600px;
    h1 {
      margin-bottom: 0px;
      font-style: normal;
      font-weight: 300;
      font-size: 36px;
      line-height: 43px;
    }

    p {
      margin-bottom: 0px;
      margin-top: 6px;
      width: 510px;
      color: #b1bfc5;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;

      ${mediaqueries.mobile`
        width: 300px;
      `}
    }
  }
`
export const ConfirmButton = styled.button`
  display: block;
  width: 140px;
  color: black;
  height: 40px;
  margin-top: 19px;
  border-radius: 5px;
  background-color: #5fc4b8;
  border: none;
  text-transform: uppercase;
  outline: none!important;
  font-weight: bold;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  letter-spacing: 1px;
`
export const StyledLabel = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 28px;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  color: ${({ radioDisabled }) => (radioDisabled ? 'gray!important' : 'white')};
  margin-top: 0px;
  margin-bottom: 12px;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & > input:disabled ~ span {
    border: 1px solid gray !important;
    background-color: transparent !important;
  }
  /* Create a custom radio button */

  & > span {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 1px solid #5fc4b8;
    border-radius: 50%;
  }

  /*on mouse-over add a background color  */

  &:hover input ~ span {
    background-color: transparent;
  }

  & > input:checked ~ span {
    background-color: #5fc4b8;
  }
`
export const OptionsWrapper = styled.div`
  margin-top: 28px;
`
export const ButtonLink = styled.button`
  display: block;
  width: fit-content;
  background-color: transparent;
  padding: 0px;
  text-decoration: underline;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  outline: none !important;
  border: none;
  color: #5fc4b8;
  margin: 6px 0px auto 0px;
`
export const UseThisToken = styled(ButtonLink)`
  margin-top: 32px;
`
export const ModalWrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Modal = styled.div`
  width: 576px;
  height: auto;
  border-radius: 5px;
  background-color: #25272b;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  padding-bottom: 24px;

  ${mediaqueries.mobile`
      width: 327px;
    `}
`
export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px;

  h1 {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    font-style: normal;
    color: #ffffff;
    margin: 0px;

    ${mediaqueries.mobile`
      width: 232px
    `}
  }
`
export const Close = styled.div`
  width: 14px;
  height: 14px;
  background-image: url(/images/icons/icon-close.svg);
  margin-right: 5px;
  cursor: pointer;
`
export const ModalBody = styled.div`
  padding: 0px 24px;
`
export const ConfirmationStatement = styled.div`
  display: flex;
  margin: 24px 0 0 0;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0px;
  }
`
export const ReadPolicyLink = styled(ButtonLink)`
  text-decoration: none;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;

  ::after {
    background-image: url(images/icons/box-arrow-up-right.svg);
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
    width: 12px;
    height: 12px;
    margin-left: 12px;
    display: inline-block;
  }
`
