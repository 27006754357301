export const STUDENT_LOGIN = 'Student Login'
export const AMAZON_VOUCHER_UPLOADED_EVENT = 'Amazon Voucher Uploaded'
export const NPS_EXIT_FORM = 'NPS Exit Form'
export const NPS_EXIT_PARTNER_STUDENT = 'NPS Exit Partner Student'
export const COLLEGE_SUCCESS_STARTED_EVENT = 'College Success Started'
export const REGISTERED_FOR_COURSES = 'Registered for Courses'
// Academic achievement
export const ACADEMIC_ACHIEVEMENT_PAGE_VIEWED = 'Academic achievement page viewed'
export const CERTIFICATE_PAGE_VIEWED = 'Certificate page viewed'
export const COURSE_PAGE_VIEWED = 'Course page viewed'
export const ACADEMIC_ACHIEVEMENTS_CLICK_THROUGH = 'Academic achievements clicked'
export const COURSE_HOME_CLICK_THROUGH = 'Course home button clicked'

// GGU Registration
export const COURSE_REGISTRATION_STARTED = 'Course Registration Started'
export const COURSE_REGISTRATION_PAGEVIEW = 'Course Registration Pageview'
export const COURSE_REGISTRATION_PAGE_SUBMITTED = 'Course Registration Page Submitted'
export const NEW_COURSE_SELECTED = 'New Course Selected'
export const COURSE_REGISTRATION_NEW_COURSE_CONFIRMED = 'Course Registration New Course Confirmed'
export const SCHEDULE_ADVISOR_MEETING_CLICK = 'Schedule Advisor Meeting Click'

// Study Blocks
export const STUDY_BLOCKS_SCHEDULE_STARTED = 'Study Blocks Schedule Started'
export const STUDY_BLOCKS_FIRST_POPUP_CLICKED = 'Studyblocks Popup 1 clicked'
export const STUDY_BLOCKS_SECOND_POPUP_CLICKED = 'Studyblocks Popup 2 clicked'
