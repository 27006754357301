import { Title } from '../Components/WhatsNextUpdate/WritingAssignments/style'
import React from 'react'

export const getMultipleAssignmentsText = (submittedAssignments) => {
  return submittedAssignments.map((submittedAssignment, index) => {
    if (index === 0) {
      return (<Title key={index}>{submittedAssignment.title}</Title>)
    }
    let titleSeparator = ', '
    if (index === submittedAssignments.length - 1) {
      titleSeparator = ', and '
      if (index === 1) {
        titleSeparator = ' and '
      }
    }
    return (
      <span key={index}>
        {titleSeparator}<Title>{submittedAssignment.title}</Title>
      </span>
    )
  })
}
