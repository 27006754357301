import styled from 'styled-components'
import mediaqueries from '../../../../mediaQueries'
import BackBreadcrumb from '../../../BackBreadcrumb/BackBreadcrumb'

export const PageWrapper = styled.div`
  margin: 92px 0 48px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${mediaqueries.tablet`
    margin-right: 80px;
    margin-left: 80px;
  `}

  ${mediaqueries.mobile`
    margin-right: 24px;
    margin-left: 24px;
  `}
`

export const PageContent = styled.div`
  width: 600px;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  > p {
    margin: 0;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  ${mediaqueries.tablet`
    margin-top: 0;
    width: 100%;
  `}
`

export const PageTitle = styled.h2`
  margin: 0;
  color: #FFF;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`

export const Separator = styled.hr`
  opacity: 0.3;
  background-color: #FFF;
  width: 100%;
  height: 1px;
  border: 0 none;
  margin: 0;
`

export const FormWrapper = styled.div`
  width: 100%;
  height: 600px;
`

export const BackButton = styled(BackBreadcrumb)`
  margin: 0;
  position: absolute;
  left: 24px;

  ${mediaqueries.tablet`
    position: static;
    left: 0;
    align-self: flex-start;
    padding-left: 0;
  `}
`
