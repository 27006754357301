import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../ContextProvider/ContextProvider'
import config from '../../config'
import {
  CardWrapper,
  CardInfoWrapper,
  CardImgWrapper,
  CardTextAndNoteWrapper,
  CardTextWrapper,
  CardImg,
  CardTitle,
  CardDescription,
  CardNote,
  CardButtons,
  CardLeftButton,
  CardRightButton,
  CrossImg,
  CardHeaderWrapper,
  ExtensionRowsWrapper,
  HideLink,
  CourseIsNewTag
} from './style'
import { PrimaryButton, SecondaryButton } from '../Buttons/style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { getCourseAssets, hasStudentMadeProgresses } from '../../utilities/course'
import ExtensionRows from '../ExtensionRows/ExtensionRows'
import api from '../../api'
import { DASHBOARD_COURSE_HIDDEN } from '../../Constants/CourseStatus'

const CardInfo = ({
  course,
  description,
  studyGuideNote,
  note,
  primaryBtnTxt,
  secondaryBtnTxt,
  handlePrimaryButton,
  handleSecondaryButton,
  isLoading,
  dataTestId,
  handleCrossIcon,
  activityHasNotStarted,
  disabled
}) => {
  const [courseIsNew, setCourseIsNew] = useState(false)
  const {
    coursesProgress,
    catalogCourses,
    courseFeatures,
    hiddenCourses
  } = useContext(AppContext)

  const { id, displayName } = course || {}
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)
  const { courseIcon } = getCourseAssets(catalogCourses, id)

  useEffect(() => {
    if (activityHasNotStarted) {
      setCourseIsNew(!hasStudentProgressed(coursesProgress, course.id))
    }
    // eslint-disable-next-line
  }, [course, coursesProgress])

  const hasStudentProgressed = (coursesProgress, courseId) => {
    if (!coursesProgress || !courseId) return
    const courseUnderScrutiny = coursesProgress.find(course => course.courseId === courseId)

    return hasStudentMadeProgresses(courseUnderScrutiny?.studentProgress)
  }

  const { isTitleUpdates, isCollegeSuccessCourse } = config
  const isCollegeSuccess = isCollegeSuccessCourse(course?.id)
  const onHideCourse = async () => {
    const key = DASHBOARD_COURSE_HIDDEN
    const body = {
      [key]: [course?.id]
    }
    await api.setCourseFeatures(key, body)
    courseFeatures()
  }
  useEffect(() => {
    if (course?.id && courseFeatures && isCollegeSuccess) {
      courseFeatures()
    }
  // eslint-disable-next-line
  },[])
  if (!description || hiddenCourses?.includes(course?.id)) return null

  return (
    <CardWrapper data-testid={dataTestId}>
      <CardInfoWrapper>
        <CardTextAndNoteWrapper>
          <CardTextWrapper>
            <CardHeaderWrapper>
              <CardImgWrapper>
                {courseIcon && <CardImg data-testid='card-icon' src={courseIcon} />}
              </CardImgWrapper>
              {displayName &&
                <CardTitle
                  data-testid='display-name'
                  isTitleUpdates={isTitleUpdates}
                  href={courseBaseUrl}
                  rel='noopener noreferrer'
                  disabled={disabled}
                >
                  {displayName}
                </CardTitle>}
              {courseIsNew && <CourseIsNewTag data-testid='new-tag' />}
            </CardHeaderWrapper>
            <CardDescription>
              {description}
            </CardDescription>
            {isCollegeSuccess &&
              <HideLink
                rel='noopener noreferrer'
                onClick={onHideCourse}
              >
                Hide Course
              </HideLink>}
          </CardTextWrapper>
          {studyGuideNote && <CardNote>{studyGuideNote}</CardNote>}
          {note && <CardNote data-testid='card-note'>{note}</CardNote>}
        </CardTextAndNoteWrapper>
        <CardButtons>
          {primaryBtnTxt &&
            <CardLeftButton
              margin={secondaryBtnTxt}
              data-testid='card-primary-btn'
            >
              <PrimaryButton
                disabled={isLoading}
                onClick={() => handlePrimaryButton()}
              >
                {isLoading
                  ? <FontAwesomeIcon icon={faSpinner} spin />
                  : primaryBtnTxt}
              </PrimaryButton>
            </CardLeftButton>}
          {secondaryBtnTxt &&
            <CardRightButton data-testid='card-secondary-btn'>
              <SecondaryButton
                onClick={() => handleSecondaryButton()}
              >
                {secondaryBtnTxt}
              </SecondaryButton>
            </CardRightButton>}
        </CardButtons>
      </CardInfoWrapper>
      {handleCrossIcon &&
        <CrossImg
          data-testid='card-cross-icon'
          src='images/icons/cross.svg' onClick={() => handleCrossIcon()}
        />}
      <ExtensionRowsWrapper>
        <ExtensionRows course={course} />
      </ExtensionRowsWrapper>
    </CardWrapper>
  )
}

export default CardInfo
