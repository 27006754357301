import styled from 'styled-components'

import media from '../../mediaQueries'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'

import { THEME } from './ExtensionRows'

export const Wrapper = styled.div`
  background: ${({ theme }) => theme === THEME.WARNING ? '#342215' : '#351915'};
  padding: 16px;
  border-radius: 5px;
  display: flex;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  ${media.mobile`
    flex-direction: row-reverse;
  `}
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  ${media.mobile`
    margin-right: auto;
    flex-direction: column;
  `}
`

export const Icon = styled(CalendarIcon)`
  fill: ${({ theme }) => theme === THEME.WARNING ? '#FFB800' : '#F2765A'};
  height: 24px;
  width: 24px;
  ${media.mobile`
    height: 32px;
    width: 32px;
    margin: auto 0;
    margin-left: 5px;
  `}
`

export const LeftSectionText = styled.div`
  margin-left: 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  ${media.mobile`
    margin: auto;
    margin-left: 0;
  `}
`

export const ExtensionLink = styled.a`
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin: auto;
  margin-right: 0;
  color: #FFFFFF;
  &:hover {
    color: #FFFFFF;
    text-decoration: none;
  }
  ${media.mobile`
    margin: auto;
    margin-left: 0;
  `}
`

export const ExtensionLinkIcon = styled.span`
  margin-left: 13px;
`

export const ExtendedText = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: auto;
  margin-right: 0;
  color: ${({ theme }) => theme === THEME.WARNING ? '#FFB800' : '#F2765A'};

  ${media.mobile`
    margin: auto;
    margin-left: 0;
  `}
`
