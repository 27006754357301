import React, { useState } from 'react'
import {
  BoxWrap,
  CardText,
  EnrolText,
  Button,
  Line,
  BodyItem
} from './style/index'
import {
  getDateStringWithYearLongMonth,
  changePTDateToLocalTime
} from '../../utilities/dateTime'
import EditCard from '../ScheduleReview/EditCard'
import { TYPE, DURATION } from '../../Constants/courseCard'
import { cohortTypes, durationTypes } from '../../Constants/cohort'

const CoursesCard = (props) => {
  const { name, duration, status, dateStart, id, finalExamEndTime } = props.data
  const { SHORT } = cohortTypes
  const { INTENSIVE, STANDARD } = durationTypes
  const initialWeek = duration === SHORT ? INTENSIVE : STANDARD

  const [type, setType] = useState(status)
  const [week, setWeek] = useState(initialWeek)
  const [startDate, setStartDate] = useState(changePTDateToLocalTime(dateStart))
  const [editData, setEditData] = useState(null)

  const setCourseState = (value, courseState) => {
    switch (courseState) {
      case 'type':
        return setType(value)
      case 'duration':
        return setWeek(value)
      default:
        return false
    }
  }

  return (
    <BodyItem
      data-testid='courses-card'
      active={props.isEdit && id !== editData}
    >
      <BoxWrap>
        <CardText>{name}</CardText>
        {props.isEdit && id === editData ? null : (
          <>
            <EnrolText>
              {TYPE[status]} - {DURATION[initialWeek]}
            </EnrolText>
            <EnrolText>
              {getDateStringWithYearLongMonth(startDate, 'short')} -{' '}
              {getDateStringWithYearLongMonth(finalExamEndTime, 'short')}
            </EnrolText>
          </>
        )}
      </BoxWrap>
      <BoxWrap className='margin-left'>
        <Button
          onClick={() => {
            if (props.isEdit && id === editData) {
              props.setIsEdit(false)
              setEditData('')
            } else {
              props.setIsEdit(true)
              setEditData(id)
            }
          }}
          data-testid='cancel-button'
        >
          {props.isEdit && id === editData ? 'Cancel' : 'Edit'}
        </Button>
        <Button onClick={() => props.removeClick()} data-testid='remove-button'>
          Remove
        </Button>
      </BoxWrap>
      {props.isEdit && id === editData ? (
        <div>
          <EditCard
            data={props.data}
            setStartDate={setStartDate}
            startDate={startDate}
            courseStates={{ duration: week, type }}
            type={type}
            week={week}
            setIsEdit={props.setIsEdit}
            onUpdate={props.onUpdate}
            removeEditId={() => setEditData('')}
            setCourseState={setCourseState}
            isToken
          />
        </div>
      ) : null}

      <Line data-testid='line' />
    </BodyItem>
  )
}

export default CoursesCard
