import React from 'react'
import CardInfo from '../CardInfo'

const GradedCard = ({
  course,
  note,
  assignment,
  courseBaseUrl,
  sectionUrl,
  sectionName
}) => {
  const assignmentUrl = `${courseBaseUrl}/#/${assignment.chapter_uuid}/writing_assignment`

  const description = (
    <>
      {'Your '}
      <a href={assignmentUrl} rel='noopener noreferrer'>
        {assignment.title}
      </a> {'has been graded! View your grade or continue to '}
      <a href={sectionUrl} rel='noopener noreferrer'>Section {sectionName}</a>
      {' to keep working.'}
    </>
  )

  return (
    <CardInfo
      course={course}
      description={description}
      note={note}
      primaryBtnTxt='View'
      secondaryBtnTxt='Continue'
      dataTestId='writing-graded'
      handlePrimaryButton={() => {
        window.location.href = assignmentUrl
      }}
      handleSecondaryButton={() => {
        window.location.href = sectionUrl
      }}
    />
  )
}

export default GradedCard
