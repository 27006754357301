import { uniqBy, isEqual, uniq } from 'lodash'
import qs from 'qs'
import config from '../config'
import { checkCourseNames, isEnrolledCourse } from './course'
import { convertEasternTimeToLocalTime, dateToSecondsSinceEpoch, diffDays } from './dateTime'
import {
  COMPLETED,
  IN_PRODUCTION,
  ENROLLED,
  HAS_PRE_REQUISITE,
  PREPARE_CALCULUS,
  SELECTED,
  COMPLETED_NOT_REPEATABLE,
  NOT_ENROLLABLE
} from '../Constants/CourseStatus'
import {
  APPLIED_COMPUTING,
  LIBERAL_STUDIES,
  BUSINESS_ADMINISTRATION,
  APPLIED_COMPUTING_INFO_LINK,
  LIBERAL_STUDIES_INFO_LINK,
  BUSINESS_ADMINISTRATION_INFO_LINK,
  SEGMENT_EVENTS_V1_LS_KEY,
  SEGMENT_EVENTS_V2_LS_KEY, FULL_TIME_SLOT, PART_TIME_SLOT
} from '../Constants/gguDegrees'
import { ENROLLED_STATUSES, PRE_DROP, SIGNED_UP } from '../Constants/studentStatus'
import {
  GGU_ENROLLMENT_URLS,
  GGU_V2_ENROLLMENT_PAGES
} from '../Constants/gguEnrollmentUrls'
import { isAuditCohort, isVIPCohort } from './cohort'
import { OFFER_NOT_ACCEPTED } from '../Constants/prospectStatus'
import {
  COURSE_SUBSTITUTES
} from '../Constants/courseSubstitutes'
import { CWIV2GGUID } from '../Constants/course'

export const getGGUDegreeInfoLink = (degree) => {
  switch (degree) {
    case APPLIED_COMPUTING:
      return APPLIED_COMPUTING_INFO_LINK
    case LIBERAL_STUDIES:
      return LIBERAL_STUDIES_INFO_LINK
    case BUSINESS_ADMINISTRATION:
      return BUSINESS_ADMINISTRATION_INFO_LINK
    default: return null
  }
}

export const isRecommendedCourse = courseId => {
  if (!courseId) return false

  const recommendedCoursesInLS = getRecommendedCoursesFromLS()
  return !!recommendedCoursesInLS?.find(course => (
    course?.courseId === courseId
  ))
}

export const isCompletedAndNotRepeatable = params => {
  const { attemptStatuses } = params || {}
  if (!attemptStatuses?.length) return false

  const hasMoreThanOneCompletedAttempt =
    attemptStatuses.filter(status => status === COMPLETED).length > 1
  if (hasMoreThanOneCompletedAttempt) return true

  const { courseId, transferredCredit } = params || {}
  const hasCompletedAttemptAndIsTransferCredit =
    attemptStatuses.includes(COMPLETED) &&
    transferredCredit?.some(course => course.courseId === courseId)

  return hasCompletedAttemptAndIsTransferCredit
}

export const hasPrerequisiteRequirement = params => {
  const {
    courseId,
    prerequisiteUUID,
    transferredPrerequisite,
    transferredCredit,
    studentAttempts,
    courseProjectedGrades
  } = params || {}
  if (!courseId && !prerequisiteUUID) return false

  const hasTransferedPrerequisite =
    transferredPrerequisite?.find(coursePrerequisite => (
      coursePrerequisite?.courseId === courseId
    ))
  if (hasTransferedPrerequisite) return false

  const hasTransferredCredit = transferredCredit?.find(coursePrerequisite => (
    coursePrerequisite?.courseId === prerequisiteUUID
  ))
  if (hasTransferredCredit) return false

  const upjPrerequisiteCourseUUId = config.getUPJCourseIdFromGGUCourseId(
    prerequisiteUUID
  )
  const prerequisiteAttempts = studentAttempts?.filter(attempt => {
    const { id } = attempt?.fields?.course?.fields || {}
    return [prerequisiteUUID, upjPrerequisiteCourseUUId].includes(id)
  })
  const hasPassedPrerequisite = prerequisiteAttempts?.some(attempt => (
    attempt?.fields?.studentStatus?.fields?.Name === COMPLETED &&
    courseProjectedGrades?.[attempt?.fields?.course?.fields?.id] >= 70
  ))

  return !hasPassedPrerequisite
}

export const getGGUCourseStatus = ({
  ignoreLS,
  outlierCourses,
  studentAttempts,
  uuid,
  courseName,
  prospects,
  courseProjectedGrades,
  prerequisite
} = {}) => {
  if (config.isCollegeSuccessCourse(uuid)) return null

  if (!ignoreLS) {
    if (isRecommendedCourse(uuid)) return SELECTED
  }

  const currentProspect = getCurrentProspect(prospects)
  const { transferredCredit } = currentProspect || {}
  const courseAttempts = studentAttempts?.filter(attempt => {
    const { id } = attempt?.fields?.course?.fields || {}
    return id === uuid
  })
  const attemptStatuses = courseAttempts?.map(attempt => (
    attempt.fields?.studentStatus?.fields?.Name
  ))

  const isNotRepeatable = isCompletedAndNotRepeatable({
    courseId: uuid, attemptStatuses, transferredCredit
  })
  if (isNotRepeatable) return COMPLETED_NOT_REPEATABLE

  const isNotEnrollable = hasCompletedSubstituteCourse({
    currentProspect, studentAttempts, courseProjectedGrades, courseName
  })
  if (isNotEnrollable) return NOT_ENROLLABLE

  const isEnrolled = attemptStatuses?.some(status => ENROLLED_STATUSES.includes(status))
  if (isEnrolled) return ENROLLED

  const isCompleted = attemptStatuses?.includes(COMPLETED) ||
    transferredCredit?.find(course => course.courseId === uuid)
  if (isCompleted) return COMPLETED

  if (prerequisite) {
    const { transferredPrerequisite } = currentProspect || {}
    const shouldCompletePrerequisite = hasPrerequisiteRequirement({
      courseId: uuid,
      prerequisiteUUID: prerequisite?.uuid,
      transferredPrerequisite,
      transferredCredit,
      studentAttempts,
      courseProjectedGrades
    })
    if (shouldCompletePrerequisite) return HAS_PRE_REQUISITE
  }

  const course = outlierCourses?.find(course => course.id === uuid)
  if (!course) return IN_PRODUCTION

  const isPrecalc = course.displayName?.toLowerCase().includes('precalculus')
  if (isPrecalc) return PREPARE_CALCULUS

  return null
}

export const getGGUSemesterEnrollmentStatus = (currentSemester) => {
  const status = {
    isWithinEnrollmentPeriod: false,
    isPostEnrollmentPeriod: false,
    isBeforeEnrollmentPeriod: false
  }

  if (!currentSemester) return status

  const {
    openEnrollment,
    closeEnrollment
  } = currentSemester
  const now = new Date()

  status.isPostEnrollmentPeriod = now > new Date(closeEnrollment)
  status.isWithinEnrollmentPeriod = (now >= new Date(openEnrollment)) &&
    (now <= new Date(closeEnrollment))
  status.isBeforeEnrollmentPeriod = now < new Date(openEnrollment)

  return status
}

export const setRecommendedCoursesInLS = (courseIds) => {
  localStorage.setItem(
    'recommendedCourses',
    JSON.stringify(courseIds?.map(courseId => ({ courseId })))
  )
}

export const getRecommendedCoursesFromLS = () => {
  return JSON.parse(localStorage.getItem('recommendedCourses'))
}

export const replaceRecommendedCoursesInLS = (replacementIds, replacedIds) => {
  const recommendedCoursesInLS = getRecommendedCoursesFromLS() || []

  if (!replacedIds) {
    return setRecommendedCoursesInLS(
      recommendedCoursesInLS.map(course => course.courseId)
        .concat(replacementIds)
    )
  }

  const newRecommendedCourses = recommendedCoursesInLS
    .reduce((courses, currentCourse) => {
      const { courseId } = currentCourse || {}
      if (replacedIds.includes(courseId)) {
        const index = replacedIds.indexOf(courseId)
        if (index > -1) currentCourse.courseId = replacementIds?.[index]
      }
      courses.push(currentCourse)
      return courses
    }, [])
    .map(course => course.courseId)

  setRecommendedCoursesInLS(newRecommendedCourses)
}

export const removeRecommendedCoursesInLS = (courseIds) => {
  if (typeof courseIds === 'boolean' && courseIds) {
    return localStorage.removeItem('recommendedCourses')
  }

  if (!Array.isArray(courseIds)) return

  const recommendedCoursesInLS = getRecommendedCoursesFromLS()

  const newRecommendedCourses = recommendedCoursesInLS
  ?.filter(course => {
    const { courseId } = course || {}
    return !courseIds.includes(courseId)
  })
  .map(course => course.courseId)

  setRecommendedCoursesInLS(newRecommendedCourses)
}

export const getRecommendationGroupCourses = (courseId, degreeData) => {
  const recommendationGroup = degreeData?.find(course => (
    course.courseId === courseId
  ))?.recommendationGroup

  if (!recommendationGroup?.length) return

  return degreeData?.filter(course => (
    isEqual(course?.recommendationGroup, recommendationGroup)
  ))
}

export const hasCompltedCourseFromGroup = ({
  courseId,
  studentAttempts,
  degreeData,
  prospects
}) => {
  const recommendationGroupCourses = getRecommendationGroupCourses(
    courseId,
    degreeData
  )

  if (!recommendationGroupCourses?.length) return true

  const { transferredCredit = [] } = prospects?.[0] || {}

  const hasCompletedFromTransfer = transferredCredit.some(course => (
    recommendationGroupCourses.some(groupCourse => {
      return course.courseId === groupCourse.courseId
    })
  ))

  if (hasCompletedFromTransfer) return true

  const hasCompletedFromAttempts = recommendationGroupCourses
    .some(groupCourse => {
      const courseAttempts = studentAttempts?.filter(attempt => {
        const { id } = attempt?.fields?.course?.fields || {}
        return id === groupCourse.courseId
      })

      return courseAttempts?.some(attempt => {
        return attempt.fields?.studentStatus?.fields?.Name === COMPLETED
      })
    })

  return hasCompletedFromAttempts
}

export const canTakeEconomicsCourses = ({
  studentAttempts,
  prospects,
  outlierCourses,
  courseId,
  degreeData
}) => {
  const isMicroOrMacro = checkCourseNames([
    'microeconomics.plus',
    'macroeconomics.plus'
  ], courseId)

  if (!isMicroOrMacro) return true

  const principlesofeconomics = degreeData?.find(course => (
    checkCourseNames(['principlesofeconomics.plus'], course?.courseId)
  ))

  const principlesofeconomicsStatus = getGGUCourseStatus({
    uuid: principlesofeconomics?.courseId,
    courseName: principlesofeconomics?.courseName,
    studentAttempts,
    prospects,
    outlierCourses
  })

  return principlesofeconomicsStatus !== COMPLETED_NOT_REPEATABLE
}

export const getRecommendedCourses = ({
  ignoreLS,
  outlierCourses,
  studentAttempts,
  prospects,
  noOfSlots,
  degreeData,
  catalogCourses
} = {}) => {
  // filter courses based on availability
  const filteredCourses = degreeData?.filter(course => {
    const { prerequisite } = catalogCourses?.find(catalogCourse => (
      catalogCourse?.uuid?.uuid === course?.courseId
    )) || {}

    const courseStatus = getGGUCourseStatus({
      ignoreLS,
      uuid: course.courseId,
      courseName: course.courseName,
      studentAttempts,
      prospects,
      outlierCourses,
      prerequisite
    })

    const canTakeMicroAndMacro = canTakeEconomicsCourses({
      studentAttempts,
      prospects,
      outlierCourses,
      courseId: course.courseId,
      degreeData
    }) // returns true if course is not Micro/Macroeconomics

    return ![COMPLETED,
      COMPLETED_NOT_REPEATABLE,
      IN_PRODUCTION,
      ENROLLED,
      HAS_PRE_REQUISITE,
      SELECTED,
      NOT_ENROLLABLE
    ].includes(courseStatus) &&
      canTakeMicroAndMacro
  }) || []

  const hasCompletedGGUCourseAttempts = studentAttempts?.some(attempt => {
    const { id } = attempt?.fields?.course?.fields || {}
    const { Name } = attempt?.fields?.studentStatus?.fields || {}
    return degreeData?.some(course => course?.courseId === id) &&
    Name === COMPLETED
  })

  if (
    !noOfSlots ||
    !filteredCourses?.length ||
    filteredCourses.length <= noOfSlots
  ) return filteredCourses

  if (!hasCompletedGGUCourseAttempts) return filteredCourses.slice(0, noOfSlots)

  // The filter below chooses one course from each recommendation group if
  // student has not completed any course from that group. But if student
  // has completed any course from the group, all courses belonging to
  // the group will be included in the list

  const newFilteredCourses = filteredCourses
    .filter(course => hasCompltedCourseFromGroup({
      courseId: course.courseId,
      studentAttempts,
      degreeData,
      prospects
    }))
    .concat(
      uniqBy(
        filteredCourses.filter(course => !hasCompltedCourseFromGroup({
          courseId: course.courseId,
          studentAttempts,
          degreeData,
          prospects
        })),
        'recommendationGroup'
      ))
    .sort((a, b) => a.priority - b.priority)

  if (newFilteredCourses.length < noOfSlots) filteredCourses.slice(0, noOfSlots)

  // we will check for heavylift courses and recommend a maximum of 2

  const noOfHeavyLiftCourses = newFilteredCourses
    .slice(0, noOfSlots)
    .filter(course => course.heavyLift)
    .length

  if (noOfHeavyLiftCourses <= 2) return newFilteredCourses.slice(0, noOfSlots)

  const nonHeavyLiftCourse = newFilteredCourses
    .slice(noOfSlots)
    .find(course => !course.heavyLift)

  if (!nonHeavyLiftCourse) return filteredCourses.slice(0, noOfSlots)

  return newFilteredCourses.slice(0, 2).concat(nonHeavyLiftCourse)
}

export const getVisitedPages = () => {
  const visitedPages = localStorage.getItem('visitedPages')
  return visitedPages ? JSON.parse(visitedPages) : []
}

export const setVisitedPages = (pathname) => {
  const visitedPages = getVisitedPages()
  visitedPages.push(pathname)
  localStorage.setItem('visitedPages', JSON.stringify(uniq(visitedPages)))
}

export const clearVisitedPages = () => {
  localStorage.removeItem('visitedPages')
}

export const getNextPage = (pathname) => {
  const visitedPages = getVisitedPages()

  if (!visitedPages.includes(pathname)) return
  const nextPage = GGU_ENROLLMENT_URLS.find(url => !visitedPages.includes(url))

  return nextPage
}

export const isGGUCourse = courseName => !!courseName?.includes('GGU')

export const hasActiveCourses = courses => {
  const _hasActiveCourses = courses?.some(course => {
    const { id } = course || {}
    if (config.isCollegeSuccessCourse(id)) return false
    return isEnrolledCourse(course)
  })

  return !!_hasActiveCourses
}

export const canStudentEnrollInUPJCourses = ({
  outlierCourses,
  currentSemester,
  studentAttempts,
  degreeData,
  isActiveGGUStudent
}) => {
  // non GGU students can enroll in UPJ courses
  if (
    !isActiveGGUStudent ||
    !outlierCourses?.length ||
    !currentSemester ||
    !studentAttempts ||
    !degreeData?.length
  ) return true

  const hasEnrolledInGGUCourse = isEnrolledInCurrentSemester({
    studentAttempts, degreeData, currentSemester
  })
  if (hasEnrolledInGGUCourse) return false

  const { closeEnrollment, openEnrollment } = currentSemester

  const now = new Date()
  const openEnrollmentDate = new Date(openEnrollment)
  if (now.getTime() >= openEnrollmentDate.getTime()) return false

  const allCohorts = outlierCourses.reduce((acc, course) => {
    const { cohorts } = course || {}
    return acc.concat(cohorts)
  }, []) || []

  const areThereEnrollableCohorts = allCohorts.some(cohort => {
    if (cohort?.testCohort) return false
    const { finalRegistrationDate, finalExamEndTime } = cohort || {}

    const cohortFinalRegistrationDate = convertEasternTimeToLocalTime(
      finalRegistrationDate, 'T15:00:00'
    )

    const closeEnrollmentDate = new Date(closeEnrollment)
    const cohortFinalExamEndTime = new Date(finalExamEndTime)

    return cohortFinalRegistrationDate?.getTime() >= now.getTime() &&
    cohortFinalExamEndTime?.getTime() <= closeEnrollmentDate?.getTime()
  })

  return areThereEnrollableCohorts
}

export const isEnrolledInCurrentSemester = ({
  currentSemester, studentAttempts, degreeData
} = {}) => {
  const enrollmentCloseDate = new Date(currentSemester?.closeEnrollment)

  const enrolledCourses = studentAttempts?.filter(attempt => {
    const { id } = attempt?.fields?.course?.fields || {}
    const isDegreeCourse = degreeData?.some(course => course?.courseId === id)
    if (!isDegreeCourse) return false

    const { dateStart } = attempt?.fields?.cohort?.fields || {}
    return new Date(dateStart) > enrollmentCloseDate
  })

  return !!enrolledCourses?.some(attempt => {
    return [ENROLLED, SIGNED_UP, PRE_DROP, COMPLETED].includes(
      attempt.fields?.studentStatus?.fields?.Name
    )
  })
}

export const getStudentSemester = semesters => {
  if (!semesters?.length) return null

  return semesters.sort((a, b) => {
    return new Date(b.openEnrollment) - new Date(a.openEnrollment)
  })?.[0] || null
}

export const getCurrentAndFutureSemesters = (
  allGGUSemesters, studentSemesterOpenEnrollmentDate
) => {
  if (!allGGUSemesters?.length || !studentSemesterOpenEnrollmentDate) return null

  return allGGUSemesters.filter(semester => {
    return new Date(semester?.openEnrollment) >= studentSemesterOpenEnrollmentDate
  })
}

export const getCurrentGGUSemester = params => {
  const { allGGUSemesters, studentAssignedSemesters } = params || {}
  if (!studentAssignedSemesters?.length || !allGGUSemesters?.length) return null

  const studentSemester = getStudentSemester(studentAssignedSemesters)
  if (!studentSemester) return null

  const {
    semesterStart: studentSemesterStart,
    openEnrollment: studentSemesterOpenEnrollment
  } = studentSemester
  const studentSemesterStartDate = new Date(studentSemesterStart)
  const studentSemesterOpenEnrollmentDate = new Date(studentSemesterOpenEnrollment)

  const now = new Date()
  if (now < studentSemesterStartDate) return { ...studentSemester, semesterNumber: 1 }

  const eligibleSemesters = getCurrentAndFutureSemesters(
    allGGUSemesters, studentSemesterOpenEnrollmentDate
  )
  if (!eligibleSemesters?.length) return null

  const sortedSemesters = eligibleSemesters.sort((a, b) => {
    return new Date(a?.openEnrollment) - new Date(b?.openEnrollment)
  })

  const currentSemester = sortedSemesters
    .map((semester, index) => ({ ...semester, semesterNumber: index + 1 }))
    .find((semester, index) => {
      const { openEnrollment, closeEnrollment } = semester || {}
      const openEnrollmentDate = new Date(openEnrollment)
      const closeEnrollmentDate = new Date(closeEnrollment)

      if (now >= openEnrollmentDate && now <= closeEnrollmentDate) return true

      const nextSemesterOpenEnrollment = sortedSemesters[index + 1]?.openEnrollment
      if (now > closeEnrollmentDate &&
      (!nextSemesterOpenEnrollment || now <= new Date(nextSemesterOpenEnrollment))) return true

      return false
    })

  return currentSemester || null
}

export const getCurrentProspect = prospects => prospects?.[0] || null

/**
 * @param {function} updateContext
 * @param {object} prospect
 * @description Currently, students have only one prospect record each,
 * hence prospects is an array of only one object. In future, a student
 * may have many prospect records. At that time, the logic to update the
 * prospects array will change. Hence it is handled in a separate util.
 */
export const setCurrentProspect = (updateContext, prospect) => {
  if (!prospect) return
  updateContext({ prospects: [prospect] })
}

export const showGGUEnrollment = ({
  prospects, currentSemester, isActiveGGUStudent, isGGUStudentEnrolled
} = {}) => {
  if (!isActiveGGUStudent || isGGUStudentEnrolled || !currentSemester) return false

  const currentProspect = getCurrentProspect(prospects)
  const isOfferNotAccepted =
    currentProspect?.prospectStatus === OFFER_NOT_ACCEPTED

  if (!currentProspect || isOfferNotAccepted ||
    currentProspect.goodFinancialStanding?.toLowerCase() !== 'yes') return false

  const { openEnrollment, closeEnrollment } = currentSemester || {}
  const openEnrollmentDate = new Date(openEnrollment)
  const closeEnrollmentDate = new Date(closeEnrollment)
  const now = new Date()
  return now >= openEnrollmentDate && now <= closeEnrollmentDate
}

export const getNextRegistrationPage = () => {
  const visitedPages = getVisitedPages()
  if (!visitedPages?.length) return GGU_V2_ENROLLMENT_PAGES.WELCOME_PAGE

  const nextPage = Object.values(GGU_V2_ENROLLMENT_PAGES)
    .find(page => {
      const { pathName, revisitIfLastVisited, skipVisitedPages } = page
      const isLastVisitedPage = visitedPages.indexOf(pathName) === visitedPages.length - 1
      if (isLastVisitedPage) return revisitIfLastVisited
      return !visitedPages.includes(pathName) && !skipVisitedPages
    })
  return nextPage
}

export const getRecommendedCohorts = ({
  recommendedCourses, outlierCourses, semesterStart
}) => {
  const recommendedCourseIds = recommendedCourses?.map(course => course.courseId)

  const recommendedOutlierCourses = outlierCourses?.filter(course => (
    recommendedCourseIds.includes(course.id)
  ))

  const semesterStartDate = dateToSecondsSinceEpoch(
    new Date(semesterStart)
  )
  const recommendedCohortIds = recommendedOutlierCourses?.map(course => {
    const cohort = course.cohorts.find(cohort => {
      const { name, dateStart: cohortDateStart } = cohort || {}
      if (isVIPCohort(name) || isAuditCohort(name)) return false

      const dateStart = dateToSecondsSinceEpoch(new Date(cohortDateStart))
      const diff = diffDays(dateStart, semesterStartDate)
      return diff <= 2
    })

    return cohort?.id
  })

  return recommendedCohortIds?.filter(id => id)
}

export const getSegmentEventsLSKey = () => {
  const params = qs.parse(window.location.search?.slice(1))
  const isRegistrationRedesign = params?.gguEnrollmentV2 === 'true'
  if (isRegistrationRedesign) return SEGMENT_EVENTS_V2_LS_KEY

  return SEGMENT_EVENTS_V1_LS_KEY
}

export const getSegmentEventsFromLS = () => {
  const key = getSegmentEventsLSKey()
  if (!key) return

  try {
    return JSON.parse(localStorage.getItem(key)) || []
  } catch (error) {
    console.error('Error in getSegmentEventsFromLS: ', error?.message)
    localStorage.removeItem(key)
    return []
  }
}

export const setFiredSegmentEventsInLS = event => {
  if (!event) return

  const key = getSegmentEventsLSKey()
  if (!key) return

  const existingEvents = getSegmentEventsFromLS() || []
  existingEvents.push(event)

  localStorage.setItem(key, JSON.stringify(existingEvents))
}

export const isSegmentEventInLS = event => {
  if (!event) return false

  const key = getSegmentEventsLSKey()
  if (!key) return

  const existingEvents = getSegmentEventsFromLS() || []
  if (!existingEvents?.length) return false

  return !!existingEvents.find(existingEvent => {
    const { eventName, pageNumber } = existingEvent
    return eventName === event.eventName && pageNumber === event.pageNumber
  })
}

export const removeSegmentEventFromLS = event => {
  if (!event) return

  const key = getSegmentEventsLSKey()
  if (!key) return

  const existingEvents = getSegmentEventsFromLS() || []
  const newEvents = existingEvents.filter(existingEvent => {
    const { eventName, pageNumber } = existingEvent
    return !(eventName === event.eventName && pageNumber === event.pageNumber)
  })

  localStorage.setItem(key, JSON.stringify(newEvents))
}

// Snippet included for VWO Split URL testing - success page tracking
export const updateVWOGoalConversion = () => {
  try {
    window._vis_opt_queue = window._vis_opt_queue || []
    // eslint-disable-next-line
    window._vis_opt_queue.push(function () { _vis_opt_goal_conversion(206) })
  } catch (error) {
    console.error('Error in VWO Goal Conversion snippet: ', error?.message)
  }
}

export const shouldRedirectToRecommendedCourses = (showGGUEnrollment, prospects) => {
  if (!prospects?.length || !showGGUEnrollment) return false

  const currentProspect = getCurrentProspect(prospects)
  if (!currentProspect) return false

  const { studyMode } = currentProspect || {}

  const studyModeToSlots = {
    'Full-time': FULL_TIME_SLOT,
    'Part-time': PART_TIME_SLOT
  }
  const noOfSlots = studyModeToSlots[studyMode] || PART_TIME_SLOT

  let recommendedCourses = []
  try {
    recommendedCourses = JSON.parse(localStorage.getItem('recommendedCourses')) ?? []
  } catch (error) {
    console.error('Error parsing recommended courses:', error)
  }

  return recommendedCourses.length >= noOfSlots || recommendedCourses.length === 0
}

export const hasCompletedSubstituteCourse = params => {
  const {
    currentProspect,
    studentAttempts,
    courseName: activeCourseName,
    courseProjectedGrades
  } = params || {}

  const courseAttempts = studentAttempts?.filter(attempt => {
    const { name: courseName } = attempt?.fields?.course?.fields || {}
    return COURSE_SUBSTITUTES?.[activeCourseName]?.some(
      subCourseName => courseName.includes(subCourseName)
    )
  })

  const hasCompletedSubstitute = courseAttempts?.some(attempt => {
    const studentStatus = attempt?.fields?.studentStatus?.fields?.Name
    const courseId = attempt?.fields?.course?.fields?.id
    return studentStatus === ENROLLED ||
      (studentStatus === COMPLETED && courseProjectedGrades?.[courseId] >= 70)
  })

  const hasTransferredCreditSubstitute =
    currentProspect?.transferredCredit?.some(course => {
      const { name: courseName } = course
      return COURSE_SUBSTITUTES?.[activeCourseName]?.some(
      subCourseName => courseName.includes(subCourseName)
    )
    })

  return hasCompletedSubstitute || hasTransferredCreditSubstitute
}

export const updateDegreeDataToCWIV2 = (degreeData = [], semester = {}) => {
  if (!degreeData?.length || !semester) return

  const { semesterStart } = semester || {}
  const isJan24TermOrAfter =
    new Date(semesterStart).getTime() >= new Date(config.CWIV2TermDate).getTime()

  if (!isJan24TermOrAfter) return

  degreeData.forEach(course => {
    const { courseName } = course || {}

    const isCWI = courseName?.toLowerCase() === 'college writing i - ggu'
    if (isCWI) course.courseId = CWIV2GGUID
  })
}
