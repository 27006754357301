import React from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import {
  CourseCTAContainer,
  CourseTag,
  FinalGradeText,
  FinishedCourseTile,
  FinishedCourseTitle,
  MyCoursesSlideArrows
} from './style'
import { getCourseAssets, getLatestCohort } from '../../../utilities/course'
import config from '../../../config'
import { getCourseTag, getFinalGradeText } from '../utils'
import { PrimaryButton } from '../style'

function FinishedCoursesSlide ({
  finishedCourses,
  catalogCourses,
  courseProjectedGrades
}) {
  return (
    <Splide
      aria-label='Finished Courses Section Slide'
      hasTrack={false}
      options={{
        type: 'slide',
        pagination: false,
        gap: '17px',
        drag: false,
        autoWidth: true,
        slideFocus: true,
        focus: 0,
        omitEnd: true
      }}
    >
      <MyCoursesSlideArrows className='splide__arrows'>
        <button className='splide__arrow splide__arrow--prev' />
        <button className='splide__arrow splide__arrow--next' />
      </MyCoursesSlideArrows>
      <SplideTrack>
        {finishedCourses.map((course) => {
          const { id, displayName } = course
          const latestCohort = getLatestCohort(course)
          const { hideStudentGradeDisplay, cohortName } = latestCohort || {}
          const { hasInTimeGradeFlag, courseBaseUrlById } = config

          const { courseImage } = getCourseAssets(catalogCourses, id)
          const courseTag = getCourseTag(course)
          const courseLink = courseBaseUrlById(id)
          const isGGUCohort = cohortName?.toLowerCase().includes('ggu')

          const isGradeDisplayOff =
            hideStudentGradeDisplay && hasInTimeGradeFlag
          const finalGradeText = getFinalGradeText(
            course,
            courseProjectedGrades
          )

          return (
            <SplideSlide key={id}>
              <FinishedCourseTile courseImage={courseImage}>
                {courseTag && (
                  <CourseTag className='course-tag'>{courseTag}</CourseTag>
                )}
                <FinishedCourseTitle className='course-title'>
                  {displayName}
                </FinishedCourseTitle>
                {!isGGUCohort && !isGradeDisplayOff && (
                  <FinalGradeText className='final-grade'>
                    {finalGradeText}
                  </FinalGradeText>
                )}
                <CourseCTAContainer className='course-cta'>
                  <PrimaryButton
                    onClick={() => (window.location.href = courseLink)}
                  >
                    Course Home
                  </PrimaryButton>
                </CourseCTAContainer>
              </FinishedCourseTile>
            </SplideSlide>
          )
        })}
      </SplideTrack>
    </Splide>
  )
}

FinishedCoursesSlide.displayName = 'FinishedCoursesSlide'
export default FinishedCoursesSlide
