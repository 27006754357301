export const GGU_ENROLLMENT_URLS = [
  '/',
  '/expected-graduation',
  '/transfer-credit-confirmation',
  '/recommended-courses'
]

export const WELCOME_PAGE_PATH = '/'
export const EXPECTED_GRADUATION_PATH = '/expected-graduation'
export const TRANSFER_CREDIT_CONFIRMATION_PATH = '/transfer-credit-confirmation'
export const GET_READY_PATH = '/get-ready'
export const COURSE_QUANTITY_PATH = '/course-quantity'
export const CHANGE_COURSE_QUANTITY_PATH = '/change-course-quantity'
export const COURSE_QUANTITY_WARNING_PATH = '/course-quantity-warning'
export const RECOMMENDED_COURSES_PATH = '/recommended-courses'
export const COURSE_INTRO_PATH = '/course-intro/:courseNumber'
export const CATALOG_PATH = '/catalog'
export const CATALOG_COURSE_DETAILS_PATH = '/catalog/:courseId'
export const REVIEW_PATH = '/review'
export const SCHEDULE_MEETING_PATH = '/schedule-advisor-meeting'

export const GGU_V2_ENROLLMENT_PAGES = {
  WELCOME_PAGE: {
    pathName: WELCOME_PAGE_PATH,
    pageNumber: '1-1',
    pageName: 'Welcome'
  },
  EXPECTED_GRADUATION: {
    pathName: EXPECTED_GRADUATION_PATH,
    pageNumber: '1-2',
    pageName: 'Welcome',
    previousPagePathname: WELCOME_PAGE_PATH
  },
  TRANSFER_CREDIT_CONFIRMATION: {
    pathName: TRANSFER_CREDIT_CONFIRMATION_PATH,
    pageNumber: '2-1',
    pageName: 'Transfer Credits',
    previousPagePathname: EXPECTED_GRADUATION_PATH
  },
  GET_READY: {
    pathName: GET_READY_PATH,
    pageNumber: '2-2',
    pageName: 'Get Ready',
    previousPagePathname: TRANSFER_CREDIT_CONFIRMATION_PATH
  },
  COURSE_QUANTITY: {
    pathName: COURSE_QUANTITY_PATH,
    pageNumber: '3-1',
    pageName: 'Confirm Quantity',
    revisitIfLastVisited: true,
    previousPagePathname: GET_READY_PATH
  },
  CHANGE_COURSE_QUANTITY: {
    pathName: CHANGE_COURSE_QUANTITY_PATH,
    pageNumber: '3-2',
    pageName: 'Change Quantity',
    skipVisitedPages: true,
    previousPagePathname: COURSE_QUANTITY_PATH
  },
  COURSE_QUANTITY_WARNING: {
    pathName: COURSE_QUANTITY_WARNING_PATH,
    pageNumber: '3-3',
    pageName: 'Part-time Warning',
    skipVisitedPages: true,
    previousPagePathname: CHANGE_COURSE_QUANTITY_PATH
  },
  RECOMMENDED_COURSES: {
    pathName: RECOMMENDED_COURSES_PATH,
    pageNumber: '4-1',
    pageName: 'All Course Recommendations',
    revisitIfLastVisited: true,
    previousPagePathname: COURSE_QUANTITY_PATH
  },
  COURSE_INTRO: {
    pathName: COURSE_INTRO_PATH,
    pageNumber: (pageNumber) => `4-${pageNumber}`,
    pageName: (courseNumber) => `Course ${courseNumber} Recommendation`,
    skipVisitedPages: true,
    previousPagePathname: RECOMMENDED_COURSES_PATH
  },
  CATALOG: {
    pathName: CATALOG_PATH,
    pageNumber: (pageNumber) => `4-${pageNumber}`,
    pageName: (courseNumber) => `Course Catalog Course ${courseNumber}`,
    skipVisitedPages: true,
    previousPagePathname: COURSE_INTRO_PATH
  },
  CATALOG_COURSE_DETAILS: {
    pathName: CATALOG_COURSE_DETAILS_PATH,
    pageNumber: (pageNumber) => `4-${pageNumber}`,
    pageName: (courseNumber) => `Course ${courseNumber} Page`,
    skipVisitedPages: true,
    previousPagePathname: CATALOG_PATH
  },
  REVIEW: {
    pathName: REVIEW_PATH,
    pageNumber: '5-1',
    pageName: 'Final Review',
    revisitIfLastVisited: true,
    previousPagePathname: RECOMMENDED_COURSES_PATH
  },
  SCHEDULE_CALL: {
    pathName: SCHEDULE_MEETING_PATH,
    revisitIfLastVisited: true,
    previousPagePathname: RECOMMENDED_COURSES_PATH
  }
}
