import React, { useContext, useState, useEffect, useRef } from 'react'
import {
  BackToLink,
  ExitFormPageContainer,
  TransferCohortHeader,
  TransferContent
} from './style'
import { PageTitle } from '../AccountArea/CoursePage/style'
import { Separator } from '../OnboardingForm/style'
import { TRANSFER_COHORT_STATES } from '../../Constants/accountArea'
import { AppContext } from '../ContextProvider/ContextProvider'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { getLatestCohort } from '../../utilities/course'
import utils from '../../utilities'
import { PrimaryButton } from '../Buttons/style'
import {
  convertEasternTimeToLocalTime,
  dateToSecondsSinceEpoch,
  getDateTimeWithShortTimezone
} from '../../utilities/dateTime'
import CohortSelection from './CohortSelection'
import TransferReview from './TransferReview'
import ExitModal from './ExitModal'

const { INTRO, TRANSFER, REVIEW } = TRANSFER_COHORT_STATES

const TransferRequestIntro = ({
  setPageState,
  finalDropDate
}) => {
  const dropDate = convertEasternTimeToLocalTime(finalDropDate)
  const formattedDropDate = dropDate
    ? getDateTimeWithShortTimezone(dateToSecondsSinceEpoch(dropDate))
      .replace(',', ' at')
    : ''

  return (
    <TransferContent>
      <p>
        Let's move you to a cohort that works better for you! Keep in mind, we
        do not carry over any progress you've made. You must complete your
        transfer before your current cohort's drop deadline ends on {formattedDropDate}.
      </p>
      <a
        href='https://help.outlier.org/hc/en-us/articles/360057567511-Changing-Enrollment-to-Another-Course-or-Cohort'
        target='_blank'
        rel='noopener noreferrer'
      >
        Learn more about transferring
      </a>
      <PrimaryButton
        width='140px'
        onClick={() => setPageState(TRANSFER)}
      >
        start
      </PrimaryButton>
    </TransferContent>
  )
}

const TransferRequest = ({ history, location }) => {
  const [newCohortDetails, setNewCohortDetails] = useState({})
  const [showExitModal, setShowExitModal] = useState(false)
  const { state, pathname } = location || {}
  const courseName = state?.courseName || pathname?.split('/')?.[3]
  const {
    reloadContext,
    courses,
    isContextLoading,
    outlierCourses,
    prospects,
    currentSemester,
    isActiveGGUStudent
  } = useContext(AppContext)
  const course = courses?.find(course => course.displayName === courseName) || {}
  const cohort = getLatestCohort(course) || {}
  const [pageState, setPageState] = useState(INTRO)
  const currentStateRef = useRef(pageState)
  const wrapperRef = useRef(null)

  useEffect(() => {
    currentStateRef.current = pageState

    // eslint-disable-next-line
  }, [pageState])

  const isTransferComponent = event => {
    const parent = wrapperRef.current
    const child = event.target

    if (parent && parent.contains(child)) return true

    const transferChildren = [
      'btn-confirm',
      'btn-next',
      'img-close',
      'btn-keep-editing',
      'btn-exit-transfer',
      'button-date-select'
    ]
    const targetId = child.getAttribute('data-testid')
    return transferChildren.includes(targetId)
  }

  const unloadHandler = event => {
    if (currentStateRef.current === INTRO) return

    event.preventDefault()
    event.returnValue = ''
  }

  const clickOutsideHandler = event => {
    if (isTransferComponent(event)) return
    if (!utils.isClickable(event.target)) return

    if (currentStateRef.current === INTRO) return

    event.preventDefault()
    setShowExitModal(true)
  }

  useEffect(() => {
    window.addEventListener('beforeunload', unloadHandler)
    document.addEventListener('click', clickOutsideHandler, false)

    return () => {
      window.removeEventListener('beforeunload', unloadHandler)
      document.removeEventListener('click', clickOutsideHandler, false)
    }

    // eslint-disable-next-line
  }, [])

  if (isContextLoading) return <LoadingSpinner />

  const { studentStatus, finalDropDate } = cohort

  return (
    <ExitFormPageContainer ref={wrapperRef}>
      <BackToLink
        title='Manage Course'
        clickHandler={() => {
          if (currentStateRef.current === INTRO) {
            history.push(`/account/manage-courses/${courseName}`)
            return
          }
          setShowExitModal(true)
        }}
      />
      <div className='content'>
        <TransferCohortHeader>
          <PageTitle>
            Transfer to a new cohort
          </PageTitle>
          <div className='course-details'>
            <p className='course'>{courseName}</p>
            <p>.</p>
            <p className='status'>{studentStatus}</p>
          </div>
          <Separator margin='32px 0' />
        </TransferCohortHeader>

        {pageState === INTRO && (
          <TransferRequestIntro
            finalDropDate={finalDropDate}
            setPageState={setPageState}
          />
        )}
        {pageState === TRANSFER && (
          <CohortSelection
            cohort={cohort}
            prospects={prospects}
            currentSemester={currentSemester}
            isActiveGGUStudent={isActiveGGUStudent}
            setPageState={setPageState}
            outlierCourses={outlierCourses}
            courseId={course?.id}
            newCohortDetails={newCohortDetails}
            setNewCohortDetails={setNewCohortDetails}
          />
        )}
        {pageState === REVIEW && (
          <TransferReview
            newCohort={newCohortDetails.selectedCohort}
            originalCohort={cohort}
            courseId={course?.id}
            reloadContext={reloadContext}
            history={history}
          />
        )}
      </div>
      <ExitModal
        show={showExitModal}
        setShow={setShowExitModal}
      />
    </ExitFormPageContainer>
  )
}

TransferRequest.displayName = 'TransferRequest'

export default TransferRequest
