import React from 'react'
import WidgetState from '../WidgetState'
import { Title } from './style'
import {
  getMultipleAssignmentsText
} from '../../../utilities/writingAssignmentsUtils'

const SubmittedCard = ({
  course,
  assignments
}) => {
  let hasSubmittedUngradedAssignment = false
  const submittedUngradedAssignments = []
  assignments.forEach(assignment => {
    const isSubmittedUngradedAssignment =
      assignment.isSubmitted && !assignment.isGraded
    if (isSubmittedUngradedAssignment) {
      submittedUngradedAssignments.push(assignment)
      hasSubmittedUngradedAssignment = true
    }
  })

  if (submittedUngradedAssignments.length === 0) { return }

  if (submittedUngradedAssignments.length > 1) {
    const multipleAssignmentsText =
      getMultipleAssignmentsText(submittedUngradedAssignments)
    const description =
      <div data-testid='submitted-description'>
        {'We’ll notify you here when the assignments '}
        {multipleAssignmentsText}
        {' have been graded.'}
      </div>
    return (
      <WidgetState
        description={description}
      />
    )
  }
  const { chapters } = course
  const lastChapter = chapters[chapters.length - 1]
  const isLastChapter =
    lastChapter.chapter_uuid === submittedUngradedAssignments[0].chapter_uuid

  if (hasSubmittedUngradedAssignment && !isLastChapter) {
    const description =
      <div data-testid='submitted-description'>
        {'We’ll notify you here when the assignment '}
        <Title>
          {submittedUngradedAssignments[0].title}
        </Title>
        {' has been graded.'}
      </div>
    return (
      <WidgetState
        description={description}
      />
    )
  }
  return null
}

export default SubmittedCard
