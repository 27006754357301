import React from 'react'
import ConfettiCanvasComponent from './ConfettiCanvasComponent'
import { ConfirmationWrap, ConfirmationText, GetStartedButton } from './style'

const Confirmation = props => {
  const { history } = props
  return (
    <ConfirmationWrap data-testid='confirmation'>
      <ConfettiCanvasComponent />
      <ConfirmationText>You’re in!</ConfirmationText>
      <GetStartedButton onClick={() => history.push('/')}>
        Let’s get started
      </GetStartedButton>
    </ConfirmationWrap>
  )
}

export default Confirmation
