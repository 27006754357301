import React from 'react'
import isEmpty from 'lodash/isEmpty'

import {
  chaptersVisitedSections
} from '../../utilities/chapterUtils'

import {
  getVisitedSectionInfo,
  getVisitedALCardInfo,
  getVisitedQuizInfo,
  getMultipleVideos,
  getLectureInProgress,
  getVisitedPracticeExerciseInfo

} from '../../utilities/section'
import QuizCard from './QuizCard'
import PracticeExercisesCard from './PracticeExercisesCard'
import { PROGRESS_TYPE } from '../../Constants/courseCard'
import PracticeTermsCard from './PracticeTermsCard'
import ActiveLearningCard from './ActiveLearningCard'
import LectureCard from './LectureCard'
import ReadingCard from './ReadingCard'
import GuessworkCard from './GuessworkCard'

const ActivityInProgressAndCompleted = ({
  course,
  courseProgress
}) => {
  const { chapters } = course
  const { studentProgress, sectionData } = courseProgress
  const { studentAnswers } = studentProgress

  const getVisitedQuizData = (quiz) => {
    const { visitedQuizzes } =
      getVisitedQuizInfo(Object.keys(studentAnswers), quiz)
    for (let i = visitedQuizzes.length - 1; i >= 0; i--) {
      const { questionId } = visitedQuizzes[i]
      if (!questionId) continue
      return {
        quizNo: i + 1,
        visitedQuiz: visitedQuizzes[i]
      }
    }
    return null
  }

  const readingVisited = () => {
    const { section_exe: sectionExe } = sectionData
    const { readings } = sectionExe
    if (!readings) return false
    const { READING_PROGRESS } = PROGRESS_TYPE

    const readingProgress = studentProgress[READING_PROGRESS]
      ? Object.keys(studentProgress[READING_PROGRESS]) : []
    const setUuids = readings.map(
      ({ question_set_uuid: questionSetUuid }) => questionSetUuid
    )

    return setUuids.some(setUuid => readingProgress.includes(setUuid))
  }

  const chapterVisitedSections =
      chaptersVisitedSections(chapters, studentProgress)

  return chapterVisitedSections?.map((chapterVisitedSection, key) => {
    const {
      visitedSections,
      chapterUuid,
      chapterNo
    } = chapterVisitedSection
    if (!visitedSections) return null

    return visitedSections?.map((visitedSection, index) => {
      const {
        sectionUuid,
        isGuessworkVisited,
        isActiveLearningVisited,
        isQuizVisited,
        sectionNo
      } = visitedSection

      const { section_exe: sectionExe } = sectionData
      if (sectionData.section_uuid !== sectionUuid) return null

      const {
        guesswork,
        readings,
        lecture,
        multi_lecture_videos: multipleVideos,
        active_learning: activeLearning,
        practice_terms: practiceTerms,
        practice_exercises: practiceExercises,
        quiz,
        conceptMap
      } = sectionExe

      const sectionVideos = getMultipleVideos(multipleVideos)

      const lectureInProgress = getLectureInProgress({
        sectionUuid,
        videos: sectionVideos,
        lecture,
        progress: studentProgress
      })

      const cardData = {
        course,
        courseProgress,
        chapterNo,
        sectionNo
      }

      // if last activity is quiz
      if (isQuizVisited) {
        const visitedQuizData = getVisitedQuizData(quiz)
        if (visitedQuizData) {
          return (
            <div data-testid='quiz-card' key={`${key}.${index}`}>
              <QuizCard
                {...cardData}
                visitedQuizData={visitedQuizData}
                chapterUuid={chapterUuid}
              />
            </div>
          )
        }
      }

      // if last activity is practice exercises
      if (!isEmpty(practiceExercises)) {
        const isFirstSection = chapterNo === 1 && sectionNo === 1
        const visitedPracticeExercises =
            getVisitedPracticeExerciseInfo(
              studentAnswers,
              practiceExercises,
              isFirstSection
            )
        if (visitedPracticeExercises) {
          return (
            <div data-testid='practice-exercises-card' key={`${key}.${index}`}>
              <PracticeExercisesCard
                {...cardData}
                chapterUuid={chapterUuid}
              />
            </div>
          )
        }
      }

      // if last activity is practice terms
      if (!isEmpty(practiceTerms)) {
        const { PRACTICE_TERM_COMPLETE, CONCEPT_MAP_COMPLETE } = PROGRESS_TYPE
        const practiceTermComplete = studentProgress[PRACTICE_TERM_COMPLETE]
          ? Object.keys(studentProgress[PRACTICE_TERM_COMPLETE]) : []
        const isPracticeTermComplete =
          practiceTermComplete.includes(sectionUuid)
        const conceptMapsComplete = studentProgress[CONCEPT_MAP_COMPLETE]
          ? Object.keys(studentProgress[CONCEPT_MAP_COMPLETE]) : []
        const isConceptMapComplete =
          conceptMapsComplete.includes(sectionUuid)

        if (isPracticeTermComplete || isConceptMapComplete) {
          return (
            <div data-testid='practice-terms-card' key={`${key}.${index}`}>
              <PracticeTermsCard
                {...cardData}
                chapterUuid={chapterUuid}
                conceptMap={conceptMap}
              />
            </div>
          )
        }
      }

      // if last activity is active learning
      if (isActiveLearningVisited) {
        const { questionId } =
          getVisitedALCardInfo(studentAnswers, activeLearning)
        if (questionId) {
          return (
            <div data-testid='active-learning-card' key={`${key}.${index}`}>
              <ActiveLearningCard {...cardData} />
            </div>
          )
        }
      }

      // if last activity is lecture
      if (lectureInProgress) {
        return (
          <div data-testid='lecture-card' key={`${key}.${index}`}>
            <LectureCard
              {...cardData}
              lectureInProgress={lectureInProgress}
            />
          </div>
        )
      }

      // if last activity is readings
      if (readings) {
        const isReadingVisited = readingVisited()
        if (isReadingVisited) {
          return (
            <div data-testid='readings-card' key={`${key}.${index}`}>
              <ReadingCard
                {...cardData}
              />
            </div>
          )
        }
      }

      // if last activity is guesswork
      if (isGuessworkVisited) {
        const { questionId } =
            getVisitedSectionInfo(Object.keys(studentAnswers), guesswork)
        if (questionId) {
          return (
            <div data-testid='guesswork-card' key={`${key}.${index}`}>
              <GuessworkCard
                {...cardData}
              />
            </div>
          )
        }
      }
      return null
    }).reverse()
  })
}

export default ActivityInProgressAndCompleted
