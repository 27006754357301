import styled, { createGlobalStyle } from 'styled-components'
import { Modal } from 'react-bootstrap'
import { PrimaryButton } from '../../Buttons/style/index'
import checkMark from '../../../assets/icons/check-mark.svg'

export const GlobalStyle = createGlobalStyle`
  .modal-dialog {
    max-width: none;
  }
  
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-body {
    padding: 0;
  }

  .modal-content {
    background-color: #161618;
    width: 640px;
    display: flex;
    align-items: flex-start;
    margin: auto;
    padding: 48px;

    @media (max-width: 767px) {
      width: 525px;
      padding: 24px;
    }
  
    @media (max-width: 575px) {
      width: 324px;
    }
  
    @media (max-width: 320px) {
      width: 250px;
    }
  }
`

export const TitleWrapper = styled(Modal.Title)`
  font-weight: 500;
  font-size: 26px;
  line-height: 29px;
  width: 100%;
  text-transform: capitalize;
`

export const CheckBox = styled.label`
  margin-top: 48px;
  display: block;
  position: relative;
  padding-left: 36px;
  margin-bottom: 36px;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  span {
    position: absolute;
    top: 5px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #161618;
    border: ${props => `2px solid ${props.disabled ? '#5E636B' : '#5FC4B8'}`};
    box-sizing: border-box;
    border-radius: 5px;
  }

  input:checked ~ span {
    background-color: #5FC4B8;
    border: 3px solid #5FC4B8;
    box-sizing: border-box;
    border-radius: 5px;
  }

  span::after {
    display: none;
    position: absolute;
    content: url(${checkMark});
  }

  input:checked ~ span::after {
    left: 2px;
    display: block;
  }

  a {
    color: #78bcb8;
  }

  p {
    margin-top : 10px;
  }
`

export const Button = styled(PrimaryButton)`
  min-width: 120px;
  width: auto;
`
