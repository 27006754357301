import React, { useContext, useState } from 'react'
import { PrimaryButton } from '../Buttons/style/index'
import {
  Container,
  Description,
  Separator,
  FieldContainer,
  Label,
  Input,
  Info,
  TooltipIcon,
  CheckBox
} from './style'
import { Header } from '../VerifyEmail/style'
import DateOfBirth from './DateOfBirth'
import api from '../../api'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { getStudentAge } from '../../utilities/user'
import { AppContext } from '../ContextProvider/ContextProvider'

const OnboardingForm = () => {
  const { studentData } = useContext(AppContext)
  const {
    firstName: contextFirstName,
    lastName: contextLastName,
    preferredName: contextPreferredName,
    dateOfBirth: contextDateOfBirth,
    enrollingStudentCertification
  } = studentData

  const [firstName, setFirstName] = useState(contextFirstName)
  const [lastName, setLastName] = useState(contextLastName)
  const [preferredName, setPreferredName] = useState(contextPreferredName)
  const [dateOfBirth, setDateOfBirth] = useState(contextDateOfBirth)
  const [dateError, setDateError] = useState('')
  const [serverError, setServerError] = useState('')
  const [isEnrolledStudent, setIsEnrolledStudent] =
    useState(!!enrollingStudentCertification)
  const [isLoading, setIsLoading] = useState(false)

  const saveStudentDetails = async () => {
    setServerError('')
    setIsLoading(true)
    const studentAge = getStudentAge(dateOfBirth)
    const result = await api.updateStudent({
      firstName,
      lastName,
      preferredName: preferredName || firstName,
      dateOfBirth,
      under13: studentAge < 13,
      enrollingStudentCertification: isEnrolledStudent ? 'Yes' : 'No'
    }, true)

    if (!result || result.error) {
      setIsLoading(false)
      setServerError(result?.error || 'Error when saving student details')
      return
    }

    window.location.reload()
  }

  if (isLoading) return <LoadingSpinner />

  return (
    <Container>
      <div className='content'>
        <Header style={{ marginTop: '64px', marginBottom: '0px' }}>
          Welcome to Outlier
        </Header>
        <Separator />
        <Description marginBottom={serverError ? '28px' : '36px'}>
          Let’s start by collecting some of your information. Completing this form
          incorrectly may impact your academic record and make it more difficult for
          you to receive transcripts and credit for your courses.
        </Description>
        {serverError && (
          <Label data-testid='serverError' className='error'>
            {serverError}
          </Label>
        )}
        <FieldContainer>
          <Label>
            Legal first name (required)
            <Info>
              <TooltipIcon />
              <span className='tooltiptext'>
                This is the first or given name that’s on your birth certificate
                and student transcript. We need this for your enrollment.
              </span>
            </Info>
          </Label>
          <Input
            className='fs-exclude'
            data-testid='firstname'
            value={firstName || ''}
            onChange={event => {
              setFirstName(event.target.value)
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <Label>
            Legal last name (required)
            <Info>
              <TooltipIcon />
              <span className='tooltiptext'>
                This is the last or family name that’s on your birth certificate and
                student transcript. We need this for your enrollment.
              </span>
            </Info>
          </Label>
          <Input
            className='fs-exclude'
            data-testid='lastname'
            value={lastName || ''}
            onChange={event => {
              setLastName(event.target.value)
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <Label>
            Preferred first name
            <Info>
              <TooltipIcon />
              <span className='tooltiptext'>
                This is the name we’ll call you in our notifications, emails, and texts.
              </span>
            </Info>
          </Label>
          <Input
            className='fs-exclude'
            value={preferredName || ''}
            onChange={event => {
              setPreferredName(event.target.value)
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <Label marginBottom={dateError ? '0' : '6px'}>
            Date of birth (required)
          </Label>
          {dateError && (
            <Label className='error'>
              {dateError}
            </Label>
          )}
          <DateOfBirth
            dateOfBirth={dateOfBirth}
            setDateError={setDateError}
            setDateOfBirth={setDateOfBirth}
          />
        </FieldContainer>
        <FieldContainer>
          <CheckBox
            onChange={event => {
              setIsEnrolledStudent(event.target.checked)
            }}
          >
            I certify that I am the enrolling student whose name will appear on official
            transcripts issued for any credits earned.
            <input type='checkbox' defaultChecked={isEnrolledStudent} data-testid='isEnrolledStudent' />
            <span />
          </CheckBox>
        </FieldContainer>
        <PrimaryButton
          data-testid='btn-submit'
          disabled={!firstName || !lastName || !dateOfBirth || !isEnrolledStudent}
          style={{ width: '150px' }}
          onClick={saveStudentDetails}
        >
          Submit
        </PrimaryButton>
      </div>
    </Container>
  )
}

OnboardingForm.displayName = 'OnboardingForm'
export default OnboardingForm
