import React from 'react'
// import PropTypes from 'prop-types'

const TextBlanks = () => {
  return (
    <>Text Blanks</>
  )
}

TextBlanks.displayName = 'TextBlanks'

TextBlanks.propTypes = {}

export default TextBlanks
