import React from 'react'
import {
  GetStartedButton,
  NoStudyBlocksContainer,
  NoStudyBlocksMessage,
  SkeletonCircle,
  SkeletonLine,
  SkeletonTile
} from './styles'

function NoStudyBlocks () {
  return (
    <NoStudyBlocksContainer>
      {[...Array(8)].map((_, index) => (
        <SkeletonTile key={index}>
          <SkeletonLine skeletonWidth='57px' />
          <ul>
            <li>
              <SkeletonCircle />
              <SkeletonLine skeletonWidth='149px' />
            </li>
            <li>
              <SkeletonCircle />
              <SkeletonLine skeletonWidth='149px' />
            </li>
            <li>
              <SkeletonCircle />
              <SkeletonLine skeletonWidth='149px' />
            </li>
          </ul>
        </SkeletonTile>
      ))}
      <NoStudyBlocksMessage>
        <p>Let us help you stay on track with Studyblocks!</p>
        <GetStartedButton as='a' href='#/account/study-blocks' width='auto'>
          Get Started
        </GetStartedButton>
      </NoStudyBlocksMessage>
    </NoStudyBlocksContainer>
  )
}

NoStudyBlocks.displayName = 'NoStudyBlocks'
export default NoStudyBlocks
