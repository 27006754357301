import React, { useState, useEffect } from 'react'
import {
  dateToSecondsSinceEpoch,
  secondsSinceEpoch
} from '../../utilities/dateTime'
import { BannerWrapper, CloseButton, Bold, GlobalStyle } from './style'

const UpdatedTermsNotificationBanner = () => {
  const [showBanner, setShowBanner] = useState(true)

  useEffect(() => {
    if (!showBanner) return

    const hasSeenTermsNotification = localStorage.getItem(
      'hasSeenTermsNotification'
    )
    if (!hasSeenTermsNotification) return

    setShowBanner(false)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!showBanner) return
    // the last updated date for terms of service
    // can be updated here
    const lastTermsOfServiceUpdateDateInSecs = dateToSecondsSinceEpoch(
      // Tue Mar-02-2021
      new Date('03-02-2021')
    )
    const todayInSecs = secondsSinceEpoch()
    // one day is 86,400 seconds, 14 days make 2 weeks
    const twoWeeksInSecs = 86400 * 14
    const hasPassedTwoWeeksFromUpdatedDate =
      todayInSecs - lastTermsOfServiceUpdateDateInSecs > twoWeeksInSecs
    if (!hasPassedTwoWeeksFromUpdatedDate) return

    setShowBanner(false)
    // eslint-disable-next-line
  }, [])

  const handleCloseBtnClick = () => {
    localStorage.setItem('hasSeenTermsNotification', true)
    setShowBanner(false)
  }

  if (!showBanner) return null

  return (
    <BannerWrapper>
      <GlobalStyle />
      <Bold>Heads-up: We’ve updated Outlier’s Terms and Policies</Bold>
      <div>
        You can check out the updated terms{' '}
        <a
          href='https://dashboard.outlier.org/#/terms-of-use'
          target='_blank'
          rel='noopener noreferrer'
        >
          here
        </a>
        , but don’t worry, we won’t test you on them! Your continued use of
        Outlier constitutes acceptance of the updated terms.
      </div>
      <CloseButton onClick={handleCloseBtnClick} />
    </BannerWrapper>
  )
}

export default UpdatedTermsNotificationBanner
