import React, { useEffect, useState } from 'react'
import {
  WhatsNextHeading, WidgetWrapper
} from './style'
import { getCourseTermState } from '../../utilities/course'
import WhatsNextWidget from './WhatsNextWidget'
import WidgetState from './WidgetState'
import config from '../../config'

const WhatsNextUpdate = ({ courses }) => {
  const [filteredCourses, setCourses] = useState([])

  const handleEndedCourses = (courseId) => {
    const endedCourses = localStorage.getItem('endedCourses')
      ? JSON.parse(localStorage.getItem('endedCourses')) : []

    if (!endedCourses.includes(courseId)) endedCourses.push(courseId)
    localStorage.setItem('endedCourses', JSON.stringify(endedCourses))

    const { courseBaseUrlById } = config
    const courseBaseUrl = courseBaseUrlById(courseId)
    window.location.href = courseBaseUrl
  }

  useEffect(() => {
    const endedCourses = localStorage.getItem('endedCourses')
      ? JSON.parse(localStorage.getItem('endedCourses')) : []

    const filteredCourses = courses.filter(course => {
      const currentTime = Date.now()
      const courseTermState = getCourseTermState(course, currentTime)
      const courseDismissed = endedCourses.includes(course.id)
      const courseDismissedAndInProgress =
        (courseDismissed && courseTermState !== 'termCompleted')

      return course.cohort &&
        (!courseDismissed || courseDismissedAndInProgress)
    })
    setCourses(filteredCourses)
  }, [
    courses
  ])

  const outOfCourses = !filteredCourses.length
  const currentTime = Date.now()

  return (
    <>
      <WhatsNextHeading>What's Next</WhatsNextHeading>
      {filteredCourses.map((course, key) => {
        const courseTermState = getCourseTermState(course, currentTime)
        return (
          <WhatsNextWidget
            key={key}
            course={course}
            courseTermState={courseTermState}
            handleEndedCourses={handleEndedCourses}
          />
        )
      })}
      {outOfCourses &&
        <WidgetWrapper data-testid='out-of-courses'>
          <WidgetState
            description='Browse our Catalog to get started with a new class.'
            primaryBtnTxt='Browse'
            primaryBtnFunction={() => {
              window.location.href = '#/catalog'
            }}
          />
        </WidgetWrapper>}
    </>
  )
}

export default WhatsNextUpdate
