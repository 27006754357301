import React, { forwardRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import {
  NavDropdown,
  DropdownMenu,
  IconContainer,
  IconImage,
  CloseDropDownIcon
} from './style'

const CustomCloseMenu = forwardRef(({ onClick }, ref) => (
  <CloseDropDownIcon
    src='images/icons/icon-close-dropdown-menu.svg'
    alt='Close menu icon'
    ref={ref}
    data-testid='dropdown-close'
    onClick={onClick}
  />
))

const CustomMenu = forwardRef(({
  children,
  paddingBottom,
  dropDownOffset,
  className,
  'aria-labelledby': labeledBy
}, ref) => {
  return (
    <DropdownMenu
      data-testid='menu'
      dropDownOffset={dropDownOffset}
      className={className}
      ref={ref}
      paddingBottom={paddingBottom}
      aria-labelledby={labeledBy}
    >
      {children}
    </DropdownMenu>
  )
})

const CustomToggle = forwardRef(({
  onClick,
  imgSrc,
  iconRightMargin,
  iconLeftMargin
}, ref) => (
  <IconContainer
    iconRightMargin={iconRightMargin}
    iconLeftMargin={iconLeftMargin}
    ref={ref}
    data-testid='dropdown-click'
    onClick={onClick}
  >
    <IconImage src={imgSrc} alt='icon image' />
  </IconContainer>
))

const DropdownMenuComponent = ({
  children,
  imgSrc,
  showDropDownIcon,
  iconRightMargin,
  iconLeftMargin,
  paddingBottom,
  dropDownOffset,
  maxHeight
}) => {
  return (
    <NavDropdown maxHeight={maxHeight}>
      <Dropdown>
        {showDropDownIcon && (
          <Dropdown.Toggle
            iconRightMargin={iconRightMargin}
            iconLeftMargin={iconLeftMargin}
            imgSrc={imgSrc}
            as={CustomToggle}
          />
        )}
        <Dropdown.Menu
          dropDownOffset={dropDownOffset}
          paddingBottom={paddingBottom}
          as={CustomMenu}
        >
          <Dropdown.Toggle as={CustomCloseMenu} />
          {children}
        </Dropdown.Menu>
      </Dropdown>
    </NavDropdown>
  )
}

export default DropdownMenuComponent
