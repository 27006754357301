import React from 'react'
import { getCourseTermState } from '../../../utilities/course'
import WhatsNextWidget from './WhatsNextWidget'
import { termState } from '../../../Constants/cohort'
import PreCohort from './PreCohort'
import InProgress from './InProgress/InProgress'

function WhatsNext ({ course, desktopHero }) {
  const currentTime = Date.now()
  const { IS_UPCOMING, IN_PROGRESS } = termState

  const courseTermState = getCourseTermState(course, currentTime)

  if (courseTermState === IS_UPCOMING) {
    return <PreCohort course={course} desktopHero={desktopHero} />
  }

  if (courseTermState === IN_PROGRESS) {
    return <InProgress course={course} desktopHero={desktopHero} />
  }

  return <WhatsNextWidget course={course} desktopHero={desktopHero} />
}

WhatsNext.displayName = 'WhatsNext'
export default WhatsNext
