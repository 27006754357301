import React, { memo, useCallback, useMemo, useState } from 'react'
import MathJax from './lib/MathJax'
import { imageClassMap, progressButtonStates } from '../../../Constants/pretest'
import { StyledOption, OptionIcon, StyledOptionWrapper, StyledLabel, StyledCorrectAnswerText } from './style'

const {
  EYE_OPEN,
  EYE_CLOSED,
  CORRECT_OPTION,
  INCORRECT_OPTION,
  UNATTEMPTED_OPTION
} = progressButtonStates

const Option = ({
  isOptionSelected,
  studentAnswer,
  isFeedbackCorrect,
  index,
  onChange,
  option,
  hasSingleAnswer
}) => {
  const [isEyeOpened, setIsEyeOpened] = useState(false)

  const handleToggleEyeOpenChange = useCallback(() => {
    // Do not toggle eye icon if answer is submitted
    if (studentAnswer) return

    setIsEyeOpened(!isEyeOpened)
    // If option is selected and eye icon is clicked, we should reset the selection
    isOptionSelected && onChange(index)
  }, [index, isEyeOpened, isOptionSelected, onChange, studentAnswer])

  const optionFeedback = useMemo(() => {
    if (!isOptionSelected || !studentAnswer) return null

    return isFeedbackCorrect ? 'correct' : 'incorrect'
  }, [studentAnswer, isOptionSelected, isFeedbackCorrect])

  const optionIcon = useMemo(() => {
    if (!studentAnswer) {
      if (isEyeOpened) return EYE_OPEN
      return EYE_CLOSED
    }

    if (!optionFeedback) return UNATTEMPTED_OPTION

    return optionFeedback === 'correct' ? CORRECT_OPTION : INCORRECT_OPTION
  }, [isEyeOpened, studentAnswer, optionFeedback])

  const showCorrectAnswerText = hasSingleAnswer && optionFeedback === 'correct'

  return (
    <StyledOptionWrapper>
      <StyledOption
        data-testid='mcq-option'
        checked={isOptionSelected}
        answered={!!studentAnswer}
        correct={isFeedbackCorrect}
        isEyeOpened={isEyeOpened}
        isIncorrectAnswer={optionFeedback === 'incorrect'}
      >
        <input
          data-testid={`mcq-input-${index}`}
          type='checkbox'
          value={index}
          disabled={!!studentAnswer || isEyeOpened}
          checked={isOptionSelected}
          onChange={() => onChange(index)}
        />
        <StyledLabel>
          {showCorrectAnswerText && (
            <StyledCorrectAnswerText>Correct Answer</StyledCorrectAnswerText>
          )}
          <MathJax key={`mcq-label-${index}`} math={option} />
        </StyledLabel>
      </StyledOption>
      <OptionIcon
        data-testid={`option-feedback-${optionIcon}`}
        src={imageClassMap[optionIcon]}
        onClick={handleToggleEyeOpenChange}
        answered={!!studentAnswer}
      />
    </StyledOptionWrapper>
  )
}

Option.displayName = 'PretestQuestionComponentOption'

export default memo(Option)
