import React, { useContext, useEffect, useState, useRef, useMemo } from 'react'
import ToolTip from '../CatalogDetailPage/Tooltip'
import {
  ButtonsWrap,
  TypeBox,
  TypeTextBox,
  TypeText,
  Icon,
  CheckBox,
  EnrollText,
  DateWrap,
  FlexContainer,
  OverrideToolTipProperties,
  ConfirmButton
} from './style'
import InfoIcon from '../../assets/icons/info.svg'
import {
  CalendarDatePicker,
  StyledDatePicker
} from '../CatalogDetailPage/style'
import {
  getDateStringWithYearLongMonth,
  changePTDateToLocalTime
} from '../../utilities/dateTime'
import { cohortTypes, durationTypes } from '../../Constants/cohort'
import DatePickerContainerWrapper from '../CatalogDetailPage/DateComponent'
import {
  getCohortsStartDates,
  getAvailableCohorts,
  filterCohorts
} from '../../utilities/cohort'
import {
  TYPE_TEXT,
  DURATION_TEXT
} from '../CatalogDetailPage/utilities/tooltipTexts'
import { AppContext } from '../ContextProvider/ContextProvider'
import { courseState } from '../../Constants/courseCard'
import config from '../../config'

const EditCard = props => {
  const {
    data,
    type,
    week,
    setIsEdit,
    onUpdate,
    isToken,
    courseStates,
    setCourseState,
    startDate,
    setStartDate,
    removeEditId
  } = props
  const [date, setDate] = useState()
  const [datePreviousValue, setDatePreviousValue] = useState(startDate)
  const [selectDateBtnClicked, setSelectDateBtnClicked] = useState(false)
  const [cohortStartDates, setCohortStartDates] = useState([])
  const [selectedCohort, setSelectedCohort] = useState()
  const [availableCohorts, setAvailableCohorts] = useState()
  const [isRadioDisabled, setIsRadioDisabled] = useState({})
  const filteredCohorts = useRef()
  const firstTime = useRef(true)

  const {
    isContextLoading,
    catalogCourses,
    outlierCourses,
    prospects,
    currentSemester,
    isActiveGGUStudent
  } = useContext(
    AppContext
  )
  const {
    uuid: { uuid },
    courseName
  } = getFeaturedCourse(data.name)

  const { ENROLL, AUDIT, SHORT, LONG } = cohortTypes
  const { INTENSIVE, STANDARD } = durationTypes

  const { dateStart, duration, id, finalExamEndTime, course: courseId } = selectedCohort || {}

  function getFeaturedCourse (name) {
    if (!catalogCourses?.length) return null
    const selectedCourse = catalogCourses.find(course => {
      const {
        uuid: { courseName }
      } = course
      return name.includes(courseName)
    })
    return selectedCourse
  }

  const urlCourseName = decodeURIComponent(data.name)

  const userCart = useMemo(() => {
    return JSON.parse(localStorage.getItem('userCart')) || []
    // eslint-disable-next-line
  }, [])

  const additionalIds = config.getAdditionalCourseIdsById(uuid)
  const {
    duration: DURATION,
    dateStart: DATESTART,
    hasPretestRequirement,
    status
  } = userCart.find(course => additionalIds?.includes(course.id))

  useEffect(() => {
    const courseSelected = getFeaturedCourse(urlCourseName)
    setAvailableCohorts(getAvailableCohorts({
      courses: outlierCourses,
      courseSelected,
      prospects,
      currentSemester,
      isActiveGGUStudent
    }))
    // eslint-disable-next-line
  }, [isContextLoading])

  useEffect(() => {
    if (!availableCohorts?.length) return
    setIsRadioDisabled({
      enroll: hasPretestRequirement
    })
    const auditChecked = type === AUDIT
    const intensiveChecked = week === INTENSIVE
    const criteria = [auditChecked, intensiveChecked]
    const cohorts = filterCohorts(availableCohorts, criteria)

    const cohortsStartDate = getCohortsStartDates(cohorts)
    filteredCohorts.current = cohorts
    setCohortStartDates(cohortsStartDate)

    if (firstTime.current) {
      firstTime.current = false
      setCourseState(DURATION === LONG ? STANDARD : INTENSIVE, 'duration')
      setCourseState(status === ENROLL ? ENROLL : AUDIT, 'type')
      return setDate(changePTDateToLocalTime(DATESTART))
    }

    setDate(cohortsStartDate && cohortsStartDate[0])
    // eslint-disable-next-line
  }, [type, week, availableCohorts])

  useEffect(() => {
    const cohort = filteredCohorts.current?.find((cohort) => {
      return changePTDateToLocalTime(cohort.dateStart)
        ?.toLocaleDateString() === date?.toLocaleDateString()
    })

    setSelectedCohort(cohort)
  }, [date])

  function closeDatePickerModal () {
    const portal = document.querySelector('.react-datepicker__portal')
    if (!portal) return

    portal.classList.add('hide-portal')
    portal.classList.remove('display-portal')
    setDate(datePreviousValue) // resets calendar date to previously selected value
  }

  function selectDateClickHandler () {
    const portal = document.querySelector('.react-datepicker__portal')
    if (!portal) return

    portal.classList.add('hide-portal')
    portal.classList.remove('display-portal')
    setSelectDateBtnClicked(true)
  }

  function calendarClickHandler () {
    const portal = document.querySelector('.react-datepicker__portal')
    if (!portal) return

    portal.classList.add('display-portal')
    portal.classList.remove('hide-portal')
  }

  function updateUserCart () {
    if (!selectedCohort) return
    const cohortObj = {
      dateStart,
      finalExamEndTime,
      name: courseName,
      duration: parseInt(duration) >= 14 ? LONG : SHORT,
      id: courseId || uuid,
      cohortId: id,
      status: courseStates.type === AUDIT ? AUDIT : ENROLL
    }

    const itemIndex = userCart?.findIndex((course) =>
      additionalIds?.includes(course.id)
    )
    const newUserCart = [...userCart]
    if (itemIndex > -1) {
      newUserCart.splice(itemIndex, 1, cohortObj)
    }

    localStorage.setItem('userCart', JSON.stringify(newUserCart))
    setStartDate(changePTDateToLocalTime(dateStart))
  }

  function shouldBtnDisable () {
    const checkWeeks = week === INTENSIVE ? SHORT : LONG
    return checkWeeks === DURATION.toString() &&
    status === courseStates.type &&
    changePTDateToLocalTime(DATESTART)
      ?.toLocaleDateString() === date?.toLocaleDateString()
  }

  const formattedCohortEndTIme = finalExamEndTime
    ? `Ends ${getDateStringWithYearLongMonth(finalExamEndTime, 'short')}`
    : ' '

  return (
    <>
      <FlexContainer isToken={isToken}>
        <OverrideToolTipProperties />
        {courseState.map(({ name, children, title }) => (
          <TypeBox key={name} isToken={isToken}>
            <TypeTextBox>
              <TypeText>{title}</TypeText>
              <ToolTip
                data-testid='type-tooltip'
                placement='right'
                tooltipText={name === 'type' ? TYPE_TEXT : DURATION_TEXT}
                wideTooltip={name !== 'type'}
                width={name !== 'type' ? '240' : undefined}
              >
                <Icon src={InfoIcon} />
              </ToolTip>
            </TypeTextBox>
            {Object.keys(children).map(courseType => (
              <TypeTextBox key={courseType}>
                <CheckBox>
                  <input
                    type='checkbox'
                    data-testid='date-input'
                    checked={courseStates[name] === courseType}
                    disabled={isRadioDisabled[courseType]}
                    onChange={() => setCourseState(courseType, name)}
                  />
                  <span />
                </CheckBox>
                <EnrollText>{children[courseType]}</EnrollText>
              </TypeTextBox>
            ))}
          </TypeBox>
        ))}
        <TypeBox isToken={isToken}>
          <TypeTextBox>
            <TypeText>Start date</TypeText>
          </TypeTextBox>
          <DateWrap className='date-wrap'>
            <CalendarDatePicker
              data-testid='calendar'
              disabled={!date}
              onClick={() => {
                setDatePreviousValue(date) // registers current calendar date value when user clicks on calendar component
                calendarClickHandler()
              }}
            >
              <StyledDatePicker
                name='date'
                selected={date}
                onChange={date => setDate(date)}
                dateFormat='MMM dd, yyyy'
                calendarContainer={DatePickerContainerWrapper(
                  selectDateClickHandler,
                  closeDatePickerModal
                )}
                dateFormatCalendar='MMM yyyy'
                disabled={!date}
                withPortal
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
                includeDates={cohortStartDates}
                onCalendarClose={() => {
                  if (!selectDateBtnClicked) {
                    setDate(datePreviousValue) // resets calendar date to previously selected value
                  }
                  setSelectDateBtnClicked(false)
                }}
                placeholderText='No cohorts found'
              />
              <span />
            </CalendarDatePicker>
            <p>
              {formattedCohortEndTIme}
            </p>
          </DateWrap>
        </TypeBox>
      </FlexContainer>
      <ButtonsWrap style={{ marginBottom: 24 }}>
        <ConfirmButton
          onClick={() => {
            setIsEdit(false)
            onUpdate()
            updateUserCart()
            if (removeEditId) removeEditId()
          }}
          className='update'
          disabled={!date || shouldBtnDisable()}
        >
          Update
        </ConfirmButton>
      </ButtonsWrap>
    </>
  )
}

export default EditCard
