import React, { useContext, useEffect, useMemo } from 'react'
import qs from 'qs'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import {
  COURSE_REGISTRATION_PAGEVIEW,
  COURSE_REGISTRATION_PAGE_SUBMITTED,
  NEW_COURSE_SELECTED
} from '../../Constants/eventType'
import { useLocation, useHistory } from 'react-router-dom'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  getGGUCourseStatus,
  getRecommendedCoursesFromLS,
  replaceRecommendedCoursesInLS,
  hasCompltedCourseFromGroup,
  getRecommendationGroupCourses,
  canTakeEconomicsCourses,
  getGGUSemesterEnrollmentStatus,
  isSegmentEventInLS,
  setFiredSegmentEventsInLS,
  removeSegmentEventFromLS
} from '../../utilities/gguDegreeUtils'
import { formatDateWithDay } from '../../utilities/dateTime'
import {
  COMPLETED_NOT_REPEATABLE,
  ENROLLED,
  HAS_PRE_REQUISITE,
  IN_PRODUCTION,
  SELECTED,
  NOT_ENROLLABLE
} from '../../Constants/CourseStatus'
import {
  MessageWrapper,
  Message,
  SubmitButtonWrapper,
  Link,
  PostEnrollmentMessage,
  MailToLink,
  PreEnrollmentMessage,
  ProfCertMessage,
  GGUMessageWrapper
} from './style'
import { getSyllabusLink } from '../../utilities/course'

const GGUStudentStatusMessage = ({
  courseData,
  courseId,
  prerequisite,
  courseName,
  isRegistrationRedesign,
  setShowConfirmChangeModal,
  currentCourseNumber,
  courseQuantity,
  hasTrailerVideo,
  currentSemester = {},
  isProfCert
}) => {
  const { sendEvent } = useSegmentEvents()
  const {
    outlierCourses,
    studentAttempts,
    prospects,
    degreeData,
    updateContext,
    courseProjectedGrades,
    isGGUStudentEnrolled
  } = useContext(AppContext)

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: courseName
  }

  const { advisorApproved, degreeStatus, goodFinancialStanding } = prospects?.[0] || {}
  const { search = {} } = useLocation()
  const history = useHistory()
  const { replace } = qs.parse(search, { ignoreQueryPrefix: true })
  const { syllabus: sylabuses } = courseData || {}
  const { termName, openEnrollment } = currentSemester

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    const isRecommendedCourse = !replace || replace === 'undefined'
    let properties = {
      page_name: 'Course Overview',
      course: courseName,
      time_stamp: Date.now()
    }

    if (isRegistrationRedesign) {
      removeSegmentEventFromLS({
        eventName: COURSE_REGISTRATION_PAGEVIEW,
        pageNumber: `4-${(currentCourseNumber * 2) + courseQuantity}`
      })
      properties = {
        page_name: `Course ${currentCourseNumber} Page`,
        ...(isRecommendedCourse
          ? {}
          : { page_number: `4-${(currentCourseNumber * 2) + courseQuantity + 1}` }
        ),
        course: courseName
      }
    }

    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [replace])

  const {
    isWithinEnrollmentPeriod,
    isBeforeEnrollmentPeriod,
    isPostEnrollmentPeriod
  } = getGGUSemesterEnrollmentStatus(currentSemester)

  const isGGUEnrollmentActive = degreeStatus === 'Active' &&
    goodFinancialStanding === 'Yes' &&
    isWithinEnrollmentPeriod

  const standardSyllabusLink = useMemo(() => {
    return getSyllabusLink(sylabuses)
  }, [sylabuses])

  const hasGroupCourseCompleted = hasCompltedCourseFromGroup({
    courseId,
    prospects,
    studentAttempts,
    degreeData
  })

  const recommendationGroupCourses = getRecommendationGroupCourses(
    courseId,
    degreeData
  )

  const hasSelectedProfCertCourse = (courseId) => {
    const recommendedCoursesInLS = getRecommendedCoursesFromLS()
    if (!recommendedCoursesInLS?.length) return false
    // We only want to return true if the already selected prof cert
    // course is not the same as the one we are trying to add
    const hasCurrentCourseInLS = recommendedCoursesInLS
      ?.find(course => course.courseId === courseId)
    if (hasCurrentCourseInLS) return false

    return recommendedCoursesInLS?.some((course) => (
      outlierCourses?.some(outlierCourse => (
        outlierCourse.id === course.courseId && outlierCourse.profCert
      ))
    ))
  }

  const hasRecommendationGroupCourseInLS = () => {
    const recommendedCoursesInLS = getRecommendedCoursesFromLS()
    if (!recommendedCoursesInLS?.length) return false

    return recommendedCoursesInLS?.some((course) => (
      recommendationGroupCourses?.some(groupCourse => (
        groupCourse.courseId === course.courseId
      ))
    ))
  }

  const handleBtnClick = () => {
    updateContext({ quitGGUEnrolment: false })

    if (isRegistrationRedesign) {
      sendEvent({
        eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
        properties: {
          page_name: `Course ${currentCourseNumber} Page`,
          page_number: `4-${(currentCourseNumber * 2) + courseQuantity + 1}`,
          course: courseName,
          [`course${currentCourseNumber}_added`]: courseName
        }
      })
      return setShowConfirmChangeModal(true)
    }

    replaceRecommendedCoursesInLS([courseId], replace && [replace])

    sendEvent({
      eventName: NEW_COURSE_SELECTED,
      properties: {
        course: courseName
      }
    })
    removeSegmentEventFromLS(segmentEvent)

    history.push('/recommended-courses')
  }

  const isSwitchBtn = () => {
    if (isRegistrationRedesign) return false

    const recommendedCourses = getRecommendedCoursesFromLS() || []
    return recommendedCourses.some(course => course.courseId === replace)
  }

  const canNotEnroll = [
    COMPLETED_NOT_REPEATABLE,
    IN_PRODUCTION,
    ENROLLED,
    HAS_PRE_REQUISITE,
    SELECTED,
    NOT_ENROLLABLE
  ].includes(getGGUCourseStatus({
    outlierCourses,
    studentAttempts,
    uuid: courseId,
    courseName,
    prospects,
    courseProjectedGrades,
    prerequisite
  }))

  const canTakeMicroAndMacro = canTakeEconomicsCourses({
    studentAttempts,
    prospects,
    outlierCourses,
    degreeData,
    courseId
  }) // returns true if course is not Micro/Macroeconomics

  const isFirstTerm = studentAttempts?.filter(
    attempt => attempt?.fields?.course?.fields?.name?.toLowerCase().includes('ggu')
  ).length < 1
  const isFirstTermProfCert = isProfCert && isFirstTerm

  const hasSelectedProfCert = isProfCert
    ? hasSelectedProfCertCourse(courseId)
    : false

  const shouldBtnDisable = () => {
    if (
      canNotEnroll ||
      advisorApproved ||
      !canTakeMicroAndMacro ||
      isFirstTermProfCert ||
      hasSelectedProfCert
    ) return true
    if (!hasRecommendationGroupCourseInLS(courseId) ||
        hasGroupCourseCompleted
    ) return false

    const courseRecommendationGroup = degreeData?.find(course => (
      course.courseId === courseId
    ))?.recommendationGroup

    const replacementRecommendationGroup = degreeData?.find(course => (
      course.courseId === replace
    ))?.recommendationGroup

    if (courseRecommendationGroup === replacementRecommendationGroup) return false

    return true
  }

  return (
    <MessageWrapper>
      <Message data-testid='message'>
        <Link onClick={() => window.open(standardSyllabusLink, '_blank')}>
          Preview the syllabus
        </Link> {hasTrailerVideo
          ? 'and watch the trailer below'
          : 'to see the course description and learning outcomes'}.&nbsp;
        {!isGGUStudentEnrolled && isGGUEnrollmentActive
          ? hasTrailerVideo ? (
            `Adding this course will include ${courseName} to your current semester’s schedule.`
          ) : `Add this course to include ${courseName} to your current semester’s schedule.`
          : ''}
      </Message>
      {((!isBeforeEnrollmentPeriod && isGGUStudentEnrolled) ||
      (!isGGUStudentEnrolled && isPostEnrollmentPeriod)) && (
        <PostEnrollmentMessage data-testid='post-enrollment-message'>
          You may enroll in this course by reaching out to&nbsp;
          <MailToLink
            onClick={() => window.open('mailto:advising@outlier.org', '_blank')}
          >
            advising@outlier.org
          </MailToLink>.
        </PostEnrollmentMessage>
      )}
      {isBeforeEnrollmentPeriod && (
        <PreEnrollmentMessage data-testid='pre-enrollment-message'>
          Registration {termName ? `for ${termName}` : ''} opens on {formatDateWithDay(openEnrollment)}.
        </PreEnrollmentMessage>
      )}
      {!isGGUStudentEnrolled && isGGUEnrollmentActive && (
        <GGUMessageWrapper>
          <SubmitButtonWrapper
            uppercase
            isSwitchBtn={isSwitchBtn()}
            disabled={shouldBtnDisable()}
          >
            <button
              onClick={handleBtnClick}
              disabled={shouldBtnDisable()}
            >
              {isSwitchBtn() ? 'Switch' : 'Add'} Course
            </button>
          </SubmitButtonWrapper>
          {isFirstTermProfCert &&
            <ProfCertMessage data-testid='prof-cert-message'>
              Professional Certificate courses are unavailable to students in their first term.
            </ProfCertMessage>}
          {hasSelectedProfCert &&
            <ProfCertMessage data-testid='prof-cert-message'>
              Please remove a certificate course from your schedule to add this course.
              <br />
              Students can only take one Professional Certificate course per term.
            </ProfCertMessage>}
        </GGUMessageWrapper>
      )}
    </MessageWrapper>
  )
}

GGUStudentStatusMessage.displayName = 'GGUStudentStatusMessage'
export default GGUStudentStatusMessage
