import React from 'react'
import WidgetState from './WidgetState'
import { dateToSecondsSinceEpoch } from '../../utilities/dateTime'
import {
  isSpecialTopicsCourse,
  shouldShowFinalGrade
} from '../../utilities/course'
import config from '../../config'

export const CompletedCard = ({ course, handleEndedCourses }) => {
  let { id, cohort } = course
  cohort = cohort[0] || cohort

  let { finalExamEndTime, cohortEndTime } = cohort

  finalExamEndTime = dateToSecondsSinceEpoch(new Date(finalExamEndTime))
  cohortEndTime = dateToSecondsSinceEpoch(new Date(cohortEndTime))
  const stateData = !shouldShowFinalGrade(finalExamEndTime, cohortEndTime)
    ? isSpecialTopicsCourse(id)
      ? {
        description: 'The cohort has ended, and your final grade will be ready' +
         ' soon. While you wait, take a look at the special topics on the' +
          ' Course Home.',
        dataTestId: 'final-grade-unavailable',
        primaryBtnTxt: 'Visit home',
        primaryBtnFunction: () => {
          const { courseBaseUrlById } = config
          const courseBaseUrl = courseBaseUrlById(id)
          window.location.href = `${courseBaseUrl}/?flag-whatsNextUpdate=true#/#special-chapters-section`
        }
      }
      : {
        description: 'The cohort has ended, and your final grade will be ready soon.',
        dataTestId: 'final-grade-unavailable'
      }
    : {
      description: `Your final grade is available on the Course Home! Remember 
      that you’ll always have access to the non-graded material.`,
      dataTestId: 'final-grade-available',
      primaryBtnTxt: 'Visit home',
      primaryBtnFunction: () => handleEndedCourses(id)
    }

  return (
    <>
      <div data-testid={stateData.dataTestId}>
        <WidgetState
          {...stateData}
        />
      </div>
    </>
  )
}
