import React from 'react'
import config from '../../../../config'
import { getVisitedSectionInfo } from '../../../../utilities/section'
import WhatsNextWidget from '../WhatsNextWidget'

function Guesswork ({
  course,
  courseProgress,
  chapterNo,
  sectionNo,
  desktopHero
}) {
  const { id } = course

  const { studentProgress, sectionData } = courseProgress
  const { studentAnswers } = studentProgress

  // get last visited guesswork section data
  const { section_exe: sectionExe, section_uuid: sectionUuid } = sectionData
  const { guesswork } = sectionExe

  const getNextActivity = () => {
    const {
      readings,
      lecture,
      multi_lecture_videos: multipleVideos,
      active_learning: activeLearning,
      practice_terms: practiceTerms,
      practice_exercises: practiceExercises,
      quiz
    } = sectionExe || {}
    if (readings && readings.length !== 0) return 'Readings'
    if (lecture || multipleVideos) return 'Lectures'
    if (activeLearning) return 'Active Learning'
    if (practiceExercises) return 'Practice Exercises'
    if (practiceTerms) return 'Practice Terms'
    if (quiz) return 'Quizzes'
  }

  // get course base url
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`

  const {
    completedQuestion,
    totalQuestion,
    questionId
  } = getVisitedSectionInfo(Object.keys(studentAnswers), guesswork)

  // check if guesswork is completed or not
  const completedGuesswork = Object.keys(studentProgress['guesswork-complete'])
  const isGuessworkCompleted = completedGuesswork.includes(sectionUuid)

  const guessworkUrl = `${sectionUrl}/${questionId}`

  const remainingQuestions = totalQuestion - completedQuestion
  const isMultipleCompletedQuestions = completedQuestion > 1 ? 's' : ''
  const isMultiRemainingQuestions = remainingQuestions > 1 ? 's' : ''

  // Guesswork - Completed
  if (isGuessworkCompleted) {
    return (
      <div data-testid='guesswork-completed'>
        <WhatsNextWidget
          description={`You completed Section ${chapterNo}.${sectionNo} Guesswork. 
          Continue to Section ${chapterNo}.${sectionNo} ${getNextActivity()}.`}
          primaryButtonText='Continue'
          primaryButtonFunction={() => {
            window.location.href = sectionUrl
          }}
          desktopHero={desktopHero}
        />
      </div>
    )
  }
  // Guesswork - In progress
  return (
    <div data-testid='guesswork-in-progress'>
      <WhatsNextWidget
        description={`You completed ${completedQuestion} Guesswork 
        question${isMultipleCompletedQuestions} in Section 
        ${chapterNo}.${sectionNo}. Continue to complete the remaining 
        ${remainingQuestions} question${isMultiRemainingQuestions}.`}
        primaryButtonText='Continue'
        primaryButtonFunction={() => {
          window.location.href = guessworkUrl
        }}
        desktopHero={desktopHero}
      />
    </div>
  )
}

Guesswork.displayName = 'Guesswork'
export default Guesswork
