import React, { useEffect, useState } from 'react'
import api from '../../api'
import MainPage from './MainPage'
import CoursePage from './CoursePage'
import CertificatePage from './CertificatePage'
import { CERTIFICATE_PAGE, COURSE_PAGE, segmentEventUrl, MAIN_PAGE } from './constants'
import { ACADEMIC_ACHIEVEMENT_PAGE_VIEWED } from '../../Constants/eventType'
import {
  MainWrapper, GlobalStyle
} from './style'

function AchievementsTracker () {
  const [currentPage, setCurrentPage] = useState(MAIN_PAGE)
  const [currentCertificate, setCurrentCertificate] = useState({})
  const [currentCourse, setCurrentCourse] = useState({})

  useEffect(() => {
    api.newSubmitTrackedEvent({
      event: ACADEMIC_ACHIEVEMENT_PAGE_VIEWED,
      props: {
        title: 'Academic Achievements main page'
      }
    }, segmentEventUrl)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentPage])

  return (
    <MainWrapper>
      <GlobalStyle />

      {currentPage === MAIN_PAGE && (
        <MainPage
          setCurrentPage={setCurrentPage}
          setCurrentCourse={setCurrentCourse}
          setCurrentCertificate={setCurrentCertificate}
        />
      )}

      {currentPage === CERTIFICATE_PAGE && (
        <CertificatePage
          setCurrentPage={setCurrentPage}
          setCurrentCourse={setCurrentCourse}
          currentCertificate={currentCertificate}
        />
      )}

      {currentPage === COURSE_PAGE && (
        <CoursePage
          currentCourse={currentCourse}
          setCurrentPage={setCurrentPage}
        />
      )}
    </MainWrapper>
  )
}

AchievementsTracker.displayName = 'AchievementsTracker'
export default AchievementsTracker
