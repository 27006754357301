export const AMAZON_RELATIONSHIP = 'Amazon'
export const GGU_RELATIONSHIP = 'GGU'
export const GUILD_RELATIONSHIP = 'Guild'
export const NYAS_RELATIONSHIP = 'NYAS'
export const specialStatusRelationships = [
  AMAZON_RELATIONSHIP,
  NYAS_RELATIONSHIP,
  GGU_RELATIONSHIP
]

export const PITTSBURGH_UNIVERSITY = 'University of Pittsburgh'
export const INSTRIDE = 'InStride'
export const ILLINOIS_TECH_BS = 'Illinois Tech BS'
export const FRONTLINE_WORKERS = 'Frontline Workers Scholarship'
