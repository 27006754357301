import React from 'react'
import PropTypes from 'prop-types'
import { WEEKDAYS } from '../../../Constants/accountArea'
import { getDateAtTime, getHoursFromDate } from '../../../utilities/dateTime'
import { setHours, setMinutes } from 'date-fns'
import {
  CustomDatePicker, InputEntry, InputWrapper, MinusIcon, PlusIcon, SectionText,
  TimeFrameElement, TimeFramesContainer
} from './style'

function TimeFrames (props) {
  const { selectedStudyBlocks, setSelectedStudyBlocks } = props

  const isAllDaysEmpty = selectedStudyBlocks?.every(({ blocks }) => !blocks?.length)
  if (isAllDaysEmpty) {
    return (
      <SectionText style={{ fontStyle: 'italic' }}>
        You have not set any study blocks. Select one or more days above
        to start adding study blocks.
      </SectionText>
    )
  }

  const addStudyBlock = (selectedDay, blockIndex) => {
    const newStudyBlocks = selectedStudyBlocks.map(({ day, blocks }) => {
      const isSelectedDay = selectedDay.name === day
      if (!isSelectedDay) return { day, blocks }

      const newBlocks = [...blocks.slice(0, blockIndex + 1),
        { start: 0, end: 0 }, ...blocks.slice(blockIndex + 1)]
      return { day, blocks: newBlocks }
    })

    setSelectedStudyBlocks(newStudyBlocks)
  }

  const removeStudyBlock = (selectedDay, blockIndex) => {
    const newStudyBlocks = selectedStudyBlocks.map(({ day, blocks }) => {
      if (selectedDay.name !== day) return { day, blocks }

      const newBlocks = blocks?.filter((_, index) => index !== blockIndex)
      return { day, blocks: newBlocks }
    })

    setSelectedStudyBlocks(newStudyBlocks)
  }

  return (
    <TimeFramesContainer data-testid='time-frames'>
      {WEEKDAYS.map((day, dayIndex) => {
        const { blocks = [] } = selectedStudyBlocks[dayIndex]
        if (!day || !blocks?.length) return null

        return (
          <TimeFrameElement key={dayIndex}>
            <div className='title'>
              {day.name}
            </div>

            <div className='inputs-container'>
              {blocks.map(({ start, end }, blockIndex) => {
                const isFirstFrame = blockIndex === 0

                const onDateChange = (key, date) => {
                  const dateHours = getHoursFromDate(date)
                  const isStartTime = key === 'start'
                  const isEndTime = key === 'end'
                  const isEndTimeBeforeStartTime = isStartTime && dateHours > end

                  const newBlocks = [...blocks]
                  const newBlock = isEndTimeBeforeStartTime
                    ? { start: dateHours, end: dateHours }
                    : { ...newBlocks[blockIndex], [key]: dateHours }
                  newBlocks[blockIndex] = newBlock
                  if (isEndTime) newBlocks.sort((a, b) => a.start - b.start)

                  const newStudyBlocks = [...selectedStudyBlocks]
                  newStudyBlocks[dayIndex] = { ...newStudyBlocks[dayIndex], blocks: newBlocks }

                  setSelectedStudyBlocks(newStudyBlocks)
                }

                const startHour = Math.floor(start)
                const startMinute = (start - startHour) * 60

                return (
                  <InputWrapper key={dayIndex + blockIndex}>
                    <InputEntry>
                      <label>From</label>
                      <CustomDatePicker
                        name='start'
                        showTimeSelect
                        showTimeSelectOnly
                        timeCaption='Time'
                        timeIntervals={30}
                        dateFormat='h:mm aa'
                        selected={getDateAtTime(start)}
                        onChange={date => onDateChange('start', date)}
                        minTime={setHours(setMinutes(new Date(), 0), 0)}
                        maxTime={setHours(setMinutes(new Date(), 59), 23)}
                      />
                    </InputEntry>

                    <InputEntry>
                      <label>to</label>
                      <CustomDatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeCaption='Time'
                        timeIntervals={30}
                        dateFormat='h:mm aa'
                        selected={getDateAtTime(end)}
                        onChange={date => onDateChange('end', date)}
                        minTime={setHours(setMinutes(new Date(), startMinute), startHour)}
                        maxTime={setHours(setMinutes(new Date(), 59), 23)}
                      />

                      <PlusIcon
                        data-testid='add-block-btn'
                        disabled={blocks.length >= 4}
                        onClick={() => addStudyBlock(day, blockIndex)}
                      />

                      {!isFirstFrame && (
                        <MinusIcon
                          data-testid='remove-block-btn'
                          onClick={() => removeStudyBlock(day, blockIndex)}
                        />
                      )}
                    </InputEntry>
                  </InputWrapper>
                )
              })}
            </div>
          </TimeFrameElement>
        )
      })}
    </TimeFramesContainer>
  )
}

TimeFrames.propTypes = {
  selectedStudyBlocks: PropTypes.array.isRequired,
  setSelectedStudyBlocks: PropTypes.func.isRequired
}

export default TimeFrames
