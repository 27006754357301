import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ButtonAsLink,
  ButtonsContainer,
  CTAButton,
  LargePopupContainer,
  PopupSubtitle,
  PopupTitle,
  StyledCloseIcon
} from './styles'
import { ACCOUNT_PATH } from '../../../../../Constants/accountArea'
import api from '../../../../../api'
import { STUDY_BLOCKS_FIRST_POPUP_CLICKED } from '../../../../../Constants/eventType'

function LargePopup ({ popUpsState }) {
  const [largePopupDismissed, setLargePopupDismissed] = useState(true)
  const history = useHistory()
  const isStudyBlocksPage = history.location.pathname.includes(
    ACCOUNT_PATH.STUDY_BLOCKS_PATH
  )

  useEffect(() => {
    if (Object.keys(popUpsState).length === 0) return

    const dismissedStateFromLS = localStorage.getItem(
      'studyBlocksLargePopupDismissed'
    )

    const { loginCounts, sbPopup1ClickedAction } = popUpsState

    if (
      dismissedStateFromLS ||
      loginCounts <= 1 ||
      sbPopup1ClickedAction === 'Dismissed'
    ) {
      return
    }

    setLargePopupDismissed(false)
  }, [popUpsState])

  const dismissPopup = async () => {
    setLargePopupDismissed(true)
    localStorage.setItem('studyBlocksLargePopupDismissed', 'true')
    await api.submitTrackedEvent({
      event: STUDY_BLOCKS_FIRST_POPUP_CLICKED,
      properties: {
        time_stamp: Date.now(),
        action: 'Dismissed'
      }
    })
  }

  return isStudyBlocksPage || largePopupDismissed ? null : (
    <LargePopupContainer>
      <div>
        <StyledCloseIcon onClick={dismissPopup} />
        <div>
          <PopupTitle>
            Let us help you stay on track with Studyblocks!
          </PopupTitle>
          <PopupSubtitle>
            Set up a learning schedule that works best for you.
          </PopupSubtitle>
        </div>
        <ButtonsContainer>
          <ButtonAsLink onClick={dismissPopup} data-testid='dismiss-popup'>
            Set up later
          </ButtonAsLink>
          <CTAButton
            onClick={async () => {
              history.push('/account/study-blocks')
              await api.submitTrackedEvent({
                event: STUDY_BLOCKS_FIRST_POPUP_CLICKED,
                properties: {
                  time_stamp: Date.now(),
                  action: 'Opened'
                }
              })
            }}
          >
            Get Started
          </CTAButton>
        </ButtonsContainer>
      </div>
    </LargePopupContainer>
  )
}

LargePopup.displayName = 'LargePopup'
export default LargePopup
