import React, { useEffect, useState } from 'react'
import capitalize from 'lodash/capitalize'
import { Input } from './style'
import { getMaxDateValue } from '../../utilities/dateTime'

const DateOfBirth = ({
  dateOfBirth,
  setDateOfBirth,
  setDateError
}) => {
  const padZeroes = value => value?.padStart(2, '0') || ''

  const contextDateOfBirth = dateOfBirth && new Date(dateOfBirth + ' 00:00')
  let contextDate = ''
  let contextMonth = ''
  let contextYear = ''
  if (contextDateOfBirth && contextDateOfBirth.toString() !== 'Invalid Date') {
    contextDate = padZeroes(contextDateOfBirth.getDate().toString())
    contextMonth = padZeroes((contextDateOfBirth?.getMonth() + 1).toString())
    contextYear = padZeroes(contextDateOfBirth?.getFullYear().toString())
  }

  const [date, setDate] = useState(contextDate)
  const [month, setMonth] = useState(contextMonth)
  const [year, setYear] = useState(contextYear)
  const INVALID_DATE_ERROR = 'Enter valid date'
  const YEAR_LENGTH_ERROR = 'Enter 4 digits for year'
  const LETTERS_IN_DATE_ERROR = 'Enter only numbers'
  const FUTURE_DATE_ERROR = 'Date cannot be in future'
  useEffect(() => {
    if (!date || !month || !year || year.length < 4) {
      return setValues({ dateOfBirth: null, dateError: '' })
    }

    const { isValidDate, error } = checkIfValidDate()
    if (!isValidDate) return setValues({ dateOfBirth: null, dateError: error })

    setValues({
      dateOfBirth: `${year}-${padZeroes(month)}-${padZeroes(date)}`,
      dateError: ''
    })

    // eslint-disable-next-line
  }, [date, month, year]);

  const checkIfValidDate = () => {
    const monthResult = validateField({
      fieldName: 'month',
      fieldValue: month,
      maxValue: 12
    })
    if (!monthResult.isValidDate) return monthResult

    const dateResult = validateField({
      fieldName: 'date',
      fieldValue: date,
      maxValue: getMaxDateValue(Number(month), Number(year))
    })
    if (!dateResult.isValidDate) return dateResult

    const yearResult = validateYear(year)
    if (!yearResult.isValidDate) { return yearResult }

    const selectedDate = new Date(year, month - 1, date)
    const isValidDate =
      selectedDate && selectedDate.toString() !== 'Invalid Date'
    if (!isValidDate) {
      return {
        isValidDate: false,
        error: INVALID_DATE_ERROR
      }
    }

    const isFutureDate = new Date() < selectedDate
    return {
      isValidDate: !isFutureDate,
      error: isFutureDate ? FUTURE_DATE_ERROR : ''
    }
  }

  const validateField = ({ fieldName, fieldValue, maxValue }) => {
    if (!fieldValue) {
      return { isValidDate: false, error: INVALID_DATE_ERROR }
    }

    const value = Number(fieldValue)
    if (isNaN(value)) {
      return { isValidDate: false, error: LETTERS_IN_DATE_ERROR }
    }

    if (value < 1) {
      return {
        isValidDate: false,
        error: `${capitalize(fieldName)} cannot be less than 1`
      }
    }

    if (value > maxValue) {
      return {
        isValidDate: false,
        error: `${capitalize(fieldName)} cannot be greater than ${maxValue}`
      }
    }

    return { isValidDate: true, error: '' }
  }

  const validateYear = fieldValue => {
    if (!fieldValue) {
      return { isValidDate: false, error: INVALID_DATE_ERROR }
    }

    if (fieldValue.length !== 4) {
      return { isValidDate: false, error: YEAR_LENGTH_ERROR }
    }

    const yearValue = Number(fieldValue)

    if (isNaN(yearValue) || yearValue < 0) {
      return { isValidDate: false, error: LETTERS_IN_DATE_ERROR }
    }

    const yearString =
      yearValue <= 30
        ? yearValue.toString().padStart(4, '200')
        : yearValue > 30 && yearValue <= 99
          ? yearValue.toString().padStart(4, '19')
          : fieldValue

    const year4Digits = Number(yearString)

    if (isNaN(year4Digits)) {
      return { isValidDate: false, error: LETTERS_IN_DATE_ERROR }
    }

    setYear(yearString)
    return { isValidDate: true, error: '' }
  }

  const setValues = ({ dateOfBirth, dateError }) => {
    setDateOfBirth(dateOfBirth)
    setDateError(dateError)
  }

  const onChangeDateOfBirth = (event, setState) => {
    setState(event.target.value.replace(/\D/g, ''))
  }

  return (
    <div className='date-of-birth'>
      <Input
        className='month'
        placeholder='MM'
        data-testid='month'
        value={month}
        onChange={event => onChangeDateOfBirth(event, setMonth)}
      />
      <Input
        className='date'
        placeholder='DD'
        data-testid='date'
        value={date}
        onChange={event => onChangeDateOfBirth(event, setDate)}
      />
      <Input
        className='year'
        placeholder='YYYY'
        data-testid='year'
        value={year}
        onChange={event => onChangeDateOfBirth(event, setYear)}
      />
    </div>
  )
}

DateOfBirth.displayName = 'DateOfBirth'
export default DateOfBirth
