import styled, { css } from 'styled-components'
import { ReactComponent as BackButtonSVG } from '../../../assets/icons/back-arrow-caret.svg'

export const TextLink = styled.a`
  color: #5fc4b8;
  text-underline-offset: 4.5px;
  font-weight: normal;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #b8fff7;
    text-decoration: underline;
  }
  &:active {
    color: #b8fff7;
    opacity: 0.3;
  }
  &:disabled {
    color: #5fc4b8;
    opacity: 0.3;
    pointer-events: none;
  }
  
`

export const ActionLink = styled(TextLink)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:disabled {
    color: #5fc4b8;
    opacity: 0.3;
  }
`

export const LinkStyles = css`
  color: #5fc4b8;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  &:disabled {
    color: #5fc4b8;
    opacity: 0.3;
    pointer-events: none;
    svg {
      fill: #5fc4b8;
      pointer-events: none;
      path{
        fill: #5fc4b8;
      }
    }
  }
  &:hover {
    color: #b8fff7;
    svg {
      fill: #b8fff7;
      path{
        fill: #b8fff7;
      }
    }
  }
  &:active {
    color: #b8fff7;
    opacity: 0.3;
    svg {
      fill: #b8fff7;
      path{
        fill: #b8fff7;
      }
    }
  }
  :focus {
    outline: none;
  }
`

export const BackButtonIcon = styled(BackButtonSVG)`
  stroke: ${props => props.color}
`
