import React, { useContext } from 'react'

import {
  getOrientationChapterSectionUrl,
  getFirstSectionUrl,
  completedFirstSection,
  completedOrientation
} from '../../utilities/chapterUtils'
import WidgetState from './WidgetState'
import fireworksIcon from '../../assets/icons/fireworks.svg'
import { Separator, StateMessage } from './style'

import { AppContext } from '../ContextProvider/ContextProvider'

export const UpcomingCard = ({ course }) => {
  const { id } = course
  const { coursesProgress } = useContext(AppContext)
  if (!coursesProgress) return null

  const { studentProgress } =
    coursesProgress.find(({ courseId }) => courseId === id)

  const isOrientationCompleted = completedOrientation(course, studentProgress)
  const isFirstSectionCompleted = completedFirstSection(course, studentProgress)

  const orientationSectionUrl = getOrientationChapterSectionUrl(course)
  const sectionUrl = getFirstSectionUrl(course)

  return (
    <div data-testid='upcoming-card'>
      {orientationSectionUrl && !isOrientationCompleted &&
        <div data-testid='start-orientation-message'>
          <WidgetState
            description='Start with Orientation to learn how to succeed in this course.'
            primaryBtnTxt='Start'
            primaryBtnFunction={() => {
              window.location.href = orientationSectionUrl
            }}
          />
          <Separator />
        </div>}
      {!isFirstSectionCompleted &&
        <WidgetState
          description='We’ve made Section 1.1 available to you early if you want to get ahead.'
          primaryBtnTxt='Start'
          primaryBtnFunction={() => {
            window.location.href = sectionUrl
          }}
        />}
      {isFirstSectionCompleted &&
        <>
          <StateMessage
            className='d-flex align-items-center justify-content-sm-between justify-content-md-start'
          >
            <img src={fireworksIcon} alt='fireworks-icon' />
            <div>
              <strong>Nice job getting ahead! </strong>
              The next set of activities will unlock when the cohort begins.
            </div>
          </StateMessage>
        </>}
    </div>)
}

export default UpcomingCard
