import styled, { css, createGlobalStyle } from 'styled-components'
import media from '../../../mediaQueries'

export const GlobalStyle = createGlobalStyle`
  body {
    height: 100vh;
    overflow: hidden;
  }
`
export const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  justify-content: center;
  align-items: center;
`
export const Modal = styled.div`
  position: relative;
  top: -50px;
  opacity: 0;

  ${props => props.showModal && css`
    opacity: 1;
    top: 0px;
  `}

  width: 576px;
  background: #25272B;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;

  ${media.mobile`
    width: 327px;
  `}
`
export const ModalHeader = styled.div`
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
`
export const ModalBody = styled.div`
  padding: 24px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;

  p {
    margin: 0px;
  }

  p:nth-child(2) {
    margin-top: 24px;
  }
`
export const ButtonsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  ${media.mobile`
    flex-flow: column nowrap;
  `}
`
export const PrimaryButton = styled.button`
  min-width: 208px;
  height: 48px;
  padding: 16px 24px;
  margin-right: 12px;
  background: #5FC4B8;
  border-radius: 5px;
  border: none;

  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #161618;
  outline: none!important;

  ${media.mobile`
    width: 100%;
    margin-right: 0px;
    margin-bottom: 12px;
  `}

  :hover {
    background: white;
  }
`
export const SecondaryButton = styled(PrimaryButton)`
  background: transparent;
  color: #5FC4B8;
  border: 1px solid #5FC4B8;
  margin: 0px;

  :hover {
    background: white;
    color: black;
  }
`
