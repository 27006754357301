import {
  OptionAvailability,
  OptionDescription,
  OptionHeader,
  UpcomingOption,
  UpcomingOptions,
  UpcomingOptionsSection
} from './style'
import React from 'react'
import { ExitOptions } from '../../../Constants/accountArea'
import config from '../../../config'
import {
  getOptionDate,
  getOptionDescription,
  getOptionIcon
} from './coursePageUtil'

const UpcomingOptionsList = ({
  optionParameters,
  withdrawDeadline,
  dropDeadline
}) => {
  const {
    canDrop,
    canWithdraw,
    isPartnership,
    isRegularPartnerUser,
    isGGUCourse,
    isPostTokensCohort,
    isStudentEnrolled,
    isAmazon,
    isGuildProfCertII
  } = optionParameters || {}

  const getUpcomingOptions = () => {
    const { WITHDRAW } = ExitOptions
    if ((isGuildProfCertII || isAmazon) && config.hasCmPartnerUpdateFlag) return []
    if (isStudentEnrolled && !canDrop) return []
    if (isStudentEnrolled && !canWithdraw && canDrop) return [WITHDRAW]
    return []
  }
  const renderUpcomingOption = (option, i) => {
    const optionDescription = getOptionDescription({
      option,
      isGGUCourse,
      isPostTokensCohort,
      isRegularPartnerUser
    })
    const optionIcon = getOptionIcon(option)
    const optionDate = getOptionDate({
      option,
      dropDeadline,
      withdrawDeadline
    })

    return (
      <UpcomingOption key={i} isPartnership={isPartnership}>
        <OptionHeader>
          <img src={optionIcon} alt={option} />
          <span>{option}</span>
        </OptionHeader>
        <OptionDescription>
          {optionDescription}
        </OptionDescription>
        <OptionAvailability>
          Available {optionDate}
        </OptionAvailability>
      </UpcomingOption>
    )
  }

  if (getUpcomingOptions()?.length < 1) return null

  return (
    <UpcomingOptionsSection>
      <h3>Upcoming option</h3>
      <p>
        You’ll be able to make this change later in the course if needed.
      </p>
      <UpcomingOptions>
        {getUpcomingOptions()
          .map((option, i) => renderUpcomingOption(option, i)
          )}
      </UpcomingOptions>
    </UpcomingOptionsSection>
  )
}
UpcomingOptionsList.displayName = 'UpcomingOptionsList'
export default UpcomingOptionsList
