import { AMAZON_VOUCHER_UPLOADED_EVENT } from '../Constants/eventType'
import { MULTIPLE } from '../Constants/frequency'
import { AMAZON_RELATIONSHIP } from '../Constants/relationships'
import {
  POPUP_TYPE, VOUCHER_STATUS, DISMISS_DAYS
} from '../Constants/voucher'
import { getLatestCohort } from './course'
import {
  addDaysToDateInSecs,
  dateToSecondsSinceEpoch,
  getDateMidNight,
  secondsSinceEpoch
} from './dateTime'

export const getPopupType = vouchers => {
  if (!vouchers?.length) return null

  const isVoucherRequired = voucher => {
    return (
      !!voucher?.dropDeadline &&
      voucher.voucherStatus === VOUCHER_STATUS.OUTSTANDING_VOUCHER
    )
  }

  const requiredVouchers = vouchers.filter(isVoucherRequired)

  const hasNoRequiredVouchers = !requiredVouchers.length
  if (hasNoRequiredVouchers) return null

  const hasIremovableVoucher = !!findIrremovableVoucher(requiredVouchers)
  return hasIremovableVoucher ? POPUP_TYPE.IRREMOVABLE : POPUP_TYPE.REMOVABLE
}

export const findIrremovableVoucher = vouchers => {
  return vouchers.find(voucher => isIrremovableVoucher(voucher))
}

export const isIrremovableVoucher = voucher => {
  const voucherDates = getARequiredVoucherDates(voucher)

  if (!voucherDates) return false

  const {
    nowAfterDismissDays,
    dropDeadlineMidNight
  } = voucherDates

  return nowAfterDismissDays >= dropDeadlineMidNight
}

export const getARequiredVoucherDates = voucher => {
  if (!voucher?.dropDeadline) return null

  const { dropDeadline, voucherStatus } = voucher

  const isNotRequired = voucherStatus !== VOUCHER_STATUS.OUTSTANDING_VOUCHER
  if (isNotRequired) return null

  const now = secondsSinceEpoch()
  const dropDeadlineMidNight = dateToSecondsSinceEpoch(
    getDateMidNight(dropDeadline)
  )

  const nowAfterDismissDays = addDaysToDateInSecs(now, DISMISS_DAYS)

  return {
    nowAfterDismissDays,
    dropDeadlineMidNight
  }
}

export const isAmazonStudent = studentCourses => {
  if (!studentCourses?.length) return false

  return studentCourses.some(course => {
    const latestCohort = getLatestCohort(course)

    if (!latestCohort) return false

    return (
      latestCohort.relationship?.fields?.relationshipName === AMAZON_RELATIONSHIP
    )
  })
}

export const getAmazonVoucherUploadedEvent = () => {
  return {
    event: AMAZON_VOUCHER_UPLOADED_EVENT,
    properties: {
      time_stamp: Date.now()
    },
    frequency: MULTIPLE
  }
}

export const hasVouchers = (vouchers) =>
  !!vouchers?.records?.length

export const hasNewAmazonVoucher = (vouchers) => {
  const paymentTuitionStatuses = [
    VOUCHER_STATUS.APPROVED_PAYMENT_REQUEST,
    VOUCHER_STATUS.OUTSTANDING_PAYMENT_REQUEST,
    VOUCHER_STATUS.PENDING_PAYMENT_REQUEST
  ]
  return !!vouchers?.records?.some(record =>
    paymentTuitionStatuses.includes(record?.voucherStatus))
}

export const shouldShowVoucherUpload = (vouchers) =>
  hasVouchers(vouchers) && !hasNewAmazonVoucher(vouchers)
