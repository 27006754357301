import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import { ReactComponent as CalendarIcon }
  from '../../../assets/icons/calendar.svg'
import { THEME } from '../ExtensionState'
import { SectionTitle } from '../../../styles/text'

export const WhatsNextHeading = styled(SectionTitle)`
  margin-bottom: 24px;
`

export const WidgetWrapper = styled.div`
  background: #1D1D1F;
  padding: 24px;
  margin-top: 12px;
  border-radius: 5px;
`
export const WidgetHeading = styled.div`
  margin-bottom: 24px;
`
export const CourseIcon = styled.span`
  background-color: #5FC4B8;
  height: 16px;
  width: 16px;
  margin-right: 8px;
  mask: ${({ src }) => `url(${src}) center/cover no-repeat;`};
  mask-size: contain;
`
export const CourseName = styled.div`
  color: #5FC4B8;
  font-size: 18px;
  line-height: 24px;
`
export const CourseState = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #CCD4D8;
  line-height: 19px;
`
export const StateDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  ${mediaqueries.mobile`
    margin-bottom: 24px;
  `}
  a {
    color: #77B1AF;
    font-weight: normal;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: #4d9b96;
    }
  }
`
export const WidgetStateContainer = styled.div`
  padding: 0;
`
export const StateCTA = styled.div`
  padding-left: 24px;
  ${mediaqueries.mobile`
    padding-left: 0;
  `}
`
export const StatePrimaryButton = styled.button`
  ${mediaqueries.mobile`
    margin-bottom: 0;
  `}
`
export const StateSecondaryButton = styled.button`
  margin-right: 0;
  margin-left: 8px;
  ${mediaqueries.mobile`
    margin-left: 0;
    margin-top: 16px;
  `}
`
export const StateMessage = styled.div`
  font-size: 16px;
  border-radius: 5px;
  padding: 16px;
  margin-top: 24px;
  background-color: #191631;
  color: #969BFF;
  line-height: 19px;

  strong {
    color: #FFFFFF;
    ${mediaqueries.mobile`
      display:inline;
    `}
    ${mediaqueries.mobile`
      display:block;
    `}
  }

  img {
    margin-right: 16px;
    margin-left: 0px;
    ${mediaqueries.tablet`
      margin-right: 16px;
      margin-left: 0px;
  `}
    ${mediaqueries.mobile`
      height: 32px;
      width: 32px;
      margin-right: 0;
      margin-left: 16px;
    `}
  }
  ${mediaqueries.tablet`
    flex-direction: row;
  `}
  ${mediaqueries.mobile`
    flex-direction: row-reverse;
  `}
`
export const ExtensionStateWrapper = styled.div`
  display: flex;
  font-size: 16px;
  border-radius: 5px;
  padding: 19px 16px;
  margin-top: 24px;
  background-color:
    ${({ theme }) => theme === THEME.WARNING ? '#342215' : '#351915'};
  color: #ffffff;

  ${mediaqueries.tablet`
    flex-direction: row;
  `}
  ${mediaqueries.mobile`
    flex-direction: row-reverse;
  `}
`
export const ExtensionStateMessage = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;

  ${mediaqueries.mobile`
    flex-direction: row-reverse;
  `}
`
export const ExtensionIcon = styled(CalendarIcon)`
  fill: ${({ theme }) => theme === THEME.WARNING ? '#FFB455' : '#F2765A'};
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  ${mediaqueries.mobile`
    position: static;
    height: 32px;
    width: 32px;
    float: right;
    margin-right: 0px;
  `}
  ${mediaqueries.mobile`
    flex-direction: row-reverse;
  `}
`
export const ExtensionTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${mediaqueries.mobile`
    flex-direction: column;
  `}
`
export const ExtensionText = styled.span`
  margin-left: 32px;
  line-height: 24px;
  font-weight: 700;

  ${mediaqueries.mobile`
    margin-left: 0;
  `}
`
export const ExtensionDeadlineTextWrapper = styled.div`
  font-weight: 700;

  a {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  ${mediaqueries.mobile`
    margin-left: 0;
    margin-top: 8px;
  `}
`
export const ExtensionExtendedText = styled.span`
  color: ${({ theme }) => theme === THEME.WARNING ? '#FFB455' : '#F2765A'};
  font-size: 16px;
  font-weight: 400;
`
export const ExtensionLinkIcon = styled.span`
  display: inline-block;
  width: 14px;
  height: 9px;
  margin-left: 13px;
  background-color: #ffffff;
  mask: ${({ src }) => `url(${src}) center/cover no-repeat;`};
  mask-size: contain;
`
export const Separator = styled.hr`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  border: 0 none;
  margin: 24px 0;
`
