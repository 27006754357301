import styled from 'styled-components'
import media from '../../../mediaQueries'

export const StyledWrapper = styled.div``

export const StyledHeaderWrapper = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 20px;
`

export const StyledTitle = styled.h2`
  font-weight: 300;
  font-size: 27px;
  line-height: 29px;
  margin-top: 15px;
  margin-bottom: 15px;
`

export const StyledSubTitle = styled.span`
  display: block;
  font-size: 18px;
  max-width: 850px;
  font-weight: 300;
`

export const StyledContentWrapper = styled.div`
  margin-top: 30px;
  max-width: 1000px;
`

export const StyledListItem = styled.div`
  border-top: ${props => props.borderTop ? 1 : 0}px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px 0 25px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

export const StyledColumn = styled.div`
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  width: ${props => props.width || 300}px;
  margin-right: 12px;
  margin-bottom: 10px;
  ${media.tablet`
  width: 200px;
  `}
  ${media.desktop`
  width: 200px;
  `}
`

export const StyledLinkText = styled.span`
  font-weight: 700;
  cursor: pointer;
  color: #5FC4B8;
  letter-spacing: 1px;
  @media only screen and (max-width: 422px) {
  margin-bottom: 10px;
}
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`

export const HiddenInput = styled.input`
  display: none;
`

export const StyledFileName = styled.span`
  font-size: 14px;
  display: block;
  margin-top: 5px;
`

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 3px;
  font-size: 16px;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 35px;
  padding-bottom: 10px;
`
