import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Spinner } from 'react-bootstrap'
import CloseIcon from '../../../assets/icons/close-icon.svg'
import {
  ModalGlobalStyle,
  ModalHeader,
  Separator,
  ButtonsContainer
} from './style'
import { PrimaryButton, SecondaryButton } from '../style'

const ConfirmationModal = ({ show, setShow, onSaveClick, isLoading }) => {
  const closeModal = () => {
    setShow(false)
  }

  return (
    <Modal show={show} centered>
      <ModalGlobalStyle />
      <ModalHeader>
        <p>Are you sure?</p>
        <img
          data-testid='close-icon'
          src={CloseIcon}
          alt='Close Modal'
          onClick={closeModal}
        />
      </ModalHeader>
      <Separator />
      <Modal.Body>
        <p>
          The total hours entered don’t meet your suggested weekly hours.
          Are you sure you want to save the study blocks you’ve added?
        </p>
        <ButtonsContainer>
          <PrimaryButton
            style={{ width: '83px' }}
            data-testid='edit-button'
            onClick={closeModal}
          >
            edit
          </PrimaryButton>
          <SecondaryButton
            data-testid='save-button'
            disabled={isLoading}
            onClick={onSaveClick}
          >
            {isLoading ? <Spinner animation='border' /> : 'save'}
          </SecondaryButton>
        </ButtonsContainer>
      </Modal.Body>
    </Modal>
  )
}

ConfirmationModal.displayName = 'ConfirmationModal'
export default ConfirmationModal
