import React, { useContext } from 'react'
import { AppContext } from '../../../ContextProvider/ContextProvider'
import {
  completedFirstSection,
  getFirstSectionUrl
} from '../../../../utilities/chapterUtils'
import find from 'lodash/find'
import WhatsNextWidget from '../WhatsNextWidget'
import { progressBeforeCohortStart } from '../../../../utilities/cohort'
import { CHAPTER_TYPE } from '../../../../Constants/courseCard'
import config from '../../../../config'
import CourseworkInProgress from './CourseworkInprogress'

function InProgress ({ course, desktopHero }) {
  let { id, cohort, chapters } = course
  const { coursesProgress } = useContext(AppContext)

  const courseProgress = find(coursesProgress, { courseId: id })
  if (!courseProgress) return null
  const { studentProgress } = courseProgress
  if (!studentProgress) return null

  const { studentAnswers } = studentProgress
  const activityNotYetStarted = !Object.keys(studentAnswers).length
  const sectionUrl = getFirstSectionUrl(course)

  const readyToBeginGuesswork = sectionUrl && activityNotYetStarted
  if (readyToBeginGuesswork) {
    return (
      <WhatsNextWidget
        description='When you’re ready to begin coursework, let’s start with Section 1.1 Guesswork.'
        primaryButtonText='Start'
        primaryButtonFunction={() => {
          window.location.href = sectionUrl
        }}
        desktopHero={desktopHero}
      />
    )
  }

  cohort = cohort[0] || cohort
  const hasProgressBeforeCohortStart = progressBeforeCohortStart(
    cohort,
    courseProgress
  )
  const getSecondSectionUrl = () => {
    if (!chapters || chapters.length === 0) return

    const { CHAPTER } = CHAPTER_TYPE
    const filteredChapters = chapters.filter(
      (chapter) => chapter.type === CHAPTER
    )

    if (!filteredChapters.length) return

    const { courseBaseUrlById } = config
    const courseBaseUrl = courseBaseUrlById(id)
    const firstSection = filteredChapters[0].sections[1]
    const { section_uuid: sectionUuid } = firstSection

    return `${courseBaseUrl}/#/${sectionUuid}`
  }

  const isFirstSectionCompleted = completedFirstSection(course, studentProgress)
  const firstSectionCompleted =
    hasProgressBeforeCohortStart && isFirstSectionCompleted

  if (firstSectionCompleted) {
    return (
      <WhatsNextWidget
        description='Great job completing Section 1.1 before the cohort began!
        Let’s continue with Section 1.2.'
        primaryButtonText='Continue'
        primaryButtonFunction={() => {
          const secondSectionUrl = getSecondSectionUrl()
          window.location.href = secondSectionUrl
        }}
        desktopHero={desktopHero}
      />
    )
  }

  if (!firstSectionCompleted) {
    return (
      <CourseworkInProgress
        courseProgress={courseProgress}
        course={course}
        desktopHero={desktopHero}
      />
    )
  }
}

InProgress.displayName = 'InProgress'
export default InProgress
