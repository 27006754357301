import React from 'react'
import {
  CourseDisclosure,
  CourseProgress,
  CourseProgressContainer,
  CourseTitle,
  DisclosureArrow,
  FlexDiv,
  MobileCTAContainer,
  MobileCourseTag,
  OpeningClosingCountdown
} from './style'
import { getCourseCountdown, getCourseProgress, getCourseTag } from '../utils'
import WhatsNext from '../WhatsNext/WhatsNext'

function MobileActiveCourses ({ coursesToDisplay }) {
  return coursesToDisplay.map((course) => {
    const { id, displayName } = course
    const { countdownText, warningType } = getCourseCountdown(course)
    const courseProgress = getCourseProgress(course)
    const courseTag = getCourseTag(course)

    return (
      <CourseDisclosure key={id} data-testid='mobile-active-courses'>
        <summary>
          <FlexDiv>
            <FlexDiv flexDirection='column' flex='1 1 auto'>
              <FlexDiv>
                <CourseTitle>{displayName}</CourseTitle>
                {courseTag && (
                  <MobileCourseTag>&nbsp;({courseTag})</MobileCourseTag>
                )}
              </FlexDiv>
              <OpeningClosingCountdown warningType={warningType}>
                {countdownText}
              </OpeningClosingCountdown>
            </FlexDiv>
            <DisclosureArrow />
          </FlexDiv>
          <CourseProgressContainer>
            <CourseProgress courseProgress={courseProgress} />
          </CourseProgressContainer>
        </summary>
        <MobileCTAContainer>
          <WhatsNext course={course} />
        </MobileCTAContainer>
      </CourseDisclosure>
    )
  })
}

MobileActiveCourses.displayName = 'MobileActiveCourses'
export default MobileActiveCourses
