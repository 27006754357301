import React, { useEffect, useMemo, useState } from 'react'
import api from '../../../api'
import {
  filterGoogleDataAnalyticsIChapters,
  getLatestCohort,
  isInProgressBeforeCutOffDate
} from '../../../utilities/course'
import { getCohortExamDateSecondsSinceEpoch } from '../../../utilities/cohort'
import {
  dateToSecondsSinceEpoch,
  secondsSinceEpoch
} from '../../../utilities/dateTime'
import { isUserAuditor } from '../../../utilities/user'

import CourseListCard from '../CourseListCard/CourseListCard'
import {
  calculateCourseProgress,
  getActiveAssessmentsLabelAndColor,
  getFormattedCourseList
} from './courseListUtils'
import {
  getCourseResourcesSchedule
} from '../../../utilities/cohortScheduleUtils'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import NoCourse from '../NoCourse/NoCourse'
import { removeQuizzesFromSection } from '../../../utilities/chapterUtils'

const CourseList = ({ courses, onClick, toggleCheckList }) => {
  const [courseList, setCourseList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchCoursesWithStudentProgressAndCourseData()
    // eslint-disable-next-line
  }, [courses])

  /**
   * Fetches course data, student progress, syllabus details and all sections data for each course
   * and sets the courseList state with the data. If the course has no schedule, it is filtered out.
  */
  const fetchCoursesWithStudentProgressAndCourseData = async () => {
    setLoading(true)

    const coursesWithStudentProgressAndCourseData = await Promise.all(
      courses.map(course => getCourseWithStudentProgressAndCourseData(course))
    )
    const coursesWithSyllabuses = coursesWithStudentProgressAndCourseData.filter(Boolean)

    setCourseList(coursesWithSyllabuses)
    setLoading(false)
  }

  const getCourseWithStudentProgressAndCourseData = async course => {
    const {
      id: activeCourseUUID,
      cohort: { id: cohortId }
    } = course || {}

    const [
      courseData,
      studentProgress,
      fetchedSchedule,
      datoSectionData
    ] = await Promise.all([
      api.getCourseData(activeCourseUUID, getLatestCohort(course), course?.name),
      api.getStudentProgress(activeCourseUUID),
      api.getSyllabusDetails(cohortId, activeCourseUUID),
      api.getAllSectionsData(activeCourseUUID)
    ])
    const { syllabusData } = fetchedSchedule || {}
    const hasSchedule =
      syllabusData?.find((schedule) => schedule.sections.length > 0)

    if (!hasSchedule) return null

    const latestCohort = getLatestCohort(course)
    const currentDate = secondsSinceEpoch()

    const { dateStart } = latestCohort || {}
    const filteredChapters = filterGoogleDataAnalyticsIChapters(courseData?.chapters, activeCourseUUID, dateStart)
    const filteredCourseData = {
      ...courseData,
      chapters: filteredChapters
    }

    const isAuditor =
      isUserAuditor(latestCohort) ||
        latestCohort?.name.toUpperCase().includes('AUDIT')

    let allSectionsData = datoSectionData
    if (isAuditor) {
      const { dateStart, finalExamEndTime, cohortEndTime, duration } = latestCohort || {}
      const isCohortInProgress = isInProgressBeforeCutOffDate({
        dateStart, finalExamEndTime, cohortEndTime, duration, courseName: course?.name
      })
      if (!isCohortInProgress) {
        allSectionsData = datoSectionData?.map(section => {
          return removeQuizzesFromSection(section)
        }) || []
      }
    }

    const {
      relationship: { fields: { liveProctoring } = {} } = {},
      finalExamEndTime
    } = latestCohort || {}

    filteredCourseData.isLiveProctoring = liveProctoring

    const isCohortEnded =
      getCohortExamDateSecondsSinceEpoch(finalExamEndTime) < currentDate

    const courseResourcesSchedule = getCourseResourcesSchedule({
      latestCohort,
      syllabusData,
      courseData: filteredCourseData
    })

    const courseProgress = calculateCourseProgress({
      courseData: filteredCourseData,
      courseResourcesSchedule,
      studentProgress,
      isCohortEnded,
      allSectionsData
    })

    const hasCohortStarted =
      dateToSecondsSinceEpoch(new Date(dateStart)) <= currentDate

    return {
      ...course,
      courseResourcesSchedule,
      activityCount:
        hasCohortStarted && courseProgress?.activityCount,
      isAudit: isAuditor,
      isCohortEnded,
      hasCohortStarted,
      isCaughtUp: courseProgress?.isCaughtUp,
      isWorkingAhead: courseProgress?.isWorkingAhead,
      hasWorkedAheadUntilExam: courseProgress?.hasWorkedAheadUntilExam,
      hasAssessmentAfterTermBreak: courseProgress?.hasAssessmentAfterTermBreak,
      assessmentAfterTermBreakType: courseProgress?.assessmentAfterTermBreakType,
      workingAheadWeekToDisplay: courseProgress?.workingAheadWeekToDisplay,
      hasWorkedAheadAndNotCaughtUp:
      courseProgress?.hasWorkedAheadAndNotCaughtUp,
      weekProgress: courseProgress?.weekProgress,
      progress: courseProgress?.progress,
      fullCourseProgress: courseProgress?.fullCourseProgress,
      chapters: filteredChapters,
      courseUnits: filteredCourseData?.courseUnits || [],
      activeAssessments:
        getActiveAssessmentsLabelAndColor(
          courseResourcesSchedule,
          filteredCourseData,
          studentProgress,
          activeCourseUUID
        ) || [],
      allSectionsData
    }
  }

  const formattedCourseList = useMemo(() => {
    return getFormattedCourseList(courseList)
  }, [courseList])

  useEffect(() => {
    if (formattedCourseList?.length === 1 && !loading) {
      onClick({ ...formattedCourseList[0], singleCourse: true })
    }
  }, [formattedCourseList, onClick, loading])

  if (loading) return <LoadingSpinner />

  if (!formattedCourseList?.length) return <NoCourse />

  return formattedCourseList.map((props, index) => (
    <CourseListCard key={index} {...props} onClick={() => onClick(props)} />
  ))
}

CourseList.displayName = 'CourseList'
export default CourseList
