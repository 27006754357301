import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../../ContextProvider/ContextProvider'
import api from '../../../api'
import AmazonRefundFormPage from './AmazonRefundFormPage'
import RefundRequested from './RefundRequested'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import { AMAZON_REFUND_REQUEST_PAGES } from '../../../Constants/accountArea'
import {
  MainText, RequestPageContainer, PageHeader, HorizontalLine, CertificateBox,
  CertificateRibbonIcon, MainButton, ReceivalPageContainer
} from './style'

const {
  REFUND_REQUEST, EMBEDDED_FORM, REQUEST_RECEIVAL, REFUND_REQUESTED
} = AMAZON_REFUND_REQUEST_PAGES

function AmazonRefundRequest () {
  const history = useHistory()
  const {
    courses = [], studentData = {}
  } = useContext(AppContext) || {}

  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(REFUND_REQUEST)

  useEffect(() => {
    const checkAmazonRefundStatus = async () => {
      const isRefundRequested = await api.getAmazonRefundStatus()
      setIsLoading(false)
      if (isRefundRequested) setCurrentPage(REFUND_REQUESTED)
    }

    checkAmazonRefundStatus()
  // eslint-disable-next-line
  }, [])

  const handleRequestRefundClick = () => setCurrentPage(EMBEDDED_FORM)

  const handleReturnToDashboard = () => history.push('/')

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (currentPage === REFUND_REQUEST) {
    return (
      <RequestPageContainer>
        <PageHeader>Request a refund</PageHeader>

        <HorizontalLine />

        <MainText style={{ marginBottom: '21px' }}>
          Start a refund request for our team to review. If your request is approved,
          the funds will be returned to the original payment method.
        </MainText>

        <MainText style={{ marginBottom: '32px' }}>
          Remember that your Certificate is only eligible for a refund before your
          first cohort drop deadline passes and within 6 months of your purchase.
          Refunds that do not meet our policy will be issued at our discretion.
        </MainText>

        <CertificateBox style={{ marginBottom: '32px' }}>
          <CertificateRibbonIcon />
          <div style={{ marginLeft: '16px' }}>
            <p style={{ marginBottom: '4px' }}>
              College Pathways Certificate
            </p>
            <p style={{ fontSize: '16px' }}>
              $1260 value
            </p>
          </div>
        </CertificateBox>

        <MainButton
          onClick={handleRequestRefundClick}
        >
          request refund
        </MainButton>
      </RequestPageContainer>
    )
  }

  if (currentPage === EMBEDDED_FORM) {
    return (
      <AmazonRefundFormPage
        studentData={studentData}
        studentCourses={courses}
        setCurrentPage={setCurrentPage}
      />
    )
  }

  if (currentPage === REQUEST_RECEIVAL) {
    return (
      <ReceivalPageContainer>
        <PageHeader style={{ marginBottom: '16px' }}>
          We’ve received your request
        </PageHeader>

        <MainText style={{ marginBottom: '32px' }}>
          Our team will review your eligibility and be in touch soon!
        </MainText>

        <MainButton
          onClick={handleReturnToDashboard}
        >
          return to dashboard
        </MainButton>
      </ReceivalPageContainer>
    )
  }

  if (currentPage === REFUND_REQUESTED) {
    return <RefundRequested />
  }
}

AmazonRefundRequest.displayName = 'AmazonRefundRequestPage'
export default AmazonRefundRequest
