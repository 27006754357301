export const GUESSWORK = 'Guesswork'
export const ACTIVE_LEARNING = 'Active Learning'
export const PRACTICE_EXERCISES = 'Practice Exercises'
export const PROBLEM_BANK = 'Problem Bank'
export const QUIZ = 'Quiz'
export const PRACTICE_TERMS = 'Practice Terms'
export const CONCEPT_MAP = 'Concept Map'
export const ASSIGNMENT = 'Assignment'
export const EXAM = 'Exam'
export const SECTION = 'section'
export const FINAL_GRADE = 'finalGrade'

export const SectionType = {
  GUESSWORK: 'Guesswork',
  LECTURE: 'Lecture',
  ACTIVE_LEARNING: 'Active Learning',
  QUIZ: 'Quiz',
  PRACTICE_TERMS: 'Practice Terms',
  READINGS: 'Readings',
  CONCEPT_MAP: 'Concept Map'
}
