import api from '../api'
import config from '../config'
import { CHAPTER_TYPE, PROGRESS_TYPE } from '../Constants/courseCard'
import get from 'lodash/get'
import { EXAM } from '../Constants/examType'
import { cloneDeep } from 'lodash'
import { getExamDatesFromCohortExamDates } from './cohort'
import { dateToSecondsSinceEpoch, diffDays, secondsSinceEpoch } from './dateTime'
import { calculateUnlockDate } from './chapterLockDates'
import { SINGLE_MIDTERM_EXAM } from '../Constants'
import {
  PREV_EXAM_DUE_DATE, PREV_EXAM_START_DATE
} from '../Constants/chapterLockCodes'
import {
  CHAPTER,
  ORIENTATION,
  WRITING_ASSIGNMENT
} from '../Constants/chapterTypes'

export { addInfoForCourses }

async function addInfoForCourses (courses) {
  if (courses.length === 0) {
    return courses
  }
  const coursesWithChapterInfo = [...courses]
  const coursesDetails = await api.getCoursesDetails(coursesWithChapterInfo.map(course => course.id))
  coursesWithChapterInfo.forEach((course) => {
    const { displayName } = course
    if (displayName === 'Psychology') {
      course.displayName = 'Intro to Psychology'
    }

    const currentCourse = coursesDetails.get(course.id)
    const {
      chapters,
      pretestExamContent,
      prerequisite,
      minimumPretestScore,
      pretestLockoutDays,
      courseIcon,
      courseImage
    } = currentCourse || {}
    course.chapters = chapters
    course.pretest = {
      pretestExamContent,
      prerequisite,
      minimumPretestScore,
      pretestLockoutDays
    }
    course.courseIcon = courseIcon
    course.courseImage = courseImage
  })
  return coursesWithChapterInfo
}
export function completedFirstSection (course, studentProgress) {
  if (!course || !studentProgress) return

  const { chapters } = course
  if (!chapters || !chapters.length) return

  const { CHAPTER } = CHAPTER_TYPE
  const filteredChapters = chapters.filter(({ type }) => type === CHAPTER)
  if (!filteredChapters.length) return

  const firstSection = filteredChapters[0].sections[0]
  const { section_uuid: sectionUuid } = firstSection
  const sectionProgress = studentProgress.sectionProgress?.[sectionUuid]

  return sectionProgress === 100
}

export const getFirstSectionUrl = (course) => {
  const { chapters } = course
  const { courseBaseUrlById, isCollegeSuccessCourse } = config

  if (!chapters || !chapters.length) return
  const isCollegeSuccess = isCollegeSuccessCourse(course.id)

  const filteredChapters = chapters.filter(chapter => chapter.type === 'chapter')

  if (!filteredChapters.length && !isCollegeSuccess) return

  const { id } = course
  const courseBaseUrl = courseBaseUrlById(id)

  if (isCollegeSuccess) return `${courseBaseUrl}/#/`
  // Finds the first section URL
  const firstSection = filteredChapters[0].sections[0]
  const sectionUuid = get(firstSection, 'section_uuid', '')
  return `${courseBaseUrl}/#/${sectionUuid}`
}

export function completedOrientation (course, studentProgress) {
  if (!course || !studentProgress) return
  const { chapters } = course
  if (!chapters) return

  const { ORIENTATION } = CHAPTER_TYPE
  const { ORIENTATION_SECTION_PROGRESS } = PROGRESS_TYPE

  const orientationChapter = chapters.find(({ type }) => type === ORIENTATION)
  if (!orientationChapter) return

  const { sections } = orientationChapter
  if (!sections || !sections.length) return

  // Finds the first section URL
  const firstSection = sections[0]
  const { section_uuid: sectionUuid } = firstSection

  const orientationSectionProgress =
    studentProgress[ORIENTATION_SECTION_PROGRESS]

  return orientationSectionProgress[sectionUuid] &&
    orientationSectionProgress[sectionUuid] === 100
}

export const getOrientationChapterSectionUrl = (course) => {
  const { id, chapters } = course
  if (!chapters || !chapters.length) return

  const orientationChapter = chapters.find(chapter => chapter.type === 'orientation')
  if (!orientationChapter) return

  const { sections } = orientationChapter
  if (!sections || !sections.length) return

  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  // Finds the first section URL
  const firstSection = sections[0]
  const { section_uuid: sectionUuid } = firstSection
  return `${courseBaseUrl}/#/${sectionUuid}`
}

export const lastVisitedSections = (sections, studentProgress) => {
  const visitedSections = []
  const {
    LAST_GUESSWORK_UUID,
    LAST_ACTIVE_LEARNING_UUID,
    LAST_QUIZ_UUID,
    SECTION_VIDEO_PROGRESS
  } = PROGRESS_TYPE
  const lastGuessworkUuids =
    Object.keys(studentProgress[LAST_GUESSWORK_UUID] || {})
  const lastActiveLearningUuids =
    Object.keys(studentProgress[LAST_ACTIVE_LEARNING_UUID] || {})
  const lastQuizUuids =
    Object.keys(studentProgress[LAST_QUIZ_UUID] || {})
  const lectureUuids =
    Object.keys(studentProgress[SECTION_VIDEO_PROGRESS] || {})

  sections.forEach((section, index) => {
    const isGuessworkVisited = lastGuessworkUuids.includes(section.section_uuid)
    const isActiveLearningVisited = lastActiveLearningUuids.includes(section.section_uuid)
    const isQuizVisited = lastQuizUuids.includes(section.section_uuid)
    const isLectureVisited = lectureUuids.includes(section.section_uuid)

    const hasVisitedSectionExercise = isGuessworkVisited ||
      isActiveLearningVisited || isLectureVisited ||
      isQuizVisited

    const visistedSection = {
      sectionNo: index + 1,
      sectionUuid: section.section_uuid,
      isGuessworkVisited,
      isActiveLearningVisited,
      isQuizVisited,
      isLectureVisited
    }
    if (hasVisitedSectionExercise) visitedSections.push(visistedSection)
  })

  return visitedSections
}

export const chaptersVisitedSections = (chapters = [], studentProgress) => {
  let chapterNo = 0
  const chaptersVisitedSections = []

  chapters.forEach((chapter) => {
    if (chapter.type !== 'chapter') return
    chapterNo += 1
    const { sections, chapter_uuid: chapterUuid } = chapter
    if (!sections) return null
    const visitedSections = lastVisitedSections(sections, studentProgress)
    chaptersVisitedSections.push({
      chapterNo,
      chapterUuid,
      visitedSections
    })
  })

  return chaptersVisitedSections
}

export const isExamOpen = (currentTime, startTime, endTime) => {
  if (!startTime || !endTime) return
  return currentTime >= startTime && currentTime <= endTime
}

export const isExamUpcoming = (currentTime, startTime) => {
  if (!startTime) return
  return currentTime < startTime
}

export const isExamClosed = (currentTime, cohortEndDate) => {
  if (!currentTime || !cohortEndDate) return
  return currentTime > cohortEndDate
}

/**
 * @returns {boolean} whether all other sections are completed
 * @description A section is considered as complete if at least one
 * quiz is completed in each section
 */
export const allSectionsCompleted = (chapters, studentProgress) => {
  const pureChapters = chapters.filter(chapter => chapter.type === 'chapter')
  return pureChapters.every(chapter => {
    const quizProgress = studentProgress['quiz-percentage']
    const quizCompletedSections = Object.keys(quizProgress)
    const { sections } = chapter
    return sections.every(section =>
      quizCompletedSections.includes(section.section_uuid))
  })
}

export const getExamReviewChapter = (exam, chapters) => {
  if (!exam || !chapters?.length) return null

  const examIndex = chapters.findIndex(chapter => chapter.chapter_uuid === exam.chapter_uuid)
  const previousChapters = chapters.slice(0, examIndex)

  for (let i = previousChapters.length - 1; i >= 0; i--) {
    const chapter = previousChapters[i]
    if (chapter.type === EXAM) return null
    if (chapter.type === 'review') return chapter
  }

  return null
}

export const isRealExam = chapter => {
  return chapter?.type === 'exam'
}

export const getExamFromExamNumber = ({ chapters, examNumber, isFinalExam }) => {
  if (!chapters?.length) return null

  // examNumber can be derived either from course chapters or airtable
  // Eg, when finding closes exam, we loop over all exams starting from mid 1 in airtable,
  // that means for final exam, we may get examNumber 6 from airtable
  // but in course chapters, final exam can be third exam,
  // so we need to find the final exam based on isFinalExam, instead of examNumber
  if (isFinalExam) return chapters.find(chapter => chapter.isFinalExam)

  return chapters.find(chapter => chapter.examNumber === examNumber)
}

export const removeQuizzesFromSection = (data) => {
  if (!data?.['section_exe']?.quiz) return data

  const sectionData = cloneDeep(data)
  delete sectionData.section_exe.quiz
  return sectionData
}

export const removeQuizzesFromChapters = (data) => {
  if (!data?.chapters?.length) return data

  const courseData = cloneDeep(data)
  const newCourseData = {
    ...courseData,
    chapters: courseData.chapters.map(chapter => {
      if (!chapter?.sections?.length) return chapter

      return {
        ...chapter,
        sections: chapter.sections.map(section => {
          delete section.quizUUIDs
          return section
        }) || []
      }
    }) || []
  }

  return newCourseData
}

export const getNextChapter = (chapters, chapterUuid) => {
  const filteredChapters = chapters.filter(
    (chapter) => chapter.type === 'chapter'
  )
  const currentChapterIndex = filteredChapters.findIndex(
    (chapter) => chapter.chapter_uuid === chapterUuid
  )

  return {
    nextChapter: filteredChapters[currentChapterIndex + 1],
    currentChapterIndex
  }
}

export const isOrientationChapter = (chapter) => {
  if (!chapter) return false
  return chapter.type === ORIENTATION
}

export const getChapterUnlockDateBySchedule = ({ schedule, chapter } = {}) => {
  const chapterSchedule = schedule?.find(currentSchedule =>
    currentSchedule?.materialCovered?.includes(
      chapter?.sections?.[0]?.title
    )
  )
  return chapterSchedule?.startDateInSecs || null
}

export const isLastAssessmentOfPreviousUnit = (params) => {
  const { assessmentChapter, courseUnits, currentChapter } = params || {}
  if (!currentChapter || !assessmentChapter || !courseUnits?.length) return false

  const currentChapterUnitIndex = courseUnits.findIndex(unit => {
    return unit?.unitContent?.some(
      content => content?.uuid === currentChapter.chapter_uuid
    )
  })
  const previousUnit = courseUnits[currentChapterUnitIndex - 1]
  const { unitContent } = previousUnit || {}
  const previousUnitLastChapter = unitContent?.[unitContent?.length - 1]
  return previousUnitLastChapter?.uuid === assessmentChapter.chapter_uuid
}

export const getChapterPrevExam = ({ chapterIndex, chapters, courseUnits }) => {
  if (!chapters?.length) return null

  const previousChapters = chapters.slice(0, chapterIndex).reverse()
  if (!courseUnits?.length) {
    return previousChapters?.find(elem => elem.type === EXAM)
  }

  const previousExam = previousChapters.find(chapter => {
    if (chapter.type !== EXAM) return false

    return isLastAssessmentOfPreviousUnit({
      assessmentChapter: chapter,
      courseUnits,
      currentChapter: chapters?.[chapterIndex]
    })
  })

  return previousExam || null
}

export const getChapterPrevAssignment = ({ chapterIndex, chapters, courseUnits }) => {
  if (!courseUnits?.length) return null

  const previousChapters = chapters.slice(0, chapterIndex).reverse()
  const previousAssignment = previousChapters.find(chapter => {
    if (chapter.type !== WRITING_ASSIGNMENT) return false

    return isLastAssessmentOfPreviousUnit({
      assessmentChapter: chapter,
      courseUnits,
      currentChapter: chapters?.[chapterIndex]
    })
  })

  return previousAssignment || null
}

export const getWritingAssignmentLockUnlockDates = (assignment) => {
  let lockDate = null
  let unlockDate = null

  if (!assignment) return { lockDate, unlockDate }

  const {
    lockTime,
    unlockTime
  } = assignment

  lockDate = dateToSecondsSinceEpoch(new Date(lockTime))
  unlockDate = dateToSecondsSinceEpoch(new Date(unlockTime))

  return { lockDate, unlockDate }
}

export const isWritingAssignmentComplete = (assignmentProgress, chapterUUID) => {
  const chapterProgress = assignmentProgress?.[chapterUUID]

  return chapterProgress?.status === 'submitted'
}

export const getPreviousAssessmentLockUnlockDates = (opts) => {
  const {
    examRetake,
    chapterIndex,
    chapters,
    courseUnits,
    cohortData,
    assignmentsProgress,
    cohortMilestones,
    examsCompleted
  } = opts

  // Get the lock date of the previous exam.
  const previousExam = getChapterPrevExam({
    chapterIndex, chapters, courseUnits
  })
  if (previousExam) {
    const { unlockDate, lockDate } = getChapterLockDates({
      chapter: previousExam,
      examRetake,
      cohortData
    })
    const { chapter_uuid: chapterUuid } = previousExam
    const isCompleted = chapterUuid in examsCompleted

    return { unlockDate, lockDate, isCompleted }
  }

  const previousAssignment = getChapterPrevAssignment({
    chapterIndex, chapters, courseUnits
  }) || {}
  const milestone = cohortMilestones?.find(
    milestone => milestone.datoAssignmentUUID === previousAssignment.chapter_uuid
  )

  if (!milestone) return { lockDate: null, unlockDate: null, isCompleted: false }

  const { unlockDate, lockDate } = getWritingAssignmentLockUnlockDates(milestone)

  const isCompleted = isWritingAssignmentComplete(
    assignmentsProgress,
    previousAssignment.chapter_uuid
  )

  return { unlockDate, lockDate, isCompleted }
}

const convertDateStrToSeconds = (dateString) => {
  if (typeof dateString === 'number') return dateString

  return parseInt(dateString, 10)
}

export const getChapterLockCode = (chapterIndex, opts) => {
  const { isAdmin, cohortName } = opts
  const isVipCohort = cohortName && cohortName.includes('VIP')
  if (isAdmin === true && isVipCohort) return false
  const {
    courseId,
    examRetake,
    chapters,
    courseUnits,
    cohortModifier,
    cohortMilestones,
    assignmentsProgress,
    cohortSpecialDays,
    examsCompleted,
    cohortExamDates,
    isAuditor,
    isVIP,
    isVIPGradedContent,
    isStudioCohort,
    cohortID,
    auditContentLock,
    courseResourcesSchedule
  } = opts

  const chapter = chapters?.[chapterIndex]
  const chapterType = chapter?.type
  const isOrientation = isOrientationChapter(chapter)

  const isAuditorAndNotAuditContentLock = isAuditor && !auditContentLock
  const isStudioCohortAndNotExamChapter =
    isStudioCohort && chapterType !== EXAM

  if (
    isAuditorAndNotAuditContentLock ||
    isStudioCohortAndNotExamChapter ||
    isOrientation ||
    isVIPGradedContent
  ) return false

  const currentDate = secondsSinceEpoch()
  const courseUnlockDate = convertDateStrToSeconds(opts.courseUnlockDate)
  const cohortData = {
    cohortID,
    cohortStartDate: courseUnlockDate,
    cohortModifier,
    cohortSpecialDays,
    cohortExamDates
  }

  if (chapter?.type !== CHAPTER) return false
  if (isVIP) return false

  const chapterUnlockDate = getChapterUnlockDateBySchedule({
    schedule: courseResourcesSchedule,
    chapter
  })

  if (chapterUnlockDate && currentDate >= chapterUnlockDate) {
    return false
  }

  const {
    unlockDate = null, lockDate = null, isCompleted
  } = getPreviousAssessmentLockUnlockDates({
    examRetake,
    chapterIndex,
    chapters,
    courseUnits,
    cohortData,
    cohortMilestones,
    assignmentsProgress,
    examsCompleted
  })

  // Unlock the chapter if there is no previous exam or assignment.
  if ((unlockDate === null) && (lockDate === null)) return false

  // Unlock the chapter if the previous exam never relocks and the current
  // date is greater than or equal to the previous exam start data.
  if ((lockDate === null)) {
    if (currentDate >= unlockDate) {
      return false
    } else {
      return PREV_EXAM_START_DATE
    }
  }

  // Unlock the chapter if the previous exam or assignment is completed or the current date
  // is greater than or equal to the exam due date of the previous
  // exam or assignment, otherwise lock it.
  const isAssessmentLocked = currentDate >= lockDate
  const isAssessmentUnlocked = currentDate >= unlockDate
  const { courseIds } = config
  const coursesToLock = [
    courseIds['collegewriting-i'],
    courseIds['collegewriting-ii']
  ]
  const unlockChapterOnAssessmentLock = !coursesToLock.includes(courseId)

  if (
    isCompleted ||
      (unlockChapterOnAssessmentLock ? isAssessmentLocked : isAssessmentUnlocked)
  ) {
    return false
  } else {
    return PREV_EXAM_DUE_DATE
  }
}

export const getChapterLockDatesByExamDates = ({
  cohortId,
  examRetake,
  chapter,
  cohortExamDates,
  isForFinalExam,
  isSingleMidtermExamCourse
}) => {
  const { title } = chapter || {}
  let unlockDate = null
  let lockDate = null

  const isRetakeAllowed = isExamRetakeType({ examRetake, chapter, cohortId })
  if (isRetakeAllowed) {
    const retake = examRetake?.find(retake => retake?.cohort?.id === cohortId)

    const {
      retakeStartDate,
      retakeEndDate
    } = getExamDatesFromExamRetakes({
      exam: chapter,
      examRetake: retake
    })

    if (retakeStartDate && retakeEndDate) {
      unlockDate = retakeStartDate
      lockDate = retakeEndDate
    }

    return {
      unlockDate,
      lockDate
    }
  }

  if (!cohortExamDates) {
    return {
      unlockDate,
      lockDate
    }
  }

  let examStartDate = null
  let examEndDate = null

  const {
    assignmentStartDate,
    assignmentEndDate,
    courseEndDate
  } = cohortExamDates

  if (title === SINGLE_MIDTERM_EXAM && isForFinalExam && assignmentStartDate && assignmentEndDate) {
    examStartDate = assignmentStartDate
    examEndDate = getExamEndDateBasedOnExamExtension(courseEndDate, assignmentEndDate)
  } else if (!title && !isForFinalExam && isSingleMidtermExamCourse && assignmentStartDate && assignmentEndDate) {
    examStartDate = assignmentStartDate
    examEndDate = getExamEndDateBasedOnExamExtension(courseEndDate, assignmentEndDate)
  } else {
    const { startDate, endDate } = getExamDatesFromCohortExamDates({
      exam: chapter,
      cohortExamDates
    })

    examStartDate = startDate
    examEndDate = endDate
  }

  if (examStartDate && examEndDate) {
    unlockDate = examStartDate
    lockDate = examEndDate
  }

  return {
    unlockDate,
    lockDate
  }
}

export const getChapterLockDates = ({
  chapter,
  cohortData,
  examRetake,
  isForFinalExam,
  isSingleMidtermExamCourse
}) => {
  let unlockDate = null
  let lockDate = null

  if (!chapter && !isSingleMidtermExamCourse) {
    return {
      unlockDate,
      lockDate
    }
  }

  const {
    cohortID,
    cohortStartDate,
    cohortModifier,
    cohortSpecialDays,
    cohortExamDates
  } = cohortData

  const chapterLockDates = getChapterLockDatesByExamDates({
    chapter,
    cohortId: cohortID,
    examRetake,
    cohortExamDates,
    isForFinalExam,
    isSingleMidtermExamCourse
  })

  if (chapterLockDates.unlockDate && chapterLockDates.lockDate) {
    unlockDate = chapterLockDates.unlockDate
    lockDate = chapterLockDates.lockDate
  } else if (chapter && Object.prototype.hasOwnProperty.call(chapter, 'unlock_at_week') &&
  Object.prototype.hasOwnProperty.call(chapter, 'unlock_duration')) {
    // Use the new relative dates if they are available. Otherwise, use the
    // old style fixed dates.
    let {
      unlock_at_week: unlockAtWeek,
      unlock_duration: unlockDuration
    } = chapter
    if (cohortModifier) {
      unlockAtWeek = cohortModifier * unlockAtWeek
      if (unlockDuration > 0) unlockDuration = cohortModifier * unlockDuration
    }
    let unlockAfterDays = (unlockAtWeek - 1) * 7
    if (cohortSpecialDays && cohortSpecialDays.length) {
      cohortSpecialDays.forEach(({ startDate, endDate, specialDays }) => {
        if (specialDays && unlockAfterDays >= specialDays) {
          unlockAfterDays = unlockAfterDays + diffDays(startDate, endDate)
        }
      })
    }
    unlockDate = dateToSecondsSinceEpoch(
      calculateUnlockDate(unlockAfterDays, cohortStartDate * 1000)
    )
    // Unlock duration of -1 means it never re-locks and stays unlocked forever.
    if (unlockDuration === -1) {
      lockDate = null
    } else {
      lockDate = dateToSecondsSinceEpoch(
        calculateUnlockDate(
          unlockAfterDays,
          unlockDuration,
          cohortStartDate * 1000
        )
      )
    }
  }

  return {
    unlockDate,
    lockDate
  }
}

export const getExamEndDateBasedOnExamExtension = (courseEndDate, assignmentEndDate) => {
  // If courseEndDate will contain examExtension, so it will be greater than assignmentEndDate
  if (courseEndDate > assignmentEndDate) {
    return courseEndDate
  }
  return assignmentEndDate
}

export const isExamRetakeType = ({
  cohortId,
  examRetake,
  chapter
}) => {
  if (!chapter?.title || !cohortId || !examRetake?.length) return false

  const { course: { examRetakeVersions } } = config
  if (!examRetakeVersions) return false

  const currentRetake = examRetake?.find(retake => retake?.cohort?.id === cohortId)

  const {
    retakeStartDate,
    retakeEndDate
  } = getExamDatesFromExamRetakes({
    exam: chapter,
    examRetake: currentRetake
  })

  return !!(retakeStartDate && retakeEndDate)
}

export const getExamDatesFromExamRetakes = ({ exam, examRetake }) => {
  const {
    finalRetakeStart,
    finalDeadline,
    midterm1RetakeStart,
    midterm1Deadline,
    midterm2Deadline,
    midterm2RetakeStart
  } = examRetake || {}

  const { examNumber, isFinalExam } = exam || {}
  const currentTime = secondsSinceEpoch()

  if (isFinalExam) {
    return {
      retakeStartDate: finalRetakeStart,
      retakeEndDate: finalDeadline,
      isExamUpcoming: isExamUpcoming(currentTime, finalRetakeStart)
    }
  }

  // Existing Midterm 1
  if (examNumber === 1) {
    return {
      retakeStartDate: midterm1RetakeStart,
      retakeEndDate: midterm1Deadline,
      isExamUpcoming: isExamUpcoming(currentTime, midterm1RetakeStart)
    }
  }

  // Existing Midterm 2
  if (examNumber === 2) {
    return {
      retakeStartDate: midterm2RetakeStart,
      retakeEndDate: midterm2Deadline,
      isExamUpcoming: isExamUpcoming(currentTime, midterm2RetakeStart)
    }
  }

  // Other exams template will be: exam{examNumber}RetakeStart and exam{examNumber}RetakeDeadline
  // Example: exam3RetakeStart and exam3RetakeDeadline
  const examStartDateKey = `exam${examNumber}RetakeStart`
  const examEndDateKey = `exam${examNumber}RetakeDeadline`

  return {
    retakeStartDate: examRetake?.[examStartDateKey],
    retakeEndDate: examRetake?.[examEndDateKey],
    isExamUpcoming: isExamUpcoming(currentTime, examRetake?.[examStartDateKey])
  }
}
