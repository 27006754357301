import inRange from 'lodash/inRange'
import _ from 'lodash'
import config from '../../../config'
import { getLastRecommendedSectionId } from '../../../utilities/course'
import { getCourseSections } from '../../../utilities/section'
import {
  getFormattedSchedule,
  getDynamicSchedules,
  getRecommendedProgress
} from '../../../utilities/cohortScheduleUtils'
import {
  diffDays,
  getDateWithoutYear,
  getTimezoneShort,
  secondsSinceEpoch,
  secondsToDateString
} from '../../../utilities/dateTime'
import {
  ACTIVE_LEARNING_COMPLETE,
  QUIZ_SECTION,
  READINGS_PROGRESS,
  READINGS_COMPLETE,
  ASSIGNMENT_PROGRESS,
  PRACTICE_EXERCISES_COMPLETE,
  ACTIVE_LEARNING,
  GUESSWORK,
  CALCULUS_LECTURE_VIDEOS,
  LECTURE_VIDEOS,
  LECTURE_COMPLETE,
  PRACTICE_EXERCISES,
  PRACTICE_TERMS,
  QUIZ,
  EXAM_COMPLETE,
  READINGS,
  CONCEPT_MAP,
  QUIZ_COMPLETE
} from '../../../Constants/studentProgressKeys'
import { BREAK, ESSAY, EXAM } from '../../../Constants/examType'
import { ASSIGNMENT } from '../../../Constants/sectionType'
import { WHITE, GREY, YELLOW, RED } from '../checklistColors'
import { CALCULUS } from '../../../Constants/courseNames'

export const getCompletedActivitiesFromExercise = (
  activity,
  studentProgress
) => {
  const completedActivitiesArray = []
  if (!activity) {
    return completedActivitiesArray
  }
  activity.forEach(({ question_set_uuid: questionSetUUID }) => {
    const isActivityCompleted =
      typeof studentProgress[questionSetUUID] === 'boolean'
        ? studentProgress[questionSetUUID]
        : studentProgress[questionSetUUID] >= 0
    return isActivityCompleted
      ? completedActivitiesArray.push(questionSetUUID)
      : null
  })
  return completedActivitiesArray
}

export const getCompletedActivitiesForPracticeExercise = (
  sectionUUID,
  studentProgress
) => {
  if (!sectionUUID) return false
  return studentProgress[sectionUUID]
}

export const getCompletedLectureVideosFromExercise = (lecture, studentProgress) => {
  const completedLecturesArray = []

  if (!lecture?.videos) {
    return completedLecturesArray
  }
  lecture.videos.forEach(({ kalturaEmbedCode }) => {
    const isLectureComplete = studentProgress[kalturaEmbedCode]
    return isLectureComplete
      ? completedLecturesArray.push(kalturaEmbedCode)
      : null
  })
  return completedLecturesArray
}

function isGuessworkCompleteForSection (studentProgress, sectionId) {
  const guessWorkComplete = studentProgress && studentProgress['guesswork-complete']
  return !!guessWorkComplete && !!guessWorkComplete[sectionId]
}

export const getActivitiesCount = (
  exercise,
  studentProgress,
  courseUuid,
  courseTitle,
  calculateNumberOfRecommendedExercises = true
) => {
  const {
    section_exe: sectionExercise,
    section_uuid: sectionUUID
  } = exercise || {}
  const isCalculus = courseTitle === CALCULUS

  const activitiesCount = {
    [ACTIVE_LEARNING]: 0,
    [GUESSWORK]: 0,
    [QUIZ]: 0,
    [CALCULUS_LECTURE_VIDEOS]: 0,
    [LECTURE_VIDEOS]: 0,
    [PRACTICE_EXERCISES]: 0,
    [PRACTICE_TERMS]: 0,
    [READINGS]: 0,
    [CONCEPT_MAP]: 0
  }

  let numberOfRecommendedExercises = 0

  let sectionHasActiveLearningComplete = false
  let sectionHasGuessworkComplete = false
  const exerciseHasGuesswork = !!sectionExercise?.[GUESSWORK]
  const exerciseHasActiveLearning = !!sectionExercise?.[ACTIVE_LEARNING]
  const exerciseHasQuiz = !!sectionExercise?.[QUIZ]
  const exerciseHasReading = !!sectionExercise?.[READINGS]
  const exerciseHasPracticeExercises = !!sectionExercise?.[PRACTICE_EXERCISES]
  const exerciseHasLectureVideosCalculus = !!sectionExercise?.[
    CALCULUS_LECTURE_VIDEOS
    ]
  const exerciseHasLectureVideos = !!sectionExercise?.[LECTURE_VIDEOS]

  const isGuessworkUnlocked = isGuessworkCompleteForSection(
    studentProgress,
    sectionUUID
  )
  sectionHasGuessworkComplete = isGuessworkUnlocked

  if (exerciseHasGuesswork) {
    if (sectionHasGuessworkComplete) {
      activitiesCount[GUESSWORK] = 1
    }
    numberOfRecommendedExercises++
    if (!sectionHasGuessworkComplete && !calculateNumberOfRecommendedExercises) {
      return [0, 0]
    }
  }
  if (exerciseHasActiveLearning) {
    numberOfRecommendedExercises++
    sectionHasActiveLearningComplete =
      studentProgress[ACTIVE_LEARNING_COMPLETE][sectionUUID]
    if (isCalculus) {
      const completedActiveLearningSections =
        Object.keys(studentProgress[ACTIVE_LEARNING_COMPLETE])
      const isActiveLearningThemeCompleted =
        completedActiveLearningSections.includes(sectionUUID)
      if (isActiveLearningThemeCompleted) {
        activitiesCount[ACTIVE_LEARNING] = 1
      }
    } else {
      if (sectionHasActiveLearningComplete) {
        activitiesCount[ACTIVE_LEARNING] = 1
      }
    }
  }
  if (isCalculus && exerciseHasLectureVideosCalculus) {
    numberOfRecommendedExercises++
    const isCalculusLectureComplete =
      studentProgress[LECTURE_COMPLETE][sectionUUID]

    if (isCalculusLectureComplete) {
      activitiesCount[CALCULUS_LECTURE_VIDEOS] = 1
    }
  }
  if (exerciseHasLectureVideos) {
    const completedLectureVideos = getCompletedLectureVideosFromExercise(
      sectionExercise[LECTURE_VIDEOS],
      studentProgress[LECTURE_COMPLETE]
    )

    activitiesCount[LECTURE_VIDEOS] = completedLectureVideos?.length
    numberOfRecommendedExercises +=
      sectionExercise[LECTURE_VIDEOS]?.videos?.length
  }

  if (exerciseHasQuiz) {
    const completedQuizzes = getCompletedActivitiesFromExercise(
      sectionExercise[QUIZ],
      studentProgress[QUIZ_SECTION]
    )
    numberOfRecommendedExercises++
    if (completedQuizzes?.length > 0) {
      activitiesCount[QUIZ] = 1
    }
  }
  if (exerciseHasReading) {
    const readingsProgress = getCompletedActivitiesFromExercise(
      sectionExercise[READINGS],
      studentProgress[READINGS_PROGRESS]
    )
    numberOfRecommendedExercises += sectionExercise[READINGS]?.length
    const isReadingCompleted = studentProgress[READINGS_COMPLETE][sectionUUID]

    if (isReadingCompleted) {
      activitiesCount[READINGS] = readingsProgress?.length
    }
  }
  if (exerciseHasPracticeExercises) {
    const arePracticeExercisesCompleted =
      getCompletedActivitiesForPracticeExercise(
        sectionUUID,
        studentProgress[PRACTICE_EXERCISES_COMPLETE]
      )
    numberOfRecommendedExercises++
    if (arePracticeExercisesCompleted) {
      activitiesCount[PRACTICE_EXERCISES] = 1
    }
  }

  // Todo: Add practice terms verification once the Epic
  // Standardize Student Progression in Sections and Section Activities is live

  return [Object.values(activitiesCount)
    .reduce((a, b) => a + b, 0), numberOfRecommendedExercises]
}

export const getAllSectionsExercises = (recommendedSections, allSections) => {
  if (!allSections) return []

  const sectionArray = []

  allSections.forEach(section => {
    const { section_uuid: sectionUUID } = section
    if (recommendedSections.includes(sectionUUID)) {
      sectionArray.push(section)
    }
  })
  return sectionArray
}

export const getAllSectionsActivitiesCount = (
  sectionArr,
  studentProgress,
  courseUuid,
  courseTitle,
  calculateNumberOfRecommendedExercises = true
) => {
  let totalActivitiesCount = 0
  let totalRecommendedActivitiesCount = 0
  let lastWorkedSection
  for (const key in sectionArr) {
    const [activitiesCount, recommendedActivitiesCount] = getActivitiesCount(
      sectionArr[key],
      studentProgress,
      courseUuid,
      courseTitle,
      calculateNumberOfRecommendedExercises
    )
    totalActivitiesCount += activitiesCount
    totalRecommendedActivitiesCount += recommendedActivitiesCount
    if (activitiesCount > 0) {
      lastWorkedSection = sectionArr[key]
    }
  }
  return [totalActivitiesCount, totalRecommendedActivitiesCount,
    lastWorkedSection]
}

export const calculateCourseProgress = ({
  courseData,
  courseResourcesSchedule,
  studentProgress,
  isCohortEnded,
  allSectionsData
}) => {
  if (!courseResourcesSchedule) return

  const cohortFormattedSchedules = getFormattedSchedule(courseResourcesSchedule)
  if (!cohortFormattedSchedules.length) return
  const dynamicSchedule = getDynamicSchedules(cohortFormattedSchedules)

  const cohortSchedules = getFormattedSchedule(courseResourcesSchedule)
  const cohortSchedulesLength = cohortSchedules.length
  if (!cohortSchedulesLength) return

  let lastSection = dynamicSchedule.find(
    ({ schedules }) => schedules?.length
  )
  let isTermBreakLastSection = false
  let hasAssessmentAfterTermBreak = false
  if (lastSection?.assignmentType === BREAK) {
    isTermBreakLastSection = true
    lastSection = dynamicSchedule.find(
      ({ schedules, assignmentType }) => assignmentType !== BREAK && schedules?.length
    )
  }
  if (!lastSection || isCohortEnded) {
    lastSection = courseResourcesSchedule[courseResourcesSchedule.length - 1]
  }

  const courseSections = getCourseSections(courseData)
  const { chapters } = courseData || {}

  const lastRecommendedSection = getLastRecommendedSectionId(
    lastSection,
    chapters
  )
  const indexOfLastRecommendedSection = courseSections.indexOf(
    lastRecommendedSection
  )
  const allRecommendedSections = courseSections.slice(
    0,
    isTermBreakLastSection
      // slice till term break and exclude the last section schedules
      ? indexOfLastRecommendedSection - lastSection?.schedules?.length + 1
      : indexOfLastRecommendedSection + 1
  )
  if (allRecommendedSections.length === 0) return

  const recommendedProgressSections = getAllSectionsExercises(
    allRecommendedSections,
    allSectionsData

  )
  if (recommendedProgressSections.length === 0) return
  const { title: courseTitle, course_uuid: courseUuid } = courseData
  const [completedActivitiesCount, recommendedActivitiesCount] = getAllSectionsActivitiesCount(
    recommendedProgressSections,
    studentProgress,
    courseUuid,
    courseTitle
  )
  const activityCount = recommendedActivitiesCount - completedActivitiesCount
  const isCaughtUp = activityCount === 0
  const aheadSectionIds = courseSections.slice(
    indexOfLastRecommendedSection + 1,
    courseSections.length - 1
  )
  const aheadSections = getAllSectionsExercises(
    aheadSectionIds,
    allSectionsData
  )
  let isWorkingAhead = false
  let hasWorkedAheadUntilExam = false
  let workingAheadWeekToDisplay = null
  let weekProgress = null
  const localStorageKey = `${courseUuid}_hasWorkedAhead`
  const hasWorkedAhead = localStorage.getItem(localStorageKey)
  if (isCaughtUp) {
    hasAssessmentAfterTermBreak = isTermBreakLastSection
      ? [EXAM, ESSAY].includes(lastSection?.schedules?.[0]?.assignmentType)
      : false
    const [numberOfWorkedAheadActivities, , lastWorkedSection] =
      getAllSectionsActivitiesCount(
        aheadSections,
        studentProgress,
        courseUuid,
        courseTitle,
        false
      )
    isWorkingAhead =
      numberOfWorkedAheadActivities < 1 ? false : numberOfWorkedAheadActivities

    if (isWorkingAhead) {
      if (!hasWorkedAhead) {
        localStorage.setItem(localStorageKey, 'true')
      }
      hasWorkedAheadUntilExam = getHasWorkedAheadUntilExam(
        dynamicSchedule,
        lastSection,
        chapters,
        courseSections,
        allSectionsData,
        indexOfLastRecommendedSection,
        studentProgress,
        courseUuid,
        courseTitle
      )
      workingAheadWeekToDisplay = getWorkingAheadWeekToDisplay(
        cohortSchedules,
        studentProgress,
        lastWorkedSection
      )
    }
  }
  if (!isCaughtUp && hasWorkedAhead) {
    weekProgress = getCurrentRecommendedWeekProgress(
      cohortSchedules,
      chapters,
      courseSections,
      allSectionsData,
      lastSection,
      indexOfLastRecommendedSection,
      studentProgress,
      courseUuid,
      courseTitle
    )
  }
  const commonSectionsProgress = []
  const { minimumSectionProgress } = studentProgress || {}
  Object.keys(minimumSectionProgress).forEach(key => {
    if (allRecommendedSections.indexOf(key) !== -1) {
      commonSectionsProgress.push(minimumSectionProgress[key])
    }
  })
  const getCommonSectionsProgress = () => {
    if (commonSectionsProgress.length) {
      let progress = _.sum(commonSectionsProgress) / courseSections.length
      progress = Math.round(progress)
      return progress
    }
  }

  const getProgressValue = () => {
    let progress = completedActivitiesCount / recommendedActivitiesCount * 100
    progress = Math.round(progress)
    return progress
  }
  return {
    activityCount,
    isCaughtUp,
    isWorkingAhead,
    hasWorkedAheadUntilExam,
    workingAheadWeekToDisplay,
    hasAssessmentAfterTermBreak,
    assessmentAfterTermBreakType: lastSection?.schedules?.[0]?.assignmentType,
    weekProgress,
    progress: getProgressValue(),
    fullCourseProgress: getCommonSectionsProgress()
  }
}

const getCurrentRecommendedWeekProgress = (
  cohortSchedules,
  chapters,
  courseSections,
  allSectionsData,
  lastSection,
  indexOfLastRecommendedSection,
  studentProgress,
  courseUuid,
  courseTitle

) => {
  const lastSectionIndex = cohortSchedules.indexOf(lastSection)
  const previousLastSection = cohortSchedules[lastSectionIndex - 1]
  const previousLastRecommendedSection = getLastRecommendedSectionId(
    previousLastSection,
    chapters
  )
  const indexOfPreviousLastRecommendedSection = courseSections.indexOf(
    previousLastRecommendedSection
  )
  const currentWeekRecommendedSectionsIds = courseSections.slice(
    indexOfPreviousLastRecommendedSection + 1,
    indexOfLastRecommendedSection + 1
  )
  const currentWeekRecommendedSections = getAllSectionsExercises(
    currentWeekRecommendedSectionsIds,
    allSectionsData
  )
  const [completedActivitiesCount] = getAllSectionsActivitiesCount(
    currentWeekRecommendedSections,
    studentProgress,
    courseUuid,
    courseTitle
  )
  return completedActivitiesCount
}

const getWorkingAheadWeekToDisplay = (
  cohortSchedules,
  studentProgress,
  lastWorkedSection
) => {
  const lastWorkedSchedule = cohortSchedules.find(
    schedule => {
      if (schedule.materialCovered) {
        return schedule?.materialCovered.includes(lastWorkedSection.title)
      }
      return null
    }
  )
  if (!lastWorkedSchedule) { return null }
  const cohortSchedulesLength = cohortSchedules.length
  let workingAheadWeekToDisplay = lastWorkedSchedule.week - 1
  if (lastWorkedSchedule.week !== cohortSchedulesLength) {
    const schedulesLength = lastWorkedSchedule.schedules.length
    const isLastSectionInSchedule =
      lastWorkedSchedule.schedules[schedulesLength - 1]
        .materialCovered === lastWorkedSection.title
    if (isLastSectionInSchedule) {
      const completedQuizzes = Object.keys(studentProgress[QUIZ_COMPLETE])
      const isSectionCompleted = completedQuizzes
        .includes(lastWorkedSection.section_uuid)
      if (isSectionCompleted) {
        workingAheadWeekToDisplay = workingAheadWeekToDisplay + 1
      }
    }
  }
  return workingAheadWeekToDisplay
}

const getHasWorkedAheadUntilExam = (
  dynamicSchedule,
  lastSection,
  chapters,
  courseSections,
  allSectionsData,
  indexOfLastRecommendedSection,
  studentProgress,
  courseUuid,
  courseTitle
) => {
  const indexOfLastDynamicScheduleSection =
    dynamicSchedule.indexOf(lastSection)
  const futureDynamicSchedule =
    dynamicSchedule.slice(indexOfLastDynamicScheduleSection)
  const nextScheduledExam = futureDynamicSchedule
    .findIndex(schedule =>
      schedule.assignmentType === EXAM ||
      schedule.assignmentType === ESSAY
    )

  const lastScheduleBeforeExamIndex = nextScheduledExam - 1
  const lastScheduleBeforeExam =
    futureDynamicSchedule[lastScheduleBeforeExamIndex]
  const lastSectionBeforeExam = getLastRecommendedSectionId(
    lastScheduleBeforeExam,
    chapters
  )
  const indexOfLastRecommendedSectionBeforeExam = courseSections.indexOf(
    lastSectionBeforeExam
  )
  const sectionsBeforeExamIds = courseSections.slice(
    indexOfLastRecommendedSection,
    indexOfLastRecommendedSectionBeforeExam + 1
  )
  const sectionsBeforeExam = getAllSectionsExercises(
    sectionsBeforeExamIds,
    allSectionsData
  )
  const [
    completedActivitiesCountBeforeExam,
    activitiesCountBeforeExam
  ] = getAllSectionsActivitiesCount(
    sectionsBeforeExam,
    studentProgress,
    courseUuid,
    courseTitle
  )
  const incompleteActivities =
    activitiesCountBeforeExam - completedActivitiesCountBeforeExam
  return incompleteActivities === 0
}

export const getActiveAssessmentWarningColor = (endDate, isNoWarnings = false) => {
  if (isNoWarnings) return GREY
  const ASSESSMENT_48HRS_TO_CLOSE = 2
  const ASSESSMENT_24HRS_TO_CLOSE = 1
  const diff = diffDays(secondsSinceEpoch(), endDate, false)
  if (diff >= ASSESSMENT_48HRS_TO_CLOSE) return GREY
  if (diff < ASSESSMENT_48HRS_TO_CLOSE && diff >= ASSESSMENT_24HRS_TO_CLOSE) {
    return YELLOW
  }
  return RED
}

export const getActiveAssessmentsLabelAndColor = (
  courseResourcesSchedule,
  courseData,
  studentProgress,
  activeCourseUUID
) => {
  if (!courseResourcesSchedule || !courseData) return null
  const activeAssessments = []

  const activeSections = getActiveSchedule(courseResourcesSchedule)
  activeSections.forEach(schedule => {
    const isExam = schedule?.assignmentType === EXAM
    const isEssay = schedule?.assignmentType === ESSAY
    const { chapters, isLiveProctoring } = courseData || {}
    if (isExam) {
      const exams = chapters.filter(chapter => chapter.type === EXAM)
      const activeExam = exams.find(exam => exam.examNumber === schedule.examNumber)
      const chapterUUID = activeExam ? activeExam.chapter_uuid : null
      if (chapterUUID) {
        const isExamSubmitted = studentProgress[EXAM_COMPLETE][chapterUUID]
        if (!isExamSubmitted) {
          const timeZone =
            getTimezoneShort(secondsToDateString(schedule?.endDateInSecs))
          const endDateTime = getDateWithoutYear(schedule?.endDateInSecs)
            .replace(', ', ' at ') + ` ${timeZone}`
          const coverTitle =
            schedule?.materialCovered?.split('Sections')?.[1]?.trim() ||
            schedule?.materialCovered
          const examColor = getActiveAssessmentWarningColor(
            schedule?.endDateInSecs, isLiveProctoring
          )
          const extensionRequestLink =
            `${config.courseBaseUrlById(activeCourseUUID)}/#/extension-request/${chapterUUID}`
          const extendDeadlineLink = examColor === RED
            ? extensionRequestLink : ''
          activeAssessments.push({
            endDateTime,
            coverTitle,
            assessmentColor: examColor,
            type: 'Exam',
            extendDeadlineLink
          })
        }
      }
    }
    if (isEssay) {
      let { title, chapterUuid = '' } = schedule
      const assignments = chapters.filter(
        chapter => chapter.type === 'WritingAssignmentChapterRecord'
      )
      const activeAssignment = assignments.find(assignment => {
        // in some cases the title contains extra space or dashes
        title = title.replace(/\s/g, '').toLowerCase()
        assignment.title = assignment.title.replace(/-|\s/g, '').toLowerCase()
        return assignment.chapter_uuid === chapterUuid || assignment.title.includes(title)
      })
      const chapterUUID = activeAssignment ? activeAssignment.chapter_uuid : ''
      if (chapterUUID) {
        const isAssignmentNotSubmitted = !studentProgress ||
        studentProgress[ASSIGNMENT_PROGRESS]?.[chapterUUID]?.['status'] !==
          'submitted'
        if (isAssignmentNotSubmitted) {
          const timeZone =
            getTimezoneShort(secondsToDateString(schedule?.endDateInSecs))
          const endDateTime = getDateWithoutYear(schedule?.endDateInSecs)
            .replace(', ', ' at ') + ` ${timeZone}`
          const assignmentColor = getActiveAssessmentWarningColor(
            schedule?.endDateInSecs
          )
          const extensionRequestLink =
            `${config.courseBaseUrlById(activeCourseUUID)}/#/extension-request/${chapterUUID}`
          const extendDeadlineLink = assignmentColor === RED
            ? extensionRequestLink : ''
          activeAssessments.push({
            endDateTime,
            assessmentColor: assignmentColor,
            type: ASSIGNMENT,
            finalAssignment: schedule?.finalAssignment,
            extendDeadlineLink
          })
        }
      }
    }
  })
  return activeAssessments
}

export function getProgressBarColor (
  courseProgress,
  courseResourcesSchedule,
  showDefaultColor = false
) {
  if (showDefaultColor) return WHITE

  const {
    values: { currentValueRP, lastWeekValueRP, lastTwoWeeksValueRP }
  } = getRecommendedProgress(courseResourcesSchedule)

  const onCurrentWeek =
    courseProgress >= lastWeekValueRP && courseProgress < currentValueRP
  if (onCurrentWeek) return WHITE

  const isMoreThanOneWeekLate =
    courseProgress >= lastTwoWeeksValueRP && courseProgress < lastWeekValueRP
  if (isMoreThanOneWeekLate) return YELLOW

  const isMoreThanTwoWeeksLate = courseProgress < lastTwoWeeksValueRP
  if (isMoreThanTwoWeeksLate) return RED
}

export const getFormattedCourseList = courseList => {
  if (!courseList?.length) return null
  const filteredCourses = courseList.filter(
    ({ isCohortEnded }) => isCohortEnded !== true
  )

  const sortByActivities = filteredCourses.sort((a, b) =>
    a.activityCount === b.activityCount ? 0
      : ((b.activityCount || 0) - (a.activityCount || 0))
  )

  const sortByActiveAssignment = sortByActivities.sort((a, b) =>
    a?.activeAssessments?.length === b?.activeAssessments?.length ? 0
      : ((b?.activeAssessments?.length || 0) - (a?.activeAssessments?.length || 0))
  )

  const sortByCaughtUp = sortByActiveAssignment.sort((a, b) =>
    a.isCaughtUp - b.isCaughtUp)

  const sortByWorkingAhead = sortByCaughtUp.sort((a, b) =>
    a?.isCaughtUp ? a.isWorkingAhead - b.isWorkingAhead : b.isWorkingAhead - a.isWorkingAhead)

  return sortByWorkingAhead.sort((a, b) =>
    a.hasCohortStarted === b.hasCohortStarted ? 0 : a.hasCohortStarted ? -1 : 1
  )
}

export const getActiveSchedule = (courseResourcesSchedule) => {
  const currentTimeInSecs = secondsSinceEpoch()
  return courseResourcesSchedule?.filter(
    schedule =>
      inRange(
        currentTimeInSecs,
        schedule.startDateInSecs,
        schedule.endDateInSecs + 1
      )
  )
}
