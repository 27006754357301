import styled from 'styled-components'

export const DetailWrapper = styled.div`
  margin-bottom: 24px;
`

export const DetailHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  & > h6 {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 0px;
    line-height: 19px;
    color: #ffffff;
  }
`

export const CheckListWrap = styled.div`
  display: flex;
  align-items: center;
  & > p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #b1bfc5;
    margin-left: 5px;
    margin-bottom: 0px;
  }
`
export const CaughtUpWrap = styled.div`
  display: flex;
  align-items: center;
  & > p {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 19px;
    color: #969BFF;
  }
`

export const CourseStatus = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  margin-bottom: 0px;
  margin-top: 16px;
  width: 100%;
`
export const FinalNoteTitle = styled.p`
  margin-bottom: 0;
`
export const FinalNoteDescription = styled.p`
  padding-top: 16px;
`
