import React from 'react'
import {
  CourseDisclosure,
  CourseTitle,
  DisclosureArrow,
  FinalGradeText,
  FlexDiv,
  MobileCTAContainer,
  MobileCourseTag
} from './style'
import { getLatestCohort } from '../../../utilities/course'
import config from '../../../config'
import { getCourseTag, getFinalGradeText } from '../utils'
import { PrimaryButton } from '../style'

function MobileFinishedCourses ({ finishedCourses, courseProjectedGrades }) {
  return finishedCourses.map((course) => {
    const { id, displayName } = course
    const latestCohort = getLatestCohort(course)
    const { hideStudentGradeDisplay, cohortName } = latestCohort || {}
    const { hasInTimeGradeFlag, courseBaseUrlById } = config

    const courseTag = getCourseTag(course)
    const courseLink = courseBaseUrlById(id)
    const isGGUCohort = cohortName?.toLowerCase().includes('ggu')

    const isGradeDisplayOff = hideStudentGradeDisplay && hasInTimeGradeFlag
    const finalGradeText = getFinalGradeText(course, courseProjectedGrades)

    return (
      <CourseDisclosure key={id} data-testid='mobile-finished-courses'>
        <summary>
          <FlexDiv>
            <FlexDiv flexDirection='column' flex='1 1 auto'>
              <FlexDiv>
                <CourseTitle>{displayName}</CourseTitle>
                {courseTag && (
                  <MobileCourseTag>&nbsp;({courseTag})</MobileCourseTag>
                )}
              </FlexDiv>
              {!isGGUCohort && !isGradeDisplayOff && (
                <FinalGradeText className='final-grade'>
                  {finalGradeText}
                </FinalGradeText>
              )}
            </FlexDiv>
            <DisclosureArrow />
          </FlexDiv>
        </summary>
        <MobileCTAContainer>
          <PrimaryButton onClick={() => (window.location.href = courseLink)}>
            Course Home
          </PrimaryButton>
        </MobileCTAContainer>
      </CourseDisclosure>
    )
  })
}

MobileFinishedCourses.displayName = 'MobileFinishedCourses'
export default MobileFinishedCourses
