import React from 'react'
import UpcomingCard from './UpcomingCard'
import InProgressCard from './InProgressCard'
import CompletedCard from './CompletedCard'
import StudioCard from './StudioCard'
import CardInfo from './CardInfo'

import {
  isStudioCohort,
  getLatestCohort
} from '../../utilities/course'

import { AUDIT, WITHDRAW } from '../../Constants/studentStatus'

export const RenderCard = ({
  course,
  courseTermState,
  handleCourses,
  currentTime
}) => {
  if (isStudioCohort(course)) {
    return <StudioCard course={course} />
  }

  const latestCohort = getLatestCohort(course)
  const noAssessments = latestCohort &&
    [AUDIT, WITHDRAW].includes(latestCohort.studentStatus)
  return (
    <>
      {courseTermState === 'isUpcoming' &&
        <div data-testid='upcoming-card'>
          <UpcomingCard
            latestCohort={latestCohort}
            course={course}
          />
        </div>}
      {courseTermState === 'inProgress' &&
        <div data-testid='in-progress-card'>
          <InProgressCard
            course={course}
            currentTime={currentTime}
            noAssessments={noAssessments}
          />
        </div>}
      {courseTermState === 'termCompleted' &&
        <div data-testid='completed-card'>
          <CompletedCard
            course={course}
            handleCourses={handleCourses}
          />
        </div>}
    </>
  )
}

export const RenderNoCoursesCard = () => {
  return (
    <>
      <CardInfo
        dataTestId='no-courses-card'
        course={{}}
        description={
          <>
            {'You’re out of courses! Browse our course catalog on '}
            <a href='https://outlier.org' rel='noopener noreferrer'>
              Outlier.org
            </a>
            {' to get started with another class'}
          </>
        }
        primaryBtnTxt='Browse'
        handlePrimaryButton={() => { window.location.href = 'https://outlier.org' }}
      />
    </>
  )
}
