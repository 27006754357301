import React, { useContext, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import TokensAndCart from '../CourseCatalog/TokensAndCart'
import {
  PageWrapper,
  BreadCrumbComponent,
  PageTitleComponent,
  PageContentComponent,
  TakeText,
  OptionBox,
  OptionHeading,
  InnerCard,
  PretextCard,
  LockImg,
  ImageBox,
  BackButton,
  CardLoader
} from './style'
import LockIcon from '../../assets/icons/lock.svg'
import UnLockIcon from '../../assets/icons/unlock.svg'
import CourseCard from '../CourseCatalog/CourseCard'
import { AppContext } from '../ContextProvider/ContextProvider'
import api from '../../api'
import { PrimaryButton } from '../Buttons/style'
import {
  getDateStringWithShortMonth,
  isDateBeforeToday
} from '../../utilities/dateTime'
import {
  passedPrerequisite,
  generateCalendarLink,
  getRecentAttempt,
  hasIncompletePretest
} from '../../utilities/pretestUtils'
import { getActiveTokens } from '../../utilities/tokenUtils'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { pretestResult } from '../../Constants/pretest'
import { addInfoForCourses } from '../../utilities/chapterUtils'
import { getCurrentCourse } from '../../utilities/course'
import { CONTENT } from '../../Constants/accountArea'
import { useLocation } from 'react-router-dom'
import {
  getAlternativeCoursesDetails,
  getLevelUpCourses,
  getRecommendedCourses
} from '../../utilities/enrollment'
import config from '../../config'

const Alternatives = ({ history }) => {
  const {
    courses,
    activeCourses,
    coursesProgress,
    tokens,
    courseProjectedGrades
  } = useContext(AppContext)
  const [pretestAttempt, setPretestAttempt] = useState([])
  const [pretestData, setPretestData] = useState()
  const [isProgress, setIsProgress] = useState()
  const [loading, setLoading] = useState(false)
  const [currentCourse, setCurrentCourse] = useState(null)
  const { pathname } = useLocation()
  const startIndex = '/catalog/'.length
  const courseIdFromUrl = pathname.slice(
    startIndex,
    pathname.lastIndexOf('/')
  )
  const courseNameFromUrl = config.courseIdToName(courseIdFromUrl)
  const levelUpCourses = courses && getLevelUpCourses(courseNameFromUrl, courses)
  const recommendedCourses = courses && getRecommendedCourses(courseNameFromUrl, courses)

  useEffect(() => {
    getPretestAttempts()
  }, [])

  useEffect(() => {
    if (isEmpty(courses) || isEmpty(activeCourses)) return

    const course = getCurrentCourse(courses) || getCurrentCourse(activeCourses)
    setCurrentCourse(course)
  }, [courses, activeCourses])

  useEffect(() => {
    if (!currentCourse || !courseProjectedGrades) return

    const checkPretestCompletion = async () => {
      const { chapters, pretest } = currentCourse

      setLoading(true)
      const courseWithChapters = chapters && pretest
        ? currentCourse
        : await addInfoForCourses([currentCourse])?.[0]
      setLoading(false)

      const {
        id,
        pretest: {
          prerequisite,
          minimumPretestScore
        } = {}
      } = courseWithChapters || {}

      const hasPassedPrerequisite = passedPrerequisite({
        courseProjectedGrades,
        studentCourses: courses,
        prerequisite,
        minimumPretestScore
      })
      if (hasPassedPrerequisite) return gotoCatalog()

      const recentAttempt = getRecentAttempt(pretestAttempt, id)
      const { testResult } = recentAttempt || {}
      if (testResult !== pretestResult.PASS) return

      gotoCatalog()
    }

    checkPretestCompletion()

    // eslint-disable-next-line
  }, [pretestAttempt, currentCourse, courseProjectedGrades])

  const gotoCatalog = () => {
    history.push({
      pathname: '/catalog',
      state: {
        pretestComplete: true,
        courseName: currentCourse?.displayName
      }
    })
  }

  const getPretestAttempts = async () => {
    setLoading(true)
    const result = await api.getPretestAttempts('attempts')
    setLoading(false)
    setPretestAttempt(result)
  }
  const params = window?.location?.hash?.split('/')[2]

  useEffect(() => {
    if (isEmpty(currentCourse) || !pretestAttempt?.length) {
      return
    }

    const recentAttempt = getRecentAttempt(
      pretestAttempt, currentCourse?.id
    )
    setPretestData(recentAttempt)

    const incompletePretest = hasIncompletePretest(recentAttempt)
    setIsProgress(incompletePretest)
    // eslint-disable-next-line
  }, [currentCourse, pretestAttempt, coursesProgress]);

  const dateBeforeToday = isDateBeforeToday(pretestData?.nextAvailable)
  const availableTokens = getActiveTokens(tokens)
  const testResult = pretestData?.testResult === pretestResult.FAIL
  const { displayName } = currentCourse || {}

  if (isEmpty(courses) || !courseProjectedGrades) return <LoadingSpinner />

  const filteredLevelUpCourses = getAlternativeCoursesDetails(
    levelUpCourses, activeCourses
  )

  const filteredRecommendedCourses = getAlternativeCoursesDetails(
    recommendedCourses, activeCourses
  )

  const hasLevelUpCourses = filteredLevelUpCourses?.length > 0

  return (
    <PageWrapper data-testid='wrapper'>
      <BreadCrumbComponent path='/catalog' title='catalog' />
      <PageTitleComponent
        title={`${displayName} options`}
        className='flex-container'
      >
        <TokensAndCart
          backToPageName={`${displayName} options`}
          tokens={availableTokens?.length || 0}
        />
      </PageTitleComponent>
      <PageContentComponent data-testid='page-content-container'>
        <TakeText data-testid='mail-text'>
          To take {displayName} for credit, you must pass the pretest, but you also
          have other options! If you need help choosing one of the options
          below, contact <a href='/'> hello@outlier.org</a>.
        </TakeText>
        <OptionBox data-testid='option-box'>
          <OptionHeading data-testid='option-one-heading'>
            Option 1: Take {displayName} for credit
          </OptionHeading>
          {loading ? (
            <CardLoader>
              <FontAwesomeIcon icon={faSpinner} spin />
            </CardLoader>
          ) : (
            <>
              <InnerCard data-testid='inner-card'>
                {testResult && dateBeforeToday
                  ? `The ${displayName} pretest is available again! If you've brushed up on the basics, dive back in and give it another shot.`
                  : isProgress || !testResult
                    ? `The ${displayName} pretest is available whenever you're ready for it. You can take it once every 7 days.`
                    : testResult && pretestData?.nextAvailable &&
                    'You can take the pretest once every 7 days. We encourage you to brush up on the basics and try again soon!'}
              </InnerCard>
              <PretextCard data-testid='pretext-card'>
                {testResult && dateBeforeToday ? (
                  <>
                    <LockImg src={UnLockIcon} alt='unlock-icon' />
                    The pretest is unlocked{' '}
                    <PrimaryButton
                      onClick={() => history.push(`/catalog/${params}/pretest`)}
                    >
                      Take the test
                    </PrimaryButton>
                  </>
                ) : testResult && pretestData?.nextAvailable ? (
                  <>
                    <LockImg src={LockIcon} alt='lock-icon' />
                    The pretest unlocks{' '}
                    {getDateStringWithShortMonth(
                      pretestData?.nextAvailable
                    )}{' '}
                    <span
                      onClick={() =>
                        generateCalendarLink({ ...currentCourse, pretestData })}
                    >
                      Add to calendar
                    </span>
                  </>
                ) : (
                  <>
                    <LockImg src={UnLockIcon} alt='' />
                    The pretest is unlocked{' '}
                    <PrimaryButton
                      onClick={() => history.push(`/catalog/${params}/pretest`)}
                    >
                      Take the test
                    </PrimaryButton>
                  </>
                )}
              </PretextCard>
            </>
          )}
        </OptionBox>
        {hasLevelUpCourses && (
          <OptionBox>
            <OptionHeading data-testid='level-heading'>
              Option 2: Level up your {displayName} knowledge in another way
            </OptionHeading>
            <ImageBox data-testid='image-box'>
              {filteredLevelUpCourses.map((item, index) => (
                <CourseCard
                  alternatives
                  course={item}
                  className='course-card'
                  key={index}
                  history={history}
                />
              ))}
            </ImageBox>
          </OptionBox>
        )}
        {filteredRecommendedCourses?.length > 0 && (
          <OptionBox>
            <OptionHeading data-testid='explore-heading'>
              Option {hasLevelUpCourses ? '3' : '2'}: Explore our other cinematic math courses
            </OptionHeading>
            <ImageBox>
              {filteredRecommendedCourses
                .map((item, index) => (
                  <CourseCard
                    alternatives
                    course={item}
                    className='course-card'
                    key={index}
                    history={history}
                  />
                ))}
            </ImageBox>
          </OptionBox>
        )}
        <BackButton
          data-testid='back-button'
          onClick={() => { history.push('/catalog') }}
        >
          Back to full Catalog
        </BackButton>
        <OptionBox>
          <TakeText style={{ fontWeight: 'normal' }}>
            If you need to return the token you bought for {displayName}, you can{' '}
            <a href={`#/${CONTENT.REFUND_TOKEN}`}> request a refund</a>
            .
          </TakeText>
        </OptionBox>
      </PageContentComponent>
    </PageWrapper>
  )
}

export default Alternatives
