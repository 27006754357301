import React, { useContext } from 'react'
import { LESSON0_VIEWED } from '../../Constants'
import { AppContext } from '../ContextProvider/ContextProvider'
import { IFrame } from './styled'

const OnboardingChapter = () => {
  const {
    studentData,
    isGGUDegreeDataLoading,
    isActiveGGUStudent
  } = useContext(AppContext) || {}

  if (isGGUDegreeDataLoading || !isActiveGGUStudent) return null
  if (!studentData || studentData[LESSON0_VIEWED]) return null

  return (
    <IFrame
      id='lesson0Frame'
      data-testid='iframelesson0'
      inPageLinks
      log
      src='https://welcome.psychology.outlier.org/?flag-lesson0=true&hideHeaderFooter=true#/'
      style={{ width: '1px', minWidth: '100%' }}
      checkOrigin={false}
    />
  )
}

OnboardingChapter.displayName = 'OnboardingChapter'

export default OnboardingChapter
