import React, { useContext } from 'react'
import { AppContext } from '../ContextProvider/ContextProvider'
import { BannerContainer, NeedsReviewMessage, WarningIcon } from './styles'
import { getNeedsReviewingAttempts } from '../../utilities/course'
import { NEEDS_REVIEWING_DEGREE } from '../../Constants/studentStatus'

const Warning =
  <WarningIcon
    src='images/icons/warning-icon.svg'
    alt='warning-needs-review'
  />

function NeedsReviewBanner () {
  const { studentAttempts } = useContext(AppContext)

  const needsReviewingAttempts = getNeedsReviewingAttempts(studentAttempts)
  if (!needsReviewingAttempts?.length) return null

  const degreeAttempts = needsReviewingAttempts.filter(attempt => {
    return attempt?.fields?.studentStatus?.fields?.Name === NEEDS_REVIEWING_DEGREE
  })
  const hasNeedsReviewingDegree = !!degreeAttempts?.length

  const courseName = hasNeedsReviewingDegree
    ? degreeAttempts.length === 1
      ? degreeAttempts?.[0].fields?.course?.fields?.displayName
      : ''
    : needsReviewingAttempts.length === 1
      ? needsReviewingAttempts[0].fields?.course?.fields?.displayName
      : ''

  return (
    <BannerContainer>
      {hasNeedsReviewingDegree
        ? (
          <NeedsReviewMessage>
            {Warning}
            You have purchased {courseName || 'multiple courses'} outside of your degree program. Please{' '}
            {/* eslint-disable-next-line */}
            <a
              href='https://help.outlier.org/hc/en-us/requests/new'
              rel='noopener noreferrer'
              target='_blank'
              data-testid='advisorLink'
            >
              speak with an Advisor
            </a> to discuss your options.
          </NeedsReviewMessage>
        ) : (
          <NeedsReviewMessage>
            {Warning}
            {courseName
              ? `You have attempted to enroll in the same ${courseName} cohort twice.`
              : 'You have attempted to enroll in the same cohort twice for multiple courses.'} Please contact{' '}
            <a
              href='https://help.outlier.org/hc/en-us/requests/new'
              rel='noopener noreferrer'
              target='_blank'
              data-testid='studentSuccessLink'
            >
              Student Success
            </a> to resolve this issue.
          </NeedsReviewMessage>
        )}
    </BannerContainer>
  )
}

export default NeedsReviewBanner
