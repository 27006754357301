import React, { useState } from 'react'
import {
  dateToSecondsSinceEpoch,
  secondsSinceEpoch
} from '../../../utilities/dateTime'
import { Separator } from '../style'
import SubmittedCard from './SubmittedCard'
import UnsubmittedCard from './UnsubmittedCard'
import OpenUnsubmittedCard from './OpenUnsubmittedCard'
import GradedCard from './GradedCard'

const WritingAssignmentsCard = ({
  course,
  courseProgress,
  assignmentsData,
  dismissAssignments
}) => {
  const [isUnsubmittedCardVisible, setIsUnsubmittedCardVisible] = useState(true)
  const { chapters } = course
  const { studentProgress } = courseProgress
  const currentTime = secondsSinceEpoch()
  let hasOpenUnsubmittedAssignment = false
  let hasSubmittedUngradedAssignment = false
  let hasClosedAssignment = false
  let hasUnsubmittedClosedAssignment = false
  let hasGradedAssignment = false
  const displayedMessages = []
  const assignmentsDatoIds = assignmentsData.map(
    assignment => assignment.datoAssignmentUUID
  )

  const assignmentPrefixRegEx = /Assignment [0-9]+: /
  const assignments = chapters.map((chapter, index) => {
    return { ...chapter, chapterNumber: index + 1 }
  })
    .filter(chapter => {
      const { hideFileUploadField, hideTextEntryField } = chapter
      const isOptionalAssignment = hideFileUploadField && hideTextEntryField
      return (
        !isOptionalAssignment &&
        chapter.type === 'WritingAssignmentChapterRecord' &&
        assignmentsDatoIds.includes(chapter.chapter_uuid)
      )
    })
    .map(chapter => {
      let { title } = chapter
      const { updateddisplaytitle } = chapter
      title = updateddisplaytitle || title

      return {
        ...chapter,
        title: title.replace(assignmentPrefixRegEx, '')
      }
    })

  const progressData = studentProgress['assignment-progress']

  const dismissUnsubmittedCard = assignments => {
    setIsUnsubmittedCardVisible(false)
    assignments.forEach((assignment) => {
      dismissAssignments(assignment.id)
    })
  }

  const addMilestonesData = assignment => {
    const { chapter_uuid: assignmentUUID } = assignment
    const deadlineData = assignmentsData.find(currentAssignment =>
      currentAssignment.datoAssignmentUUID === assignmentUUID)
    if (!deadlineData) return

    const unlockTime = dateToSecondsSinceEpoch(new Date(deadlineData.unlockTime))
    const lockTime = dateToSecondsSinceEpoch(new Date(deadlineData.lockTime))
    const isOpenAssignment = (!unlockTime || currentTime > unlockTime) &&
      currentTime < lockTime
    const isClosedAssignment =
      (!unlockTime || currentTime > unlockTime) && currentTime > lockTime
    assignment.id = deadlineData.id
    assignment.lockTime = lockTime
    assignment.isOpen = isOpenAssignment
    assignment.isClosed = isClosedAssignment
    hasClosedAssignment = hasClosedAssignment || isClosedAssignment
  }

  const addProgressData = assignment => {
    const assignmentProgress = progressData[assignment.chapter_uuid]
    if (!assignmentProgress) {
      assignment.isSubmitted = false
      if (assignment.isClosed === true) {
        hasUnsubmittedClosedAssignment = true
      }
    } else {
      assignment.isSubmitted = assignmentProgress.status === 'submitted'
      assignment.grade = assignmentProgress.grade
      assignment.isGraded = !!assignment.grade
      hasGradedAssignment = hasGradedAssignment || assignment.isGraded
      hasSubmittedUngradedAssignment =
        hasSubmittedUngradedAssignment ||
        (assignment.isSubmitted && !assignment.grade)
    }
    hasOpenUnsubmittedAssignment = hasOpenUnsubmittedAssignment ||
      (assignment.isOpen && assignment.isSubmitted === false)
  }

  const enrichAssignments = () => {
    assignments.forEach(assignment => {
      addMilestonesData(assignment)
      addProgressData(assignment)
    })
  }

  enrichAssignments()

  if (hasOpenUnsubmittedAssignment) {
    const openUnsubmittedCard = (
      <div key={displayedMessages.length} data-testid='open-unsubmitted-card'>
        <OpenUnsubmittedCard course={course} assignments={assignments} />
      </div>
    )
    displayedMessages.push(openUnsubmittedCard)
  }

  if (hasGradedAssignment) {
    const gradedCard = (
      <div key={displayedMessages.length} data-testid='graded-card'>
        <GradedCard
          course={course}
          assignments={assignments}
          dismissAssignments={dismissAssignments}
        />
      </div>
    )
    displayedMessages.push(gradedCard)
  }

  if (hasSubmittedUngradedAssignment) {
    const submittedCard = (
      <div key={displayedMessages.length} data-testid='submitted-card'>
        <Separator />
        <SubmittedCard course={course} assignments={assignments} />
      </div>
    )
    displayedMessages.push(submittedCard)
  }

  if (hasUnsubmittedClosedAssignment && isUnsubmittedCardVisible) {
    const unsubmittedCard = (
      <div key={displayedMessages.length} data-testid='unsubmitted-card'>
        <Separator />
        <UnsubmittedCard
          course={course}
          assignments={assignments}
          dismissUnsubmittedCard={dismissUnsubmittedCard}
        />
      </div>
    )
    displayedMessages.push(unsubmittedCard)
  }

  // Todo: handle closed and upcoming states
  return displayedMessages
}

export default WritingAssignmentsCard
