import React from 'react'
import config from '../../config'
import WidgetState from './WidgetState'
import { CHAPTER_TYPE, PROGRESS_TYPE } from '../../Constants/courseCard'

const QuizCard = ({
  course,
  courseProgress,
  visitedQuizData,
  chapterUuid,
  chapterNo,
  sectionNo
}) => {
  const { id, chapters } = course

  const { studentProgress, sectionData } = courseProgress
  const { 'quiz-section': quizSection } = studentProgress

  const { visitedQuiz, quizNo } = visitedQuizData
  const {
    section_uuid: sectionUuid
  } = sectionData

  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`

  const getDescription = (invokedBy, messageArgs = {}) => {
    const {
      completedQuestion,
      totalQuestion,
      nextChapterNo,
      nextSectionNo,
      percentage
    } = messageArgs

    const remainingQuestions = totalQuestion - completedQuestion
    const isMultipleCompletedQuestions = completedQuestion > 1 ? 's ' : ''
    const isMultipleRemainingQuestions = completedQuestion > 1 ? 's ' : ''
    const sectionNumber = `Section ${chapterNo}.${sectionNo}`

    if (invokedBy === 'inProgress') {
      return `You completed ${completedQuestion}
       question${isMultipleCompletedQuestions} in Quiz ${quizNo} of
        ${sectionNumber} Quizzes. Continue to complete the remaining
         ${remainingQuestions} question${isMultipleRemainingQuestions}.`
    }
    if (invokedBy === 'nextSection') {
      return `You completed Quiz ${quizNo} of ${sectionNumber}
       Quizzes and scored ${percentage}%. Continue to Section
        ${nextChapterNo}.${nextSectionNo} or review your answers.`
    }
  }

  // when last activity is quiz and in progress
  const handleInProgressQuiz = () => {
    const {
      completedQuestion,
      totalQuestion,
      questionId
    } = visitedQuiz
    const quizUrl = `${sectionUrl}/${questionId}`

    return {
      dataTestId: 'quiz-inprogress',
      description: getDescription('inProgress', {
        completedQuestion,
        totalQuestion
      }),
      primaryBtnTxt: 'Continue',
      primaryBtnFunction: () => { window.location.href = quizUrl }
    }
  }

  const handleNextSection = ({
    nextChapterNo,
    nextSectionNo,
    nextSectionUrl
  }) => {
    const {
      questionId,
      questionSetUuid
    } = visitedQuiz

    const { QUIZ_SECTION } = PROGRESS_TYPE
    const quizUrl = `${sectionUrl}/${questionId}`
    const quizSection = studentProgress[QUIZ_SECTION]
    const percentage = quizSection[questionSetUuid] || 0

    return {
      dataTestId: 'quiz-completed',
      description: getDescription('nextSection', {
        nextChapterNo,
        nextSectionNo,
        nextSectionUrl,
        quizUrl,
        percentage
      }),
      primaryBtnTxt: 'Continue',
      primaryBtnFunction: () => { window.location.href = nextSectionUrl },
      secondaryBtnTxt: 'Review',
      secondaryBtnFunction: () => { window.location.href = quizUrl }
    }
  }

  const handleChapterByType = nextChapter => {
    const { CHAPTER, EXAM, WRITING_ASSIGNMENT_CHAPTER_RECORD } = CHAPTER_TYPE
    switch (nextChapter.type) {
      case CHAPTER: {
        const nextSection = nextChapter.sections[0]
        const nextSectionUrl = `${courseBaseUrl}/#/${nextSection.section_uuid}`
        return handleNextSection({
          nextChapterNo: chapterNo + 1,
          nextSectionNo: 1,
          nextSectionUrl
        })
      }
      case EXAM: {
        // Todo: handle quiz state when the next section is exam
        return {}
      }
      case WRITING_ASSIGNMENT_CHAPTER_RECORD: {
        // Todo: handle quiz state when the next section is WA
        return {}
      }
      default:
        return {}
    }
  }

  // when last activity is quiz and completed
  const handleCompletedQuiz = () => {
    const chapterIndex = chapters.findIndex(c => c.chapter_uuid === chapterUuid)
    const chapter = chapters[chapterIndex]
    if (sectionNo < chapter.sections?.length) {
      const nextSection = chapter.sections[sectionNo]
      const nextSectionUrl = `${courseBaseUrl}/#/${nextSection.section_uuid}`
      return handleNextSection({
        nextChapterNo: chapterNo,
        nextSectionNo: sectionNo + 1,
        nextSectionUrl
      })
    } else if (chapterIndex < chapters.length - 1) {
      const nextChapter = chapters[chapterIndex + 1]
      return handleChapterByType(nextChapter)
    }
    return {}
  }

  const { questionSetUuid } = visitedQuiz
  const isQuizCompleted = questionSetUuid in quizSection
  const stateData = !isQuizCompleted ? handleInProgressQuiz() : handleCompletedQuiz()
  const { dataTestId } = stateData

  return (
    <div data-testid={dataTestId}>
      <WidgetState
        {...stateData}
      />
    </div>
  )
}

export default QuizCard
