import React, { useContext, useEffect, useRef } from 'react'
import * as typeformEmbed from '@typeform/embed'
import { FORM_URLS } from '../../data/constants'
import { AppContext } from '../ContextProvider/ContextProvider'
import { getStudentAge } from '../../utilities/user'
import { NoAccessWrapper } from './style'

const AudienceConsentStudentForm = () => {
  const { studentData, courses } = useContext(AppContext)
  const formRef = useRef(null)
  const studentCanAccess = checkStudentAccess()

  function checkStudentAccess () {
    if (!studentData || !courses.length) return false

    // check if student is enrolled in any courses other than College Success
    const activeCourses = courses.filter(
      (course) => course.name !== 'College Success'
    )
    if (!activeCourses.length) return false

    if (getStudentAge(studentData.dateOfBirth) < 18) {
      const studentHasGuardian = studentData?.guardianEmail
      const hasHideTOSRelationship = studentData?.relationship?.find(
        (relationship) => relationship.hideTOS
      )
      return studentHasGuardian || hasHideTOSRelationship
    }

    return true
  }

  useEffect(() => {
    if (!formRef.current || !studentCanAccess) return

    const embedForm = async () => {
      try {
        typeformEmbed.makeWidget(
          formRef.current,
          FORM_URLS.audienceConsentStudent,
          {
            onSubmit: ({ response_id: responseID }) => {
              console.log(
                'Form submitted successfully! Response ID: ',
                responseID
              )
            }
          }
        )
      } catch (error) {
        console.error('Error when embedding form: ', error.message)
      }
    }

    embedForm()
  }, [formRef, studentCanAccess])

  if (!studentCanAccess) {
    return (
      <NoAccessWrapper>
        <h1>You are unable to be an audience member</h1>
        <p>
          It looks like you are unable to be an audience member unless you meet
          these criteria:
        </p>
        <ul>
          <li>You are 18 years of age </li>
          <li>
            You are under 18 years of age, but a current Outlier student with
            guardian permission to take Outlier courses
          </li>
        </ul>
        <p>
          If you feel like you do meet the above criteria, please reach out to {' '}
          <a href='mailto:success@outlier.org'>success@outlier.org </a>for
          additional help.
        </p>
      </NoAccessWrapper>
    )
  }

  return (
    <div
      id='embedded-typeform'
      ref={formRef}
      data-testid='audience-consent-student-form'
    />
  )
}

export default AudienceConsentStudentForm
