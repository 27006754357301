import { groupBy, isEqual, inRange, omit, countBy, pick } from 'lodash'
import {
  ACTIVE_LEARNING,
  ACTIVE_LEARNING_COMPLETE,
  CALCULUS_LECTURE_VIDEOS,
  CONCEPT_MAP,
  GUESSWORK,
  LECTURE,
  LECTURE_COMPLETE,
  LECTURE_VIDEOS,
  MULTI_LECTURE_VIDEOS,
  PRACTICE_EXERCISES,
  PRACTICE_EXERCISES_COMPLETE,
  PRACTICE_SETS,
  PRACTICE_TERM,
  PRACTICE_TERMS,
  PRACTICE_TERM_COMPLETE,
  PROBLEM_BANK,
  QUIZ,
  QUIZ_SECTION,
  READINGS,
  READINGS_COMPLETE,
  EXAM_COMPLETE,
  ASSIGNMENT_PROGRESS,
  CONCEPT_MAP_COMPLETE,
  READINGS_PROGRESS,
  STUDENT_ANSWERS
} from '../../../Constants/studentProgressKeys'
import {
  ASSIGNMENT,
  FINAL_GRADE,
  SECTION,
  SectionType
} from '../../../Constants/sectionType'
import {
  getDateWithoutYear,
  getTimezoneShort,
  secondsSinceEpoch,
  secondsToDateString,
  secondsToFormattedDateShort
} from '../../../utilities/dateTime'
import { BREAK, ESSAY, EXAM } from '../../../Constants/examType'
import {
  getDynamicSchedules,
  getFormattedSchedule
} from '../../../utilities/cohortScheduleUtils'
import {
  getSectionExerciseLockText,
  getSectionGuessworkUUID,
  hasActiveLearningOpened,
  isGuessworkCompleteForSection,
  isSectionRequiredActiveLearningCompleted
} from '../../../utilities/section'
import { PROGRESS_TYPE } from '../../../Constants/courseCard'
import utils from '../../../utilities'
import {
  getActiveAssessmentWarningColor,
  getCompletedActivitiesFromExercise,
  getCompletedLectureVideosFromExercise
} from '../CourseList/courseListUtils'
import { WHITE, GREY } from '../checklistColors'
import { checkCourseNames } from '../../../utilities/course'
import { isUserAuditor } from '../../../utilities/user'

/**
 * this function will return the section list generated from generateSectionList.
 * @param sectionsData {array} - all the section data array
 * @param dynamicCohortSchedule
 * @param chapters {array} - used to get the guessworkUUID for section
 * @param filteredSchedules {array} - used to get the information related to essay, exam and assignment
 * @param isIntensive
 * @param studentData
 * @returns {array} - generated section list from schedules and sectionsData
 */
export const createSectionList = (params) => {
  const {
    courseUnits,
    isVIP,
    isVIPGradedContent,
    isStudioCohort,
    examRetakes,
    sectionsData,
    dynamicCohortSchedule,
    chapters,
    filteredSchedules,
    isIntensive,
    studentData,
    courseId,
    latestCohort = {}
  } = params || {}
  const isExamWithoutSchedules =
    !dynamicCohortSchedule?.schedules?.length &&
    dynamicCohortSchedule?.assignmentType === EXAM
  const isEssayIntensiveSchedules =
    isIntensive &&
    dynamicCohortSchedule?.assignmentType === ESSAY
  const isTermBreak = dynamicCohortSchedule?.assignmentType === BREAK

  const schedules = isExamWithoutSchedules || isEssayIntensiveSchedules || isTermBreak
    ? [dynamicCohortSchedule]
    : dynamicCohortSchedule?.schedules
  if (!sectionsData?.length || !schedules?.length) {
    return null
  }
  const list = generateSectionList({
    courseUnits,
    isVIP,
    isVIPGradedContent,
    isStudioCohort,
    examRetakes,
    sectionsData,
    chapters,
    schedules,
    filteredSchedules,
    studentData,
    courseId,
    latestCohort
  })
  const sectionList = []
  list.forEach(section =>
    Object.keys(section)?.map((sectionKey, index) =>
      sectionList.push({ ...section[sectionKey], index })
    )
  )

  return sectionList
}

/**
 * this function will return the section list generated from sectionsData.
 * @param sectionsData {array} - all the section data array
 * @param schedules {array} - all the schedules data
 * @param chapters {array} - used to get the guessworkUUID for section
 * @param isIntensive
 * @param studentData {object}  - used to get the student progress
 * @param filteredSchedules {array} - used to get the information related to essay, exam and assignment
 * @returns {array} - generated section list from schedules and sectionsData
 */
export const generateSectionList = (params) => {
  const {
    courseUnits,
    isVIP,
    isVIPGradedContent,
    isStudioCohort,
    examRetakes,
    sectionsData,
    chapters,
    schedules,
    filteredSchedules,
    studentData = {},
    courseId,
    latestCohort = {}
  } = params || {}
  const {
    relationship: { fields: { liveProctoring } = {} } = {}
  } = latestCohort || {}
  const isAudit = isUserAuditor(latestCohort)
  const hasOnlyTermBreak = schedules?.every(schedule => schedule?.assignmentType === BREAK)

  const list = []
  const sectionsTitles = []
  schedules.filter(schedule => schedule !== undefined).forEach((schedule, index) => {
    if (hasOnlyTermBreak && schedule?.assignmentType === BREAK) {
      const { title, assignmentType, materialCovered } = schedule || {}
      list.push(sectionObj({
        ...schedule,
        assessmentColor: '#FFFFFF',
        sectionTitle: title,
        sectionType: '',
        description: materialCovered,
        type: assignmentType
      }))
      return
    }

    sectionsData.forEach(section => {
      const videos = section?.['section_exe'][MULTI_LECTURE_VIDEOS]?.videos
      const inList = videos?.find(item =>
        schedule?.materialCovered?.includes(item?.displayTitle)
      )
      const inParentList =
        schedule?.materialCovered?.toLowerCase() === section?.title?.toLowerCase()
      if (inList || inParentList) {
        sectionsTitles.push(section?.title?.toLowerCase())
      }
    })
    const materialCovered = schedule?.materialCovered
      ?.replace('+', 'and')
      ?.toLowerCase()
    const section = sectionsData.find(
      ({ title }) =>
        materialCovered === title?.replace('+', 'and')?.toLowerCase() ||
        title?.toLowerCase().includes(materialCovered)
    )
    const key = schedule.title
    const title = key?.split('|')?.[1]?.trim() || key
    const examEssaySchedule = filteredSchedules?.find(
      ({ materialCovered = '', title = '' }) =>
        (materialCovered &&
          materialCovered === schedule?.materialCovered) ||
        title === schedule?.title
    )
    const hasNotByPassedDueDate =
      secondsSinceEpoch() < examEssaySchedule?.endDateInSecs

    if (schedule?.assignmentType === EXAM && hasNotByPassedDueDate) {
      const examNumber = title.match(/\d+/)
      const titleToMatch = examNumber ? `Midterm Exam ${examNumber[0]}` : title
      const examDetails = chapters.find(
        ({ type, title: chapterTitle }) =>
          type === EXAM && chapterTitle === titleToMatch
      )
      const examUUID = examDetails?.['chapter_uuid']
      const isComplete = studentData[EXAM_COMPLETE][examUUID]
      examEssaySchedule.isLiveProctoring = liveProctoring
      examEssaySchedule.isAudit = isAudit

      const examObj = isExam(examEssaySchedule, title, isComplete)
      list.push(examObj)
    }

    if (schedule?.assignmentType === ESSAY && hasNotByPassedDueDate) {
      const essayDetails = chapters.find(({ title: assignmentTitle }) =>
        assignmentTitle.includes(title)
      )
      const essayUUID = essayDetails?.['chapter_uuid'] || examEssaySchedule.chapterUuid
      const isComplete = studentData[ASSIGNMENT_PROGRESS][essayUUID]
      examEssaySchedule.isAudit = isAudit

      const essayObj = isAssignment(examEssaySchedule, title, isComplete)
      list.push(essayObj)
    }
    if (!section ||
      schedule?.assignmentType === EXAM ||
      schedule?.assignmentType === ESSAY) return []
    const guessworkUUID = getSectionGuessworkUUID(section, chapters)
    const { section_exe: sectionExe, section_uuid: sectionUUID } = section
    const completedExercise = completedExercises(sectionExe, studentData)
    const practiceExercise =
      Array.isArray(sectionExe[PROBLEM_BANK])
        ? sectionExe[PROBLEM_BANK]
        : [sectionExe[PROBLEM_BANK] || '']
    const inCompletedPractices = practiceExercise?.filter(
      ({ question_set_uuid: questionSetUUID }) =>
        !completedExercise?.includes(questionSetUUID)
    )
    const activeLearningQuestions = sectionExe[ACTIVE_LEARNING]?.Question?.filter(({
      Question_uuid: QuestionUUID
    }) => QuestionUUID)
    const inCompletedActiveLearning = inCompletedActiveLearnings(
      activeLearningQuestions, studentData[STUDENT_ANSWERS]
    )
    const completedQuizzes = getCompletedActivitiesFromExercise(
      sectionExe[QUIZ],
      studentData[QUIZ_SECTION]
    )
    const inCompletedQuizzes = sectionExe.quiz?.filter(
      ({ question_set_uuid: questionSetUUID }) =>
        !completedQuizzes?.includes(questionSetUUID)
    )

    const completedReadings = getCompletedActivitiesFromExercise(
      sectionExe[READINGS],
      studentData[READINGS_PROGRESS]
    )
    const inCompletedReadings = sectionExe.readings?.filter(
      ({ question_set_uuid: questionSetUUID }) =>
        !completedReadings?.includes(questionSetUUID)
    )
    const completedMultiLectureVideos = getCompletedLectureVideosFromExercise(
      sectionExe[MULTI_LECTURE_VIDEOS],
      studentData[LECTURE_COMPLETE]
    )
    const inCompletedMultiLectureVideos = sectionExe?.[
      MULTI_LECTURE_VIDEOS
    ]?.videos?.filter(
      ({ kalturaEmbedCode }) =>
        !completedMultiLectureVideos?.includes(kalturaEmbedCode)
    )
    const {
      guesswork: isGuesswork,
      multi_lecture_videos: multiLectureVideos,
      lecture: isLecture,
      active_learning: isActiveLearning,
      problem_bank: isPracticeExercises,
      practice_terms: isPracticeTerms,
      quiz: isQuiz,
      readings: isReadings,
      conceptMap: isConceptMap
    } = getIncompleteActivities(section, studentData, courseId)

    const activeLearningOpened =
      studentData?.[PROGRESS_TYPE.LAST_ACTIVE_LEARNING_UUID] || {}
    const type = sectionExe.quiz ? QUIZ : ''
    const isActiveLearningOpened =
      hasActiveLearningOpened(activeLearningOpened, sectionUUID) || false
    const activities = [PROBLEM_BANK, PRACTICE_EXERCISES, QUIZ, PRACTICE_TERMS]
    const isActivityLocked =
      !isActiveLearningOpened && activities.includes(type)
    const {
      guesswork,
      lecture,
      activeLearning,
      practiceExercises,
      practiceTerms,
      quiz,
      readings,
      conceptMap
    } = getSectionText(
      sectionExe,
      inCompletedQuizzes,
      inCompletedPractices,
      inCompletedMultiLectureVideos,
      inCompletedReadings,
      inCompletedActiveLearning
    )
    const {
      guessworkExerciseId,
      lectureExerciseId,
      activeLearningExerciseId,
      practiceExerciseId,
      quizExerciseId,
      readingsExerciseId,
      conceptMapExerciseId
    } = getSectionExerciseId(
      sectionExe,
      inCompletedQuizzes,
      inCompletedPractices,
      inCompletedMultiLectureVideos,
      inCompletedReadings,
      inCompletedActiveLearning,
      courseId
    )

    const options = {
      courseId,
      chapters,
      courseUnits,
      isVIP,
      isVIPGradedContent,
      isStudioCohort,
      examRetakes,
      latestCohort,
      courseResourcesSchedule: filteredSchedules,
      sectionUUID: sectionUUID,
      studentProgress: studentData,
      currentDate: secondsSinceEpoch(),
      guessworkSectionUUID: guessworkUUID
    }
    list.push({
      ...(sectionExe[GUESSWORK] &&
        sectionObj({
          sectionTitle: title,
          sectionType: SectionType.GUESSWORK,
          description: guesswork,
          sectionUUID,
          exerciseId: guessworkExerciseId,
          lockText: getSectionExerciseLockText(GUESSWORK, options),
          isCompleted: Boolean(!isGuesswork)
        })),
      ...((sectionExe[LECTURE] || sectionExe[MULTI_LECTURE_VIDEOS]) &&
        sectionObj({
          sectionTitle: title,
          sectionType: `${SectionType.LECTURE}s`,
          description: lecture,
          sectionUUID,
          exerciseId: lectureExerciseId,
          lockText: getSectionExerciseLockText(LECTURE, options),
          isCompleted: Boolean(
            sectionExe[LECTURE?.toLowerCase()]
              ? !isLecture
              : !multiLectureVideos
          ),
          lectureVideos:
            sectionExe[LECTURE?.toLowerCase()]?.lecturevideos || []
        })),
      ...(sectionExe[ACTIVE_LEARNING] &&
        sectionObj({
          sectionTitle: title,
          sectionType: SectionType.ACTIVE_LEARNING,
          description: activeLearning,
          sectionUUID,
          exerciseId: activeLearningExerciseId,
          lockText: getSectionExerciseLockText(ACTIVE_LEARNING, options),
          isCompleted: Boolean(!isActiveLearning) || !Object.values(inCompletedActiveLearning)?.length,
          activeLearningQuestions
        })),
      ...(sectionExe[PROBLEM_BANK]?.length &&
        sectionObj({
          sectionTitle: title,
          sectionType: PRACTICE_SETS,
          description: practiceExercises,
          sectionUUID,
          exerciseId: practiceExerciseId,
          lockText: isActivityLocked || getSectionExerciseLockText(PROBLEM_BANK, options),
          isCompleted: Boolean(!isPracticeExercises)
        })),
      ...(sectionExe[PRACTICE_TERM] &&
        sectionObj({
          sectionTitle: title,
          sectionType: SectionType.PRACTICE_TERMS,
          description: practiceTerms,
          sectionUUID,
          exerciseId: 'Quizlet',
          lockText: isActivityLocked || getSectionExerciseLockText(PRACTICE_TERM, options),
          isCompleted: Boolean(!isPracticeTerms)
        })),
      ...(sectionExe[CONCEPT_MAP] &&
        sectionObj({
          sectionTitle: title,
          sectionType: SectionType.CONCEPT_MAP,
          description: conceptMap,
          sectionUUID,
          exerciseId: conceptMapExerciseId,
          lockText: getSectionExerciseLockText(CONCEPT_MAP, options),
          isCompleted: Boolean(!isConceptMap)
        })),
      ...(sectionExe[QUIZ] &&
        sectionObj({
          sectionTitle: title,
          sectionType: SectionType.QUIZ,
          description: quiz,
          sectionUUID,
          exerciseId: quizExerciseId,
          lockText: isActivityLocked || getSectionExerciseLockText(QUIZ, options),
          isCompleted: Boolean(!isQuiz)
        })),
      ...(sectionExe[READINGS]?.length &&
        sectionObj({
          sectionTitle: title,
          sectionType: SectionType.READINGS,
          description: readings,
          sectionUUID,
          exerciseId: readingsExerciseId,
          lockText: getSectionExerciseLockText(READINGS, options),
          isCompleted: Boolean(!isReadings) || !inCompletedReadings?.length
        }))
    })
  })
  return list
}

/**
 * this function will filter schedules of type section, exam, essay.
 * @param dynamicCohortSchedules {array} - all the section data array
 * @returns {array} - generated section list from schedules and sectionsData
 */
export const getSectionTypeSchedules = dynamicCohortSchedules => {
  if (!dynamicCohortSchedules) {
    return null
  }
  return dynamicCohortSchedules?.filter?.(
    ({ schedules = [], assignmentType = '' } = {}) =>
      schedules?.length ||
      [SECTION, BREAK, EXAM, ESSAY, FINAL_GRADE].includes(assignmentType)
  )
}

/**
 * this function will return the exam object for a section
 * @param exam {object}
 * @param title {string} - used to get the title of the exam
 * @param isComplete {boolean}
 * @returns {object} - exam object for a section
 */
const isExam = (exam, title, isComplete) => {
  const coverTitle =
    exam?.materialCovered?.split('Sections')?.[1]?.trim() ||
    exam?.materialCovered
  const currentTimeInSecs = secondsSinceEpoch()
  const isActiveAssessment = inRange(
    currentTimeInSecs,
    exam?.startDateInSecs,
    exam?.endDateInSecs + 1
  )
  const timeZone = getTimezoneShort(secondsToDateString(exam?.endDateInSecs))
  const endDateTime =
    getDateWithoutYear(exam?.endDateInSecs).replace(', ', ' at ') + ` ${timeZone}`

  const isNoWarnings = exam?.isLiveProctoring || !exam?.isAudit
  const warningColor = getActiveAssessmentWarningColor(
    exam?.endDateInSecs, isNoWarnings
  )
  const assessmentColor = warningColor === GREY ? WHITE : warningColor
  return {
    [`${title} ${EXAM}`]: {
      title,
      description: coverTitle && `Covers Chapters ${coverTitle}`,
      section_uuid: '',
      lockText: '',
      type: EXAM,
      hashLink: exam?.textLink?.hashLink,
      endDate: `Open until ${endDateTime}`,
      assessmentColor,
      isActiveAssessment,
      isCompleted: Boolean(isComplete)
    }
  }
}

/**
 * this function will return the assignment object for a section
 * @param assignment {object}
 * @param title {string} - used to get the title of the assignment
 * @param isComplete {boolean}
 * @returns {object} - assignment object for a section
 */
const isAssignment = (assignment, title, isComplete) => {
  const currentTimeInSecs = secondsSinceEpoch()
  const isActiveAssessment = inRange(
    currentTimeInSecs,
    assignment?.startDateInSecs,
    assignment?.endDateInSecs + 1
  )
  const timeZone = getTimezoneShort(secondsToDateString(assignment?.endDateInSecs))
  const endDateTime =
    getDateWithoutYear(assignment?.endDateInSecs).replace(', ', ' at ') + ` ${timeZone}`
  const warningColor = getActiveAssessmentWarningColor(
    assignment?.endDateInSecs, assignment?.isAudit
  )
  const assessmentColor = warningColor === GREY ? WHITE : warningColor
  const assignmentTitle =
    assignment?.finalAssignment ? 'Final Assignment' : 'Writing Assignment'
  return sectionObj({
    sectionTitle: assignmentTitle,
    description: `"${title}"`,
    type: ASSIGNMENT,
    hashLink: assignment?.textLink?.hashLink,
    endDate: `Due by ${endDateTime}`,
    assessmentColor,
    isActiveAssessment,
    isCompleted: Boolean(isComplete)
  })
}

/**
 * this function will the previous sections from the week given.
 * @param lastTwoWeeksRP {object} - use to find the last section
 * @param formattedSchedules {array} - used to get all the sections
 * @param isIntensive
 * @returns {array} - generated a previous section list
 */
export const previousSections = (
  lastTwoWeeksRP,
  formattedSchedules,
  isIntensive
) => {
  if (!lastTwoWeeksRP || !formattedSchedules?.length) return []
  const allSections = [...formattedSchedules]
  let lastSectionIndex = allSections?.findIndex(
    section => section?.title === lastTwoWeeksRP?.title
  )
  if (isIntensive) {
    const filterSections = allSections
      ?.filter(section => section?.week === lastTwoWeeksRP?.week)
      ?.pop()
    lastSectionIndex = allSections?.findIndex(
      section => section?.title === filterSections?.title
    )
  }

  const isTermBreak = lastTwoWeeksRP?.assignmentType === BREAK
  const lastTitle = isTermBreak ? formattedSchedules[lastSectionIndex - 1].title : lastTwoWeeksRP?.title
  const endDateInSecs = isTermBreak ? formattedSchedules[lastSectionIndex - 1].endDateInSecs : lastTwoWeeksRP?.endDateInSecs
  const { title: firstTitle, startDateInSecs } = formattedSchedules?.[0] || {}
  const title = firstTitle
  const startDate = secondsToFormattedDateShort(startDateInSecs)
  const endDate = secondsToFormattedDateShort(endDateInSecs)
  const firstSectionTitle = title?.split(' ')[0] + ' ' + title?.split(' ')[1]
  const splitTitle = lastTitle.split(' ')
  const lastSectionTitle = splitTitle[splitTitle.length - 1]

  const filteredSection = allSections?.splice(
    0, isTermBreak ? lastSectionIndex : lastSectionIndex + 1
  )
  const mergedSchedules = filteredSection?.map(section => section?.schedules)
  const schedules = [].concat.apply([], mergedSchedules)
  const mergedSchedule = []
  mergedSchedule.push({
    title: `${firstSectionTitle} - ${lastSectionTitle}`,
    assignmentType: SECTION,
    schedules: schedules,
    startDate: startDate,
    endDate: endDate
  })
  if (isTermBreak) mergedSchedule.push(lastTwoWeeksRP)
  return mergedSchedule
}

/**
 * this function will return the formatted schedules for intensive schedules.
 * @param formattedSchedules {array} - used to get all the formatted schedules
 * @returns {array} - generated a current section list
 */
export const getIntensiveSchedules = (formattedSchedules) => {
  if (!formattedSchedules?.length) return []
  const scheduleDays = formattedSchedules?.map(schedule =>
    schedule?.days?.map(day => ({
      ...day,
      schedules:
        day?.assignmentType === EXAM
          ? [day]
          : schedule?.assignmentType === ESSAY && day?.assignmentType === ESSAY
            ? []
            : day?.schedules,
      week: schedule?.week
    }))
  )
  return [].concat.apply([], scheduleDays)
}

/**
 * this function will return the conceptMap and practice terms type activities for a section.
 * @param exerciseList {object} - if there present the key conceptMap or practiceTerms, attach type to them
 * @returns {array} - with conceptMap and practiceTerms type attached.
 */
export const getPracticeActivities = exerciseList => {
  const { conceptMap, practice_terms: practiceTerms } = exerciseList
  const activities = []
  if (conceptMap) {
    activities.push({
      type: CONCEPT_MAP,
      ...conceptMap
    })
  }
  if (practiceTerms) {
    activities.push({
      type: PRACTICE_TERMS,
      ...practiceTerms
    })
  }
  return activities
}

/**
 * this function will return the section object for various activities: exam/assignment/section.
 * @param sectionTitle {string} - title of the section
 * @param sectionType {string} - type of the section
 * @param description {string} - description of the section
 * @param sectionUUID {string} - id of the section
 * @param exerciseId {string}
 * @param lockText {string} - lock text for the section
 * @param type {string} - type of the schedule
 * @param hashLink {string} - navigate to exam/assignment/essay
 * @param endDate {string} - end date for the exam/assignment/essay
 * @param isActiveAssessment {boolean} - isActiveAssessment exam/assignment/essay
 * @param isCompleted
 * @returns {array} - generated a current section list
 */
const sectionObj = ({
  sectionTitle,
  sectionType = '',
  description = '',
  sectionUUID = '',
  exerciseId = '',
  lockText = '',
  type = SECTION,
  hashLink,
  endDate,
  assessmentColor = '',
  isActiveAssessment,
  isCompleted,
  lectureVideos = [],
  activeLearningQuestions = []
}) => {
  const title = `${sectionTitle} ${sectionType}`
  return {
    [title]: {
      title,
      description,
      section_uuid: sectionUUID,
      exerciseId,
      lockText,
      type,
      hashLink,
      endDate,
      assessmentColor,
      isActiveAssessment,
      isCompleted,
      lectureVideos,
      activeLearningQuestions
    }
  }
}

/**
 * this function will return the current week activity for a schedules.
 * @param courseResourcesSchedule {array} - used to find the current week activity for a schedules
 * @param isIntensive
 * @param previousLastWeekSection
 * @returns {array} - current week activity for a schedules
 */
export const getRecommendedProgressWeek = (
  courseResourcesSchedule,
  hasCohortStarted,
  isIntensive,
  previousLastWeekSection
) => {
  if (!courseResourcesSchedule?.length) return []
  let formattedSchedules = getFormattedSchedule(
    courseResourcesSchedule,
    isIntensive
  )
  const currentWeekIndex = formattedSchedules?.findIndex(
    schedule => schedule?.week === previousLastWeekSection?.week
  )
  if (currentWeekIndex > -1) {
    formattedSchedules = formattedSchedules.slice(currentWeekIndex + 1)
  }
  const cohortSchedulesLength = formattedSchedules.length
  const lastWeek = formattedSchedules[cohortSchedulesLength - 1]
  let recommendedProgressWeek = getDynamicSchedules(formattedSchedules)
  if (!recommendedProgressWeek) {
    recommendedProgressWeek = [lastWeek]
  }
  const list = []
  if (hasCohortStarted) {
    recommendedProgressWeek.forEach(progress => {
      const currentTimeInSecs = secondsSinceEpoch()
      const isActive = inRange(
        currentTimeInSecs,
        progress.startDateInSecs,
        progress.endDateInSecs + 1
      )
      if (isActive) {
        const days = []
        isIntensive &&
          progress.days.forEach(day => {
            const isActiveDay = currentTimeInSecs >= day.startDateInSecs
            if (isActiveDay) {
              days.push(day)
            }
          })
        list.push({ ...progress, days })
      }
    })
    return list
  }
  const section1 = recommendedProgressWeek[0]
  const openSection1 = section1?.title?.includes('Sections 1.1')
  if (openSection1) {
    section1.schedules.splice(1)
    return [section1]
  }
  return list
}

export const getPreviousLastWeekSection = (
  courseResourcesSchedule,
  isIntensive
) => {
  if (!courseResourcesSchedule?.length) return {}
  const formattedSchedules = getFormattedSchedule(
    courseResourcesSchedule,
    isIntensive
  )
  const cohortSchedulesLength = formattedSchedules.length
  const recommendedProgressWeek = getDynamicSchedules(formattedSchedules)[0]
  const indexOfRP = recommendedProgressWeek?.week - 1
  let recommendedProgressLastWeek = formattedSchedules[indexOfRP - 1]
  const lastWeek = formattedSchedules[cohortSchedulesLength - 1]
  if (!recommendedProgressLastWeek) {
    recommendedProgressLastWeek = lastWeek
  }
  return recommendedProgressLastWeek
}

export const getNextWeekSection = (
  courseResourcesSchedule,
  isIntensive
) => {
  if (!courseResourcesSchedule?.length) return {}
  const formattedSchedules = getFormattedSchedule(
    courseResourcesSchedule,
    isIntensive
  )

  const cohortSchedulesLength = formattedSchedules.length
  const recommendedProgressWeek = getDynamicSchedules(formattedSchedules)[0]

  const currentWeekIndex = formattedSchedules.findIndex(
    schedule => schedule.week === recommendedProgressWeek.week
  )
  if (currentWeekIndex === cohortSchedulesLength + 1) {
    const lastWeek = formattedSchedules[cohortSchedulesLength - 1]
    return lastWeek
  }

  const nextWeekSection = formattedSchedules[currentWeekIndex + 1]
  if (recommendedProgressWeek?.assignmentType !== BREAK) return nextWeekSection
  return [recommendedProgressWeek, nextWeekSection]
}

export const getExerciseId = (exercises, key) => {
  if (!exercises?.length) return ''
  const exeIds = []
  exercises.forEach(exercise => {
    exeIds.push(exercise[key])
  })
  return exeIds[0]
}

export const getRecommendedProgress = (
  isCohortEnded,
  courseResourcesSchedule
) => {
  const formattedSchedules = getFormattedSchedule(courseResourcesSchedule)
  const cohortSchedulesLength = formattedSchedules.length
  let recommendedProgressWeek = getDynamicSchedules(formattedSchedules)[0]
  let lastWeek = formattedSchedules[cohortSchedulesLength - 1]
  if (!recommendedProgressWeek || isCohortEnded) {
    recommendedProgressWeek = lastWeek
  }

  const indexOfRP = recommendedProgressWeek?.week - 1
  const recommendedProgressLastWeek = formattedSchedules[indexOfRP - 1]
  const recommendedProgressLastTwoWeeks = formattedSchedules[indexOfRP - 2]

  lastWeek = lastWeek?.week
  const recommendedProgressWeekValue =
    (recommendedProgressWeek?.week / lastWeek) * 100
  const oneWeekRecommendedProgress =
    (recommendedProgressLastWeek?.week / lastWeek) * 100 || 0
  const twoWeekRecommendedProgress =
    (recommendedProgressLastTwoWeeks?.week / lastWeek) * 100 || 0

  return {
    currentRP: recommendedProgressWeek,
    lastWeekRP: recommendedProgressLastWeek,
    lastTwoWeeksRP: recommendedProgressLastTwoWeeks,
    values: {
      currentValueRP: Math.round(recommendedProgressWeekValue),
      lastWeekValueRP: Math.round(oneWeekRecommendedProgress),
      lastTwoWeeksValueRP: Math.round(twoWeekRecommendedProgress)
    }
  }
}

export const getIncompleteActivities = (exercise, studentProgress, courseId) => {
  if (!exercise && !studentProgress) return null

  const { section_exe: sectionExercise, section_uuid: sectionUUID } =
    exercise || {}
  const {
    guesswork,
    active_learning: activeLearning,
    quiz,
    readings,
    practice_terms: practiceTerms,
    multi_lecture_videos: multiLectureVideos,
    lecture,
    problem_bank: practiceExercises,
    conceptMap
  } = sectionExercise

  return {
    [GUESSWORK]: incompleteSectionUUIDForGuesswork(
      guesswork,
      studentProgress,
      sectionUUID
    ),
    [ACTIVE_LEARNING]: incompleteSectionUUIDForActiveLearning({
      activeLearning,
      studentProgress,
      sectionUUID,
      courseId
    }),
    [CALCULUS_LECTURE_VIDEOS]: incompleteSectionUUIDForLectureVideos(
      lecture,
      studentProgress,
      sectionUUID
    ),
    [LECTURE_VIDEOS]: incompleteSectionUUIDForMultiLectureVideos(
      multiLectureVideos,
      studentProgress,
      sectionUUID
    ),
    [QUIZ]: incompleteSectionUUIDForQuiz(quiz, studentProgress, sectionUUID),
    [READINGS]: incompleteSectionUUIDForReading(
      readings,
      studentProgress,
      sectionUUID
    ),
    [PROBLEM_BANK]: incompleteSectionUUIDForPracticeExercises(
      practiceExercises,
      studentProgress,
      sectionUUID
    ),
    [PRACTICE_TERMS]: incompleteSectionUUIDForPracticeTerms(
      practiceTerms,
      studentProgress,
      sectionUUID
    ),
    [CONCEPT_MAP]: incompleteSectionUUIDForConceptMap(
      conceptMap,
      studentProgress,
      sectionUUID
    )
  }
}

const incompleteSectionUUIDForPracticeTerms = (
  practiceTerms,
  studentProgress,
  sectionUUID
) => {
  const exerciseHasPracticeTerms = !!practiceTerms
  if (exerciseHasPracticeTerms) {
    const completedPracticeTerms =
      studentProgress[PRACTICE_TERM_COMPLETE][sectionUUID]

    if (!completedPracticeTerms) {
      return sectionUUID
    }
  }
  return ''
}

const incompleteSectionUUIDForConceptMap = (
  conceptMap,
  studentProgress,
  sectionUUID
) => {
  const exerciseHasConceptMap = !!conceptMap
  if (exerciseHasConceptMap) {
    const completedConceptMap =
      studentProgress[CONCEPT_MAP_COMPLETE][sectionUUID]

    if (!completedConceptMap) {
      return sectionUUID
    }
  }
  return ''
}

const incompleteSectionUUIDForPracticeExercises = (
  practiceExercises,
  studentProgress,
  sectionUUID
) => {
  const exerciseHasPracticeExercises =
    !!practiceExercises && studentProgress[PRACTICE_EXERCISES_COMPLETE]
  if (exerciseHasPracticeExercises) {
    const isPracticeExercisesCompleted =
      studentProgress[PRACTICE_EXERCISES_COMPLETE]?.[sectionUUID]
    if (!isPracticeExercisesCompleted) {
      return sectionUUID
    }
  }
  return ''
}

const incompleteSectionUUIDForReading = (
  readings,
  studentProgress,
  sectionUUID
) => {
  const exerciseHasReading = !!readings
  if (exerciseHasReading) {
    const isReadingCompleted =
      studentProgress[READINGS_COMPLETE]?.[sectionUUID]

    if (!isReadingCompleted) {
      return sectionUUID
    }
  }
  return ''
}

const incompleteSectionUUIDForQuiz = (quiz, studentProgress, sectionUUID) => {
  const exerciseHasQuiz = !!quiz && studentProgress[QUIZ_SECTION]
  if (exerciseHasQuiz) {
    const completedQuizzes = getCompletedActivitiesFromExercise(
      quiz,
      studentProgress[QUIZ_SECTION]
    )

    const activeQuiz = Math.abs(quiz?.length - completedQuizzes?.length)
    if (activeQuiz > 0 && completedQuizzes?.length === 0) {
      return sectionUUID
    }
  }
  return ''
}

const incompleteSectionUUIDForMultiLectureVideos = (
  multiLectureVideos,
  studentProgress,
  sectionUUID
) => {
  const exerciseHasLectureVideos = !!multiLectureVideos
  if (exerciseHasLectureVideos) {
    const completedLectureVideos = getCompletedLectureVideosFromExercise(
      multiLectureVideos,
      studentProgress[LECTURE_COMPLETE]
    )

    const activeLectures = Math.abs(
      multiLectureVideos?.videos?.length - completedLectureVideos?.length
    )
    if (activeLectures > 0) {
      return sectionUUID
    }
  }
  return ''
}

const incompleteSectionUUIDForLectureVideos = (
  lecture,
  studentProgress,
  sectionUUID
) => {
  const exerciseHasLectureVideosCalculus = !!lecture
  if (exerciseHasLectureVideosCalculus) {
    const isCalculusLectureComplete =
      studentProgress[LECTURE_COMPLETE]?.[sectionUUID]

    if (!isCalculusLectureComplete) {
      return sectionUUID
    }
  }
  return ''
}

const incompleteSectionUUIDForActiveLearning = ({
  activeLearning,
  studentProgress,
  sectionUUID,
  courseId
}) => {
  let sectionHasActiveLearningComplete = false
  const exerciseHasActiveLearning = !!activeLearning
  if (exerciseHasActiveLearning) {
    sectionHasActiveLearningComplete = isSectionRequiredActiveLearningCompleted({
      courseId,
      sectionUUID,
      activeLearning,
      studentAnswers: studentProgress[STUDENT_ANSWERS],
      activeLearningCompleteObject: studentProgress[ACTIVE_LEARNING_COMPLETE]
    })

    if (!sectionHasActiveLearningComplete) {
      return sectionUUID
    }
  }
  return ''
}

const incompleteSectionUUIDForGuesswork = (
  guesswork,
  studentProgress,
  sectionUUID
) => {
  let sectionHasGuessworkComplete = false
  const hasExerciseGuessworkUUID = !!sectionUUID
  const exerciseHasGuesswork = !!guesswork
  if (hasExerciseGuessworkUUID) {
    sectionHasGuessworkComplete = isGuessworkCompleteForSection(
      studentProgress,
      sectionUUID
    )
  }

  if (exerciseHasGuesswork && !sectionHasGuessworkComplete) {
    return sectionUUID
  }
  return ''
}

export const completedExercises = (sectionExe, studentData) => {
  if ((sectionExe && !sectionExe[PROBLEM_BANK]?.length) || !studentData) {
    return null
  }
  const activity = sectionExe[PROBLEM_BANK]
  const completedActivitiesArray = []
  activity.forEach(({ question_set_uuid: questionSetUUID }) => {
    const isActivityCompleted =
      studentData?.['studentAnswers']?.[questionSetUUID]
    return isActivityCompleted && isActivityCompleted.isPracticeCompleted
      ? completedActivitiesArray.push(questionSetUUID)
      : null
  })
  return completedActivitiesArray
}

export const getSectionText = (
  sectionExe,
  inCompletedQuizzes,
  inCompletedPractices,
  inCompletedMultiLectureVideos,
  inCompletedReadings,
  inCompletedActiveLearning
) => {
  const {
    guesswork,
    lecture
  } = sectionExe
  const guessworkQuestion = guesswork?.Question?.length || 0
  const guessworkText = utils.pluralizeWord(guessworkQuestion, 'question')
  const lectureVideo =
    inCompletedMultiLectureVideos?.length ||
    lecture?.lecturevideos?.length ||
    0
  const lectureText = utils.pluralizeWord(lectureVideo, 'video')
  const activeLearningQuestions = Object.values(inCompletedActiveLearning)?.length || 0
  const activeLearningText = utils.pluralizeWord(
    activeLearningQuestions,
    'chapter'
  )
  const practiceExercisesSet = inCompletedPractices?.length || 0
  const practiceExercises = utils.pluralizeWord(practiceExercisesSet, 'set')
  const activities = getPracticeActivities(sectionExe)
  const practiceTermsPart =
    countBy(activities, 'type')[PRACTICE_TERMS] || 0
  const conceptMapPart = countBy(activities, 'type')[CONCEPT_MAP] || 0
  const practiceTerms = utils.pluralizeWord(practiceTermsPart, 'part')
  const conceptMap = utils.pluralizeWord(conceptMapPart, 'page')
  const quiz = '1 attempt required'
  const readingsLength = inCompletedReadings?.length || 0
  const readingsText = utils.pluralizeWord(readingsLength, 'reading')
  return {
    guesswork: guessworkText,
    lecture: lectureText,
    activeLearning: activeLearningText,
    practiceExercises,
    practiceTerms,
    quiz,
    readings: readingsText,
    conceptMap
  }
}

export const getSectionExerciseId = (
  sectionExe,
  inCompletedQuizzes,
  inCompletedPractices,
  inCompletedMultiLectureVideos,
  inCompletedReadings,
  inCompletedActiveLearning,
  courseId
) => {
  const {
    active_learning: activeLearning,
    guesswork,
    lecture,
    conceptMap
  } = sectionExe
  const { question_set_uuid: questionUUID, Question: question } = guesswork || {}
  const guessworkExerciseId = questionUUID || getExerciseId(
    question,
    'Question_uuid'
  )
  const lectureExerciseId =
    getExerciseId(inCompletedMultiLectureVideos, 'uuid') ||
    getExerciseId(lecture?.lecturevideos, 'lecturevideos_uuid')
  const inCompletedActiveLearnings =
    Object.values(inCompletedActiveLearning)[0] || []
  const alreadyStarted =
    inCompletedActiveLearnings?.length === activeLearning?.Question?.length
  const isCalculus = checkCourseNames(['calculus', 'calculus.plus'], courseId)
  const activeLearningExerciseId = alreadyStarted || isCalculus
    ? activeLearning?.['question_set_uuid']
    : getExerciseId(inCompletedActiveLearnings, 'Question_uuid')
  const practiceExerciseId = getExerciseId(
    inCompletedPractices,
    'question_set_uuid'
  )
  const quizExerciseId = getExerciseId(inCompletedQuizzes, 'question_set_uuid')
  const readingsExerciseId = getExerciseId(
    inCompletedReadings,
    'question_set_uuid'
  )
  const conceptMapExerciseId = conceptMap?.uuid

  return {
    guessworkExerciseId,
    lectureExerciseId,
    activeLearningExerciseId,
    practiceExerciseId,
    quizExerciseId,
    readingsExerciseId,
    conceptMapExerciseId
  }
}

export const filterClickedSection = (section, selectedActivity) => {
  const currentActivity = {
    exerciseId: section?.exerciseId || '',
    section_uuid: section?.['section_uuid'],
    title: section?.title,
    description: section?.description,
    type: section?.type,
    isCompleted: section?.isCompleted
  }

  const isAlreadyClicked = isEqual(
    omit(selectedActivity, ['isCompleted', 'exerciseId', 'description']),
    omit(currentActivity, ['isCompleted', 'exerciseId', 'description'])
  )

  return isAlreadyClicked && section?.isCompleted
    ? section?.isCompleted
    : !section?.isCompleted
}

export const removeAlreadyAnimatedActivity = (
  currentActivity,
  lastClickedActivity,
  toggleState,
  textAnimation
) => {
  const isSameActivityTitleUUID =
    lastClickedActivity?.['section_uuid'] ===
    currentActivity?.['section_uuid'] &&
    lastClickedActivity?.title === currentActivity?.title &&
    currentActivity?.type !== ASSIGNMENT
  const isSameDescription =
    lastClickedActivity?.description === currentActivity?.description
  const isAnimated =
    isEqual(
      omit(lastClickedActivity, ['isCompleted', 'exerciseId']),
      omit(currentActivity, ['isCompleted', 'exerciseId'])
    ) && currentActivity?.isCompleted
  if (
    (isAnimated && toggleState) ||
    (textAnimation && !isSameDescription && isSameActivityTitleUUID)
  ) {
    setTimeout(() => {
      localStorage.removeItem('lastClickedActivity')
    }, 4000)
  }
  return { isSameActivityTitleUUID, isSameDescription, isAnimated }
}

export const completedSection = (sections, selectedActivity) => {
  const allSections = sections?.map((section) => ({
    exerciseId: section?.exerciseId || '',
    section_uuid: section?.['section_uuid'],
    title: section?.title,
    description: section?.description,
    type: section?.type,
    isCompleted: section?.isCompleted
  }))
  const currentActivity =
    allSections?.find((section) => section?.title === selectedActivity?.title)
  const completeAllActivities =
    allSections?.every((section) => section?.isCompleted)
  const isAlreadyClicked = isEqual(
    omit(selectedActivity, ['isCompleted', 'exerciseId', 'description']),
    omit(currentActivity, ['isCompleted', 'exerciseId', 'description'])
  )
  return isAlreadyClicked && completeAllActivities
}

export const getLectureInstructors = (videos, isLecture) => {
  if (!videos?.length && !isLecture) return null
  const formattedVideos = videos?.map(
    ({
      lecturevideos_uuid: lecturevideosUuid,
      instructor: { name, instructor_uuid: instructorUuid }
    }) => ({
      lecturevideos_uuid: lecturevideosUuid,
      instructorName: name,
      instructor_uuid: instructorUuid
    }))

  return formattedVideos
}

export const getFormattedSections = (sections) => {
  if (!sections?.length) return []
  const sectionData =
    [].concat.apply([], sections?.map(section => section?.sections))

  return sectionData?.map(section => ({
    ...section,
    section_exe: pick(section, [
      'active_learning',
      'conceptMap',
      'guesswork',
      'lecture',
      'multi_lecture_videos',
      'problem_bank',
      'practice_terms',
      'quiz',
      'readings'
    ])
  }))
}

export const getActiveLearningInstructors = (
  questions,
  isActiveLearning,
  studentAnswers = {}
) => {
  if (!questions?.length && !isActiveLearning && !studentAnswers) return []
  const allQuestions = inCompletedActiveLearnings(questions, studentAnswers)
  const groupedQuestions =
    groupBy(questions, x => x.instructor?.['theme_name'] || '') || {}
  return Object.entries(allQuestions).map(([name, value]) => {
    const alreadyStarted = Object.values(groupedQuestions)
      .find((questions) => questions?.length === value?.length) || []
    const { instructor_uuid: instructorUuid } = value?.[0]?.instructor || {}
    const { Question_uuid: QuestionUUID } = value?.[0] || []
    return {
      lecturevideosUUID: alreadyStarted?.length ? '' : QuestionUUID,
      instructorName: name,
      instructor_uuid: instructorUuid
    }
  })
}

export const inCompletedActiveLearnings = (questions, studentAnswers) => {
  if (!questions?.length && !studentAnswers) return []
  const unansweredQuestionList =
    questions?.filter(({ Question_uuid: questionSetUUID }) => {
      return !studentAnswers?.[questionSetUUID]
    })
  const unansweredQuestions =
    groupBy(unansweredQuestionList, x => x.instructor?.['theme_name'] || '')

  return unansweredQuestions
}
