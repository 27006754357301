import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'

import api from '../api'

import {
  chaptersVisitedSections,
  getChapterLockCode,
  getNextChapter
} from './chapterUtils'

import {
  convertSecondsToTime,
  dateToSecondsSinceEpoch,
  secondsSinceEpoch,
  secondsToFormattedDateShort
} from './dateTime'
import { getCohortExamDates, getCohortModifier, getCohortSpecialDays, getCohortUpcomingExamDates } from './cohort'
import config from '../config'
import { CHAPTER_TYPE } from '../Constants/courseCard'
import { ASSIGNMENT_PROGRESS, EXAM_COMPLETE, GUESSWORK, QUIZ } from '../Constants/studentProgressKeys'
import { getLatestCohort } from './course'
import { isUserAuditor } from './user'
import { PREV_EXAM_DUE_DATE, PREV_EXAM_START_DATE } from '../Constants/chapterLockCodes'

export const getCourseSections = (courseData) => {
  const { chapters } = courseData
  if (!chapters) return []
  const courseSections = []
  chapters.forEach(chapter => {
    const { type, sections, chapter_uuid: chapterUuid } = chapter
    if (type === 'chapter') {
      return courseSections.push(
        ...sections.map(section => section.section_uuid)
      )
    }
    chapterUuid !== 'practice-exam' && courseSections.push(chapterUuid)
  })
  return courseSections
}

export const getMultipleVideos = (multipleVideos) => {
  if (!multipleVideos || !typeof multipleVideos === 'object') return []

  const { videos } = multipleVideos
  if (!videos || !videos.length) return []

  return videos.map(({ uuid, ...rest }, index) => ({
    id: uuid,
    order: index + 1,
    ...rest
  }))
}

export const getLectureInProgress = ({
  sectionUuid,
  videos,
  lecture,
  progress
}) => {
  const videoProgress = progress['lecture-video-progress'] || {}
  const lectureComplete = progress['lecture-complete'] || {}

  if (isEmpty(videoProgress)) return false

  const getRemainingTime = (duration, timeSpentInSeconds) => {
    if (!duration || typeof timeSpentInSeconds === 'undefined') return
    const splittedDuration = duration.split(':')

    // get hours, minutes and seconds from duration
    const hrsExist = splittedDuration.length === 3
    let hrs = 0
    let min = 0
    let sec = 0

    if (hrsExist) {
      hrs = splittedDuration[0] || 0
      min = splittedDuration[1] || 0
      sec = splittedDuration[2]
    } else {
      min = splittedDuration[0] || 0
      sec = splittedDuration[1] || 0
    }

    return convertSecondsToTime(
      parseInt(hrs) * 3600 +
              parseInt(min) * 60 +
              parseInt(sec) - parseInt(timeSpentInSeconds))
  }

  if (isEmpty(videos)) {
    // Handling for calculus courses
    // Multiple instructor videos might be present

    const lectureVideos = get(lecture, 'lecturevideos', [])
    const vid = lectureVideos[0] || {}
    // checks if lecture is completed
    const completed = sectionUuid in lectureComplete
    if (completed) return { id: vid.lecturevideos_uuid, completed }

    for (let i = lectureVideos.length - 1; i >= 0; i--) {
      const {
        kaltura_embed_code: embedCode,
        lecturevideos_uuid: id,
        duration
      } = lectureVideos[i]

      const timeSpentInSeconds = parseInt(get(videoProgress, embedCode, 0))
      const timeSpent = convertSecondsToTime(timeSpentInSeconds)
      const remainingTime = getRemainingTime(duration, timeSpentInSeconds)

      if (timeSpentInSeconds > 0) {
        return { embedCode, id, timeSpent, remainingTime }
      }

      if (i === 0) {
        return { embedCode, id, timeSpent, remainingTime }
      }
    }
  } else {
    // Handling for psychology courses
    for (let i = videos.length - 1; i >= 0; i--) {
      const { id, kalturaEmbedCode, embedCode: code, duration, order } = videos[i]
      const embedCode = code || kalturaEmbedCode
      const timeSpentInSeconds = parseInt(get(videoProgress, embedCode, 0))

      // checks if lecture is completed
      const completed = embedCode in lectureComplete
      if (completed) return { id, completed, order }

      const timeSpent = convertSecondsToTime(timeSpentInSeconds)
      const remainingTime = getRemainingTime(duration, timeSpentInSeconds)

      if (timeSpentInSeconds > 0) {
        return {
          embedCode,
          duration,
          order,
          sectionUUID: sectionUuid,
          timeSpent,
          remainingTime,
          id
        }
      }

      if (i === 0) {
        return { order, id, remainingTime }
      }
    }
  }
  return false
}

export const getVisitedQuizInfo = (studentAnswersArray, section = []) => {
  const visitedQuizzes = []
  section.forEach((s, index) => {
    const { Question, question_set_uuid: questionSetUuid } = s
    const quizData = {
      quizNo: index + 1,
      questionSetUuid,
      completedQuestion: 0,
      totalQuestion: Question.length,
      questionId: ''
    }
    Question.forEach((question) => {
      const { Question_uuid: questionUuid } = question
      const isStudentAnswerAttempted = studentAnswersArray.includes(questionUuid)
      if (isStudentAnswerAttempted) {
        quizData.questionId = questionUuid
        quizData.completedQuestion += 1
      }
    })
    visitedQuizzes.push(quizData)
  })

  return {
    visitedQuizzes
  }
}

export const getVisitedPracticeExerciseInfo = (
  studentAnswers, practiceExercises, isFirstSection
) => {
  const data = handleProblemBanks(
    studentAnswers, practiceExercises, isFirstSection
  )
  return data
}

const handleProblemBanks = (studentAnswers, practiceExercises, isFirstSection) => {
  for (let i = practiceExercises.length - 1; i >= 0; i--) {
    const {
      question_set_uuid: questionSetUuid,
      title
    } = practiceExercises[i]

    const answer = studentAnswers[questionSetUuid]
    if (!answer) continue

    const { hasPopupQuestionAttempted = false, isPracticeCompleted, trials = [] } = answer

    if (isPracticeCompleted) {
      return {
        id: questionSetUuid,
        title,
        completed: true
      }
    }

    /* For chapter 1 and section 1 we render 4 questions only.
    In other sections we render 5 questions including 1 pop up question */
    const totalQuestion = i === 0 && isFirstSection ? 4 : 5

    const visited = trials.length + (hasPopupQuestionAttempted ? 1 : 0)
    const remaining = totalQuestion - visited
    if (visited === 0) continue
    return { title, id: questionSetUuid, visited, remaining }
  }
}

export const getVisitedSectionInfo = (studentAnswersArray, section) => {
  if (!section) return {}
  const { Question } = section
  const totalQuestion = Question.length
  let completedQuestion = 0
  let questionId = ''

  Question.forEach((question) => {
    const { Question_uuid: questionUuid } = question
    const isStudentAnswerAttempted = studentAnswersArray.includes(questionUuid)
    if (isStudentAnswerAttempted) {
      completedQuestion += 1
      questionId = questionUuid
    }
  })

  return {
    totalQuestion,
    completedQuestion,
    questionId
  }
}

export const getVisitedALCardInfo = (studentAnswers, section) => {
  if (!section) return {}
  const { Question } = section
  const totalQuestion = Question.length
  let questionId = ''
  const completedQuestion = Question.filter(question => {
    const { Question_uuid: questionUuid } = question
    const { correct } = studentAnswers[questionUuid] || {}
    if (correct) {
      questionId = questionUuid
    }
    return correct
  }).length

  return {
    totalQuestion,
    completedQuestion,
    questionId
  }
}

export const getSectionData = async (course, studentProgress) => {
  const { id, chapters, name } = course
  const { studentAnswers } = studentProgress
  const studentAnswersArray = Object.keys(studentAnswers)
  let sectionFound = false
  let sectionData = null

  // fetch all chapters visited sections
  const chapterVisitedSections =
    chaptersVisitedSections(chapters, studentProgress)

  for (let i = chapterVisitedSections.length - 1; i >= 0; i--) {
    if (sectionFound) break
    const { chapterNo, visitedSections } = chapterVisitedSections[i]

    for (let j = visitedSections.length - 1; j >= 0; j--) {
      const {
        sectionNo,
        sectionUuid,
        isGuessworkVisited,
        isActiveLearningVisited,
        isQuizVisited
      } = visitedSections[j]

      // fetch highest chapter section data
      sectionData = await api.loadSectionData({
        activeCourseUUID: id,
        uuid: sectionUuid,
        latestCohort: getLatestCohort(course),
        courseName: name
      })
      const { section_exe: sectionExe } = sectionData

      const {
        guesswork,
        quiz,
        active_learning: activeLearning,
        lecture,
        multi_lecture_videos: multipleVideos,
        practice_exercises: practiceExercises
      } = sectionExe
      const sectionVideos = getMultipleVideos(multipleVideos)
      const lectureInProgress = getLectureInProgress({
        sectionUuid,
        videos: sectionVideos,
        lecture,
        progress: studentProgress
      })

      // Checks if student last activity was on quizzes //
      if (isQuizVisited) {
        const { visitedQuizzes } =
          getVisitedQuizInfo(studentAnswersArray, quiz)
        for (let i = visitedQuizzes.length - 1; i >= 0; i--) {
          const { questionId } = visitedQuizzes[i]
          if (questionId) {
            sectionFound = true
            break
          }
        }
        if (sectionFound) break
      }

      // Checks if student last activity was on Practice Exercises //
      if (!isEmpty(practiceExercises)) {
        const isFirstSection = chapterNo === 1 && sectionNo === 1
        const visitedPracticeExercises = getVisitedPracticeExerciseInfo(
          studentAnswers,
          practiceExercises,
          isFirstSection
        )
        if (visitedPracticeExercises) {
          sectionFound = true
          break
        }
      }

      // Checks if student last activity was on Active Learning //
      if (isActiveLearningVisited) {
        const { questionId } =
          getVisitedALCardInfo(studentAnswers, activeLearning)
        if (questionId) {
          sectionFound = true
          break
        }
      }

      // Checks if student last activity was on Lecture //
      if (lectureInProgress) {
        sectionFound = true
        break
      }

      // Checks if student last activity was on Guesswork //
      if (isGuessworkVisited) {
        const { questionId } =
          getVisitedSectionInfo(studentAnswersArray, guesswork)
        if (questionId) {
          sectionFound = true
          break
        }
      }
    }
  }

  return sectionData
}

export const getNextAvailableSection = (course, chapterInfo) => {
  if (!course || !chapterInfo) return {}

  const { id, cohort, chapters } = course
  const { chapterNo, chapterUuid, sectionNo } = chapterInfo
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)
  const chapterIndex =
    chapters.findIndex(chapter => chapter.chapter_uuid === chapterUuid)
  const chapter = chapters[chapterIndex]

  if (sectionNo < chapter.sections.length) { // if chapter's next section exists
    const nextSection = chapter.sections[sectionNo]
    const nextSectionUrl = `${courseBaseUrl}/#/${nextSection.section_uuid}`
    return {
      nextChapterNo: chapterNo,
      nextSectionNo: sectionNo + 1,
      nextSectionUrl
    }
  }

  if (chapterIndex < chapters.length - 1) { // if chapter next section don't exist
    const nextChapter = chapters[chapterIndex + 1]
    const { type } = nextChapter
    if (type === 'chapter') { // check if we next chapter is chapter not exam
      const nextSection = nextChapter.sections[0]
      const nextSectionUrl = `${courseBaseUrl}/#/${nextSection.section_uuid}`
      return {
        nextChapterNo: chapterNo + 1,
        nextSectionNo: 1,
        nextSectionUrl
      }
    } else { // check if next chapter is exam not chapter
      const currentTime = secondsSinceEpoch()
      const upcomingExamDates =
        getCohortUpcomingExamDates(cohort, currentTime, nextChapter)
      const IS_EXAM = !upcomingExamDates && !nextChapter.isFinalExam
      if (IS_EXAM) {
        const { nextChapter, currentChapterIndex } = getNextChapter(
          chapters,
          chapterUuid
        )
        if (!nextChapter) return {}

        const nextSection = nextChapter.sections[0]
        const nextSectionUrl = `${courseBaseUrl}/#/${nextSection.section_uuid}`
        return {
          nextChapterNo: currentChapterIndex + 2,
          nextSectionNo: 1,
          nextSectionUrl
        }
      }
    }
  }

  return {}
}

export const getSectionGuessworkUUID = (section, chapters) => {
  const { section_exe: sectionTypes, section_uuid: sectionUUID } = section

  const hasGuesswork = !!sectionTypes.guesswork
  if (hasGuesswork) return sectionUUID

  const previousSectionWithGuesswork = getPreviousSectionWithGuesswork(
    sectionUUID,
    chapters
  )

  if (!previousSectionWithGuesswork) return null
  const { section_uuid: prevSectionUUID } = previousSectionWithGuesswork

  return prevSectionUUID
}

export const getPreviousSectionWithGuesswork = (sectionUUID, chapters) => {
  const previousSection = getPreviousSection(sectionUUID, chapters)

  if (!previousSection) return null
  const { guessworkUUID, section_uuid: prevSectionUUID } = previousSection
  if (guessworkUUID) return previousSection

  return getPreviousSectionWithGuesswork(prevSectionUUID, chapters)
}

export const getPreviousSection = (sectionUUID, chapters) => {
  const currentChapter = getChapterOfASection(sectionUUID, chapters)

  if (!currentChapter) return null

  const { sections } = currentChapter

  const currentSectionIndex = sections.findIndex(
    section => section.section_uuid === sectionUUID
  )
  const isFirstSection = currentSectionIndex === 0

  if (!isFirstSection) return sections[currentSectionIndex - 1]

  const currentChapterIndex = chapters.findIndex(
    chapter => chapter.chapter_uuid === currentChapter.chapter_uuid
  )

  return getLastSectionFromPrevChapters(currentChapterIndex, chapters)
}

export const getChapterOfASection = (sectionUUID, chapters) => {
  if (!sectionUUID || !chapters || !chapters.length) return null

  const currentChapter = chapters.find(chapter => {
    const isChapterOrOrientation =
      chapter.type === CHAPTER_TYPE.CHAPTER ||
      chapter.type === CHAPTER_TYPE.ORIENTATION
    if (!isChapterOrOrientation) return false

    const { sections } = chapter

    return sections.some(section => section.section_uuid === sectionUUID)
  })

  return currentChapter || null
}

export const getLastSectionFromPrevChapters = (chapterIndex, chapters) => {
  const previousChapters = chapters.slice(0, chapterIndex)
  let previousChapter = null

  for (let i = previousChapters.length - 1; i >= 0; i--) {
    const chapter = previousChapters[i]

    if (chapter.type !== CHAPTER_TYPE.CHAPTER) continue
    const { sections } = chapter

    if (!sections || !sections.length) continue

    previousChapter = chapter
    break
  }

  if (!previousChapter) return null

  const { sections } = previousChapter
  return sections[sections.length - 1]
}

export const getGuessworkLockCode = (opts) => {
  const {
    isAuditor,
    isVIP,
    isVIPGradedContent,
    isStudioCohort,
    studentProgress,
    chapters,
    sectionUUID,
    latestCohort,
    examRetakes,
    courseUnits,
    courseResourcesSchedule,
    courseId
  } = opts || {}
  const chapterIndex = chapters?.findIndex(chapter => {
    return chapter?.sections?.some(
      currentSection => sectionUUID === currentSection.section_uuid
    )
  }) || -1
  if (chapterIndex < 0) return false
  const {
    id,
    auditContentLock,
    milestones
  } = latestCohort || {}
  const lockCodeOptions = {
    ...opts,
    examRetake: examRetakes,
    chapters,
    courseUnits,
    cohortModifier: getCohortModifier(chapters, latestCohort?.duration),
    cohortMilestones: milestones,
    cohortSpecialDays: getCohortSpecialDays(latestCohort),
    cohortExamDates: getCohortExamDates(latestCohort),
    assignmentsProgress: studentProgress?.[ASSIGNMENT_PROGRESS],
    examsCompleted: studentProgress?.[EXAM_COMPLETE],
    courseUnlockDate: dateToSecondsSinceEpoch(new Date(latestCohort?.dateStart)),
    isAuditor,
    isVIP,
    isVIPGradedContent,
    isStudioCohort,
    cohortID: id,
    courseId,
    auditContentLock,
    courseResourcesSchedule
  }
  return getChapterLockCode(chapterIndex, lockCodeOptions)
}

export const getSectionExerciseLockCode = (exerciseType, opts) => {
  const { isAdmin, isStudioCohort, isVIP, isVIPGradedContent } = opts

  if (
    isAdmin ||
    isVIPGradedContent ||
    isStudioCohort ||
    config.isPreviewCourse
  ) {
    return false
  }

  const {
    latestCohort,
    studentProgress,
    guessworkSectionUUID,
    isCohortEndedForStudent
  } = opts

  // Unlock all exercises but Quiz for VIP accounts
  if (isVIP && exerciseType !== QUIZ) return false

  const isAuditor = isUserAuditor(latestCohort) ||
    latestCohort?.name?.toUpperCase()?.includes('AUDIT')
  if (isAuditor && exerciseType === QUIZ) {
    return (
      isCohortEndedForStudent && getSectionExerciseLockCode.reason.COURSE_ENDED
    )
  }

  // no guessworkSectionUUID means section has no guesswork and there is
  // also no sibling section(sections in the same chapter) with guesswork before it.
  // so we unlock all section items.
  if (isAuditor || !guessworkSectionUUID) return false

  if (exerciseType === GUESSWORK) return getGuessworkLockCode(opts)

  if (exerciseType === QUIZ && opts.isNoAssessments) {
    return getSectionExerciseLockCode.reason.NO_ASSESSMENTS
  }

  if (
    exerciseType === QUIZ &&
    opts.finalExamLockDate !== null &&
      opts.currentDate >= opts.finalExamLockDate
  ) {
    return getSectionExerciseLockCode.reason.AFTER_FINAL_EXAM_DUE_DATE
  }

  if (config?.course?.unlockExercises) return false

  if (isGuessworkCompleteForSection(studentProgress, guessworkSectionUUID)) {
    return false
  }

  return getSectionExerciseLockCode.reason.GUESSWORK_NOT_COMPLETED
}

getSectionExerciseLockCode.reason = {
  GUESSWORK_NOT_COMPLETED: 1,
  AFTER_FINAL_EXAM_DUE_DATE: 2,
  NO_ASSESSMENTS: 3,
  COURSE_ENDED: 4
}

export const getSectionExerciseLockText = (exercise, opts) => {
  const lockCode = getSectionExerciseLockCode(exercise, opts)

  const { isVIP } = opts

  if (!lockCode) return lockCode

  if ([PREV_EXAM_DUE_DATE, PREV_EXAM_START_DATE].includes(lockCode)) {
    return 'Previous exam must be completed'
  }

  if (lockCode === getSectionExerciseLockCode.reason.GUESSWORK_NOT_COMPLETED) {
    return 'Complete Guesswork to unlock'
  }

  if (lockCode === getSectionExerciseLockCode.reason.NO_ASSESSMENTS) {
    if (isVIP) return 'Assessment unavailable due to VIP account'
    return 'Assessment unavailable due to enrollment status'
  }

  if (
    lockCode === getSectionExerciseLockCode.reason.AFTER_FINAL_EXAM_DUE_DATE
  ) {
    const formattedDate = secondsToFormattedDateShort(opts.finalExamLockDate)
    return `Final Exam Past Due (${formattedDate})`
  }

  if (lockCode === getSectionExerciseLockCode.reason.COURSE_ENDED) {
    return 'Course is Closed'
  }
}

export const isGuessworkCompleteForSection = (studentProgress, sectionId) => {
  const guessWorkComplete = studentProgress?.['guesswork-complete']
  return !!guessWorkComplete && !!guessWorkComplete[sectionId]
}

export const hasActiveLearningOpened = (activeLearningOpened, sectionUUID) => {
  return activeLearningOpened[sectionUUID]
}

export const getNumberOfCompletedCardsFromSet = (cards, studentAnswers) => {
  if (!cards || !studentAnswers) return 0

  return cards.reduce((total, card) => {
    const isCardCompleted = studentAnswers.some(answer => {
      const { uuid, correct } = answer
      return (card.Question_uuid === uuid) && correct
    })

    return isCardCompleted ? total + 1 : total
  }, 0)
}

export const hasAllActiveLearningCardsCompleted = (cards, studentAnswers) => {
  if (!cards || !cards.length || !studentAnswers) return false

  const numberOfCompletedCards = getNumberOfCompletedCardsFromSet(
    cards, studentAnswers
  )
  const totalCards = cards.length

  return numberOfCompletedCards === totalCards
}

export const hasAllActiveLearningCardsCompletedForThemes = (
  activeLearning, studentAnswers
) => {
  const { Question } = activeLearning
  const groupedCardsByTheme = groupBy(
    Question, card => card.instructor?.['theme_name'] || ''
  )

  return Object.values(groupedCardsByTheme).some(cards => {
    return hasAllActiveLearningCardsCompleted(cards, studentAnswers)
  })
}

export const getStudentAnswersArray = (studentAnswers) => {
  const studentAnswersArray = []
  if (!studentAnswers) return studentAnswersArray
  for (const uuid in studentAnswers) {
    studentAnswersArray.push({
      uuid,
      ...studentAnswers[uuid]
    })
  }
  return studentAnswersArray
}

// A function which checks if an active learning is completed for a section,
// and it also considers courses with active learning theme, that means if one of the theme
// is completed, it will treat as section active learning is completed
export const isSectionRequiredActiveLearningCompleted = ({
  courseId,
  sectionUUID,
  activeLearning,
  studentAnswers,
  activeLearningCompleteObject
}) => {
  // If section does not have active learning, consider active learning as completed
  if (!activeLearning?.Question) return true

  const studentAnswersArray = getStudentAnswersArray(studentAnswers)

  if (config.hasCourseActiveLearningTheme(courseId)) {
    // check if all active learning cards are completed for at least one theme
    return hasAllActiveLearningCardsCompletedForThemes(activeLearning, studentAnswersArray)
  }

  return !!activeLearningCompleteObject?.[sectionUUID]
}
