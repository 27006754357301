import styled, { createGlobalStyle, css } from 'styled-components'
import Tooltip from 'react-bootstrap/Tooltip'
import DatePicker from 'react-datepicker'
import BackBreadcrumb from '../../BackBreadcrumb/BackBreadcrumb'
import { LinkStyles } from '../../Links/style'
import { ReactComponent as BoxArrowUpRight } from '../../../assets/icons/box-arrow-up-right.svg'
import noMediaImage from '../../../assets/images/no-media.png'

export const OverrideCSSProperties = createGlobalStyle`
    body {
        padding-bottom: 0px;
        min-height: fit-content!important;
        ${({ displayModal }) =>
          displayModal && 'height: 100%; overflow: hidden;'}
        
        ::-webkit-scrollbar {
          width: 0px;
        }
    }
    footer {
      background-color: #000000!important;
    }
    #content {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;
        margin-right: 0px;
        max-width: 100vw;
    }

    .tooltip.show {
        opacity: 1;
    }
`
export const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 117% auto;
  width: 100%;
  max-width: 1920px;
  height: fit-content;
  margin: 0px auto;
  padding: 0px;
  overflow-x: hidden;
`
export const HeroImage = styled.div`
  background-image: ${({ imgUrl }) => (imgUrl ? `url(${imgUrl})` : `url(${noMediaImage})`)};
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
  min-width: 360px;
  height: 400px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  overFlow: visible!important;
`
export const GradientOverlay = styled.div`
  width: 100%;
  min-Width: 360px;
  height: 105%;
  background-image: linear-gradient(to top, rgb(22, 22, 24) 5%, transparent 76%);
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  width: 100vw;
  min-width: 360px;
  min-height: ${({ trailerVideo }) => (trailerVideo ? 'calc(100vh - 476px)' : 'calc(100vh - 64px)')};
  ${({ showAlternateStates }) => (showAlternateStates && 'min-height: 100vh;')}
  max-height: 1200px;
  padding-bottom: 45px;
  background-position: top right;
  background-size: 100% auto;
  background-repeat: no-repeat;

  @media (min-width: 992px) {
    background-size: auto 100%;
    background-image: linear-gradient(to right,#161618 45%,transparent 81%), ${({ imgUrl }) => (imgUrl ? `url(${imgUrl})` : `url(${noMediaImage})`)};
  }

  @media (min-width: 1200px) {
    background-image: linear-gradient(to right, #161618 52%, transparent 77%), ${({ imgUrl }) => (imgUrl ? `url(${imgUrl})` : `url(${noMediaImage})`)};
  }
  ${props => props.isGGUStudentView
  ? css`
    @media (min-width: 1340px) {
      background-image: linear-gradient(to right, #161618 ${({ trailerVideo }) => (trailerVideo ? '60%' : '45%')}, transparent 80%), ${({ imgUrl }) => (imgUrl ? `url(${imgUrl})` : `url(${noMediaImage})`)};
    }
  `
  : css`
    @media (min-width: 1500px) {
      background-image: linear-gradient(to right, #161618 54%, transparent 80%), ${({ imgUrl }) => (imgUrl ? `url(${imgUrl})` : `url(${noMediaImage})`)};
    }
  `
  };

  @media (max-width: 768px) {
  ${({ trailerVideo }) => (!trailerVideo && css`
    background-position: bottom;
    background-size: cover;
    background-image: linear-gradient(to bottom,#161618 45%,transparent 82%), ${({ imgUrl }) => (imgUrl ? `url(${imgUrl})` : `url(${noMediaImage})`)};
    `
  )}
`
export const CardWrapper = styled.div`
  padding: 0px 24px;
  max-width: 530px;
  ${({ showPretestStates }) =>
  (`padding-bottom: ${showPretestStates ? '0px!important' : '115px'};`)}

  @media(min-width: 768px) {
    padding: 0px 0px 190px 64px;
  }

  @media(min-width: 1200px) {
    padding: 0px 0px 200px 64px;
  }  

  @media(min-width: 1440px) {
    padding: 0px 0px 185px 64px;
  }

  h2 {
    margin-top: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #b1bfc5;

    button {
      color: #5fc4b8;
      text-decoration: underline;
      border: none;
      outline: none;
      background-color: transparent;
      padding: 0px;
    }
  }
  
`
export const ManageCourseLink = styled.button`
  display: block;
  font-family: Lato;
  outline: none!important;
  /* identical to box height */

  margin-top: -8px;
  border: none;
  background-color: transparent;
  padding: 0px;
  
  ${LinkStyles}
`
export const BreadCrumbComponent = styled(BackBreadcrumb)`
  margin: 24px 0px 0px 24px;
  padding: 0px;
  height: 24px;

  @media (min-width: 768px) {
    margin-left: 64px;
  }
`
export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 112px 24px 0px 24px;
  height: 112px;
  ${props => props.isGGUStudentView && css`
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: space-between;
    height: fit-content;
  `}
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  @media (min-width: 445px) {
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 100%;
    margin: 152px 64px 0px 64px;
    height: fit-content;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: space-between;
    ${props => !props.isGGUStudentView &&
      'padding-bottom: 4px;'
    }
  }

  @media (min-width: 992px) {
    margin-top: 32px;
  }
`
export const CourseTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 48px;
  line-height: 48px;
  font-size: 36px;
  font-weight: 300;
  margin-right: 24px;
  ${props => !props.isGGUStudentView &&
    'width: 100%;'
  }

  @media (min-width: 768px) {
    width: fit-content;
    max-width: 390px;
  }

  @media (min-width: 992px) {
    width: fit-content;
    max-width: 500px;
  }
`
export const TokensInfoWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 8px 0 32px 0px;
  justify-content: flex-start;
`
export const Tokens = styled.div`
  line-height: 17px;
  font-size: 16px;
  font-weight: 400;
  color: #b1bfc5;

  @media (min-width: 768px) {
    line-height: 19px;
  }
`
export const AddTokens = styled.div`
  line-height: 17px;
  color: #5fc4b8;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 8px;
  font-style: normal;
  letter-spacing: 1px;
`
export const ShoppingBagWrapper = styled.div`
  position: relative;
  margin-left: auto;

  span {
    position: absolute;
    bottom: 6px;
    right: 9px;
    width: 6px;
    line-height: 11px;
    font-size: 9px;
    height: 11px;
    color: #5fc4b8;
  }

  @media (min-width: 768px) {
      margin-left: 26px;
    }
`
export const ShoppingBag = styled.img`
  width: 24px;
  height: 24px;
`

export const AddCourseTitle = styled.div`
  width: 100%;
  height: 29px;
  margin: 32px 24px 0px 24px;
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;

  @media (min-width: 768px) {
    margin: 32px 64px 0px 64px;
  }
`
export const FormWrapper = styled.form`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  margin-right: 24px;

  & > div {
    margin: 24px 0px 0px 24px;
  }

  @media (min-width: 768px) {
    margin-right: 64px;

    & > div {
      margin: 24px 0px 0px 64px;
    }
  }

  @media (min-width: 992px) {
    width: 50% !important;
  }
`
export const Forms = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding-right: 24px;
  width: 100%;
  justify-content: space-between;
  ${({
     showPretestStates,
    showAlternateStates
  }) =>
    ((showPretestStates && showAlternateStates) && 'margin-top: 0px!important;')}

  & > div {
    height: fit-content;
    margin-bottom: 16px;
  }

  & > div h3 {
    color: #98a4ae;
    font-size: 16px;
    line-height: 19px;
  }

  & > div h3 span {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    top: 2px;
    margin-left: 12px;
    background-image: url(images/icons/tooltip-i.svg);
  }

  @media (min-width: 576px) {
    flex-flow: row nowrap;
  }

  @media (min-width: 768px) {
    padding-right: 195px;

    & > div {
      margin-bottom: 0px;
    }
  }

  @media (min-width: 992px) {
    padding-right: 0px;
  }
`
export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    font-family: Lato, Sans-Serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    width: 255px;
    max-width: none;
    padding: 12px;
    color: #ffffff;
    text-align: left;
    background-color: #25272b;
    border-radius: 0.25rem;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 50%);
  }
`
export const WiderStyledTooltip = styled(StyledTooltip)`
  width: 231px;
  .tooltip-inner {
    ${({ width }) => width && `width: ${width}px`};
  }
`
export const StyledLabel = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 28px;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  color: ${({ radioDisabled }) => (radioDisabled ? 'gray!important' : 'white')};
  margin-top: 12px;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & > input:disabled ~ span {
    border: 1px solid gray !important;
    background-color: transparent !important;
  }
  /* Create a custom radio button */

  & > span {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 1px solid #5fc4b8;
    border-radius: 50%;
  }

  /*on mouse-over add a background color  */

  &:hover input ~ span {
    background-color: transparent;
  }

  & > input:checked ~ span {
    background-color: #5fc4b8;
  }
`
export const CalendarDatePicker = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  margin-top: -4px;

  & > div {
    z-index: 10;
  }

  & > span {
    position: absolute;
    background-image: ${({ disabled }) => disabled ? 'none' : 'url(images/icons/icon-calendar.svg)'};
    width: 16px;
    height: 16px;
    right: 0px;
    top: 13px;
    z-index: 0;
  }
`
export const StyledDatePicker = styled(DatePicker)`
  width: 140px;
  line-height: 20px;
  padding-left: 0px;
  padding-bottom: 6px;
  font-size: 16px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ disabled }) => disabled ? 'gray' : '#5fc4b8'};
  cursor: pointer;
`
export const EndDate = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-top: 6px;
`
export const SyllabusLink = styled.div`
  width: 100%;
  margin-top: 16px;
  color: ${props => !props.disabled
    ? '#5fc4b8'
    : 'rgba(95, 196, 184, 0.5)'
  };
  user-select: none;
  ${LinkStyles};

  @media(min-width: 576px) {
    margin-top: 8px;
  }

  @media(min-width: 768px) {
    margin-top: 24px;
  }
`
export const CostWrapper = styled(SyllabusLink)`
  margin-top: 32px;
  color: #ffffff;

  @media(min-width: 576px) {
    margin-top: 32px;
  }
`

export const SubmitButtonWrapper = styled(SyllabusLink)`
  width: 100%;
  height: 40px;
  padding-top: 0px;
  padding-right: 24px;
  margin-top: ${({ mt }) => mt ? `${mt}px` : '32px'}!important;

  button {
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    line-height: 17px;
    letter-spacing: 1px;
    border: none;
    border-radius: 5px;
    background-color: #5fc4b8;
    width: 100%;
    height: 100%;
    outline: none!important;
    ${({ uppercase }) => uppercase && 'text-transform: uppercase'};
    ${({ isSwitchBtn }) => isSwitchBtn && `
      width: fit-content!important;
      padding-left: 16px;
      padding-right: 16px;
    `}
  
    ${props => !props.disabled && css`
      :active {
        background-color: #FFFFFF;
        color: black;
      }
    `}
  }

  button:disabled {
    color: black;
    opacity: 0.3;
  }

  @media (min-width: 576px) {
    & > button {
      width: 140px;
      margin-left: 0px;
    }
  }
`
export const VideoWrapper = styled.div`
  width: 100%;
  min-width: 360px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  background-color: #161618;

  @media (min-width: 992px) {
    padding-bottom: 41px;
    background-color: transparent;
  }
`
export const KalturaVideoPlayer = styled.div`
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1 / 0.89;
  background-color: black;
  margin: 0px auto 0 auto;

  @media (min-width: 576px) {
    aspect-ratio: 1 / 0.56;
  }

  @media (min-width: 768px) {
    aspect-ratio: 1 / 0.42;
  }

  @media (min-width: 992px) {
    width: 50%;
    aspect-ratio: 1 / 0.4;
  }

  @media (min-width: 1200px) {
    aspect-ratio: 1 / 0.6;
  }
`

export const TileImage = styled.img`
  width: 100%;
  object-fit: cover;
  aspect-ratio:auto 1 / 0.89;
  background-color: black;
  margin: 0px auto 0 auto;

  @media (min-width: 576px) {
    aspect-ratio: 1 / 0.56;
  }

  @media (min-width: 768px) {
    aspect-ratio: 1 / 0.42;
  }

  @media (min-width: 992px) {
    width: 50%;
    aspect-ratio: 1 / 0.4;
  }

  @media (min-width: 1200px) {
    aspect-ratio: 1 / 0.6;
  }
`
export const LongDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: black;
  padding: 64px 24px 105px 24px;
  font-size: 18px;
  font-weight: 300;
  line-height: 21px;
  color: #b1bfc5;

  @media (min-width: 576px) {
    padding: 64px 32px 105 32px;
  }

  @media (min-width: 768px) {
    padding: 64px 64px 105px 64px;
  }

  @media (min-width: 992px) {
    width: 50%;
    padding: 64px;
  }
`
export const SeeAllDetailsLink = styled.div`
  margin-top: 24px;
  user-select: none;
  ${LinkStyles}
`

export const DetailsLinkIcon = styled(BoxArrowUpRight)`
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  top: 1px;
  margin-left: 12px;
  margin-bottom: 6px;
`

/* Modal styles */

export const ModalWrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Modal = styled.div`
  width: 327px;
  height: 359px;
  border-radius: 5px;
  background-color: #25272b;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);

  @media (min-width: 768px) {
    width: 576px;
    height: 256px;
  }
`
export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px;

  h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    color: #ffffff;
    margin: 0px;
  }
`
export const Close = styled.div`
  width: 14px;
  height: 14px;
  background-image: url(/images/icons/icon-close.svg);
  margin-right: 5px;
  cursor: pointer;
`
export const ModalBody = styled.div`
  padding: 0px 24px;
`
export const ConfirmationStatement = styled.div`
  display: flex;
  margin: 24px 0 0 0;
`
export const CustomCheckBox = styled.label`
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  height: 19.2px;
  margin: 0px;
  text-align: top;
  p {
    margin: 0px;
    margin-top: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  input {
    position: absolute;
    top: 1.5px;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 1px solid #5fc4b8;
    box-sizing: border-box;
    border-radius: 3px;
  }

  /* On mouse-over, add a transparent background color */
  &:hover input ~ span {
    background-color: transparent;
  }

  /* When the checkbox is checked, add a transparent background */
  & input:checked ~ span {
    background-color: transparent;
  }

  span:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ span:after {
    display: block;
  }

  /* Style the checkmark/indicator */

  span:after {
    left: 4.5px;
    top: 1.3px;
    width: 5px;
    height: 10px;
    border: solid #5fc4b8;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
export const Description = styled.div`
  padding: 6px 24px 0px 28px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  button {
    display: block;
    border: none;
    width: 279px;
    height: 49px;
    margin-bottom: 16px;
    text-transform: uppercase;
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
  }
  button:first-child:disabled {
    background: #5fc4b8!important;
    opacity: 0.3!important;
    color: black;
  }
  button:first-child {
    background-color: #5fc4b8;
  }
  button:last-child {
    background-color: transparent;
    color: #5fc4b8;
    border: 1px solid #5fc4b8;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    button {
      width: 154px;
      margin-right: 16px;
    }
  }
`
export const PretestStateWrapper = styled.div`
  margin-top: 24px;
  margin-left: 24px;
  width: 504px;
  min-width: 100%;
  padding-right: 50px;

  @media(min-width: 768px) {
    margin-left: 64px;
  }

  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #b1bfc5;
    margin-bottom: 0px;
  }
`
export const OtherOptionsWrapper = styled(PretestStateWrapper)`
  margin-top: 24px;
`
export const PretestSectionButton = styled(ManageCourseLink)`
  margin-top: 8px;
  span {
    margin-right: 8px;
  }
`
export const MessageWrapper = styled.div`
  margin-left: 64px;
  margin-top: 32px;
  width: 512px;

  @media (max-width: 768px) {
    margin-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`
export const Message = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
`
export const PostEnrollmentMessage = styled(Message)`
  margin-top: 66px;
  padding-bottom: 135px;
`
export const PreEnrollmentMessage = styled(Message)`
  margin-top: 30px;
  padding-bottom: 135px;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
`
export const Link = styled.span`
  color: #5FC4B8;
  text-decoration: underline;
  cursor: pointer;
`
export const MailToLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
`
export const SemesterTerm = styled.h2`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 0px;
`
export const CustomLink = styled.a`
  color: #5FC4B8 !important;
  border-bottom: 1px solid #5FC4B8;

  :hover {
    text-decoration: none;
  }
`

export const GGUMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 48px;
  margin-bottom: 73px;
`

export const ProfCertMessage = styled.span`
  color: #CCD4D8;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
