import {
  convertEasternTimeToLocalTime,
  getDateStringWithYearLongMonth
} from './dateTime'
import { StudentStatus, ExitOptions } from '../Constants/accountArea'
import { NYAS_RELATIONSHIP } from '../Constants/relationships'
export const getCohortStatus = (weeks) => {
  return `${weeks} Weeks`
}

export const getStudentStatus = (status) => {
  const { SIGNED_UP, PREDROP, ENROLLED, AUDIT_TRANSCRIPT, AUDIT } = StudentStatus
  /* Status that are considered to be onboarded to course  */
  const isEnrolled = [SIGNED_UP, PREDROP, ENROLLED].includes(status)
  if (isEnrolled) return ENROLLED

  /* Audit status could be both simple audit or a transcript */
  const isAudit = [AUDIT, AUDIT_TRANSCRIPT].includes(status)
  return isAudit ? 'Auditing' : status
}

export const getUpComingDeadline = (studentData) => {
  const {
    finalWithdrawalDate,
    finalDropDate,
    studentStatus,
    isAmazon,
    isGuild,
    showGuildExitOptions,
    isGGU,
    isPartnership,
    isProfCert
  } = studentData

  if (studentStatus === 'Withdraw' || isProfCert) return ''

  const now = new Date()
  const drop = convertEasternTimeToLocalTime(finalDropDate)
  const withdraw = convertEasternTimeToLocalTime(finalWithdrawalDate)
  const dropDeadline = getDateStringWithYearLongMonth(
    drop?.toISOString(), 'short'
  )

  if (isGuild) return showGuildExitOptions ? `Last day to transfer: ${dropDeadline}` : ''

  const withdrawDeadline = getDateStringWithYearLongMonth(
    withdraw?.toISOString(), 'short'
  )
  const isAuditing = studentStatus === 'Auditing'
  if (studentStatus === 'Withdraw') return ''

  if (drop > now) {
    const isEnrolled = studentStatus === 'Enrolled'
    let nonAmazonStudentText

    if (isAmazon) {
      return `Last day to transfer: ${dropDeadline}`
    }

    if (isGGU) {
      return `Last day to drop: ${dropDeadline}`
    }

    if (isEnrolled && isPartnership) {
      nonAmazonStudentText = 'drop or audit'
    } else if (isEnrolled && !isPartnership) {
      nonAmazonStudentText = 'drop, transfer or audit'
    } else if (!isEnrolled && !isPartnership) {
      nonAmazonStudentText = 'drop or transfer'
    } else {
      nonAmazonStudentText = 'drop'
    }

    return `Last day to ${nonAmazonStudentText}: ${dropDeadline}`
  }

  if (!isAuditing && withdraw > now) {
    return `Last day to withdraw: ${withdrawDeadline}`
  }

  return ''
}

export const isUpcomingOrCurrentCohort = (cohort) => {
  const endDate = new Date(cohort.finalExamEndTime)
  const now = new Date()
  return (endDate.getTime() > now.getTime())
}

export const hasAccessStatus = (status) => {
  const { DROP, MELT, ADMINSTRATIVE_DROP } = StudentStatus
  return ![DROP, MELT, ADMINSTRATIVE_DROP].includes(status)
}

export const getEmailSubject = (option, courseName) => {
  const { AUDIT, DROP, REQUEST_EXCEPTION, WITHDRAW } = ExitOptions
  switch (option) {
    case AUDIT:
      return `I’d like to audit ${courseName}`
    case DROP:
      return `I’d like to drop ${courseName}`
    case WITHDRAW:
      return `I’d like to withdraw from ${courseName}`
    case REQUEST_EXCEPTION:
      return `I’d like to exit ${courseName} after its withdrawal deadline.`
    default:
      return ''
  }
}

export const showSelfServiceOptions = (isPartnership, partnerName) => {
  return !isPartnership || partnerName === NYAS_RELATIONSHIP
}
