import styled, { css } from 'styled-components'
import Collapse from 'react-bootstrap/Collapse'
// import media from '../../../mediaQueries'
import { ReactComponent as ProgressEmpty } from '../../../assets/icons/icon-new-progress-empty.svg'
import { ReactComponent as ActiveCircle } from '../../../assets/icons/active-circle.svg'
import { ReactComponent as WarningCircle } from '../../../assets/icons/warning-circle.svg'
import { ReactComponent as CheckCircle } from '../../../assets/icons/icon-check-circle-unfilled.svg'

export const DisplayWrapper = styled.div`
  margin-bottom: 48px;
`

export const DisplayHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  h2 {
    margin-bottom: 0;
  }

  a {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;

    :hover {
      text-decoration: none;
    }
  }

  > div {
    width: 24px;
    height: 24px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    > img {
      width: 14px;
      height: 8px;
    }
  }


  ${({ open }) => open && css`
    img {
      transform: rotate(180deg);
    }
  `}
`

export const TextCollapse = styled(Collapse)`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 0;
`

export const CustomCollapse = styled(Collapse)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 24px;
  padding: 24px;
  gap: 16px;
  background: linear-gradient(180deg, #1D1D1F 0%, rgba(29, 29, 31, 0) 100%);
  border-radius: 10px;

  font-weight: 300;
  font-size: 18px;
  line-height: 24px;

  p {
    margin-bottom: 0;
  }

  .grey {
    color: #CCD4D8;
  }
`

export const WeekWrapper = styled.div`
  height: 55px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  overflow: hidden;
  flex-wrap: wrap;
`
export const DayWrapper = styled.div`
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ separator }) => separator && css`
    border-right: 0.5px solid #5B6067;
    padding-right: ${({ isFirst }) => isFirst ? '12px' : '20px'};
  `}

  h3 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: ${({ isActiveDay }) => isActiveDay ? '#fff' : '#CCD4D8'};
  }
`

export const SessionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`

export const SessionContainer = styled.div`
  min-width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 6px;
  gap: 6px;
  background: #232327;
  border-radius: 5px;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
    color: ${({ isPast, isActive }) => isPast || isActive ? '#fff' : '#CCD4D8'};
    opacity: ${({ isPast }) => isPast && '0.5'};
  }
`

export const ProgressEmptyIcon = styled(ProgressEmpty)`
  width: 16px;
  height: 16px;

  circle {
    stroke: #fff !important;
  }
`

export const ActiveIcon = styled(ActiveCircle)`
`

export const WarningIcon = styled(WarningCircle)`
`

export const CheckIcon = styled(CheckCircle)`
  width: 16px;
  height: 16px;
`

export const Separator = styled.span`
  border-right: 0.5px solid #5B6067;
`
