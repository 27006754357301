import styled from 'styled-components'
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg'
import checkMark from '../../../assets/icons/check-mark.svg'

export const Container = styled.div`
  display: flex;
  justify-content: center;

  & > div.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 600px;

    @media (max-width: 767px) {
      width: 525px;
    }

    @media (max-width: 575px) {
      width: 324px;
    }

    @media (max-width: 320px) {
      width: 250px;
    }
  }
`

export const Description = styled.p`
  font-family: Lato;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  width: 600px;
  margin-bottom: ${props => props.marginBottom || '0'}

  @media (max-width: 767px) {
    width: 525px;
  }

  @media (max-width: 575px) {
    width: 324px;
  }

  @media (max-width: 320px) {
    width: 250px;
  }

  & > a {
    text-decoration: none;
  }

  & > span {
    font-weight: bold;
    word-break: break-all;
  }
`

export const Separator = styled.hr`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  border: 0 none;
  margin: ${props => props.margin || '36px 0px'};
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div.date-of-birth {
    display: flex;

    & > input {
      width: 60px;
      margin-right: 12px;
      margin-bottom: 24px;

      &::placeholder {
        color: rgba(255, 255, 255, 0.3);
      }

      &.year {
        width: 70px;
      }
    }
  }
`

export const Label = styled.label`
  font-family: Lato;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #B1BFC5;
  margin-bottom: ${props => props.marginBottom || '6px'};

  &.error {
    color: #E1774F;
    margin-top: 8px;
    margin-bottom: 8px !important;
  }
`

export const Info = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  & .tooltiptext {
    display: none;
    width: 255px;
    background-color: #25272B;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    color: #ffffff;
    border-radius: 5px;
    padding: 12px;
    position: absolute;
    z-index: 1;
    font-size: 16px;
    line-height: 19px;
    margin-left: 25px;
    margin-top: -30px;
  }

  &:hover {
    & .tooltiptext {
      display: block;
    }
  }
`

export const TooltipIcon = styled(InfoIcon)`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-top: -2px;
`

export const Input = styled.input`
  width: 400px;
  height: 40px;
  color: white;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 12px;
  padding: 10px 11px;
  font-size: 16px;
  line-height: 19px;

  @media (max-width: 767px) {
    width: 525px;
  }

  @media (max-width: 575px) {
    width: 324px;
  }

  @media (max-width: 320px) {
    width: 250px;
  }
`

export const CheckBox = styled.label`
  margin-top: ${({ marginTop }) => marginTop};
  display: block;
  width: ${({ width }) => width || '600px'};
  position: relative;
  padding-left: 36px;
  margin-bottom: ${({ marginBottom }) => marginBottom || '36px'};
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: 767px) {
    width: 525px;
  }

  @media (max-width: 575px) {
    width: 324px;
  }

  @media (max-width: 320px) {
    width: 250px;
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  span {
    position: absolute;
    top: -3px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #161618;
    border: ${props => props.disabled ? '2px solid #5E636B' : '2px solid #5FC4B8'};
    box-sizing: border-box;
    border-radius: 5px;
  }

  input:checked ~ span {
    background-color: #5FC4B8;
    border: 3px solid #5FC4B8;
    box-sizing: border-box;
    border-radius: 5px;
  }

  span::after {
    display: none;
    position: absolute;
    content: url(${checkMark});
  }

  input:checked ~ span::after {
    left: 2px;
    display: block;
  }
`
