import React, { useContext, useEffect } from 'react'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import api from '../../api'
import { DEGREE_REGISTRATION_INCOMPLETE } from '../../Constants'
import {
  updateVWOGoalConversion,
  getCurrentProspect,
  getRecommendedCohorts,
  removeSegmentEventFromLS
} from '../../utilities/gguDegreeUtils'
import { AppContext } from '../ContextProvider/ContextProvider'
import { Page, Title, Description, PrimaryButton } from './style'
import ConfettiCanvasComponent from '../ScheduleReview/ConfettiCanvasComponent'
import { COURSE_REGISTRATION_PAGEVIEW, SCHEDULE_ADVISOR_MEETING_CLICK } from '../../Constants/eventType'

const ScheduleAdvisorMeeting = ({
  recommendedCourses,
  setIsLoading
}) => {
  const { sendEvent } = useSegmentEvents()
  const {
    studentData,
    currentSemester,
    prospects,
    updateContext,
    outlierCourses
  } = useContext(AppContext)
  const currentProspect = getCurrentProspect(prospects)
  const { semesterStart } = currentSemester || {}
  const { advisorUrl, prospectId } = currentProspect || {}

  useEffect(() => {
    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: '5-1'
    })
    updateVWOGoalConversion()
    // eslint-disable-next-line
  }, [])

  const scheduleMeeting = async () => {
    window.open(advisorUrl, '_blank', 'noopener,noreferrer')

    sendEvent({ eventName: SCHEDULE_ADVISOR_MEETING_CLICK })

    setIsLoading(true)
    await api.updateProspectData(
      prospectId, {
        callCTAclicked: new Date().toISOString(),
        tempCart: getRecommendedCohorts({
          recommendedCourses,
          outlierCourses,
          semesterStart
        })
      }
    )
    const newProspects = await api.getProspectsData()
    setIsLoading(false)

    updateContext({
      prospects: newProspects,
      studentData: {
        ...studentData,
        [DEGREE_REGISTRATION_INCOMPLETE]: false
      }
    })
  }

  return (
    <Page alignItems='left'>
      <ConfettiCanvasComponent />
      <Title textAlign='left'>
        Enrollment: complete!
      </Title>
      <p className='small-title'>What's next?</p>
      <Description>
        Your academic advisor will review your selected courses to make sure&nbsp;
        they'll keep you on track to graduation. If you have any questions in the&nbsp;
        meantime, please schedule an appointment with your Academic Advisor.&nbsp;
        Let's get started!
      </Description>
      <PrimaryButton onClick={scheduleMeeting}>
        Schedule Advisor Meeting
      </PrimaryButton>
    </Page>
  )
}

ScheduleAdvisorMeeting.displayName = 'ScheduleAdvisorMeeting'

export default ScheduleAdvisorMeeting
