import styled, { createGlobalStyle, css } from 'styled-components'
import media from '../../../../mediaQueries'
import { ReactComponent as PlusSVG } from '../../../../assets/icons/plus-circle-main.svg'
import { ReactComponent as MinusSVG } from '../../../../assets/icons/minus-sign.svg'
import { ReactComponent as CalendarPlusSVG } from '../../../../assets/icons/calendar-plus.svg'
import { ReactComponent as SparkSVG } from '../../../../assets/icons/spark.svg'
import { ReactComponent as SparkPlusSVG } from '../../../../assets/icons/spark-plus.svg'
import DatePicker from 'react-datepicker'
import Select from 'react-select'

export const PageContainer = styled.div`
  margin-top: 22px;
`

// STREAK

export const StreaksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 36px;
`

export const StreakContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 16px;
  border: 1px solid #969BFF;
  border-radius: 5px;
`

export const StreakTitle = styled.h3`
  margin-bottom: 0;
  color: #969BFF;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const StreakText = styled.p`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0;
`

export const SparkIcon = styled(SparkSVG)`
`

export const SparkPlusIcon = styled(SparkPlusSVG)`
`

// PAGE TEXT

export const PageHeader = styled.h2`
  color: #fff;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 16px;

  display: flex;
  align-items: baseline;

  img {
    margin-left: 12px;
  }
`

export const SectionTitle = styled.h3`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: ${({ marginBottom }) => marginBottom || '12px'};
`

export const SectionText = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;

  span {
    color: ${({ warning }) => warning && '#FF8064'};
  }
`

// Days Section

export const DaysContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 24px;

  ${media.smallMobile`
    display: none;
  `}
`

export const DayElement = styled.div`
  width: 63px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 15px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #CCD4D8;

  background: #25272B;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: ${({ selected }) => selected ? '#fff' : '#5FC4B8'};
    border: 1px solid #5FC4B8;
  }

  :active {
    color: #b8fff7;
    opacity: 0.3;
  }

  ${props => props.selected && `
    color: #fff;
    border: 1px solid #fff;
  `}
`

export const CustomSelect = styled(Select)`
  min-height: 60px;
  margin-bottom: 24px;
  color: #fff;
  background-color: #0D0D0F;
  display: none;

  ${media.smallMobile`
    display: block;
  `}

  & > .select__control {
    width: 100%;
    height: auto;
    min-height: 60px;
    padding: 12px;
    border: 1px solid #5B6067;
    border-radius: 5px;

    &.select__control--is-focused {
      border: 1px solid #fff !important;

      .select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }

    .select__indicators {
      border: none;

      .select__clear-indicator {
        display: none;
      }

      .select__dropdown-indicator {
        padding: 0;
      }
    }

    .select__single-value {
      font-size: 16px;
    }

    .select__value-container {
      padding: 0;
      gap: 6px;
      font-size: 16px;

      .select__input {
        color: #fff;
      }

      .select__multi-value {
        display: flex;
        justify-content: space-between;

        width: 80px;
        height: 30px;
        background: #1D1D1F;
        margin: 0;
        padding: 6px 8px 6px 12px;
        border-radius: 5px;

        .select__multi-value__label {
          padding: 0;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
        }

        .select__multi-value__remove {
        }

        .select__multi-value__remove:hover {
          background-color: transparent;
          color: #fff;
        }
      }
    }
  }

  & > .select__menu {
    width: 100%;
    height: 200px;
    color: white !important;
  }
`

export const courseSelectStyles = {
  indicatorSeparator: styles => ({ display: 'none' }),
  control: (base, state) => ({
    ...base,
    width: '100%',
    height: '40px',
    background: '#0D0D0F',
    color: 'white',
    border: '1px solid #5B6067',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px'

  }),
  menu: base => ({
    ...base,
    width: 'fit-content',
    height: '296px !important',
    zIndex: 1000,
    padding: '8px 0',
    marginTop: '12px',
    background: '#25272B',
    border: 'none',
    borderRadius: '5px',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)'
  }),
  menuList: base => ({
    ...base,
    width: '100%',
    height: '100%',
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    width: '100%',
    height: '40px',
    padding: '8px 16px',
    fontSize: '16px',
    lineHeight: '24px',
    color: state.isSelected ? '#fff' : '#98A4AE',
    backgroundColor: '#25272B',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      border: '1px solid #5FC4B8',
      borderRadius: '5px'
    },
    '&:active': { backgroundColor: '#25272B', opacity: 0.3 },
    position: 'relative',
    '&:after': state.isSelected ? {
      content: '""',
      position: 'absolute',
      top: '13px',
      right: '18px',
      width: '12px',
      height: '8px',
      borderBottom: '1px solid #5FC4B8',
      borderLeft: '1px solid #5FC4B8',
      transform: `rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg)`
    } : { display: 'none' }
  }),
  placeholder: base => ({
    ...base,
    margin: 0,
    color: '#5B6067',
    fontSize: '16px',
    lineHeight: '24px'
  }),
  singleValue: base => ({
    ...base,
    color: '#fff',
    paddingLeft: '6px',
    margin: 0,
    fontSize: '18px',
    lineHeight: '22px'
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#fff'
  }),
  indicatorContainer: base => ({
    ...base,
    paddingLeft: 0
  })
}

// Time Frames Section

export const TimeFramesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`

export const TimeFrameElement = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  .title {
    width: 58px;
    height: 31px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 3.5px 8px;
    background: #1D1D1F;
    border-radius: 5px;
  }

  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
  }

  ${media.smallMobile`
    gap: 8px;
    flex-direction: column;
  `}
`

export const InputWrapper = styled.div`
  display: flex;
  gap: 16px;

  ${media.smallMobile`
    flex-direction: column;
    margin-left: 16px;
  `}
`

export const DatePickerDropdownStyling = css`
  .react-datepicker-wrapper {
    width: unset;
  }

  .react-datepicker__tab-loop {
    position: absolute;
    top: 0;
    left: 0;
  }

  .react-datepicker-popper {
    padding-top: 7.5px;
    padding-bottom: 4.5px;
  }

  .react-datepicker {
    border: none;
    border-radius: 5px;
    background: #25272B;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    display: none;
  }

  .react-datepicker__time-container {
    width: 100%;
  }

  .react-datepicker__time {
    padding: 3px;
    width: 104px !important;
    height: 216px;
    border-radius: 5px;
    background: #25272B;

    ${media.smallMobile`
      width: 200px !important;
    `}
  }

  .react-datepicker__time-box {
    width: 100% !important;
    background: #25272B;
    border-radius: unset;
  }

  .react-datepicker__time-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background: #25272B;
    border-radius: 5px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
  }

  .react-datepicker__time-list-item {
    height: 24px !important;
    padding: 4px 0 !important;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .react-datepicker__time-list-item:hover {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.07) !important;
  }

  .react-datepicker__time-list-item--selected {
    font-weight: 400 !important;
    background-color: rgba(255, 255, 255, 0.07) !important;
  }

  .react-datepicker__time-list-item--disabled {
    color: #98A4AE !important;
  }

  .react-datepicker__time-list::-webkit-scrollbar {
    width: 3px;
    background-color: #25272B;
  }

  .react-datepicker__time-list::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #25272B;
  }

  .react-datepicker__time-list::-webkit-scrollbar-thumb {
    height: 50px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
  }
`

export const InputEntry = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  label {
    margin: 0;
  }

  ${media.smallMobile`
    label {
      min-width: 40px;
    }
  `}

  ${DatePickerDropdownStyling}
`

export const CustomDatePicker = styled(DatePicker)`
  width: 104px !important;
  height: 32px;
  padding: 7px 0;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  border-radius: 5px;
  border: 1px solid #5B6067;
  background-color: #0D0D0F;

  &:hover {
    border-color: #5FC4B8;
  }

  &:focus {
    outline: none;
    border-color: #fff;
  }

  ${media.smallMobile`
    width: 200px !important;
  `}
`

export const IconStyles = css`
  cursor: pointer;
  width: 16px;
  height: 16px;

  :hover {
    fill: #b8fff7;
  }

  :active {
    color: #b8fff7;
    opacity: 0.3;
  }

  ${({ disabled }) => disabled && `
    opacity: 0.3;
    pointer-events: none;
  `}
`

export const PlusIcon = styled(PlusSVG)`
  ${IconStyles}
`

export const MinusIcon = styled(MinusSVG)`
  ${IconStyles}
`

export const AddToCalendarIcon = styled(CalendarPlusSVG)`
  height: 16px;
  ${IconStyles}
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  button {
    padding: 16px 24px;
  }

  ${media.smallMobile`
    flex-direction: column;

    button {
      width: 100%;
    }
  `}
`

export const ErrorMessage = styled.p`
  color: #FF8064;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 12px 0;

  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`

// MODAL

export const ModalGlobalStyle = createGlobalStyle`
  .modal-backdrop.show {
    opacity: 0.5;
  }

  .modal-dialog {
    max-width: none;
  }

  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-body {
    padding: 24px;
    & > p {
      margin-bottom: 24px;
    }
  }

  .modal-content {
    background-color: #25272B;
    width: 455px;
    display: flex;
    align-items: flex-start;
    margin: auto;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    ${media.smallMobile`
      width: calc(100% - 12px);
    `}
  }
`

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px;

  & > p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    flex: 1;
    margin-bottom: 0;
  }

  & > img {
    cursor: pointer;
    width: 14px;
    height: 14px;
  }
`

export const Separator = styled.hr`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  border: 0 none;
  margin: 0;
`

// TOAST NOTIFICATION

export const ToastWrapper = styled.div`
  position: fixed;
  z-index: 1;
  top: 64px;
  right: 0;
  max-width: 400px;
  margin: 24px 24px 0;
  padding: 24px;
  background-color: #25272B;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;

  ${media.smallMobile`
    max-width: calc(100% - 48px);
  `}
`

export const ToastHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;

  img {
    cursor: pointer;
  }

  ${media.smallMobile`
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #404040;
  `}
`

export const ToastHeaderData = styled.div`
  display: flex;
`

export const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-left: 12px;
  line-height: 19px;
`

export const ToastBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }

  .italic {
    color: #CCD4D8;
    font-size: 14px;
    line-height: 18px;
    font-style: italic;
  }

  ${media.smallMobile`
    gap: 24px;
  `}
`
