import React from 'react'
import _, { cloneDeep } from 'lodash'
import { checkCourseNames } from '../../utilities/course'
import { AUDIT, COMPLETED, ENROLLED, SIGNED_UP } from '../../Constants/studentStatus'
import { formatDateWithDay, getStartEndDateUSFormat } from '../../utilities/dateTime'
import {
  defaultCertificates,
  COLOR_COMPLETED, COLOR_IN_PROGRESS, COLOR_NOT_ENROLLED
} from './constants'

import CertificateCompleted3SVG from '../../assets/icons/certificates/certificate-complete-3.svg'
import CertificateCompleted4SVG from '../../assets/icons/certificates/certificate-complete-4.svg'

import CertificateEnrolled0Of3SVG from '../../assets/icons/certificates/certificate-enrolled-0-3.svg'
import CertificateEnrolled1Of3SVG from '../../assets/icons/certificates/certificate-enrolled-1-3.svg'
import CertificateEnrolled2Of3SVG from '../../assets/icons/certificates/certificate-enrolled-2-3.svg'

import CertificateEnrolled0Of4SVG from '../../assets/icons/certificates/certificate-enrolled-0-4.svg'
import CertificateEnrolled1Of4SVG from '../../assets/icons/certificates/certificate-enrolled-1-4.svg'
import CertificateEnrolled2Of4SVG from '../../assets/icons/certificates/certificate-enrolled-2-4.svg'
import CertificateEnrolled3Of4SVG from '../../assets/icons/certificates/certificate-enrolled-3-4.svg'

import CertificateNotEnrolled4SVG from '../../assets/icons/certificates/certificate-not-enrolled-4.svg'
import CertificateNotEnrolled3SVG from '../../assets/icons/certificates/certificate-not-enrolled-3.svg'

import CalculusICompletedSVG from '../../assets/icons/courses/calculus-i-completed.svg'
import CalculusIEnrolledSVG from '../../assets/icons/courses/calculus-i-enrolled.svg'
import CalculusINotEnrolledSVG from '../../assets/icons/courses/calculus-i-not-enrolled.svg'

import PrecalculusCompletedSVG from '../../assets/icons/courses/precalculus-completed.svg'
import PrecalculusEnrolledSVG from '../../assets/icons/courses/precalculus-enrolled.svg'
import PrecalculusNotEnrolledSVG from '../../assets/icons/courses/precalculus-not-enrolled.svg'

import StatisticsCompletedSVG from '../../assets/icons/courses/statistics-completed.svg'
import StatisticsEnrolledSVG from '../../assets/icons/courses/statistics-enrolled.svg'
import StatisticsNotEnrolledSVG from '../../assets/icons/courses/statistics-not-enrolled.svg'

import CollegeAlgebraCompletedSVG from '../../assets/icons/courses/college-algebra-completed.svg'
import CollegeAlgebraEnrolledSVG from '../../assets/icons/courses/college-algebra-enrolled.svg'
import CollegeAlgebraNotEnrolledSVG from '../../assets/icons/courses/college-algebra-not-enrolled.svg'

import CollegeWritingICompletedSVG from '../../assets/icons/courses/college-writing-i-completed.svg'
import CollegeWritingIEnrolledSVG from '../../assets/icons/courses/college-writing-i-enrolled.svg'
import CollegeWritingINotEnrolledSVG from '../../assets/icons/courses/college-writing-i-not-enrolled.svg'

import FinancialAccountingCompletedSVG from '../../assets/icons/courses/financial-accounting-completed.svg'
import FinancialAccountingEnrolledSVG from '../../assets/icons/courses/financial-accounting-enrolled.svg'
import FinancialAccountingNotEnrolledSVG from '../../assets/icons/courses/financial-accounting-not-enrolled.svg'

import MacroeconomicsCompletedSVG from '../../assets/icons/courses/macroeconomics-completed.svg'
import MacroeconomicsEnrolledSVG from '../../assets/icons/courses/macroeconomics-enrolled.svg'
import MacroeconomicsNotEnrolledSVG from '../../assets/icons/courses/macroeconomics-not-enrolled.svg'

import MicroeconomicsCompletedSVG from '../../assets/icons/courses/microeconomics-completed.svg'
import MicroeconomicsEnrolledSVG from '../../assets/icons/courses/microeconomics-enrolled.svg'
import MicroeconomicsNotEnrolledSVG from '../../assets/icons/courses/microeconomics-not-enrolled.svg'

import PrinciplesOfEconCompletedSVG from '../../assets/icons/courses/principles-of-econ-completed.svg'
import PrinciplesOfEconEnrolledSVG from '../../assets/icons/courses/principles-of-econ-enrolled.svg'
import PrinciplesOfEconNotEnrolledSVG from '../../assets/icons/courses/principles-of-econ-not-enrolled.svg'

import BusinessCompletedSVG from '../../assets/icons/courses/business-completed.svg'
import BusinessEnrolledSVG from '../../assets/icons/courses/business-enrolled.svg'
import BusinessNotEnrolledSVG from '../../assets/icons/courses/business-not-enrolled.svg'

import PsychologyCompletedSVG from '../../assets/icons/courses/psychology-completed.svg'
import PsychologyEnrolledSVG from '../../assets/icons/courses/psychology-enrolled.svg'
import PsychologyNotEnrolledSVG from '../../assets/icons/courses/psychology-not-enrolled.svg'

import SociologyCompletedSVG from '../../assets/icons/courses/sociology-completed.svg'
import SociologyEnrolledSVG from '../../assets/icons/courses/sociology-enrolled.svg'
import SociologyNotEnrolledSVG from '../../assets/icons/courses/sociology-not-enrolled.svg'

import AstronomyCompletedSVG from '../../assets/icons/courses/astronomy-completed.svg'
import AstronomyEnrolledSVG from '../../assets/icons/courses/astronomy-enrolled.svg'
import AstronomyNotEnrolledSVG from '../../assets/icons/courses/astronomy-not-enrolled.svg'

import PhilosophyCompletedSVG from '../../assets/icons/courses/philosophy-completed.svg'
import PhilosophyEnrolledSVG from '../../assets/icons/courses/philosophy-enrolled.svg'
import PhilosophyNotEnrolledSVG from '../../assets/icons/courses/philosophy-not-enrolled.svg'

import ElectiveCourseSVG from '../../assets/icons/courses/elective-course.svg'

// FUNCTIONS

/**
 * @param {String} courseType required | elective
 * @param {String} studentStatus Completed | Enrolled | notEnrolled
 * @param {String} courseCategory for elective courses only: Business | Math | Liberal Arts
 * @param {Number} noOfCourses number of elective courses in a given category
 * @returns the type of the course based on all the given parameters
 * e.g for elective completed or enrolled course, we return the category name + Course
 */

export const getCourseTypeStatement = (params) => {
  const {
    courseType = '', studentStatus, courseCategory, noOfCourses
  } = params || {}

  if (!courseType) return ''

  if (courseType.toLowerCase() === 'required') {
    return `${courseType} Course`
  }

  const electiveEnrolledOrCompleted =
    courseType.toLowerCase() === 'elective' &&
    [COMPLETED, ENROLLED, SIGNED_UP].includes(studentStatus)

  if (electiveEnrolledOrCompleted) {
    return `${courseCategory} Course`
  }

  const electiveNotEnrolled =
    courseType.toLowerCase() === 'elective' &&
    ![COMPLETED, ENROLLED, SIGNED_UP].includes(studentStatus)

  if (electiveNotEnrolled) {
    return `Choose from ${noOfCourses} courses`
  }

  return `${courseType} Course`
}

export const getCourseDateStatement = (cohort = {}, studentStatus = '') => {
  if (studentStatus === COMPLETED) {
    return `Completed ${formatDateWithDay(cohort?.dateEnd)}`
  }

  if ([ENROLLED, SIGNED_UP].includes(studentStatus)) {
    return getStartEndDateUSFormat(cohort?.dateStart, cohort?.dateEnd)
  }

  return ''
}

export const getNoOfStatusCourses = (courses = [], status = '') => {
  return courses?.reduce((previousValue, course) => {
    if (course?.cohort?.studentStatus === status) return previousValue + 1
    return previousValue
  }, 0) || 0
}

export const getNoOfCourses = (courses) => {
  return _.uniqWith(courses, (current, next) => {
    return current.courseCategory && current.courseCategory === next.courseCategory
  }).length
}

export const getOrderedCourses = (courses = []) => {
  const clonedCourses = cloneDeep(courses)
  const mapStatuses = { [COMPLETED]: 1, [ENROLLED]: 2, [SIGNED_UP]: 2, [AUDIT]: 3 }

  return clonedCourses?.sort((courseA, courseB) => {
    const mappedCourseAStatus = mapStatuses[courseA?.cohort?.studentStatus] || 4
    const mappedCourseBStatus = mapStatuses[courseB?.cohort?.studentStatus] || 4
    return mappedCourseAStatus - mappedCourseBStatus
  })
}

export const getAdjustedCourses = (courses = [], grades = {}) => {
  return courses?.map(course => {
    return {
      id: course?.id,
      name: course?.displayName,
      grades: {
        projectedGrade: grades?.[course?.id]
      },
      cohort: {
        dateStart: course?.statusData[0]?.dateStart,
        dateEnd: course?.statusData[0]?.cohortEndTime,
        studentStatus: course?.statusData[0]?.studentStatus
      }
    }
  })
}

export const getAllCourses = (enrolledCourses = [], allCoursesData = []) => {
  const collegeSuccessId = 'ckyp0xzra00003e5zdb9d8mta'
  const enrolledCoursesIds = enrolledCourses?.map(course => course?.id)

  const filteredCoursesData = allCoursesData?.filter(course => {
    return !enrolledCoursesIds.includes(course?.id)
  })
  const filteredEnrolledCourses = enrolledCourses?.filter(course => {
    return course?.id !== collegeSuccessId
  })

  return [...filteredEnrolledCourses, ...filteredCoursesData]
}

export const getStatusColor = (status) => {
  if (status === COMPLETED) return COLOR_COMPLETED
  if ([ENROLLED, SIGNED_UP].includes(status)) return COLOR_IN_PROGRESS
  return COLOR_NOT_ENROLLED
}

export const getFilteredCertificates = (allCertificates = []) => {
  return allCertificates?.filter(certificate => {
    return defaultCertificates.includes(certificate?.certificateName) ||
      certificate?.isPurchased
  })
}

export const getOrderedCertificates = (certificates = []) => {
  const clonedCertificates = cloneDeep(certificates)
  const mapStatuses = { [COMPLETED]: 1, [ENROLLED]: 2, notEnrolled: 3 }

  return clonedCertificates?.sort((certA, certB) => {
    const certAStatus = getCertificateStatus(certA)
    const certBStatus = getCertificateStatus(certB)
    return mapStatuses[certAStatus] - mapStatuses[certBStatus]
  })
}

export const getCertificateIcon = (params) => {
  const {
    certificateStatus: status, noOfCourses, noOfCompletedCourses, style
  } = params

  if (status === COMPLETED) {
    if (noOfCourses === 3) return <img src={CertificateCompleted3SVG} alt='CertificateCompleted3SVG' style={style} />
    return <img src={CertificateCompleted4SVG} alt='CertificateCompletedSVG' style={style} />
  }

  if (status === ENROLLED && noOfCourses === 3) {
    if (noOfCompletedCourses === 0) return <img src={CertificateEnrolled0Of3SVG} alt='CertificateEnrolled0Of3SVG' style={style} />
    if (noOfCompletedCourses === 1) return <img src={CertificateEnrolled1Of3SVG} alt='CertificateEnrolled1Of3SVG' style={style} />
    return <img src={CertificateEnrolled2Of3SVG} alt='CertificateEnrolled1Of3SVG' style={style} />
  }

  if (status === ENROLLED && noOfCourses === 4) {
    if (noOfCompletedCourses === 0) return <img src={CertificateEnrolled0Of4SVG} alt='CertificateEnrolled0Of4SVG' style={style} />
    if (noOfCompletedCourses === 1) return <img src={CertificateEnrolled1Of4SVG} alt='CertificateEnrolled1Of4SVG' style={style} />
    if (noOfCompletedCourses === 2) return <img src={CertificateEnrolled2Of4SVG} alt='CertificateEnrolled2Of4SVG' style={style} />
    return <img src={CertificateEnrolled3Of4SVG} alt='CertificateEnrolled3Of4SVG' style={style} />
  }

  if (noOfCourses === 3) {
    return <img src={CertificateNotEnrolled3SVG} alt='CertificateNotEnrolled3SVG' style={style} />
  }

  return <img src={CertificateNotEnrolled4SVG} alt='CertificateNotEnrolled4SVG' style={style} />
}

export const getCertificateStatus = (certificate = {}) => {
  const { courses = [] } = certificate || {}

  const noOfCourses = getNoOfCourses(courses)
  const noOfCompletedCourses = courses?.reduce((previousValue, course) => {
    if (course?.cohort?.studentStatus === COMPLETED) return previousValue + 1
    return previousValue
  }, 0)
  const noOfEnrolledCourses = courses?.reduce((previousValue, course) => {
    if ([ENROLLED, SIGNED_UP].includes(course?.cohort?.studentStatus)) return previousValue + 1
    return previousValue
  }, 0)

  const isCompleted = noOfCourses === noOfCompletedCourses
  const isEnrolled = noOfEnrolledCourses !== 0 || noOfCompletedCourses !== 0

  if (isCompleted) return 'Completed'
  if (isEnrolled) return 'Enrolled'
  return 'notEnrolled'
}

export const getCourseIcon = (courseId, status, style = {}) => {
  const isCompleted = status === COMPLETED
  const isEnrolled = [ENROLLED, SIGNED_UP].includes(status)
  switch (courseId) {
    case checkCourseNames(['calculus', 'calculus.plus'], courseId):
      if (isCompleted) return <img src={CalculusICompletedSVG} alt='calculus-i-completed' style={style} />
      if (isEnrolled) return <img src={CalculusIEnrolledSVG} alt='calculus-i-enrolled' style={style} />
      return <img src={CalculusINotEnrolledSVG} alt='calculus-i-not-enrolled' style={style} />

    case checkCourseNames(['precalculus', 'precalculus.plus'], courseId):
      if (isCompleted) return <img src={PrecalculusCompletedSVG} alt='precalculus-completed' style={style} />
      if (isEnrolled) return <img src={PrecalculusEnrolledSVG} alt='precalculus-enrolled' style={style} />
      return <img src={PrecalculusNotEnrolledSVG} alt='precalculus-not-enrolled' style={style} />

    case checkCourseNames(['statistics', 'statistics.plus'], courseId):
      if (isCompleted) return <img src={StatisticsCompletedSVG} alt='statistics-completed' style={style} />
      if (isEnrolled) return <img src={StatisticsEnrolledSVG} alt='statistics-enrolled' style={style} />
      return <img src={StatisticsNotEnrolledSVG} alt='statistics-not-enrolled' style={style} />

    case checkCourseNames(['collegealgebra', 'collegealgebra.plus'], courseId):
      if (isCompleted) return <img src={CollegeAlgebraCompletedSVG} alt='collegealgebra-completed' style={style} />
      if (isEnrolled) return <img src={CollegeAlgebraEnrolledSVG} alt='collegealgebra-enrolled' style={style} />
      return <img src={CollegeAlgebraNotEnrolledSVG} alt='collegealgebra-not-enrolled' style={style} />

    case checkCourseNames(['collegewriting-i', 'collegewriting-i.plus'], courseId):
      if (isCompleted) return <img src={CollegeWritingICompletedSVG} alt='collegewriting-i-completed' style={style} />
      if (isEnrolled) return <img src={CollegeWritingIEnrolledSVG} alt='collegewriting-i-enrolled' style={style} />
      return <img src={CollegeWritingINotEnrolledSVG} alt='collegewriting-i-not-enrolled' style={style} />

    case checkCourseNames(['financialaccounting', 'financialaccounting.plus'], courseId):
      if (isCompleted) return <img src={FinancialAccountingCompletedSVG} alt='financial-accounting-completed' style={style} />
      if (isEnrolled) return <img src={FinancialAccountingEnrolledSVG} alt='financial-accounting-enrolled' style={style} />
      return <img src={FinancialAccountingNotEnrolledSVG} alt='financial-accounting-not-enrolled' style={style} />

    case checkCourseNames(['macroeconomics', 'macroeconomics.plus'], courseId):
      if (isCompleted) return <img src={MacroeconomicsCompletedSVG} alt='macroeconomics-completed' style={style} />
      if (isEnrolled) return <img src={MacroeconomicsEnrolledSVG} alt='macroeconomics-enrolled' style={style} />
      return <img src={MacroeconomicsNotEnrolledSVG} alt='macroeconomics-not-enrolled' style={style} />

    case checkCourseNames(['microeconomics', 'microeconomics.plus'], courseId):
      if (isCompleted) return <img src={MicroeconomicsCompletedSVG} alt='microeconomics-completed' style={style} />
      if (isEnrolled) return <img src={MicroeconomicsEnrolledSVG} alt='microeconomics-enrolled' style={style} />
      return <img src={MicroeconomicsNotEnrolledSVG} alt='microeconomics-not-enrolled' style={style} />

    case checkCourseNames(['principlesofeconomics', 'principlesofeconomics.plus'], courseId):
      if (isCompleted) return <img src={PrinciplesOfEconCompletedSVG} alt='principles-of-econ-completed' style={style} />
      if (isEnrolled) return <img src={PrinciplesOfEconEnrolledSVG} alt='principles-of-econ-enrolled' style={style} />
      return <img src={PrinciplesOfEconNotEnrolledSVG} alt='principles-of-econ-not-enrolled' style={style} />

    case checkCourseNames(['business', 'business.plus'], courseId):
      if (isCompleted) return <img src={BusinessCompletedSVG} alt='business-completed' style={style} />
      if (isEnrolled) return <img src={BusinessEnrolledSVG} alt='business-enrolled' style={style} />
      return <img src={BusinessNotEnrolledSVG} alt='business-not-enrolled' style={style} />

    case checkCourseNames(['psychology', 'psychology.plus'], courseId):
      if (isCompleted) return <img src={PsychologyCompletedSVG} alt='psychology-completed' style={style} />
      if (isEnrolled) return <img src={PsychologyEnrolledSVG} alt='psychology-enrolled' style={style} />
      return <img src={PsychologyNotEnrolledSVG} alt='psychology-not-enrolled' style={style} />

    case checkCourseNames(['sociology', 'sociology.plus'], courseId):
      if (isCompleted) return <img src={SociologyCompletedSVG} alt='sociology-completed' style={style} />
      if (isEnrolled) return <img src={SociologyEnrolledSVG} alt='sociology-enrolled' style={style} />
      return <img src={SociologyNotEnrolledSVG} alt='sociology-not-enrolled' style={style} />

    case checkCourseNames(['astronomy', 'astronomy.plus'], courseId):
      if (isCompleted) return <img src={AstronomyCompletedSVG} alt='astronomy-completed' style={style} />
      if (isEnrolled) return <img src={AstronomyEnrolledSVG} alt='astronomy-enrolled' style={style} />
      return <img src={AstronomyNotEnrolledSVG} alt='astronomy-not-enrolled' style={style} />

    case checkCourseNames(['philosophy', 'philosophy.plus'], courseId):
      if (isCompleted) return <img src={PhilosophyCompletedSVG} alt='philosophy-completed' style={style} />
      if (isEnrolled) return <img src={PhilosophyEnrolledSVG} alt='philosophy-enrolled' style={style} />
      return <img src={PhilosophyNotEnrolledSVG} alt='philosophy-not-enrolled' style={style} />

    default:
      if (isCompleted) return <img src={CalculusICompletedSVG} alt='calculus-i-completed' style={style} />
      if (isEnrolled) return <img src={CalculusIEnrolledSVG} alt='calculus-i-enrolled' style={style} />
      return <img src={ElectiveCourseSVG} alt='elective-course' style={style} />
  }
}
