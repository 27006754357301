import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Container, StyledQuestionText, StyledQuestionTextWrapper } from './style'
import {
  questionTypes,
  progressButtonStates,
  pretestResult,
  imageClassMap
} from '../../../Constants/pretest'
import {
  canContinuePretest,
  getScore,
  getAnswerObject,
  getQuestionType,
  has3IncorrectAnswers
} from '../../../utilities/pretestUtils'
import MultipleChoice from './MultipleChoice'
import FreeForm from './FreeForm'
import TextBlanks from './TextBlanks'
import { loadMathJax } from './lib/mathjaxScript'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import Buttons from './Buttons'
import api from '../../../api'
import StudyBreakModal from '../StudyBreakModal'
import AttemptFailedModal from '../AttemptFailedModal'

const { MULTIPLE_CHOICE, TRUE_FALSE, FREE_FORM, TEXT_BLANKS } = questionTypes

const { ACTIVE, DEFAULT, CORRECT_OPTION, INCORRECT_OPTION } = progressButtonStates

const componentByQuestionType = {
  [MULTIPLE_CHOICE]: MultipleChoice,
  [TRUE_FALSE]: MultipleChoice,
  [FREE_FORM]: FreeForm,
  [TEXT_BLANKS]: TextBlanks
}

const QuestionComponent = ({
  courseId,
  pretest,
  setScorePage,
  recentAttempt,
  questionSetIndex
}) => {
  const {
    pretestExamContent,
    minimumPretestScore,
    pretestLockoutDays
  } = pretest || {}

  const questionSet = pretestExamContent[questionSetIndex] || []
  const [pretestQuestions, setPretestQuestions] = useState([])
  const [currentAnswer, setCurrentAnswer] = useState(null)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [studentAnswers, setStudentAnswers] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [showStudyBreakModal, setShowStudyBreakModal] = useState(false)
  const [showAttemptFailed, setShowAttemptFailed] = useState(false)
  const [triggerSave, setTriggerSave] = useState()

  const isLastQuestion = currentQuestionIndex === pretestQuestions.length - 1

  const currentQuestion = useMemo(() => {
    return pretestQuestions[currentQuestionIndex]
  }, [pretestQuestions, currentQuestionIndex])

  const studentAnswer = useMemo(() => {
    return studentAnswers[currentQuestion?.['Question_uuid']]
  }, [currentQuestion, studentAnswers])

  const questionStatus = useMemo(() => {
    if (!studentAnswer) return currentAnswer ? ACTIVE : DEFAULT

    return studentAnswer.correct ? CORRECT_OPTION : INCORRECT_OPTION
  }, [currentAnswer, studentAnswer])

  const QuestionComponent = useMemo(() => {
    if (!currentQuestion) return null

    const questionType = getQuestionType(currentQuestion)
    return componentByQuestionType[questionType]
  }, [currentQuestion])

  const saveStudentAttempt = useCallback(async (score) => {
    const today = new Date()
    const nextAttemptDate = today.setDate(today.getDate() + pretestLockoutDays)
    const nextAvailable = new Date(nextAttemptDate).toDateString()
    const { PASS, FAIL } = pretestResult
    const passedPretest = score >= minimumPretestScore
    const pretestId = recentAttempt?.testResult
      ? null : recentAttempt?.pretestAttempt || null

    setIsLoading(true)
    const attemptSaved = await api.savePretestAttempt(
      courseId,
      pretestId,
      {
        testResult: passedPretest ? PASS : FAIL,
        earnedScore: score,
        questionSetIndex,
        ...(passedPretest ? {} : { nextAvailable })
      }
    )
    setIsLoading(false)

    const { success, message } = attemptSaved

    if (message) return false

    return success
  }, [courseId, minimumPretestScore, pretestLockoutDays, questionSetIndex, recentAttempt])

  useEffect(() => {
    loadMathJax(setIsLoading)

    const { Question: questions } = questionSet
    if (!questions?.length) return

    setPretestQuestions(questions)
    // eslint-disable-next-line
  }, [])

  const submitHandler = () => {
    const studentAnswer = getAnswerObject({
      currentQuestion,
      currentAnswer
    })

    const _studentAnswers = { ...studentAnswers }
    _studentAnswers[currentQuestion.Question_uuid] = studentAnswer
    setStudentAnswers(_studentAnswers)
  }

  const finishHandler = useCallback(async (showScore) => {
    const score = getScore(pretestQuestions, studentAnswers)

    const attemptSaved = await saveStudentAttempt(score)
    if (!attemptSaved) return

    showScore && setScorePage(score)
  }, [pretestQuestions, saveStudentAttempt, setScorePage, studentAnswers])

  useEffect(() => {
    if (has3IncorrectAnswers(pretestQuestions, studentAnswers)) {
      setShowStudyBreakModal(true)
    }
  }, [pretestQuestions, studentAnswers])

  useEffect(() => {
    if (!pretestQuestions?.length || isLastQuestion) return
    if (canContinuePretest(pretestQuestions, minimumPretestScore, studentAnswers)) return

    setShowAttemptFailed(true)
    finishHandler(false)
  }, [finishHandler, isLastQuestion, minimumPretestScore, pretestQuestions, studentAnswers])

  useEffect(() => {
    if (!triggerSave) return

    finishHandler(true)
  }, [triggerSave, studentAnswers, finishHandler])

  const nextHandler = index => {
    const nextQuestionIndex = isNaN(index) ? currentQuestionIndex + 1 : index

    if (currentQuestionIndex < 0 ||
      nextQuestionIndex >= pretestQuestions.length) return

    setCurrentQuestionIndex(nextQuestionIndex)
  }

  const handleBack = () => {
    if (currentQuestionIndex <= 0) return

    setCurrentQuestionIndex(currentQuestionIndex - 1)
  }

  if (isLoading) return <LoadingSpinner />

  const { Question_uuid: questionUUID } = currentQuestion || {}

  return (
    <Container>
      <StyledQuestionTextWrapper>
        <img
          src={imageClassMap[questionStatus]}
          alt='Question status'
        />
        <StyledQuestionText>
          Question {currentQuestionIndex + 1} / {pretestQuestions.length}
        </StyledQuestionText>
      </StyledQuestionTextWrapper>
      {QuestionComponent && (
        <QuestionComponent
          question={currentQuestion}
          studentAnswer={studentAnswer}
          questionStatus={questionStatus}
          setCurrentAnswer={setCurrentAnswer}
        />
      )}
      <Buttons
        questionUUID={questionUUID}
        isLastQuestion={isLastQuestion}
        submitHandler={submitHandler}
        nextHandler={nextHandler}
        currentQuestionIndex={currentQuestionIndex}
        studentAnswer={studentAnswer}
        currentAnswer={currentAnswer}
        onBack={handleBack}
        finishHandler={() => setTriggerSave(Math.random())}
        answerSelected={!!currentAnswer}
        answerSubmitted={!!studentAnswer}
      />
      <StudyBreakModal
        show={showStudyBreakModal}
        setShow={setShowStudyBreakModal}
      />
      <AttemptFailedModal
        show={showAttemptFailed}
        pretestLockoutDays={pretestLockoutDays}
      />
    </Container>
  )
}

QuestionComponent.displayName = 'QuestionComponent'

QuestionComponent.propTypes = {
  courseId: PropTypes.string,
  pretest: PropTypes.shape({
    pretestExamContent: PropTypes.arrayOf(PropTypes.object).isRequired
  }),
  setScorePage: PropTypes.func,
  questionSetIndex: PropTypes.number,
  recentAttempt: PropTypes.object,
  courseName: PropTypes.string.isRequired
}

export default QuestionComponent
