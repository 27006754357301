export const courseUTMKeys = {
  'Calculus I': 'calculus',
  'Intro to Psychology': 'psychology',
  'Intro to Financial Accounting': 'accounting',
  'Intro to Astronomy': 'astronomy',
  'Intro to Statistics': 'stats',
  'Intro to Philosophy': 'philio',
  'Intro to Microeconomics': 'micro',
  Precalculus: 'precalc',
  'Intro to Macroeconomics': 'macro',
  'College Algebra': 'algebra',
  'Intro to Sociology': 'sociology',
  'College Writing I': 'writing1',
  'Principles of Economics': 'poe',
  'College Success': 'collegesuccess',
  'Intro to Business': 'business',
  'College Pathway': 'collegepathway',
  'Business Foundation': 'businessfoundation',
  'Data Science Prep': 'datascience'
}
