import React, { useState } from 'react'
import seenIcon from '../../assets/icons/icon-check-circle-filled.svg'
import caretIcon from '../../assets/icons/caret.svg'
import notSeenIcon from '../../assets/icons/icon-check-circle-unfilled.svg'
import {
  AnnouncementWrapper,
  AnnouncementDate,
  CardContainer,
  Card,
  CardHeader,
  CardTitle,
  CardIcon,
  CardBody,
  Link,
  CollapseButton,
  CardFooter,
  FooterText,
  Dot
} from './styles'

const Announcement = ({
  announcement = {},
  setSeenAnnouncements,
  selectedAnnouncementId
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const notificationLength = announcement?.body?.length

  const getBodyText = () => {
    if (notificationLength <= 283) return announcement.body
    return isOpen ? announcement.body : `${announcement?.body?.slice(0, 280)}...`
  }

  return (
    <AnnouncementWrapper
      data-testid='announcement'
      id={`announcement-${announcement.id}`}
    >
      <AnnouncementDate>{announcement.date}</AnnouncementDate>
      <CardContainer>
        <Card
          isHighlightened={
            announcement.id?.toString() === selectedAnnouncementId
          }
        >
          <CardHeader>
            <CardTitle>
              {announcement.title}
            </CardTitle>
            <CardIcon
              alt='seen-check-icon'
              src={announcement.seen ? seenIcon : notSeenIcon}
              onClick={() => setSeenAnnouncements(preVal => [
                ...preVal,
                announcement.id
              ])}
            />
          </CardHeader>
          <CardBody isOpen={isOpen}>
            {getBodyText()}
            {announcement.link &&
              <Link
                href={announcement.link?.href}
                target='_blank'
              >
                {announcement.link?.text}
              </Link>}
            {notificationLength > 283 &&
              <CollapseButton
                isOpen={isOpen}
                onClick={() => setIsOpen(!isOpen)}
              >
                <img src={caretIcon} alt='caret-icon' />
                {isOpen ? 'Read Less' : 'Read More'}
              </CollapseButton>}
          </CardBody>
          <CardFooter>
            <FooterText>{announcement.course}</FooterText>
            <Dot />
            <FooterText>{announcement.name}</FooterText>
          </CardFooter>
        </Card>
      </CardContainer>
    </AnnouncementWrapper>
  )
}

Announcement.displayName = 'Announcement'
export default Announcement
