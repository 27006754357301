import React, { useContext, useEffect } from 'react'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import { GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import {
  getDateStringWithYearLongMonth
} from '../../utilities/dateTime'
import { AppContext } from '../ContextProvider/ContextProvider'
import CourseSlot from './CourseSlot'
import {
  GroupHeaderMessage,
  GroupHeaderMessageContainer,
  Page, PrimaryButton, Subtext, Term, Title
} from './style'
import {
  COURSE_REGISTRATION_PAGEVIEW,
  COURSE_REGISTRATION_PAGE_SUBMITTED
} from '../../Constants/eventType'
import {
  isSegmentEventInLS,
  removeSegmentEventFromLS,
  setFiredSegmentEventsInLS
} from '../../utilities/gguDegreeUtils'
import { Instructions } from '../GGUEnrollmentFlow/styles'
import { DEGREE_PLAN_WORKSHEETS, DEGREE_PLAN_WORKSHEET_LINK } from '../../Constants/gguDegrees'

const RecommendedCourses = ({
  currentProspect,
  recommendedCourses,
  setCurrentPage,
  setCurrentCourseNumber
}) => {
  const { sendEvent } = useSegmentEvents()
  const {
    currentSemester,
    catalogCourses
  } = useContext(AppContext)

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: '4-1'
  }

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: '3-1'
    })
    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: '3-3'
    })
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'All Course Recommendations',
        page_number: '4-1',
        recommended_course1: recommendedCourses?.[0]?.courseName || '',
        recommended_course2: recommendedCourses?.[1]?.courseName || '',
        recommended_course3: recommendedCourses?.[2]?.courseName || ''
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  const semesterStart = getDateStringWithYearLongMonth(currentSemester?.semesterStart, 'short')

  return (
    <Page>
      <Title>Recommended courses</Title>
      <Subtext marginTop='8px'>
        Based on your major and transfer credits, here are the courses we recommend you take next term.
      </Subtext>
      <Term>{currentSemester?.term}</Term>
      <GroupHeaderMessageContainer>
        <img src='images/icons/info-circle.svg' alt='info-icon' />
        <GroupHeaderMessage data-testid='group-header-message'>
          Professional Certificate courses are not permitted to be taken in your first term with Degrees+. Only one Professional Certificate course may be taken per term.
        </GroupHeaderMessage>
      </GroupHeaderMessageContainer>
      {recommendedCourses?.map((course, index) => {
        const { courseId } = course

        return (
          <CourseSlot
            key={courseId}
            index={index}
            course={course}
            semesterStart={semesterStart}
            catalogCourses={catalogCourses}
          />
        )
      })}
      <Instructions marginBottom='0'>
        You can check out our{' '}
        <a
          href={DEGREE_PLAN_WORKSHEETS[currentProspect?.degree] || DEGREE_PLAN_WORKSHEET_LINK}
          target='_blank'
          rel='noopener noreferrer'
        >
          Degree Plan Worksheet
        </a>
        {' '}to help make decisions about future semesters.
      </Instructions>
      <PrimaryButton onClick={() => {
        setCurrentCourseNumber(1)
        sendEvent({
          eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
          properties: {
            page_name: 'All Course Recommendations',
            page_number: '4-1',
            recommended_course1: recommendedCourses?.[0]?.courseName || '',
            recommended_course2: recommendedCourses?.[1]?.courseName || '',
            recommended_course3: recommendedCourses?.[2]?.courseName || ''
          }
        })
        setCurrentPage(GGU_V2_ENROLLMENT_PAGES.COURSE_INTRO)
      }}
      >
        Explore Courses
      </PrimaryButton>
    </Page>
  )
}

RecommendedCourses.displayName = 'RecommendedCoursesV2'

export default RecommendedCourses
