import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import {
  SidebarWrap,
  SidebarHeader,
  SidebarBody,
  SidebarFooter,
  CoursesNotFound,
  TotalText,
  ExploreButton,
  ReviewButton
} from './style/index'
import CourseCard from './CourseCard'
import RemoveModal from './RemoveConfirmationModal'
import CloseIcon from '../../assets/icons/close-icon.svg'

const Sidebar = (props) => {
  const {
    courses, onRemove, onUpdate, history, updateContext, isInstride
  } = props
  const [removeData, setRemoveData] = useState(null)
  const [removeModal, setRemoveModal] = useState(false)
  const [courseName, setCourseName] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [slideIn, setSlideIn] = useState(false)

  useEffect(() => {
    setSlideIn(true)
  }, [])

  function closeSideBar () {
    setSlideIn(false)
    setTimeout(props.handleClose, 250)
  }

  return (
    <SidebarWrap
      data-testid='sidebar'
      slideIn={slideIn}
    >
      <SidebarHeader data-testid='sidebar-header'>
        <h5>Selected courses</h5>
        <img
          src={CloseIcon} alt=''
          onClick={closeSideBar}
        />
      </SidebarHeader>
      {courses.length ? (
        <>
          <SidebarBody data-testid='sidebar-body' isEdit={isEdit}>
            {courses.map((item, key) => (
              <CourseCard
                key={key}
                removeClick={() => {
                  setRemoveData(key)
                  setRemoveModal(true)
                  setCourseName(item.name)
                  updateContext({ localCourses: courses })
                }}
                data={item}
                onUpdate={onUpdate}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
              />
            ))}
            {!isEdit && (
              <TotalText>
                Total: {courses.length} {isInstride ? 'course' : 'token'}{courses.length > 1 ? 's' : ''}
              </TotalText>
            )}
          </SidebarBody>
          {!isEdit ? (
            <SidebarFooter data-testid='sidebar-footer'>
              <ExploreButton onClick={closeSideBar}>
                Explore catalog
              </ExploreButton>
              <ReviewButton
                onClick={() => {
                  setSlideIn(false)
                  setTimeout(() => {
                    history.push('/review-schedule')
                  }, 250)
                }}
              >
                Review selection
              </ReviewButton>
            </SidebarFooter>
          ) : null}
        </>
      ) : (
        <CoursesNotFound>You haven’t selected any courses yet.</CoursesNotFound>
      )}
      <RemoveModal
        show={removeModal}
        onHide={() => setRemoveModal(false)}
        onRemove={() => onRemove(removeData)}
        courseName={courseName}
      />
    </SidebarWrap>
  )
}

Sidebar.displayName = 'Sidebar'
export default withRouter(Sidebar)
