import styled, { createGlobalStyle } from 'styled-components'
import media from '../../../mediaQueries'
import BackBreadcrumb from '../../BackBreadcrumb/BackBreadcrumb'
import {
  PrimaryButton as PrimaryButtonStyle,
  SecondaryButton as SecondaryButtonStyle
} from '../../Buttons/style'
import {
  font14x16x700,
  font14x24x400,
  font16x19x400,
  font16x24x400,
  font16x24x700,
  font18x24x300,
  font18x24x400,
  font24x29x300,
  font24x32x300,
  font32x48x300
} from '../../global.styled'

export const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
  }
`

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  padding-bottom: 75px;
  flex-direction: column;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  background: linear-gradient(180deg, #211631 0%, #000000 100%);
  color: #FFFFFF;
  margin-top: 64px;
  align-items: center;
  justify-content: center;
`

export const Page = styled.div`
  max-width: 600px;
  margin: 0 29px;
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  flex: 1;
  justify-content: center;

  canvas{
    position: absolute;
    height: 100%;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 0;
  }

  & img.bank {
    width: 64px;
  }

  & > p {
    margin-top: ${({ marginTop }) => marginTop || '24px'};

    &.small-title {
      ${font18x24x400}
    }
  }
`

export const BackButton = styled(BackBreadcrumb)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: 0;
  padding-left: 24px;
  margin-top: 24px;
  z-index: 1;
`

export const Title = styled.p`
  margin-top: 24px;
  margin-bottom: 0;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  ${font32x48x300}
`

export const Subtext = styled.p`
  margin-top: ${({ marginTop }) => marginTop || '12px'};
  margin-bottom: 0;
  text-align: center;
  ${font24x32x300}
`

export const Description = styled.p`
  margin-top: ${({ marginTop }) => marginTop || '12px'};
  margin-bottom: 0;
  ${font18x24x300}

  & span {
    &.link {
      color: #5FC4B8;
      text-decoration: underline;
      cursor: pointer;
      ${font18x24x300}
    }
  }
`

export const WarningText = styled.p`
  margin-bottom: 0;
  margin-top: ${({ marginTop }) => marginTop || '18px'};
  ${font14x24x400}

  & span {
    color: #5FC4B8;
    text-decoration: underline;
    cursor: pointer;
    ${font14x24x400}
  }
`

export const PrimaryButton = styled(PrimaryButtonStyle)`
  margin-top: ${({ margintop }) => margintop || '48px'};
  margin-right: ${({ marginright }) => marginright || '0px'};
  padding: 16px 24px;
  height: auto;
  width: fit-content;
  background-color: #5FC4B8;
  z-index: 1;

  ${media.smallMobile`
    width: 100%;
    margin-right: 0;
  `}

  &:hover {
    background-color: rgb(81 162 153) !important;
    border-color: rgb(81 162 153) !important;
  }
`

export const SecondaryButton = styled(SecondaryButtonStyle)`
  margin-top: ${({ margintop }) => margintop || '48px'};
  padding: 16px 24px;
  height: auto;
  width: fit-content;
  border: 3px solid #5FC4B8;

  ${media.smallMobile`
    width: 100%;
    margin-top: 12px;
  `}
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  width: 100%;

  ${media.smallMobile`
    flex-direction: column;
  `}
`

export const RadioWrapper = styled.div`
  margin-top: 48px;
  width: 100%;
`

export const Term = styled.p`
  text-transform: uppercase;
  margin-top: ${({ marginTop }) => marginTop || '36px'};
  margin-bottom: 12px;
  ${font14x16x700}
`

export const GroupHeaderMessageContainer = styled.div`
  display: flex;
  width: 600px;
  padding: 19px 16px;
  margin-bottom: 16px;
  align-items: center;
  gap: 16px;
  align-self: center;
  border-radius: 5px;
  background-color: #1D1D1F;
`

export const GroupHeaderMessage = styled.span`
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const CourseSlotContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100px;
  border: 1px solid white;
  border-radius: 5px;
  margin-bottom: 16px;
  background: rgba(0,0,0,0.25);

  & > div {
    &.img {
      border-right: 1px solid white;
      flex-shrink: 0;
      height: 100px;
      width: 102px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 42px!important;
        font-style: normal;
        ${
          ({ isSvg }) => isSvg
            ? 'filter: invert(68%) sepia(27%) saturate(656%) hue-rotate(123deg) brightness(100%) contrast(85%);'
            : ''
        }
      }

      ${media.smallMobile`
        border-right: unset;
        width: unset;
        margin-left: 24px;
      `}
    }

    &.course {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 24px;
      padding-right: 16px;

      & p {
        margin-bottom: 0;

        &:first-child {
          ${font18x24x400}
        }

        &:last-child {
          ${font16x24x400}
        }
      }

      ${media.smallMobile`
        flex-direction: column;
        align-items: stretch;
        padding: 24px;
      `}
    }
  } 
`

export const CourseDetails = styled.div`
  padding: 24px;
  width: 100%;
  background-color: #1D1D1F;
  border-radius: 0 0 5px 5px;

  & > p {
    margin-bottom: 6px;
    ${font24x29x300}
  }

  & > div {
    display: flex;
    justify-content: space-between;

    & span {
      ${font16x19x400}
    }

    & button {
      color: #5FC4B8;
      width: 140px;
      text-transform: uppercase;
      background-color: transparent;
      border: 0 none;
      outline: none;
      ${font14x16x700}

      & > img {
        margin-left: 9px;
      }
    }
  }
`

export const KalturaVideoPlayer = styled.div`
  width: 50%;
  object-fit: contain;
  aspect-ratio: 1 / 0.6;
  background-color: black;
  margin: 0px auto 0 auto;

  ${media.mobile`
    width: 100%;
    aspect-ratio: 1 / 0.56;
  `}

  ${media.smallMobile`
    aspect-ratio: 1 / 0.89;
  `}
`

export const ModalWrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  justify-content: center;
  align-items: center;
  z-index: 1;
`

export const ModalHeader = styled.div`
  display: flex;
  padding: 12px 24px;
  background-color: black;
  border: 0 none;
  width: 100%;
  justify-content: flex-end;
`

export const Close = styled.div`
  width: 14px;
  height: 14px;
  background-image: url(/images/icons/icon-close.svg);
  margin: 0;
  cursor: pointer;
`

export const ModalBody = styled.div`
  padding: ${({ padding }) => padding || '0'};
  width: 100%;
  ${({ bgColor }) => bgColor && `background-color:  ${bgColor}`};

  & > p {
    margin-bottom: 0;

    &.confirm-change-message {
      ${font16x24x400}
    }
  }
`

export const HeroImage = styled.div`
  width: 500px;
  height: 300px;
  display: grid;
  margin-top: 24px;

  ${media.smallMobile`
    width: 100%;
    height: auto;
  `}

  & > img {
    width: 500px;
    height: 300px;
    opacity: 0.5;

    ${media.smallMobile`
      width: 100%;
      height: auto;
    `}

    &:last-child {
      width: 48px;
      height: 48px;
      cursor: pointer;
      position: absolute;
      display: inline-block;
      margin-left: 226px;
      margin-top: 126px;

      ${media.smallMobile`
        margin-left: calc(100vw/2 - 63px);
        margin-top: 65px;
      `}
    }
  }
`

export const ConfirmChangeModalWrapper = styled.div`
  position: fixed;
  background-color: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const ModalContainer = styled.div`
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  position: absolute;
  background-color: #25272B;
  width: 455px;
  border-radius: 5px;
  padding: 0;
  top: 112px;
  left: calc(50% - 227px);
`

export const ConfirmChangeModalHeader = styled.div`
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ffffff;
  & > p{
    margin-bottom: 0;
    ${font16x24x700}
  }
`
