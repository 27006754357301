import { useAuth0 } from '../Auth0Provider/Auth0Provider'
import { isAuthRequired } from '../../utilities/authUtils'

const AppGuard = ({ children }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const { hash, search } = window.location
  const searchParams = new URLSearchParams(search)
  const emailParam = decodeURIComponent(searchParams.get('email') || '')
  const forceLoginRedirect = () => {
    setTimeout(() => {
      loginWithRedirect({
        redirect_uri: window.location.origin,
        login_hint: emailParam,
        appState: { targetUrl: window.location.href }
      })
    }, 1000)
    return true
  }

  const auth0token = localStorage.getItem('auth0token')
  if (auth0token) return children

  if (!isAuthenticated) return isAuthRequired(hash) ? forceLoginRedirect() : children

  return children
}

export default AppGuard
