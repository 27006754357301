import React, { useEffect, useRef } from 'react'
import { BorderLine, CalendarListContainer, CalendarListText } from './styles'

export default function CalendarList ({ calendarKey, handleCalendarDropdown }) {
  const calendarRef = useRef()

  const stopPropagation = (e) => {
    e.stopPropagation()
  }

  const loadScript = () => {
    const script = document.createElement('script')
    script.id = 'addEventScript'
    script.async = true
    script.type = 'text/javascript'
    script.defer = true
    script.src = 'https://cdn.addevent.com/libs/stc/1.0.2/stc.min.js'
    document.body.appendChild(script)
  }

  const checkIfClickedOutside = e => {
    // If the clicked target is not within the dropdown, close the dropdown
    const shouldHideDropdown = calendarRef.current &&
     !calendarRef.current.contains(e.target)
    if (shouldHideDropdown) {
      handleCalendarDropdown()
    }
  }

  useEffect(() => {
    const { addeventstc } = window
    addeventstc ? addeventstc.refresh() : loadScript()

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <CalendarListContainer
      onClick={stopPropagation}
      ref={calendarRef}
    >
      {calendarKey &&
        <>
          <CalendarListText
            data-styling='none'
            title='Add to Calendar'
            data-direct='outlook'
            className='addeventstc'
            data-id={calendarKey}
          >
            Outlook Calendar
          </CalendarListText>

          <BorderLine />

          <CalendarListText
            title='Add to Calendar'
            className='addeventstc'
            data-direct='google'
            data-id={calendarKey}
          >
            Google Calendar
          </CalendarListText>

          <BorderLine />

          <CalendarListText
            title='Add to Calendar'
            className='addeventstc'
            data-direct='apple'
            data-id={calendarKey}
          >
            Apple Calendar
          </CalendarListText>

          <BorderLine />
        </>}
    </CalendarListContainer>
  )
}

CalendarList.displayName = 'CalendarList'
