import React, { useContext } from 'react'
import { SectionPageTitle, TitleHeading, Description, Link } from './style'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  APPLIED_COMPUTING,
  LIBERAL_STUDIES,
  BUSINESS_ADMINISTRATION,
  APPLIED_COMPUTING_INFO_LINK,
  LIBERAL_STUDIES_INFO_LINK,
  BUSINESS_ADMINISTRATION_INFO_LINK
} from '../../Constants/gguDegrees'

const PageTitle = props => {
  const { title, isGGUStudentView, children, className } = props
  const { prospects } = useContext(AppContext)

  const getGGUDegreeInfoLink = () => {
    const { degree } = prospects?.[0] || {}

    switch (degree) {
      case APPLIED_COMPUTING:
        return APPLIED_COMPUTING_INFO_LINK
      case LIBERAL_STUDIES:
        return LIBERAL_STUDIES_INFO_LINK
      case BUSINESS_ADMINISTRATION:
        return BUSINESS_ADMINISTRATION_INFO_LINK
      default: return null
    }
  }

  return (
    <SectionPageTitle className={className}>
      <TitleHeading>{title}</TitleHeading>
      {children}
      {isGGUStudentView && (
        <Description data-testid='description'>Taking these courses will contribute to your associate’s degree. You can review your program’s course requirements
          &nbsp;<Link href={getGGUDegreeInfoLink()} target='_blank'>here</Link>.
        </Description>
      )}
    </SectionPageTitle>
  )
}

export default PageTitle
