import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import config from '../../config'
import {
  HeaderText, HeaderTitle, HeaderWrapper, PageWrapper, VideoPlayer
} from './style'

const {
  configureKalturaPlayer,
  getKalturaSettings: { PARTNER_ID, UICONF_ID, scriptUrl }
} = config

const KALTURA_UUIDS = {
  'part-1': '1_acj6fuxb',
  'part-2': '1_tzso5zxz',
  'part-3': '1_lpuoblcb'
}

const VALID_PART_NUMBERS = ['part-1', 'part-2', 'part-3']

function GGUGettingStarted (props) {
  const [isKalturaLoading, setIsKalturaLoading] = useState(true)
  const { partNumber = 'part-1' } = useParams() || {}

  useEffect(() => {
    loadKalturaScript(scriptUrl)
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (isKalturaLoading) return
    const videoUUID = KALTURA_UUIDS[partNumber]
    configureKalturaPlayer(videoUUID, PARTNER_ID, UICONF_ID)
  }, [isKalturaLoading, partNumber])

  const loadKalturaScript = (url) => {
    if (document.getElementById('Kaltura-Script')) {
      setIsKalturaLoading(false)
      return
    }

    const KalturaScript = document.createElement('script')
    KalturaScript.id = 'Kaltura-Script'
    KalturaScript.type = 'text/javascript'
    KalturaScript.src = url

    KalturaScript.onload = () => {
      setIsKalturaLoading(false)
    }

    document.body.appendChild(KalturaScript)
  }

  if (!VALID_PART_NUMBERS.includes(partNumber)) {
    return <Redirect to='/' />
  }

  return (
    <PageWrapper>
      <HeaderWrapper>
        <HeaderTitle>Getting Started</HeaderTitle>
        <HeaderText>{partNumber.replace('-', ' ')}</HeaderText>
      </HeaderWrapper>

      <VideoPlayer id='kaltura_video_player' />
    </PageWrapper>
  )
}

GGUGettingStarted.displayName = 'GGUGettingStarted'
export default GGUGettingStarted
