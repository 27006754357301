import React, { useContext, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import api from '../../api'
import {
  COURSE_QUANTITY_LS_KEY,
  COURSE_QUANTITY_TOOLTIP,
  STUDY_MODE
} from '../../Constants/gguDegrees'
import {
  Description,
  Page,
  Title,
  ButtonsWrapper,
  PrimaryButton,
  SecondaryButton,
  WarningText
} from './style'
import { GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  isSegmentEventInLS,
  removeSegmentEventFromLS,
  setCurrentProspect,
  setFiredSegmentEventsInLS
} from '../../utilities/gguDegreeUtils'
import { COURSE_REGISTRATION_PAGEVIEW, COURSE_REGISTRATION_PAGE_SUBMITTED } from '../../Constants/eventType'

const CourseQuantity = ({
  setIsLoading, courseQuantity, currentProspect, setCurrentPage
}) => {
  const { sendEvent } = useSegmentEvents()
  const { FULL_TIME, PART_TIME } = STUDY_MODE
  const { updateContext } = useContext(AppContext)
  const { prospectId, studyMode } = currentProspect || {}
  const currentStudyMode = studyMode || FULL_TIME

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: '3-1'
  }

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: '2-2'
    })
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'Confirm Quantity',
        page_number: '3-1',
        time_commitment: currentStudyMode
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  const confirmCourseQuantity = async () => {
    localStorage.setItem(COURSE_QUANTITY_LS_KEY, courseQuantity)
    setIsLoading(true)
    const result = await api.updateProspectData(prospectId, { studyMode: currentStudyMode })
    setIsLoading(false)

    if (isEmpty(result)) return

    sendEvent({
      eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
      properties: {
        page_name: 'Confirm Quantity',
        page_number: '3-1',
        time_commitment: currentStudyMode,
        confirm_or_change: 'Confirmed',
        confirmed_course_quantity: '3'
      }
    })
    setCurrentProspect(
      updateContext,
      {
        ...currentProspect,
        studyMode: currentStudyMode
      })
    setCurrentPage(GGU_V2_ENROLLMENT_PAGES.RECOMMENDED_COURSES)
  }

  return (
    <Page alignItems='flex-start'>
      <Title textAlign='left'>
        Confirm the number of courses you'll take
      </Title>
      <Description>
        You're enrolled {currentStudyMode.toLowerCase()}. That means you'll need to&nbsp;
        complete {courseQuantity} courses this term. Keep in mind: the average course requires&nbsp;
        10-15 hours of work each week to maximize your success.
      </Description>
      <WarningText>
        Taking fewer than 3 courses per term will change your status to part-time.&nbsp;
        This could affect your financial aid and graduation date.&nbsp;
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={props => (
            <Tooltip id='button-tooltip' {...props}>
              {COURSE_QUANTITY_TOOLTIP}
            </Tooltip>
          )}
        >
          <span>Learn more.</span>
        </OverlayTrigger>

      </WarningText>
      <ButtonsWrapper>
        <PrimaryButton onClick={confirmCourseQuantity} marginright='16px'>
          Confirm
        </PrimaryButton>
        <SecondaryButton
          variant='secondary'
          onClick={() => {
            sendEvent({
              eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
              properties: {
                page_name: 'Confirm Quantity',
                page_number: '3-1',
                time_commitment: PART_TIME,
                confirm_or_change: 'Changed',
                confirmed_course_quantity: '2'
              }
            })
            setCurrentPage(GGU_V2_ENROLLMENT_PAGES.CHANGE_COURSE_QUANTITY)
          }}
        >
          Change Course Quantity
        </SecondaryButton>
      </ButtonsWrapper>
    </Page>
  )
}

CourseQuantity.displayName = 'CourseQuantity'

export default CourseQuantity
