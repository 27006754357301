import { useContext } from 'react'
import qs from 'qs'
import api from '../../api'
import { AppContext } from '../../Components/ContextProvider/ContextProvider'
import {
  COURSE_REGISTRATION_PAGE_SUBMITTED,
  COURSE_REGISTRATION_STARTED,
  NEW_COURSE_SELECTED,
  REGISTERED_FOR_COURSES,
  SCHEDULE_ADVISOR_MEETING_CLICK
} from '../../Constants/eventType'
import { MULTIPLE } from '../../Constants/frequency'
import { getCurrentProspect } from '../../utilities/gguDegreeUtils'

const useSegmentEvents = () => {
  const params = qs.parse(window.location.search?.slice(1))
  const isRegistrationRedesign = params?.gguEnrollmentV2 === 'true'
  const {
    currentSemester,
    prospects
  } = useContext(AppContext) || {}
  const { semesterNumber, term } = currentSemester || {}
  const currentProspect = getCurrentProspect(prospects)
  const { degree, gguSemesters } = currentProspect || {}
  const { term: cohort } = gguSemesters?.sort((a, b) => {
    return new Date(b.openEnrollment) - new Date(a.openEnrollment)
  })?.[0] || {}

  const getDefaultProperties = eventName => {
    let properties = {}
    switch (eventName) {
      case COURSE_REGISTRATION_STARTED:
        properties = {
          degree,
          ...(isRegistrationRedesign ? { term } : {}),
          time_stamp: Date.now()
        }
        break
      case COURSE_REGISTRATION_PAGE_SUBMITTED:
      case NEW_COURSE_SELECTED:
      case REGISTERED_FOR_COURSES:
      case SCHEDULE_ADVISOR_MEETING_CLICK:
        properties = {
          time_stamp: Date.now()
        }
        break
      default:
        properties = {}
    }

    return {
      product: 'Degree',
      url: window.location.href,
      semester: `${semesterNumber}`,
      cohort,
      ...properties
    }
  }

  const sendEvent = ({
    eventName, properties, frequency = MULTIPLE
  }) => {
    const finalProperties = {
      ...getDefaultProperties(eventName),
      ...(properties || {})
    }
    api.submitTrackedEvent({
      event: eventName,
      properties: {
        ...finalProperties,
        ...(
          finalProperties.time_stamp
            ? {
              time_stamp: new Date()
                .toLocaleString(
                  'en-US', { dateStyle: 'short', timeStyle: 'short', hourCycle: 'h23' }
                )
            } : {}
        )
      },
      frequency
    })
  }

  return { sendEvent }
}

useSegmentEvents.displayName = 'useSegmentEvents'

export default useSegmentEvents
