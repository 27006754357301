import React, { useState, useContext } from 'react'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import {
  Container,
  Header,
  Label,
  Input,
  Content,
  ErrorContainer
} from './styles'
import { PrimaryButton } from '../Buttons/style'
import utils from '../../utilities'
import api from '../../api'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'
import { PERMISSION_REQUESTED } from './permissionStates'
import { PERMISSION_REQUESTED_KEY } from '../../Constants'

const PermissionRequired = ({
  email,
  setEmail,
  setPermissionState
}) => {
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { user: { email: studentEmail } } = useContext(Auth0Context)

  const sendEmail = async () => {
    setError('')

    const isValidEmail = utils.validateEmail(email)
    if (!isValidEmail) return setError('Enter valid email')
    const isTheSameEmail = utils.isTheSameEmail(email, studentEmail)
    if (isTheSameEmail) {
      return setError(
        'Oops! You\'ve entered your own email. Please share a different email address.'
      )
    }

    localStorage.removeItem(PERMISSION_REQUESTED_KEY)

    setIsLoading(true)
    const { success, message } = await api.requestGuardianPermission(email)
    setIsLoading(false)
    if (!success) return setError(message)

    localStorage.setItem(PERMISSION_REQUESTED_KEY, 'true')
    setPermissionState(PERMISSION_REQUESTED)
  }

  if (isLoading) return <LoadingSpinner />

  return (
    <Container>
      <div className='content'>
        <Header>Permission needed to continue</Header>
        <Content>
          It’s awesome you’re getting an early start on college-level courses,
          but because you are under 18 years old, first your parent or guardian
          will need to agree to our Terms of Use and Privacy Policy for you.
          Please provide us with their email address below to get started.
        </Content>
        <Content>
          If it is not possible to reach your parent or guardian this way or if you
          need additional assistance, please reach out to
          <span className='success'> success@outlier.org</span>.
        </Content>
        <Label>Parent or guardian’s email address</Label>
        {error
          ? (
            <ErrorContainer>
              <img alt='alert-icon' src='images/icons/icon-alert-exclamation.svg' />
              <span data-testid='error' className='error'>
                {error}
              </span>
            </ErrorContainer>
          )
          : null}
        <Input
          hasError={!!error}
          className='fs-exclude'
          type='email'
          data-testid='email'
          onChange={e => setEmail(e.target.value)}
          value={email}
        />
        <PrimaryButton
          data-testid='btn-send-email'
          onClick={sendEmail}
        >
            Submit
        </PrimaryButton>
      </div>
    </Container>
  )
}

PermissionRequired.displayName = 'PermissionRequired'
export default PermissionRequired
