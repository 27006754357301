import styled, { createGlobalStyle } from 'styled-components'
import { Button as BootstrapButton } from 'react-bootstrap'
import mediaqueries from '../../mediaQueries'

export const GlobalStyle = createGlobalStyle`
  .modal-dialog {
    max-width: none;
  }
  
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-body {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    & > p {
      margin-bottom: 24px;
      font-weight: 300;
    }
    & > ol {
      margin-bottom: 24px;
    }
  }

  .modal-content {
    background-color: #161618;
    width: 700px;
    min-height: 203px;
    display: flex;
    align-items: flex-start;
    margin: auto;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    ${mediaqueries.mobile`
      width: 525px;
    `}
  
    ${mediaqueries.smallMobile`
      width: 324px;
    `}
  
    ${mediaqueries.foldable`
      width: 250px;
    `}
  }
`

export const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;

  ${mediaqueries.mobile`
    flex-direction: column;

    & > button:first-child {
      margin-bottom: 10px;
    }
  `}

`

const buttonStyle = `
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: 16px;
  padding-left: 24px;
  padding-right: 24px;
`

export const PrimaryButton = styled(BootstrapButton)`
  ${buttonStyle}
  background-color: #77B1AF;
  color: #161618;
  border: none;
  white-space: ${props => props.whiteSpace || 'nowrap'};
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '40px'};
  ${({ padding }) => padding && `padding: ${padding};`}
  ${mediaqueries.mobile`
    width: 100%;
  `}

  &:active {
    background-color: #77B1AF !important;
    color: #161618 !important;
    border: 0 none !important;
  }

  &:focus {
    outline: 0;
    background-color: #77B1AF;
    color: #161618;
    border: none;
    box-shadow: unset;
  }

  &:hover {
    background-color: #77B1AF;
    color: #161618;
    border: none;
  }

  &:disabled {
    color: #212529;
    background-color: #78BCB8;
    border-color: #78BCB8;
    pointer-events: none;
  }
`

export const SecondaryButton = styled(BootstrapButton)`
${buttonStyle}
  background-color: #1D1D1F;
  color: #77B1AF;
  border: 3px solid #77B1AF;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '40px'};

  ${mediaqueries.mobile`
    width: 100%;
  `}

  &:active {
    background-color: #1D1D1F !important;
    color: #77B1AF !important;
    border: 3px solid #77B1AF !important;
  }

  &:focus {
    outline: 0;
    background-color: #1D1D1F;
    color: #77B1AF;
    border: 3px solid #77B1AF;
    box-shadow: unset;
  }

  &:hover {
    color: #77B1AF;
    background-color: #1D1D1F;
    border: 3px solid #77B1AF;
  }

  &:disabled {
    background-color: #161618;
    border-color: #77b1af;
    color: #77b1af;
    pointer-events: none;
  }
`

export const StyledHeaderText = styled.p`
  font-weight: 400 !important;
`

export const StyledList = styled.ol`
  font-weight: 300;
`

export const StyledAnchor = styled.a`
  color: #78bcb8 !important;
  &:hover {
    text-decoration: none;
  }
`
