import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import { Button } from 'react-bootstrap'

export const WelcomeMsg = styled.span`
  font-weight: normal;
  font-size: 18px;
  color: #98a1aa;

  @media (min-width: 420px) {
    float: right;
    margin-top: 17px;
  }

  @media (max-width: 767px) {
    float: none;
  }
`

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CenterContainer = styled(PageContainer)`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const PageHeader = styled.h1`
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;
  margin-bottom: 16px;
`

export const TextContent = styled.p`
  color: #FFF;
  width: 590px;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;

  ${mediaqueries.mobile`
    width: fit-content;
  `}
`

export const PrimaryButton = styled(Button)`
  display: flex;
  padding: 16px 24px;
  margin: 0;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #5FC4B8;
  width: fit-content;

  color: #161618;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:hover {
    background: #b8fff7;
  }

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: #5FC4B8;
    color: #161618;
    text-decoration: none;
  }
`
