import React from 'react'
import {
  Card,
  CourseListInfoContainer,
  CourseListInfoChildContainer,
  CourseListContainer,
  ChecklistIcon,
  ChecklistIconWrapper,
  ProgressBarContainer,
  ProgressBarBackground,
  ProgressBar,
  FlexWrapper,
  CourseStartDate,
  IsCaughtUp
} from './styles'
import { getProgressBarColor } from '../CourseList/courseListUtils'
import { WHITE, GREY, YELLOW, RED, VIOLET } from '../checklistColors'
import { ASSIGNMENT, EXAM } from '../../../Constants/sectionType'

const CourseListCard = ({
  displayName,
  courseResourcesSchedule,
  activityCount,
  isAudit,
  hasCohortStarted,
  isCaughtUp,
  progress,
  onClick,
  activeAssessments = []
}) => {
  const hasActiveExam = activeAssessments.some(({ type }) => type === EXAM)
  const hasActiveAssignment = activeAssessments.some(
    ({ type }) => type === ASSIGNMENT
  )
  const activeExams = activeAssessments.filter(({ type }) => type === EXAM)
  const activeAssignments = activeAssessments.filter(
    ({ type }) => type === ASSIGNMENT
  )
  const getAssessmentLabel = () => {
    if (hasActiveExam) {
      if (hasActiveAssignment) {
        return `${activeAssessments.length} Assessments`
      }
      if (activeExams.length > 1) {
        return `${activeExams.length} Assessments`
      }
      return EXAM
    }
    if (hasActiveAssignment) {
      if (activeAssignments.length > 1) {
        return `${activeAssignments.length} Assignments`
      }
      return ASSIGNMENT
    }

    return ''
  }

  const getAssessmentWarningColor = () => {
    const hasActiveAssessmentRedColor = activeAssessments.some(
      ({ assessmentColor }) => assessmentColor === RED
    )
    if (hasActiveAssessmentRedColor) return RED

    const hasActiveAssessmentYellowColor = activeAssessments.some(
      ({ assessmentColor }) => assessmentColor === YELLOW
    )
    if (hasActiveAssessmentYellowColor) return YELLOW

    const hasActiveAssessmentGreyColor = activeAssessments.some(
      ({ assessmentColor }) => assessmentColor === GREY
    )
    if (hasActiveAssessmentGreyColor) return GREY

    return ''
  }

  const assessmentLabel = getAssessmentLabel()

  const renderCheckListIconSection = () => {
    const progressBarColor = getProgressBarColor(
      progress,
      courseResourcesSchedule,
      isAudit
    )
    const incompleteActivitiesWarningColor =
      progressBarColor === WHITE ? GREY : progressBarColor
    const assessmentWarningColor = getAssessmentWarningColor()

    const showAssessmentLabel = assessmentLabel && !isAudit

    return (
      <>
        <CourseListInfoChildContainer data-testid='courseListInfoContainer'>
          <CourseListContainer>
            <ChecklistIconWrapper color={incompleteActivitiesWarningColor}>
              <ChecklistIcon
                width='14px'
                height='10.5px'
                color={incompleteActivitiesWarningColor}
                marginRight='6px'
                src='images/icons/checklist.svg'
              />

              {!!activityCount && (
                <CourseListContainer className='activityCount'>
                  {activityCount}
                </CourseListContainer>
              )}
            </ChecklistIconWrapper>

            {showAssessmentLabel && (
              <ChecklistIconWrapper
                data-testid='assessment-icon'
                color={assessmentWarningColor}
              >
                <ChecklistIcon
                  height='16px'
                  width='16px'
                  color={assessmentWarningColor}
                  marginRight='4px'
                  src='images/icons/calendar.svg'
                />
                {assessmentLabel}
              </ChecklistIconWrapper>
            )}
          </CourseListContainer>
          <ChecklistIcon
            width='13px'
            height='7px'
            src='images/icons/caret-right.svg'
          />
        </CourseListInfoChildContainer>
        <>
          <ProgressBarContainer noMargin>
            <ProgressBarBackground />
            <ProgressBar percent={progress} color={progressBarColor} />
          </ProgressBarContainer>
        </>
      </>
    )
  }

  const renderCourseListInfo = () => {
    if (!isCaughtUp && hasCohortStarted) {
      return renderCheckListIconSection()
    }
    const { startDate } = courseResourcesSchedule?.[0] || {}
    const showCaughtUpMessage = isCaughtUp && !isAudit

    return (
      <FlexWrapper>
        {showCaughtUpMessage && (
          <>
            <ChecklistIcon
              width='12px'
              height='9.55px'
              color={VIOLET}
              src='images/icons/icon-check-basic.svg'
            />
            <IsCaughtUp data-testid='caughtUpText'>
              Caught up
            </IsCaughtUp>
          </>
        )}
        {!hasCohortStarted && (
          <CourseStartDate data-testid='courseStartDate'>
            Begins {startDate}
          </CourseStartDate>
        )}
        <ChecklistIcon
          width='13px'
          height='7px'
          flex
          src='images/icons/caret-right.svg'
        />
      </FlexWrapper>
    )
  }

  return (
    <Card hasProgressBar={isCaughtUp || !hasCohortStarted} onClick={onClick}>
      <h6 data-testid='courseName'>
        {isAudit ? `${displayName} (Audit)` : displayName}
      </h6>
      <CourseListInfoContainer hasAssessment={assessmentLabel}>
        {renderCourseListInfo()}
      </CourseListInfoContainer>
    </Card>
  )
}

CourseListCard.displayName = 'CourseListCard'
export default CourseListCard
