import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import Option from './Option'

const Options = ({
  questionUUID,
  options,
  correctAnswer,
  studentAnswer,
  setCurrentAnswer,
  hasSingleAnswer
}) => {
  const [selectedIndeces, setSelectedIndeces] = useState([])

  useEffect(() => {
    const { answer } = studentAnswer || {}
    setSelectedIndeces(answer || [])

    // eslint-disable-next-line
  }, [questionUUID])

  useEffect(() => {
    setCurrentAnswer(selectedIndeces?.length ? selectedIndeces : null)

    // eslint-disable-next-line
  }, [selectedIndeces])

  const handleSingleSelection = selectedIndex => {
    if (selectedIndeces.includes(selectedIndex)) {
      return setSelectedIndeces([])
    }

    setSelectedIndeces([selectedIndex])
  }

  const handleMultipleSelection = selectedIndex => {
    const indeces = cloneDeep(selectedIndeces)

    if (indeces.includes(selectedIndex)) {
      const newIndeces = indeces.filter(index => index !== selectedIndex)
      return setSelectedIndeces(newIndeces)
    }

    indeces.push(selectedIndex)
    setSelectedIndeces(indeces)
  }

  const selectionChangeHandler = selectedIndex => {
    if (hasSingleAnswer && correctAnswer.length === 1) {
      handleSingleSelection(selectedIndex)
      return
    }

    handleMultipleSelection(selectedIndex)
  }

  const isChecked = index => selectedIndeces.includes(index)

  return (
    options.map((option, index) => {
      const isOptionSelected = isChecked(index)
      const isFeedbackCorrect = correctAnswer
        .map(answer => Number(answer) - 1)
        .includes(index)

      return (
        <Option
          key={`mcq-option-${questionUUID}-${index}`}
          index={index}
          isOptionSelected={isOptionSelected}
          studentAnswer={studentAnswer}
          isFeedbackCorrect={isFeedbackCorrect}
          option={option}
          onChange={selectionChangeHandler}
          hasSingleAnswer={hasSingleAnswer}
        />
      )
    })
  )
}

Options.displayName = 'Options'

Options.propTypes = {
  questionUUID: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  correctAnswer: PropTypes.arrayOf(PropTypes.string),
  studentAnswer: PropTypes.object,
  setCurrentAnswer: PropTypes.func,
  hasSingleAnswer: PropTypes.bool
}

export default Options
