import React from 'react'
import { CalendarContainer } from 'react-datepicker'
import {
  ButtonWrapper,
  DatePickerContainer,
  DatePickerTitle,
  DatePickerWrapper,
  OverrideDatePickerStyles,
  SelectDateButton
} from './style/datePickerStyles'

export default function DatePickerContainerWrapper (
  selectDateClickHandler,
  closeDatePickerModal
) {
  const MyContainer = ({ className, children }) => {
    return (
      <DatePickerWrapper data-testid='date-modal' onClick={(e) => e.stopPropagation()}>
        <OverrideDatePickerStyles />
        <DatePickerTitle>
          <div>Select start date</div>
          <div
            data-testid='button-close-calendar'
            onClick={closeDatePickerModal}
          />
        </DatePickerTitle>
        <CalendarContainer className={className}>
          <DatePickerContainer>{children}</DatePickerContainer>
        </CalendarContainer>
        <ButtonWrapper>
          <SelectDateButton
            data-testid='button-date-select'
            onClick={(e) => {
              e.preventDefault()
              selectDateClickHandler()
            }}
          >
            select date
          </SelectDateButton>
        </ButtonWrapper>
      </DatePickerWrapper>
    )
  }

  return MyContainer
}
