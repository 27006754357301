import React, { useContext, useState } from 'react'

import {
  Box,
  BoxWrap,
  EnrolText,
  EnrollSpan,
  Button,
  CardWrap,
  CartButton
} from './style'
import {
  getDateStringWithYearLongMonth,
  changePTDateToLocalTime
} from '../../utilities/dateTime'
import { cohortTypes, durationTypes } from '../../Constants/cohort'
import EditCard from '../ScheduleReview/EditCard'
import { useLocation } from 'react-router-dom'
import CartSideBarPanel from '../CartSideBarPanel/CartSideBarPanel'
import { AppContext } from '../ContextProvider/ContextProvider'

const Card = (props) => {
  const { data, onRemove, onUpdate, cartItems } = props

  const { SHORT, LONG } = cohortTypes
  const { INTENSIVE, STANDARD } = durationTypes

  const local = useLocation()
  const [isEdit, setIsEdit] = useState(false)
  const [isEditData, setEditData] = useState(null)
  const [type, setType] = useState(data.status)
  const [week, setWeek] = useState(
    data.duration === SHORT ? INTENSIVE : STANDARD
  )
  const [startDate, setStartDate] = useState(
    changePTDateToLocalTime(data.dateStar)
  )
  const isDataEdit = isEdit && data?.id === isEditData
  const [toggleSidebar, setToggleSidebar] = useState(
    local.state?.isSidebarOpen || false
  )
  const { updateContext } = useContext(
    AppContext
  )

  const toggleSideBar = () => setToggleSidebar(!toggleSidebar)

  const onRemoveCart = (index) => {
    cartItems.splice(index, 1)
    localStorage.setItem('userCart', JSON.stringify(cartItems))
    updateContext({ localCourses: cartItems })
  }

  const setCourseState = (value, courseState) => {
    switch (courseState) {
      case 'type':
        return setType(value)
      case 'duration':
        return setWeek(value)
      default:
        return false
    }
  }

  return (
    <CardWrap data-testid='card'>
      <Box isEdit={isDataEdit} data-testid='box'>
        {!isDataEdit && (
          <BoxWrap>
            <EnrolText>
              {data.status} -{' '}
              <EnrollSpan>
                {data.duration === LONG ? '14wk Standard' : '7wk Intensive'}
              </EnrollSpan>
            </EnrolText>
            <EnrolText>
              {getDateStringWithYearLongMonth(
                changePTDateToLocalTime(data.dateStart), 'short'
              )
                ?.slice(0, 6)} -{' '}
              {getDateStringWithYearLongMonth(
                data.finalExamEndTime, 'short'
              )}
            </EnrolText>
            <EnrolText>10hr/wk Commitment</EnrolText>
          </BoxWrap>
        )}

        <BoxWrap className='flex-container' data-testid='buttons'>
          <Button
            onClick={() => {
              setIsEdit(!isEdit)
              setEditData(data.id)
            }}
          >
            {isDataEdit ? 'Cancel' : 'Edit'}
          </Button>
          <Button onClick={() => onRemove(data.id)}>Remove</Button>
        </BoxWrap>
      </Box>
      {isDataEdit ? (
        <>
          <EditCard
            data={data}
            setStartDate={setStartDate}
            startDate={startDate}
            courseStates={{ duration: week, type }}
            type={type}
            setType={setType}
            week={week}
            setWeek={setWeek}
            setIsEdit={setIsEdit}
            onUpdate={onUpdate}
            setCourseState={setCourseState}
          />
        </>
      ) : (
        <CartButton onClick={toggleSideBar}>View Cart</CartButton>
      )}
      {toggleSidebar && (
        <CartSideBarPanel
          handleClose={toggleSideBar}
          courses={cartItems}
          onRemove={onRemoveCart}
          onUpdate={onUpdate}
          updateContext={updateContext}
        />
      )}
    </CardWrap>
  )
}

export default Card
