import { ExitOptions, StudentStatus } from '../../../Constants/accountArea'
import { ExeptionRequestContainer } from './style'
import { getEmailSubject } from '../../../utilities/courseManagementUtils'
import React from 'react'

const ExceptionRequest = ({
  studentStatus,
  relationshipAdminEmail,
  courseName,
  isRegularPartnerUser,
  navigateToFormPage
}) => {
  const { REQUEST_EXCEPTION } = ExitOptions
  const { ENROLLED } = StudentStatus
  if (studentStatus === ENROLLED) {
    return (
      <ExeptionRequestContainer>
        <h3>Still need to exit?</h3>
        <p>
          <span>
            {isRegularPartnerUser
              ? 'Although all exit options have expired, you may be eligible for an exception due to circumstances outside of your control. Start your request by contacting your organization’s administrator with details about your situation.'
              : ` Although all exit options have expired, we occasionally grant exceptions for situations outside of your control.
                Tell us more about your situation to start the discussion with our team.
              `}
          </span>
          {isRegularPartnerUser ? (
            <a href={`mailto:${relationshipAdminEmail}?subject=${getEmailSubject(REQUEST_EXCEPTION, courseName)}`}>Email my organization </a>
          )
            : (
              <span id='link' onClick={() => navigateToFormPage(ExitOptions.REQUEST_EXCEPTION)}>Tell us more</span>
            )}
        </p>
      </ExeptionRequestContainer>
    )
  } else {
    return (
      <ExeptionRequestContainer>
        <div>
          <h3>All exit options have expired.</h3>
          <p>Don’t forget that you have lifetime access to nongraded course content.</p>
        </div>
      </ExeptionRequestContainer>
    )
  }
}
ExceptionRequest.displayName = 'ExceptionRequest'
export default ExceptionRequest
