import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import AppGuard from './Components/AppGuard/AppGuard'
import { Auth0Provider } from './Components/Auth0Provider/Auth0Provider'
import ContextProvider from './Components/ContextProvider/ContextProvider'
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

ReactDOM.render(
  <ErrorBoundary>
    <Auth0Provider>
      <ContextProvider>
        <AppGuard>
          <Router>
            <App />
          </Router>
        </AppGuard>
      </ContextProvider>
    </Auth0Provider>
  </ErrorBoundary>
  , document.getElementById('root'))

serviceWorkerRegistration.register()
