import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { PrimaryButton } from './QuestionComponent/style'
import {
  GlobalStyle,
  ExitModalHeader,
  Separator,
  ButtonsContainer
} from './style'
import utils from '../../utilities'

const AttemptFailedModal = ({ show, pretestLockoutDays }) => {
  const gotoDetailsPage = () => {
    window.location.hash = utils.getDetailsPagePath() + '/alternatives'
  }

  return (
    <Modal show={show} centered onHide={Function}>
      <GlobalStyle />
      <ExitModalHeader>
        <p>You didn't pass... this time.</p>
      </ExitModalHeader>
      <Separator />
      <Modal.Body>
        <p>
          You've made a valiant effort, but at this point, a passing grade isn't
          reachable this time around. Don't worry: We've got some great options to
          keep you learning. (And you can always study up try again in {pretestLockoutDays} days.)
        </p>
        <ButtonsContainer>
          <PrimaryButton
            data-testid='btn-see-options'
            onClick={gotoDetailsPage}
          >
            see options
          </PrimaryButton>
        </ButtonsContainer>
      </Modal.Body>
    </Modal>
  )
}

AttemptFailedModal.diaplayName = 'AttemptFailedModal'

export default AttemptFailedModal
