import React, { useContext, useEffect, useRef } from 'react'
import * as typeformEmbed from '@typeform/embed'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { AppContext } from '../../ContextProvider/ContextProvider'
import {
  BackButton,
  FormWrapper,
  PageContent, PageTitle, PageWrapper, Separator
} from './style/cancellation'
import { getInStrideCancellationFormUrl } from '../../../utilities/accountArea'

function CancellationPage () {
  const formRef = useRef(null)
  const history = useHistory()
  const {
    updateContext, studentData = {}
  } = useContext(AppContext)
  const { id: studentId, instrideId } = studentData

  useEffect(() => {
    if (!formRef.current) return

    const embedForm = () => {
      const formUrl = getInStrideCancellationFormUrl({ studentId, instrideId })
      const transferableUrlParameters = ['student_id', 'instride_id']
      const onFormSubmit = () => {
        updateContext({
          studentData: { ...studentData, instrideSubscriptionStatus: 'requested' }
        })
        setTimeout(viewSubscriptionPage, 5000)
      }

      typeformEmbed.makeWidget(
        formRef.current,
        formUrl,
        {
          onSubmit: onFormSubmit,
          transferableUrlParameters
        }
      )
    }

    embedForm()
  // eslint-disable-next-line
  }, [formRef])

  const viewSubscriptionPage = () => {
    history.push('/account/subscription')
  }

  return (
    <PageWrapper>
      <BackButton
        title='back to account'
        clickHandler={viewSubscriptionPage}
      />

      <PageContent>
        <PageTitle>
          Subscription cancellation form
        </PageTitle>
        <Separator />
        <p>
          Below is the request form you must complete to cancel your
          1-year Outlier subscription.
        </p>

        <FormWrapper ref={formRef} />
      </PageContent>
    </PageWrapper>
  )
}

export default CancellationPage
