import React from 'react'
import {
  CourseDetails,
  CourseIcon,
  CourseTitle,
  MobileGradient,
  MobileSlideContainer,
  RecommnededCourseTag,
  SlidePaginationWrapper,
  SmallHeroContainer,
  WelcomeMessage
} from './style'
import { getCourseAssets } from '../../../utilities/course'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import WhatsNext from '../WhatsNext/WhatsNext'
import { CourseCTAContainer, PrimaryButton } from '../style'
import { getRecommendedCourseLink } from '../utils'

function SmallHeroSlide ({
  coursesToDisplay,
  catalogCourses,
  randomWelcomeNote,
  studentName,
  showRecommendedCourses
}) {
  return (
    <>
      <MobileGradient />
      <SmallHeroContainer data-testid='small-hero-slide'>
        <WelcomeMessage>
          {randomWelcomeNote},&nbsp;
          <span className='fs-exclude'>{studentName}</span>!
        </WelcomeMessage>
        <Splide
          aria-label='For-Credit Courses Slide'
          hasTrack={false}
          options={{
            type: 'slide',
            rewind: true,
            arrows: false,
            padding: 'var(--mobile-layout-padding)',
            gap: '12px'
          }}
        >
          <SplideTrack>
            {coursesToDisplay.map((course) => {
              const { id, displayName } = course
              const { courseImage, courseIcon } = getCourseAssets(
                catalogCourses,
                id
              )

              return (
                <SplideSlide key={id}>
                  <MobileSlideContainer courseImage={courseImage}>
                    {showRecommendedCourses && (
                      <RecommnededCourseTag data-testid='recommended-course-tag'>
                        Recommended
                      </RecommnededCourseTag>
                    )}
                    <CourseIcon courseIcon={courseIcon} />
                    <CourseDetails>
                      <CourseTitle>{displayName}</CourseTitle>
                      {showRecommendedCourses ? (
                        <>
                          <CourseCTAContainer>
                            <PrimaryButton
                              onClick={() => {
                                const recommendedCourseLink = getRecommendedCourseLink(
                                  course
                                )

                                window.location.href = recommendedCourseLink
                              }}
                              data-testid='primary-button'
                            >
                              Learn More
                            </PrimaryButton>
                          </CourseCTAContainer>
                        </>
                      ) : (
                        <WhatsNext course={course} />
                      )}
                    </CourseDetails>
                  </MobileSlideContainer>
                </SplideSlide>
              )
            })}
          </SplideTrack>
          <SlidePaginationWrapper className='splide__pagination' />
        </Splide>
      </SmallHeroContainer>
    </>
  )
}

SmallHeroSlide.displayName = 'SmallHeroSlide'
export default SmallHeroSlide
