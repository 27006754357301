import React, { useMemo, useState } from 'react'
import CourseCard from './CourseCard'
import CourseGroups from './CourseGroups'
import PropTypes from 'prop-types'
import { getCatalogCourses } from '../../utilities/course'
import { StyledRow } from './style'

const Courses = ({
  isGGUStudentView,
  list,
  courses,
  outlierCourses,
  setCurrentPage,
  currentCourseNumber,
  courseQuantity,
  history
}) => {
  const [activeCard, setActiveCard] = useState(null)

  const filteredCourses = useMemo(() => {
    return getCatalogCourses({
      catalogCourses: list,
      studentCourses: courses,
      outlierCourses
    })
  }, [list, courses, outlierCourses])

  if (isGGUStudentView) {
    return (
      <CourseGroups
        setCurrentPage={setCurrentPage}
        currentCourseNumber={currentCourseNumber}
        courseQuantity={courseQuantity}
      />
    )
  }

  return (
    <StyledRow data-testid='courses-row'>
      {filteredCourses.map((course) => (
        <CourseCard
          course={course}
          activeCard={activeCard}
          key={course.id}
          history={history}
          setActiveCard={setActiveCard}
        />
      ))}
    </StyledRow>
  )
}

CourseCard.propTypes = {
  list: PropTypes.array
}

Courses.displayName = 'Courses'
export default Courses
