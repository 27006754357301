import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import useSkipFirstEffect from '../../hooks/useSkipFirstEffect'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import { COURSE_REGISTRATION_STARTED } from '../../Constants/eventType'
import {
  CHANGE_COURSE_QUANTITY_PATH,
  COURSE_QUANTITY_PATH,
  COURSE_QUANTITY_WARNING_PATH,
  EXPECTED_GRADUATION_PATH,
  GET_READY_PATH,
  GGU_V2_ENROLLMENT_PAGES,
  TRANSFER_CREDIT_CONFIRMATION_PATH,
  RECOMMENDED_COURSES_PATH,
  WELCOME_PAGE_PATH,
  COURSE_INTRO_PATH,
  CATALOG_PATH,
  CATALOG_COURSE_DETAILS_PATH,
  REVIEW_PATH,
  SCHEDULE_MEETING_PATH
} from '../../Constants/gguEnrollmentUrls'
import { AppContext } from '../ContextProvider/ContextProvider'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import ExpectedGraduation from './ExpectedGraduation'
import { BackButton, Container, GlobalStyle } from './style'
import WelcomePage from './WelcomePage'
import {
  getCurrentProspect,
  getRecommendedCourses,
  getNextRegistrationPage,
  setVisitedPages,
  removeRecommendedCoursesInLS,
  setRecommendedCoursesInLS,
  getRecommendedCoursesFromLS,
  isSegmentEventInLS,
  setFiredSegmentEventsInLS
} from '../../utilities/gguDegreeUtils'
import TransferCreditConfirmation from './TransferCreditConfirmation'
import GetReady from './GetReady'
import CourseQuantity from './CourseQuantity'
import ChangeCourseQuantity from './ChangeCourseQuantity'
import CourseQuantityWarning from './CourseQuantityWarning'
import RecommendedCourses from './RecommendedCourses'
import {
  COURSE_QUANTITY_LS_KEY,
  FULL_TIME_SLOT,
  PART_TIME_SLOT,
  SELECTED_COURSE_LS_KEY,
  STUDY_MODE
} from '../../Constants/gguDegrees'
import CourseIntro from './CourseIntro'
import CourseCatalog from '../CourseCatalog/CourseCatalog'
import CatalogDetailPage from '../CatalogDetailPage/CatalogDetailPage'
import ConfirmChangeModal from './ConfirmChangeModal'
import { DEGREE_REGISTRATION_INCOMPLETE } from '../../Constants'
import api from '../../api'
import Review from './Review'
import ScheduleAdvisorMeeting from './ScheduleAdvisorMeeting'

const GGUEnrollmentFlow = () => {
  const { sendEvent } = useSegmentEvents()
  const history = useHistory()
  const { pathname } = useLocation() || {}
  const [showConfirmChangeModal, setShowConfirmChangeModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [recommendedCourses, setRecommendedCourses] = useState([])
  const nextPage = getNextRegistrationPage()
  const [currentPage, setCurrentPage] = useState(
    nextPage || GGU_V2_ENROLLMENT_PAGES.WELCOME_PAGE
  )
  const [currentCourseNumber, setCurrentCourseNumber] = useState(0)
  const {
    prospects,
    currentSemester,
    isContextLoading,
    isGGUDegreeDataLoading,
    outlierCourses,
    catalogCourses,
    degreeData,
    studentAttempts,
    updateContext,
    studentData
  } = useContext(AppContext)
  const { previousPagePathname, goBackClicked } = currentPage || {}

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_STARTED,
    pageNumber: '1-1'
  }

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    sendEvent({ eventName: COURSE_REGISTRATION_STARTED })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (typeof studentData?.[DEGREE_REGISTRATION_INCOMPLETE] === 'boolean') return

    updateContext({
      studentData: {
        ...studentData,
        [DEGREE_REGISTRATION_INCOMPLETE]: true
      }
    })
    api.setStudentData(
      DEGREE_REGISTRATION_INCOMPLETE,
      { [DEGREE_REGISTRATION_INCOMPLETE]: true }
    )
  }, [studentData, updateContext])

  const currentProspect = useMemo(() => {
    return getCurrentProspect(prospects)
    // eslint-disable-next-line
  }, [prospects])

  const [courseQuantity, setCourseQuantity] = useState(
    !currentProspect?.studyMode || currentProspect?.studyMode === STUDY_MODE.FULL_TIME
      ? FULL_TIME_SLOT : Number(localStorage.getItem(COURSE_QUANTITY_LS_KEY) || PART_TIME_SLOT)
  )

  useEffect(() => {
    showConfirmChangeModal && setShowConfirmChangeModal(false)
    // eslint-disable-next-line
  }, [pathname])

  const refreshRecommendedCourses = () => {
    removeRecommendedCoursesInLS(true)

    const recommendedCourses = getRecommendedCourses({
      outlierCourses,
      catalogCourses,
      degreeData,
      studentAttempts,
      prospects,
      noOfSlots: courseQuantity
    })

    setRecommendedCourses(recommendedCourses)
    setRecommendedCoursesInLS(
      recommendedCourses.map(course => course.courseId)
    )
  }

  useEffect(() => {
    localStorage.removeItem('enrollmentConfirmed')
    const recommendedCoursesOnLS = getRecommendedCoursesFromLS()
    if (!recommendedCoursesOnLS?.length) {
      refreshRecommendedCourses()
      return
    }

    const recommendedCoursesIds = recommendedCoursesOnLS
      .map((course) => course.courseId)

    const recommendedCourses = recommendedCoursesIds?.map(courseId => {
      return degreeData?.find(course => course.courseId === courseId)
    }) || []

    return setRecommendedCourses(recommendedCourses)
    // eslint-disable-next-line
  }, [])

  useSkipFirstEffect(() => {
    refreshRecommendedCourses()
    // eslint-disable-next-line
  }, [courseQuantity])

  const { courseId: currentCourseId } =
        recommendedCourses?.[currentCourseNumber - 1] || {}

  useEffect(() => {
    let { pathName, previousPagePathname } = currentPage || {}
    const state = {}
    let search = ''
    if (!pathName) return

    if (pathName === COURSE_INTRO_PATH) {
      pathName = pathName.replace(':courseNumber', `${currentCourseNumber}`)
    }

    if (pathName === CATALOG_PATH) {
      search = `?replace=${currentCourseId}`
    }

    if (pathName === CATALOG_COURSE_DETAILS_PATH) {
      if (previousPagePathname === COURSE_INTRO_PATH) {
        pathName = pathName.replace(':courseId', `${currentCourseId}`)
      } else {
        const selectedCourseId = localStorage.getItem(SELECTED_COURSE_LS_KEY)
        if (!selectedCourseId) return
        pathName = pathName.replace(':courseId', `${selectedCourseId}`)
        search = `?replace=${currentCourseId}`
      }
    }

    showConfirmChangeModal && setShowConfirmChangeModal(false)
    history.push({
      pathname: pathName,
      search,
      state: { overrideVisitedPage: true, ...state }
    })

    if (currentPage?.skipVisitedPages) return
    setVisitedPages(pathName)
    // eslint-disable-next-line
  }, [currentPage?.pathName, currentCourseNumber])

  return (
    <Container className='page-container'>
      <GlobalStyle />
      {isContextLoading || isGGUDegreeDataLoading || isLoading
        ? <LoadingSpinner /> : (
          <>
            {previousPagePathname ? (
              <BackButton
                title='Go Back'
                clickHandler={() => {
                  const previousPage = Object.values(GGU_V2_ENROLLMENT_PAGES)
                    .find(page => {
                      return page.pathName === previousPagePathname
                    })
                  setCurrentPage({ ...previousPage, goBackClicked: true })
                }}
              />
            ) : null}
            <Switch>
              <Route
                exact
                path={WELCOME_PAGE_PATH}
                component={() => (
                  <WelcomePage
                    name={`${studentData?.firstName} ${studentData?.lastName}`}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              />
              <Route
                exact
                path={EXPECTED_GRADUATION_PATH}
                component={() => (
                  <ExpectedGraduation
                    degree={currentProspect?.degree}
                    semesterStart={currentSemester?.semesterStart}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              />
              <Route
                exact
                path={TRANSFER_CREDIT_CONFIRMATION_PATH}
                component={() => (
                  <TransferCreditConfirmation
                    currentProspect={currentProspect}
                    goBackClicked={goBackClicked}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              />
              <Route
                exact
                path={GET_READY_PATH}
                component={() => (<GetReady setCurrentPage={setCurrentPage} />)}
              />
              <Route
                exact
                path={COURSE_QUANTITY_PATH}
                component={() => (
                  <CourseQuantity
                    setIsLoading={setIsLoading}
                    courseQuantity={courseQuantity}
                    currentProspect={currentProspect}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              />
              <Route
                exact
                path={CHANGE_COURSE_QUANTITY_PATH}
                component={() => (
                  <ChangeCourseQuantity
                    courseQuantity={courseQuantity}
                    setCourseQuantity={setCourseQuantity}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              />
              <Route
                exact
                path={COURSE_QUANTITY_WARNING_PATH}
                component={() => (
                  <CourseQuantityWarning
                    setIsLoading={setIsLoading}
                    currentProspect={currentProspect}
                    courseQuantity={courseQuantity}
                    setCourseQuantity={setCourseQuantity}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              />
              <Route
                exact
                path={RECOMMENDED_COURSES_PATH}
                component={() => (
                  <RecommendedCourses
                    recommendedCourses={recommendedCourses}
                    setCurrentPage={setCurrentPage}
                    setCurrentCourseNumber={setCurrentCourseNumber}
                    currentProspect={currentProspect}
                  />
                )}
              />
              <Route
                exact
                path={COURSE_INTRO_PATH}
                component={() => (
                  <CourseIntro
                    recommendedCourses={recommendedCourses}
                    courseQuantity={courseQuantity}
                    currentCourseNumber={currentCourseNumber}
                    setCurrentCourseNumber={setCurrentCourseNumber}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              />
              <Route
                exact
                path={CATALOG_PATH}
                component={() => (
                  <CourseCatalog
                    setCurrentPage={setCurrentPage}
                    currentCourseNumber={currentCourseNumber}
                    courseQuantity={courseQuantity}
                  />)}
              />
              <Route
                exact
                path={CATALOG_COURSE_DETAILS_PATH}
                component={() => (
                  <CatalogDetailPage
                    setCurrentPage={setCurrentPage}
                    setShowConfirmChangeModal={setShowConfirmChangeModal}
                    currentCourseNumber={currentCourseNumber}
                    courseQuantity={courseQuantity}
                  />
                )}
              />
              <Route
                exact
                path={REVIEW_PATH}
                component={() => (
                  <Review
                    setCurrentPage={setCurrentPage}
                    recommendedCourses={recommendedCourses}
                    setIsLoading={setIsLoading}
                    currentCourseNumber={currentCourseNumber}
                    courseQuantity={courseQuantity}
                  />
                )}
              />
              <Route
                exact
                path={SCHEDULE_MEETING_PATH}
                component={() => (
                  <ScheduleAdvisorMeeting
                    recommendedCourses={recommendedCourses}
                    setIsLoading={setIsLoading}
                  />
                )}
              />
            </Switch>
          </>)}
      {showConfirmChangeModal && (
        <ConfirmChangeModal
          setShowConfirmChangeModal={setShowConfirmChangeModal}
          originalCourseId={currentCourseId}
          newCourseId={pathname?.split('/')?.[2]}
          courseQuantity={courseQuantity}
          currentCourseNumber={currentCourseNumber}
          setCurrentCourseNumber={setCurrentCourseNumber}
          setCurrentPage={setCurrentPage}
          setRecommendedCourses={setRecommendedCourses}
        />
      )}
    </Container>
  )
}

GGUEnrollmentFlow.displayName = 'GGUEnrollmentFlowV2'

export default GGUEnrollmentFlow
