import React, { useContext, useEffect, useMemo } from 'react'
import qs from 'qs'
import { ContentContainer, ContentContainerCustom } from './style'
import { useLocation } from 'react-router-dom'
import { FULL_WIDTH_PATHS } from '../../Constants'
import config from '../../config'
import GGUEnrollmentFlow from '../GGUEnrollmentFlow/GGUEnrollmentFlow'
import GGUEnrollmentFlowV2 from '../GGUEnrollmentFlowV2/GGUEnrollmentFlow'
import { AppContext } from '../ContextProvider/ContextProvider'
import StudyBlocksNotifications from '../AccountArea/StudyBlocks/Notifications'
import { GGU_ENROLLMENT_URLS, GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import StudyBlocksPopups from '../AccountArea/StudyBlocks/Popups'

const Layout = ({ header, content, footer }) => {
  const { pathname } = useLocation()
  const params = qs.parse(window.location.search?.slice(1))
  const isRegistrationRedesign = params?.gguEnrollmentV2 === 'true'
  const {
    showGGUEnrollment,
    quitGGUEnrolment,
    isGGUStudentEnrolled,
    isGGUDegreeDataLoading,
    updateContext,
    isContextLoading
  } = useContext(AppContext)

  const renderContainer = (ContainerComponent, props) => {
    return (
      <ContainerComponent
        {...props}
        id='content'
        hasPracExerciseFlag={config.hasPracExerciseFlag}
      >
        {content}
      </ContainerComponent>
    )
  }

  const isContactPath = pathname === '/contact'
  const isGGUEnrollmentPage = isRegistrationRedesign
    ? Object.values(GGU_V2_ENROLLMENT_PAGES).find(page => {
      const { pathName } = page
      return pathName === pathname ||
        pathname.startsWith('/course-intro/') ||
        pathname.startsWith('/catalog/')
    })
    : GGU_ENROLLMENT_URLS.includes(pathname)
  const useCustomContainer = FULL_WIDTH_PATHS.some(fullWidthPath => {
    return pathname.startsWith(fullWidthPath)
  })
  const container =
    useCustomContainer || (config.hasDashboardRedesignFlag && pathname === '/')
      ? ContentContainerCustom
      : ContentContainer

  const isDataLoading = useMemo(() => {
    return isGGUDegreeDataLoading || isContextLoading
  }, [isGGUDegreeDataLoading, isContextLoading])

  useEffect(() => {
    if (!showGGUEnrollment) return
    updateContext({ quitGGUEnrolment: false })
    // eslint-disable-next-line
  }, [showGGUEnrollment])

  return (
    <>
      <StudyBlocksNotifications />

      <header data-testid='header'>{header}</header>
      {(showGGUEnrollment && !quitGGUEnrolment && isGGUEnrollmentPage &&
        !isGGUStudentEnrolled && !isDataLoading
      )
        ? isRegistrationRedesign
          ? <GGUEnrollmentFlowV2 /> : <GGUEnrollmentFlow />
        : (
          <>
            <section data-testid='section'>
              {renderContainer(container, isContactPath && { fluid: 'md' })}
            </section>
            {footer}
          </>)}
      {config.hasDashboardRedesignFlag && <StudyBlocksPopups />}
    </>
  )
}

export default Layout
