import React, { useEffect, useState } from 'react'
import config from '../../config'
import utilities from '../../utilities'
import {
  Close,
  ModalBody,
  ModalWrapper,
  ModalHeader,
  KalturaVideoPlayer
} from './style'

const TrailerModal = ({ setShowTrailerModal, kalturaEmbedCode }) => {
  const {
    configureKalturaPlayer,
    getKalturaSettings: { PARTNER_ID, UICONF_ID, scriptUrl }
  } = config
  const [kalturaScriptLoaded, setKalturaScriptLoaded] = useState(false)

  const kalturaLoadHandler = () => {
    setKalturaScriptLoaded(true)
  }

  useEffect(() => {
    if (!kalturaEmbedCode) return

    const kalturaScriptIsLoaded = document.querySelector('#Kaltura-Script')
    if (!kalturaScriptIsLoaded) {
      utilities.appendKalturaScriptToBody(scriptUrl, kalturaLoadHandler)
      return
    }

    if (!kalturaScriptLoaded) return

    configureKalturaPlayer(kalturaEmbedCode, PARTNER_ID, UICONF_ID)
    // eslint-disable-next-line
  }, [kalturaEmbedCode, kalturaScriptLoaded])

  return (
    <ModalWrapper>
      <ModalHeader>
        <Close onClick={() => { setShowTrailerModal(false) }} data-testid='close-icon' />
      </ModalHeader>
      <ModalBody bgColor='#000'>
        <KalturaVideoPlayer
          data-testid='kaltura_video_player'
          id='kaltura_video_player'
          onClick={() => {
            setShowTrailerModal(true)
          }}
        />
      </ModalBody>
    </ModalWrapper>
  )
}

TrailerModal.displayName = 'TrailerModal'

export default TrailerModal
