import axios from 'axios'
import { notificationsData } from './Components/AppHeader/notificationMockData'
import config from './config'
import { VOUCHER_TYPE } from './Constants/voucher'
import { getApi, postApi, putApi } from './utilities/axios'
import {
  addExamNumbersToCohortSyllabus,
  addExamNumbersToCourseData,
  filterOutAdditionalOrientationChapters,
  isInProgressBeforeCutOffDate,
  outlierCourses
} from './utilities/course'
import { canOverrideStudentEmail, isUserAuditor } from './utilities/user'
import {
  removeQuizzesFromChapters,
  removeQuizzesFromSection
} from './utilities/chapterUtils'
import utils from './utilities'
import { getUserTimeZone } from './utilities/dateTime'

let overrideStudentEmail = false
let studentEmailQueryParam = {}
const { studentEmailOverride } = config

export default {
  transferCohort,
  getShopifyVariants,
  incrementStudyGuideClicks,
  refundTokens,
  getAllTokens,
  getCatalogCourses,
  deleteStudentData,
  requestGuardianPermission,
  updateStudent,
  resendEmail,
  logError,
  getStudentCourses,
  getCohortOriginalDeadlines,
  changePassword,
  getCoursesDetails,
  getStudentProgress,
  getAllSectionsData,
  getChecklistSectionsData,
  getCourseData,
  getProctorioUrls,
  loadSectionData,
  getSyllabusDetails,
  getStudentId,
  getAllNotifications,
  sendContactEmail,
  verifyPassword,
  verifyEmailAndSendCode,
  updateNewEmailInATAndAC,
  getProjectedGrade,
  submitTrackedEvent,
  newSubmitTrackedEvent,
  getTypeFormResponses,
  setStudentData,
  getActiveCourses,
  getStudentData,
  getProspectsData,
  getGGUAdvisorApprovalRequired,
  getGGUdegreeRec,
  getAllGGUSemesters,
  enrollGGUStudents,
  uploadTuitionVoucher,
  getAmazonVouchers,
  assignTokens,
  savePretestAttempt,
  getPretestAttempts,
  getOutlierCourses,
  getExamRetakes,
  getStudentAttempts,
  getStudentCertificates,
  getAmazonRefundStatus,
  updateAuth0User,
  enrollToCollegeSuccessCourse,
  updateProspectData,
  setCourseFeatures,
  getCourseFeatures,
  getStudentCurrentGrade,
  saveStudyBlocks,
  updateStudyBlocks,
  getStudyBlocks,
  createStudyBlocksCalendar,
  getStudyBlocksPopupsState
}

const apiResponseCache = {}

async function refundTokens (tokens) {
  if (overrideStudentEmail) return { status: 200, data: 'true' }

  try {
    const url = '/token/refund'
    const { data } = await postApi(url, {}, { tokens })
    return data
  } catch (e) {
    console.error('Error in refundTokens', e.message)
    return { success: false, message: e.message }
  }
}

async function getAllTokens ({ activityLog }) {
  try {
    const { data } = await getApi(`/student/tokens${activityLog ? '?activity=true' : ''}`)
    return data
  } catch (e) {
    console.error('Error when fetching tokens', e.message)
    const { response: { data } = {} } = e
    return data
  }
}

async function getCatalogCourses () {
  try {
    const { data } = await getApi(
      'dato/catalog', {}, {}
    )
    return data
  } catch (e) {
    console.error('Error when fetching catalog courses', e.message)
    const { response: { data } = {} } = e
    return data
  }
}

async function logError (data) {
  try {
    const res = await postApi('/monitor/log-frontend-error', {}, data)
    return res
  } catch (error) {
    console.error('Error when sending error logs: ', error.message)
    return null
  }
}

async function getStudentCourses (addAccessParam) {
  overrideStudentEmail = await canOverrideStudentEmail()
  studentEmailQueryParam = overrideStudentEmail
    ? { studentEmail: studentEmailOverride }
    : {}
  const accessParam = addAccessParam || config.getUserAssignedCourses()
    ? { access: true } : {}

  try {
    const { data } = await getApi(
      '/student/courses',
      {},
      { ...studentEmailQueryParam, ...accessParam }
    )
    return data
  } catch (error) {
    console.error('Error when fetching studentCourses error logs: ', error.message)
    return null
  }
}

async function getCohortOriginalDeadlines () {
  try {
    const { data } = await getApi('/student/cohort-deadlines')
    return data
  } catch (error) {
    console.error('Error when fetching active courses: ', error.message)
  }
}

async function getActiveCourses () {
  // api fetches from Shopify which does not have a development password token
  if (process.env.NODE_ENV === 'development') return outlierCourses
  const url = '/active-courses'
  if (apiResponseCache[url]) {
    return apiResponseCache[url]
  }

  try {
    const { data } = await getApi(url, {})
    apiResponseCache[url] = data
    return data
  } catch (error) {
    console.error('Error when fetching active courses: ', error.message)
    return {}
  }
}

async function getTypeFormResponses (formId, responseId) {
  const url = `https://api.outlier.org/typeform/${formId}/${responseId}`

  try {
    const { data } = await getApi(url)
    return data
  } catch (error) {
    return {}
  }
}

async function getAllNotifications () {
  try {
    // const { data } = await getApi('/student/notifications', {}, queryParams)
    const { data } = notificationsData
    return data
  } catch (error) {
    console.error('Notifications error', error.message)
  }
}

async function getCoursesDetails (coursesId) {
  const requests = []
  coursesId.forEach((courseId) => {
    const url = getUrl(courseId)
    requests.push(getApi(url))
  })
  const responses = await axios.all(requests)
  const coursesData = new Map()
  responses.forEach((response) => {
    const { data: { course_uuid: courseUUID }, data } = response
    if (!courseUUID) return

    const courseDataWithExamNumbers = addExamNumbersToCourseData(data)
    coursesData.set(
      courseUUID,
      filterOutAdditionalOrientationChapters(courseDataWithExamNumbers)
    )
  })
  return coursesData
}

async function getStudentProgress (courseId) {
  try {
    const suffix = overrideStudentEmail ? encodeURIComponent(studentEmailOverride) : ''
    const { data } = await getApi(`/student/progress/${courseId}/${suffix}`)
    return data
  } catch (e) {
    console.info(e)
  }
}

async function getAllSectionsData (activeCourseUUID) {
  const url = `/course-data/sections/${activeCourseUUID}`
  try {
    const { data } = await getApi(url)
    if (data?.error) throw new Error(data.error)
    return data
  } catch (error) {
    console.error('Error in getAllSectionsData', error.message)
    return null
  }
}

async function getChecklistSectionsData (activeCourseUUID, latestCohort, courseName) {
  const url = `/checklist/${activeCourseUUID}`
  try {
    const { data } = await getApi(url)
    if (data?.error) throw new Error(data.error)

    const isAuditor = isUserAuditor(latestCohort) ||
      latestCohort?.name.toUpperCase().includes('AUDIT')
    const sectionsData = data
    if (isAuditor) {
      const { dateStart, finalExamEndTime, cohortEndTime, duration } = latestCohort || {}
      const isCohortInProgress = isInProgressBeforeCutOffDate({
        dateStart, finalExamEndTime, cohortEndTime, duration, courseName
      })
      if (!isCohortInProgress) {
        sectionsData.forEach((sectionData, index) => {
          sectionsData[index].sections = sectionData.sections.map(section => {
            delete section.quiz
            return section
          }) || []
        })
      }
    }
    return sectionsData
  } catch (error) {
    console.error('Error in getAllSectionsData', error.message)
    return null
  }
}

async function getCourseData (courseUuid, latestCohort, courseName) {
  const url = getUrl(courseUuid)
  try {
    const { data } = await getApi(url)
    if (data?.error) throw new Error(data.error)

    const courseDataWithExamNumbers = addExamNumbersToCourseData(data)
    const isAuditor = isUserAuditor(latestCohort) ||
      latestCohort?.name.toUpperCase().includes('AUDIT')
    let courseData = courseDataWithExamNumbers
    if (isAuditor) {
      const { dateStart, finalExamEndTime, cohortEndTime, duration } = latestCohort || {}
      const isCohortInProgress = isInProgressBeforeCutOffDate({
        dateStart, finalExamEndTime, cohortEndTime, duration, courseName
      })
      if (!isCohortInProgress) {
        courseData = removeQuizzesFromChapters(courseDataWithExamNumbers)
      }
    }
    return filterOutAdditionalOrientationChapters(courseData)
  } catch (error) {
    console.error('Error in getCourseData', error.message)
    return null
  }
}

async function loadSectionData ({
  activeCourseUUID, uuid, latestCohort, courseName
}) {
  try {
    const url = getUrl(activeCourseUUID, uuid)
    const { data } = await getApi(url)
    let sectionData = data

    const isAuditor = isUserAuditor(latestCohort) ||
      latestCohort?.name.toUpperCase().includes('AUDIT')
    if (isAuditor) {
      const { dateStart, finalExamEndTime, cohortEndTime, duration } = latestCohort || {}
      const isCohortInProgress = isInProgressBeforeCutOffDate({
        dateStart, finalExamEndTime, cohortEndTime, duration, courseName
      })
      sectionData = isCohortInProgress
        ? data
        : removeQuizzesFromSection(data)
    }

    return sectionData
  } catch (e) {
    console.info(e)
  }
}

async function getSyllabusDetails (cohortID, courseId) {
  const url = `/cohort-syllabus/${cohortID}`
  try {
    const { data } = await getApi(url)
    if (data?.error) throw new Error(data.error)

    return addExamNumbersToCohortSyllabus(data, courseId)
  } catch (error) {
    console.error('Error in getSyllabusDetails: ', error.message)
    return null
  }
}

async function changePassword (email) {
  if (overrideStudentEmail) return { overrideStudentEmail }

  const { REACT_APP_CLIENT_ID } = process.env

  try {
    const url = `${config.auth0BaseURL}/dbconnections/change_password`
    const response = await axios.post(url, {
      client_id: REACT_APP_CLIENT_ID,
      email,
      connection: 'Username-Password-Authentication'
    })
    return {
      title: 'Email Sent',
      titleIcon: 'images/icons/icon-alert-check.svg#icon-alert-check',
      text: response.data,
      buttonText: 'Great'
    }
  } catch (e) {
    return {
      title: 'Oops! Something\'s wong.',
      titleIcon: 'images/icons/icon-alert-exclamation.svg#icon-alert-exclamation',
      text: 'We were unable to send you an email. Please try again',
      buttonText: 'Ok'
    }
  }
}

async function getStudentData () {
  overrideStudentEmail = await canOverrideStudentEmail()
  studentEmailQueryParam = overrideStudentEmail
    ? { studentEmail: studentEmailOverride }
    : {}

  try {
    const { data } = await getApi('/student/student-data', {}, { ...studentEmailQueryParam })
    return data
  } catch (e) {
    console.error('Error in getStudentData', e.message)
    return {}
  }
}

async function getProspectsData () {
  try {
    const { data } = await getApi('/prospects/prospect-data')
    return data
  } catch (e) {
    console.error('Error in getProspectsData', e.message)
    return {}
  }
}

async function getGGUAdvisorApprovalRequired () {
  try {
    const { data } = await getApi('/ggu/advisor-approval-required')
    return data
  } catch (e) {
    console.error('Error in getGGUAdvisorApprovalRequired', e.message)
    return {}
  }
}

async function getGGUdegreeRec (degreeRec) {
  try {
    const { data } = await getApi(`/ggu/${degreeRec}`)
    return data
  } catch (e) {
    console.error('Error in getGGUdegreeRec', e.message)
    return {}
  }
}

async function getAllGGUSemesters () {
  try {
    const { data } = await getApi('/ggu/semesters')
    if (data?.error) throw new Error(data.error)
    return data
  } catch (e) {
    console.error('Error in getAllGGUSemesters', e.message)
    return null
  }
}

async function enrollGGUStudents (prospectId, cohortIds) {
  try {
    const { data } = await postApi('/ggu/enroll-students', {}, { prospectId, cohortIds })
    return data
  } catch (e) {
    console.error('Error in enrollGGUStudents', e.message)
    return {}
  }
}

async function updateProspectData (prospectId, prospectData) {
  try {
    const { data } = await putApi(`/prospects/prospect-data/${prospectId}`, {}, prospectData)
    return data
  } catch (e) {
    console.error('Error in updateProspectData', e.message)
    return {}
  }
}

async function setStudentData (key, sectionData) {
  if (overrideStudentEmail) return { status: 200, data: 'true' }

  try {
    const url = `/student/data/${key}`
    const { data } = await putApi(url, {}, sectionData)
    return data
  } catch (e) {
    console.error('Error in setStudentData', e.message)
    return { success: false, message: e.message }
  }
}

async function getStudentId () {
  const { data: { studentId } } = await getApi('/student/student-id')
  return studentId
}

async function getOutlierCourses () {
  try {
    const { data } = await getApi('/courses')
    return data
  } catch (e) {
    console.error('Error fetching Outlier courses')
    return []
  }
}

async function getExamRetakes () {
  try {
    const { data } = await getApi('/student/exam-retakes')
    return data
  } catch (error) {
    console.error('Error fetching exam retakes', error.message)
    return null
  }
}

async function getStudentAttempts () {
  try {
    const { data } = await getApi('/student/attempts')
    return data
  } catch (e) {
    console.error('Error fetching student attempts ', e.message)
    return []
  }
}

async function getStudentCertificates () {
  try {
    const { data } = await getApi('/student/certificates')
    return data
  } catch (e) {
    console.error('Error fetching student certificates', e.message)
    return []
  }
}

async function sendContactEmail (studentId, message) {
  if (overrideStudentEmail) return

  const { data } = await postApi('/send-contact-email', {},
    { studentId, message }
  )
  return data
}

async function verifyPassword (email, password) {
  if (overrideStudentEmail) return { overrideStudentEmail }

  const { REACT_APP_CLIENT_ID, REACT_APP_DOMAIN } = process.env

  try {
    const resourceOwnerPasswordGrantApi =
      `https://${REACT_APP_DOMAIN}/oauth/token`
    const response = await axios.post(resourceOwnerPasswordGrantApi, {
      grant_type: 'password',
      client_id: REACT_APP_CLIENT_ID,
      username: email,
      password: password,
      scope: 'openid profile email'
    })
    return response && response.data
  } catch (e) {
    console.error('Error when fetching password: ', e.message)
    return { error: e.message }
  }
}

async function verifyEmailAndSendCode ({ newEmail, code, type }) {
  if (overrideStudentEmail) return { overrideStudentEmail }

  try {
    const { data } = await postApi('/verify-account', {}, { newEmail, code, type })
    return data
  } catch (e) {
    console.error('Error when verifying email', e.message)
    const { response: { data } = {} } = e
    return data
  }
}

async function updateNewEmailInATAndAC (newEmail) {
  if (overrideStudentEmail) return { overrideStudentEmail }

  try {
    const { data } = await postApi('/change-user-email', {}, { newEmail })
    return data
  } catch (e) {
    console.error('Error when updating email in AT and AC', e.message)
    return { success: false, message: e.message }
  }
}

async function resendEmail () {
  if (overrideStudentEmail) return { overrideStudentEmail }

  try {
    const { data } = await getApi('/email-verification')
    return data
  } catch (e) {
    console.error('Error when resending email', e.message)
    const { response: { data } = {} } = e
    return data
  }
}

async function requestGuardianPermission (guardianEmail) {
  if (overrideStudentEmail) return { overrideStudentEmail }

  console.info('API start requestGuardianPermission')
  try {
    const { data } = await postApi(
      `/student/guardian-permission/${guardianEmail}`,
      {},
      { courseURL: window.location.origin }
    )
    console.info('API done requestGuardianPermission')

    return data
  } catch (err) {
    console.error(`API error in requestGuardianPermission: ${err.message}`)
    return { message: err.message }
  }
}

async function deleteStudentData () {
  if (overrideStudentEmail) return { overrideStudentEmail }

  try {
    const url = '/student/remove-under13'
    const result = await putApi(url, {}, {})
    return result
  } catch (e) {
    console.error('Error in updateStudent', e.message)
    return { success: false, error: e.message }
  }
}

async function updateStudent (studentData, isOnboarding = false) {
  if (overrideStudentEmail) return { overrideStudentEmail }

  try {
    const url = `/student/update${isOnboarding ? '?isOnboarding=true' : ''}`
    const result = await putApi(url, {}, studentData)
    return result
  } catch (e) {
    const {
      response: { data: { message: errorMessage } = {} } = {},
      message
    } = e || {}
    console.error('Error in updateStudent', errorMessage || message)
    return { success: false, error: errorMessage || message }
  }
}

async function getStudentGradeReport (courseID, cohortID, studentEmail) {
  const email = overrideStudentEmail ? studentEmailOverride : studentEmail
  const url = `/student/grades/${courseID}/${cohortID}/${email}`

  const { data } = await getApi(url)
  return data
}

async function getProctorioUrls (examTake, examTag, courseId) {
  if (overrideStudentEmail) {
    return {
      launchUrl: ''
    }
  }

  const parameters = {
    launchUrl: examTake,
    examStart: examTake,
    examTake,
    examTag,
    examEnd: config.courseBaseUrl,
    examSettings: config.getProctorioExamSettings(courseId)
  }

  console.info('API start getProctorioUrls')
  try {
    const { data } = await postApi('/student/proctorio/get-urls', {}, parameters)
    console.info('API done getProctorioUrls')

    return data
  } catch (err) {
    console.error(`API error in getProctorioUrls: ${err.message}`)
    return null
  }
}

async function getProjectedGrade (courseID, cohortID, studentEmail) {
  try {
    const { projectedGrade } = await getStudentGradeReport(courseID, cohortID, studentEmail)
    return projectedGrade
  } catch (e) {
    console.error('Error when fetching grades: ', e.message)
    return null
  }
}

function getUrl (courseUUID, sectionUUID = courseUUID) {
  return `/dato/files/${courseUUID}/${sectionUUID}`
}

async function submitTrackedEvent (rawData, courseId = '', cohortId = '') {
  console.info('API start submitTrackedEvent')

  const utmProperties = utils.getUTMProperties()
  console.log('utm properties: ', utmProperties)

  const properties = { ...rawData.properties, ...utmProperties }
  const data = { ...rawData, properties }
  let url = '/student/analytics/track-event'
  if (courseId) url += `/${courseId}`
  if (cohortId) url += `/${cohortId}`
  try {
    const res = await postApi(url, {}, data)
    console.info('API done submitTrackedEvent')
    return res
  } catch (err) {
    console.error(`API error in submitTrackedEvent: ${err.message}`)
    return null
  }
}

async function newSubmitTrackedEvent (rawData = {}, url = '') {
  console.info('API start newSubmitTrackedEvent')

  const utmProperties = utils.getUTMProperties()
  console.log('utm properties: ', utmProperties)

  const props = { ...rawData.props, ...utmProperties }
  const data = { ...rawData, props }

  try {
    const res = await postApi(url, {}, data)
    console.info('API done newSubmitTrackedEvent')
    return res
  } catch (err) {
    console.error(`API error in newSubmitTrackedEvent: ${err.message}`)
    return null
  }
}

async function getShopifyVariants (courseId) {
  try {
    const { data } = await getApi(
      `/shopify/variants/${courseId}`, {}, {}
    )
    return data
  } catch (e) {
    console.error('Error when fetching shopify variants', e.message)
    const { response: { data } = {} } = e
    return data
  }
}

async function incrementStudyGuideClicks (courseId, chapterId) {
  try {
    const url = `/student/study-guide-view/${courseId}/${chapterId}`
    const res = await putApi(url)
    return res
  } catch (error) {
    return null
  }
}

async function getTuitionVouchers (relationship) {
  console.info('API start getTuitionVouchers: ', relationship)
  try {
    const { data } = await getApi(`/student/get-tuition-vouchers/${relationship}`)

    console.info('API done getTuitionVouchers: ', relationship)
    return data
  } catch (e) {
    console.error(`API error in getTuitionVouchers ${relationship}: ${e.message}`)
    return null
  }
}

async function getAmazonVouchers () {
  const vouchers = await getTuitionVouchers(VOUCHER_TYPE.AMAZON)

  return vouchers
}

async function uploadTuitionVoucher (voucherId, formData, voucherNumber) {
  try {
    const { data } = await putApi(
      `/student/upload-tuition-voucher/${voucherId}?pdfVoucherNumber=${voucherNumber}`,
      {}, formData, { 'Content-type': 'multipart/form-data' }
    )

    console.info('API done uploadTuitionVoucher')
    return data
  } catch (e) {
    console.error(`API error in uploadTuitionVoucher: ${e.message}`)
    return null
  }
}

async function savePretestAttempt (courseId, pretestId, result) {
  if (overrideStudentEmail) return { success: true }

  try {
    const url = pretestId
      ? `/pretest/${courseId}/${pretestId}`
      : `/pretest/${courseId}`
    const { data } = await postApi(url, {}, result)
    const { fields: { pretestAttempt } = {}, success } = data?.[0] || {}
    return { success: !!(pretestAttempt || success) }
  } catch (e) {
    console.error('Error in savePretestAttempt', e.message)
    return { success: false, message: e.message }
  }
}

async function getPretestAttempts (type) {
  try {
    const { data } = await getApi(`/student/pretests/${type}`)
    return data
  } catch (e) {
    console.error('Error when getting pretest attempts', e.message)
    return []
  }
}

async function assignTokens (body) {
  if (overrideStudentEmail) return { status: 200, data: 'true' }

  try {
    const url = '/tokens/assign'
    const { data } = await postApi(url, {}, body)
    return data
  } catch (e) {
    console.error('Error in assignTokens', e.message)
    return null
  }
}

/**
 * @returns {Boolean} indicating if the refund have been requested
 * the endpoint returns status 200 only if the refund have been requested
 */
async function getAmazonRefundStatus () {
  try {
    const url = '/amazon/student-refund'
    const res = await getApi(url)

    const isRefundRequested = res.status === 200
    return isRefundRequested
  } catch (e) {
    console.error('Error in getAmazonRefundStatus', e.message, e)
    return null
  }
}

async function transferCohort (courseId, cohortDetails) {
  if (overrideStudentEmail) return { status: 200, data: 'true' }

  try {
    const url = `/student/transfer/${courseId}`
    const { data } = await putApi(url, {}, cohortDetails)
    return data
  } catch (e) {
    console.error('Error in transferCohort', e.message)
    return { success: false, message: e.message }
  }
}

async function enrollToCollegeSuccessCourse () {
  if (overrideStudentEmail) return

  try {
    const url = '/student/college-success/enroll'
    const { data } = await putApi(url, {}, {})
    return data
  } catch (e) {
    console.error('Error in enrollCollegeSuccess', e.message)
    return null
  }
}

async function updateAuth0User (body) {
  if (overrideStudentEmail) return body

  try {
    const url = '/update/auth0/user'
    const { data } = await putApi(url, {}, body)
    return data
  } catch (e) {
    console.error('Error in updateAuth0User', e.message)
    return { success: false, message: e.message }
  }
}

async function setCourseFeatures (key, body) {
  if (overrideStudentEmail) return body

  try {
    const url = `/student/course-feature/dashboard-course/${key}`
    const { data } = await putApi(url, {}, body)
    return data
  } catch (e) {
    console.error('Error in setCourseFeatures', e.message)
    return { success: false, message: e.message }
  }
}

async function getCourseFeatures () {
  try {
    const url = '/student/course-features/dashboard-course'
    const res = await getApi(url)
    return res?.data
  } catch (e) {
    console.error('Error in getCourseFeatures', e.message, e)
    return null
  }
}

async function getStudentCurrentGrade (courseId, cohortName, studentEmail) {
  try {
    const url =
      `/student/current-grade/${courseId}/${encodeURIComponent(cohortName)}/${encodeURIComponent(studentEmail)}`
    const res = await getApi(url)
    return res?.data
  } catch (e) {
    console.error('Error in getStudentCurrentGrade: ', e.message)
    return {}
  }
}

async function saveStudyBlocks (studyBlocks = [], reminders = []) {
  try {
    const url = '/student/study-block'
    const { data } = await putApi(url, {}, {
      timeZone: getUserTimeZone(),
      studyBlocks,
      reminders
    })
    return { success: true, data }
  } catch (e) {
    console.error('Error while saving the study blocks', e.message)
    return { success: false, message: e.message }
  }
}

async function updateStudyBlocks (
  inactiveStudyBlocksIDs = [], newStudyBlocks = [], reminders = []
) {
  try {
    const url = '/student/study-block/update-schedule'
    const { data } = await putApi(url, {}, {
      timeZone: getUserTimeZone(),
      inactiveStudyBlocksIDs,
      newStudyBlocks,
      reminders
    })
    return { success: true, data }
  } catch (e) {
    console.error('Error while updating the study blocks', e.message)
    return { success: false, message: e.message }
  }
}

async function getStudyBlocks () {
  try {
    const url = '/student/study-block'
    const { data: { data: { studyBlocks, metadata, reminders } = {} } } = await getApi(url)
    return { success: true, studyBlocks, metadata, reminders }
  } catch (e) {
    console.error('Error while getting the study blocks', e.message)
    return { success: false, message: e.message }
  }
}

async function createStudyBlocksCalendar () {
  try {
    const url = '/student/study-block/add-to-calendar'
    const { data: { success, data = {} } = {} } = await getApi(url)
    return { success, data }
  } catch (e) {
    console.error('Error while adding study blocks to the calendar', e.message)
    return { success: false, message: e.message }
  }
}

async function getStudyBlocksPopupsState () {
  try {
    const url = '/student/ui-activity'
    const { data } = await getApi(url)
    return data
  } catch (e) {
    console.error(
      'Error while getting the study blocks popups state',
      e.message
    )
    return { success: false, message: e.message }
  }
}
