import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ButtonAsLink } from '../LargePopup/styles'
import {
  ButtonsContainer,
  CTAButton,
  PopupTitle,
  SmallPopupContainer,
  StyledCloseIcon,
  TitleContainer
} from './styles'
import { ACCOUNT_PATH } from '../../../../../Constants/accountArea'
import api from '../../../../../api'
import { STUDY_BLOCKS_SECOND_POPUP_CLICKED } from '../../../../../Constants/eventType'

function SmallPopup ({ popUpsState }) {
  const [smallPopupDismissed, setSmallPopupDismissed] = useState(true)
  const history = useHistory()
  const isStudyBlocksPage = history.location.pathname.includes(
    ACCOUNT_PATH.STUDY_BLOCKS_PATH
  )

  useEffect(() => {
    if (Object.keys(popUpsState).length === 0) return

    const dismissedStateFromLS = localStorage.getItem(
      'studyBlocksSmallPopupDismissed'
    )

    const { sbPopup1ClickedAction, sbPopup2ClickedAction } = popUpsState

    if (
      dismissedStateFromLS ||
      sbPopup1ClickedAction !== 'Dismissed' ||
      sbPopup2ClickedAction === 'Dismissed'
    ) {
      return
    }

    setSmallPopupDismissed(false)
  }, [popUpsState])

  const dismissPopup = async () => {
    setSmallPopupDismissed(true)
    localStorage.setItem('studyBlocksSmallPopupDismissed', 'true')
    await api.submitTrackedEvent({
      event: STUDY_BLOCKS_SECOND_POPUP_CLICKED,
      properties: {
        time_stamp: Date.now(),
        action: 'Dismissed'
      }
    })
  }

  return smallPopupDismissed || isStudyBlocksPage ? null : (
    <SmallPopupContainer>
      <TitleContainer>
        <PopupTitle>Don't forget to set up your Studyblocks!</PopupTitle>
        <StyledCloseIcon onClick={dismissPopup} />
      </TitleContainer>
      <ButtonsContainer>
        <CTAButton
          onClick={async () => {
            history.push('/account/study-blocks')
            await api.submitTrackedEvent({
              event: STUDY_BLOCKS_SECOND_POPUP_CLICKED,
              properties: {
                time_stamp: Date.now(),
                action: 'Opened'
              }
            })
          }}
        >
          Start now
        </CTAButton>
        <ButtonAsLink onClick={dismissPopup} data-testid='dismiss-popup'>
          Skip
        </ButtonAsLink>
      </ButtonsContainer>
    </SmallPopupContainer>
  )
}

SmallPopup.displayName = 'SmallPopup'
export default SmallPopup
