export const marketingUrls = {
  recTYIftSm0hiTLEz: 'https://www.outlier.org/collections/business-foundation',
  recS4CAjvmH9Q34BR: 'https://www.outlier.org/collections/college-pathway',
  recE2sZAWyCeCcgLp: 'https://www.outlier.org/collections/data-science-prep',
  cku4ayarl00003g5v8zya64ih: 'https://www.outlier.org/products/intro-to-business',
  ckxyytpau00003f5xhe3sf4xj: 'https://www.outlier.org/products/principles-of-economics',
  ckw6tiqy100003e5xexynd0dz: 'https://www.outlier.org/products/college-algebra',
  cku4ayye700003g5vsb6jgmhb: 'https://www.outlier.org/products/college-writing-i',
  ckp359ieh00003h5vm158kdt0: 'https://www.outlier.org/products/intro-to-financial-accounting',
  ckp3592gx00003h5vsd5ijmp9: 'https://www.outlier.org/products/intro-to-sociology',
  ckp356yiy00003h5vpz3fbu1h: 'https://www.outlier.org/products/precalculus',
  'b227c462-332c-40e0-8735-ea3af6f11661': 'https://www.outlier.org/products/calculus-i',
  ckdampe3b00003g5m6l85b1s5: 'https://www.outlier.org/products/intro-to-statistics',
  ckiawlgvw00003h5o8c6u6rog: 'https://www.outlier.org/products/intro-to-microeconomics',
  ckp3587wu00003h5v6jfbv37v: 'https://www.outlier.org/products/intro-to-macroeconomics',
  '1e2f466d-049a-41e7-af53-74afbfa9d87b': 'https://www.outlier.org/products/intro-to-psychology',
  ckgqsu5lf00003h5lzot6as6x: 'https://www.outlier.org/products/intro-to-philosophy',
  ckdf2158p00003g5milp6mvj8: 'https://www.outlier.org/products/intro-to-astronomy'
}

export const courseHomeUrls = {
  cku4ayarl00003g5v8zya64ih: 'https://business.beta.outlier.org',
  ckxyytpau00003f5xhe3sf4xj: 'https://principlesofeconomics.beta.outlier.org',
  ckw6tiqy100003e5xexynd0dz: 'https://collegealgebra.beta.outlier.org',
  cku4ayye700003g5vsb6jgmhb: 'https://collegewriting-i.beta.outlier.org',
  ckp359ieh00003h5vm158kdt0: 'https://financialaccounting.beta.outlier.org',
  ckp3592gx00003h5vsd5ijmp9: 'https://sociology.beta.outlier.org',
  ckp356yiy00003h5vpz3fbu1h: 'https://precalculus.beta.outlier.org',
  'b227c462-332c-40e0-8735-ea3af6f11661': 'https://calculus.beta.outlier.org',
  ckdampe3b00003g5m6l85b1s5: 'https://statistics.beta.outlier.org',
  ckiawlgvw00003h5o8c6u6rog: 'https://microeconomics.beta.outlier.org',
  ckp3587wu00003h5v6jfbv37v: 'https://macroeconomics.beta.outlier.org',
  '1e2f466d-049a-41e7-af53-74afbfa9d87b': 'https://psychology.beta.outlier.org',
  ckgqsu5lf00003h5lzot6as6x: 'https://philosophy.beta.outlier.org',
  ckdf2158p00003g5milp6mvj8: 'https://astronomy.beta.outlier.org'
}

export const allCoursesData = [
  {
    id: 'cku4ayarl00003g5v8zya64ih',
    displayName: 'Intro to Business',
    url: 'https://business.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/intro-to-business',
    grades: {
      projectedGrade: ''
    },
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: 'ckxyytpau00003f5xhe3sf4xj',
    displayName: 'Principles of Economics',
    url: 'https://principlesofeconomics.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/principles-of-economics',
    grades: {
      projectedGrade: ''
    },
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: 'ckw6tiqy100003e5xexynd0dz',
    displayName: 'College Algebra',
    url: 'https://collegealgebra.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/college-algebra',
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: 'cku4ayye700003g5vsb6jgmhb',
    displayName: 'College Writing I',
    url: 'https://collegewriting-i.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/college-writing-i',
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: 'ckp359ieh00003h5vm158kdt0',
    displayName: 'Intro to Financial Accounting',
    url: 'https://financialaccounting.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/intro-to-financial-accounting',
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: 'ckp3592gx00003h5vsd5ijmp9',
    displayName: 'Intro to Sociology',
    url: 'https://sociology.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/intro-to-sociology',
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: 'ckp356yiy00003h5vpz3fbu1h',
    displayName: 'Precalculus',
    url: 'https://precalculus.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/precalculus',
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: 'b227c462-332c-40e0-8735-ea3af6f11661',
    displayName: 'Calculus I',
    url: 'https://calculus.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/calculus-i',
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: 'ckdampe3b00003g5m6l85b1s5',
    displayName: 'Intro to Statistics',
    url: 'https://statistics.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/intro-to-statistics',
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: 'ckiawlgvw00003h5o8c6u6rog',
    displayName: 'Intro to Microeconomics',
    url: 'https://microeconomics.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/intro-to-microeconomics',
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: 'ckp3587wu00003h5v6jfbv37v',
    displayName: 'Intro to Macroeconomics',
    url: 'https://macroeconomics.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/intro-to-macroeconomics',
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: '1e2f466d-049a-41e7-af53-74afbfa9d87b',
    displayName: 'Intro to Psychology',
    url: 'https://psychology.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/intro-to-psychology',
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: 'ckgqsu5lf00003h5lzot6as6x',
    displayName: 'Intro to Philosophy',
    url: 'https://philosophy.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/intro-to-philosophy',
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  },
  {
    id: 'ckdf2158p00003g5milp6mvj8',
    displayName: 'Intro to Astronomy',
    url: 'https://astronomy.beta.outlier.org',
    marketingUrl: 'https://www.outlier.org/products/intro-to-astronomy',
    cohort: {
      dateStart: '',
      dateEnd: '',
      studentStatus: ''
    }
  }
]
