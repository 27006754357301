import styled from 'styled-components'
import { Card } from 'react-bootstrap'

export const CardWrapper = styled(Card)`
  background-color: #1D1D1F;
  padding: 24px 12px;
  height: 100%;
`

export const ExitTitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  line-height: 29px;
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  margin-top: 32px;
`

export const Link = styled.a`
  color: #77B1AF!important;
  font-weight: 400;
  
  &:hover {
      text-decoration: none;
      opacity: 0.5
  }
`

export const PageOverview = styled.div`
  margin-top: 24px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  white-space: pre-wrap;
`

export const SectionOverview = styled(PageOverview)`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 36px;
`

export const BoldParagraph = styled.p`
  font-weight: 400;
  margin-bottom: 24px;
`

export const BoldText = styled.span`
  font-weight: 400;
`

export const PageContent = styled.div`
  margin: 24px 1rem 1rem 1rem;
`
export const RejectionMessage = styled.div`
  display: flex;

  img {
    width: 20px;
    height: 20px;
    margin-right: 13px;
    position: relative;
    top: 2px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #E1774F;
    margin-bottom: 0;
  }
`

export const FormWrapper = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 36px;
  height: 700px;

  @media (max-width: 767px) {
    height: 88vh;
  }
`
