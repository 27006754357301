import styled from 'styled-components'
import mediaqueries from '../../../../mediaQueries'

export const PageWrapper = styled.div`
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  gap: 36px;

  ${mediaqueries.mobile`
    margin-top: 24px;
  `}
`

export const PageBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > button {
    width: fit-content;
    height: fit-content;
  }

  ${mediaqueries.mobile`
    > button {
      width: 100%;
    }
  `}
`

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const PageTitle = styled.h2`
  margin: 0;
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`

export const SubTitle = styled.h3`
  margin: 0;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  display: flex;
  align-items: center;
`

export const Dot = styled.div`
  width: 2px;
  height: 2px;
  margin: 0 8px;
  display: inline-block;
  background: #98A4AE;
  border-radius: 50%;
  align-self: center;
`

export const BackgroundText = styled.div`
  gap: 8px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 5px;
  background:#1D1D1F;

  > h3 {
    margin: 0;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  > p {
    margin: 0;
    color: #CCD4D8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    > a {
      text-decoration: underline;
    }
  }
`

export const NormalText = styled.p`
  margin: 0;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;

  > a {
    text-decoration: underline;
  }
`

export const customStyles = {
  primaryBtn: {
    padding: '14px 24px'
  },
  secondaryBtn: {
    padding: '8px'
  }
}
