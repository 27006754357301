import styled from 'styled-components'

export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #22292d;
  padding: 12px;
  margin-top: 65px !important;
`

export const NeedsReviewMessage = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: white;
  font-weight: 400;
  text-align: center;
  margin: 0px;
`

export const WarningIcon = styled.img`
  margin-right: 5px;
`
