import React, { useContext } from 'react'
import {
  CourseIcon,
  WidgetHeading,
  CourseName,
  CourseState,
  WidgetWrapper
} from './style'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  getCohortStartSecondsSinceEpoch,
  getCourseAssets
} from '../../utilities/course'
import { RenderCard } from './RenderCard'
import { termState } from '../../Constants/cohort'
import find from 'lodash/find'
import { progressBeforeCohortStart } from '../../utilities/cohort'

const WhatsNextWidget = ({
  course,
  courseTermState,
  handleEndedCourses
}) => {
  const {
    catalogCourses,
    isStudioCohort,
    coursesProgress
  } = useContext(AppContext)
  const { id, displayName, cohort } = course

  const { courseIcon } = getCourseAssets(catalogCourses, id)
  const { IS_UPCOMING, IN_PROGRESS } = termState

  const getCourseDateState = (cohort, courseTermState) => {
    if (!cohort || isStudioCohort) return
    const startDateSinceEpoch = getCohortStartSecondsSinceEpoch(cohort)
    let dateState = ''
    if (courseTermState === IN_PROGRESS) {
      const courseProgress = find(coursesProgress, { courseId: id })
      if (!courseProgress) return

      const progressBeforeCohortBegin =
        progressBeforeCohortStart(cohort, courseProgress)
      dateState = progressBeforeCohortBegin ? 'Now open!' : ''
    }
    if (courseTermState === IS_UPCOMING) {
      const startDate = new Date(startDateSinceEpoch)
      const month =
        startDate.toLocaleString('en-US', { month: 'short' })
      const day = startDate.getDate()
      dateState = day + ' ' + month
      dateState = 'begins ' + dateState
    }
    return dateState
  }

  const courseDateState = getCourseDateState(cohort, courseTermState)

  return (
    <>
      <WidgetWrapper>
        <WidgetHeading className='d-md-flex flex-sm-column flex-md-row'>
          <CourseName className='d-flex align-items-center flex-fill'>
            <CourseIcon src={courseIcon} />
            <div>{displayName}</div>
          </CourseName>
          <CourseState data-testid='course-state-date'>
            {courseDateState}
          </CourseState>
        </WidgetHeading>
        <RenderCard
          course={course}
          courseTermState={courseTermState}
          handleEndedCourses={handleEndedCourses}
        />
      </WidgetWrapper>
    </>
  )
}

export default WhatsNextWidget
