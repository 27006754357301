import React from 'react'
import PropTypes from 'prop-types'
import {
  Content,
  ScoreHeader,
  Description
} from './style'
import { PrimaryButton } from './QuestionComponent/style'
import { Header } from '../Tokens/style'
import SeeOptionsButton from './SeeOptionsButton'
import utils from '../../utilities'

const ScorePage = ({ courseName, score, minimumPretestScore, pretestLockoutDays }) => {
  const passedPretest = score >= minimumPretestScore

  const gotoCoursePage = () => {
    window.location.hash = utils.getDetailsPagePath()
  }

  return (
    <Content>
      <div>
        <ScoreHeader>
          score: {score}%
        </ScoreHeader>
        <Header>
          {passedPretest
            ? 'Congrats, you passed the test!'
            : 'You didn’t pass (yet!)'}
        </Header>
        <Description>
          {passedPretest
            ? `You are now qualified for enrollment in ${courseName}.`
            : `A score of ${minimumPretestScore} or higher is needed to enroll in ${courseName}. But don’t worry, you can study up and try again in ${pretestLockoutDays} days. In the meantime, explore all of your options.`}
        </Description>
        {passedPretest
          ? (
            <PrimaryButton
              onClick={gotoCoursePage}
              data-testid='btn-continue'
              width='150px'
            >
              continue
            </PrimaryButton>
          ) : (
            <SeeOptionsButton />
          )}
      </div>
    </Content>
  )
}

ScorePage.displayName = 'ScorePage'

ScorePage.propTypes = {
  courseName: PropTypes.string,
  score: PropTypes.number,
  minimumPretestScore: PropTypes.number,
  pretestLockoutDays: PropTypes.number
}

export default ScorePage
