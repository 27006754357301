import React from 'react'
import Hero from './Hero/Hero'
import { DashboardContainer } from './style'
import { MyCoursesContainer } from './MyCourses/style'
import ActiveCourses from './MyCourses/ActiveCourses'
import FinishedCourses from './MyCourses/FinishedCourses'
import StudyBlocks from './StudyBlocks/StudyBlocks'
import RecommendedCourses from './RecommendedCourses/RecommendedCourses'

function DashboardRedesign () {
  return (
    <DashboardContainer className='dashboard-redesign'>
      <Hero />
      <MyCoursesContainer>
        <ActiveCourses />
        <FinishedCourses />
      </MyCoursesContainer>
      <StudyBlocks />
      <RecommendedCourses />
    </DashboardContainer>
  )
}

DashboardRedesign.displayName = 'DashboardRedesign'
export default DashboardRedesign
