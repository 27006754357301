import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Toast from '../../../Toast'
import OpenBookSvg from '../../../../assets/icons/open-book.svg'
import {
  getSessionsArray,
  getUpcomingActiveSession
} from '../../../../utilities/studyBlocks'
import { getBlockTime } from '../../../StudyBlocksDisplay/utils'
import { getHoursFromDate } from '../../../../utilities/dateTime'

function StudyBlockStarting ({ studyBlocksData }) {
  const [show, setShow] = useState(false)
  const remove = () => setShow(false)

  const [upcomingSession, setUpcomingSession] = useState({})

  useEffect(() => {
    const studyBlocks = getSessionsArray(studyBlocksData?.studyBlocks)

    const sessionIntervalId = setInterval(() => {
      const upcomingSession = getUpcomingActiveSession(studyBlocks)
      setUpcomingSession(upcomingSession)

      const currentTime = new Date()
      const sessionStartTime = upcomingSession && new Date(upcomingSession?.start)
      const timeLeftForSessionStart = sessionStartTime - currentTime
      const [MIN_5, MIN_4] = [5 * 60 * 1000, 4 * 60 * 1000]
      const shouldShowToast = timeLeftForSessionStart <= MIN_5 &&
        timeLeftForSessionStart >= MIN_4
      setShow(prevShow => prevShow || shouldShowToast)
    }, 1000 * 60) // check every minute

    return () => {
      clearInterval(sessionIntervalId)
    }
  }, [studyBlocksData])

  const title = 'Your next study block starts in 5 minutes'
  const toastWrapperStyle = { margin: '24px 64px 0', width: '448px' }
  const blockHours = {
    start: getHoursFromDate(upcomingSession?.start),
    end: getHoursFromDate(upcomingSession?.end)
  }
  const getBody = () => (
    <p>
      It’s time to reel in the focus and work between {getBlockTime(blockHours)}.
      You’ve got this!
    </p>
  )

  return (
    <Toast
      show={show}
      remove={remove}
      title={title}
      getBody={getBody}
      headerIcon={OpenBookSvg}
      toastWrapperStyle={toastWrapperStyle}
    />
  )
}

StudyBlockStarting.propTypes = {
  studyBlocksData: PropTypes.shape({
    studyBlocks: PropTypes.object
  })
}

StudyBlockStarting.displayName = 'StudyBlockStarting'
export default StudyBlockStarting
