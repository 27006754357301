import React from 'react'
import WhatsNextWidget from '../WhatsNextWidget'
import config from '../../../../config'
import { PROGRESS_TYPE } from '../../../../Constants/courseCard'

function Reading ({
  course,
  courseProgress,
  chapterNo,
  sectionNo,
  desktopHero
}) {
  const { id } = course
  const { studentProgress, sectionData } = courseProgress

  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const { READING_PROGRESS, READING_COMPLETE } = PROGRESS_TYPE

  const { section_exe: sectionExe, section_uuid: sectionUuid } = sectionData

  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`
  const sectionNumber = `Section ${chapterNo}.${sectionNo}`

  const getReadingInfo = () => {
    const { readings } = sectionExe

    const readingProgress = studentProgress[READING_PROGRESS]
      ? Object.keys(studentProgress[READING_PROGRESS])
      : []

    for (let i = readings.length - 1; i >= 0; i--) {
      const { question_set_uuid: questionSetUuid } = readings[i]
      const visitedReading = readingProgress.includes(questionSetUuid)
      if (visitedReading) {
        return {
          readingNo: i + 1,
          readingUuid: questionSetUuid
        }
      }
    }
    return null
  }

  const getNextActivity = () => {
    const {
      lecture,
      multi_lecture_videos: multipleVideos,
      active_learning: activeLearning,
      practice_terms: practiceTerms,
      practice_exercises: practiceExercises,
      quiz
    } = sectionExe || {}
    if (lecture || multipleVideos) return 'Lectures'
    if (activeLearning) return 'Active Learning'
    if (practiceExercises) return 'Practice Exercises'
    if (practiceTerms) return 'Practice Terms'
    if (quiz) return 'Quizzes'
  }

  const completedGuesswork = Object.keys(studentProgress[READING_COMPLETE])
  const isReadingCompleted = completedGuesswork.includes(sectionUuid)

  const { readingNo, readingUuid } = getReadingInfo()

  let stateData = {
    dataTestId: 'reading-inprogress',
    description: `You viewed Reading ${readingNo ||
      ''} of ${sectionNumber} Readings. Continue to complete the activity.`,
    primaryButtonText: 'Continue',
    primaryButtonFunction: () => {
      window.location.href = `${sectionUrl}/${readingUuid}`
    }
  }
  if (isReadingCompleted) {
    stateData = {
      dataTestId: 'reading-completed',
      description: `You completed Reading ${readingNo ||
        ''} of ${sectionNumber}Readings. Continue to ${sectionNumber} ${getNextActivity()}.`,
      primaryButtonText: 'Continue',
      primaryButtonFunction: () => {
        window.location.href = sectionUrl
      }
    }
  }
  const { dataTestId } = stateData
  return (
    <div data-testid={dataTestId}>
      <WhatsNextWidget {...stateData} desktopHero={desktopHero} />
    </div>
  )
}

Reading.displayName = 'Reading'
export default Reading
