import React, { useEffect } from 'react'
import Bank from '../../assets/icons/gguRegistration/bank.svg'
import {
  COURSE_REGISTRATION_PAGEVIEW,
  COURSE_REGISTRATION_PAGE_SUBMITTED
} from '../../Constants/eventType'
import { GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import {
  isSegmentEventInLS,
  setFiredSegmentEventsInLS
} from '../../utilities/gguDegreeUtils'
import { Title, Subtext, PrimaryButton, Page } from './style'

const WelcomePage = ({ name, setCurrentPage }) => {
  const { sendEvent } = useSegmentEvents()

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: '1-1'
  }

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'Welcome',
        page_number: '1-1'
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  return (
    <Page>
      <img className='bank' alt='Bank' src={Bank} />
      <Title>Welcome to course enrollment, {name}!</Title>
      <Subtext>Let's pick your courses for next term.</Subtext>
      <PrimaryButton
        onClick={() => {
          sendEvent({
            eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
            properties: {
              page_name: 'Welcome',
              page_number: '1-1'
            }
          })
          setCurrentPage(GGU_V2_ENROLLMENT_PAGES.EXPECTED_GRADUATION)
        }}
      >
        Get Started
      </PrimaryButton>
    </Page>
  )
}

WelcomePage.displayName = 'WelcomePage'

export default WelcomePage
