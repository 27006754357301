import styled from 'styled-components'
import { Card, Col, Row } from 'react-bootstrap'
import media from '../../../mediaQueries'

const CardBodyWrapper = styled.div`
  height: 4px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
`

export const CardWrapper = styled(Card)`
  ${({ disabled }) => !disabled && 'cursor: pointer;'}
  background-color: #161618;
  border: none;
  margin-top: 11px;
  margin-bottom: 11px;
`

export const CardImageWrapper = styled.div`
  position: relative;
  height: 249px;
  background-image: ${({ src }) =>
    `linear-gradient(180deg, rgba(0, 0, 0, 0) 49.78%, #000000 100%),
     url(${src});
  `};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 5px;
  @media (max-width: 767px) {
    background-size: cover;
    background-position: unset;
    height: 202px;
  }
`

export const CardImageTextWrapper = styled.div`
  position: absolute;
  margin-bottom: 12px;
  width: 90%;
  left: 25.5px;
  bottom: 26px;
`

export const CardBodyInProgressWrapper = styled(CardBodyWrapper)`
  background: #77b1af;
`

export const CardBodyTermCompletedWrapper = styled(CardBodyWrapper)`
  background: #C1CFD9;
`

export const CardBodyInProgressTextWrapper = styled(Card.Text)`
  color: #161618;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
`

export const CardBodyToBeStartedWrapper = styled(CardBodyWrapper)`
  background: #C1CFD9;
`

export const CardBodyToBeStartedTextWrapper = styled(Card.Text)`
  color: #161618;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
`

export const CardBodyTermCompletedTextWrapper = styled(Card.Text)`
  color: #c9d4de;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
`

export const CourseTextWrapper = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
`

export const CourseDateWrapper = styled.div`
  font-size: 14px;
  color: #B1BFC5;
  line-height: 17px;
  margin-top: 5px;
`

export const CourseStateWrapper = styled.div`
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  margin-left: 53px;
  
  ${({ isStudentProgress }) => isStudentProgress && `
    margin-right: 25.57px;
    margin-left: 25.57px;
    ${media.tablet`
    margin-right: 25.12px;
    margin-left: 25.12px;`}
    ${media.mobile`
    margin-right: 25.1px;
    margin-left: 25.1px;`}
    ${media.smallMobile`
    margin-right: 25.43px;
    margin-left: 25.43px;`}
  `}
`

export const CourseDescriptionWrapper = styled.div`
  font-size: 14px;
  font-style: italic;
  color: #B1BFC5;
  margin-top: 5px;
  line-height: 17px;
`

export const CourseLearnMoreLink = styled.div`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #77B1AF;
  line-height: 17px;
  margin-top: 12px;
`

export const CardCol = styled(Col)`
  @media (min-width: 768px) {
    padding-left: 4.5px;
    padding-right: 4.5px;
  }
`

export const StyledRow = styled(Row)`
  @media (min-width: 768px) {
    margin-left: -4px;
    margin-right: -4px;
  }
`

export const CourseIcon = styled.span`
  background-color: #fff;
  width: 37px;
  height: 36px;
  mask: ${({ src }) => `
   url(${src}) no-repeat 1px 0;
 `};
  mask-size: contain;
  margin-right: 16px;
  margin-top: 6px;
`

export const ProjectedFinalGradeText = styled.span`
  position: absolute;
  top: 26px;
  left 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
`

export const CardCourseDescriptionWrapper = styled.div``

export const CourseDescription = styled.div``

export const Container = styled.div`
  height: 3px;
  position: relative;
  margin-top: 222px;
  ${media.mobile`
  margin-top: 175px;`}
  ${media.smallMobile`
  margin-top: 175px;`}
`

export const BaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
  transition: width 10s ease-in-out;
`

export const Background = styled(BaseBox)`
  background: #5E636B;
  width: 100%;
`

export const Progress = styled(BaseBox)`
  background: #5FC4B8;
  width: ${({ percent }) => percent}%;
`

export const BrandWrap = styled.span`
  background: #191631;
  border-radius: 3px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #969BFF;
  padding:4px 12px;
  margin-left:8px;
  vertical-align: middle;
  position: absolute;
  right: 10px;
  top: 10px;
`

export const AddLink = styled.p`
  color: #5fc4b8;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  word-wrap: break-word;
  margin-top: 3px;
  display: block;
  margin-bottom: 0px;
  cursor: pointer;
  width: 187px;
  margin-left: 53px;
  text-transform: uppercase;
  &:hover{
    color: #b8fff7 !important;
  }
`
