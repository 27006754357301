import React, { useEffect, useRef } from 'react'
import { BackButton, PageContainer, HeaderText, SubHeaderText } from './PageNotFound.styled'
import { useHistory } from 'react-router-dom'

const PageNotFound = () => {
  const history = useHistory()
  const headerRef = useRef(null)

  const goToDashboard = () => {
    history.push('/')
  }

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.focus()
    }
  }, [])

  return (
    <PageContainer
      role='alert'
      aria-live='assertive'
      data-testid='page-not-found'
      aria-labelledby='header-text'
      aria-describedby='sub-header-text'
    >
      <HeaderText
        id='header-text'
        data-testid='header-text'
        tabIndex='-1'
        ref={headerRef}
      >
        Whoops!
      </HeaderText>
      <SubHeaderText id='sub-header-text' data-testid='sub-header-text'>
        The page you are looking for does not exist.
      </SubHeaderText>
      <BackButton
        data-testid='back-button'
        onClick={goToDashboard}
      >
        Back to Dashboard
      </BackButton>
    </PageContainer>
  )
}

export default PageNotFound
