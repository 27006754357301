import styled from 'styled-components'
import { LinkStyles } from '../../Links/style'

export const BackButton = styled.div`
  margin-top: 88px;
  height: 17px;
  width: fit-content;
  font-style: normal;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1.4em;
  line-height: 17px;
  div {
    margin-left: 1em;
  }
  ${LinkStyles}
`
