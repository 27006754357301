import React, { useState } from 'react'
import {
  ModalWrapper,
  Modal,
  ModalHeader,
  Close,
  ModalBody,
  ConfirmationStatement,
  CustomCheckBox,
  Description,
  ButtonsWrapper
} from './style'

function ModalContent ({ agreement, modalDescription, title, changeHandler }) {
  return (
    <>
      <ConfirmationStatement>
        <CustomCheckBox>
          <p>{agreement}</p>
          <input
            type='checkbox'
            name={title}
            id=''
            onChange={(e) => {
              const { checked, name } = e.target
              changeHandler(name, checked)
            }}
          />
          <span />
        </CustomCheckBox>
      </ConfirmationStatement>
      <Description>{modalDescription}</Description>
    </>
  )
}

export default function Modals ({
  modalType,
  letsDoItClickHandler,
  goBackClickHandler
}) {
  const [auditIschecked, setAuditIsChecked] = useState(false)

  const changeHandler = (name, checked) => {
    setAuditIsChecked(checked)
  }

  return (
    <ModalWrapper data-testid='modal'>
      <Modal modalType={modalType}>
        <ModalHeader>
          <h1>Confirm a few last things</h1>
          <Close onClick={goBackClickHandler} data-testid='close-icon' />
        </ModalHeader>
        <ModalBody>
          <ModalContent
            agreement={'I don\'t want credits for this course.'}
            modalDescription={'Auditing this course means that you won\'t earn credits, receive a grade, or see it appear on your academic record.'}
            title='audit'
            changeHandler={changeHandler}
          />
          <ButtonsWrapper>
            <button
              disabled={!auditIschecked}
              onClick={() => letsDoItClickHandler()}
            >
              let's do this
            </button>
            <button onClick={() => goBackClickHandler()}>
              go back
            </button>
          </ButtonsWrapper>
        </ModalBody>
      </Modal>
    </ModalWrapper>
  )
}
