import styled from 'styled-components'
import { Button } from 'react-bootstrap'

const ButtonWrapper = styled(Button)`
  border-radius: 5px;
  height: 40px;
  width: ${props => props.width || '100%'};
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;

  &:not(:disabled):not(.disabled):active {
    background-color: transparent;
    border-color: transparent;
  }
`

export const PrimaryButton = styled(ButtonWrapper)`
  background-color: #77B1AF;
  color: #161618;
  border: none;

  &:hover {
    color: #161618;
    background-color: #4d9b96;
    border-color: #4d9b96;
  }

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: #77b1af;
    border: 3px solid #77b1af;
    color: #161618;
    text-decoration: none;
  }

  &:disabled {
    color: #212529;
    background-color: #78BCB8;
    border-color: #78BCB8;
    pointer-events: none;
  }
`

export const SecondaryButton = styled(ButtonWrapper)`
  background-color: #1D1D1F;
  color: #77B1AF;
  border: 3px solid #77B1AF;

  &:hover {
    color: #4d9b96;
    background: #1D1D1F !important;
    border-color: #4d9b96 !important;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
    background-color: transparent;
    border: 3px solid #77b1af;
    color: #77b1af;
    text-decoration: none;
  }

  &:disabled {
    color: #8E9C9F;
    background-color: #5B6B6E;
    border-color: #5B6B6E;
    pointer-events: none;
  }
`
