import React from 'react'
import {
  RECOMMENDED_COURSES_BUTTON_STATE_IDENTIFIER,
  COURSE_QUANTITY_LS_KEY,
  SELECTED_COURSE_LS_KEY,
  SEGMENT_EVENTS_V1_LS_KEY,
  SEGMENT_EVENTS_V2_LS_KEY
} from '../../Constants/gguDegrees'
import {
  clearVisitedPages,
  getCurrentProspect,
  removeRecommendedCoursesInLS
} from '../../utilities/gguDegreeUtils'
import {
  OverrideCSSprops,
  EnrollmentContainer,
  EnrollmentTitle,
  LetsGetStartedBtn
} from './styles'
import useLocalStorage from '../../hooks/localStorageHook/localStorageHook'

const EnrollmentConfirmation = ({ setShowEC, prospects }) => {
  const currentProspect = getCurrentProspect(prospects)

  const [, setButtonState] = useLocalStorage({
    identifier: `${RECOMMENDED_COURSES_BUTTON_STATE_IDENTIFIER}_${currentProspect?.prospectId}`
  })

  const handleClick = () => {
    localStorage.setItem(
      'enrollmentConfirmed',
      JSON.stringify({ confirmed: true })
    )
    removeRecommendedCoursesInLS(true)
    clearVisitedPages()
    localStorage.removeItem(COURSE_QUANTITY_LS_KEY)
    localStorage.removeItem(SELECTED_COURSE_LS_KEY)
    localStorage.removeItem(SEGMENT_EVENTS_V1_LS_KEY)
    localStorage.removeItem(SEGMENT_EVENTS_V2_LS_KEY)
    setButtonState('')
    setShowEC(false)
  }

  return (
    <EnrollmentContainer>
      <OverrideCSSprops />
      <EnrollmentTitle>You’re officially registered for your upcoming semester!</EnrollmentTitle>
      <LetsGetStartedBtn onClick={handleClick}>let’s get started</LetsGetStartedBtn>
    </EnrollmentContainer>
  )
}

export default EnrollmentConfirmation
