import styled, { css } from 'styled-components'
import media from '../../../mediaQueries'

export const ToastWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 400px;
  margin: 24px 24px 0;
  display: flex;
  flex-direction: column;
  gap: ${({ hasBreak }) => hasBreak ? '24px' : '16px'};
  background-color: #25272B;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;

  hr {
    ${({ hasBreak }) => !hasBreak && css`
      display: none;
    `}
  }

  ${media.smallMobile`
    margin: 24px !important;
    width: calc(100% - 48px) !important;
    gap: 24px;

    hr {
      display: block;
    }
  `}
`

export const HorizontalLine = styled.hr`
  margin: 0;
  opacity: 0.1;
  border: 1px solid #FFFFFF;
`

export const ToastHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 24px 0 24px;

  img {
    cursor: pointer;
  }

  #header-icon {
    margin-right: 8px;
  }

  #close-icon {
    width: 14px;
    height: 14px;
  }
`

export const ToastHeaderData = styled.div`
  display: flex;
`

export const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`

export const ToastBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px 24px 24px;
  gap: ${({ hasBreak }) => hasBreak ? '24px' : '16px'};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  p {
    margin-bottom: 0;
    letter-spacing: 0.5px;
  }

  br {
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline;
  }

  button {
    padding: 16px 24px;
    width: fit-content;
  }
`
