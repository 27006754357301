import { ExitOptions } from '../../../Constants/accountArea'
import AuditIcon from '../../../assets/icons/audit-icon.svg'
import DropIcon from '../../../assets/icons/drop-icon.svg'
import TransferIcon from '../../../assets/icons/transfer-icon.svg'
import WithdrawIcon from '../../../assets/icons/withdraw-icon.svg'
import { getCohortDeadlinesTime } from '../../../utilities/cohort'
import { FORM_LINKS, TYPE_FORM_IDS } from '../../../data/constants'

export const getOptionIcon = (option) => {
  const { AUDIT, DROP, TRANSFER, WITHDRAW } = ExitOptions
  switch (option) {
    case AUDIT:
      return AuditIcon
    case DROP:
      return DropIcon
    case TRANSFER:
      return TransferIcon
    case WITHDRAW:
      return WithdrawIcon
    default:
      return AuditIcon
  }
}

export const getOptionDescription = ({
  option,
  isGGUCourse,
  isPostTokensCohort,
  isRegularPartnerUser
}) => {
  const { AUDIT, DROP, TRANSFER, WITHDRAW } = ExitOptions

  switch (option) {
    case AUDIT:
      return 'Learn the material without being graded or receiving college credit.'
    case DROP:
      if (isGGUCourse) return 'Leave this course without impacting your academic record.'
      return !isPostTokensCohort || isRegularPartnerUser
        ? 'Leave this course and have it disappear from your academic record.'
        : 'Leave this course and reclaim a token that can be used in the future.'
    case TRANSFER:
      return 'Move into a new cohort with dates that better fit your schedule.'
    case WITHDRAW:
      return 'Earn a “W” on your transcript instead of a letter grade.'
    default:
      return ''
  }
}

export const getOptionDate = ({ option, dropDeadline, withdrawDeadline }) => {
  const { AUDIT, DROP, TRANSFER, WITHDRAW } = ExitOptions

  switch (option) {
    case AUDIT:
    case DROP:
    case TRANSFER:
      return dropDeadline
    case WITHDRAW:
      return withdrawDeadline
    default:
      return undefined
  }
}

export const getFormPageNavigationData = ({
  option,
  cohort,
  courseName,
  partnerName,
  isPostTokensCohort,
  isStudentEnrolled
}) => {
  const { AUDIT, DROP, TRANSFER, WITHDRAW, REQUEST_EXCEPTION } = ExitOptions
  const { dropTimePT, withdrawalTimePT } =
    getCohortDeadlinesTime(cohort)

  const getDropFormId = () => {
    const {
      scholarshipDrop,
      drop,
      'drop_v2.1': dropV21,
      'auditDrop_v2.1': auditDropV21,
      auditDrop
    } = TYPE_FORM_IDS

    const {
      scholarship,
      relationship: {
        fields: { relationshipType = [] } = {}
      } = {}
    } = cohort || {}
    const isScholarship = scholarship || relationshipType?.[0] === 'scholarship'
    if (isScholarship && !isPostTokensCohort) return scholarshipDrop

    if (isStudentEnrolled) return isPostTokensCohort ? dropV21 : drop
    return isPostTokensCohort ? auditDropV21 : auditDrop
  }

  const getFormId = () => {
    switch (option) {
      case AUDIT:
        return TYPE_FORM_IDS.audit
      case DROP:
        return getDropFormId()
      case TRANSFER:
        return undefined
      case WITHDRAW:
        return TYPE_FORM_IDS.withdrawal
      case REQUEST_EXCEPTION:
        return TYPE_FORM_IDS.exception
      default:
        return undefined
    }
  }

  const getLink = () => {
    switch (option) {
      case AUDIT:
        return FORM_LINKS.audit
      case DROP:
        return FORM_LINKS.drop
      case TRANSFER:
        return FORM_LINKS.transfer
      case WITHDRAW:
        return FORM_LINKS.withdrawal
      case REQUEST_EXCEPTION:
        return FORM_LINKS.exception
      default:
        return undefined
    }
  }

  const getDeadlinePT = () => {
    switch (option) {
      case AUDIT:
        return dropTimePT
      case DROP:
        return dropTimePT
      case TRANSFER:
        return dropTimePT
      case WITHDRAW:
        return withdrawalTimePT
      default:
        return undefined
    }
  }

  const formId = getFormId(option, cohort, isPostTokensCohort, isStudentEnrolled)
  const link = getLink(option)
  const deadline = getDeadlinePT(option, dropTimePT, withdrawalTimePT)

  return {
    link,
    state: {
      formId,
      courseName,
      cohort,
      partnerName,
      deadline
    }
  }
}
