import isEqual from 'lodash/isEqual'
import { pretestResult, questionTypes } from '../Constants/pretest'
import { COMPLETED } from '../Constants/studentStatus'
import { getCurrentCourse, getLatestCohort, isEnrolledCourse } from './course'
import { ICalendar } from 'datebook'
import { addInfoForCourses } from './chapterUtils'

export const getQuestionType = question => {
  if (!question) return null

  const { question_type: questionType } = question
  return questionType
}

export const getQuestionIndex = (questions, currentQuestion) => {
  if (!questions?.length) return -1

  return questions.findIndex(question => {
    return question.Question_uuid === currentQuestion.Question_uuid
  })
}

export const setQuestionsState = (questions, state) => {
  return questions.map(question => {
    question.currentState = state
    return question
  })
}

const getMultipleChoiceAnswer = ({
  questionUUID,
  answer,
  currentAnswer
}) => {
  const correctAnswer = answer
    .map(value => Number(value) - 1)
    .sort()
  const studentAnswer = currentAnswer.sort()
  const isCorrectAnswer = isEqual(correctAnswer, studentAnswer)

  return {
    uuid: questionUUID,
    answer: studentAnswer,
    correct: isCorrectAnswer
  }
}

export const getAnswerObject = ({
  currentQuestion,
  currentAnswer
}) => {
  const { MULTIPLE_CHOICE, TRUE_FALSE } = questionTypes
  const {
    Question_uuid: questionUUID,
    question_type: questionType,
    answer
  } = currentQuestion

  if (![MULTIPLE_CHOICE, TRUE_FALSE].includes(questionType)) return

  return getMultipleChoiceAnswer({
    questionUUID,
    currentAnswer,
    answer
  })
}

export function filterSortPretestAttempts (pretestAttempts, ids = []) {
  if (!pretestAttempts || !Array.isArray(pretestAttempts) || !ids.length) return

  return pretestAttempts.filter(attempt => {
    const { courseId = '' } = attempt
    return ids.includes(courseId)
  })?.sort((a, b) => {
    const aCreatedAt = new Date(a.Created)
    const bCreatedAt = new Date(b.Created)
    return bCreatedAt - aCreatedAt
  })
}

export const getRecentAttempt = (attempts, courseIds) => {
  const sortedAttempts = filterSortPretestAttempts(attempts, courseIds)
  if (!sortedAttempts?.length) return null

  return sortedAttempts[0]
}

export const hasPretestAccess = (attempts, courseId) => {
  const [testAttempts, overrides] = attempts
  const overridePretest = (overrides || [])
    .some(override => override.courseId === courseId)
  if (overridePretest) return false

  const recentAttempt = getRecentAttempt(testAttempts, courseId)
  if (!recentAttempt) return true

  const { testResult, nextAvailable } = recentAttempt
  if (testResult === pretestResult.PASS) return false

  return new Date(nextAvailable) <= new Date()
}

export const passedPrerequisite = ({
  courseProjectedGrades,
  prerequisite,
  studentCourses,
  minimumPretestScore
}) => {
  if (!prerequisite) return false

  const { uuid: prerequisiteCourseId } = prerequisite
  const prerequisiteAttempt = studentCourses.find(course => {
    return course.id === prerequisiteCourseId
  })
  if (!prerequisiteAttempt) return false

  const latestCohort = getLatestCohort(prerequisiteAttempt)
  const { studentStatus } = latestCohort || {}
  if (studentStatus !== COMPLETED) return false

  const grade = courseProjectedGrades[prerequisiteCourseId]
  return grade >= minimumPretestScore
}

export const hasPretestEligibility = ({
  studentCourses,
  courseProjectedGrades,
  currentCourse,
  attempts
}) => {
  const { pretest, id } = currentCourse
  if (!pretest) return false

  const { pretestExamContent, prerequisite, minimumPretestScore } = pretest
  if (!pretestExamContent?.length) return false

  if (prerequisite && passedPrerequisite({
    studentCourses,
    courseProjectedGrades,
    prerequisite,
    minimumPretestScore
  })) return false

  return hasPretestAccess(attempts, id)
}

export const has3IncorrectAnswers = (questions = [], studentAnswers = {}) => {
  if (!questions) return false

  const answeredQuestions = questions.filter(
    question => !!studentAnswers[question.Question_uuid])

  if (answeredQuestions.length !== 3) return false

  return !answeredQuestions.some(question => {
    const { correct } = studentAnswers[question.Question_uuid]
    return correct
  })
}

export const getScore = (questions = [], studentAnswers = {}) => {
  if (!questions?.length) return 0

  const correctAnswerCount = questions.filter(question => {
    const { correct } = studentAnswers[question.Question_uuid] || {}
    return correct
  }).length

  return correctAnswerCount &&
    Math.round(correctAnswerCount / questions.length * 100)
}

export const canContinuePretest = (questions = [], minimumPretestScore, studentAnswers = {}) => {
  if (!questions) return false

  const score = getScore(questions, studentAnswers)

  if (score >= minimumPretestScore) return true

  const unansweredQuestionsCount = questions.filter(question => {
    return !studentAnswers[question.Question_uuid]
  }).length
  const unansweredScore = unansweredQuestionsCount &&
    Math.round(unansweredQuestionsCount / questions.length * 100)

  const possibleScore = score + unansweredScore

  return possibleScore >= minimumPretestScore
}

export const generateCalendarLink = ({ name, pretestData }) => {
  const config = {
    title: `${name} Pretest`,
    description: `${name} Pretest`,
    start: new Date(pretestData?.nextAvailable)
  }
  return new ICalendar(config).download()
}

export function hasPretestContent (courseData) {
  if (!courseData) return
  return courseData.pretestExamContent?.length > 0
}

export function hasIncompletePretest (recentAttempt) {
  if (!recentAttempt) return false

  return !recentAttempt.testResult
}

export const addPretestDetailsToCourse = async course => {
  if (!course) return null

  const courseWithChapters = await addInfoForCourses([course])
  if (!courseWithChapters?.length) return null

  return courseWithChapters[0]
}

/**
 * @param {Array} studentCourses - array of courses assigned to student
 * @param {Array} outlierCourses - array of outlier courses available for purchase
 * @returns {Object} course info like id, title along with pretest info
 * @description - student assigned courses are already prefilled with pretest info;
 * So we avoid a dato call by using that. If not we look for outlier course array,
 * then enrich it with pretest info from dato endpoint
 */
export const getCourseWithPretestDetails = async (
  studentCourses, outlierCourses
) => {
  const assignedCourse = getCurrentCourse(studentCourses)

  if (assignedCourse) {
    if (isEnrolledCourse(assignedCourse)) return null
    const { chapters, pretest } = assignedCourse
    if (chapters && pretest) return assignedCourse
  }

  const course = getCurrentCourse(outlierCourses)
  const courseWithPretestDetails = await addPretestDetailsToCourse(course)

  return courseWithPretestDetails
}

export function hasStudentPassedPretest (pretestAttempts, ids) {
  const attempts = filterSortPretestAttempts(pretestAttempts, ids)

  if (!attempts?.length ||
    attempts[0]?.testResult === 'fail' ||
    !attempts[0]?.testResult) return

  return true
}

export function getPretestRetakeDate (pretestAttempts, ids) {
  const attempts = filterSortPretestAttempts(pretestAttempts, ids)

  if (!attempts?.length || attempts[0]?.testResult === 'pass' || !attempts[0]?.testResult) return
  const { nextAvailable = '' } = attempts[0]
  return nextAvailable.slice(0, 10)
}
