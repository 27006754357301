import React, { useState, useContext } from 'react'
import {
  DropdownButton,
  FinishedCoursesContainer,
  FinishedCoursesDropdown
} from './style'
import { AppContext } from '../../ContextProvider/ContextProvider'
import { getFinishedCourses } from '../utils'

import FinishedCoursesSlide from './FinishedCoursesSlide'
import { BREAKPOINTS } from '../../../mediaQueries'
import useWindowSize from '../../../hooks/useWindowSize'
import MobileFinishedCourses from './MobileFinishedCourses'

function FinishedCourses () {
  const { courses, catalogCourses, courseProjectedGrades } = useContext(
    AppContext
  )
  const [showDropdown, setShowDropdown] = useState(false)
  const [windowWidth] = useWindowSize()

  const finishedCourses = getFinishedCourses(courses)
  const isLargeScreen = windowWidth > BREAKPOINTS.mobile

  return (
    <FinishedCoursesContainer>
      <DropdownButton onClick={() => setShowDropdown((state) => !state)}>
        {showDropdown ? '– Hide' : '+ Show'} finished courses (
        {finishedCourses.length})
      </DropdownButton>
      <FinishedCoursesDropdown show={showDropdown}>
        {isLargeScreen ? (
          <FinishedCoursesSlide
            finishedCourses={finishedCourses}
            catalogCourses={catalogCourses}
            courseProjectedGrades={courseProjectedGrades}
          />
        ) : (
          <MobileFinishedCourses
            finishedCourses={finishedCourses}
            courseProjectedGrades={courseProjectedGrades}
          />
        )}
      </FinishedCoursesDropdown>
    </FinishedCoursesContainer>
  )
}

FinishedCourses.displayName = 'FinishedCourses'
export default FinishedCourses
