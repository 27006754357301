import React from 'react'
import {
  ContentWrapper,
  MessageCard as StyledMessageCard,
  ExtensionDeadlineLink,
  ExtensionLinkArrowIcon
} from './styles'
import { ChecklistIcon } from '../CourseListCard/styles'

const MessageCard = ({
  testId,
  color,
  backgroundColor,
  iconPath,
  isFirstCard,
  activity
}) => {
  const {
    title,
    getDescription,
    endDateTime,
    endDateTimeAssessments,
    extendDeadlineLink
  } = activity || {}

  if (!backgroundColor) {
    return getDescription?.()
  }

  return (
    <StyledMessageCard
      data-testid={testId}
      marginTop={isFirstCard ? '0px' : '8px'}
      color={color}
      backgroundColor={backgroundColor}
    >
      <ContentWrapper>
        {title ? <h4>{title}</h4> : null}
        {getDescription?.()}
        {endDateTime && <p>{endDateTime}</p>}
        {endDateTimeAssessments &&
          <ul> {endDateTimeAssessments.map((endDateTimeAssessment, index) => (
            <li key={index}>{endDateTimeAssessment.endDateTime}</li>
          ))}
          </ul>}
        {extendDeadlineLink &&
          <ExtensionDeadlineLink href={extendDeadlineLink}>
            <a href={extendDeadlineLink}>Extend Deadline</a>
            <ExtensionLinkArrowIcon src='images/icons/arrow.svg' />
          </ExtensionDeadlineLink>}
      </ContentWrapper>
      {iconPath && (
        <ChecklistIcon
          data-testid={`${testId}_checklist_icon`}
          width='32px'
          height='32px'
          color={color}
          marginRight='0px'
          src={iconPath}
        />
      )}
    </StyledMessageCard>
  )
}

MessageCard.displayName = 'MessageCard'
export default MessageCard
