import React from 'react'
import SlotOptions from './SlotOptions'
import {
  CourseSlotContainer,
  CourseImageContainer,
  CourseMainContainer,
  CourseNameContainer,
  CourseIcon
} from './styles'
import { getCourseAssets } from '../../utilities/course'

const CourseSlot = ({
  index,
  course,
  semesterStart,
  setShouldReloadPage,
  noOfCoursesInSlots,
  catalogCourses,
  advisorApproved
}) => {
  const { courseId, courseNameText } = course || {}
  const { courseIcon } = getCourseAssets(catalogCourses, courseId)

  return (
    <CourseSlotContainer data-testid='course-slot'>
      <CourseImageContainer>
        {courseIcon &&
          <CourseIcon src={courseIcon} className='course-icon' data-testid='course-icon' />}
      </CourseImageContainer>
      <CourseMainContainer>
        <CourseNameContainer>{courseNameText}</CourseNameContainer>
        <div>
          Starts {semesterStart}
        </div>
      </CourseMainContainer>
      <SlotOptions
        course={course}
        index={index}
        setShouldReloadPage={setShouldReloadPage}
        noOfCoursesInSlots={noOfCoursesInSlots}
        advisorApproved={advisorApproved}
      />
    </CourseSlotContainer>
  )
}

export default CourseSlot
