import React from 'react'
import {
  HeaderContents,
  HeaderBreadCrumb,
  HeaderContainer,
  CloseSideDrawerIcon
} from './styles'
import {
  ProgressBarContainer,
  ProgressBarBackground,
  ProgressBar
} from '../CourseListCard/styles'
import { getProgressBarColor } from './ChecklistHeaderUtils'

const ChecklistHeader = ({
  removeProgressBar,
  isCourseDetailSection,
  toggleCheckList,
  onClick,
  progress,
  isCaughtUp
}) => {
  const color = getProgressBarColor(isCaughtUp)
  return (
    <HeaderContainer borderSeparated={removeProgressBar}>
      <HeaderContents>
        <HeaderBreadCrumb
          showCaret={isCourseDetailSection}
          onClick={onClick}
        >
          {isCourseDetailSection ? 'Courses' : 'Activities'}
        </HeaderBreadCrumb>
        <CloseSideDrawerIcon
          onClick={() => toggleCheckList(false)}
          alt='checklist'
          src='images/icons/icon-side-drawer-cross.svg'
        />
      </HeaderContents>
      {!removeProgressBar && (
        <ProgressBarContainer noMargin>
          <ProgressBarBackground />
          <ProgressBar percent={progress} color={color} />
        </ProgressBarContainer>
      )}
    </HeaderContainer>
  )
}

ChecklistHeader.displayName = 'ChecklistHeader'
export default ChecklistHeader
