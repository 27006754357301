import React, { useEffect } from 'react'
import api from '../../api'
import PropTypes from 'prop-types'
import { courseHomeUrls } from './data'
import { segmentEventUrl, MAIN_PAGE } from './constants'
import {
  COURSE_HOME_CLICK_THROUGH, COURSE_PAGE_VIEWED
} from '../../Constants/eventType'
import { AUDIT, COMPLETED, ENROLLED, SIGNED_UP } from '../../Constants/studentStatus'
import BackwardIcon from '../../assets/icons/back-arrow-caret.svg'
import { getCourseDateStatement, getCourseIcon } from './utils'
import {
  BackwardLink, GlobalStyle, MainTitle, PageContentWrapper, ViewButton
} from './style'
import config from '../../config'

function CoursePage ({ currentCourse, setCurrentPage }) {
  const {
    id: courseId, name, displayName, cohort, cohort: { studentStatus },
    grades: { projectedGrade } = {}
  } = currentCourse

  useEffect(() => {
    api.newSubmitTrackedEvent({
      event: COURSE_PAGE_VIEWED,
      props: {
        title: displayName || name
      }
    }, segmentEventUrl)
  }, [displayName, name])

  const iconStyle = { width: '120px', height: '132px' }
  const mainTitleStyle = { margin: '16px 0 24px' }

  const isAuditCourse = studentStatus === AUDIT
  const isEnrolledCourse = [ENROLLED, SIGNED_UP].includes(studentStatus)
  const isCompletedCourse = studentStatus === COMPLETED
  const isEnrolledOrCompleted = isEnrolledCourse || isCompletedCourse

  const dateStatement = getCourseDateStatement(cohort, studentStatus)
  const dateStatementColor = isEnrolledCourse ? '#fff' : '#969BFF'

  const catalogUrl = `${config.courseBaseUrl}#/catalog/${courseId}`
  const courseHomeUrl = courseHomeUrls[courseId]

  const getViewButtonText = () => {
    if (isEnrolledOrCompleted) return 'course home'
    if (isAuditCourse) return 'take for credit'
    return 'enroll'
  }

  const onViewButtonClick = () => {
    if (isEnrolledOrCompleted) {
      api.newSubmitTrackedEvent({
        event: COURSE_HOME_CLICK_THROUGH,
        props: {
          courseName: displayName || name
        }
      }, segmentEventUrl)
    }

    const link = isEnrolledOrCompleted ? courseHomeUrl : catalogUrl
    window.open(link, '_blank')
  }

  return (
    <>
      <GlobalStyle notEnrolled={!isEnrolledOrCompleted} />

      <BackwardLink onClick={() => setCurrentPage(MAIN_PAGE)}>
        <img src={BackwardIcon} alt='backward-button' />
        <p>academic achievements</p>
      </BackwardLink>

      <PageContentWrapper
        color={dateStatementColor}
      >
        {getCourseIcon(courseId, studentStatus, iconStyle)}

        <MainTitle style={mainTitleStyle}>
          {displayName || name}
        </MainTitle>

        {dateStatement && (
          <p style={{ marginBottom: isCompletedCourse ? '12px' : '24px' }}>
            {isEnrolledCourse ? 'Enrolled ' : ''}{dateStatement}
          </p>
        )}

        {isCompletedCourse && (
          <p style={{ marginBottom: '24px' }}>
            Grade: {projectedGrade}
          </p>
        )}

        {!isEnrolledOrCompleted && (
          <>
            <p id='not-enrolled-text'>
              {isAuditCourse
                ? 'You’re currently auditing this course.'
                : 'You’re not enrolled in this course.'}
            </p>

            <p id='not-enrolled-sub-text'>
              {isAuditCourse
                ? 'To earn this badge, you must enroll in and pass this course for credit.'
                : 'To earn this badge, you must enroll in and pass this course.'}
            </p>
          </>
        )}

        <ViewButton onClick={onViewButtonClick}>
          {getViewButtonText()}
        </ViewButton>
      </PageContentWrapper>
    </>
  )
}

CoursePage.displayName = 'CoursePage'
CoursePage.propTypes = {
  currentCourse: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired
}

export default CoursePage
