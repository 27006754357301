import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { allCoursesData } from './data'
import { COMPLETED, ENROLLED, SIGNED_UP } from '../../Constants/studentStatus'
import { AppContext } from '../ContextProvider/ContextProvider'
import { CERTIFICATE_PAGE, COURSE_PAGE } from './constants'
import {
  getCertificateIcon, getCertificateStatus, getCourseIcon, getStatusColor,
  getNoOfStatusCourses, getFilteredCertificates, getOrderedCertificates,
  getOrderedCourses, getAdjustedCourses, getAllCourses, getNoOfCourses
} from './utils'
import {
  MainTitle, SectionContainer, SectionTitle,
  CertificateContainer, CourseNameWrapper, CourseName, CourseContainer
} from './style'

const CertificateBadge = ({ certificate, adjustedCourses, setCurrentPage, setCurrentCertificate }) => {
  const electiveCourse = []
  const courseCohorts = adjustedCourses.reduce(
    (acc, val) => ({ ...acc, [val.id]: val.cohort }), {})
  const certificateCourses = certificate.courses.map(course => ({
    ...course,
    ...(courseCohorts[course.id] && { cohort: courseCohorts[course.id] })
  }))
  const orderedCourses = getOrderedCourses(certificateCourses)
  certificate.courses = orderedCourses

  const {
    certificateName, courses = []
  } = certificate || {}

  const certificateStatus = getCertificateStatus(certificate)
  const certificateColor = getStatusColor(certificateStatus)

  const iconStyle = { width: '120px', height: '132px' }
  const noOfCourses = getNoOfCourses(courses)
  const noOfCompletedCourses = getNoOfStatusCourses(courses, COMPLETED)

  const onCertificateClick = () => {
    setCurrentPage(CERTIFICATE_PAGE)
    setCurrentCertificate(certificate)
  }

  return (
    <CertificateContainer
      color={certificateColor}
      onClick={onCertificateClick}
    >
      {getCertificateIcon({
        certificateStatus, noOfCourses, noOfCompletedCourses, style: iconStyle
      })}

      <h3>{certificateName}</h3>

      <div id='courses-wrapper'>
        {courses?.map(({ name: courseName, cohort, courseType, courseCategory }) => {
          const isElective = courseType === 'elective'
          const isEnrolled = [ENROLLED, SIGNED_UP].includes(cohort?.studentStatus)
          const categoryName = `${courseCategory} Elective`
          if (electiveCourse.includes(courseCategory) && !isEnrolled) return
          isElective && electiveCourse.push(courseCategory)
          const courseNameDisplayed = (!isElective || isEnrolled) ? courseName : categoryName
          const courseColor = getStatusColor(cohort?.studentStatus)

          return (
            <CourseNameWrapper
              color={courseColor}
              key={`${certificateName}-${courseName}`}
            >
              <span />
              <CourseName color={courseColor}>
                {courseNameDisplayed}
              </CourseName>
            </CourseNameWrapper>
          )
        })}
      </div>
    </CertificateContainer>
  )
}

const CourseBadge = ({ course, setCurrentPage, setCurrentCourse }) => {
  const { id, name, displayName, cohort: { studentStatus } } = course || {}
  const courseColor = getStatusColor(studentStatus)

  const onBadgeClick = () => {
    setCurrentCourse(course)
    setCurrentPage(COURSE_PAGE)
  }

  return (
    <CourseContainer
      color={courseColor}
      onClick={onBadgeClick}
    >
      {getCourseIcon(id, studentStatus)}

      <div id='course-name'>
        <p>{displayName || name}</p>
      </div>
    </CourseContainer>
  )
}

function MainPage (props) {
  const {
    certificates: allCertificates, courses, courseProjectedGrades
  } = useContext(AppContext)

  const {
    setCurrentPage, setCurrentCourse, setCurrentCertificate
  } = props

  const certificates = getFilteredCertificates(allCertificates)
  const orderedCertificates = getOrderedCertificates(certificates)

  const adjustedCourses = getAdjustedCourses(courses, courseProjectedGrades)
  const orderedCourses = getOrderedCourses(adjustedCourses)
  const allCourses = getAllCourses(orderedCourses, allCoursesData)

  return (
    <>
      <MainTitle border style={{ paddingBottom: '32px' }}>
        Academic achievements
      </MainTitle>

      <SectionTitle>Certificates</SectionTitle>
      <SectionContainer gap={24}>
        {orderedCertificates?.map(certificate => (
          <CertificateBadge
            certificate={certificate}
            key={certificate.certificateId}
            adjustedCourses={adjustedCourses}
            setCurrentPage={setCurrentPage}
            setCurrentCertificate={setCurrentCertificate}
          />
        ))}
      </SectionContainer>

      <SectionTitle style={{ margin: '31px 0 13px' }}>
        Courses
      </SectionTitle>
      <SectionContainer gap={22}>
        {allCourses?.map(course => (
          <CourseBadge
            course={course}
            key={course.name}
            setCurrentPage={setCurrentPage}
            setCurrentCourse={setCurrentCourse}
          />
        ))}
      </SectionContainer>
    </>
  )
}

MainPage.displayName = 'MainPage'
MainPage.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  setCurrentCourse: PropTypes.func.isRequired,
  setCurrentCertificate: PropTypes.func.isRequired
}

export default MainPage
