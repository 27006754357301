import React, { useEffect, useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import {
  WorkingAhead,
  WhiteLink,
  StyledP,
  CollapseWrapper,
  CollapseToggleWrapper,
  CollapseText
} from './styles'
import { ChecklistIcon } from '../CourseListCard/styles'
import {
  RED,
  VIOLET,
  YELLOW_MESSAGE_CARD
} from '../checklistColors'
import MessageCard from './MessageCard'

const ActivitiesMessageCard = ({
  courseId,
  weekProgress,
  hasWorkedAheadAndNotCaughtUp,
  hasAssessmentAfterTermBreak,
  assessmentAfterTermBreakType,
  hasWorkedAheadUntilExam,
  isOneWeekBehindRecommendedActivities,
  isTwoWeeksBehindRecommendedActivities,
  isWorkingAhead,
  isCaughtUp,
  twoWeeksActivitiesClearedButNotOneWeek,
  allPastActivitiesCleared,
  assessments = []
}) => {
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    return () => {
      const localStorageKey = `${courseId}_hasWorkedAhead`
      hasWorkedAheadAndNotCaughtUp &&
      localStorage.removeItem(localStorageKey)
    }
  }, [hasWorkedAheadAndNotCaughtUp, courseId])

  const getActivityMessage = () => {
    if (hasWorkedAheadAndNotCaughtUp) {
      return {
        testId: 'caughtUpCard',
        color: '#fff',
        backgroundColor: '#25272B',
        activity: {
          getDescription: () => {
            return (
              <p>
                {'You’re ahead of schedule! You’ve crossed off '}
                <span>{weekProgress} activities</span> from this week’s list.
              </p>
            )
          }
        }
      }
    }

    if (isCaughtUp) {
      let cardTitle = 'You’re all caught up!'
      let cardDescription = hasAssessmentAfterTermBreak
        ? `You have an ${assessmentAfterTermBreakType} following the term break. We suggest you review previous chapters.`
        : 'We added next week’s activities below, if you want to get ahead.'

      if (hasWorkedAheadUntilExam) {
        cardTitle = 'Wow, you’re way ahead!'
        cardDescription = 'The next set of activities will unlock after the exam.'
      }

      return {
        testId: 'caughtUpCard',
        color: VIOLET,
        backgroundColor: '#191631',
        iconPath: 'images/icons/rocket.svg',
        activity: {
          title: cardTitle,
          getDescription: () => {
            return (
              <>
                <p>
                  {cardDescription}
                </p>
                {isWorkingAhead > 0 && (
                  <WorkingAhead>
                    {`Extra activities completed: ${isWorkingAhead}`}
                  </WorkingAhead>
                )}
              </>
            )
          }
        }
      }
    }

    if (allPastActivitiesCleared) {
      return {
        activity: {
          getDescription: () => {
            return (
              <StyledP data-testid='all-past-activities-cleared'>
                Nice work! You've completed all past activities
                and are now on track.
              </StyledP>
            )
          }
        }
      }
    }

    if (isTwoWeeksBehindRecommendedActivities) {
      return {
        testId: 'two-weeks-behind-card',
        color: RED,
        backgroundColor: '#2E141C',
        iconPath: 'images/icons/icon-alert-exclamation.svg',
        activity: {
          title: 'Your list has gotten long.',
          getDescription: () => {
            return (
              <p>
                Start working through it, and{' '}
                <WhiteLink href='mailto:success@outlier.org'>
                  contact us
                </WhiteLink> if you need help.
              </p>
            )
          }
        }
      }
    }

    if (twoWeeksActivitiesClearedButNotOneWeek) {
      return {
        testId: 'two-weeks-activities-cleared-but-not-one-week',
        color: YELLOW_MESSAGE_CARD,
        backgroundColor: '#342215',
        iconPath: 'images/icons/icon-alert-exclamation.svg',
        activity: {
          title: 'Your list is still a little long.',
          getDescription: () => {
            return (
              <p>
                Great work clearing out the red!
                Keep up the good work.
              </p>
            )
          }
        }
      }
    }

    if (isOneWeekBehindRecommendedActivities) {
      return {
        testId: 'one-week-behind-card',
        color: YELLOW_MESSAGE_CARD,
        backgroundColor: '#342215',
        iconPath: 'images/icons/icon-alert-exclamation.svg',
        activity: {
          title: 'Your list is longer than usual.',
          getDescription: () => {
            return (
              <p>
                Expect to spend a little extra time on coursework this week.
              </p>
            )
          }
        }
      }
    }
  }

  const getAssessmentMessages = () => {
    if (!assessments?.length) return []

    return assessments.map((assessment) => {
      return {
        testId: 'assessmentCard',
        color: assessment.assessmentWarningColor,
        backgroundColor: assessment.backgroundColor,
        iconPath: 'images/icons/calendar.svg',
        activity: {
          title: assessment.cardTitle,
          getDescription: () => {
            return <p>{assessment.cardDescription}</p>
          },
          endDateTime: assessment.endDateTime,
          endDateTimeAssessments: assessment.endDateTimeAssessments,
          extendDeadlineLink: assessment.extendDeadlineLink
        }
      }
    })
  }

  const messages = [getActivityMessage(), ...getAssessmentMessages()].filter(Boolean)
  const messagesWithIcons = messages.filter((message) => message.iconPath)

  const showCollapseBackground = messages.length > 1 && isOpen

  return (
    <CollapseWrapper open={showCollapseBackground}>
      <Collapse
        in={isOpen}
      >
        <div>
          {messages.map((message, index) => {
            return <MessageCard key={index} {...message} isFirstCard={index === 0} />
          })}
        </div>
      </Collapse>
      {messages.length > 1 && (
        <CollapseToggleWrapper open={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <div style={{ display: 'flex' }}>
            <img
              src={isOpen ? '/images/icons/arrow-up.svg' : '/images/icons/arrow-down.svg'}
              alt='arrow-down'
            />
            <CollapseText>
              {isOpen ? 'Hide Messages' : 'Show Messages'}
            </CollapseText>
          </div>
          {!isOpen && (
            <div style={{ display: 'flex' }}>
              {messagesWithIcons.map((message, index) => (
                <div
                  key={index}
                  style={{
                    marginRight: index === messagesWithIcons.length - 1 ? '0px' : '8px'
                  }}
                >
                  <ChecklistIcon
                    width='16px'
                    height='16px'
                    color={message.color}
                    marginRight='0px'
                    src={message.iconPath}
                  />
                </div>
              ))}
            </div>
          )}
        </CollapseToggleWrapper>
      )}
    </CollapseWrapper>
  )
}

ActivitiesMessageCard.displayName = 'ActivitiesMessageCard'
export default ActivitiesMessageCard
