import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useEffect
} from 'react'
import {
  ChecklistContainer,
  ChecklistWrapper,
  GlobalStyle,
  ChecklistBody
} from './styles'
import { useDetectOutsideRefEvent } from './useDetectOutsideRefEvent'
import ChecklistHeader from './ChecklistHeader/ChecklistHeader'
import ChecklistIntro from '../Checklist/ChecklistIntro/ChecklistIntro'
import NoCourse from './NoCourse/NoCourse'
import CourseList from './CourseList/CourseList'
import CourseActivities from './CourseActivities/CourseActivities'
import { getLatestCohort } from '../../utilities/course'
import {
  dateToSecondsSinceEpoch,
  secondsSinceEpoch
} from '../../utilities/dateTime'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { AppContext } from '../ContextProvider/ContextProvider'
import { CHECKLIST_INTRO_VIEWED } from '../../Constants/studentProgressKeys'
import api from '../../api'

const Checklist = ({
  toggleCheckList
}) => {
  const [isSavingChecklistIntro, setIsSavingChecklistIntro] = useState(false)
  const [showChecklistIntro, setShowChecklistIntro] = useState(false)
  const {
    courses: studentCourses,
    studentData,
    isContextLoading,
    isActiveGGUStudent,
    isGGUDegreeDataLoading
  } = useContext(AppContext) || {}

  const drawerRef = useRef(null)
  useDetectOutsideRefEvent(drawerRef, () => {
    toggleCheckList(false)
  })
  const isMultiCourse = studentCourses?.length > 1
  const hasCourses = studentCourses?.length
  const [courseDetail, setCourseDetail] = useState({})

  const onGoBack = () => {
    if (courseDetail) {
      setCourseDetail({})
    }
  }

  const onCourseClick = useCallback((course) => {
    setCourseDetail(course)
  }, [])

  useEffect(() => {
    if (!studentData) return

    setShowChecklistIntro(!studentData[CHECKLIST_INTRO_VIEWED])
  }, [studentData])

  const setViewChecklist = async () => {
    setIsSavingChecklistIntro(true)
    const data = await api.setStudentData(
      CHECKLIST_INTRO_VIEWED, { [CHECKLIST_INTRO_VIEWED]: true }
    )
    if (data?.success) {
      setShowChecklistIntro(false)
    }
    setIsSavingChecklistIntro(false)
  }

  const renderChecklistBody = (hasCohortStarted) => {
    if (!studentData || isSavingChecklistIntro) return <LoadingSpinner />

    if (
      !studentCourses || isContextLoading || (isActiveGGUStudent && isGGUDegreeDataLoading)
    ) return <LoadingSpinner />

    if (showChecklistIntro) {
      return <ChecklistIntro setViewChecklist={setViewChecklist} />
    }

    if (studentCourses?.length === 0) return <NoCourse />

    if (courseDetail?.id) {
      return (
        <CourseActivities
          isFromCourseDetail
          course={courseDetail}
          toggleCheckList={toggleCheckList}
          hasCohortStarted={hasCohortStarted}
          isGGUCohort={isGGUSelectedCohort}
        />
      )
    }

    if (hasCourses) {
      return (
        <CourseList
          courses={studentCourses}
          onClick={onCourseClick}
          toggleCheckList={toggleCheckList}
        />
      )
    }

    return (
      <h6
        data-testid='checklistBody'
        style={{
          width: '100%',
          marginTop: '20px',
          textAlign: 'center'
        }}
      >
        Checklist Body
      </h6>
    )
  }

  const latestCohort = getLatestCohort(courseDetail)
  const { dateStart, name } = latestCohort || {}
  const isGGUSelectedCohort = name?.toLowerCase().includes('ggu')
  const currentDate = secondsSinceEpoch()
  const hasCohortStarted =
    dateToSecondsSinceEpoch(new Date(dateStart)) <= currentDate
  const isCourseDetailSection =
    isMultiCourse && !!courseDetail.id && !courseDetail.singleCourse
  const progress =
    isCourseDetailSection ? courseDetail.progress : courseDetail.fullCourseProgress
  const { isCaughtUp } = courseDetail
  return (
    <>
      <GlobalStyle />
      <ChecklistWrapper>
        <ChecklistContainer
          data-testid='checklist-container'
          ref={drawerRef}
        >
          <ChecklistHeader
            removeProgressBar={showChecklistIntro}
            isCourseDetailSection={isCourseDetailSection}
            toggleCheckList={toggleCheckList}
            onClick={onGoBack}
            progress={hasCohortStarted ? progress : 0}
            isCaughtUp={isCaughtUp}
          />
          <ChecklistBody>
            {renderChecklistBody(hasCohortStarted, isGGUSelectedCohort)}
          </ChecklistBody>
        </ChecklistContainer>
      </ChecklistWrapper>
    </>
  )
}

Checklist.displayName = 'Checklist'
export default Checklist
