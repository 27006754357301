import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const LargeHeroContainer = styled.div`
  margin-bottom: 64px;
  :hover {
    .splide__arrow--prev,
    .splide__arrow--next {
      display: block;
    }
  }
`
export const SmallHeroContainer = styled.div`
  margin-bottom: 48px;
  & .splide__track {
    margin: 0 calc(var(--mobile-layout-padding) * -1);
  }
`
export const SlideContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 610px;
  padding: 65px;
  background-image: ${({ heroImage }) => `url(${heroImage})`};
  background-repeat: no-repeat;
  background-position: right;
`
export const MobileSlideContainer = styled.div`
  position: relative;
  padding: 16px;
  margin: 24px 0;
  border-radius: 4px;
  height: 360px;
  background-image: linear-gradient(0deg, #000000 20%, rgba(0, 0, 0, 0.01) 75%),
    ${({ courseImage }) => `url(${courseImage})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`
export const Gradient = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 60%, rgb(28, 29, 33) 99%),
    linear-gradient(to right, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0) 70%);
`
export const MobileGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.0001) 100%);
`
export const WelcomeMessage = styled.p`
  position: relative;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;

  ${mediaqueries.mobile`
    font-size: 14px;
    line-height: 17px;
  `}
`
export const CourseDetails = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;

  ${mediaqueries.mobile`
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    justify-content: revert;
    height: revert;
  `}
`
export const CourseIcon = styled.span`
  margin-bottom: 40px;
  width: 44px;
  height: 48px;
  background-color: #5fc4b8;
  mask: ${({ courseIcon }) => `url(${courseIcon}) no-repeat 1px 0`};

  ${mediaqueries.mobile`
    display: inline-block;
    height: 40px;
    margin-bottom: 0;
  `}
`
export const CourseTitle = styled.h1`
  color: #fff;
  font-size: 60px;
  font-weight: 300;
  line-height: 66px;
  margin: 0;

  ${mediaqueries.mobile`
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 16px;
  `}
`
export const ChangeCourseContainer = styled.div`
  & .splide__track {
    clip-path: fill-box;
  }

  & .splide__slide {
    padding: 24px 0;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    border: none;
    border-top: 4px solid transparent;
    opacity: 0.65;

    :active {
      opacity: 0.3;
    }

    :focus {
      outline: none;
    }
    :focus-visible {
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  & .splide__slide.is-active {
    opacity: 1;
    border-color: #fff;
  }
`
export const SlidePaginationWrapper = styled.ul`
  gap: 4px;
  position: absolute;
  bottom: 64px;
  right: 64px;
  z-index: 1;
  max-width: 490px;

  ${mediaqueries.mobile`
    position: revert;
    padding: 0;
    margin: 0 auto;
  `}

  & .splide__pagination__page {
    padding: 0;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: none;
    background-color: #333234;

    ${mediaqueries.mobile`
      background-color: #555558;
      height: 8px;
      width: 8px;
    `}

    :focus {
      outline: none;
    }
    :focus-visible {
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
  & .splide__pagination__page:not(:last-of-type) {
    margin-right: 4px;
  }
  & .splide__pagination__page.is-active {
    background-color: #fff;
  }
`
export const Separator = styled.hr`
  border: 0.5px solid rgba(102, 102, 102, 0.5);
  margin: 0 64px;
`
export const RecommnededCourseTag = styled.div`
  position: absolute;
  top: 60px;
  right: 65px;
  padding: 6px 12px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 2px;
  text-transform: uppercase;

  ${mediaqueries.mobile`
    top: 16px;
    right: 16px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    padding: 6px 8px;
  `}
`
