import styled from 'styled-components'
import mediaqueries from '../../../../../mediaQueries'
import { CTAButton as Button } from '../LargePopup/styles'
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close-icon.svg'

export const SmallPopupContainer = styled.div`
  position: fixed;
  top: 80px;
  right: 16px;
  padding: 21px;
  background: #25272b;
  border-radius: 5px;
  max-width: 320px;
  ${mediaqueries.mobile`
    width: 256px;
  `}
`
export const PopupTitle = styled.h5`
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
  gap: 8px;
  ${mediaqueries.mobile`
    font-size: 16px;
    line-height: 26px;
  `}
`
export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 27px;
  ${mediaqueries.mobile`
    gap: 25px;
  `}
`
export const CTAButton = styled(Button)`
  width: 184px;
  ${mediaqueries.mobile`
    width: 144px;
  `}
`
export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 4px;
  display: flex;
  flex-shrink: 0;
`
export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  gap: 8px;
  ${mediaqueries.mobile`
    margin-bottom: 16px;
  `}
`
