import React, { useState, useEffect, useContext, useMemo, useRef } from 'react'
import classNames from 'classnames'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import api from '../../api'
import {
  getDateStringWithYearLongMonth
} from '../../utilities/dateTime'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  getRecommendedCohorts as getRecommendedCohortsFunction,
  getRecommendedCoursesFromLS,
  setRecommendedCoursesInLS,
  removeRecommendedCoursesInLS,
  hasCompltedCourseFromGroup,
  getRecommendedCourses,
  isSegmentEventInLS,
  setFiredSegmentEventsInLS,
  removeSegmentEventFromLS,
  updateVWOGoalConversion,
  getCurrentProspect
} from '../../utilities/gguDegreeUtils'
import CourseSlot from './CourseSlot'
import GroupedCourseSlot from './GroupedCourseSlot'
import WarningSlots from './WarningSlots'
import {
  RECOMMENDED_COURSES_BUTTON_STATE_IDENTIFIER,
  FULL_TIME_SLOT,
  PART_TIME_SLOT,
  DEGREE_PLAN_WORKSHEET_LINK,
  DEGREE_PLAN_WORKSHEETS
} from '../../Constants/gguDegrees'
import {
  PageTitle,
  RecommendedPageWrapper,
  SectionTitle,
  SemesterTitle,
  CourseSlotWrapper,
  ScheduleCallButton,
  Instructions,
  GroupHeaderMessageContainer,
  GroupHeaderMessage
} from './styles'
import { MULTIPLE } from '../../Constants/frequency'
import {
  COURSE_REGISTRATION_PAGEVIEW,
  COURSE_REGISTRATION_PAGE_SUBMITTED,
  REGISTERED_FOR_COURSES
} from '../../Constants/eventType'
import {
  DONE_COURSE_SELECTIONS,
  PLEASE_WAIT_FOR_ACADEMIC_ADVISOR,
  SCHEDULE_ADVISOR_MEETING,
  SUBMIT_FOR_REVIEW,
  SCHEDULE_MEETING
} from './contstants'
import ReturnToDashboard from './ReturnToDashboard'
import useLocalStorage from '../../hooks/localStorageHook/localStorageHook'
import { DEGREE_REGISTRATION_INCOMPLETE } from '../../Constants'

const RecommendedCourses = ({ setIsLoading, showReturnToDashboard }) => {
  const { sendEvent } = useSegmentEvents()
  const originalRecommendedCourses = useRef([])
  const [shouldReloadPage, setShouldReloadPage] = useState(false)
  const [recommendedCourses, setRecommendedCourses] = useState([])

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: '4-1'
  }

  const {
    degreeData,
    gguAdvisorApprovalRequired,
    outlierCourses,
    studentAttempts,
    catalogCourses,
    prospects,
    currentSemester,
    studentData,
    courseProjectedGrades,
    updateContext,
    reloadContext
  } = useContext(AppContext)

  const { transfers, fullTime, partTime } = gguAdvisorApprovalRequired || {}

  const currentProspect = useMemo(() => {
    return getCurrentProspect(prospects)
    // eslint-disable-next-line
  }, [prospects])

  const {
    advisorUrl,
    prospectId,
    studyMode,
    transferredCredit,
    transferredPrerequisite,
    degree,
    advisorApproved
  } = currentProspect || {}

  const buttonStateLSValue = currentSemester ? `review ${currentSemester.term}` : ''

  const [buttonState, setButtonState] = useLocalStorage({
    identifier: `${RECOMMENDED_COURSES_BUTTON_STATE_IDENTIFIER}_${prospectId}`
  })

  const isInReviewState = useMemo(() => {
    if (!buttonStateLSValue) return false
    return buttonState === buttonStateLSValue
  }, [buttonState, buttonStateLSValue])

  const requireAdvisorApproval =
    (transfers && transferredCredit?.length) ||
    (transfers && transferredPrerequisite?.length) ||
    (fullTime && studyMode === 'Full-time') ||
    (partTime && studyMode === 'Part-time')

  const noOfSlots = studyMode === 'Full-time' ? FULL_TIME_SLOT : PART_TIME_SLOT
  useEffect(() => {
    // This enables enrollment confirmation page to be displayed after successful enrollment
    localStorage.removeItem('enrollmentConfirmed')
  }, [])

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'Recommended Courses',
        page_number: '4-1',
        time_stamp: Date.now()
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const recommendedCoursesOnLS = getRecommendedCoursesFromLS()
    let recommendedCourses

    originalRecommendedCourses.current = getRecommendedCourses({
      ignoreLS: true,
      outlierCourses,
      catalogCourses,
      degreeData,
      studentAttempts,
      prospects,
      noOfSlots
    })

    if (recommendedCoursesOnLS) {
      const recommendedCoursesIds = recommendedCoursesOnLS
        .map((course) => course.courseId)

      recommendedCourses = degreeData?.filter((course) => (
        recommendedCoursesIds.includes(course.courseId)
      )) || []

      return setRecommendedCourses(recommendedCourses)
    }

    recommendedCourses = getRecommendedCourses({
      outlierCourses,
      catalogCourses,
      degreeData,
      studentAttempts,
      prospects,
      noOfSlots
    })

    setRecommendedCoursesInLS(
      recommendedCourses.map(course => course.courseId)
    )

    setRecommendedCourses(recommendedCourses)
    // eslint-disable-next-line
  }, [shouldReloadPage])

  const getRecommendedCohorts = () => {
    const recommendedCourses = getRecommendedCoursesFromLS()
    const cohorts = getRecommendedCohortsFunction({
      recommendedCourses,
      outlierCourses,
      semesterStart
    })
    return cohorts
  }

  const getEventForRegisteredCourses = () => {
    const { degree, program, term } = currentProspect

    return {
      event: REGISTERED_FOR_COURSES,
      properties: {
        program: program || '',
        degree: degree || '',
        cohort: term || '',
        timeCommitment: studyMode,
        registeredCourse1: recommendedCourses[0]?.courseName || '',
        registeredCourse2: recommendedCourses[1]?.courseName || '',
        registeredCourse3: recommendedCourses[2]?.courseName || '',
        timestamp: Date.now()
      },
      frequency: MULTIPLE
    }
  }

  const handleClick = async () => {
    if (advisorApproved) return
    if (isInReviewState) {
      window.open(advisorUrl, '_blank', 'noopener,noreferrer')
    }

    if (!isInReviewState) {
      setButtonState(buttonStateLSValue)
    }
    setIsLoading(true)

    const dataEvent = getEventForRegisteredCourses()

    await Promise.all([
      dataEvent && api.submitTrackedEvent(dataEvent),
      api.updateProspectData(prospectId, {
        callCTAclicked:
          isInReviewState
            ? new Date().toISOString()
            : undefined,
        tempCart: getRecommendedCohorts()
      }),
      isInReviewState && api.setStudentData(
        DEGREE_REGISTRATION_INCOMPLETE,
        { [DEGREE_REGISTRATION_INCOMPLETE]: false }
      )
    ])
    const originalCourses = originalRecommendedCourses?.current || []
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
      properties: {
        page_name: 'Recommended Courses',
        page_number: '4-1',
        recommended_course1: originalCourses[0]?.courseName || '',
        recommended_course2: originalCourses[1]?.courseName || '',
        recommended_course3: originalCourses[2]?.courseName || '',
        registeredCourse1: recommendedCourses[0]?.courseName || '',
        registeredCourse2: recommendedCourses[1]?.courseName || '',
        registeredCourse3: recommendedCourses[2]?.courseName || ''
      }
    })
    const newProspects = await api.getProspectsData()

    updateContext({
      prospects: newProspects,
      studentData: {
        ...studentData,
        [DEGREE_REGISTRATION_INCOMPLETE]: false
      }
    })
    setIsLoading(false)

    if (isInReviewState) return removeSegmentEventFromLS(segmentEvent)

    updateVWOGoalConversion()
  }

  const updateTempCart = () => {
    if (!isInReviewState) return

    api.updateProspectData(prospectId, {
      tempCart: getRecommendedCohorts()
    })
  }

  const handleEnroll = async () => {
    setIsLoading(true)
    const cohortIds = getRecommendedCohorts()
    await api.enrollGGUStudents(prospectId, cohortIds)
    setIsLoading(false)
    removeRecommendedCoursesInLS(true)
    await api.setStudentData(
      DEGREE_REGISTRATION_INCOMPLETE,
      { [DEGREE_REGISTRATION_INCOMPLETE]: false }
    )

    updateVWOGoalConversion()
    reloadContext()
  }

  const worksheetLink = DEGREE_PLAN_WORKSHEETS[degree] || DEGREE_PLAN_WORKSHEET_LINK

  const getInstructions = (requireAdvisorApproval, isInReviewState) => {
    if (!requireAdvisorApproval) {
      return (
        <div data-testid='instructions'>
          <Instructions>
          Once you’ve reviewed your schedule, confirm your courses and get started! If you would like some help, you can reach out to  an Enrollment Counselor{' '}
            <a href='mailto:advising@outlier.org'>
            here
            </a>.
          </Instructions>
          <Instructions>
          You can also check out our{' '}
            <a
              href={worksheetLink}
              target='_blank'
              rel='noopener noreferrer'
            >
            Degree Plan Worksheets
            </a>
            {' '}to help make decisions about future semesters.
          </Instructions>
        </div>
      )
    }
    if (isInReviewState) {
      return (
        <Instructions data-testid='instructions'>
          {<span>{PLEASE_WAIT_FOR_ACADEMIC_ADVISOR} <br /> {SCHEDULE_MEETING}</span>}
        </Instructions>
      )
    }
    return (
      <div data-testid='instructions'>
        <Instructions>
          {DONE_COURSE_SELECTIONS}
        </Instructions>
        <Instructions>
        You can also check out our{' '}
          <a
            href={worksheetLink}
            target='_blank'
            rel='noopener noreferrer'
          >
          Degree Plan Worksheets
          </a>
          {' '}to help make decisions about future semesters.
        </Instructions>
      </div>
    )
  }

  if (!currentSemester) return null

  const closeEnrollment = getDateStringWithYearLongMonth(currentSemester?.closeEnrollment, 'short')
  const semesterStart = getDateStringWithYearLongMonth(currentSemester?.semesterStart, 'short')

  return (
    <RecommendedPageWrapper>
      <PageTitle className='mb-2'>
        {isInReviewState ? 'Your schedule has been submitted!' : 'Here are your recommended courses.'}
      </PageTitle>
      {!isInReviewState && (
        <SectionTitle
          data-testid='closing-date-info'
          className='mt-1 mb-5'
        >
        Last day of enrollment is {closeEnrollment}.
        </SectionTitle>
      )}
      <SemesterTitle>
        {currentSemester?.termName}
      </SemesterTitle>
      <GroupHeaderMessageContainer>
        <img src='images/icons/info-circle.svg' alt='info-icon' />
        <GroupHeaderMessage data-testid='group-header-message'>
          Professional Certificate courses are not permitted to be taken in your first term with Degrees+. Only one Professional Certificate course may be taken per term.
        </GroupHeaderMessage>
      </GroupHeaderMessageContainer>
      <CourseSlotWrapper>
        {recommendedCourses?.map((course, index) => {
          const { courseId, recommendationGroup } = course
          const shouldNotBeGrouped = hasCompltedCourseFromGroup({
            courseId,
            prospects,
            studentAttempts,
            degreeData
          })

          if (!recommendationGroup?.length || shouldNotBeGrouped) {
            return (
              <CourseSlot
                key={courseId}
                index={index}
                course={course}
                semesterStart={semesterStart}
                catalogCourses={catalogCourses}
                setShouldReloadPage={setShouldReloadPage}
                noOfCoursesInSlots={recommendedCourses.length}
                advisorApproved={advisorApproved}
              />
            )
          }

          return (
            <GroupedCourseSlot
              key={index}
              index={index}
              recommendationGroup={recommendationGroup}
              course={course}
              semesterStart={semesterStart}
              setShouldReloadPage={setShouldReloadPage}
              noOfCoursesInSlots={recommendedCourses.length}
              outlierCourses={outlierCourses}
              studentAttempts={studentAttempts}
              prospects={prospects}
              courseProjectedGrades={courseProjectedGrades}
              advisorApproved={advisorApproved}
            />
          )
        })}
        {recommendedCourses?.length < noOfSlots && (
          <WarningSlots
            noOfCoursesInSlots={recommendedCourses.length}
            noOfSlots={noOfSlots}
          />
        )}
      </CourseSlotWrapper>
      {getInstructions(requireAdvisorApproval, isInReviewState)}
      <ScheduleCallButton
        className={classNames({
          btn: true,
          'btn-primary': !requireAdvisorApproval || !isInReviewState,
          'btn-secondary': requireAdvisorApproval && isInReviewState
        })}
        onClick={requireAdvisorApproval ? handleClick : handleEnroll}
        advisorApproved={advisorApproved}
      >
        {requireAdvisorApproval
          ? isInReviewState
            ? SCHEDULE_ADVISOR_MEETING
            : SUBMIT_FOR_REVIEW
          : 'Enroll'}
      </ScheduleCallButton>
      {showReturnToDashboard && (
        <ReturnToDashboard
          updateTempCart={updateTempCart}
          updateContext={updateContext}
        />
      )}
    </RecommendedPageWrapper>
  )
}

RecommendedCourses.displayName = 'RecommendedCourses'
export default RecommendedCourses
