import React, { useContext, useEffect, useState } from 'react'
import LargePopup from './LargePopup/LargePopup'
import SmallPopup from './SmallPopup/SmallPopup'
import { AppContext } from '../../../ContextProvider/ContextProvider'
import api from '../../../../api'
import { getSessionsArray } from '../../../../utilities/studyBlocks'

function StudyBlocksPopups () {
  const { studyBlocksData = {} } = useContext(AppContext)
  const [popUpsState, setPopUpsState] = useState({})

  const hasStudyBlocks =
    getSessionsArray(studyBlocksData?.studyBlocks).length > 0

  useEffect(() => {
    if (hasStudyBlocks) return

    const largePopupDismissed = localStorage.getItem(
      'studyBlocksLargePopupDismissed'
    )
    const smallPopupDismissed = localStorage.getItem(
      'studyBlocksSmallPopupDismissed'
    )

    if (largePopupDismissed && smallPopupDismissed) return

    const checkPopupStatus = async () => {
      const popUpsState = await api.getStudyBlocksPopupsState()

      const { sbPopup1ClickedAction, sbPopup2ClickedAction } = popUpsState
      if (sbPopup1ClickedAction === 'Dismissed') {
        localStorage.setItem('studyBlocksLargePopupDismissed', true)
      }
      if (sbPopup2ClickedAction === 'Dismissed') {
        localStorage.setItem('studyBlocksSmallPopupDismissed', true)
      }

      setPopUpsState(popUpsState)
    }

    checkPopupStatus()
  }, [hasStudyBlocks])

  return !Object.keys(studyBlocksData).length > 0 || hasStudyBlocks ? null : (
    <>
      <LargePopup popUpsState={popUpsState} />
      <SmallPopup popUpsState={popUpsState} />
    </>
  )
}

StudyBlocksPopups.displayName = 'StudyBlocksPopups'
export default StudyBlocksPopups
