import React, { useContext } from 'react'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  HIDE_DASHBOARD_ITEM,
  INSTRIDE_WELCOME_PAGE_VIEW,
  PERMISSION_REQUESTED_KEY
} from '../../Constants'
import {
  CenterContainer, PageHeader, PrimaryButton, TextContent
} from './style'
import { useHistory } from 'react-router-dom'

function InstrideWelcomePage () {
  const history = useHistory()
  const { studentData } = useContext(AppContext)
  const { firstName, preferredName } = studentData || {}

  const continueCourse = () => {
    localStorage.removeItem(PERMISSION_REQUESTED_KEY)
    localStorage.removeItem(INSTRIDE_WELCOME_PAGE_VIEW)
    localStorage.removeItem(HIDE_DASHBOARD_ITEM)
    history.push('/catalog')
  }

  return (
    <CenterContainer>
      <PageHeader>Welcome, {preferredName || firstName}</PageHeader>
      <TextContent style={{ marginBottom: '32px' }}>
        We’re excited that you’re going to be learning with us!
        Let’s get started with the course catalog.
        There, you can select the courses you’d like to enroll in.
      </TextContent>
      <PrimaryButton
        data-testid='btn-continue'
        onClick={continueCourse}
      >
        get started
      </PrimaryButton>
    </CenterContainer>
  )
}

InstrideWelcomePage.displayName = 'InstrideWelcomePage'
export default InstrideWelcomePage
