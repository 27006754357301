import React, { useContext } from 'react'
import {
  CourseTile,
  CourseTileWrapper,
  CourseTitle,
  ExploreCatalogLink,
  ExploreCatalogLinkMobile,
  LearnMoreButton,
  RecommendedCoursesContainer,
  RecommendedCoursesSlideContainer
} from './styles'
import { SectionHeader, SectionTitle } from '../style'
import { AppContext } from '../../ContextProvider/ContextProvider'
import { getCourseAssets, getMoreCourses } from '../../../utilities/course'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { BREAKPOINTS } from '../../../mediaQueries'
import { getRecommendedCourseLink } from '../utils'

function RecommendedCourses () {
  const { courses, catalogCourses, activeCourses } = useContext(AppContext)
  const recommendedCourses = getMoreCourses(courses, activeCourses).slice(0, 4)

  return (
    <RecommendedCoursesContainer>
      <SectionHeader>
        <SectionTitle>Recommended Courses</SectionTitle>
        <ExploreCatalogLink href='#/catalog'>
          Explore Catalog
        </ExploreCatalogLink>
      </SectionHeader>
      <RecommendedCoursesSlideContainer>
        <Splide
          aria-label='Recommended Courses Section Slide'
          hasTrack={false}
          options={{
            type: 'slide',
            pagination: false,
            arrows: false,
            gap: '17px',
            autoWidth: true,
            slideFocus: true,
            focus: 0,
            omitEnd: true,
            padding: 'var(--mobile-layout-padding)',
            breakpoints: {
              [BREAKPOINTS.mobile]: {
                gap: '12px'
              }
            }
          }}
        >
          <SplideTrack>
            {recommendedCourses.map((course) => {
              const { displayName, id } = course
              const { courseImage } = getCourseAssets(catalogCourses, id)

              return (
                <SplideSlide key={id}>
                  <CourseTileWrapper>
                    <CourseTile courseImage={courseImage} data-testid='course-tile'>
                      <CourseTitle className='course-title'>
                        {displayName}
                      </CourseTitle>
                      <LearnMoreButton
                        className='learn-more'
                        data-testid='learn-more'
                        onClick={() => {
                          const recommendedCourseLink = getRecommendedCourseLink(
                            course
                          )

                          window.location.href = recommendedCourseLink
                        }}
                      >
                        Learn More
                      </LearnMoreButton>
                    </CourseTile>
                  </CourseTileWrapper>
                </SplideSlide>
              )
            })}
          </SplideTrack>
        </Splide>
      </RecommendedCoursesSlideContainer>
      <ExploreCatalogLinkMobile href='#/catalog'>
        Explore Catalog
      </ExploreCatalogLinkMobile>
    </RecommendedCoursesContainer>
  )
}

RecommendedCourses.displayName = 'RecommendedCourses'
export default RecommendedCourses
