import React, { useContext } from 'react'
import {
  Container,
  Header,
  Content
} from '../GuardianPermission/styles'
import { PrimaryButton } from '../Buttons/style'
import { PERMISSION_REQUESTED_KEY } from '../../Constants'
import { AppContext } from '../ContextProvider/ContextProvider'
import config from '../../config'

const WelcomePage = ({
  over18
}) => {
  const { studentData } = useContext(AppContext)
  const { preferredName } = studentData || {}

  const continueCourse = () => {
    localStorage.removeItem(PERMISSION_REQUESTED_KEY)
    window.open(config.courseBaseUrl, '_self')
  }

  return (
    <Container welcomePage>
      <div className='content'>
        <Header>Welcome, {preferredName}</Header>
        <Content marginBottom='36px'>
          {over18
            ? 'You’re on your way to becoming an Outlier!'
            : 'Now that you have permission to take our classes, you’re on your way to becoming an Outlier!'}
        </Content>
        <PrimaryButton
          data-testid='btn-continue'
          onClick={continueCourse}
        >
          let's go
        </PrimaryButton>
      </div>
    </Container>
  )
}

WelcomePage.displayName = 'WelcomePage'
export default WelcomePage
