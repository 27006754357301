import { GGUPortalLogin } from './style'
import React from 'react'
import { getLatestCohort } from '../../utilities/course'
import { getLetterGrade } from '../../utilities/gradeUtils'
import config from '../../config'

const TranscriptGradeMessage = ({ transcript, courses, isExcludedCourses }) => {
  const {
    cohort,
    course,
    relationshipName,
    studentStatus
  } = transcript?.fields || {}

  const { name: cohortName } = cohort?.fields || {}

  const courseData = courses.find(
    courseElement =>
      courseElement?.id === course?.fields?.id
  )
  const latestCohort = getLatestCohort(courseData)
  const hideStudentGradeDisplay =
    latestCohort?.hideStudentGradeDisplay
  const isGGUCohort = cohortName.toLowerCase().includes('ggu')
  const isGradeDisplayOff = config.hasInTimeGradeFlag &&
    hideStudentGradeDisplay

  const GradeDisplayOffMessage = () => {
    if (!isGGUCohort) return null

    const { courseBaseUrlById } = config
    const courseUrl = courseBaseUrlById(course?.fields?.id)
    const instructorUrl = `${courseUrl}/#/resources?instructor`

    return (
      <p>
          Please {' '}
        <a
          href={instructorUrl}
          target='_blank'
          rel='noopener noreferrer'
        >
            contact your instructor
        </a>
        {' '} for your grade.
      </p>
    )
  }

  const GradeDisplayOnMessage = () => {
    if (!isGGUCohort) {
      return <p>Grade: {getLetterGrade(transcript?.grade || 0)}</p>
    }

    return (
      <GGUPortalLogin>
          Log into the{' '}
        <a
          href='https://portal.ggu.edu/'
          target='_blank'
          rel='noopener noreferrer'
        >
            myGGU student portal
        </a>{' '}
          to view your final grade.
      </GGUPortalLogin>
    )
  }

  if (isExcludedCourses) {
    return <p>{relationshipName || studentStatus?.fields?.Name}</p>
  }
  if (isGradeDisplayOff) {
    return <GradeDisplayOffMessage />
  }
  return <GradeDisplayOnMessage />
}

TranscriptGradeMessage.displayName = 'TranscriptGradeMessage'
export default TranscriptGradeMessage
