import { isDate, compareDesc } from 'date-fns'

export {
  getDateStringWithYear,
  secondsSinceEpoch,
  dateToSecondsSinceEpoch,
  secondsToFormattedDay,
  secondsToFormattedDateShort,
  secondsToFormattedTimeShort,
  secondsToFormattedDateTimeShort,
  getNextWeekdayDate,
  getNextWeekdayDateInSecs,
  getDateWithoutYear,
  weeksToSeconds,
  daysToSeconds,
  changeTimezone,
  diffDays,
  getSecondsFromTime,
  getTimeFromSeconds,
  secondsToDateString,
  millisecondsToSeconds,
  convertToPTTime,
  getDateStringWithYearLongMonth,
  addWeeksToDate,
  getDateMidNight,
  addDaysToDate,
  addDaysToDateInSecs,
  getDateMMDDYYFormat,
  secondsToMilliseconds,
  getISODateTimeString,
  getUserTimeZone,
  getTimezoneShort
}

// Logic of this file was taken from calculus-static, dateTimeUtils.js

// undefined means use the user's locale settings
const USER_LOCALE = process.env.NODE_ENV === 'test' ? 'en-US' : undefined

// Returns the current date/time in seconds.
// Does not need tests because that would require mocking Date.now and is not
// worth the effort since this method is already well known. Instead see:
// https://stackoverflow.com/questions/3830244/get-current-date-time-in-seconds
function secondsSinceEpoch () {
  return Math.floor(Date.now() / 1000)
}

function millisecondsToSeconds (milliseconds) {
  return Math.floor(milliseconds / 1000)
}

function secondsToMilliseconds (seconds) {
  return seconds * 1000
}

// Given a date, it returns that date/time in seconds.
// Does not need tests because that would require mocking Date.now and is not
// worth the effort since this method is already well known. Instead see:
// https://stackoverflow.com/questions/3830244/get-current-date-time-in-seconds
function dateToSecondsSinceEpoch (date) {
  return Math.floor(date.getTime() / 1000)
}

function getISODateTimeString (seconds) {
  if (!seconds) return seconds

  const date = new Date(seconds * 1000)
  return date.toISOString()
}

function secondsToFormattedDay (seconds, weekDayType = 'short') {
  if (!seconds) return
  const date = new Date(seconds * 1000)
  return date.toLocaleString('en-us', { weekday: weekDayType })
}

// Extracted from an existing component. Might not be the perfect abstraction
// yet and clearly doesn't support locales other then 'en-us'. Putting all the
// date formatting in this module will help us eventually determine the best
// abstraction so this is a good first step.
function secondsToFormattedDateShort (seconds) {
  const convDate = new Date(seconds * 1000)
  return convDate.toLocaleString('en-us', { month: 'short', day: '2-digit' })
}

function getDateStringWithYear (dateStringISO) {
  if (!dateStringISO) return null

  const date = new Date(dateStringISO)
  if (date.toString() === 'Invalid Date') return null

  return date.toLocaleDateString(
    'en-US',
    { year: 'numeric', day: 'numeric', month: 'short' }
  )
}

// Given a time in seconds since epoch, it returns a short time, formatted
// according to the user's locale.
function secondsToFormattedTimeShort (seconds) {
  const convDate = new Date(seconds * 1000)
  // 'numeric' means a leading zero will be dropped. '2-digit' means the leading
  // zero will be preserved.
  const options = {
    timeStyle: 'short',
    hour: 'numeric',
    minute: '2-digit'
  }
  // undefined for the first parameter means use the user's locale settings.
  return convDate.toLocaleTimeString(USER_LOCALE, options)
}

// Given a time in seconds since epoch, it returns a short date time, formatted
// according to the user's locale.
function secondsToFormattedDateTimeShort (seconds, monthStyle = 'long') {
  if (!seconds) return
  const convDate = new Date(seconds * 1000)
  // 'numeric' means a leading zero will be dropped. '2-digit' means the leading
  // zero will be preserved.
  const options = {
    month: monthStyle,
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit'
  }
  // undefined for the first parameter means use the user's locale settings.
  return convDate.toLocaleString(USER_LOCALE, options)
}

function secondsToDateString (seconds) {
  return new Date(seconds * 1000).toString()
}

function getTimezoneShort (dateString) {
  if (process.env.NODE_ENV === 'test') return 'PDT'
  const timezoneLong = dateString.substring(dateString.indexOf('(') + 1, dateString.indexOf(')'))
  // Extract first character from each word of timezoneLong
  const timezoneShort = timezoneLong.split(' ').map(word => word[0]).join('')
  return timezoneShort
}

/**
 * @param {date} date in seconds
 * @param {number} dayOfTheWeek between 1 and 7
 * {Mon: 1, Tue: 2, Wed: 3, Thurs: 4, Fri: 5, Sat: 6, Sun: 7}
 * @returns {number} date of the next specified day if short is false
 * or old month is equal to new month
 * @returns {string} date of next specified day (e.g 'Jan 10') if short is true
 * or old month is not equal to new month
 */
function getNextWeekdayDate (date, dayOfTheWeek, short = false) {
  const newDate = new Date(date * 1000)
  const currentDateMonth = newDate.getMonth()
  newDate.setDate(
    newDate.getDate() + ((dayOfTheWeek - 1 - newDate.getDay() + 7) % 7) + 1
  )
  const newDateMonth = newDate.getMonth()
  const isAnotherMonth = currentDateMonth !== newDateMonth

  if (short || isAnotherMonth) {
    return newDate.toLocaleString('en-us', { month: 'short', day: 'numeric' })
  }

  return newDate.getDate()
}

/**
 * @param {date} date in seconds
 * @param {number} dayOfTheWeek between 1 and 7
 * {Mon: 1, Tue: 2, Wed: 3, Thurs: 4, Fri: 5, Sat: 6, Sun: 7}
 * @returns {number} date of the next specified day in seconds
 */
function getNextWeekdayDateInSecs (date, dayOfTheWeek) {
  const newDate = new Date(date * 1000)
  newDate.setDate(
    newDate.getDate() + ((dayOfTheWeek - 1 - newDate.getDay() + 7) % 7) + 1
  )

  return Math.round(newDate / 1000)
}

function getDateWithoutYear (dateInSeconds) {
  const newDate = new Date(dateInSeconds * 1000)

  return newDate.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    hour12: true
  })
}

function weeksToSeconds (weeks) {
  // Days, hours, minutes, seconds
  return parseFloat(weeks) * 7 * 24 * 60 * 60
}

function daysToSeconds (days) {
  return parseFloat(days) * 24 * 60 * 60
}

function getUserTimeZone () {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

// Changes the underlying date object to a new time zone while preserving the
// date and the time. For example, if a UTC date of 2019-Sep-24 1am is
// given and the IANATimeZone is 'America/Los_Angeles', it will return a date
// object that is 2019-Sep-24 1am for Los Angeles.
function changeTimezone (date, IANATimezone) {
  // Create a new date in the requested timezone.
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: IANATimezone
  }))

  // Get the difference between the given date and the new timezone.
  var diff = date.getTime() - invdate.getTime()

  // Calculate a new date adjusted using the difference.
  return new Date(date.getTime() + diff)
}

// Returns the difference in days between two dates
function diffDays (startDate, endDate, isRounded = true) {
  const date1 = new Date(startDate * 1000)
  const date2 = new Date(endDate * 1000)
  let diff = (date1.getTime() - date2.getTime()) / 1000
  diff /= (60 * 60 * 24)
  return Math.abs(isRounded ? Math.round(diff) : diff)
}

// Returns seconds from HH:mmm:ss or mm:ss or ss format
function getSecondsFromTime (time) {
  if (!time) return
  const units = time.split(':')
  let [seconds, multiplier] = [0, 1]
  while (units.length > 0) {
    seconds += multiplier * parseInt(units.pop(), 10)
    multiplier *= 60
  }

  return seconds
}

// Returns HH:mm:ss or mm:ss from seconds
function getTimeFromSeconds (seconds) {
  if (!seconds) return
  // Hours, minutes and seconds
  const hrs = ~~(seconds / 3600)
  const mins = ~~((seconds % 3600) / 60)
  const secs = ~~seconds % 60

  // Output like '1:01' or '4:03:59'
  let time = ''
  if (hrs > 0) {
    time += `${hrs}:${(mins < 10 ? '0' : '')}`
  }
  time += `${mins}:${(secs < 10 ? '0' : '')}`
  time += secs

  return time
}

export const convertSecondsToTime = (seconds) => {
  const sec = (seconds % 60).toString().padStart(2, '0')
  const min = parseInt((seconds % 3600) / 60).toString()
  const hrs = parseInt(seconds / 3600)
  if (hrs) {
    return `${hrs}:${min.padStart(2, '0')}:${sec}`
  }
  return `${min}:${sec}`
}

/**
 * @param {string} dateStr the string to construct the Date object,
 *   the format should be DD/MM/YYYY
 * @param {number} extender the number of weeks to extend the date if wanted
 * @returns {string} the full month name and the day as two digits eg. September 08
 *   if provided the proper dateStr regardless of the timezone, otherwise undefined
 */
export const getMonthDayFromDate = (dateStr, extender, datetype = 'long') => {
  if (!dateStr || typeof dateStr !== 'string') return
  let date = new Date(dateStr + 'T00:00:00')

  if (date.toString() === 'Invalid Date') return

  if (extender) {
    const extenderMilliSeconds = extender * 7 * 24 * 60 * 60 * 1000
    date = new Date(date.getTime() + extenderMilliSeconds)
  }

  return date.toLocaleString('en-us', { month: datetype, day: '2-digit' })
}

/**
 * @returns {number} the number of milliseconds since epoch from the date provided, adjusted to PT
 * this is a generalization of the function getCohortStartSecondsSinceEpoch
 */
export const getPTTimeSinceEpoch = (dateStr) => {
  if (!dateStr || typeof dateStr !== 'string') return

  // Use the string date part and set the time to 00:00 AM UTC
  const date = new Date(dateStr + 'T00:00:00')

  if (!date) return

  // Convert date to PST time.
  const datePST = changeTimezone(date, 'America/Los_Angeles')

  return datePST.getTime()
}

/**
 * @param {number} seconds
 * @returns {string} It returns a short date time along with short timezone,
 * formatted according to the user's locale.
 */
export const getDateTimeWithShortTimezone = seconds => {
  const formattedDate = secondsToFormattedDateTimeShort(seconds)
  const timezone = getTimezoneShort(secondsToDateString(seconds))
  return `${formattedDate} ${timezone}`
}

function convertToPTTime (date) {
  // subtract a minute from day milliseconds
  const dayMilliSeconds = 24 * 60 * 60 * 1000 - (60 * 1000)
  return getPTTimeSinceEpoch(date) + dayMilliSeconds
}

function getDateStringWithYearLongMonth (dateStringISO, datetype = 'long') {
  if (!dateStringISO) return null

  const date = new Date(dateStringISO)
  if (date.toString() === 'Invalid Date') return null

  return date.toLocaleDateString(
    'en-US',
    { year: 'numeric', day: 'numeric', month: datetype }
  )
}

function addWeeksToDate (startDate, week) {
  const numWeeks = week
  const now = new Date(startDate)
  now.setDate(now.getDate() + numWeeks * 7)
  return getDateStringWithYearLongMonth(now, 'short')
}

export const getDifferenceOfDaysWithCurrentDate = (date) => {
  if (!date) return ''
  const currentDate = secondsSinceEpoch()
  return diffDays(currentDate, date)
}

export const parseDate = (date) =>
  date ? Date.parse(new Date(date)) : ''

export function formatDate (value) {
  const date = new Date(value || ' ')
  if (date.toString() === 'Invalid Date') return
  return date
    .toLocaleString(undefined, { month: 'short', year: 'numeric' })
    .split(' ')
    .join(', ')
}

export function formatDateWithDay (value) {
  const date = new Date(value || ' ')
  if (date.toString() === 'Invalid Date') return

  const dateArray = date
    .toLocaleString(undefined, { day: 'numeric', month: 'short', year: 'numeric' })
    .split(' ')
  const month = dateArray[0] || ''
  const day = dateArray[1]?.replace(',', '') || ''
  const year = dateArray[2] ? `, ${dateArray[2]}` : ''

  return `${month} ${day}${year}`
}

export function toDateString (value) {
  if (!value || new Date(value).toString() === 'Invalid Date') return
  return new Date(value).toISOString().split('T')[0]
}

export function isDateBeforeToday (date) {
  if (!date || new Date(date).toString() === 'Invalid Date') return
  return new Date(date) < new Date()
}

export function getDateStringWithShortMonth (value) {
  const date = new Date(value || ' ')
  if (date.toString() === 'Invalid Date') return
  return date
    .toLocaleString('en-US', { month: 'short', day: 'numeric' })
}

/**
 * @param {String | Date} startValue a valid date or a date string
 * @param {String | Date} endValue a valid date or a date string
 * @returns the start and end date in US format
 * e.g (same year) startValue = 2022/01/10, endValue = 2022/5/10 -> Jan 10 - May 10, 2022
 * e.g (different year) startValue = 2021/11/10, endValue = 2022/2/10 -> Nov 10, 2021 - Feb 10, 2022
 */
export function getStartEndDateUSFormat (startValue, endValue) {
  const startDate = new Date(startValue || ' ')
  const endDate = new Date(endValue || '')

  if (
    startDate.toString() === 'Invalid Date' ||
    endDate.toString() === 'Invalid Date'
  ) return

  const startYear = startDate.getFullYear()
  const endYear = endDate.getFullYear()
  const isSameYear = startYear === endYear

  const formattedStartDate = getDateStringWithShortMonth(startValue)
  const formattedEndDAte = getDateStringWithShortMonth(endValue)

  if (isSameYear) {
    return `${formattedStartDate} - ${formattedEndDAte}, ${startYear}`
  }

  return `${formattedStartDate}, ${startYear} - ${formattedEndDAte}, ${endYear}`
}

export function getElevenFiftyNinePmInSecs (dateInSecs) {
  if (!dateInSecs) return

  const newDate = new Date(dateInSecs * 1000)
  return Math.round(newDate.setHours(23, 59, 59, 59) / 1000)
}

export function getTwelveAmInSecs (dateInSecs) {
  if (!dateInSecs) return

  const newDate = new Date(dateInSecs * 1000)
  return Math.round(newDate.setHours(0, 0, 0, 0) / 1000)
}

function getDateMidNight (dateString) {
  const date = new Date(dateString)
  date.setHours(0, 0, 0, 0)

  return date
}

/**
 * @param {number} dateInSeconds
 * @param {number} days
 * @returns {number} date of the next specified day if
 * old month is equal to new month
 * @returns {string} date of next specified day (e.g 'Jan 10') if
 * old month is not equal to new month or short param is set to true
 */
function addDaysToDate (dateInSeconds, days, short = false) {
  const newDate = new Date(dateInSeconds * 1000)
  const currentDateMonth = newDate.getMonth()
  newDate.setDate(newDate.getDate() + days)

  const newDateMonth = newDate.getMonth()
  const isAnotherMonth = currentDateMonth !== newDateMonth

  if (isAnotherMonth || short) {
    return newDate.toLocaleString('en-us', { month: 'short', day: 'numeric' })
  }

  return newDate.getDate()
}

function addDaysToDateInSecs (dateInSeconds, days) {
  // one day is 86_400 seconds —> 24 hr * 60 mins * 60 secs
  const daysInSecs = days * 86_400

  return dateInSeconds + daysInSecs
}

function getDateMMDDYYFormat (dateValue = '') {
  const date = new Date(dateValue)

  if (date.toString() === 'Invalid Date') return

  const MM = date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
  const DD = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
  const YYYY = date.getFullYear()

  return `${MM}/${DD}/${YYYY}`
}

/**
 * @param {string} Date in the format 'YYYY-MM-DD'
 * @returns {boolean} true if input NY date is adjusted for DST
 */

export function isDstInEffectForPTDate (date) {
  // When DST takes effect it starts at 02:00 AM.
  // Here we check to see if DST is in effect by passing
  // a UTC date at 10:00:00 (equivalent to PS Date at 02:00 AM).
  // if DST is in effect hour shifts by +1
  const adjustedDate = new Date(date + 'T10:00:00-0000')
  if (String(adjustedDate) === 'Invalid Date') return null

  return adjustedDate
    .toLocaleString('en', { timeZone: 'America/Los_Angeles' })
    .includes('3:00:00 AM')
}

/**
 * @param {string} Date in 'YYYY-MM-DD' format
 * @returns {object} date object in local time
 */
export function changePTDateToLocalTime (date) {
  // When Dst is in effect time difference b/n UTC & PS time decreases by one hour, hence the -0700 offset
  if (isDstInEffectForPTDate(date) === null) return null
  const timeZoneOffset = isDstInEffectForPTDate(date) ? '-0700' : '-0800'
  const dateString = date + 'T00:00:00' + timeZoneOffset
  return new Date(dateString)
}

export function convertEasternTimeToLocalTime (
  dateString,
  timeString = 'T23:59:00'
) {
  if (!dateString || typeof dateString !== 'string') return

  const date = new Date(dateString + timeString)
  if (!date || date.toString() === 'Invalid Date') return

  const easterTimeDate = changeTimezone(date, 'America/New_York')
  return easterTimeDate
}

export const getDateString = date => {
  const newDate = date.toLocaleDateString(
    'en-US',
    { year: 'numeric', day: '2-digit', month: '2-digit' }
  ).split('/')
  return `${newDate[2]}-${newDate[0]}-${newDate[1]}`
}

export const getMaxDateValue = (month, year = 0) => {
  return new Date(year, month, 0).getDate()
}

/**
 * @param {Array of dates} dates an array containing valid date or a date string
 * @returns {Date} the earliest date from dates[]
 * e.g ['2022-04-13', '2022-01-21', '2022-12-01', '2023-01-02']
 * it return '2022-01-21' as Date
 */
export const getEarliestDate = (dates = []) => {
  let earliestDate = null

  dates.forEach((date) => {
    const currentDate = date ? new Date(date) : ''

    if (isDate(currentDate) && (!earliestDate || compareDesc(currentDate, earliestDate) === 1)) {
      earliestDate = currentDate
    }
  })

  return earliestDate
}

/**
 * @param {Number} hour - hour in 24 hour format
 * @returns {String} - time in 12 hour format
 * e.g 13 => 1:00 PM , e.g 12 => 12:00 PM , e.g 23 => 11:00 PM
 * e.g 0 => 12:00 AM , e.g 24 => 12:00 AM
*/
export function formatHoursToTime (hour) {
  const date = new Date()
  const wholeHour = Math.floor(hour)
  const minutes = (hour - wholeHour) * 60
  date.setHours(wholeHour)
  date.setMinutes(minutes)
  date.setSeconds(0)

  return date.toLocaleTimeString(
    'en-US', { hour: 'numeric', minute: '2-digit', hour12: true })
}

/**
 * @param {number} time - The time in hours, can include decimal values for minutes.
 * @param {Date} initDate - The Date to set the time on or current Date if not provided
 * @returns {Date} - The Date object set to the given time.
 */
export function getDateAtTime (time, initDate) {
  const date = initDate || new Date()
  const hours = Math.floor(time)
  const minutes = Math.round((time - hours) * 60)
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(0)
  return date
}

/**
 * @param {String} date in the form of a string.
 * @returns {number} - The hours extracted from the Date object.
 */
export function getHoursFromDate (dateString) {
  if (new Date(dateString).toString() === 'Invalid Date') return

  const date = new Date(dateString)
  const dateHours = date.getHours()
  const dateMinutes = date.getMinutes()
  const minutesFraction = Number((dateMinutes / 60).toFixed(2))

  return dateHours + minutesFraction
}
