import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { PrimaryButton, SecondaryButton } from '../Pretest/QuestionComponent/style'
import {
  GlobalStyle,
  ExitModalHeader,
  Separator,
  ButtonsContainer
} from '../Pretest/style'
import CloseIcon from '../../assets/icons/close-icon.svg'

const ExitModal = ({ show, setShow }) => {
  const closeModal = () => {
    setShow(false)
  }

  const gotoCourseManagement = () => {
    const { hash } = window.location
    window.location.hash = hash.slice(0, hash.indexOf('/course-exit'))
  }

  return (
    <Modal show={show} centered>
      <GlobalStyle />
      <ExitModalHeader>
        <p>Are you sure you want to leave?</p>
        <img
          data-testid='img-close'
          src={CloseIcon}
          alt='Close Exit Modal'
          onClick={closeModal}
        />
      </ExitModalHeader>
      <Separator />
      <Modal.Body>
        <p>
          Any options that you chose on the transfer form will be
          forgotten and you will remain in your current cohort.
        </p>
        <ButtonsContainer>
          <PrimaryButton
            data-testid='btn-exit-transfer'
            onClick={gotoCourseManagement}
            height='49px'
          >
            exit form
          </PrimaryButton>
          <SecondaryButton
            data-testid='btn-keep-editing'
            onClick={closeModal}
            height='49px'
          >
            keep editing
          </SecondaryButton>
        </ButtonsContainer>
      </Modal.Body>
    </Modal>
  )
}

ExitModal.displayName = 'ExitModal'

export default ExitModal
