import React from 'react'
import config from '../../config'
import { getVisitedALCardInfo } from '../../utilities/section'
import WidgetState from './WidgetState'
import groupBy from 'lodash/groupBy'

const ActiveLearningCard = ({
  course,
  courseProgress,
  chapterNo,
  sectionNo
}) => {
  const { id } = course
  const { studentProgress, sectionData } = courseProgress
  const { studentAnswers } = studentProgress

  const {
    section_exe: sectionExe,
    section_uuid: sectionUuid
  } = sectionData
  const { active_learning: activeLearning } = sectionExe

  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)
  const { completedQuestion, totalQuestion, questionId } =
    getVisitedALCardInfo(studentAnswers, activeLearning)

  const completedActiveLearning =
    Object.keys(studentProgress['active-learning-complete'])
  const isActiveLearningCompleted = completedActiveLearning.includes(sectionUuid)

  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`
  const activeLearningUrl = `${sectionUrl}/${questionId}`

  const sectionNumber = `Section ${chapterNo}.${sectionNo}`

  const getNextActivity = () => {
    const {
      practice_terms: practiceTerms,
      practice_exercises: practiceExercises,
      quiz
    } = sectionExe
    if (practiceExercises) return 'Practice Exercises'
    if (practiceTerms) return 'Practice Terms'
    if (quiz) return 'Quizzes'
  }

  const remainingCards = totalQuestion - completedQuestion
  const isMultipleRemainingCards = remainingCards > 1 ? 's' : ''
  const isMultipleCompletedCards = completedQuestion > 1 ? 's' : ''

  // ActiveLearning - In progress
  let stateData = {
    dataTestId: 'activeLearning-in-progress',
    description: `You completed ${completedQuestion}
   card${isMultipleCompletedCards} of ${sectionNumber} Active Learning.
    Continue to complete the remaining ${remainingCards} 
    card${isMultipleRemainingCards}.`,
    primaryBtnTxt: 'Continue',
    primaryBtnFunction: () => { window.location.href = activeLearningUrl }
  }

  // ActiveLearning - Completed
  if (isActiveLearningCompleted) {
    stateData = {
      dataTestId: 'activeLearning-completed',
      description: `You reached the end of ${sectionNumber} Active Learning.
           Continue to ${sectionNumber} ${getNextActivity()}.`,
      primaryBtnTxt: 'Continue',
      primaryBtnFunction: () => { window.location.href = sectionUrl }
    }
  }

  // handle Active learning parts.
  const questionList = activeLearning.Question ?? []
  const allQuestions = groupBy(questionList, question => question
    .instructor?.['theme_name'] || '')
  const isMultiAL = Object.keys(allQuestions).length > 1

  if (isMultiAL) {
    const answerUUIDs = Object.keys(studentAnswers)
    const unansweredQuestionList =
      questionList.filter(q => !answerUUIDs.includes(q.Question_uuid))
    const unansweredQuestions =
      groupBy(unansweredQuestionList, x => x.instructor?.['theme_name'] || '')
    const allQuestionsKeys = Object.keys(allQuestions)

    let remainingQuestions, completedQuestions, chapterNo
    for (let i = 0; i < allQuestionsKeys.length; i++) {
      const themeName = allQuestionsKeys[i]
      const questionCount = allQuestions[themeName].length
      remainingQuestions = unansweredQuestions[themeName]?.length ?? 0
      completedQuestions = questionCount - remainingQuestions
      chapterNo = i + 1
      if (completedQuestions !== 0) break
    }

    if (remainingQuestions !== 0) {
      // handle InProgress AL parts
      const isMultipleRemaining = remainingQuestions > 1 ? 's' : ''
      const isMultipleCompleted = completedQuestions > 1 ? 's' : ''
      stateData = {
        dataTestId: 'activeLearning-part-in-progress',
        description: `You completed ${completedQuestions}
        card${isMultipleCompleted} of Chapter ${chapterNo} of ${sectionNumber}
        Active Learning. Continue to complete the remaining
        ${remainingQuestions} card${isMultipleRemaining}.`,
        primaryBtnTxt: 'Continue',
        primaryBtnFunction: () => { window.location.href = activeLearningUrl }
      }
    } else {
      // handle completed AL parts
      stateData = {
        dataTestId: 'activeLearning-part-completed',
        description: `You reached the end of Chapter ${chapterNo} of
       ${sectionNumber} Active Learning.
       Continue to ${sectionNumber} ${getNextActivity()}.`,
        primaryBtnTxt: 'Continue',
        primaryBtnFunction: () => { window.location.href = sectionUrl }
      }
    }
  }

  const { dataTestId } = stateData

  return (
    <div data-testid={dataTestId}>
      <WidgetState
        {...stateData}
      />
    </div>
  )
}

export default ActiveLearningCard
