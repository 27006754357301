import React from 'react'
import { useHistory } from 'react-router-dom'
import { FULL_TIME_SLOT } from '../../Constants/gguDegrees'
import {
  CatalogLink,
  WarningSlotContainer,
  WarningSlotTitle,
  Arrow,
  WarningText
} from './styles'

const WarningSlots = ({
  noOfCoursesInSlots,
  noOfSlots
}) => {
  const history = useHistory()

  const handleCatalogClick = () => history.push('/catalog')
  const isFullTime = noOfSlots === FULL_TIME_SLOT

  return (
    <>
      {isFullTime && noOfCoursesInSlots < 2 && (
        <WarningSlotContainer data-testid='add-course-slot'>
          <WarningSlotTitle>Add a course</WarningSlotTitle>
          <CatalogLink onClick={handleCatalogClick}>
            <div>View course catalog</div>
            <Arrow />
          </CatalogLink>
        </WarningSlotContainer>
      )}
      <WarningSlotContainer data-testid='warning-slot'>
        <WarningText>
          {isFullTime
            ? `Taking ${noOfCoursesInSlots === 2 ? 'two courses' : 'one course'} is considered part-time, which may affect your financial aid and projected graduation date.`
            : 'Taking one course may affect your projected graduation date. '}
        </WarningText>
        <CatalogLink onClick={handleCatalogClick}>
          <div>View course catalog</div>
          <Arrow />
        </CatalogLink>
      </WarningSlotContainer>
    </>
  )
}

WarningSlots.displayName = 'WarningSlots'
export default WarningSlots
