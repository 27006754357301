import styled, { keyframes } from 'styled-components'
import media from '../../../mediaQueries'
import { LinkStyles } from '../../Links/style'

const fadeIn = keyframes`
  from {
    top: 0px;
    opacity: 0;
  }
  to {
    top: 75px;
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    top: 75px;
    opacity: 1;
  }
  to {
    top: 0px;
    opacity: 0;
  }
`

export const VerifyEmailWrapper = styled.div`
  display: flex;
  min-height: 80vh;
  justify-content: center;
  align-items: center;
`
export const VerifyEmailText = styled.h2`
  font-size: 38px;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  
  & > div.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 600px;

    @media (max-width: 767px) {
      width: 525px;
    }

    @media (max-width: 575px) {
      width: 324px;
    }
  }
`

export const Header = styled.h2`
  font-family: Lato;
  font-weight: 300;
  font-size: 36px;
  line-height: 43px;
  color: #FFFFFF;
  text-align: left;
`

export const Description = styled.p`
  font-family: Lato;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;

  & > span {
    font-weight: bold;
    word-break: break-all;
  }
`

export const ActionContainer = styled.div`
  padding: 24px;
  background: #1D1D1F;
  border-radius: 5px;
`

export const Text = styled.p`
  font-family: Lato;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;

  & > span {
    text-underline-offset: 4.5px;
    text-decoration: underline;
    ${LinkStyles};
    font-weight: 400;
    text-transform: none;
  }
`

export const Snackbar = styled.div`
  visibility: hidden;
  width: 600px;
  margin-left: -300px;
  background-color: #2C3339;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 75px;
  font-size: 17px;

  & > span {
    font-weight: bold;
  }

  &.show {
    visibility: visible;
    -webkit-animation: ${fadeIn} 1s, ${fadeOut} 1s 4s;
    animation: ${fadeIn} 1s, ${fadeOut} 1s 4s;
  }

  @media (max-width: 767px) {
    width: 525px;
    margin-left: -262px;
  }

  @media (max-width: 575px) {
    width: 324px;
    margin-left: -162px;
  }
`

export const SettingInput = styled.input`
  margin-bottom: 24px;
  width: 100%;
  max-width: 351px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  border: ${props => props.hasError
    ? '1px solid #E1774F' : '1px solid rgba(255, 255, 255, 0.3)'};
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  padding: 0px 10px;
  &:focus {
    outline: none;
    border: 1px solid #5fc4b8;
  }
  ${media.mobile`max-width: 302px;`}
`

export const SettingItemEditContent = styled.div`
  padding: 24px;
  background: #1d1d1f;
  border-radius: 5px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div.email {
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
  }

  span {
    margin-bottom: 10px;
    color: #B1BFC5;
    font-size: 16px;
    line-height: 19px;

    &.code-description {
      font-size: 14px;
      line-height: 17px;
      margin-top: 2px;
      margin-bottom: 12px;
    }

    &.error {
      color: #E1774F;
      margin-bottom: 12px;
    }

    &.header {
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 23px;
    }
  }

  div.subTitle {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 27px;
    font-weight: 300;
  }

  div {
    display: flex;

    button {
      margin-right: 12px;
      width: 120px;

      @media (max-width: 575px) {
        width: 145px;
      }
    }
  }
`
