export const FILE_FIELD_NAME = 'tuitionVoucher'
export const DASHBOARD_VOUCHER_URL = 'https://dashboard.outlier.org/?flag-voucher=true#/account/voucher'
export const VOUCHER_STATUS = {
  OUTSTANDING_VOUCHER: 'Outstanding Voucher',
  PENDING_APPROVAL: 'Pending Approval',
  APPROVED_VOUCHER: 'Approved Voucher',
  OUTSTANDING_PAYMENT_REQUEST: 'Outstanding Payment Request',
  APPROVED_PAYMENT_REQUEST: 'Approved Payment Request',
  PENDING_PAYMENT_REQUEST: 'Pending Payment Request'
}

export const VOUCHER_TYPE = {
  AMAZON: 'amazon'
}

export const POPUP_TYPE = {
  REMOVABLE: 'REMOVABLE',
  IRREMOVABLE: 'IRREMOVABLE'
}

// Days until drop deadline a student can dismiss the upload voucher popup, Past it cannot be dismissed.
export const DISMISS_DAYS = 17
