import React from 'react'
import PropTypes from 'prop-types'
import CourseButton from '../../CourseButton/CourseButton'
import {
  ButtonsContainer
} from './style'
import { BACK, NEXT, NEXT_ENABLED, imageClassMap } from '../../../Constants/pretest'

const Buttons = ({
  isLastQuestion,
  currentQuestionIndex,
  submitHandler,
  nextHandler,
  studentAnswer,
  currentAnswer,
  onBack,
  finishHandler
}) => {
  const submitAnswer = () => {
    submitHandler()
  }

  const submitAndFinishPretest = () => {
    submitHandler()
    finishHandler()
  }

  return (
    <ButtonsContainer>
      <CourseButton
        dataTestId='btn-submit-answer'
        className={{
          'btn-primary': !studentAnswer,
          'btn-secondary': studentAnswer
        }}
        style={{ marginRight: '12px' }}
        disabled={!currentAnswer || studentAnswer}
        onClick={isLastQuestion ? submitAndFinishPretest : submitAnswer}
      >
        Check
      </CourseButton>
      <CourseButton
        className={{
          'btn-secondary': true
        }}
        style={{ marginRight: '12px' }}
        dataTestId='btn-prev'
        onClick={onBack}
        disabled={!currentQuestionIndex}
      >
        <img src={imageClassMap[BACK]} alt='back arrow' />
      </CourseButton>
      <CourseButton
        className={{
          'btn-primary': studentAnswer,
          'btn-secondary': !studentAnswer
        }}
        dataTestId='btn-next'
        onClick={isLastQuestion ? finishHandler : nextHandler}
        disabled={!studentAnswer}
      >
        <img src={imageClassMap[studentAnswer ? NEXT : NEXT_ENABLED]} alt='next arrow' />
      </CourseButton>
    </ButtonsContainer>
  )
}

Buttons.displayName = 'Buttons'

Buttons.propTypes = {
  questionUUID: PropTypes.string,
  isLastQuestion: PropTypes.bool,
  finishHandler: PropTypes.func,
  submitHandler: PropTypes.func,
  nextHandler: PropTypes.func,
  answerSelected: PropTypes.bool,
  answerSubmitted: PropTypes.bool
}

export default Buttons
