import React from 'react'
import { Redirect } from 'react-router-dom'
import BackBreadcrumb from '../BackBreadcrumb/BackBreadcrumb'
import {
  PageOverview, Link, BoldText,
  PageContent, RejectionMessage,
  ExitTitle
} from './style'
import { TYPE_FORM_IDS } from '../../data/constants'

const getResponseText = (courseName, formId, history) => {
  const goToExitPage = () => history.push('/course-exit')
  const {
    drop,
    'drop_v2.1': dropV21,
    'auditDrop_v2.1': auditDropV21,
    auditDrop
  } = TYPE_FORM_IDS
  if ([drop, dropV21, auditDropV21, auditDrop].includes(formId)) {
    return {
      title: 'drop form',
      contentText:
  <PageOverview>
    <p>The drop deadline for this course has passed and your Drop Form submission <BoldText>has not been accepted</BoldText>.</p>
    <p>You have not successfully been removed from {courseName} Unless you take further action to remove yourself from this course, you will receive a letter grade at the end of your term.</p>
    <p>Please visit the <Link onClick={goToExitPage}>Course Exit page</Link> to determine what exit options may be available to you at this time.</p>
    <p>If you believe that this submission has been rejected in error, please contact us at <Link href='mailto:success@outlier.org'>success@outlier.org</Link>.</p>
  </PageOverview>
    }
  } else if (formId === TYPE_FORM_IDS.withdrawal) {
    return {
      title: 'withdrawal form',
      contentText:
  <PageOverview>
    <p>The withdrawal deadline for this course has passed and your Withdrawal Form submission <BoldText>has not been accepted</BoldText>.</p>
    <p>You are no longer eligible to exit this course and will receive a letter grade at the end of the course term.</p>
    <p>If you believe that this submission has been rejected in error, please contact us at <Link href='mailto:success@outlier.org'>success@outlier.org</Link>.</p>
  </PageOverview>
    }
  } else if (formId === TYPE_FORM_IDS.audit) {
    return {
      title: 'audit form',
      contentText:
  <PageOverview>
    <p>Your course has already ended and your submission for auditor status <BoldText>has not been accepted</BoldText>.</p>
    <p>You will receive a letter grade for your work during this course term.</p>
    <p>If you believe that this submission has been rejected in error, please contact us at <Link href='mailto:success@outlier.org'>success@outlier.org</Link>.</p>
  </PageOverview>
    }
  } else if (formId === TYPE_FORM_IDS.exception) {
    return {
      title: 'exception form',
      contentText:
  <PageOverview>
    <p>Your course has already ended and your petition for an exit exception <BoldText>has not been accepted</BoldText>.</p>
    <p>You will receive a letter grade for your work during this course term.</p>
    <p>If you believe that this submission has been rejected in error, please contact us at <Link href='mailto:success@outlier.org'>success@outlier.org</Link>.</p>
  </PageOverview>
    }
  }
}

const RejectionPage = ({ location, history }) => {
  const locationState = location.state

  if (!locationState) return <Redirect to='/course-exit' />

  const { courseName, formId } = locationState
  const { title, contentText } = getResponseText(courseName, formId, history)

  return (
    <>
      <BackBreadcrumb title='exit a course' path='/course-exit' />

      <ExitTitle>
        {title}
      </ExitTitle>

      <PageContent>
        <RejectionMessage>
          <img src='images/alerticon.png' alt='alert-icon' />
          <p>Your submission has not been accepted.</p>
        </RejectionMessage>

        {contentText}
      </PageContent>
    </>
  )
}

export default RejectionPage
