import styled from 'styled-components'
import media from '../../../mediaQueries'
import BackBreadcrumb from '../../BackBreadcrumb/BackBreadcrumb'
import { StyledRow } from '../../MyCourses/style'
import PageTitle from '../../PageTitle/PageTitle'

export const PageWrapper = styled.div`
  margin: 0 auto;
  margin-top: 64px;
  padding: 85px;
  max-width: 816px;
  ${media.mobile`
    padding: 85px 32px;
  `}
  ${media.smallMobile`
    padding: 85px 23px;
  `}
  .flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${media.mobile`
      display: block;
    `}
  }
`

export const BreadCrumbComponent = styled(BackBreadcrumb)`
  padding-left: 2px;
  margin-top: 0px;
  position: absolute;
  left: 64px;
  top: 24px;
  ${media.mobile`
    left: 32px;
  `}
  ${media.smallMobile`
    left: 23px;
  `}
`

export const PageTitleComponent = styled(PageTitle)`
  padding-top: 0px;
  padding-bottom: 26px;
  margin-top: -5px;
`
export const PageContentComponent = styled.div`
  margin-top: 36px;
`

export const MainBox = styled.div`
  width: 70%;
  // height: calc(100vh - 100px);
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  @media screen and (min-width: 767px) and (max-width: 1024px) {
    width: 90%;
  }
`

export const TakeText = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  a {
    color: #3fbcaf;
    font-weight: 400;
  }
`

export const OptionBox = styled.div`
  margin-top: 44px;
`

export const OptionHeading = styled.h4`
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 20px;
`

export const InnerCard = styled.div`
  background: #1d1d1f;
  border-radius: 5px;
  padding: 24px;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
`

export const PretextCard = styled.div`
  background: #232327;
  border-radius: 0px 0px 5px 5px;
  padding: 24px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #3fbcaf;
    margin-left: 12px;
    cursor: pointer;
    ${media.smallMobile`
      display: block;
      margin: 0px;
      margin-top: 12px;
    `}
  }
  button {
    width: 162px;
    margin-left: 24px;
  }
  ${media.smallMobile`
    display: block;
    text-align: center;
  `}
`

export const LockImg = styled.img`
  width: 10px;
  margin-right: 12px;
  height: 13px;
`

export const ImageBox = styled(StyledRow)`
  margin: 0px -15px !important;
  .course-card {
    width: 50%;
    padding: 0px 9px;
    ${media.smallMobile`
        width:100%;
        `}
  }
  .course-card .card > div {
    max-width: 100%;
    height: 288px;
  }
  .course-card .card {
    margin: 2px 0px;
  }
  ${media.smallMobile`
      display: block;
    `}
`

export const BackButton = styled.button`
  border: 1px solid #5fc4b8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5fc4b8;
  background-color: transparent;
  width: 234px;
  margin-top: 44px;
  margin-bottom: 4px;
  outline: none !important;
  box-shadow: unset !important;
  ${media.smallMobile`
      width: 100%;
    `}
`

export const CardLoader = styled.div`
  text-align: center;
`
