import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { PrimaryButton, SecondaryButton } from './QuestionComponent/style'
import {
  GlobalStyle,
  ExitModalHeader,
  Separator,
  OptionsLink,
  ButtonsContainer
} from './style'
import CloseIcon from '../../assets/icons/close-icon.svg'
import Arrow from '../../assets/icons/arrow.svg'
import utils from '../../utilities'
import api from '../../api'

const ExitModal = ({ show, setShow, opts }) => {
  const { recentAttempt, questionSetIndex, courseId } = opts || {}
  const closeModal = () => {
    setShow(false)
  }

  const saveStudentAttempt = async () => {
    const pretestId = recentAttempt?.testResult
      ? null : recentAttempt?.pretestAttempt || null

    await api.savePretestAttempt(
      courseId,
      pretestId,
      {
        testResult: null,
        questionSetIndex
      }
    )
  }

  const gotoDetailsPage = async () => {
    await saveStudentAttempt()
    window.location.hash = utils.getDetailsPagePath()
  }

  return (
    <Modal show={show} centered>
      <GlobalStyle />
      <ExitModalHeader>
        <p>Are you sure you want to exit?</p>
        <img
          data-testid='img-close'
          src={CloseIcon}
          alt='Close Exit Modal'
          onClick={closeModal}
        />
      </ExitModalHeader>
      <Separator />
      <Modal.Body>
        <p>
          Fear not! You won't receive a failing grade for exiting and
          can try again at any time. And if you'd rather skip the test,
          we've got other options for you.
        </p>
        <OptionsLink
          data-testid='link-show-options'
          onClick={async () => {
            await saveStudentAttempt()
            window.location.hash = utils.getDetailsPagePath() + '/alternatives'
          }}
        >
          see options
          <img src={Arrow} alt='See Options' />
        </OptionsLink>
        <ButtonsContainer>
          <PrimaryButton
            data-testid='btn-keep-going'
            onClick={closeModal}
            height='49px'
          >
            keep going
          </PrimaryButton>
          <SecondaryButton
            data-testid='btn-exit-pretest'
            onClick={gotoDetailsPage}
            height='49px'
          >
            exit pretest
          </SecondaryButton>
        </ButtonsContainer>
      </Modal.Body>
    </Modal>
  )
}

ExitModal.diaplayName = 'ExitModal'

export default ExitModal
