import React, { useEffect } from 'react'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import { GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import RadioInput from '../GGUEnrollmentFlow/RadioInput'
import { Title, Description, Page, PrimaryButton, RadioWrapper } from './style'
import { COURSE_REGISTRATION_PAGEVIEW, COURSE_REGISTRATION_PAGE_SUBMITTED } from '../../Constants/eventType'
import { isSegmentEventInLS, removeSegmentEventFromLS, setFiredSegmentEventsInLS } from '../../utilities/gguDegreeUtils'

const ChangeCourseQuantity = ({ courseQuantity, setCourseQuantity, setCurrentPage }) => {
  const { sendEvent } = useSegmentEvents()
  const courseSelectionHandler = () => {
    if (!courseQuantity) return

    sendEvent({
      eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
      properties: {
        page_name: 'Change Quantity',
        page_number: '3-2',
        confirmed_course_quantity: `${courseQuantity}`
      }
    })
    setCurrentPage(GGU_V2_ENROLLMENT_PAGES.COURSE_QUANTITY_WARNING)
  }

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: '3-2'
  }

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: '3-1'
    })
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'Change Quantity',
        page_number: '3-2'
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  return (
    <Page alignItems='flex-start'>
      <Title textAlign='left'>
        How many courses would you like to take this term?
      </Title>
      <Description>
        Plan to spend about 10-15 hours per week on each course.
      </Description>
      <RadioWrapper>
        <RadioInput
          label='2 courses'
          name='course_quantity'
          value='2 courses'
          onChange={() => setCourseQuantity(2)}
          checked={courseQuantity === 2}
        />
        <RadioInput
          className='mt-4'
          label='1 course'
          name='course_quantity'
          value='1 course'
          onChange={() => setCourseQuantity(1)}
          checked={courseQuantity === 1}
        />
      </RadioWrapper>
      <PrimaryButton disabled={!courseQuantity} onClick={courseSelectionHandler}>
        Continue
      </PrimaryButton>
    </Page>
  )
}

ChangeCourseQuantity.displayName = 'ChangeCourseQuantity'

export default ChangeCourseQuantity
