import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import { SlideArrowsContainer } from '../style'

export const MyCoursesContainer = styled.section`
  padding: 0 var(--desktop-layout-padding);
  margin-bottom: 64px;

  ${mediaqueries.mobile`
    padding: 0;
  `}
`
export const CoursesContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 32px;

  .splide:not(.is-overflow) .splide__arrows {
    display: none;
  }

  & .splide__track {
    overflow: clip;
    overflow-clip-margin: 60px;
  }

  ${mediaqueries.mobile`
    padding-bottom: 24px;
  `};
`
export const CourseTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding: 16px 24px;
  width: 315px;
  height: 430px;
  border-radius: 4px;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 28.34%,
      #000000 91.21%
    ),
    ${({ courseImage }) => `url(${courseImage})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;

  @media (any-hover: hover) {
    transition-delay: 0.3s;
    transition-duration: 0.3s;
    transition-property: transform, -webkit-transform, z-index;
    transition-timing-function: ease-in-out;
    z-index: 1;

    &:hover {
      transform: scale(1.2);
      z-index: 2;
      box-shadow: 3px 2px 16px 0px #00000073;

      & .course-tag {
        opacity: 0;
      }

      & .course-ctas {
        visibility: visible;
        position: static;
        transform: translateY(0);
      }

      & .course-ctas button {
        height: 39px;
        font-size: 11px;
      }

      & .course-countdown {
        font-size: 11px;
      }
    }
  }
`
export const CourseTitle = styled.h5`
  font-size: 23px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0px;
  margin-bottom: 12px;

  ${mediaqueries.mobile`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `}
`
export const OpeningClosingCountdown = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${({ warningType }) => {
    switch (warningType) {
      case 'orange':
        return '#FFBB55'
      default:
        return '#bababb'
    }
  }};

  ${mediaqueries.mobile`
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.7px;
  `}
`
export const CourseProgressContainer = styled.div`
  height: 6px;
  border-radius: 10px;
  background-color: #292929;
  clip-path: border-box;
  margin-top: 24px;

  ${mediaqueries.mobile`
    margin-top: 16px;
  `}
`
export const CourseProgress = styled.div`
  height: 100%;
  background-color: #5fc4b8;
  width: ${({ courseProgress }) => `${courseProgress}%`};
`
export const CourseTag = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  width: max-content;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 2px;
  color: #ffffff;
  padding: 6px 10px 7px 10px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  text-transform: uppercase;
  opacity: 1;
  transition-duration: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
`
export const MyCoursesSlideArrows = styled(SlideArrowsContainer)`
  & .splide__arrow {
    display: block;
    transition: opacity 0.3s ease;
  }
  & .splide__arrow:disabled {
    opacity: 0;
    pointer-events: none;
  }
  & .splide__arrow--next {
    transform: translateY(-50%) translateX(100%);
  }
  & .splide__arrow--prev {
    transform: translateY(-50%) translateX(-100%);
  }
`
export const CourseCTAContainer = styled.div`
  visibility: hidden;
  position: absolute;
  margin-top: 24px;
  transform: translateY(20px);
  transition-duration: 0.2s;
  transition-property: transform;
  transition-timing-function: ease-in-out;
  & button {
    height: 49px;
  }
`
export const FinishedCoursesContainer = styled.div`
  .splide:not(.is-overflow) .splide__arrows {
    display: none;
  }

  & .splide__track {
    overflow: clip;
    overflow-clip-margin: 60px;
  }
`

export const DropdownButton = styled.button`
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  color: #bababb;
  text-decoration: underline;
  text-underline-offset: 4px;
  background-color: transparent;
  padding: 0;
  border: 0;

  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }

  ${mediaqueries.mobile`
    font-size: 16px;
    line-height: 24px;
  `}
`
export const FinishedCoursesDropdown = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  padding-top: 24px;
`
export const FinishedCourseTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding: 24px;
  width: 315px;
  height: 209px;
  border-radius: 4px;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 28.34%,
      #000000 91.21%
    ),
    ${({ courseImage }) => `url(${courseImage}?h=260&sat=-100)`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;

  @media (any-hover: hover) {
    transition-delay: 0.3s;
    transition-duration: 0.3s;
    transition-property: transform, -webkit-transform, z-index;
    transition-timing-function: ease-in-out;
    z-index: 1;

    &:hover {
      transform: scale(1.25);
      z-index: 2;
      box-shadow: 3px 2px 16px 0px #00000073;
      padding: 21px;

      & .course-title {
        margin-bottom: 10px;
        font-weight: 400;
      }
      & .course-tag {
        opacity: 0;
      }
      & .final-grade {
        font-size: 11px;
      }
      & .course-cta {
        visibility: visible;
        position: static;
        transform: translateY(0);
        margin-top: 20px;
      }
      & .course-cta button {
        height: 39px;
        font-size: 11px;
      }
    }
  }
`
export const FinishedCourseTitle = styled.h5`
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0px;
  margin-bottom: 12px;
`
export const FinalGradeText = styled.span`
  font-size: 14px;
  line-height: 17px;
  color: #bababb;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;

  ${mediaqueries.mobile`
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.7px;
  `}
`
export const DisclosureArrow = styled.span`
  position: relative;
  flex: 0 0 48px;
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('/images/icons/arrow-up.svg');
    background-repeat: no-repeat;
    background-position: center;
    rotate: 180deg;
    scale: 1.5;
  }
`
export const CourseDisclosure = styled.details`
  background-color: #000000;
  padding: 12px 16px;
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & summary {
    display: block;
  }
  &[open] {
    ${DisclosureArrow}::after {
      transform: rotate(180deg);
    }
  }
`
export const MobileCourseTag = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
export const FlexDiv = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  ${({ flex }) => flex && `flex: ${flex};`};
  min-width: 0;
`
export const MobileCTAContainer = styled.div`
  margin-top: 24px;
`
