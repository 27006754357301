import React from 'react'
import UpcomingCard from './UpcomingCard'
import { isStudioCohort } from '../../utilities/course'
import StudioCard from './StudioCard'
import InProgressCard from './InProgressCard'
import { termState } from '../../Constants/cohort'
import { CompletedCard } from './CompletedCard'

export const RenderCard = ({
  course,
  courseTermState,
  handleEndedCourses
}) => {
  const {
    IS_UPCOMING, IN_PROGRESS, TERM_COMPLETED
  } = termState
  return (
    <>
      {isStudioCohort(course) && <StudioCard course={course} />}
      {courseTermState === IS_UPCOMING && <UpcomingCard course={course} />}
      {courseTermState === IN_PROGRESS && <InProgressCard course={course} />}
      {courseTermState === TERM_COMPLETED &&
        <CompletedCard
          course={course}
          handleEndedCourses={handleEndedCourses}
        />}
    </>
  )
}
