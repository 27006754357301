import styled from 'styled-components'
import { LinkStyles } from '../../Links/style'
import { SectionTitle } from '../../../styles/text'

export const Link = styled.h3`
  ${LinkStyles}
`

export const WhatsNextWrapper = styled.div`
  padding-bottom: 48px;
`

export const CourseTitle = styled(SectionTitle)`
  margin-bottom: 0;
`

export const ExploreMoreCoursesWrapper = styled.div`
  margin-bottom: 16px;
`
export const ExploreCatalog = styled.div`
  span { margin-right: 12px }
  ${LinkStyles}
`

export const MoreCoursesTitle = styled(SectionTitle)`
  margin-bottom: 24px;
  margin-top: 48px;
`
export const CoursesTitleDiv = styled.div`
  margin-left: 14px;
`
export const ViewAllDiv = styled.div`
  margin-left: 21px;
  margin-top: 10px;
`
export const CardsDiv = styled.div`
  margin-top: 19px;
`
