import React from 'react'
import Modal from 'react-bootstrap/Modal'
import {
  GlobalStyle,
  AlertModalHeaderWrapper,
  AlertModalTitleWrapper,
  AlertIcon,
  AlertModalParagraph,
  AlertModalFooterWrapper,
  AlertModalButton
} from './style'

const AlertModal = props => {
  const {
    show,
    title,
    titleIcon,
    text,
    onHide,
    buttonText,
    onButtonClick
  } = props
  return (
    <Modal
      show={show}
      dialogClassName='tiny-modal'
      onHide={() => onHide()}
      data-testid='alert-modal'
      centered
    >
      <GlobalStyle />
      <AlertModalHeaderWrapper>
        <AlertModalTitleWrapper>
          {
            titleIcon && <AlertIcon src={titleIcon} />
          }
          {title}
        </AlertModalTitleWrapper>
      </AlertModalHeaderWrapper>
      <Modal.Body>
        <AlertModalParagraph> {text} </AlertModalParagraph>
      </Modal.Body>
      <AlertModalFooterWrapper>
        <AlertModalButton
          onClick={() => onButtonClick()}
        >
          {buttonText}
        </AlertModalButton>
      </AlertModalFooterWrapper>
    </Modal>
  )
}

export default AlertModal
