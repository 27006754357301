import styled from 'styled-components'

export const SectionPageTitle = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 45px;
  border-bottom: 2px solid #2b2d31;
`

export const TitleHeading = styled.h1`
  font-size: 35px;
  font-weight: 300;
  display: inline;

  @media (max-width: 767px) {
    display: block;
  }
`
export const Description = styled.p`
  width: 100%;
  padding: 0px;
  padding-top: 8px;
  margin: 0px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
`
export const Link = styled.a`
  font-family: 'Lato';
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || '300'};
  font-size: ${({ fontSize }) => fontSize || '18px'};
  line-height: 24px;
  color: #5FC4B8!important;
  text-decoration: underline!important;
`
