import React, { useContext, useState } from 'react'
import { isEqual } from 'lodash'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  replaceRecommendedCoursesInLS,
  getGGUCourseStatus
} from '../../utilities/gguDegreeUtils'
import {
  COMPLETED_NOT_REPEATABLE,
  ENROLLED,
  HAS_PRE_REQUISITE,
  IN_PRODUCTION,
  NOT_ENROLLABLE
} from '../../Constants/CourseStatus'
import {
  GroupedCourseSlotContainer,
  SelectedCourseContainer,
  GroupedCourseImageContainer,
  CourseMainContainer,
  CourseNameContainer,
  ChoiceDescription,
  StartDateContainer,
  GroupOptionsContainer,
  OptionHeader,
  CourseIcon,
  OptionDescription
} from './styles'
import SlotOptions from './SlotOptions'
import { getCourseAssets } from '../../utilities/course'

const GroupedCourseSlot = (props) => {
  const { catalogCourses, degreeData } = useContext(AppContext)
  const {
    index,
    recommendationGroup,
    course = {},
    outlierCourses,
    studentAttempts,
    prospects,
    semesterStart,
    setShouldReloadPage,
    noOfCoursesInSlots,
    courseProjectedGrades,
    advisorApproved
  } = props
  const groupCourses = degreeData?.filter(course => (
    isEqual(course?.recommendationGroup, recommendationGroup)
  ))

  const [selectedCourseId, setSelectedCourseId] = useState(course?.courseId)
  const selectedCourse =
    groupCourses?.find(course => course.courseId === selectedCourseId)
  const { courseId, courseNameText } = selectedCourse || course
  const { courseIcon } = getCourseAssets(catalogCourses, courseId)
  const courseHeader = courseNameText || `${recommendationGroup} requirement`
  const courseOptionNames =
    groupCourses?.map(course => course?.courseNameText)

  const getCourseOptionsList = (courseOptionNames) => {
    if (!courseOptionNames?.length) return []
    if (courseOptionNames?.length <= 2) return courseOptionNames.join(' or ')
    const lastOptionWithOr = `or ${courseOptionNames?.pop()}`
    courseOptionNames.push(lastOptionWithOr)
    return courseOptionNames.join(', ')
  }

  const getCourseOption = (course) => {
    const { courseNameText, courseId } = course
    const catalogPageLink = `#/catalog/${courseId}`
    const catalogCourse =
      catalogCourses?.find(course => course?.uuid?.uuid === courseId)
    const {
      prerequisite,
      oneLineDescription: {
        descriptionText
      } = {}
    } = catalogCourse || {}

    const shouldDisableOption = [
      COMPLETED_NOT_REPEATABLE,
      IN_PRODUCTION,
      ENROLLED,
      HAS_PRE_REQUISITE,
      NOT_ENROLLABLE
    ].includes(getGGUCourseStatus({
      outlierCourses,
      studentAttempts,
      uuid: courseId,
      prospects,
      courseProjectedGrades,
      prerequisite
    }))

    return (
      <div
        key={courseId}
        data-testid='course-option'
      >
        <OptionHeader data-testid='option-header'>
          <input
            className='radio'
            type='radio'
            value={courseId}
            onChange={e => {
              replaceRecommendedCoursesInLS([e.target.value], selectedCourseId)
              setSelectedCourseId(e.target.value)
            }}
            checked={courseId === selectedCourseId}
            disabled={shouldDisableOption}
          />
          {courseNameText}
        </OptionHeader>
        <OptionDescription>
          {descriptionText} <a href={catalogPageLink}>Learn more</a>
        </OptionDescription>
      </div>
    )
  }

  return (
    <GroupedCourseSlotContainer data-testid='grouped-slot'>
      <SelectedCourseContainer>
        <GroupedCourseImageContainer data-testid='course-icon-wrapper'>
          {!courseId
            ? '?'
            : <CourseIcon src={courseIcon} className='course-icon' data-testid='course-icon' />}
        </GroupedCourseImageContainer>
        <CourseMainContainer>
          <CourseNameContainer>
            <div>
              {courseHeader}
            </div>
            <ChoiceDescription data-testid='instruction'>
              Choose between {getCourseOptionsList(courseOptionNames)} to fulfill your {recommendationGroup} requirement.
            </ChoiceDescription>
          </CourseNameContainer>
          {courseIcon &&
            <StartDateContainer data-testid='start-date'>
              Starts {semesterStart}
            </StartDateContainer>}
        </CourseMainContainer>
        <SlotOptions
          course={selectedCourse || course}
          index={index}
          setShouldReloadPage={setShouldReloadPage}
          noOfCoursesInSlots={noOfCoursesInSlots}
          advisorApproved={advisorApproved}
        />
      </SelectedCourseContainer>
      <GroupOptionsContainer>
        {groupCourses?.map(getCourseOption)}
      </GroupOptionsContainer>
    </GroupedCourseSlotContainer>
  )
}

GroupedCourseSlot.displayName = 'GroupedCourseSlot'
export default GroupedCourseSlot
