import 'react-datepicker/dist/react-datepicker.css'
import styled, { createGlobalStyle } from 'styled-components'

export const OverrideDatePickerStyles = createGlobalStyle`
    .hide-portal {
        display: none!important;
    }
    .display-portal {
        display: flex!important;
    }
    @media(min-width: 0px) {
        .react-datepicker__portal {
            background-color: rgba(0, 0, 0, 0.5);
        }
        .react-datepicker {
            background-color: #25272B;
            width: 279px;
            margin-top: 24px;
            border: none;
        }
        .react-datepicker__header {
            background-color: #25272B;
            border-bottom: none;
        }
        .react-datepicker__header:not(.react-datepicker__header--has-time-select){
            border: none;
        }
        .react-datepicker__current-month {
            color: white;
            margin-top: 27px;
            font-style: normal;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px!important;
            line-height: 17px;
            letter-spacing: 1px;
        }
        .react-datepicker__navigation {
            margin-top: 27px;
        }
        .react-datepicker__month-container {
            float: none;
            background-color: #25272B;
            border-radius: 5px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            padding-bottom: 15px;
        }
        .react-datepicker__day {
            margin: 4px;
            padding: 0px;
            height: 36px;
            width: 36px!important;
            line-height: 36px!important;
            color: #5FC4B8!important;
            border: 1px solid #5FC4B8;
            border-radius: 50%;
        }
        .react-datepicker__day--disabled {
            color: rgba(255,255,255,0.5)!important;
            border: none!important;
        }
        .react-datepicker__day--outside-month {
            color: rgba(255,255,255,0.4);
        }
        .react-datepicker__day:hover {
            color: black;
            border-radius: 50%;
        }

        .react-datepicker__day--selected {
            border-radius: 50%;
            color: black!important;
            background-color: #5FC4B8;
        }

        .react-datepicker__day--selected:hover {
            border-radius: 50%;
            color: black;
            background-color: #5FC4B8;
        }
        .react-datepicker__day--keyboard-selected {
            border-radius: 50%;
            color: black;
            background-color: #5FC4B8;
        }
        .react-datepicker__day--keyboard-selected:hover {
            background-color: white;
        }
        
        .react-datepicker__day-names {
            margin-top: 14px;

            div:nth-child(1), div:nth-child(7) {
                display: none;
            }

            div {
                color: transparent;
                width: 36px!important;
                line-height: 24px!important;
                height: 24px!important;
                margin: 4px;
            }

            div::after {
                color: white;
                margin-left: -15px;
            }

            div:nth-child(2)::after {
                content: "M";
            }
            div:nth-child(3)::after {
                content: "T";
            }
            div:nth-child(4)::after {
                content: "W";
            }
            div:nth-child(5)::after {
                content: "T";
            }
            div:nth-child(6)::after {
                content: "F";
            }
        }

        .react-datepicker__day--weekend {
            display: none;
        }
        .react-datepicker__month {
            margin: 0px;
            background-color: #25272B;
        }
        .react-datepicker__navigation--previous {
            display: flex;
            justify-content: center;
            width: 36px;
            left: 33px;
            align-items: center;
            span {
                display: none!important;
            }

            ::after {
                content: '';
                display: block;
                width: 14px;
                height: 14px;
                background-image: url(/images/icons/left-arrow.svg);
                background-position: center;
                background-size: cover;
            }
        }

        .react-datepicker__navigation--next {
            display: flex;
            width: 36px;
            justify-content: center;
            right: 33px;
            align-items: center;
            span {
                display: none!important;
            }

            ::after {
                content: '';
                display: block;
                width: 14px;
                height: 14px;
                background-image: url(/images/icons/right-arrow.svg);
                background-position: center;
                background-size: cover;
            }
        }

        @media(min-width: 768px) {
            .react-datepicker {
                width: 528px;
            }

            .react-datepicker__day {
                margin: 4px 35px;
            }
            .react-datepicker__day-name {
                margin: 4px 35px !important;
            }

            .react-datepicker__month-container {
                width: 528px;
            }

        }
}
  
`

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 327px;
  background: #25272b;
  color: white;
  overflow: hidden;
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 576px;
  }
`
export const DatePickerTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  height: 72px;
  color: white;
  background-color: #25272b;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  div:nth-child(2) {
    width: 14px;
    height: 14px;
    background-image: url(/images/icons/icon-close.svg);
    background-size: cover;
    cursor: pointer;
  }
`
export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 24px;

  @media (min-width: 768px) {
    width: 145px;
    padding: 24px 0px;
    margin-left: 24px;
    margin-right: auto;
  }
`
export const SelectDateButton = styled.button`
  width: 100%;
  height: 49px;
  color: black;
  background-color: #5fc4b8;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  border-radius: 5px;
  border: none;
  outline: none;
`
export const DatePickerContainer = styled.div`
  position: 'relative';
  width: 279px;

  @media (min-width: 768px) {
    width: 528px;
  }
`
