import styled, { css } from 'styled-components'
import media from '../../../mediaQueries'
import { PrimaryButton, SecondaryButton } from '../../Buttons/style/index'

export const ContactWrapper = styled.div`
  ${props => props.isGGUStudentView
    ? css`
      max-width: 1200px;
      margin: 112px auto 0 auto;
      padding: 0px 110px;

      ${media.tablet`
        padding: 0px 64px;
      `}

      ${media.mobile`
        padding: 0px 24px;
      `}
    `
    : css`
      margin-top: 122px;
      margin-bottom: 0px;
      margin-left: auto;
      margin-right: auto;
      max-width: 994px;
      ${media.desktop`margin-top: 117px; width: 784px;`}
      ${media.tablet`width: 560px; margin-top: 123px;`}
      ${media.mobile`
        width: 100%;
        margin: 128px 0 0 0;
        padding: 0px 12px 0px 12px;
      `}
      ${media.smallMobile`
        width: 100%;
        padding: 0px 12px 0px 12px;
      `}
  `}
`
export const ContactHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 38px;
  justify-content: space-between;
  ${media.mobile`
    flex-direction: column;
    text-align: left;
  `}
`
export const EmailTextWrapper = styled.div`
  text-align: right;
  margin-top: 7px;
  ${media.tablet`margin-top: 1px;`}
  ${media.mobile`text-align: left;`}
  ${media.smallMobile`margin-top: -1px;`}
`
export const EmailText = styled.h3`
  font-size: 16px;    
  font-weight: 400;
  color: #C1CFD9;
  margin-top: -7px;
  line-height: 19px;
`
export const Span = styled.span`
  margin-top: 9px;
`
export const SecondaryHeading = styled.h1`
  font-size: 32px;
  font-weight: 300;
  text-transform: uppercase;
  width: 63%;
  padding: 0px!important;
  margin-top: 6px;
  ${media.tablet`margin-top: 0px;`}
  ${media.mobile`margin-bottom: 12px;`}
`
export const BreakLine = styled.div`
  ${props => props.isGGUStudentView
    ? css`
      margin: 48px 0 35px 0;
      border-bottom: 2px solid #2B2D31;
    `
    : css`
      width:100%;
      height: 0;
      border: 2px solid #2B2D31;
      margin-top: 33px;
      margin-bottom: 46px;
      ${media.desktop`margin-bottom: 51px;`}
      ${media.tablet`
        margin-top: 41px;
        margin-right: 12px;
      `}
      ${media.mobile`
        margin-top: 105px;
        margin-bottom: 63px;
      `}
      ${media.smallMobile`
        margin-top: 73px;
        margin-bottom: 43px;
      `}
    `
  }
`
export const Form = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${media.tablet`
    flex-direction: column;
    align-items: center;
  `}
`
export const FormDiv = styled.div`
  max-width: 500px;
  min-width: 500px;
  // margin-right:143px;
  text-align: center;
  width: 100%;
  ${media.desktop`
  max-width: 375px; min-width:375px; margin-right:60px;`}
  ${media.tablet`
    max-width: 562px; min-width:562px; margin-left:auto; margin-right:auto;
  `}
  ${media.mobile`min-width: 0; max-width: none !important;`}
  ${media.smallMobile`min-width: 0;`}
`

export const FormTextArea = styled.textarea` 
  width: 100%;
  height: 200px;
  color: white;
  font-size: 18px;
  padding: 5px;
  background-color:rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 11px 0 16px 0;
  ${media.desktop`width: 375px;`}
  ${media.tablet`
    width: 100%;
    margin-top: 19px;
  `}
  ${media.smallMobile`
    margin-top: 19px;
  `}
`
export const SubmitButton = styled(PrimaryButton)`
  width: 150px;
`
export const SubmitButtonDiv = styled.div`

`
export const FormHeading = styled.h4`
  font-size: 24px;
  line-height: 28.8px;
  font-weight: 300;
  margin-bottom: 12px;
  ${media.tablet`
    width: 355.56px;
    height: 29px;
    margin-bottom: 0;
  `}
  ${media.mobile`
    width: 100%;
    margin:0px;
  `}
`
export const CardDiv = styled.div`
  background-color: #1D1D1F;
  max-width: 351px;
  min-width: 351px;
  padding: 24px 13px 12px 12px;
  @media(max-width:802px) {
    width: 20px;
  }
  ${media.desktop`
    height: 313px;
    padding-bottom: 7%;
  `}
  ${media.tablet`
    margin-top: 64px;
    max-width: 562px;
    min-width: 562px;
    height: 240px;
    padding: 23px 12px 12px 12.22px;
  `}
  ${media.mobile`
    max-width: none !important;
    min-width:0;
    width: 100%;
  `}
  ${media.smallMobile`
    height: 313px;
    min-width:0;
    width:100%;
  `}
`
export const Card = styled.div`
  width: 100%;
`
export const CardHeading = styled(FormHeading)`
  width: 100%;
  font-weight: 300;
  font-size: 18px;
`
export const SupportText = styled(CardHeading)`
  font-size: 18px;
  line-height: 21.6px;
  ${media.tablet`
    width: 100%;
    height: 44px;
    margin-top: 6px;
  `}
  ${media.mobile`
    margin-top: 12px;
  `}
`
export const EmailJoinDiv = styled.div`
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  margin-right: 12px;
  justify-content: space-between;
  ${media.tablet`
    flex-direction: row !important;
    justify-content: space-between;
  `}
  ${media.mobile`flex-direction: column;`}
  ${media.smallMobile`
    flex-direction: row !important;
    height: 94px;
  `}
`
export const EmailInput = styled.input`
  width: 100%;
  height: 40px;
  color: white;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 12px;
  padding: 10px 11px;
  font-size: 16px;
  line-height: 19px;
  &::placeholder{
    font-size: 16px;
    line-height: 19px;
    color: #DFDFDF;
  }
  ${media.tablet`
    width: 67.7%;
    height: 40px;
    margin-right: 6px;
    margin-top: 0px;
    margin-bottom: 0px;
  `}
  ${media.smallMobile`
    width: 100%;
    height: 40px;
    margin-right: 0px;
    margin-bottom: 12px;
  `}
`
export const JoinButton = styled(SecondaryButton)`
  width: 100%;
  border-radius: 5px;
  ${media.tablet`
    width: 30%;
    margin-bottom: 2px;
    float: right;
  `}
  ${media.smallMobile`
    width: 100%;
    margin-bottom: 0;
  `}
`
export const Paragraph = styled.p`
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin: 24px 1px 24px 0;
 ${media.tablet`
    width: 100%;
    height: 20px;
    margin-top: 12px;
  `}
  ${media.mobile`margin-top: 24px;`}
`
export const Link = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  text-decoration: underline;
  color: #5FC4B8;
  cursor: pointer;
`
export const SectionWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 24px;

  ${media.mobile`
    flex-direction: column;
  `}
`
export const SectionTitle = styled.h2`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;

  img {
    margin-top: 2px;
    margin-right: 12px;
  }
`
export const SectionDescription = styled.p`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  opacity: 0.9;
`
export const Tile = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 49%;
  align-items: center;
  margin-bottom: 12px;
  padding: 12px;
  background: #0A2A2D;
  border-radius: 5px;
  cursor: pointer;

  ${media.mobile`
    width: 100%;
  `}

  :hover {
    background: #0F3E43;
  }

  img {
    transform: rotate(-90deg);
  }
`
export const TileContent = styled.div`
  display: flex;
  flex-direction: column;
`
export const TileTitle = styled.h3`
  margin-bottom: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF;
`
export const TileDescription = styled.p`
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #B1BFC5;
`
