import React, { useContext } from 'react'

import PageTitle from '../PageTitle/PageTitle'
import PageContent from '../PageContent/PageContent'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { AppContext } from '../ContextProvider/ContextProvider'

import Courses from './Courses'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { FAILURE } from '../ErrorMessage/errorType'

const MyCourses = () => {
  const { courses, showFailure, isContextLoading } = useContext(AppContext)

  if (showFailure) return <ErrorMessage errorType={FAILURE} />
  if (!courses || isContextLoading) return <LoadingSpinner />
  return (
    <>
      <PageTitle title='My Courses' />
      <PageContent>
        <div data-testid='courses'><Courses list={courses} /></div>
      </PageContent>
    </>
  )
}

export default MyCourses
