import React, { useState } from 'react'
import api from '../../../api'
import { WebAuth } from 'auth0-js'
import { EDIT_EMAIL_MODES } from '../../../Constants/accountArea'
import { Spinner } from 'react-bootstrap'
import { ErrorMessage, InputField, EditTitle, SaveButton } from '../style'

const { EMAIL_UPDATE_SUCCESS } = EDIT_EMAIL_MODES

const webAuth = new WebAuth({
  domain: process.env.REACT_APP_DOMAIN,
  clientID: process.env.REACT_APP_CLIENT_ID
})

function CodeVerification ({ newEmail, password, setCurrentMode }) {
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState('')
  const [serverError, setServerError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleCodeInputChange = e => {
    setCodeError('')
    setServerError('')
    setCode(e.target.value)
  }

  const getConfirmButtonContent = () => {
    if (isLoading) return <Spinner animation='border' />
    if ((codeError || serverError) && code) return 'resend'
    return 'confirm'
  }

  const signupUser = () => {
    setIsLoading(true)

    try {
      webAuth.signup({
        connection: 'Username-Password-Authentication',
        email: newEmail,
        password: password
      }, err => {
        if (err) {
          const { code, description } = err
          const isExisting = code === 'user_exists'
          const isDev = process.env.NODE_ENV === 'development' && code === 'invalid_signup'
          // To account for users that are trying to reuse an old email
          // (already in auth0) and using an old email in development
          if (isExisting || isDev) return setCurrentMode(EMAIL_UPDATE_SUCCESS)

          return setServerError(description)
        }

        setCurrentMode(EMAIL_UPDATE_SUCCESS)
      })
    } catch (error) {
      setServerError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const verifyCodeAndUpdateEmail = async () => {
    if (!code) {
      return setCodeError('Please enter a valid code.')
    }

    // Resend code

    if (codeError || serverError) {
      setCode('')
      setCodeError('')
      setServerError('')
      setIsLoading(true)
      const result = await api.verifyEmailAndSendCode({ newEmail })
      setIsLoading(false)
      const { success, message } = result || {}
      if (!success) {
        setServerError(message)
      }
      return
    }

    // Verify code, update email and signup

    setIsLoading(true)
    const verifyResponse = await api.verifyEmailAndSendCode({ code })
    setIsLoading(false)
    const { success: verifySuccess, message: verifyMessage } = verifyResponse || {}
    if (!verifySuccess) {
      return setCodeError(verifyMessage)
    }

    setIsLoading(true)
    const updateResponse = await api.updateNewEmailInATAndAC(newEmail)
    setIsLoading(false)
    const { success: updateSuccess, message: updateMessage } = updateResponse || {}
    if (!updateSuccess) {
      return setCodeError(updateMessage)
    }

    signupUser()
  }

  return (
    <>
      <EditTitle>Verification code</EditTitle>
      <p className='fs-exclude' style={{ marginBottom: '12px' }}>
        Enter the verification code in the email sent to {newEmail} to confirm the change.
      </p>

      <InputField
        autoFocus
        value={code}
        disabled={isLoading}
        hasError={codeError || serverError}
        onChange={handleCodeInputChange}
        data-testid='verification-code-input'
      />

      {serverError && (
        <ErrorMessage lastItem={!codeError}>{serverError}</ErrorMessage>
      )}
      {codeError && (
        <ErrorMessage lastItem>{codeError}</ErrorMessage>
      )}

      <SaveButton
        disabled={isLoading}
        onClick={verifyCodeAndUpdateEmail}
        data-testid='update-email-button'
      >
        {getConfirmButtonContent()}
      </SaveButton>
    </>
  )
}

export default CodeVerification
