import React from 'react'
import config from '../../config'

import {
  getNextAvailableSection,
  getVisitedPracticeExerciseInfo
} from '../../utilities/section'

import WidgetState from './WidgetState'

const PracticeExercisesCard = ({
  course,
  courseProgress,
  chapterNo,
  chapterUuid,
  sectionNo
}) => {
  const { id } = course
  const { studentProgress, sectionData } = courseProgress
  const {
    studentAnswers,
    'practice-exercises-complete': practiceExercisesComplete
  } = studentProgress

  const {
    section_uuid: sectionUuid,
    section_exe: sectionExe
  } = sectionData
  const { practice_exercises: practiceExercises } = sectionExe

  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const isFirstSection = chapterNo === 1 && sectionNo === 1
  const visitedPracticeExercises =
      getVisitedPracticeExerciseInfo(studentAnswers, practiceExercises, isFirstSection)

  const {
    title,
    id: practiceExerciseId,
    remaining,
    visited,
    completed
  } = visitedPracticeExercises

  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`
  const problemSetUrl = `${sectionUrl}/${practiceExerciseId}`

  const isMultipleVisitedQuestions = visited > 1 ? 's' : ''
  const isMultipleRemainingQuestions = remaining > 1 ? 's' : ''

  const isSectionPracticeExercisesComplete =
        practiceExercisesComplete[sectionUuid]

  const sectionNumber = `Section ${chapterNo}.${sectionNo}`
  const chapterInfo = {
    chapterUuid,
    chapterNo,
    sectionNo
  }

  const getNextActivity = () => {
    const {
      practice_terms: practiceTerms,
      practice_exercises: practiceExercises,
      quiz
    } = sectionExe

    // Check practice exercises section is completed
    if (
      !isSectionPracticeExercisesComplete &&
        practiceExercises
    ) return `${sectionNumber} Practice Exercises`

    if (practiceTerms) return `${sectionNumber} Practice Terms`
    if (quiz) return `${sectionNumber} Quizzes`

    if (isSectionPracticeExercisesComplete) {
      const { nextChapterNo, nextSectionNo } =
          getNextAvailableSection(course, chapterInfo)

      return `Section ${nextChapterNo}.${nextSectionNo}`
    }
  }

  // Practice Exercises - In progress
  let dataTestId = 'practiceExercises-in-progress'
  let description = `You completed ${visited}
   question${isMultipleVisitedQuestions} in ${title} of ${sectionNumber}
    Practice Exercises. Continue to complete the remaining ${remaining}
     question${isMultipleRemainingQuestions}.`
  let primaryCTAUrl = problemSetUrl

  // Practice Exercises - Completed
  if (completed) {
    primaryCTAUrl = sectionUrl
    if (isSectionPracticeExercisesComplete) {
      const { nextSectionUrl } = getNextAvailableSection(course, chapterInfo)
      primaryCTAUrl = nextSectionUrl
    }

    description = `You completed ${title} of ${sectionNumber}
     Practice Exercises. Continue to ${getNextActivity()}`
    dataTestId = 'practiceExercises-completed'
  }

  return (
    <div data-testid={dataTestId}>
      <WidgetState
        description={description}
        primaryBtnTxt='Continue'
        primaryBtnFunction={() => {
          window.location.href = primaryCTAUrl
        }}
      />
    </div>
  )
}

export default PracticeExercisesCard
