import styled from 'styled-components'
import media from '../../../mediaQueries'
import { PrimaryButton, SecondaryButton } from '../../Buttons/style/index'
import { LinkStyles } from '../../Links/style'

export const Link = styled.p`
  ${LinkStyles}
`

export const CustomButton = styled(SecondaryButton)`
  border: 1px solid #5fc4b8;
  box-sizing: border-box;
  border-radius: 5px;
  color: #5fc4b8;
`

export const Button = styled(PrimaryButton)`
  margin-top: 32px;
  width: ${props => props.width || '120px'};

  &:focus {
    border: 0 none;
  }
`

export const Container = styled.div`
  margin: 88px 103px 0 103px;
  display: flex;
  flex-direction: column;

  & div.addTokens {
    display: flex;
    justify-content: center;
  }
  
  ${media.mobile`
    margin-left: 24px;
    margin-right: 24px;
  `}
`

export const AddTokensContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  width: 600px;

  ${media.mobile`
    width: 525px;
  `}

  ${media.smallMobile`
    width: 324px;
  `}

  & div {
    &.header {
      display: flex;
      align-items: baseline;

      & > p {
        margin-bottom: 0;

        &:first-child {
          flex: 1;
        }

        &:last-child {
          color: #B1BFC5;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
        }
      }
    }

    &.counts {
      display: flex;
      font-weight: normal;

      & > p {
        margin-bottom: 0;

        &.dot {
          margin-top: -6px;
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    }
  }
`

export const TokensCounter = styled.div`
  margin-top: 35px;
  margin-bottom: 0;
  display: flex;

  & p {
    margin-bottom: 0;
  }

  & > div.value {
    background-color: #1D1D1F;
    border-radius: 5px;
    display: flex;
    padding: 24px;
    margin-right: 6px;

    & > p {
      font-weight: 300;
      font-size: 64px;
      line-height: 64px;
      margin-right: 24px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;

      & > p {
        &:last-child {
          margin-top: 8px;
          color: #B1BFC5;
        }
      }
    }
  }

  & > div.adjust-value {
    display: flex;
    flex-direction: column;
    color: #5FC4B8;

    & > div {
      display: flex;
      align-items: center;
      background-color: #1D1D1F;
      border-radius: 5px;
      flex: 1;
      padding-left: 14px;
      padding-right: 14px;
      cursor: pointer;

      & > p {
        &.plus {
          font-size: 22px;
          font-weight: 400;
        }

        &.minus {
          border-bottom: 2px solid;
          width: 12px;
        }
      }

      &:last-child {
        margin-top: 6px;
      }
    }
  }
`
