import React, { useEffect, useRef } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import {
  CourseCTAContainer,
  CourseDescription,
  PrimaryButton,
  SlideArrowsContainer
} from '../style'
import { getCourseAssets } from '../../../utilities/course'
import {
  ChangeCourseContainer,
  CourseDetails,
  CourseIcon,
  CourseTitle,
  Gradient,
  LargeHeroContainer,
  RecommnededCourseTag,
  Separator,
  SlideContainer,
  SlidePaginationWrapper,
  WelcomeMessage
} from './style'
import WhatsNext from '../WhatsNext/WhatsNext'
import { getRecommendedCourseLink } from '../utils'

function LargeHeroSlide ({
  coursesToDisplay,
  catalogCourses,
  randomWelcomeNote,
  studentName,
  showRecommendedCourses
}) {
  const heroSlideRef = useRef(null)
  const changeCourseSlideRef = useRef(null)

  useEffect(() => {
    if (
      heroSlideRef.current &&
      changeCourseSlideRef.current &&
      changeCourseSlideRef.current.splide
    ) {
      heroSlideRef.current.sync(changeCourseSlideRef.current.splide)
    }
  }, [heroSlideRef, changeCourseSlideRef])

  return (
    <LargeHeroContainer data-testid='large-hero-slide'>
      <Splide
        aria-label='For-Credit Courses Slide'
        ref={heroSlideRef}
        hasTrack={false}
        options={{
          type: 'fade',
          keyboard: 'focused',
          rewind: true,
          slideFocus: true,
          autoplay: true,
          interval: 3000,
          pauseOnHover: true
        }}
      >
        <SlideArrowsContainer className='splide__arrows'>
          <button className='splide__arrow splide__arrow--prev' />
          <button className='splide__arrow splide__arrow--next' />
        </SlideArrowsContainer>
        <SplideTrack>
          {coursesToDisplay.map((course) => {
            const { id, displayName, description } = course
            const {
              courseHeroImage,
              courseIcon,
              longDescription
            } = getCourseAssets(catalogCourses, id)

            return (
              <SplideSlide key={id}>
                <SlideContainer heroImage={courseHeroImage}>
                  <Gradient />
                  <WelcomeMessage data-testid='welcome-message'>
                    {randomWelcomeNote},&nbsp;
                    <span className='fs-exclude'>{studentName}</span>!
                  </WelcomeMessage>
                  {showRecommendedCourses && (
                    <RecommnededCourseTag data-testid='recommended-course-tag'>
                      Recommended
                    </RecommnededCourseTag>
                  )}
                  <CourseDetails data-testid='course-details'>
                    <CourseIcon
                      courseIcon={courseIcon}
                      data-testid='course-icon'
                    />
                    <CourseTitle>{displayName}</CourseTitle>
                    {showRecommendedCourses ? (
                      <>
                        <CourseDescription>
                          {longDescription || description}
                        </CourseDescription>
                        <CourseCTAContainer>
                          <PrimaryButton
                            width='280px'
                            onClick={() => {
                              const recommendedCourseLink = getRecommendedCourseLink(
                                course
                              )

                              window.location.href = recommendedCourseLink
                            }}
                            data-testid='primary-button'
                          >
                            Learn More
                          </PrimaryButton>
                        </CourseCTAContainer>
                      </>
                    ) : (
                      <WhatsNext course={course} desktopHero />
                    )}
                  </CourseDetails>
                </SlideContainer>
              </SplideSlide>
            )
          })}
        </SplideTrack>
        <SlidePaginationWrapper className='splide__pagination' />
      </Splide>
      <Separator />
      <ChangeCourseContainer data-testid='large-hero-subslide'>
        <Splide
          aria-label='Slide for Selecting a Different For-Credit Course'
          ref={changeCourseSlideRef}
          hasTrack={false}
          options={{
            type: 'slide',
            keyboard: 'focused',
            slideFocus: true,
            arrows: false,
            isNavigation: true,
            pagination: false,
            rewind: true,
            gap: '56px',
            fixedWidth: 'auto',
            focus: 'center',
            padding: {
              right: '64px',
              left: '64px'
            }
          }}
        >
          <SplideTrack>
            {coursesToDisplay.map((course) => {
              const { displayName, id } = course
              return <SplideSlide key={id}>{displayName}</SplideSlide>
            })}
          </SplideTrack>
        </Splide>
      </ChangeCourseContainer>
    </LargeHeroContainer>
  )
}

LargeHeroSlide.displayName = 'LargeHeroSlide'
export default LargeHeroSlide
