import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../ContextProvider/ContextProvider'

import {
  Header, SidebarContainer, Sidebar, Button, UserIcon, SecurityIcon, CertificateIcon,
  DropdownButton, DropdownMenu, DropdownItem, DropdownContainer, CaretIcon, CoinsIcon,
  BookIcon, WalletIcon, VoucherIcon, CalendarIcon, NewBadge, SubscriptionIcon,
  SubscriptionWhiteIcon
} from './style'
import {
  CONTENT,
  ACCOUNT_PATH,
  PERSONAL_INFO, LOGIN_SECURITY, TRANSCRIPTS,
  VOUCHER_UPLOAD,
  AMAZON_REFUND,
  STUDY_BLOCKS,
  SUBSCRIPTION,
  COURSE_MANAGEMENT, TOKENS
} from '../../Constants/accountArea'
import { shouldShowVoucherUpload } from '../../utilities/voucher'
import { EXCLUDED_PARTNER_PROGRAMS } from '../../Constants/instride'

const {
  PERSONAL_INFO_PATH,
  COURSE_MANAGEMENT_PATH,
  STUDY_BLOCKS_PATH,
  LOGIN_SECURITY_PATH,
  VOUCHER_UPLOAD_PATH,
  TRANSCRIPTS_PATH,
  SUBSCRIPTION_PATH
} = ACCOUNT_PATH

const getButtonColor = (pageType, currentPage) => {
  let buttonColor = '#5FC4B8'
  if (pageType === currentPage) buttonColor = '#FFF'
  return buttonColor
}

function SidebarButton (props) {
  const {
    Icon, text, activePage, handleClick, isNew, testid
  } = props
  const color = getButtonColor(text, activePage)
  const activeItem = text === activePage
  const stopHoverEffect = text === SUBSCRIPTION

  return (
    <Button
      data-testid={testid}
      onClick={handleClick}
      isActive={activeItem}
      color={color}
      stopHover={stopHoverEffect}
    >
      <Icon
        color={color}
      />

      {text}

      {isNew && <NewBadge>new</NewBadge>}
    </Button>
  )
}

const getDropdownButtonIcon = (pageType) => {
  let Icon
  if (pageType === PERSONAL_INFO) Icon = UserIcon
  if (pageType === LOGIN_SECURITY) Icon = SecurityIcon
  if (pageType === VOUCHER_UPLOAD) Icon = VoucherIcon
  if (pageType === TRANSCRIPTS) Icon = CertificateIcon
  if (pageType === TOKENS) Icon = CoinsIcon
  if (pageType === SUBSCRIPTION) Icon = SubscriptionWhiteIcon
  if (pageType === COURSE_MANAGEMENT) Icon = BookIcon
  if (pageType === STUDY_BLOCKS) Icon = CalendarIcon
  if (pageType === AMAZON_REFUND) Icon = WalletIcon

  return (
    <span>
      <Icon color='#fff' />
    </span>
  )
}

function Navigation (props) {
  const history = useHistory()
  const { activePage } = props
  const [showDropdown, setShowDropdown] = useState(false)
  const {
    isStudioCohort, vouchers, studentData = {}
  } = useContext(AppContext) || {}
  const { instrideId, partnerProgramId } = studentData

  const showSubscription =
    instrideId && !EXCLUDED_PARTNER_PROGRAMS.includes(partnerProgramId)
  const showVoucherUpload = shouldShowVoucherUpload(vouchers)

  const [isStudyBlocksNew, setIsStudyBlocksNew] = useState(
    () => !localStorage.getItem('studyBlocksVisited')
  )

  const navigateToMenu = tabName => {
    history.push(`/${CONTENT.ACCOUNT}/${tabName}`)
  }

  const handleDropdownItemClick = (page) => {
    navigateToMenu(page)
    setShowDropdown(!showDropdown)
  }

  return (
    <SidebarContainer>
      <Header>Account</Header>

      <Sidebar>
        <SidebarButton
          testid='personal-info-button'
          Icon={UserIcon}
          text={PERSONAL_INFO}
          activePage={activePage}
          handleClick={() => navigateToMenu(PERSONAL_INFO_PATH)}
        />

        {!isStudioCohort && (
          <SidebarButton
            testid='transcripts-button'
            Icon={CertificateIcon}
            text={TRANSCRIPTS}
            activePage={activePage}
            handleClick={() => navigateToMenu(TRANSCRIPTS_PATH)}
          />
        )}
        <SidebarButton
          testid='course-management-button'
          Icon={BookIcon}
          text={COURSE_MANAGEMENT}
          activePage={activePage}
          handleClick={() => navigateToMenu(COURSE_MANAGEMENT_PATH)}
        />
        {(
          <SidebarButton
            testid='study-blocks-button'
            Icon={CalendarIcon}
            text={STUDY_BLOCKS}
            activePage={activePage}
            isNew={isStudyBlocksNew}
            handleClick={() => {
              setIsStudyBlocksNew(false)
              navigateToMenu(STUDY_BLOCKS_PATH)
              localStorage.setItem('studyBlocksVisited', true)
            }}
          />
        )}
        {showSubscription && (
          <SidebarButton
            testid='subscription-button'
            Icon={SubscriptionIcon}
            text={SUBSCRIPTION}
            activePage={activePage}
            handleClick={() => navigateToMenu(SUBSCRIPTION_PATH)}
          />
        )}
        <SidebarButton
          testid='login-security-button'
          Icon={SecurityIcon}
          text={LOGIN_SECURITY}
          activePage={activePage}
          handleClick={() => navigateToMenu(LOGIN_SECURITY_PATH)}
        />

        {showVoucherUpload && (
          <SidebarButton
            testid='voucher-upload-button'
            Icon={VoucherIcon}
            text={VOUCHER_UPLOAD}
            activePage={activePage}
            handleClick={() => navigateToMenu(VOUCHER_UPLOAD_PATH)}
          />
        )}

      </Sidebar>

      <DropdownContainer>
        <DropdownButton
          data-testid='drop-down-button'
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <span>
            {getDropdownButtonIcon(activePage)}
            {activePage}
          </span>
          <CaretIcon
            style={{ position: 'relative', top: '6px' }}
          />
        </DropdownButton>

        {showDropdown && (
          <DropdownMenu>
            <DropdownItem
              data-testid='personal-info-drop-down'
              onClick={() => handleDropdownItemClick(PERSONAL_INFO_PATH)}
            >
              <UserIcon color='#fff' />
              {PERSONAL_INFO}
            </DropdownItem>

            {!isStudioCohort && (
              <DropdownItem
                data-testid='transcripts-drop-down'
                onClick={() => handleDropdownItemClick(TRANSCRIPTS_PATH)}
              >
                <CertificateIcon color='#fff' />
                {TRANSCRIPTS}
              </DropdownItem>
            )}
            <DropdownItem
              onClick={() => handleDropdownItemClick(COURSE_MANAGEMENT_PATH)}
            >
              <BookIcon color='#fff' />
              {COURSE_MANAGEMENT}
            </DropdownItem>

            {(
              <DropdownItem
                onClick={() => {
                  setIsStudyBlocksNew(false)
                  handleDropdownItemClick(STUDY_BLOCKS_PATH)
                  localStorage.setItem('studyBlocksVisited', true)
                }}
              >
                <CalendarIcon color='#fff' />
                {STUDY_BLOCKS}
                {isStudyBlocksNew && <NewBadge>new</NewBadge>}
              </DropdownItem>
            )}
            {showSubscription && (
              <DropdownItem
                data-testid='subscription-drop-down'
                onClick={() => handleDropdownItemClick(SUBSCRIPTION_PATH)}
              >
                <SubscriptionWhiteIcon color='#fff' />
                {SUBSCRIPTION}
              </DropdownItem>
            )}

            <DropdownItem
              data-testid='login-security-drop-down'
              onClick={() => handleDropdownItemClick(LOGIN_SECURITY_PATH)}
            >
              <SecurityIcon color='#fff' />
              {LOGIN_SECURITY}
            </DropdownItem>
            {
              showVoucherUpload && (
                <DropdownItem
                  data-testid='voucher-upload-drop-down'
                  onClick={() => handleDropdownItemClick(VOUCHER_UPLOAD_PATH)}
                >
                  <VoucherIcon color='#fff' />
                  {VOUCHER_UPLOAD}
                </DropdownItem>
              )
            }

          </DropdownMenu>
        )}
      </DropdownContainer>
    </SidebarContainer>
  )
}

Navigation.displayName = 'Navigation'
export default Navigation
