import React from 'react'
import CardInfo from '../CardInfo'
import { toWords } from 'number-to-words'
import capitalize from 'lodash/capitalize'

const ClosedCard = ({
  course,
  note,
  almostClosing,
  assignmentsCount,
  assignment,
  courseBaseUrl,
  sectionUrl,
  sectionName
}) => {
  const singleAssignment = assignmentsCount === 1
  const assignmentUrl = `${courseBaseUrl}/#/${assignment.chapter_uuid}/writing_assignment`

  const description = almostClosing
    ? singleAssignment ? (
      <>
        <a href={assignmentUrl} rel='noopener noreferrer'>
          {assignment.title}
        </a> is closing soon! View the assignment or continue to
        <a href={sectionUrl} rel='noopener noreferrer'> Section {sectionName} </a>
      to keep working.
      </>
    ) : (
      <>
        {capitalize(toWords(assignmentsCount))} assignments are closing soon!
      Visit the <a href={courseBaseUrl} rel='noopener noreferrer'> Course Homepage </a>
      to view the assignments or continue to
        <a href={sectionUrl} rel='noopener noreferrer'> Section {sectionName} </a>
      to keep working.
      </>
    )
    : singleAssignment ? (
      <>
        <a href={assignmentUrl} rel='noopener noreferrer'>
          {assignment.title}
        </a> has just closed. Continue to
        <a href={sectionUrl} rel='noopener noreferrer'> Section {sectionName} </a>
      to keep working.
      </>
    ) : (
      <>
        {capitalize(toWords(assignmentsCount))} assignments have just closed.
      Continue to
        <a href={sectionUrl} rel='noopener noreferrer'> Section {sectionName} </a>
      to keep working.
      </>
    )

  return (
    <CardInfo
      course={course}
      description={description}
      note={note}
      primaryBtnTxt={almostClosing ? singleAssignment ? 'View' : 'View All' : 'Continue'}
      secondaryBtnTxt={almostClosing ? 'Continue' : ''}
      dataTestId='writing-closed'
      handlePrimaryButton={() => {
        window.location.href = almostClosing
          ? singleAssignment ? assignmentUrl : courseBaseUrl
          : sectionUrl
      }}
      handleSecondaryButton={() => {
        window.location.href = almostClosing ? sectionUrl : ''
      }}
    />
  )
}

export default ClosedCard
