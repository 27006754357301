import React, { useState, useRef, useEffect, useMemo, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../ContextProvider/ContextProvider'
import Notification from './Notification'
import { announcements as mockAnnouncements } from './mockData'
import allSeenIcon from '../../assets/icons/icon-no-announcements.svg'
import notAllSeenIcon from '../../assets/icons/icon-announcements.svg'
import closeIcon from '../../assets/icons/close-icon.svg'
import viewAllLinkImg from '../../assets/icons/arrow.svg'
import {
  DisableBackgroundScrolling,
  NotificationWrapper,
  NotificationsIcon,
  NotificationModal,
  ModalHeader,
  ModalTitle,
  CloseIcon,
  ModalBody,
  ModalFooter,
  ViewAllLink,
  ViewAllLinkIcon,
  ModalWrapper
} from './styles'

const NotificationsTool = () => {
  const [showModal, setShowModal] = useState(false)
  const modalRef = useRef(null)
  const history = useHistory()
  const { seenAnnouncementsFromContext } = useContext(AppContext)

  const announcements = useMemo(() => {
    return mockAnnouncements.map(announcement => {
      if (!seenAnnouncementsFromContext?.includes(announcement.id)) return announcement
      return { ...announcement, seen: true }
    })
  }, [seenAnnouncementsFromContext])

  const allNotificationsSeen = useMemo(() => {
    return announcements?.every(announcement => announcement.seen)
  }, [announcements])

  useEffect(() => {
    // handle esc key press event
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setShowModal(false)
      }
    }
    window.addEventListener('keydown', handleEsc)

    // handle click outside of modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      window.removeEventListener('keydown', handleEsc)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      {showModal && <DisableBackgroundScrolling />}
      <NotificationWrapper ref={modalRef}>
        <NotificationsIcon
          alt='notifications icon'
          onClick={() => setShowModal(preVal => !preVal)}
          src={allNotificationsSeen ? allSeenIcon : notAllSeenIcon}
        />
        <ModalWrapper
          showModal={showModal}
        >
          <NotificationModal
            data-testid='notification-modal'
            showModal={showModal}
          >
            <ModalHeader>
              <ModalTitle>Recent Announcements</ModalTitle>
              <CloseIcon
                alt='close icon'
                src={closeIcon}
                onClick={() => setShowModal(false)}
              />
            </ModalHeader>
            <ModalBody>
              {announcements.map((announcement, i) => (
                <Notification
                  key={i}
                  index={i}
                  announcement={announcement}
                  setShowModal={setShowModal}
                />
              ))}
            </ModalBody>
            <ModalFooter>
              <ViewAllLink
                onClick={() => {
                  history.push('/announcements')
                  setShowModal(false)
                }}
              >
                View all
                <ViewAllLinkIcon src={viewAllLinkImg} />
              </ViewAllLink>
            </ModalFooter>
          </NotificationModal>
        </ModalWrapper>
      </NotificationWrapper>
    </>
  )
}

NotificationsTool.displayName = 'NotificationsTool'
export default NotificationsTool
