import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Toast from '../../../Toast'
import { PrimaryButton } from '../../style'
import { getLatestCohort } from '../../../../utilities/course'
import { getStartEndDateUSFormat } from '../../../../utilities/dateTime'
import {
  getActiveCourses,
  getRecommendedHours,
  getLatestEnrolledCourse,
  getLatestScheduledCourse
} from '../utils'

function AdjustStudyBlocksToast ({ courses, studyBlocksData }) {
  const [latestCourses, setLatestCourses] = useState({ scheduled: {}, enrolled: {} })
  const [show, setShow] = useState(false)
  const remove = () => setShow(false)
  const onAdjustClick = () => {
    window.location.href = '#/account/study-blocks'
    remove()
  }

  useEffect(() => {
    const activeCourses = getActiveCourses(courses)
    const { createdAt, updatedAt } = studyBlocksData?.metadata || {}
    const latestStudyBlocksUpdate = updatedAt || createdAt
    const latestEnrolledCourse = getLatestEnrolledCourse(
      activeCourses, latestStudyBlocksUpdate)
    const latestScheduledCourse = getLatestScheduledCourse(
      activeCourses, latestStudyBlocksUpdate)

    setLatestCourses({
      scheduled: latestScheduledCourse, enrolled: latestEnrolledCourse
    })
    setShow(!!latestEnrolledCourse && !!latestScheduledCourse)
  }, [courses, studyBlocksData])

  const title = 'Overlapping course schedules'
  const toastWrapperStyle = { margin: '28px 64px 0', width: '448px' }
  const getBody = () => {
    const { scheduled = {}, enrolled = {} } = latestCourses
    const { name: scheduledName = '' } = scheduled
    const { name: enrolledName = '' } = enrolled
    const scheduledEndTime = getLatestCohort(scheduled)?.cohortEndTime
    const enrolledStartTime = getLatestCohort(enrolled)?.dateStart
    const overlapTime = getStartEndDateUSFormat(enrolledStartTime, scheduledEndTime)
    const newRecommendedHours = getRecommendedHours(getActiveCourses(courses))

    return (
      <>
        <div>
          <p style={{ marginBottom: '6px' }}>
            Hey there! Your new course {enrolledName} overlaps with courses you’ve
            already scheduled: {scheduledName}.
          </p>
          <p>
            The overlap runs between {overlapTime},
            making your new commitment {newRecommendedHours}hrs/wk.
          </p>
        </div>
        <PrimaryButton
          onClick={onAdjustClick}
        >
          adjust study blocks
        </PrimaryButton>
      </>
    )
  }

  return (
    <Toast
      hasBreak
      show={show}
      remove={remove}
      title={title}
      getBody={getBody}
      toastWrapperStyle={toastWrapperStyle}
    />
  )
}

AdjustStudyBlocksToast.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object),
  studyBlocksData: PropTypes.shape({
    studyBlocks: PropTypes.object
  })
}

AdjustStudyBlocksToast.displayName = 'AdjustStudyBlocksToast'
export default AdjustStudyBlocksToast
