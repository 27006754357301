import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { ScheduleCallButton } from './styles'

const ReturnToDashboard = ({ updateContext, updateTempCart }) => {
  const history = useHistory()
  return (
    <ScheduleCallButton
      className={classNames({
        btn: true,
        'btn-primary': false,
        'btn-secondary': true
      })}
      marginTop='24px'
      onClick={() => {
        updateTempCart && updateTempCart()
        updateContext({ quitGGUEnrolment: true })
        history.push('/')
      }}
    >
      Return To Dashboard
    </ScheduleCallButton>
  )
}

ReturnToDashboard.displayName = 'ReturnToDashboard'

export default ReturnToDashboard
