import React, { useContext } from 'react'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import { COURSE_REGISTRATION_NEW_COURSE_CONFIRMED } from '../../Constants/eventType'
import { GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import { getRecommendedCoursesFromLS, replaceRecommendedCoursesInLS } from '../../utilities/gguDegreeUtils'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  ConfirmChangeModalWrapper,
  ConfirmChangeModalHeader,
  Close,
  ModalBody,
  ButtonsWrapper,
  PrimaryButton,
  SecondaryButton,
  ModalContainer
} from './style'

const ConfirmChangeModal = ({
  currentCourseNumber,
  setCurrentCourseNumber,
  setCurrentPage,
  courseQuantity,
  setShowConfirmChangeModal,
  setRecommendedCourses,
  originalCourseId,
  newCourseId
}) => {
  const { sendEvent } = useSegmentEvents()
  const {
    catalogCourses,
    degreeData
  } = useContext(AppContext)

  const getCourseName = courseId => {
    return catalogCourses?.find(course => {
      return course?.uuid?.uuid === courseId
    })?.courseName?.replace(' - GGU', '') || ''
  }

  const originalCourseName = getCourseName(originalCourseId)
  const newCourseName = getCourseName(newCourseId)

  const confirmCourseChange = () => {
    setShowConfirmChangeModal(false)
    replaceRecommendedCoursesInLS([newCourseId], [originalCourseId])
    const recommendedCoursesIds = (getRecommendedCoursesFromLS() || [])
      .map((course) => course.courseId)

    const newRecommendedCourses = degreeData?.filter((course) => (
      recommendedCoursesIds.includes(course.courseId)
    ))
    const orderedCourses = recommendedCoursesIds.map(courseId => {
      return newRecommendedCourses.find(course => course.courseId === courseId)
    })
    setRecommendedCourses(orderedCourses)

    sendEvent({
      eventName: COURSE_REGISTRATION_NEW_COURSE_CONFIRMED,
      properties: {
        [`registeredCourse${currentCourseNumber}`]: newCourseName
      }
    })

    if (currentCourseNumber >= courseQuantity) {
      setCurrentPage(GGU_V2_ENROLLMENT_PAGES.REVIEW)
      return
    }
    setCurrentCourseNumber(currentCourseNumber + 1)
    setCurrentPage(GGU_V2_ENROLLMENT_PAGES.COURSE_INTRO)
  }

  const gotoCatalog = () => {
    setShowConfirmChangeModal(false)
    setCurrentPage(GGU_V2_ENROLLMENT_PAGES.CATALOG)
  }

  return (
    <ConfirmChangeModalWrapper data-testid='modal'>
      <ModalContainer>
        <ConfirmChangeModalHeader>
          <p>Confirm course change?</p>
          <Close onClick={() => setShowConfirmChangeModal(false)} data-testid='close-icon' />
        </ConfirmChangeModalHeader>
        <ModalBody padding='24px'>
          <p className='confirm-change-message'>Instead of taking {originalCourseName}, you'll take {newCourseName}.</p>
          <ButtonsWrapper>
            <PrimaryButton margintop='24px' marginright='16px' onClick={gotoCatalog}>
              back to catalog
            </PrimaryButton>
            <SecondaryButton margintop='24px' onClick={confirmCourseChange}>
              confirm
            </SecondaryButton>
          </ButtonsWrapper>
        </ModalBody>
      </ModalContainer>
    </ConfirmChangeModalWrapper>
  )
}

ConfirmChangeModal.displayName = 'ConfirmChangeModal'

export default ConfirmChangeModal
