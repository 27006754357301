import React from 'react'
import CardInfo from '../CardInfo'
import { toWords } from 'number-to-words'
import capitalize from 'lodash/capitalize'

const OpenCard = ({
  course,
  note,
  openAssignmentsCount,
  openAssignment,
  courseBaseUrl,
  sectionUrl,
  sectionName
}) => {
  const singleAssignmentOpen = openAssignmentsCount === 1
  const assignmentUrl = `${courseBaseUrl}/#/${openAssignment.chapter_uuid}/writing_assignment`

  const description = singleAssignmentOpen ? (
    <>
      <a href={assignmentUrl} rel='noopener noreferrer'>
        {openAssignment.title}
      </a> is open! View the assignment or continue to
      <a href={sectionUrl} rel='noopener noreferrer'> Section {sectionName} </a>
      to keep working.
    </>
  ) : (
    <>
      {capitalize(toWords(openAssignmentsCount))} new assignments are open!
      Visit the <a href={courseBaseUrl} rel='noopener noreferrer'> Course Homepage </a>
      to view the assignments or continue to
      <a href={sectionUrl} rel='noopener noreferrer'> Section {sectionName} </a>to keep working.
    </>
  )

  return (
    <CardInfo
      course={course}
      description={description}
      note={note}
      primaryBtnTxt={singleAssignmentOpen ? 'Start' : 'View All'}
      secondaryBtnTxt='Continue'
      dataTestId='writing-open'
      handlePrimaryButton={() => {
        window.location.href = singleAssignmentOpen
          ? assignmentUrl : courseBaseUrl
      }}
      handleSecondaryButton={() => { window.location.href = sectionUrl }}
    />
  )
}

export default OpenCard
