import React, { useContext } from 'react'
import find from 'lodash/find'
import ActivityNotYetStartedCard from './ActivityNotYetStartedCard'
import ActivityInProgressAndCompletedCard
  from './ActivityInProgressAndCompletedCard'
import ExamOpenAndCompletedCard from './ExamOpenAndCompletedCard'
import WritingAssignmentsCard from './WritingAssignments/WritingAssignmentsCard'
import { AppContext } from '../ContextProvider/ContextProvider'
import api from '../../api'
import {
  getCohortClosestExam,
  getCohortOpenExamDates
} from '../../utilities/cohort'
import {
  daysToSeconds,
  getDifferenceOfDaysWithCurrentDate,
  getTimezoneShort,
  secondsToDateString,
  secondsToFormattedDateTimeShort
} from '../../utilities/dateTime'
import { DAYS_BEFORE_EXAM } from '../../Constants'
import has from 'lodash/has'
import { PROGRESS_TYPE } from '../../Constants/courseCard'
import { getLatestCohort } from '../../utilities/course'
import { getExamFromExamNumber } from '../../utilities/chapterUtils'

const InProgressCard = ({
  course,
  currentTime,
  noAssessments
}) => {
  let { id, cohort } = course
  const latestCohort = getLatestCohort(course)
  cohort = cohort[0] || cohort
  const { coursesProgress } = useContext(AppContext)

  const courseProgress = find(coursesProgress, { courseId: id })
  if (!courseProgress) return null

  const { studentProgress } = courseProgress
  if (!studentProgress) return null

  const { studentAnswers } = studentProgress

  const { startDate, examNumber, isFinalExam } = getCohortClosestExam(cohort)
  const isStudyGuideUnlocked =
      getDifferenceOfDaysWithCurrentDate(startDate) <= DAYS_BEFORE_EXAM
  const formattedDate = secondsToFormattedDateTimeShort(startDate)
  const timezone = getTimezoneShort(secondsToDateString(startDate))

  const exam = getExamFromExamNumber({
    chapters: course?.chapters,
    examNumber,
    isFinalExam
  }) || {}

  const getUpcomingExamNote = () => {
    if (!formattedDate) return
    return <>The exam will open on {formattedDate} {timezone}</>
  }

  const getUpcomingStudyGuideNote = () => {
    const studyGuideOpenDate = startDate - daysToSeconds(DAYS_BEFORE_EXAM)
    const formattedStudyGuideDate = secondsToFormattedDateTimeShort(
      studyGuideOpenDate
    )
    return <>The study guide will open on {formattedStudyGuideDate} {timezone}</>
  }

  const openExamStudyGuide = async (link, examId) => {
    const { examStudyGuide, chapter_uuid: chapterId } = exam || {}
    const { file: { url } = {} } = examStudyGuide || {}
    api.incrementStudyGuideClicks(id, chapterId || examId)
    window.open(url || link, '_blank')
  }

  const isActivityNotStarted = () => {
    const videoProgressLength = Object.keys(studentProgress[LECTURE_VIDEO_PROGRESS]).length
    const studentAnswersLength = Object.keys(studentAnswers).length

    if (videoProgressLength === 1) {
      const [[, timeSpentInSeconds]] = Object.entries(studentProgress[LECTURE_VIDEO_PROGRESS])
      return timeSpentInSeconds <= 0 && !Object.keys(studentAnswers).length
    }

    return !videoProgressLength && !studentAnswersLength
  }

  const renderProgressedActivity = () => {
    const note = exam && getUpcomingExamNote()
    const containsStudyGuide = has(exam, 'examStudyGuide.file.url')
    return (
      <ActivityInProgressAndCompletedCard
        course={course}
        note={note}
        exam={{
          ...exam,
          studyGuideNote:
            !isStudyGuideUnlocked &&
            containsStudyGuide &&
            getUpcomingStudyGuideNote(),
          isStudyGuideOpen: isStudyGuideUnlocked && containsStudyGuide,
          openExamStudyGuide
        }}
        courseProgress={courseProgress}
        noAssessments={noAssessments}
      />
    )
  }

  const { LECTURE_VIDEO_PROGRESS } = PROGRESS_TYPE

  if (isActivityNotStarted()) {
    // renders activity not yet started card if student answer is empty
    return (
      <ActivityNotYetStartedCard
        course={course}
      />
    )
  } else {
    const examOpenData = getCohortOpenExamDates(cohort, currentTime)
    if (examOpenData && !noAssessments) {
      // renders exam open or completed card if exam is open
      return (
        <ExamOpenAndCompletedCard
          course={course}
          openExamStudyGuide={openExamStudyGuide}
          courseProgress={courseProgress}
          examOpenData={examOpenData}
          noAssessments={noAssessments}
        />
      )
    }

    const { milestones: writingAssignmentsData } = latestCohort || {}
    if (writingAssignmentsData && !noAssessments) {
      return (
        <WritingAssignmentsCard
          course={course}
          courseProgress={courseProgress}
          assignmentsData={writingAssignmentsData}
          examOpenData={examOpenData}
          handleDefaultState={renderProgressedActivity}
        />
      )
    }

    return renderProgressedActivity()
  }
}

export default InProgressCard
