import React, { useContext } from 'react'
import {
  Container,
  Header,
  Content
} from './styles'
import { PrimaryButton } from '../Buttons/style'
import {
  GGU_WELCOME_PAGE_VIEW,
  PERMISSION_REQUESTED_KEY
} from '../../Constants'
import { AppContext } from '../ContextProvider/ContextProvider'
import config from '../../config'

const GGUWelcomePage = () => {
  const { studentData } = useContext(AppContext)
  const { preferredName } = studentData || {}

  const continueCourse = () => {
    localStorage.removeItem(PERMISSION_REQUESTED_KEY)
    localStorage.removeItem(GGU_WELCOME_PAGE_VIEW)
    window.open(config.courseBaseUrl, '_self')
  }

  return (
    <Container>
      <Header>Welcome, {preferredName}</Header>
      <Content>
        <div>
          You just took a powerful first step towards an associate degree.
        </div>
        <div>
          We will be reaching out via email for next steps in the application and enrollment process. In the meantime, check out the free College Success course for some helpful tips to prepare.
        </div>
      </Content>
      <PrimaryButton
        data-testid='btn-continue'
        onClick={continueCourse}
      >
        let's go
      </PrimaryButton>
    </Container>
  )
}

GGUWelcomePage.displayName = 'GGUWelcomePage'
export default GGUWelcomePage
