import React, { useContext } from 'react'
import {
  ArrowIcon,
  Info,
  InfoIcon,
  TranscriptItemContainer,
  TranscriptListItem
} from './style'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  getDateStringWithYearLongMonth,
  getMonthDayFromDate
} from '../../utilities/dateTime'
import TranscriptGradeMessage from './TranscriptGradeMessage'

const openLink = link => {
  window.open(link, '_blank')
}

const TranscriptsList = ({
  transcripts,
  showTranscripts,
  title,
  transcriptsLink,
  description
}) => {
  const isExcludedCourses = !transcriptsLink
  const { courses } = useContext(AppContext)
  return (
    <TranscriptItemContainer>
      <h2>{title}</h2>
      {transcriptsLink && (
        <div className='start-transcript'>
          {showTranscripts
            ? (
              <>
                <div>
                  <h3>Order transcript</h3>
                  <Info>
                    <InfoIcon />
                    <span className='tooltiptext'>
                        Courses are listed 10 business days after your cohort's Final Exam window closes.
                    </span>
                  </Info>
                </div>
                <div onClick={() => { openLink(transcriptsLink) }}>
                  <p className='link'>
                    <span>Start</span>
                    <ArrowIcon />
                  </p>
                </div>
              </>
            ) : (
              <div>
                <h3>Credit transfer</h3>
              </div>
            )}
        </div>
      )}
      {showTranscripts && (
        <p className='description'>
          {description || 'Your transcript is ready for these courses:'}
        </p>
      )}
      {showTranscripts
        ? (
          <TranscriptListItem>
            {transcripts.map(transcript => {
              if (!transcript?.showTranscript) return null
              const { course, cohort } = transcript?.fields || {}
              const {
                dateStart,
                finalExamEndTime
              } = cohort?.fields || {}
              const formattedDateStart = getMonthDayFromDate(
                dateStart, null, 'short'
              )
              const formattedEndDate = getDateStringWithYearLongMonth(
                finalExamEndTime, 'short'
              )

              return (
                <div className='item' key={transcript?.id}>
                  <p>
                    {course?.fields?.displayName} ({formattedDateStart} - {formattedEndDate})
                  </p>
                  <TranscriptGradeMessage
                    key={transcript?.id}
                    transcript={transcript}
                    courses={courses}
                    isExcludedCourses={isExcludedCourses}
                  />
                </div>
              )
            })}
          </TranscriptListItem>
        )
        : (
          <p className='description'>
            You'll be able to start your transcript request process here shortly after your course ends.
          </p>
        )}
    </TranscriptItemContainer>
  )
}

TranscriptsList.displayName = 'TranscriptsList'

export default TranscriptsList
