import React, { useContext } from 'react'

import { AppContext } from '../ContextProvider/ContextProvider'
import { getLatestCohort } from '../../utilities/course'
import config from '../../config'
import {
  dateToSecondsSinceEpoch,
  diffDays,
  getISODateTimeString,
  secondsSinceEpoch
} from '../../utilities/dateTime'
import ExtensionRow from './ExtensionRow'
import {
  getCohortExamDates,
  getExamDatesFromCohortExamDates
} from '../../utilities/cohort'

export const THEME = {
  DANGER: 'danger',
  WARNING: 'warning'
}

export const TYPE = {
  EXAM: 'Exam',
  ASSIGNMENT: 'Assignment',
  FINAL_ESSAY: 'Essay'
}

export const currentTime = secondsSinceEpoch()
export const ONE_DAY = 24 * 60 * 60
export const THREE_DAYS = 3 * ONE_DAY

export const processExtensionData = ({
  currentEndTime,
  endTime,
  examId,
  courseId,
  isCompleted,
  title,
  isLiveProctoringExam,
  type = TYPE.EXAM
}) => {
  // if Exam/Assignment is already completed
  if (isCompleted || isLiveProctoringExam) return

  const timeDiff = dateToSecondsSinceEpoch(new Date(currentEndTime)) - currentTime
  // if End time > 3 days
  if (timeDiff > THREE_DAYS) return

  // if End time has passed
  if (timeDiff < 0) return

  const lessThanOneDayLeft = timeDiff < ONE_DAY
  const lessThanThreeDaysLeft = timeDiff < THREE_DAYS

  const theme = lessThanOneDayLeft ? THEME.DANGER : THEME.WARNING

  const link = lessThanThreeDaysLeft
    ? `${config.courseBaseUrlById(courseId)}/#/extension-request/${examId}`
    : ''

  // Check if deadline is already extended
  const isExtended = diffDays(
    dateToSecondsSinceEpoch(new Date(endTime)),
    dateToSecondsSinceEpoch(new Date(currentEndTime))
  ) > 0

  let rightSectionText
  if (isExtended) {
    rightSectionText = 'Deadline Extended'
  } else if (lessThanThreeDaysLeft) {
    rightSectionText = 'EXTEND DEADLINE'
  }

  return {
    isExtended,
    link,
    rightSectionText,
    theme,
    title,
    type
  }
}

const ExtensionRows = props => {
  const { cohortOriginalDeadlines = [], coursesProgress = [] } = useContext(AppContext) || {}
  if (!cohortOriginalDeadlines.length) return null

  const { course } = props
  const examChapters = (course.chapters || []).filter(c => c.type === 'exam')

  const courseProgress = coursesProgress.find(c => c.courseId === course.id) || {}
  const { studentProgress } = courseProgress

  const cohort = getLatestCohort(course)
  const currentCohortDeadline = cohortOriginalDeadlines.find(c => c?.id === cohort?.id)
  if (!currentCohortDeadline) return null

  const {
    cohortMilestones
  } = currentCohortDeadline || {}

  const {
    relationship: { fields: { liveProctoring } = {} } = {}
  } = cohort || {}

  const getExtensionRows = () => {
    const latestCohortExamDates = getCohortExamDates(cohort)
    const originalCohortExamDates = getCohortExamDates(currentCohortDeadline)

    return examChapters.reduce((extensions, exam) => {
      const {
        endDate: latestEndDate
      } = getExamDatesFromCohortExamDates({
        exam,
        cohortExamDates: latestCohortExamDates
      })
      const latestEndTime = getISODateTimeString(latestEndDate)

      const {
        endDate: originalEndDate
      } = getExamDatesFromCohortExamDates({
        exam,
        cohortExamDates: originalCohortExamDates
      })
      const originalEndTime = getISODateTimeString(originalEndDate)
      if (!originalEndTime) return extensions

      const examChapterUUID = exam.chapter_uuid
      const extensionData = processExtensionData({
        courseId: course.id,
        currentEndTime: latestEndTime,
        endTime: originalEndTime,
        examId: examChapterUUID,
        isLiveProctoringExam: liveProctoring,
        isCompleted: studentProgress?.['exam-complete']?.[examChapterUUID] === true
      })

      if (extensionData) extensions.push(extensionData)

      return extensions
    }, [])
  }

  const extensionRows = getExtensionRows()

  // Assignments ->
  cohortMilestones.forEach(cohortMilestone => {
    const {
      id: milestoneId,
      lockTime: endTime,
      name: title
    } = cohortMilestone
    const { studentProgress } = courseProgress

    const currentCohortMilestone = (cohort?.milestones || [])
      .find(c => c.id === milestoneId)

    const currentEndTime = currentCohortMilestone?.lockTime
    const examId = currentCohortMilestone?.datoAssignmentUUID
    const isCompleted = studentProgress?.['assignment-progress']?.[examId]?.status === 'submitted'

    /* Assignment could be final essay which is a special case */
    const type = currentCohortMilestone?.finalAssignment
      ? TYPE.FINAL_ESSAY
      : TYPE.ASSIGNMENT

    const extensionData = processExtensionData({
      courseId: course.id,
      currentEndTime: currentEndTime || endTime,
      endTime,
      examId,
      isCompleted,
      // this title is required for cases when more than one assignments are visible simultaneously
      title,
      type
    })
    if (extensionData) extensionRows.push(extensionData)
  })

  const extensionAssignments = extensionRows?.filter(e => e.type === TYPE.ASSIGNMENT)
  const multipleAssignmentsPresent = extensionAssignments?.length > 1
  return (
    <>
      {extensionRows.map((extensionRow, key) => {
        const {
          isExtended,
          link,
          rightSectionText,
          theme,
          title,
          type
        } = extensionRow

        const displayName = (multipleAssignmentsPresent && type === TYPE.ASSIGNMENT)
          ? ` "${title}"`
          : ''
        const leftSectionText = `${type}${displayName} closing soon!`
        return (
          <ExtensionRow
            key={key}
            theme={theme}
            leftSectionText={leftSectionText}
            rightSectionText={rightSectionText}
            isExtended={isExtended}
            link={link}
          />
        )
      })}
    </>
  )
}

ExtensionRows.displayName = 'ExtensionRows'
export default ExtensionRows
