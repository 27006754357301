import React from 'react'
import CardInfo from './CardInfo'
import config from '../../config'

import {
  secondsSinceEpoch,
  dateToSecondsSinceEpoch,
  secondsToFormattedDateTimeShort,
  secondsToDateString,
  getTimezoneShort
} from '../../utilities/dateTime'
import { ExamLink } from './style'
import ExamOpenAndCompletedCard from './ExamOpenAndCompletedCard'
import { getLatestCohort } from '../../utilities/course'
import { getCohortClosestExam } from '../../utilities/cohort'
import { secondsToMilliseconds } from 'date-fns'

const QuizCard = ({
  course,
  noAssessments,
  examOpenData,
  handleStartButtonClick,
  exam,
  note,
  courseProgress,
  visitedQuizData,
  chapterUuid,
  chapterNo,
  sectionNo,
  reviewChapterTitle,
  reviewChapterUrl,
  reviewFirstSectionUUID
}) => {
  const { id, chapters } = course

  const { studentProgress, sectionData } = courseProgress
  const { 'quiz-section': quizSection } = studentProgress

  const { visitedQuiz, quizNo } = visitedQuizData
  // get last visited guesswork section data
  const {
    section_uuid: sectionUuid
  } = sectionData

  // get course base url
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`

  const {
    openExamStudyGuide,
    title,
    studyGuideNote,
    isStudyGuideOpen,
    examOpen,
    examLoading
  } = exam

  let reviewChapterStarted = false
  if (reviewFirstSectionUUID) {
    const reviewChapterProgress = Object.keys(studentProgress)
      .filter((key) => key.includes('review'))
      .map((key) => studentProgress[key])
      .filter((progress) => Object.keys(progress).length > 0)

    if (reviewChapterProgress.length > 0) {
      reviewChapterStarted = true
    }
  }

  const reviewChapterExistsAndNotStarted = reviewFirstSectionUUID && !reviewChapterStarted

  const getDescription = (invokedBy, messageVariables = {}) => {
    const {
      completedQuestion,
      quizUrl,
      title: nextSectionTitle,
      nextSectionUrl,
      totalQuestion,
      nextChapterNo,
      nextSectionNo,
      percentage
    } = messageVariables
    const remainingQuestions = totalQuestion - completedQuestion
    const isMultipleCompletedQuestions = completedQuestion > 1 ? 's ' : ' '

    if (invokedBy === 'inProgress' && examOpen && reviewFirstSectionUUID) {
      return (
        <>
          {"It's exam time! Continue to complete the remaining"}
          {` ${totalQuestion - completedQuestion} questions in `}
          <a href={quizUrl} rel='noopener noreferrer'>
            Quiz {quizNo}
          </a>
          {' in '}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
          {', start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>
          {', or start '}
          <a href={reviewChapterUrl} rel='noopener noreferrer'>
            Review {reviewChapterTitle}
          </a>
          .
        </>
      )
    }

    if (invokedBy === 'inProgress' && examOpen) {
      return (
        <>
          {'It\'s exam time! Continue to complete the remaining'}
          {` ${totalQuestion - completedQuestion} questions in `}
          <a href={quizUrl} rel='noopener noreferrer'>
            Quiz {quizNo}
          </a>
          {' in '}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
          , {!isStudyGuideOpen && 'or'} {'start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>
          {
            isStudyGuideOpen && (
              <>
                {' or view the '}
                <span onClick={() => openExamStudyGuide()}>
                  Study Guide
                </span>
                {' to review the course material'}
              </>
            )
          }
          .
        </>
      )
    }

    if (invokedBy === 'inProgress' && isStudyGuideOpen) {
      return (
        <>
          {`The ${title} is coming up! View the `}
          <span onClick={() => openExamStudyGuide()}>Study Guide</span>
          {` or continue to complete the remaining ${remainingQuestions}`}
          {' questions in '}
          <a href={quizUrl} rel='noopener noreferrer'>
            Quiz {quizNo}
          </a>
          {' in '}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
        </>
      )
    }

    if (invokedBy === 'inProgress') {
      return (
        <>
          {`You completed ${completedQuestion}`}
          {` question${isMultipleCompletedQuestions} in `}
          <a href={quizUrl} rel='noopener noreferrer'>Quiz {quizNo}</a>
          {' in '}
          <a
            href={sectionUrl}
            rel='noopener noreferrer'
          >Section {chapterNo}.{sectionNo}
          </a>
          {'. Continue to complete the remaining '}
          {totalQuestion - completedQuestion} questions
          {reviewFirstSectionUUID && (
            <>
              {' or start '}
              <a href={reviewChapterUrl} rel='noopener noreferrer'>
                Review {reviewChapterTitle}
              </a>
            </>
          )}
          .
        </>
      )
    }

    if (invokedBy === 'nextSection' && examOpen && reviewFirstSectionUUID) {
      return (
        <>
          {'It\'s exam time!  You completed '}
          <a href={quizUrl} rel='noopener noreferrer'>Quiz {quizNo}</a>
          {' in '}
          <a
            href={sectionUrl}
            rel='noopener noreferrer'
          >Section {chapterNo}.{sectionNo}
          </a>{` and scored ${percentage}%. Continue to `}
          <a
            href={nextSectionUrl}
            rel='noopener noreferrer'
          >Section {nextChapterNo}.{nextSectionNo}
          </a>, start {' '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>, {'or start '}
          <a href={reviewChapterUrl} rel='noopener noreferrer'>
            Review {reviewChapterTitle}
          </a>
          .
        </>
      )
    }

    if (invokedBy === 'nextSection' && examOpen) {
      return (
        <>
          {'It\'s exam time!  You completed '}
          <a href={quizUrl} rel='noopener noreferrer'>Quiz {quizNo}</a>
          {' in '}
          <a
            href={sectionUrl}
            rel='noopener noreferrer'
          >Section {chapterNo}.{sectionNo}
          </a>{` and scored ${percentage}%. Continue to `}
          <a
            href={nextSectionUrl}
            rel='noopener noreferrer'
          >Section {nextChapterNo}.{nextSectionNo}
          </a>, {!isStudyGuideOpen && 'or'} {'start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>
          {
            isStudyGuideOpen && (
              <>
                {' or view the '}
                <span onClick={() => openExamStudyGuide()}>
                  Study Guide
                </span>
                {' to review the course material.'}
              </>
            )
          }
        </>
      )
    }

    if (invokedBy === 'nextSection' && isStudyGuideOpen) {
      return (
        <>
          {`The ${title} is coming up! You completed `}
          <a href={quizUrl} rel='noopener noreferrer'>
            Quiz {quizNo}
          </a>
          {' in '}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
          {` and scored ${percentage}%. Review your answers, continue to `}
          <a href={nextSectionUrl} rel='noopener noreferrer'>
            Section {nextChapterNo}.{nextSectionNo}
          </a>
          {' or view the '}
          <span onClick={() => openExamStudyGuide()}>Study Guide</span>
          {' to review the course material.'}
        </>
      )
    }

    if (invokedBy === 'nextSection') {
      return (
        <>
          {'You completed '}
          <a href={quizUrl} rel='noopener noreferrer'>
          Quiz {quizNo}
          </a>
          {' in '}
          <a
            href={sectionUrl}
            rel='noopener noreferrer'
          >Section {chapterNo}.{sectionNo}
          </a>
          {` and scored ${percentage}%. Review your answers or continue to `}
          <a
            href={nextSectionUrl}
            rel='noopener noreferrer'
          >Section {nextChapterNo}.{nextSectionNo}
          </a>
          .
        </>
      )
    }

    if (invokedBy === 'examUpcoming' && isStudyGuideOpen) {
      return (
        <>
          {'You’re all caught up! '}
          {isStudyGuideOpen ? (
            <>
              {'View the '}
              <span onClick={() => openExamStudyGuide()}>
                Study Guide
              </span>
              {' or continue'}
            </>
          ) : (
            <>Continue</>
          )}
          {` to brush up on the material in preparation for the ${title}.`}
        </>
      )
    }

    if (invokedBy === 'writingAssignment' && examOpen) {
      return (
        <>
          {'It’s exam time! You completed '}
          <a href={quizUrl} rel='noopener noreferrer'>
            Quiz {quizNo}
          </a>
          {' in '}
          <a
            href={sectionUrl}
            rel='noopener noreferrer'
          >
            Section {chapterNo}.{sectionNo}
          </a>{` and scored ${percentage}%. Continue to `}
          <a
            href={nextSectionUrl}
            rel='noopener noreferrer'
          >{nextSectionTitle}
          </a>, {!isStudyGuideOpen && 'or'} start
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>
          {
            isStudyGuideOpen && (
              <>
                {' or view the '}
                <span onClick={() => openExamStudyGuide()}>
                  Study Guide
                </span>
                {' to review the course material.'}
              </>
            )
          }
        </>
      )
    }

    if (invokedBy === 'writingAssignment' && isStudyGuideOpen) {
      return (
        <>
          {`The ${title} is coming up! You completed `}
          <a href={quizUrl} rel='noopener noreferrer'>
            Quiz {quizNo}
          </a>
          {' in '}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
          {` and scored ${percentage}%. Review your answers, continue to `}
          <a
            href={nextSectionUrl}
            rel='noopener noreferrer'
          >{nextSectionTitle}
          </a>{' or view the '}
          <span onClick={() => openExamStudyGuide()}>Study Guide</span>
          {' to review the course material.'}
        </>
      )
    }

    if (invokedBy === 'writingAssignment') {
      return (
        <>
          {'You completed '}
          <a href={quizUrl} rel='noopener noreferrer'>
            {`Quiz ${quizNo}`}
          </a>
          {' in '}
          <a
            href={sectionUrl}
            rel='noopener noreferrer'
          >Section {chapterNo}.{sectionNo}
          </a>
          {` and scored ${percentage}%.`}
          {' Review your answers or continue to '}
          <a
            href={nextSectionUrl}
            rel='noopener noreferrer'
          >{nextSectionTitle}
          </a>.
        </>
      )
    }

    if (invokedBy === 'examUpcoming' && reviewChapterExistsAndNotStarted) {
      return (
        <>
          You’re all caught up! View{' '}
          <a href={reviewChapterUrl} rel='noopener noreferrer'>
            Review {reviewChapterTitle}
          </a>{' '}
          or continue to brush up on the material in preparation for the {title}.
        </>
      )
    }

    if (invokedBy === 'examUpcoming') {
      return (
        <>
          You’re all caught up! Continue to brush up on the material
          {reviewFirstSectionUUID && (
            <>
              {' or view '}
              <a href={reviewChapterUrl} rel='noopener noreferrer'>
                Review {reviewChapterTitle}
              </a>
            </>
          )}{' '}
          in preparation for the {title}.
        </>
      )
    }
  }

  // when last activity is quiz and in progress
  const handleInProgressQuiz = () => {
    const {
      completedQuestion,
      totalQuestion,
      questionId
    } = visitedQuiz
    const quizUrl = `${sectionUrl}/${questionId}`
    const navigateToQuiz = () =>
      (window.location.href = quizUrl)

    return {
      dataTestId: 'quiz-inprogress',
      description: getDescription('inProgress', {
        completedQuestion,
        totalQuestion,
        quizUrl
      }),
      note,
      studyGuideNote,
      secondaryBtnTxt: examOpen ? 'Start' : isStudyGuideOpen && 'Continue',
      handleSecondaryButton: () => {
        if (isStudyGuideOpen) {
          navigateToQuiz()
          return
        }
        handleStartButtonClick()
      },
      primaryBtnTxt: isStudyGuideOpen ? 'View' : 'Continue',
      handlePrimaryButton: () => {
        isStudyGuideOpen ? openExamStudyGuide() : navigateToQuiz()
      }
    }
  }

  const handleNextSection = ({
    isCaughtUp,
    nextChapterNo,
    nextSectionNo,
    nextSectionUrl
  }) => {
    const {
      questionId,
      questionSetUuid
    } = visitedQuiz

    const quizUrl = `${sectionUrl}/${questionId}`
    const quizSection = studentProgress['quiz-section']
    const percentage = quizSection[questionSetUuid]

    const navigateToNextSection = () => {
      if (!isStudyGuideOpen) {
        window.location.href = quizUrl
        return
      }
      window.location.href = nextSectionUrl
    }

    return {
      dataTestId: 'quiz-completed',
      description: isCaughtUp ? 'caughtUp' : getDescription('nextSection', {
        nextChapterNo,
        nextSectionNo,
        nextSectionUrl,
        quizUrl,
        percentage
      }),
      note,
      studyGuideNote,
      primaryBtnTxt: isStudyGuideOpen ? 'View' : 'Continue',
      handlePrimaryButton: () => {
        isStudyGuideOpen ? openExamStudyGuide() : navigateToNextSection()
      },
      secondaryBtnTxt: examOpen ? 'Start' : isStudyGuideOpen ? 'Continue' : 'Review',
      handleSecondaryButton: () => {
        examOpen
          ? handleStartButtonClick()
          : navigateToNextSection()
      }
    }
  }

  const handleIfExamUpcoming = (unlockDate, { title }) => {
    const navigateToCourseUrl = () => (window.location.href = courseBaseUrl)
    const navigateToReviewChapter = () => (window.location.href = reviewChapterUrl)
    const unlockDateInSeconds = unlockDate / 1000
    const formattedDate = secondsToFormattedDateTimeShort(unlockDateInSeconds)
    const timezone = getTimezoneShort(secondsToDateString(unlockDateInSeconds))
    return {
      dataTestId: 'activity-completed-and-no-others-available',
      description: getDescription('examUpcoming', {
        title
      }),
      studyGuideNote,
      secondaryBtnTxt: isStudyGuideOpen
        ? 'Continue'
        : reviewChapterExistsAndNotStarted && 'View',
      handleSecondaryButton: () => {
        reviewChapterExistsAndNotStarted
          ? navigateToReviewChapter()
          : navigateToCourseUrl()
      },
      primaryBtnTxt: isStudyGuideOpen ? 'View' : 'Continue',
      handlePrimaryButton: () => {
        isStudyGuideOpen ? openExamStudyGuide() : navigateToCourseUrl()
      },
      note: <>The next exam will open on {formattedDate} {timezone}</>
    }
  }

  const handleChapterByType = nextChapter => {
    // eslint-disable-next-line
    switch (nextChapter.type) {
      case 'chapter': {
        const nextSection = nextChapter.sections[0] || {}
        const nextSectionUrl = `${courseBaseUrl}/#/${nextSection.section_uuid}`
        return handleNextSection({
          nextChapterNo: chapterNo + 1,
          nextSectionNo: 1,
          nextSectionUrl
        })
      }
      case 'exam':
      case 'review': {
        const { cohort } = course
        const { startDate } = getCohortClosestExam(cohort) || {}
        const examDetails = {
          title: nextChapter.title
        }

        if (startDate) {
          const unlockDate = secondsToMilliseconds(startDate)

          return handleIfExamUpcoming(unlockDate, examDetails)
        } else if (!nextChapter.isFinalExam) {
          let chapterIndex = chapters.findIndex(c =>
            c.chapter_uuid === nextChapter.chapter_uuid)
          nextChapter = chapters[++chapterIndex]

          while (!nextChapter.sections && chapterIndex < (chapters.length - 1)) {
            nextChapter = chapters[++chapterIndex]
          }

          if (!nextChapter || !nextChapter.sections ||
            !nextChapter.sections.length) return {}
          const nextSection = nextChapter.sections[0] || {}
          const nextSectionUrl = `${courseBaseUrl}/#/${nextSection.section_uuid}`
          return handleNextSection({
            isCaughtUp: examOpen,
            nextChapterNo: chapterNo + 1,
            nextSectionNo: 1,
            nextSectionUrl
          })
        }
        break
      }
      case 'WritingAssignmentChapterRecord': {
        return handleWritingAssignment(nextChapter)
      }
    }
  }

  const handleNextChapterAfterWriting = ({
    writingsChapter,
    quizUrl,
    percentage
  }) => {
    const chapterIndex = chapters.findIndex(chapter =>
      chapter.chapter_uuid === writingsChapter.chapter_uuid)
    const nextChapter = chapters[chapterIndex + 1]
    if (nextChapter) return handleChapterByType(nextChapter)
    return {
      dataTestId: 'quiz-completed',
      description: (
        <>
          You completed
          <a href={quizUrl} rel='noopener noreferrer'>
              Quiz {quizNo}
          </a> in{' '}
          <a
            href={sectionUrl}
            rel='noopener noreferrer'
          >Section {chapterNo}.{sectionNo}
          </a> and scored {percentage}%. Review your answers or continue to the
          <a
            href={`${courseBaseUrl}`}
            rel='noopener noreferrer'
          > Course Homepage
          </a>.
        </>
      ),
      note,
      primaryBtnTxt: 'Continue',
      handlePrimaryButton: () => { window.location.href = courseBaseUrl },
      secondaryBtnTxt: 'Review',
      handleSecondaryButton: () => { window.location.href = quizUrl }
    }
  }

  const handleWritingAssignment = writingsChapter => {
    const { questionId, questionSetUuid } = visitedQuiz
    const latestCohort = getLatestCohort(course)
    const { milestones: writingAssignmentsData } = latestCohort || {}
    const { title, chapter_uuid: assignmentUUID } = writingsChapter
    const quizUrl = `${sectionUrl}/${questionId}`
    const quizSection = studentProgress['quiz-section']
    const percentage = quizSection[questionSetUuid]
    const assignmentData = writingAssignmentsData?.find(assignment =>
      assignment.datoAssignmentUUID === assignmentUUID)

    if (!assignmentData) {
      return handleNextChapterAfterWriting({
        writingsChapter,
        quizUrl,
        percentage
      })
    }

    const currentTime = secondsSinceEpoch()
    const unlockTime = dateToSecondsSinceEpoch(new Date(assignmentData.unlockTime))
    const lockTime = dateToSecondsSinceEpoch(new Date(assignmentData.lockTime))
    const isOpenAssignment = (!unlockTime || currentTime > unlockTime) &&
      currentTime < lockTime

    if (!isOpenAssignment) {
      return handleNextChapterAfterWriting({
        writingsChapter,
        quizUrl,
        percentage
      })
    }

    const nextSectionUrl = `${courseBaseUrl}/#/${assignmentUUID}/writing_assignment`
    const formattedDate = secondsToFormattedDateTimeShort(lockTime)
    const timezone = getTimezoneShort(secondsToDateString(lockTime))
    const navigateToUrl = () => {
      if (!isStudyGuideOpen) {
        window.location.href = quizUrl
      }
      window.location.href = nextSectionUrl
    }
    return {
      dataTestId: 'quiz-before-writing-completed',
      description: getDescription('writingAssignment', {
        percentage,
        quizUrl,
        title,
        nextSectionUrl
      }),
      studyGuideNote,
      primaryBtnTxt: isStudyGuideOpen ? 'View' : 'Continue',
      handlePrimaryButton: () => {
        isStudyGuideOpen ? openExamStudyGuide() : navigateToUrl()
      },
      secondaryBtnTxt: examOpen ? 'Start' : isStudyGuideOpen ? 'Continue' : 'Review',
      handleSecondaryButton: () => {
        examOpen
          ? handleStartButtonClick()
          : navigateToUrl()
      },
      note: <>{title} is open until {formattedDate} {timezone}</>
    }
  }

  // when last activity is quiz and completed
  const handleCompletedQuiz = () => {
    const chapterIndex = chapters.findIndex(c => c.chapter_uuid === chapterUuid)
    const chapter = chapters[chapterIndex]
    if (sectionNo < chapter.sections.length) {
      const nextSection = chapter.sections[sectionNo]
      const nextSectionUrl = `${courseBaseUrl}/#/${nextSection.section_uuid}`
      return handleNextSection({
        nextChapterNo: chapterNo,
        nextSectionNo: sectionNo + 1,
        nextSectionUrl
      })
    } else if (chapterIndex < chapters.length - 1) {
      const nextChapter = chapters[chapterIndex + 1]
      return handleChapterByType(nextChapter)
    }
    return {}
  }

  let data = null
  const { questionSetUuid } = visitedQuiz

  const isQuizCompleted = questionSetUuid in quizSection
  if (!isQuizCompleted) {
    data = handleInProgressQuiz()
  } else {
    data = handleCompletedQuiz()
  }

  if (data?.description === 'caughtUp') {
    return (
      <ExamOpenAndCompletedCard
        course={course}
        openExamStudyGuide={openExamStudyGuide}
        courseProgress={courseProgress}
        isCaughtUp
        examOpenData={examOpenData}
        noAssessments={noAssessments}
      />
    )
  }

  return (
    <CardInfo
      course={course}
      {...data}
    />
  )
}

export default QuizCard
