import React, { useContext, useState } from 'react'
import api from '../../api'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'
import { PrimaryButton } from '../Buttons/style'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import EmailPassword from './EmailPassword'
import {
  Snackbar,
  Container,
  Header,
  Description,
  ActionContainer,
  Text
} from './style'

const VerifyEmailStudent = ({
  studentEmail
}) => {
  const modes = {
    VERIFY_EMAIL_MESSAGE: 'verifyEmailMessage',
    EMAIL_PASSWORD_ENTRY: 'emailPasswordEntry',
    EMAIL_UPDATE_SUCCESS: 'emailUpdateSuccess'
  }
  const [showSnackbar, setShowSnackbar] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [currentMode, setCurrentMode] = useState(modes.VERIFY_EMAIL_MESSAGE)
  const [isLoading, setIsLoading] = useState(false)
  const { logout } = useContext(Auth0Context)

  const showEmailResentMessage = () => {
    setShowSnackbar('show')
    setTimeout(() => {
      setShowSnackbar('')
    }, 5000)
  }

  const resendEmail = async () => {
    setIsLoading(true)
    const { success, message, error } = await api.resendEmail()
    setIsLoading(false)

    if (!success) return console.error('Error when resending email:', message || error)
    showEmailResentMessage()
  }

  const changeEmail = () => {
    setNewEmail('')
    setCurrentMode(modes.EMAIL_PASSWORD_ENTRY)
  }

  return (
    <Container>
      {isLoading && <LoadingSpinner />}

      {currentMode === modes.VERIFY_EMAIL_MESSAGE && (
        <div className='content' data-testid='verify-email-wrapper' style={{ marginTop: '191px' }}>
          <Header>First things first... </Header>
          <Description>
            Please verify your email address by following the link in the email that we sent to
            <span> {studentEmail}</span>.
          </Description>
          <ActionContainer>
            <Text>
              If you didn’t receive our email, you can&nbsp;
              <span
                onClick={resendEmail}
                data-testid='resend-email'
              >
                resend the email
              </span> or&nbsp;
              <span
                onClick={changeEmail}
                data-testid='change-email'
              >
                change the email address
              </span> associated with your account.
            </Text>
          </ActionContainer>
        </div>
      )}

      {currentMode === modes.EMAIL_PASSWORD_ENTRY && (
        <EmailPassword
          newEmail={newEmail}
          setNewEmail={setNewEmail}
          studentEmail={studentEmail}
          modes={modes}
          setCurrentMode={setCurrentMode}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}

      {currentMode === modes.EMAIL_UPDATE_SUCCESS && (
        <div className='content' data-testid='email-updated-wrapper' style={{ marginTop: '191px' }}>
          <Description>
            This account has lost access to your enrolled course(s).
            Please logout, click the verification link in the email sent to {newEmail},
            and then log back in with the new email.
          </Description>
          <PrimaryButton
            data-testid='btn-logout'
            onClick={logout}
            width='145px'
          >
            Logout
          </PrimaryButton>
        </div>
      )}

      <Snackbar data-testid='email-resent' className={showSnackbar}>
        Email with verification link has been resent to <span>{studentEmail}</span>.
        Please verify your email address by clicking the link.
      </Snackbar>
    </Container>
  )
}

export default VerifyEmailStudent
