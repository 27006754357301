import React, { useContext } from 'react'
import { AppContext } from '../../ContextProvider/ContextProvider'
import '@splidejs/react-splide/dist/css/splide-core.min.css'
import { getForCreditCourses } from '../utils'
import { shuffle } from 'lodash'
import LargeHeroSlide from './LargeHeroSlide'
import useWindowSize from '../../../hooks/useWindowSize'
import { BREAKPOINTS } from '../../../mediaQueries'
import SmallHeroSlide from './SmallHeroSlide'
import { getMoreCourses } from '../../../utilities/course'

const MESSAGE_LIST = [
  'Hi there',
  'Welcome',
  'Hey',
  'Salutations',
  'Greetings',
  'Good to see you',
  'Happy [day of the week]'
]

const getRandomWelcomeNote = () => {
  const dayOfWeek = new Date().toLocaleString('en-us', { weekday: 'long' })
  const message = MESSAGE_LIST[
    Math.floor(Math.random() * MESSAGE_LIST.length)
  ].replace('[day of the week]', dayOfWeek)
  return message
}

function Hero () {
  const { courses, catalogCourses, studentData, activeCourses } = useContext(
    AppContext
  )
  const [windowWidth] = useWindowSize()

  const { firstName, preferredName } = studentData || {}
  const studentName = preferredName || firstName || 'student'
  const randomWelcomeNote = getRandomWelcomeNote()
  const isLargeScreen = windowWidth > BREAKPOINTS.mobile

  const forCreditCourses = getForCreditCourses(courses)
  const coursesToDisplay = forCreditCourses.length
    ? shuffle(forCreditCourses)
    : getMoreCourses(courses, activeCourses).slice(0, 4)

  const showRecommendedCourses = !forCreditCourses.length

  return (
    <>
      {isLargeScreen ? (
        <LargeHeroSlide
          coursesToDisplay={coursesToDisplay}
          catalogCourses={catalogCourses}
          randomWelcomeNote={randomWelcomeNote}
          studentName={studentName}
          showRecommendedCourses={showRecommendedCourses}
        />
      ) : (
        <SmallHeroSlide
          coursesToDisplay={coursesToDisplay}
          catalogCourses={catalogCourses}
          randomWelcomeNote={randomWelcomeNote}
          studentName={studentName}
          showRecommendedCourses={showRecommendedCourses}
        />
      )}
    </>
  )
}

Hero.displayName = 'Hero'
export default Hero
