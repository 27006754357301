import styled, { createGlobalStyle } from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const ModalWrapper = styled.div`
  font-size: 16px;
  line-height: 26px;
  min-height: 203px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ModalContent = styled.div`
  margin-bottom: 24px;
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  ${mediaqueries.mobile`
    flex-direction: column;
    justify-content: stretch
  `}
`

export const GlobalStyle = createGlobalStyle`
  .modal-dialog {
    max-width: none;
    display: flex;
    justify-content: center;
  }
  
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-body {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .modal-content {
    background-color: #25272B;
    width: auto;
    min-height: 203px;
    display: flex;
    align-items: flex-start;
    margin: auto;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    border-radius: 15px; 
  }
  
  .modal-button {
    height: 55px;
    width: 155px;
    font-size: 13px;
    white-space: normal;
    margin-bottom: 10px;
  }
`
