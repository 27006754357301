export const TYPE_FORM_IDS = {
  audit: 'h3LRn3TI',
  exception: 'H8ZVJKgw',
  drop: 'dqhJWnGz',
  'drop_v2.1': 'Fnk0EKQQ',
  auditDrop: 'RrQgKdm4',
  'auditDrop_v2.1': 'icF4IVY3',
  scholarshipDrop: 'PAaGAiux',
  withdrawal: 'ItK6drGZ'
}

export const FORM_LINKS = {
  transfer: 'course-exit/transfer',
  drop: 'course-exit/drop',
  withdrawal: 'course-exit/withdraw',
  audit: 'course-exit/audit',
  exception: 'course-exit/exception',
  audienceConsent: '/audience-consent'
}

export const FORM_TYPES = {
  audit: 'audit',
  exception: 'exception',
  withdrawal: 'withdrawal',
  drop: 'drop'
}

// A reference of the form outcomes coming from the API
export const FORM_OUTCOMES = {
  T3: 'T3',
  drop: 'drop',
  audit: 'audit',
  voucher: 'voucher',
  transferAppeal: 'transferAppeal',
  withdrawal: 'withdrawal',
  successPlan: 'successPlan',
  successGuidance: 'successGuidance'
}

export const FORM_URLS = {
  successPlan: 'https://outlierorg.typeform.com/to/wjnT5wME',
  timeTracking: 'https://outlierorg.typeform.com/to/HZYx9Z0t',
  audienceConsent: 'https://outlierorg.typeform.com/to/OLAnQYuR',
  audienceConsentStudent: 'https://outlierorg.typeform.com/to/H1Oxgj7z'
}

export const WEEK_DAYS = [
  'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
]
