import React from 'react'
import { addDays, subDays } from 'date-fns'
import { getHoursFromDate } from '../../../utilities/dateTime'
import { getDayDatesInfo } from '../../StudyBlocksDisplay/utils'
import { ActiveSessionIcon, UpcomingSessionIcon } from './styles'
import { ReactComponent as StudyBlocksAttended } from '../../../assets/icons/study-block-attended.svg'
import { ReactComponent as StudyBlocksMissed } from '../../../assets/icons/study-block-missed.svg'

export function getStudyBlocksForNextFiveDays (studyBlocks) {
  if (!studyBlocks.length) return []

  const nextFiveDates = [
    new Date(subDays(new Date(), 1)).toDateString(),
    new Date().toDateString(),
    new Date(addDays(new Date(), 1)).toDateString(),
    new Date(addDays(new Date(), 2)).toDateString(),
    new Date(addDays(new Date(), 3)).toDateString()
  ]

  return nextFiveDates.map((date) => {
    const studyBlock = studyBlocks.find(
      (studyBlock) => studyBlock?.date === date
    )
    return { date, blocks: studyBlock?.blocks }
  })
}

export function getBlockIcon (date = '', block = {}) {
  const { isToday } = getDayDatesInfo(date)

  const currentHours = getHoursFromDate(new Date())
  const isActive =
    isToday && block?.start <= currentHours && block?.end >= currentHours

  if (isActive) {
    return {
      icon: <ActiveSessionIcon data-testid='active-session-icon' />,
      isActive
    }
  }

  if (block?.isAttended) {
    return { icon: <StudyBlocksAttended data-testid='attended-session-icon' /> }
  }

  if (block?.isAttended === false) {
    return { icon: <StudyBlocksMissed data-testid='missed-session-icon' /> }
  }

  return { icon: <UpcomingSessionIcon data-testid='upcoming-session-icon' /> }
}
