import styled from 'styled-components'
import { TextLink } from '../../Links/style'

export const Footer = styled.footer`
  background-color: #151518;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;

  &:before {
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.25);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 465px) {
    iframe[name="us-entrypoint-widgetApp"][id*="us–style-manager-1widgetIframe"] {
      max-width: 95% !important;
      margin: auto !important;
    }
    iframe[name="us-entrypoint-buttonV2"] {
      left: 54%;
    }
  }
`

export const ListGroupWrapper = styled.div`
  display: flex;
  position: relative;
`

export const ListGroup = styled.ul`
  display: inline-flex;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  list-style: none;

  @media (max-width: 400px) {
    li:nth-child(2) span {
      padding-left: 4px;
    }
  }
`

export const ListItem = styled.li`
  display: inline-block;
`

export const Link = styled.a`
  padding: 10px 0px;
  display: inline-block;
  border: 1px solid #727272;
  font-size: calc(0.5rem + var(--font-increase-rem));
  border: 1px solid transparent;

  > * {
    display: inline-block;
    vertical-align: middle;
  }

  span {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 400px) {
    font-size: calc(0.25rem + var(--font-increase-rem));
  }
  ${TextLink}
`
