import React from 'react'
import { RadioWrapper } from './styles'

const RadioInput = ({ className, label, name, onChange, children, checked }) => {
  return (
    <RadioWrapper className={className}>
      <label htmlFor={label}>
        <div className='d-flex align-items-center'>
          <input
            className='radio'
            type='radio'
            id={label}
            name={name}
            value={label}
            onChange={onChange}
            checked={checked}
          />
          <span className='ml-2'>{label}</span>
        </div>
        <p className='subtext'>{children}</p>
      </label>
    </RadioWrapper>
  )
}

export default RadioInput
