import React from 'react'
import config from '../../config'
import { CourseSlotContainer } from './style'

const CourseSlot = ({
  course,
  semesterStart,
  catalogCourses
}) => {
  const { courseId, courseNameText } = course || {}
  const courseIcon = catalogCourses?.find(course => {
    return course?.uuid?.uuid === courseId
  })?.media?.courseIcon?.url || config.courseIcon(courseId, true)

  return (
    <CourseSlotContainer
      data-testid='course-slot'
      isSvg={!!courseIcon?.endsWith('.svg')}
    >
      <div className='img'>
        {courseIcon && <img src={courseIcon} alt='courseIcon' />}
      </div>
      <div className='course'>
        <p>{courseNameText}</p>
        <p>Starts {semesterStart}</p>
      </div>
    </CourseSlotContainer>
  )
}

CourseSlot.displayName = 'CourseSlotV2'

export default CourseSlot
