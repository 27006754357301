import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  HorizontalLine,
  MainText,
  PageHeader,
  RequestPageContainer
} from './style'
import { CustomLink, BackLink } from '../style'
import BackIcon from '../../../assets/icons/back-arrow-caret.svg'

function RefundRequested () {
  const history = useHistory()

  const returnToAccountArea = () => history.push('/account')

  return (
    <RequestPageContainer>
      <PageHeader>Request a refund</PageHeader>

      <HorizontalLine />

      <MainText>
        You’ve already requested a refund for the College Pathways Certificate.
        If you have questions about your refund, please email{' '}
        <CustomLink
          href='mailto:success@outlier.org'
        >
          success@outlier.org
        </CustomLink>
        .
      </MainText>

      <BackLink
        style={{ position: 'absolute', left: '29px', top: '-44px' }}
        onClick={returnToAccountArea}
      >
        <img src={BackIcon} style={{ marginRight: '13px' }} alt='back-icon' />
        <span>back</span>
      </BackLink>
    </RequestPageContainer>
  )
}

export default RefundRequested
