import React, { useState } from 'react'
import CheckIcon from '../../../assets/icons/correct.svg'
import CloseIcon from '../../../assets/icons/close-icon.svg'
import {
  ToastWrapper,
  ToastHeader,
  ToastHeaderData,
  HeaderText,
  ToastBody,
  AddToCalendarIcon
} from './style'
import { TransparentButton } from '../style'
import CalendarList from '../../CalendarList/CalendarList'

function ToastNotification ({
  show, setShow, isAddToCalendarDisabled,
  calendarKey, handleAddToCalendar
}) {
  const removeToast = () => {
    setShow(false)
  }

  const [showCalendarList, setShowCalendarList] = useState(false)
  const handleCalendarDropdown = () => setShowCalendarList(!showCalendarList)
  const onAddToCalendar = async () => {
    if (calendarKey) return handleCalendarDropdown()
    const newCalendarKey = await handleAddToCalendar()
    setShowCalendarList(!!newCalendarKey)
  }

  if (!show) return null

  return (
    <ToastWrapper data-testid='toast-notification'>
      <ToastHeader>
        <ToastHeaderData>
          <img src={CheckIcon} alt='check-icon' />
          <HeaderText>
            Work blocks saved!
          </HeaderText>
        </ToastHeaderData>

        <img
          data-testid='close-icon'
          src={CloseIcon}
          alt='Close Toast'
          onClick={removeToast}
        />
      </ToastHeader>

      <ToastBody>
        <p>
          Visit the {' '}
          <a href='#/'>Student Dashboard</a> {' '}
          to view your study blocks.
        </p>

        <TransparentButton
          onClick={onAddToCalendar}
          disabled={isAddToCalendarDisabled}
          style={{ position: 'relative', width: 'fit-content' }}
        >
          <AddToCalendarIcon style={{ marginRight: '8px' }} />
          add to calendar
          {showCalendarList && (
            <CalendarList
              calendarKey={calendarKey}
              handleCalendarDropdown={handleCalendarDropdown}
            />
          )}
        </TransparentButton>

        <p className='italic'>
          Add to calendar is available in the Study blocks settings if you wish to
          complete this later.
        </p>
      </ToastBody>
    </ToastWrapper>
  )
}

ToastNotification.displayName = 'ToastNotification'
export default ToastNotification
