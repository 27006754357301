import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
  NotificationCard,
  NotificationCardHeader,
  NotificationCardTitle,
  NotificationCardUnseenIcon,
  NotificationCardBody,
  Link,
  NotificationCardFooter,
  NotificationFooterText,
  Dot
} from './styles'

const getAnnouncementBody = (body) => {
  if (body.length > 100) return `${body.slice(0, 100)}...`
  return body
}

const Notification = React.memo(({ announcement, setShowModal }) => {
  const history = useHistory()
  const { id, title, seen, body, link, date, name, course } = announcement

  const handleOnClick = useCallback(() => {
    history.push({
      pathname: '/announcements',
      search: `?id=${id}`
    })
    setShowModal(false)
  }, [history, id, setShowModal])

  return (
    <NotificationCard data-testid='notification' onClick={handleOnClick}>
      <NotificationCardHeader>
        <NotificationCardTitle>{title}</NotificationCardTitle>
        {!seen && <NotificationCardUnseenIcon data-testid='seen-icon' />}
      </NotificationCardHeader>
      <NotificationCardBody>
        {getAnnouncementBody(body)}
        {link && (
          <Link
            onClick={e => e.stopPropagation()}
            href={link?.href} target='_blank'
          >
            {link?.text}
          </Link>
        )}
      </NotificationCardBody>
      <NotificationCardFooter>
        <NotificationFooterText>
          {date}
          <Dot show />
          {name}
        </NotificationFooterText>
        <NotificationFooterText>{course}</NotificationFooterText>
      </NotificationCardFooter>
    </NotificationCard>
  )
})

Notification.displayName = 'Notification'
export default Notification
