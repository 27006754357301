export const cohortTypes = {
  SHORT: '7',
  LONG: '14',
  AUDIT: 'audit',
  ENROLL: 'enroll'
}

export const durationTypes = {
  INTENSIVE: 'intensive',
  STANDARD: 'standard',
  EXTENDED: 'extended'
}

export const termState = {
  IN_PROGRESS: 'inProgress',
  IS_UPCOMING: 'isUpcoming',
  TERM_COMPLETED: 'termCompleted'
}

export const INTENSIVE_COHORT_DURATION = 8
export const STANDARD_COHORT_DURATION = 14
export const EXTENDED_COHORT_DURATION = 39

export const COHORT_DURATIONS = {
  [durationTypes.INTENSIVE]: [INTENSIVE_COHORT_DURATION, 7],
  [durationTypes.STANDARD]: [STANDARD_COHORT_DURATION, 15],
  [durationTypes.EXTENDED]: [EXTENDED_COHORT_DURATION]
}
