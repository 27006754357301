const TYPE = {
  enroll: 'Enroll (For credit)',
  audit: 'Audit (Ungraded)'
}

const DURATION = {
  standard: '14 Weeks (Standard)',
  intensive: '7 Weeks (Intensive)'
}

const CHAPTER_TYPE = {
  ORIENTATION: 'orientation',
  CHAPTER: 'chapter',
  EXAM: 'exam',
  WRITING_ASSIGNMENT_CHAPTER_RECORD: 'WritingAssignmentChapterRecord'
}

const PROGRESS_TYPE = {
  CONCEPT_MAP_PROGRESS: 'concept-map-progress',
  CONCEPT_MAP_COMPLETE: 'concept-map-complete',
  PRACTICE_TERM_COMPLETE: 'practice-term-complete',
  LECTURE_COMPLETE: 'lecture-complete',
  LECTURE_VIDEO_PROGRESS: 'lecture-video-progress',
  SECTION_VIDEO_PROGRESS: 'section-video-progress',
  ORIENTATION_SECTION_PROGRESS: 'orientation-section-progress',
  LAST_PROGRESS_TIMESTAMP: 'last-progress-timestamp',
  GUESSWORK_COMPLETE: 'guesswork-complete',
  READING_COMPLETE: 'reading-complete',
  READING_PROGRESS: 'reading-progress',
  QUIZ_SECTION: 'quiz-section',
  EXAM_COMPLETE: 'exam-complete',
  EXAM_PERCENTAGE: 'exam-percentage',
  LAST_QUIZ_UUID: 'last-quiz-uuid',
  LAST_ACTIVE_LEARNING_UUID: 'last-active-learning-uuid',
  LAST_GUESSWORK_UUID: 'last-guesswork-uuid'
}
const courseState = [
  {
    title: 'Type',
    name: 'type',
    children: TYPE
  }, {
    title: 'Duration',
    name: 'duration',
    children: DURATION
  }
]

export { courseState, TYPE, DURATION, CHAPTER_TYPE, PROGRESS_TYPE }
