const PLEASE_WAIT_FOR_ACADEMIC_ADVISOR = 'Your Academic Advisor will review your course selections and reach out via email if there are any issues or suggested changes.'
const SCHEDULE_MEETING = 'You can also schedule a meeting with them below if you have any questions or concerns.'
const DONE_COURSE_SELECTIONS = 'Once you are done making your course selections for the semester, please submit them to your Academic Advisor for review and approval below.'
const SCHEDULE_ADVISOR_MEETING = 'Schedule Advisor meeting'
const SUBMIT_FOR_REVIEW = 'Submit for Review'

export {
  PLEASE_WAIT_FOR_ACADEMIC_ADVISOR,
  SCHEDULE_MEETING,
  DONE_COURSE_SELECTIONS,
  SCHEDULE_ADVISOR_MEETING,
  SUBMIT_FOR_REVIEW
}
