import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import { PrimaryButton, SectionLink } from '../style'

export const RecommendedCoursesContainer = styled.div`
  padding: 0 var(--desktop-layout-padding);
  margin-bottom: 40px;

  ${mediaqueries.mobile`
    padding: 0;
  `}
`
export const RecommendedCoursesSlideContainer = styled.div`
  margin-top: 24px;
  & .splide__track {
    margin: 0 calc(var(--mobile-layout-padding) * -1);
    @media (any-hover: hover) {
      overflow: clip;
      overflow-clip-margin: 90px;
    }
  }
`
export const CourseTileWrapper = styled.div`
  position: relative;
  --tile-width: 315px;
  --tile-height: 145px;
  width: var(--tile-width);
  height: var(--tile-height);

  ${mediaqueries.mobile`
    width: 160px;
    height: 209px;
  `}
`
export const CourseTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, #000000 90%),
    ${({ courseImage }) => `url(${courseImage}?sat=-10)`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;

  ${mediaqueries.mobile`
    padding: 16px;
  `}

  @media (any-hover: hover) {
    position: absolute;

    &:hover {
      --hover-tile-width: 389px;
      --hover-tile-height: 300px;
      --translate-x: calc(
        ((var(--hover-tile-width) - var(--tile-width)) / 2) * -1
      );
      --translate-y: calc(
        ((var(--hover-tile-height) - var(--tile-height)) / 2) * -1
      );

      width: var(--hover-tile-width);
      height: var(--hover-tile-height);
      transform: translateX(var(--translate-x)) translateY(var(--translate-y));
      z-index: 3;
      box-shadow: 3px 2px 16px 0px #00000073;

      & .course-title {
        font-size: 27px;
        font-weight: 400;
      }

      & .learn-more {
        display: block;
      }
    }
  }
`
export const CourseTitle = styled.h5`
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0px;
  margin: 0;
`
export const ExploreCatalogLink = styled(SectionLink)`
  ${mediaqueries.mobile`
    display: none;
  `}
`

export const ExploreCatalogLinkMobile = styled(SectionLink)`
  display: none;

  ${mediaqueries.mobile`
    display: block;
    margin-top: 24px;
    font-size: 15px;
    line-height: 24px;
  `}
`
export const LearnMoreButton = styled(PrimaryButton)`
  display: none;
  height: 49px;
  padding: 16px 32px;
  margin-top: 24px;
  letter-spacing: 2px;
  line-height: 17px;
`
