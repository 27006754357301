import React, { useContext, useEffect } from 'react'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import isEmpty from 'lodash/isEmpty'
import api from '../../api'
import {
  COURSE_QUANTITY_LS_KEY,
  COURSE_QUANTITY_TOOLTIP,
  FULL_TIME_SLOT,
  PART_TIME_SLOT,
  STUDY_MODE
} from '../../Constants/gguDegrees'
import {
  ButtonsWrapper,
  Description,
  Page,
  PrimaryButton,
  SecondaryButton,
  Title
} from './style'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  isSegmentEventInLS,
  removeSegmentEventFromLS,
  setCurrentProspect,
  setFiredSegmentEventsInLS
} from '../../utilities/gguDegreeUtils'
import { GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import { COURSE_REGISTRATION_PAGEVIEW, COURSE_REGISTRATION_PAGE_SUBMITTED } from '../../Constants/eventType'

const CourseQuantityWarning = ({
  setIsLoading, currentProspect, courseQuantity, setCourseQuantity, setCurrentPage
}) => {
  const { sendEvent } = useSegmentEvents()
  const { updateContext } = useContext(AppContext)
  const { PART_TIME } = STUDY_MODE

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: '3-3'
  }

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: '3-2'
    })
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'Part-time Warning',
        page_number: '3-3'
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  const updateStudyMode = async (confirmedStudyMode, newCourseQuantity) => {
    localStorage.setItem(COURSE_QUANTITY_LS_KEY, newCourseQuantity || courseQuantity)
    newCourseQuantity && setCourseQuantity(newCourseQuantity)

    setIsLoading(true)
    const result = await api.updateProspectData(
      currentProspect?.prospectId, { studyMode: confirmedStudyMode }
    )
    setIsLoading(false)

    if (isEmpty(result)) return

    setCurrentProspect(
      updateContext,
      {
        ...currentProspect,
        studyMode: confirmedStudyMode
      })
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
      properties: {
        page_name: 'Part-time Warning',
        page_number: '3-3',
        time_commitment: confirmedStudyMode,
        confirmed_course_quantity: `${newCourseQuantity || courseQuantity}`
      }
    })
    setCurrentPage(GGU_V2_ENROLLMENT_PAGES.RECOMMENDED_COURSES)
  }

  return (
    <Page alignItems='flex-start'>
      <Title textAlign='left'>
        Heads up: Reducing your course load affects your status
      </Title>
      <Description>
        Taking fewer than 3 courses per term will change your status to part-time.&nbsp;
        This could affect your financial aid and graduation date.&nbsp;
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={props => (
            <Tooltip id='button-tooltip' {...props}>
              {COURSE_QUANTITY_TOOLTIP}
            </Tooltip>
          )}
        >
          <span className='link'>Learn more.</span>
        </OverlayTrigger>
      </Description>
      <ButtonsWrapper>
        <PrimaryButton onClick={() => updateStudyMode(PART_TIME)} marginright='16px'>
          Continue
        </PrimaryButton>
        <SecondaryButton
          variant='secondary'
          onClick={() => {
            const { studyMode } = currentProspect || {}
            updateStudyMode(
              studyMode,
              studyMode === STUDY_MODE.FULL_TIME ? FULL_TIME_SLOT : PART_TIME_SLOT
            )
          }}
        >
          Keep Current Course Quantity
        </SecondaryButton>
      </ButtonsWrapper>
    </Page>
  )
}

CourseQuantityWarning.displayName = 'CourseQuantityWarning'

export default CourseQuantityWarning
