import React, { useState } from 'react'
import WidgetState from '../WidgetState'
import { Title } from './style'
import { Separator } from '../style'
import config from '../../../config'

const GradedCard = ({
  course,
  assignments,
  dismissAssignments
}) => {
  const [hiddenAssignments, setHiddenAssignments] = useState([])
  const { id } = course
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)
  let hasGradedAssignments = false
  const gradedAssignments = []

  assignments.forEach(assignment => {
    const isGradedAssignment = assignment.isGraded
    if (isGradedAssignment) {
      gradedAssignments.push(assignment)
      hasGradedAssignments = true
    }
  })
  const handleDismiss = (assignmentId) => {
    setHiddenAssignments((prevValue) => {
      return [...prevValue, assignmentId]
    })
    dismissAssignments(assignmentId)
  }

  const handleReview = (assignment) => {
    dismissAssignments(assignment.id)
    window.location.href = assignment.url
  }

  const hiddenAssignmentsContains = (assignmentId) => {
    return hiddenAssignments.includes(assignmentId)
  }

  if (gradedAssignments.length === 0) { return }
  if (hasGradedAssignments) {
    gradedAssignments.forEach(assignment => {
      assignment.url =
        `${courseBaseUrl}/#/${assignment.chapter_uuid}/writing_assignment`
      assignment.description =
        <>
          {'The assignment '}
          <Title>{assignment.title}</Title>
          {' has been graded, and you received a score '}
          {`of ${assignment.grade}%.`}
        </>
    })
  }

  return (
    gradedAssignments.map((assignment, index) => {
      const { description } = assignment
      return (
        (!hiddenAssignmentsContains(assignment.id) &&
          <div key={index} data-testid='graded-description'>
            <Separator />
            <WidgetState
              description={description}
              primaryBtnTxt='Review'
              primaryBtnFunction={() => handleReview(assignment)}
              secondaryBtnTxt='Dismiss'
              secondaryBtnFunction={() => handleDismiss(assignment.id)}
            />
          </div>
        ))
    })
  )
}

export default GradedCard
