import React from 'react'
import { ContentWrapper } from './style'

const PageContent = ({ children }) => {
  return (
    <ContentWrapper data-testid='content'>
      {children}
    </ContentWrapper>
  )
}

export default PageContent
