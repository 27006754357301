import React, { useState, useEffect, useContext } from 'react'
import { FullStoryAPI } from 'react-fullstory'
import jwtDecode from 'jwt-decode'

import createClient from './client'
import config from '../../config'
import api from '../../api'
import { STUDENT_LOGIN } from '../../Constants/eventType'
import { MULTIPLE } from '../../Constants/frequency'
import { EMAIL_VERIFIED } from '../../Constants'
import { isOutlierAccount } from '../../utilities/user'

const DEFAULT_REDIRECT_CALLBACK = appState => {
  window.history.replaceState({}, document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname)
}

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [studentId, setStudentId] = useState(null)
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0token = setAuth0token()
      if (auth0token) {
        setIsAuthenticated(true)
        const user = jwtDecode(auth0token)
        setUser(user)
        setLoading(false)
        setRedirect(true)
        return
      }
      const auth0FromHook = await createClient()
      setAuth0(auth0FromHook)
      const hasLoggedIn = !config.isEmailVerified() && window.location.search.includes('code=')
      if (hasLoggedIn) {
        let appState = ''
        try {
          const res = await auth0FromHook.handleRedirectCallback()
          appState = res.appState
        } catch (e) {
        }
        onRedirectCallback(appState)
      }
      const isAuthenticated = await auth0FromHook.isAuthenticated()
      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        const isAdmin = isOutlierAccount(user?.email)
        const searchParams = new URLSearchParams(window.location.search)
        const emailVerifiedParam = searchParams.get(EMAIL_VERIFIED)
        const shouldVerifyUser = !user.email_verified && (isAdmin || emailVerifiedParam)

        if (shouldVerifyUser) {
          try {
            const response = await api.updateAuth0User({ email_verified: true })
            const { email_verified: emailVerified } = response || {}
            if (emailVerified) window.location.href = window.location.origin
          } catch (error) {
            console.error(error)
          }
        }

        setUser(user)
        await initFullstory()
        await api.enrollToCollegeSuccessCourse()
      }
      if (hasLoggedIn) {
        const eventProperties = {
          properties: {
            time_stamp: new Date().getTime()
          },
          event: STUDENT_LOGIN,
          frequency: MULTIPLE
        }
        api.submitTrackedEvent(eventProperties)
      }
      setLoading(false)
      setRedirect(true)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const setAuth0token = () => {
    const locationHref = window.location.href
    const URLtoken = locationHref.split('#/')[0].split('token=')[1]

    if (URLtoken) {
      localStorage.setItem('auth0token', URLtoken)
      return URLtoken
    }

    const auth0token = localStorage.getItem('auth0token')
    return auth0token
  }

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }

  const initFullstory = async () => {
    try {
      const studentId = await api.getStudentId()

      FullStoryAPI('identify', studentId, {
        // Real displayName is not passed to FS in order to limit exposure of PII.
        // As FS needs either displayName or email, first one is passed with the student id
        displayName: studentId,
        studentId_str: studentId
      })

      setStudentId(studentId)
    } catch (e) {
      console.error('Error when fetching studentId: ', e.message)
    }
  }

  if (!redirect) return null
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        studentId,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: () => {
          const { courseBaseUrl } = config
          localStorage.removeItem('userCart')
          auth0Client.logout({
            returnTo: courseBaseUrl
          })
        }
      }}
    >
      {React.cloneElement(children, { isAuthenticated, user })}
    </Auth0Context.Provider>
  )
}
