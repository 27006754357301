import React, { useEffect, useState, useMemo } from 'react'

import { PageContent, ScheduleText } from './style'
import RemoveModal from '../CartSideBarPanel/RemoveConfirmationModal'
import Card from './Card'

const CatalogState = ({ reset, rerenderParent, isParentRerendered, additionalIds }) => {
  const [deleted, setDeleted] = useState(false)
  const [removeData, setRemoveData] = useState()
  const [removeModal, setRemoveModal] = useState(false)
  const [removeName, setRemoveName] = useState('')
  useEffect(() => {
    if (deleted) {
      setDeleted(false)
    }
  }, [deleted])
  const cartItems = useMemo(
    () => JSON.parse(localStorage.getItem('userCart')) || [],
    // eslint-disable-next-line
    [deleted, isParentRerendered]
  )

  const courses = cartItems || []
  const onRemove = () => {
    const filterCourses = courses.filter((i) => i.id !== removeData)
    localStorage.setItem('userCart', JSON.stringify(filterCourses))
    setDeleted(true)
    reset()
  }

  const alreadySelected = courses.find(
    (item) => additionalIds.includes(item?.id)
  )

  return (
    <>
      <PageContent data-testid='page-content'>
        <ScheduleText data-testid='title'>
          This course is already in your cart.
        </ScheduleText>
        {alreadySelected?.id && (
          <Card
            cartItems={cartItems}
            data={alreadySelected}
            onRemove={(id) => {
              setRemoveModal(!removeModal)
              setRemoveData(id)
              setRemoveName(alreadySelected.name)
            }}
            onUpdate={() => {
              setDeleted(true)
              rerenderParent()
            }}
          />
        )}
      </PageContent>
      <RemoveModal
        show={removeModal}
        onHide={() => setRemoveModal(false)}
        onRemove={() => {
          onRemove()
          rerenderParent()
        }}
        courseName={removeName}
      />
    </>
  )
}

export default CatalogState
