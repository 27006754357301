import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useHistory } from 'react-router-dom'
import { POPUP_TYPE } from '../../Constants/voucher'
import { getPopupType } from '../../utilities/voucher'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  GlobalStyle,
  ButtonsContainer,
  PrimaryButton,
  StyledAnchor,
  StyledHeaderText,
  StyledList,
  SecondaryButton
} from './styled'

const VoucherUploadModal = () => {
  const {
    showVoucherPopup,
    setShowVoucherPopup,
    vouchers: { records } = {}
  } = useContext(AppContext)
  const history = useHistory()

  const [popupType, setPopupType] = useState(null)

  useEffect(() => {
    const popupType = getPopupType(records)
    if (!popupType) return

    setPopupType(popupType)
  }, [records])

  const buttonStyles = {
    height: '55px',
    width: '150px',
    fontSize: '13px',
    whiteSpace: 'normal'
  }

  const closeModal = () => {
    setPopupType(null)
    setShowVoucherPopup(false)
  }

  const handleRedirect = () => {
    setPopupType(null)
    history.push('/account/voucher')
  }

  const shouldNotRenderModal = !popupType || !showVoucherPopup
  if (shouldNotRenderModal) return null

  const isRemovableType = popupType === POPUP_TYPE.REMOVABLE

  const amazonCareerChoiceLink = (
    <StyledAnchor target='_blank' href='https://www.amazoncareerchoice.com/home'>
      Amazon Career Choice Dashboard
    </StyledAnchor>
  )

  return (
    <Modal
      show={!!popupType}
      centered
      onHide={() => null}
      data-testid='voucher-upload-modal'
    >
      <GlobalStyle />
      <Modal.Body>
        <>
          <StyledHeaderText>
            Action Required: Upload Your Amazon Career Choice Tuition Voucher
            for College Pathway!
          </StyledHeaderText>
          <p>
            Amazon Career Choice needs to approve your enrollment. Before your course starts:
          </p>
          <StyledList type='number'>
            <li>
              Go to {amazonCareerChoiceLink}{' '}
              to generate a unique voucher and fill it out.
            </li>
            <li>
              Once you have filled out the voucher, submit it{' '}
              <StyledAnchor onClick={handleRedirect}>here</StyledAnchor>.
            </li>
          </StyledList>
          <p>We look forward to seeing you in class!</p>
          <p>
            Note: If you do not submit your voucher before your course starts,
            we will have to drop you from the program.
          </p>
        </>
        <ButtonsContainer>
          {
            isRemovableType && (
              <SecondaryButton
                {...buttonStyles}
                onClick={closeModal}
              >
                Dismiss
              </SecondaryButton>
            )
          }
          <PrimaryButton
            {...{ ...buttonStyles }}
            onClick={handleRedirect}
          >
            Submit Voucher
          </PrimaryButton>
        </ButtonsContainer>
      </Modal.Body>
    </Modal>
  )
}

export default VoucherUploadModal
