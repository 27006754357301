import React, { useContext, useEffect, useMemo, useState } from 'react'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import { NUMBER_TO_ORDINALS } from '../../Constants'
import { COURSE_INTRO_PATH, GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import { AppContext } from '../ContextProvider/ContextProvider'
import Arrow from '../../assets/icons/arrow.svg'
import PlayIcon from '../../assets/icons/play-icon.svg'
import {
  Page,
  Title,
  HeroImage,
  CourseDetails,
  ButtonsWrapper,
  PrimaryButton,
  SecondaryButton
} from './style'
import TrailerModal from './TrailerModal'
import {
  COURSE_REGISTRATION_NEW_COURSE_CONFIRMED,
  COURSE_REGISTRATION_PAGEVIEW,
  COURSE_REGISTRATION_PAGE_SUBMITTED
} from '../../Constants/eventType'
import { isSegmentEventInLS, removeSegmentEventFromLS, setFiredSegmentEventsInLS } from '../../utilities/gguDegreeUtils'

const CourseIntro = ({
  recommendedCourses,
  setCurrentPage,
  courseQuantity,
  currentCourseNumber,
  setCurrentCourseNumber
}) => {
  const { sendEvent } = useSegmentEvents()
  const {
    catalogCourses
  } = useContext(AppContext)
  const [showTrailerModal, setShowTrailerModal] = useState(false)
  const { courseId: currentCourseId } =
    recommendedCourses?.[currentCourseNumber - 1] || {}

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: `4-${currentCourseNumber + 1}`
  }

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: '4-1'
    })
    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: `4-${currentCourseNumber - 1}`
    })
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: `Course ${currentCourseNumber} Recommendation`,
        page_number: `4-${currentCourseNumber + 1}`,
        [`recommended_course${currentCourseNumber}`]:
          recommendedCourses?.[currentCourseNumber - 1]?.courseName || ''
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentCourseId) return
    setCurrentPage(GGU_V2_ENROLLMENT_PAGES.RECOMMENDED_COURSES)
    // eslint-disable-next-line
  }, [currentCourseId])

  const currentCourse = useMemo(() => {
    if (!currentCourseId) return null

    return catalogCourses?.find(course => {
      return course?.uuid?.uuid === currentCourseId
    })
  }, [currentCourseId, catalogCourses])

  const {
    media,
    courseName,
    oneLineDescription
  } = currentCourse || {}

  const { trailerVideo } = media || {}
  const { kalturaEmbedCode } = trailerVideo || {}

  const gotoNextCourse = () => {
    sendEvent({
      eventName: COURSE_REGISTRATION_NEW_COURSE_CONFIRMED,
      properties: {
        [`registeredCourse${currentCourseNumber}`]: courseName
      }
    })
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
      properties: {
        page_name: `Course ${currentCourseNumber} Recommendation`,
        page_number: `4-${currentCourseNumber + 1}`,
        [`recommended_course${currentCourseNumber}`]:
          recommendedCourses?.[currentCourseNumber - 1]?.courseName || '',
        [`course${currentCourseNumber}_decision`]: 'Confirm',
        [`registeredCourse${currentCourseNumber}`]: courseName
      }
    })

    if (currentCourseNumber >= courseQuantity) {
      setCurrentPage(GGU_V2_ENROLLMENT_PAGES.REVIEW)
      return
    }

    setCurrentCourseNumber(currentCourseNumber + 1)
    setCurrentPage(GGU_V2_ENROLLMENT_PAGES.COURSE_INTRO)
  }

  const changeCourse = () => {
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
      properties: {
        page_name: `Course ${currentCourseNumber} Recommendation`,
        page_number: `4-${currentCourseNumber + 1}`,
        [`recommended_course${currentCourseNumber}`]:
          recommendedCourses?.[currentCourseNumber - 1]?.courseName || '',
        [`course${currentCourseNumber}_decision`]: 'Change',
        [`registeredCourse${currentCourseNumber}`]: null
      }
    })
    setCurrentPage(GGU_V2_ENROLLMENT_PAGES.CATALOG)
  }

  return (
    <Page>
      <Title>Your {NUMBER_TO_ORDINALS[currentCourseNumber]} course</Title>
      {kalturaEmbedCode && (
        <HeroImage onClick={() => { setShowTrailerModal(true) }}>
          <img src={media?.heroImage?.url} alt='Hero' />
          <img src={PlayIcon} alt='Play' />
        </HeroImage>
      )}
      <CourseDetails>
        <p>{courseName?.replace(' - GGU', '') || ''}</p>
        <div>
          <span>
            {oneLineDescription?.descriptionText || ''}
          </span>
          <button onClick={() => {
            setCurrentPage({
              ...GGU_V2_ENROLLMENT_PAGES.CATALOG_COURSE_DETAILS,
              previousPagePathname: COURSE_INTRO_PATH
            })
          }}
          >
            Learn More
            <img src={Arrow} alt='See Options' />
          </button>
        </div>
      </CourseDetails>
      <ButtonsWrapper justifyContent='center'>
        <PrimaryButton marginright='16px' onClick={gotoNextCourse}>
          Continue
        </PrimaryButton>
        <SecondaryButton onClick={changeCourse}>
          Change Course
        </SecondaryButton>
      </ButtonsWrapper>
      {showTrailerModal && (
        <TrailerModal
          kalturaEmbedCode={trailerVideo?.kalturaEmbedCode}
          setShowTrailerModal={setShowTrailerModal}
        />
      )}
    </Page>
  )
}

CourseIntro.displayName = 'CourseIntro'

export default CourseIntro
