import React from 'react'
import {
  CourseCTAContainer,
  CourseDescription,
  PrimaryButton,
  SecondaryButton
} from '../style'

function WhatsNextWidget ({
  description,
  primaryButtonText,
  primaryButtonFunction,
  secondaryButtonText,
  secondaryButtonFunction,
  desktopHero,
  caughtUpText
}) {
  return (
    <>
      {desktopHero && description && (
        <CourseDescription>
          {caughtUpText && (
            <span>
              <img src='/images/icons/fireworks.svg' alt='fireworks-icon' />
              {caughtUpText}
            </span>
          )}
          {description}
        </CourseDescription>
      )}
      {(primaryButtonText || secondaryButtonText) && (
        <CourseCTAContainer>
          {primaryButtonText && (
            <PrimaryButton
              width={desktopHero && '280px'}
              onClick={primaryButtonFunction}
              data-testid='primary-button'
            >
              {primaryButtonText}
            </PrimaryButton>
          )}
          {secondaryButtonText && (
            <SecondaryButton
              width={desktopHero && '280px'}
              onClick={secondaryButtonFunction}
              data-testid='secondary-button'
            >
              {secondaryButtonText}
            </SecondaryButton>
          )}
        </CourseCTAContainer>
      )}
    </>
  )
}

WhatsNextWidget.displayName = 'WhatsNextWidget'
export default WhatsNextWidget
