import React from 'react'
import config from '../../config'
import WidgetState from './WidgetState'

export const StudioCard = ({ course }) => {
  const { id } = course
  const courseUrl = config.courseBaseUrlById(id)

  return (
    <WidgetState
      data-test='studio-card-id'
      description='Check with your instructor for your coursework for this course.'
      primaryBtnTxt='View course'
      primaryBtnFunction={() => { window.location.href = courseUrl }}
    />
  )
}

export default StudioCard
