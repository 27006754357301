import React from 'react'
import {
  ActiveIcon,
  CheckIcon,
  ProgressEmptyIcon,
  WarningIcon
} from '../style'
import {
  getHoursFromDate,
  formatHoursToTime
} from '../../../utilities/dateTime'
import { WEEK_DAYS } from '../../../data/constants'
import { getLatestSession, getStreaks } from '../../../utilities/studyBlocks'

export function getDayDatesInfo (date) {
  const todayDate = new Date()
  const isToday = date === todayDate.toDateString()
  const isYesterday = date ===
    new Date(new Date().setDate(todayDate.getDate() - 1)).toDateString()
  const isTomorrow = date ===
    new Date(new Date().setDate(todayDate.getDate() + 1)).toDateString()
  const isOldDate = date <=
    new Date(new Date().setDate(todayDate.getDate() - 2)).toDateString()
  const dayLong = WEEK_DAYS[new Date(date)?.getDay()]

  return { isToday, isYesterday, isTomorrow, isOldDate, dayLong }
}

export function getBlockTime (block = {}) {
  const blockKeys = Object.keys(block)
  if (!blockKeys?.includes('start') || !blockKeys?.includes('end')) return ''

  const startTime = formatHoursToTime(block?.start).toLocaleLowerCase()
  const endTime = formatHoursToTime(block?.end).toLocaleLowerCase()

  const startTimeSuffix = startTime.split(' ')[1]
  const endTimeSuffix = endTime.split(' ')[1]

  if (startTimeSuffix === endTimeSuffix) {
    return `${startTime?.split(' ')[0]} - ${endTime}`
  }

  return `${startTime} - ${endTime}`
}

export function getBlockIcon (date = '', block = {}) {
  const {
    isToday
  } = getDayDatesInfo(date)

  const currentHours = getHoursFromDate(new Date())
  const isActive = isToday && block?.start <= currentHours && block?.end >= currentHours

  if (isActive) return <ActiveIcon />
  if (block?.isAttended) return <CheckIcon />
  if (block?.isAttended === false) return <WarningIcon />
  return <ProgressEmptyIcon />
}

export function getDaysAndBlocks (sessionsArray = []) {
  if (!sessionsArray?.length) return []
  const daysAndBlocks = []
  const lastSessionsIDs = sessionsArray.slice(-4).map(session => session?.id)

  sessionsArray.forEach((session = {}) => {
    const start = new Date(session.start)
    const end = new Date(session.end)
    const sessionDate = start.toDateString()

    const block = {
      ...session,
      start: getHoursFromDate(start),
      end: getHoursFromDate(end),
      isLastBlocks: lastSessionsIDs.includes(session.id)
    }

    const dayIndex = daysAndBlocks.findIndex(day => day.date === sessionDate)

    if (dayIndex === -1) {
      daysAndBlocks.push({
        date: sessionDate,
        blocks: [block],
        isLastDays: lastSessionsIDs.includes(session.id)
      })
    } else {
      daysAndBlocks[dayIndex].blocks.push(block)
      daysAndBlocks[dayIndex].isLastDays = lastSessionsIDs.includes(session.id)
    }
  })

  return daysAndBlocks
}

export function getStudyBlocksMessage (sessionsArray = [], daysAndBlocks = []) {
  if (!sessionsArray?.length) return ''

  const currentDate = new Date()
  const latestSession = getLatestSession(sessionsArray, currentDate)
  const isActiveSession = latestSession?.start <= currentDate.getTime() &&
    latestSession?.end >= currentDate.getTime()
  const todayBlocks = daysAndBlocks.find(day => day.date === currentDate.toDateString())?.blocks
  const isTodayBlocksCompleted = todayBlocks?.every(block => block.isAttended)
  const { currentStreak, longestStreak } = getStreaks(sessionsArray, latestSession)

  if (latestSession?.isAttended === false) {
    return 'You missed your most recent study block. Study during each block so your streak won’t reset!'
  }
  if (isActiveSession && currentStreak - 1) { // -1 because the current session is counted
    return `You’re on your ${currentStreak - 1} study blocks streak, Let’s continue your streak!`
  }
  if (isActiveSession && !(currentStreak - 1)) {
    return 'Let’s get started on your study block streak!'
  }
  if (!isActiveSession && isTodayBlocksCompleted) {
    return 'You’ve completed all of today’s study blocks! Keep it up!'
  }
  if (currentStreak < longestStreak || currentStreak <= 2) {
    return `You’ve made ${currentStreak} study block${currentStreak > 1 ? 's in a row' : ''}. Keep it up!`
  }
  if (currentStreak === longestStreak) {
    return `You’ve made ${currentStreak} study blocks in a row, making it your longest streak. Great work!`
  }

  return 'Let’s get started on your study block streak!'
}
