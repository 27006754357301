import React, { useContext, useEffect, useState, useMemo } from 'react'
import { Separator } from '../OnboardingForm/style'
import {
  BackToLink,
  Header
} from '../Tokens/style'
import {
  Container,
  AddTokensContainer,
  Button,
  TokensCounter
} from './style'
import { Auth0Context } from '../Auth0Provider/Auth0Provider'
import { AppContext } from '../ContextProvider/ContextProvider'
import { getCheckoutUrl, getActiveTokens } from '../../utilities/tokenUtils'
import api from '../../api'
import config from '../../config'
import { ACCOUNT_PATH, CONTENT } from '../../Constants/accountArea'
import { BackButtonIcon } from '../Links/style'

const SetTokensCount = ({ history, location }) => {
  const { user: { email } = {} } = useContext(Auth0Context) || {}
  const { studentData: { firstName = '', lastName = '' } = {}, tokens: userTokens } = useContext(AppContext) || {}
  const tokensAvailable = getActiveTokens(userTokens)
  const cartItems = useMemo(
    () => JSON.parse(localStorage.getItem('userCart')) || [],
    // eslint-disable-next-line
    []
  )

  const { state: { keptTokens = 0 } = {} } = location
  const requiredTokens = tokensAvailable?.length - cartItems?.length - keptTokens
  const tokenCount = requiredTokens <= 0 ? Math.abs(requiredTokens) : 1

  const {
    state: {
      availableTokens = tokensAvailable?.length || 0,
      coursesCount = cartItems?.length || 0,
      tokensCount = tokenCount || 1,
      backToPageName = 'tokens',
      redirectToPath = `/${CONTENT.ACCOUNT}/${ACCOUNT_PATH.TOKENS_PATH}`,
      onBackToClick = () => history.push(redirectToPath)
    } = {}
  } = location

  const [tokensToBuy, setTokensToBuy] = useState(tokensCount)
  const [tokenDetails, setTokenDetails] = useState({})

  useEffect(() => {
    const getTokenDetails = async () => {
      const { token: tokenCourseId } = config.getCourseIds()
      const variants = await api.getShopifyVariants(tokenCourseId)

      if (!variants?.length) return

      setTokenDetails(variants[0])
    }

    getTokenDetails()

    // eslint-disable-next-line
  }, [])

  const checkout = () => {
    const { variantId } = tokenDetails
    const isDirectedFromScheduleReview = redirectToPath === '/review-schedule'
    const path = isDirectedFromScheduleReview
      ? redirectToPath + '?assignTokens=true'
      : redirectToPath
    const tokensCheckoutUrl = getCheckoutUrl(
      {
        email,
        firstName,
        lastName,
        tokensCount: tokensToBuy,
        variantId,
        redirectToPath: `${window.location.origin}/#${path}`
      }
    )

    if (!tokensCheckoutUrl) return console.error('Invalid checkout URL')

    window.open(tokensCheckoutUrl, '_self')
  }

  const addToken = () => {
    setTokensToBuy(tokensToBuy => tokensToBuy + 1)
  }

  const removeToken = () => {
    if (tokensToBuy === 1) return

    setTokensToBuy(tokensToBuy => tokensToBuy - 1)
  }

  const { price: shopifyPrice } = tokenDetails
  const priceInNumber = Number(shopifyPrice)
  const tokenPrice = isNaN(priceInNumber) ? 0 : priceInNumber

  return (
    <Container>
      <BackToLink data-testid='btn-back' onClick={onBackToClick}>
        <BackButtonIcon />
        <span>{backToPageName}</span>
      </BackToLink>
      <div className='addTokens'>
        <AddTokensContainer>
          <div className='header'>
            <Header>Add Tokens</Header>
            <p>Tokens: {availableTokens}</p>
          </div>
          <Separator margin='32px 0 34px 0' />
          <p className='content'>
          Choose the number of tokens you'd like to purchase.
          Discounts may be applied at checkout.
          </p>
          <div className='counts'>
            {!!coursesCount && (
              <>
                <p>Courses selected: {coursesCount}</p>
                <p className='dot'>.</p>
              </>
            )}
            <p>Tokens needed: {tokensCount}</p>
          </div>
          <TokensCounter>
            <div className='value'>
              <p>{tokensToBuy}</p>
              <div>
                <p>TOKEN{tokensToBuy > 1 ? 'S' : ''}</p>
                <p>COST: ${tokensToBuy * tokenPrice}</p>
              </div>
            </div>
            <div className='adjust-value'>
              <div data-testid='btn-plus' onClick={addToken}>
                <p className='plus'>+</p>
              </div>
              <div data-testid='btn-minus' onClick={removeToken}>
                <p className='minus' />
              </div>
            </div>
          </TokensCounter>
          <Button
            data-testid='btn-checkout'
            onClick={checkout}
          >
          checkout
          </Button>
        </AddTokensContainer>
      </div>
    </Container>
  )
}

SetTokensCount.displayName = 'SetTokensCount'

export default SetTokensCount
