import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const PageContent = styled.div`
  margin-top: 32px;
  padding: 0px 64px;
  width: 100%;

  @media (max-width: 576px) {
    padding: 0px 24px;
  }
`

export const Box = styled.div`
  margin: 24px 0;
  margin-bottom: ${({ isEdit }) => isEdit ? 24 : 0};
  ${mediaqueries.smallMobile`
    display: block;
  `}
`

export const BoxWrap = styled.div`
  &.flex-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding:0px;
    ${mediaqueries.mobile`
      display: block;
    `}
  }
`

export const EnrolText = styled.p`
  font-weight: normal;
  font-size: 16px;
  color: #fff;
  margin-bottom: 8px;
  text-transform: capitalize;
`

export const EnrollSpan = styled.span``

export const Button = styled.button`
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5fc4b8;
  background-color: unset;
  border: none;
  outline: none !important;
  padding: 0px;
  margin-right: 12px;
  line-height: 17px;
  ${mediaqueries.mobile`
    margin: unset;
    margin-right: 12px;
  `}
`
export const CartButton = styled.button`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  background-color: #5fc4b8;
  width: 133px;
  height: 48px;
  outline: none !important;
  margin-top: 48px;

  :disabled {
    color: black;
    opacity: 0.3;
  }

  @media (max-width: 576px) {
    margin-top: 32px;
    width: 100%;
  }
`

export const AlertModalParagraph = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 24px;
  margin-bottom: 0;
  color: #ffffff;
`

export const AlertModalFooterWrapper = styled.div`
  display: flex;
  padding: 0px 24px;
  padding-bottom: 24px;
  button {
    width: 113px;
    margin-right: 16px;
    height: 49px;
  }
`

export const ConfirmationWrap = styled.div`
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  canvas {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
  }
  button {
    width: 170px;
    height: 40px;
  }
`

export const ConfirmationText = styled.h4`
  font-weight: 300;
  font-size: 36px;
  line-height: 43px;
  color: #ffffff;
  margin-bottom: 32px;
`

export const ScheduleText = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 32px;
`

export const CardWrap = styled.div`
  position: relative;
`
