import React, { useState } from 'react'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import {
  Container,
  Header,
  Content
} from './styles'
import { PrimaryButton, SecondaryButton } from '../Buttons/style'
import api from '../../api'
import {
  PERMISSION_REQUIRED,
  PERMISSION_AWAITED,
  PERMISSION_GRANTED
} from './permissionStates'
import { isLatestTermsAccepted } from '../../utilities/user'

const PermissionRequested = ({
  guardianEmail,
  setEmail,
  setPermissionState
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const checkStatus = async () => {
    setIsLoading(true)
    const studentData = await api.getStudentData()
    setIsLoading(false)
    const { guardianTOSDate } = studentData
    const termsAccepted = isLatestTermsAccepted(guardianTOSDate)
    if (termsAccepted) return setPermissionState(PERMISSION_GRANTED)

    setPermissionState(PERMISSION_AWAITED)
  }

  const resendEmail = () => {
    setEmail('')
    setPermissionState(PERMISSION_REQUIRED)
  }

  if (isLoading) return <LoadingSpinner />

  return (
    <Container>
      <div className='content'>
        <Header>We’ve reached out</Header>
        <Content>
          We have sent an email to <span>{guardianEmail}</span> with instructions
          on how to agree to our Terms of Service and Privacy Policy.
        </Content>
        <Content marginBottom='24px'>
          Please give them a heads up to ensure it’s not lost in the shuffle of
          their inbox. Check to see if they’ve given their permission below
          or resend the email.
        </Content>
        <div className='buttons-container'>
          <PrimaryButton
            data-testid='btn-check-status'
            onClick={checkStatus}
          >
            check status
          </PrimaryButton>
          <SecondaryButton
            data-testid='btn-resend-email'
            onClick={resendEmail}
          >
            resend
          </SecondaryButton>
        </div>
      </div>
    </Container>
  )
}

PermissionRequested.displayName = 'PermissionRequested'
export default PermissionRequested
