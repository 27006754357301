import React from 'react'
import isEmpty from 'lodash/isEmpty'
import GuessworkCard from './GuessworkCard'
import LectureCard from './LectureCard'
import ActiveLearningCard from './ActiveLearningCard'
import PracticeExercisesCard from './PracticeExercisesCard'
import QuizCard from './QuizCard'

import {
  chaptersVisitedSections, getExamReviewChapter
} from '../../utilities/chapterUtils'

import {
  getVisitedSectionInfo,
  getVisitedALCardInfo,
  getVisitedQuizInfo,
  getMultipleVideos,
  getLectureInProgress,
  getVisitedPracticeExerciseInfo

} from '../../utilities/section'
import config from '../../config'

const ActivityInProgressAndCompletedCard = ({
  course,
  courseProgress,
  note,
  exam,
  examOpenData,
  handleStartButtonClick,
  noAssessments
}) => {
  const { id, chapters } = course
  const { studentProgress, sectionData } = courseProgress
  const { studentAnswers } = studentProgress

  // get course base url
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)
  const examReviewChapter = getExamReviewChapter(exam, course.chapters)
  const reviewFirstSectionUUID =
    examReviewChapter?.sections?.[0]?.['section_uuid']
  const reviewChapterUrl = `${courseBaseUrl}/#/${reviewFirstSectionUUID}`

  const getVisitedQuizData = (quiz) => {
    const { visitedQuizzes } =
      getVisitedQuizInfo(Object.keys(studentAnswers), quiz)
    for (let i = visitedQuizzes.length - 1; i >= 0; i--) {
      const { questionId } = visitedQuizzes[i]
      if (!questionId) continue
      return {
        quizNo: i + 1,
        visitedQuiz: visitedQuizzes[i]
      }
    }
    return null
  }

  const chapterVisitedSections =
    chaptersVisitedSections(chapters, studentProgress)
  for (let i = chapterVisitedSections.length - 1; i >= 0; i--) {
    const {
      visitedSections,
      chapterUuid,
      chapterNo
    } = chapterVisitedSections[i]

    for (let j = visitedSections.length - 1; j >= 0; j--) {
      const {
        sectionUuid,
        isGuessworkVisited,
        isActiveLearningVisited,
        isQuizVisited,
        isLectureVisited,
        sectionNo
      } = visitedSections[j]

      const { section_exe: sectionExe } = sectionData

      if (sectionData.section_uuid !== sectionUuid) continue

      const {
        guesswork,
        lecture,
        multi_lecture_videos: multipleVideos,
        active_learning: activeLearning,
        practice_exercises: practiceExercises,
        quiz
      } = sectionExe

      // if last activity is quiz
      if (isQuizVisited && !noAssessments) {
        const visitedQuizData = getVisitedQuizData(quiz)
        if (visitedQuizData) {
          return (
            <QuizCard
              course={course}
              note={note}
              exam={exam}
              examOpenData={examOpenData}
              noAssessments={noAssessments}
              handleStartButtonClick={handleStartButtonClick}
              courseProgress={courseProgress}
              visitedQuizData={visitedQuizData}
              chapterUuid={chapterUuid}
              chapterNo={chapterNo}
              sectionNo={sectionNo}
              reviewChapterTitle={examReviewChapter?.title}
              reviewChapterUrl={reviewChapterUrl}
              reviewFirstSectionUUID={reviewFirstSectionUUID}
            />
          )
        }
      }

      // if last activity is practice exercises
      if (!isEmpty(practiceExercises)) {
        const isFirstSection = chapterNo === 1 && sectionNo === 1
        const visitedPracticeExercises =
          getVisitedPracticeExerciseInfo(studentAnswers, practiceExercises, isFirstSection)
        if (visitedPracticeExercises) {
          return (
            <PracticeExercisesCard
              course={course}
              note={note}
              exam={exam}
              handleStartButtonClick={handleStartButtonClick}
              courseProgress={courseProgress}
              chapterUuid={chapterUuid}
              chapterNo={chapterNo}
              sectionNo={sectionNo}
              noAssessments={noAssessments}
              reviewChapterTitle={examReviewChapter?.title}
              reviewChapterUrl={reviewChapterUrl}
              reviewFirstSectionUUID={reviewFirstSectionUUID}
            />
          )
        }
      }

      // if last activity is active learning
      if (isActiveLearningVisited) {
        const { questionId } =
          getVisitedALCardInfo(studentAnswers, activeLearning)
        if (questionId) {
          return (
            <ActiveLearningCard
              course={course}
              note={note}
              exam={exam}
              handleStartButtonClick={handleStartButtonClick}
              courseProgress={courseProgress}
              chapterNo={chapterNo}
              sectionNo={sectionNo}
              reviewChapterTitle={examReviewChapter?.title}
              reviewChapterUrl={reviewChapterUrl}
              reviewFirstSectionUUID={reviewFirstSectionUUID}
            />
          )
        }
      }

      const sectionVideos = getMultipleVideos(multipleVideos)

      const lectureInProgress = getLectureInProgress({
        sectionUuid,
        videos: sectionVideos,
        lecture,
        progress: studentProgress
      })

      // if last activity is lecture
      if (lectureInProgress || isLectureVisited) {
        return (
          <LectureCard
            course={course}
            note={note}
            exam={exam}
            handleStartButtonClick={handleStartButtonClick}
            courseProgress={courseProgress}
            lectureInProgress={lectureInProgress}
            chapterNo={chapterNo}
            sectionNo={sectionNo}
            reviewChapterTitle={examReviewChapter?.title}
            reviewChapterUrl={reviewChapterUrl}
            reviewFirstSectionUUID={reviewFirstSectionUUID}
          />
        )
      }

      // if last activity is guesswork
      if (isGuessworkVisited) {
        const { questionId } =
          getVisitedSectionInfo(Object.keys(studentAnswers), guesswork)
        if (questionId) {
          return (
            <GuessworkCard
              course={course}
              note={note}
              exam={exam}
              handleStartButtonClick={handleStartButtonClick}
              courseProgress={courseProgress}
              chapterNo={chapterNo}
              sectionNo={sectionNo}
              reviewChapterTitle={examReviewChapter?.title}
              reviewChapterUrl={reviewChapterUrl}
              reviewFirstSectionUUID={reviewFirstSectionUUID}
            />
          )
        }
      }
    }
  }

  return null
}

export default ActivityInProgressAndCompletedCard
