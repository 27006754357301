import { useRef, useEffect } from 'react'

// This can only be called inside of the body of a function component
export function usePreviousState (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
