import React, { useEffect, useState, useMemo, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import ShoppingBagIcon from '../../assets/icons/shopping-bag.svg'
import { TokenWrapper } from './style'
import PropTypes from 'prop-types'
import Sidebar from '../CartSideBarPanel/CartSideBarPanel'
import { AppContext } from '../ContextProvider/ContextProvider'
import AddTokensButton from '../AddTokens/AddTokensButton'

const TokensAndCart = ({
  tokens = 0,
  hideBag,
  backToPageName,
  isParentRerendered,
  showAvailableTokens,
  rerenderParent
}) => {
  const [deleted, setDeleted] = useState(false)
  const Local = useLocation()
  const {
    studentData = {}, updateContext
  } = useContext(AppContext)
  const { instrideId } = studentData || {}

  useEffect(() => {
    if (deleted) {
      setDeleted(false)
    }
    // eslint-disable-next-line
  }, [deleted]);

  const cartItems = useMemo(
    () => JSON.parse(localStorage.getItem('userCart')) || [],
    // eslint-disable-next-line
    [deleted, isParentRerendered]
  )

  const [toggleSidebar, setToggleSidebar] = useState(Local.state?.isSidebarOpen || false)

  const toggleSideBar = () => setToggleSidebar(!toggleSidebar)

  const onRemove = (index) => {
    cartItems.splice(index, 1)
    localStorage.setItem('userCart', JSON.stringify(cartItems))
    setDeleted(true)
    updateContext({ localCourses: cartItems })
  }

  return (
    <TokenWrapper hideBag data-testid='tokens'>
      {!instrideId && (
        <>
          Tokens: {tokens}
          <AddTokensButton
            backToPageName={backToPageName}
            redirectToPath={`${Local.pathname}`}
          />
        </>
      )}

      {showAvailableTokens && (
        <span>Available tokens: {tokens}</span>
      )}

      {hideBag ? null : (
        <div className='bag-wrap' onClick={toggleSideBar}>
          <img src={ShoppingBagIcon} alt='shopping-bag-icon' />
          <span data-testid='shopping-bag'>{cartItems.length}</span>
        </div>
      )}
      {toggleSidebar ? (
        <Sidebar
          handleClose={toggleSideBar}
          courses={cartItems}
          onRemove={(id) => onRemove(id)}
          onUpdate={() => {
            setDeleted(true)
            if (rerenderParent) rerenderParent()
          }}
          isInstride={!!instrideId}
          updateContext={updateContext}
        />
      ) : null}
    </TokenWrapper>
  )
}

TokensAndCart.propTypes = {
  tokens: PropTypes.number,
  showAvailableTokens: PropTypes.bool,
  items: PropTypes.number
}
TokensAndCart.displayName = 'TokensAndCart'

export default TokensAndCart
