import React from 'react'
import PropTypes from 'prop-types'
import upperFirst from 'lodash/upperFirst'
import DesmosGraph from './lib/DesmosGraph'
import {
  FeedbackContainer,
  Separator,
  QuestionContainer,
  QuestionTextContainer
} from './style'
import Options from './Options'
import MathJax from './lib/MathJax'
import { imageClassMap, progressButtonStates } from '../../../Constants/pretest'

const { CORRECT_OPTION } = progressButtonStates

const MultipleChoice = ({ question, studentAnswer, questionStatus, setCurrentAnswer }) => {
  const {
    Question_uuid: questionUUID,
    only_allow_one: hasSingleAnswer,
    options,
    answer,
    lesson_text: lessonText,
    desmos_graph: demosGraph,
    question_text: questionText
  } = question

  return (
    <QuestionContainer>
      {lessonText && (
        <>
          <MathJax math={lessonText} />
          <Separator />
        </>
      )}
      <QuestionTextContainer expression={demosGraph}>
        {demosGraph && <DesmosGraph expression={demosGraph} />}
        {questionText && (
          <MathJax math={questionText} />
        )}
      </QuestionTextContainer>
      <Options
        questionUUID={questionUUID}
        hasSingleAnswer={hasSingleAnswer}
        options={options}
        correctAnswer={answer}
        studentAnswer={studentAnswer}
        answerFeedback={questionStatus}
        setCurrentAnswer={setCurrentAnswer}
      />
      {studentAnswer && (
        <FeedbackContainer data-testid={`feedback-${questionStatus}`}>
          <img src={imageClassMap[questionStatus]} alt={`Feedback: ${questionStatus}`} />
          <span>{upperFirst(questionStatus === CORRECT_OPTION ? 'correct' : 'incorrect')}</span>
        </FeedbackContainer>
      )}
    </QuestionContainer>
  )
}

MultipleChoice.displayName = 'MultipleChoice'

MultipleChoice.propTypes = {
  question: PropTypes.object.isRequired,
  setCurrentAnswer: PropTypes.func,
  questionStatus: PropTypes.string,
  studentAnswer: PropTypes.object
}

export default MultipleChoice
