import React, { useContext } from 'react'
import { AppContext } from '../../ContextProvider/ContextProvider'
import {
  completedFirstSection,
  completedOrientation,
  getFirstSectionUrl,
  getOrientationChapterSectionUrl
} from '../../../utilities/chapterUtils'
import WhatsNextWidget from './WhatsNextWidget'
import fireworksIcon from '../../../assets/icons/fireworks.svg'
import { LargeCelebratoryMessage } from '../style'

function PreCohort ({ course, desktopHero }) {
  const { id } = course
  const { coursesProgress } = useContext(AppContext)
  if (!coursesProgress) return null

  const { studentProgress } = coursesProgress.find(
    ({ courseId }) => courseId === id
  )

  const isOrientationCompleted = completedOrientation(course, studentProgress)
  const isFirstSectionCompleted = completedFirstSection(course, studentProgress)

  const orientationSectionUrl = getOrientationChapterSectionUrl(course)
  const sectionUrl = getFirstSectionUrl(course)
  return (
    <div data-testid='upcoming-card'>
      {!isFirstSectionCompleted && (
        <WhatsNextWidget
          description={`We’ve made Section 1.1 available to you early if you want to get ahead 
          ${orientationSectionUrl &&
            !isOrientationCompleted &&
            'or start with Orientation to learn how to succeed in this course'}.`}
          primaryButtonText='Visit Home'
          primaryButtonFunction={() => (window.location.href = sectionUrl)}
          desktopHero={desktopHero}
        />
      )}
      {desktopHero && isFirstSectionCompleted && (
        <LargeCelebratoryMessage>
          <img src={fireworksIcon} alt='fireworks-icon' />
          <div>
            <strong>Nice job getting ahead!</strong>
            <p>
              The next set of activities will unlock when the cohort begins.
            </p>
          </div>
        </LargeCelebratoryMessage>
      )}
    </div>
  )
}

PreCohort.displayName = 'PreCohort'
export default PreCohort
