import React, { useState, useMemo, useEffect, useRef, useContext } from 'react'
import { AppContext } from '../ContextProvider/ContextProvider'
import { uniq } from 'lodash'
import qs from 'qs'
import BackBreadcrumb from '../BackBreadcrumb/BackBreadcrumb'
import Announcement from './Announcement'
import { announcements as mockAnnouncements } from './mockData'
import {
  ContentWrapper,
  Header,
  Title,
  CheckAllIcon,
  MarkAllAsRead,
  AnnouncementsContainer,
  OverrideGlobalStyle
} from './styles'

const AnnouncementsPage = ({ location }) => {
  const [seenAnnouncements, setSeenAnnouncements] = useState([])
  const headerRef = useRef(null)
  const {
    seenAnnouncementsFromContext,
    updateContext
  } = useContext(AppContext)

  const selectedAnnouncementId = qs.parse(
    location.search,
    { ignoreQueryPrefix: true }
  ).id

  const announcements = useMemo(() => {
    return mockAnnouncements.map(announcement => {
      if (!seenAnnouncements.includes(announcement.id)) return announcement
      return { ...announcement, seen: true }
    })
  }, [seenAnnouncements])

  const shouldMarkAllDisable = announcements.every(
    announcement => announcement.seen
  )

  useEffect(() => {
    setSeenAnnouncements(preVal => (
      uniq([
        ...(seenAnnouncementsFromContext || []),
        ...preVal
      ]))
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    updateContext({
      seenAnnouncementsFromContext: uniq(
        [
          ...(seenAnnouncementsFromContext || []),
          ...seenAnnouncements]
      )
    })
    // eslint-disable-next-line
  }, [seenAnnouncements])

  useEffect(() => {
    handleLocationChange()
    // eslint-disable-next-line
  }, [selectedAnnouncementId])

  const handleLocationChange = () => {
    if (!selectedAnnouncementId) return
    setSeenAnnouncements(preVal => uniq([...preVal, selectedAnnouncementId * 1]))
    const announcement = document.getElementById(`announcement-${selectedAnnouncementId}`)

    if (!announcement) return
    window.scrollTo({
      top: announcement.offsetTop - 170 - headerRef.current.offsetHeight,
      behavior: 'smooth'
    })
  }

  const onMarkAllAsRead = () => {
    if (shouldMarkAllDisable) return
    setSeenAnnouncements(mockAnnouncements.map(
      announcement => announcement.id
    ))
  }

  return (
    <>
      <OverrideGlobalStyle />
      <BackBreadcrumb
        title='Back to [Activity/Page]'
        className='back-breadcrumb'
        path='/'
      />
      <ContentWrapper>
        <Header ref={headerRef}>
          <Title>Announcements</Title>
          <MarkAllAsRead
            onClick={onMarkAllAsRead}
            disabled={shouldMarkAllDisable}
          >
            <CheckAllIcon />
            <span>Mark all as read</span>
          </MarkAllAsRead>
        </Header>
        <AnnouncementsContainer>
          {announcements.map((announcement, index) => (
            <Announcement
              key={index}
              announcement={announcement}
              setSeenAnnouncements={setSeenAnnouncements}
              selectedAnnouncementId={selectedAnnouncementId}
            />
          ))}
        </AnnouncementsContainer>
      </ContentWrapper>
    </>
  )
}

AnnouncementsPage.displayName = 'Announcements'
export default AnnouncementsPage
