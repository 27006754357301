import React, { useEffect } from 'react'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import { COURSE_REGISTRATION_PAGEVIEW, COURSE_REGISTRATION_PAGE_SUBMITTED } from '../../Constants/eventType'
import { GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import { CreditWrapper } from '../GGUEnrollmentFlow/styles'
import { Page, PrimaryButton, Subtext, Title } from './style'
import { isSegmentEventInLS, removeSegmentEventFromLS, setFiredSegmentEventsInLS } from '../../utilities/gguDegreeUtils'

const TransferCreditConfirmation = ({
  currentProspect, goBackClicked, setCurrentPage
}) => {
  const { sendEvent } = useSegmentEvents()
  const { transferredCredit, generalElectiveCredits } = currentProspect || {}

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: '2-1'
  }

  useEffect(() => {
    if (!transferredCredit?.length && !generalElectiveCredits) return
    if (isSegmentEventInLS(segmentEvent)) return

    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: '1-2'
    })
    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'Transfer Credits',
        page_number: '2-1',
        transfer_credits_available: transferredCredit?.map((course, index) => {
          return {
            [`Course ${index + 1}`]: {
              'Course Name': course?.displayName,
              'Course Credit': 4
            }
          }
        })
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (transferredCredit?.length || generalElectiveCredits) return

    if (goBackClicked) {
      setCurrentPage({
        ...GGU_V2_ENROLLMENT_PAGES.EXPECTED_GRADUATION, goBackClicked: true
      })
      return
    }

    setCurrentPage(GGU_V2_ENROLLMENT_PAGES.GET_READY)

    // eslint-disable-next-line
  }, [transferredCredit, generalElectiveCredits])

  return (
    <Page>
      <Title>
        Congrats! You're ahead of the game!
      </Title>
      <Subtext>
        You've earned the following transfer credits.
      </Subtext>
      <CreditWrapper data-testid='creditWrapper' marginTop='48px'>
        {transferredCredit?.map(({ displayName }) => (
          <div className='element' key={displayName}>
            <p className='course-name'>{displayName}</p>
            <p className='credits'>4 credits</p>
          </div>
        ))}
        {generalElectiveCredits && (
          <div className='element' key='generalElectives' data-testid='generalElectives'>
            <p className='course-name'>General Electives</p>
            <p className='credits'>{generalElectiveCredits} credits</p>
          </div>
        )}
      </CreditWrapper>
      <PrimaryButton
        onClick={() => {
          sendEvent({
            eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
            properties: {
              page_name: 'Transfer Credits',
              page_number: '2-1'
            }
          })
          setCurrentPage(GGU_V2_ENROLLMENT_PAGES.GET_READY)
        }}
      >
        Continue
      </PrimaryButton>
    </Page>
  )
}

TransferCreditConfirmation.displayName = 'TransferCreditConfirmation'

export default TransferCreditConfirmation
