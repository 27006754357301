import React, { useContext } from 'react'
import StudyBlockStarting from './StudyBlockStarting'
import EditStudyBlocksToast from './EditStudyBlocksToast'
import AdjustStudyBlocksToast from './AdjustStudyBlocksToast'
import { AppContext } from '../../../ContextProvider/ContextProvider'

function StudyBlocksNotifications () {
  const { courses = [], studyBlocksData = {} } = useContext(AppContext)

  return (
    <>
      <StudyBlockStarting studyBlocksData={studyBlocksData} />
      <EditStudyBlocksToast studyBlocksData={studyBlocksData} />
      <AdjustStudyBlocksToast
        courses={courses}
        studyBlocksData={studyBlocksData}
      />
    </>
  )
}

StudyBlocksNotifications.displayName = 'StudyBlocksNotifications'
export default StudyBlocksNotifications
