import React, { useEffect } from 'react'
import api from '../../api'
import PropTypes from 'prop-types'
import { marketingUrls } from './data'
import { COURSE_PAGE, MAIN_PAGE, segmentEventUrl } from './constants'
import BackwardIcon from '../../assets/icons/back-arrow-caret.svg'
import {
  getCertificateIcon,
  getCourseIcon,
  getStatusColor,
  getCourseDateStatement,
  getCourseTypeStatement,
  getCertificateStatus,
  getNoOfStatusCourses,
  getNoOfCourses
} from './utils'
import {
  BackwardLink, MainTitle, PageContentWrapper, CertificateCourseWrapper, EnrollButton, ViewButton
} from './style'
import { COMPLETED, ENROLLED, SIGNED_UP } from '../../Constants/studentStatus'
import {
  CERTIFICATE_PAGE_VIEWED
} from '../../Constants/eventType'
import config from '../../config'

const CertificateCourse = ({
  course, setCurrentPage, setCurrentCourse
}) => {
  const {
    id: courseId, name: courseName, cohort, cohort: { studentStatus } = {},
    courseType, courseCategory, noOfCourses
  } = course || {}

  const isElective = courseType === 'elective'
  const isEnrolledCourse = [ENROLLED, SIGNED_UP].includes(studentStatus)
  const enrolledOrCompletedCourse = [COMPLETED, ENROLLED, SIGNED_UP].includes(studentStatus)

  const courseColor = getStatusColor(studentStatus)
  const dateStatementColor = isEnrolledCourse ? '#fff' : courseColor

  const categoryName = `${courseCategory} Elective`
  const courseNameDisplayed = (!isElective || isEnrolledCourse) ? courseName : categoryName
  const courseTypeStatement = getCourseTypeStatement({
    courseType, studentStatus, courseCategory, noOfCourses
  })
  const courseDateStatement = getCourseDateStatement(cohort, studentStatus)

  const onCourseClick = () => {
    if (!enrolledOrCompletedCourse) return
    setCurrentCourse(course)
    setCurrentPage(COURSE_PAGE)
  }

  const onEnrollClick = () => {
    const catalogUrl = `${config.courseBaseUrl}#/catalog/${courseId}`
    window.open(catalogUrl, '_blank')
  }

  return (
    <CertificateCourseWrapper
      color={courseColor}
      onClick={onCourseClick}
      pointer={enrolledOrCompletedCourse}
    >
      <div id='icon-wrapper'>
        {getCourseIcon(courseId, studentStatus)}
      </div>

      <div id='info-wrapper'>
        <div id='name-wrapper'>
          <p id='course-name'>{courseNameDisplayed}</p>
          <p id='course-type'>{courseTypeStatement}</p>
        </div>

        {enrolledOrCompletedCourse
          ? (
            <p id='date-text' style={{ color: dateStatementColor }}>
              {courseDateStatement}
            </p>
          ) : (
            <EnrollButton onClick={onEnrollClick}>
              enroll
            </EnrollButton>
          )}
      </div>
    </CertificateCourseWrapper>
  )
}

function CertificatePage ({ setCurrentPage, setCurrentCourse, currentCertificate }) {
  const {
    certificateId, certificateName, courses
  } = currentCertificate || {}

  useEffect(() => {
    api.newSubmitTrackedEvent({
      event: CERTIFICATE_PAGE_VIEWED,
      props: {
        title: certificateName
      }
    }, segmentEventUrl)
  }, [certificateName])

  const electiveCourse = []
  const iconStyle = { width: '120px', height: '132px' }
  const noOfCourses = getNoOfCourses(courses)
  const noOfCompletedCourses = getNoOfStatusCourses(courses, COMPLETED)

  const marketingUrl = marketingUrls[certificateId]
  const certificateStatus = getCertificateStatus(currentCertificate)

  const showMarketingButton = true// certificateStatus === COMPLETED
  const mainTitleMargin = showMarketingButton ? '16px 0 24px' : '16px 0 48px'

  const onViewClick = () => {
    window.open(marketingUrl, '_blank')
  }

  return (
    <>
      <BackwardLink onClick={() => setCurrentPage(MAIN_PAGE)}>
        <img src={BackwardIcon} alt='backward-button' />
        <p>ACADEMIC ACHIEVEMENTS</p>
      </BackwardLink>

      <PageContentWrapper>
        {getCertificateIcon({
          certificateStatus, noOfCourses, noOfCompletedCourses, style: iconStyle
        })}

        <MainTitle style={{ margin: mainTitleMargin }}>
          {certificateName}
        </MainTitle>

        {showMarketingButton && (
          <ViewButton onClick={onViewClick}>
            view certificate
          </ViewButton>
        )}

        {courses?.map(course => {
          const { cohort, courseType, courseCategory } = course
          const isElective = courseType === 'elective'
          const isEnrolled = [ENROLLED, SIGNED_UP].includes(cohort?.studentStatus)
          if (electiveCourse.includes(courseCategory) && !isEnrolled) return
          isElective && electiveCourse.push(courseCategory)
          return (
            <CertificateCourse
              key={course.id}
              course={course}
              certificate={currentCertificate}
              setCurrentPage={setCurrentPage}
              setCurrentCourse={setCurrentCourse}
            />
          )
        }
        )}
      </PageContentWrapper>
    </>
  )
}

CertificatePage.displayName = 'CertificatePage'
CertificatePage.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  setCurrentCourse: PropTypes.func.isRequired,
  currentCertificate: PropTypes.object.isRequired
}

export default CertificatePage
