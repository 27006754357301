import {
  differenceInDays,
  differenceInMilliseconds,
  format,
  formatDistanceStrict
} from 'date-fns'
import {
  AUDIT,
  AUDIT_TRANSCRIPT,
  COMPLETED,
  ENROLLED,
  ENROLLED_STATUSES,
  INCOMPLETE,
  PRE_DROP,
  SIGNED_UP,
  WITHDRAW
} from '../../Constants/studentStatus'
import config from '../../config'
import { isAuditCohort } from '../../utilities/cohort'
import {
  calculateCohortStartAndEndDates,
  getCohortDatesInSeconds,
  getCourseTermState,
  getLatestCohort,
  shouldShowFinalGrade
} from '../../utilities/course'
import { getIsVIP } from '../../utilities/user'
import { courseUTMKeys } from '../../Constants/courseUTMKey'

export const getForCreditCourses = (studentCourses) => {
  if (!studentCourses?.length) {
    return []
  }

  return studentCourses.filter((course) => {
    const { id } = course
    if (config.isCollegeSuccessCourse(id)) {
      return false
    }

    const isVIP = getIsVIP(course)
    if (isVIP) {
      return true
    }

    const latestCohort = getLatestCohort(course)
    const { studentStatus } = latestCohort || {}

    return ENROLLED_STATUSES.includes(studentStatus)
  })
}

export const getActiveCourses = (studentCourses) => {
  if (!studentCourses?.length) {
    return []
  }

  if (studentCourses?.length === 1) {
    return studentCourses
  }

  if (studentCourses.some((course) => getIsVIP(course))) {
    return studentCourses
  }

  const inProgressCreditCourses = getInProgressCreditCourses(studentCourses)
  const upcomingCreditCourses = getUpcomingCreditCourses(studentCourses)
  const auditCourses = getActiveAuditCourses(studentCourses)

  const freeCourses = studentCourses.filter((course) => {
    const { id } = course
    return config.isCollegeSuccessCourse(id)
  })

  return [
    ...inProgressCreditCourses,
    ...upcomingCreditCourses,
    ...auditCourses,
    ...freeCourses
  ]
}

export const getInProgressCreditCourses = (studentCourses) => {
  return studentCourses
    .filter((course) => {
      const latestCohort = getLatestCohort(course)
      const { studentStatus } = latestCohort || {}
      return [ENROLLED, PRE_DROP].includes(studentStatus)
    })
    .sort((courseA, courseB) => {
      const latestCohortA = getLatestCohort(courseA)
      const latestCohortB = getLatestCohort(courseB)
      if (latestCohortA.finalExamEndTime !== latestCohortB.finalExamEndTime) {
        return (
          new Date(latestCohortA.finalExamEndTime) -
          new Date(latestCohortB.finalExamEndTime)
        )
      } else {
        return (
          new Date(latestCohortA?.createdAt) -
          new Date(latestCohortB?.createdAt)
        )
      }
    })
}

const getUpcomingCreditCourses = (studentCourses) => {
  return studentCourses.filter((course) => {
    const latestCohort = getLatestCohort(course)
    const { studentStatus } = latestCohort || {}
    return studentStatus === SIGNED_UP
  })
}

const getActiveAuditCourses = (studentCourses) => {
  return studentCourses.filter((course) => {
    const latestCohort = getLatestCohort(course)
    const { studentStatus } = latestCohort || {}

    const couseTermState = getCourseTermState(course)

    return (
      [AUDIT, AUDIT_TRANSCRIPT].includes(studentStatus) &&
      couseTermState === 'inProgress'
    )
  })
}

export const getCourseTag = (course) => {
  const latestCohort = getLatestCohort(course)
  const { name: cohortName, studentStatus } = latestCohort || {}

  switch (true) {
    case studentStatus === WITHDRAW:
      return 'Withdrawn'
    case studentStatus === INCOMPLETE:
      return 'Incomplete'
    case config.isCollegeSuccessCourse(course.id):
      return 'Free'
    case isAuditCohort(cohortName):
      return 'Audit'
    case studentStatus === SIGNED_UP:
      return 'Enrolled'
    case [ENROLLED, PRE_DROP].includes(studentStatus):
      return 'For-Credit'
    default:
      return ''
  }
}

export const getCourseCountdown = (course) => {
  const courseTermState = getCourseTermState(course)
  const { dateStart, dateEnd } = calculateCohortStartAndEndDates(course)
  const today = new Date()

  if (courseTermState === 'isUpcoming') {
    const diffInDays = differenceInDays(dateStart, today)
    if (diffInDays <= 14) {
      return {
        countdownText: `Opens: in ${formatDistanceStrict(dateStart, today)}`
      }
    }

    return {
      countdownText: `Opens: ${format(dateStart, 'd MMM')}`
    }
  }

  if (courseTermState === 'inProgress') {
    const diffInDays = differenceInDays(dateEnd, today)

    if (diffInDays <= 14) {
      return {
        countdownText: `Closing: in ${formatDistanceStrict(dateEnd, today, {
          roundingMethod: 'floor'
        })}`,
        warningType: 'orange'
      }
    }

    return {
      countdownText: `Closing: ${format(dateEnd, 'd MMM')}`
    }
  }

  return {
    countdownText: ''
  }
}

export const getCourseProgress = (course) => {
  const courseTermState = getCourseTermState(course)

  if (courseTermState === 'isUpcoming') {
    return 0
  }
  if (courseTermState === 'termCompleted') {
    return 100
  }

  const { dateStart, dateEnd } = calculateCohortStartAndEndDates(course)
  const today = new Date()

  const totalDuration = differenceInMilliseconds(dateEnd, dateStart)
  const elapsedDuration = differenceInMilliseconds(today, dateStart)
  const progress = (elapsedDuration / totalDuration) * 100

  return Math.round(progress)
}

export const getFinishedCourses = (studentCourses) => {
  if (!studentCourses?.length) {
    return []
  }

  return studentCourses.filter((course) => {
    const latestCohort = getLatestCohort(course)
    const courseTermState = getCourseTermState(course)
    const { studentStatus } = latestCohort || {}

    return (
      [COMPLETED, WITHDRAW, INCOMPLETE].includes(studentStatus) ||
      courseTermState === 'termCompleted'
    )
  })
}

export const getFinalGradeText = (course, courseProjectedGrades) => {
  const latestCohort = getLatestCohort(course)
  const { studentStatus } = latestCohort || {}
  const isWithdrawn = studentStatus === WITHDRAW

  if (isWithdrawn) {
    return '-'
  }
  const { cohortEndTime, finalExamEndTime } = getCohortDatesInSeconds(course)

  if (shouldShowFinalGrade(finalExamEndTime, cohortEndTime)) {
    const { id } = course
    const grade = courseProjectedGrades?.[id]
    return `Final Grade: ${grade}${typeof grade === 'number' ? '%' : ''}`
  }

  return 'Final Grade: Pending'
}

export const getRecommendedCourseLink = (course) => {
  const { displayName, link } = course
  const courseKey = courseUTMKeys[`${displayName}`]
  const UTMParams = `?utm_source=website&utm_medium=lifecycle&utm_campaign=studentdash&utm_term=${courseKey}&utm_content=recs`

  return `${link}${UTMParams}`
}
