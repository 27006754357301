import React from 'react'
import CardInfo from './CardInfo'
import config from '../../config'

import {
  getVisitedALCardInfo
} from '../../utilities/section'
import { ExamLink } from './style'

const ActiveLearningInProgressAndCompletedCard = ({
  course,
  note,
  exam,
  handleStartButtonClick,
  courseProgress,
  chapterNo,
  sectionNo,
  reviewChapterTitle,
  reviewChapterUrl,
  reviewFirstSectionUUID
}) => {
  const { id } = course
  const { studentProgress, sectionData } = courseProgress
  const { studentAnswers } = studentProgress

  // get last visited guesswork section data
  const {
    section_exe: sectionExe,
    section_uuid: sectionUuid
  } = sectionData
  const { active_learning: activeLearning } = sectionExe

  // get course base url
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)
  const { completedQuestion, totalQuestion, questionId } =
    getVisitedALCardInfo(studentAnswers, activeLearning)

  const completedActiveLearning =
    Object.keys(studentProgress['active-learning-complete'])
  const isActiveLearningCompleted = completedActiveLearning.includes(sectionUuid)

  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`
  const activeLearningUrl = `${sectionUrl}/${questionId}`

  const {
    isStudyGuideOpen,
    openExamStudyGuide,
    title,
    studyGuideNote,
    examOpen,
    examLoading
  } = exam

  const getDescription = () => {
    const isExamOpenAndActiveLearningComplete =
      isActiveLearningCompleted && examOpen
    const isStudyGuideOpenAndActiveLearningComplete =
      isActiveLearningCompleted && isStudyGuideOpen
    const remainingCards = totalQuestion - completedQuestion
    const isMultpleRemainingCards = remainingCards > 1 ? 's' : ''
    const isMultpleCompletedCards = completedQuestion > 1 ? 's' : ''

    if (isExamOpenAndActiveLearningComplete && reviewFirstSectionUUID) {
      return (
        <>
          {'It\'s exam time! You finished '}
          <a href={activeLearningUrl} rel='noopener noreferrer'>
            Active Learning
          </a>
          {' in '}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
          {', start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>, {'or start '}
          <a href={reviewChapterUrl} rel='noopener noreferrer'>
            Review {reviewChapterTitle}
          </a>
          .
        </>
      )
    }

    if (isExamOpenAndActiveLearningComplete) {
      return (
        <>
          {'It\'s exam time! You finished '}
          <a href={activeLearningUrl} rel='noopener noreferrer'>
            Active Learning
          </a>
          {' in '}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
          {', start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>
          {isStudyGuideOpen && (
            <>
              {' or view the '}
              <span onClick={() => openExamStudyGuide()}> Study Guide</span> to
              review the course material.
            </>
          )}
          .
        </>
      )
    }

    if (examOpen && reviewFirstSectionUUID) {
      return (
        <>
          {"It's exam time! Continue to complete the remaining "}
          {`${remainingCards} card${isMultpleRemainingCards} in `}
          <a href={activeLearningUrl} rel='noopener noreferrer'>
            Active Learning
          </a>
          {' in '}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
          , {'start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>
          , {' or start '}
          <a href={reviewChapterUrl} rel='noopener noreferrer'>
            Review {reviewChapterTitle}
          </a>
          .
        </>
      )
    }

    if (examOpen) {
      return (
        <>
          {'It\'s exam time! Continue to complete the remaining '}
          {`${remainingCards} card${isMultpleRemainingCards} in `}
          <a href={activeLearningUrl} rel='noopener noreferrer'>
            Active Learning
          </a>
          {' in '}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
          , {!isStudyGuideOpen && 'or'} {'start '}
          <ExamLink
            disable={examLoading}
            onClick={() => handleStartButtonClick()}
          >
            {title}
          </ExamLink>
          {isStudyGuideOpen && (
            <>
              {' or view the '}
              <span onClick={() => openExamStudyGuide()}>Study Guide</span>
              {' to review the course material'}
            </>
          )}.
        </>
      )
    }

    if (isStudyGuideOpenAndActiveLearningComplete) {
      return (
        <>
          {`The ${title} is coming up! You finished `}
          <a href={activeLearningUrl} rel='noopener noreferrer'>
            Active Learning
          </a>
          {' in '}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
          {'. View '}
          <span onClick={() => openExamStudyGuide()}>Study Guide</span>
          {' or continue to keep working on the material.'}
        </>
      )
    }

    if (isStudyGuideOpen) {
      return (
        <>
          {`The ${title} is coming up! View the `}
          <span onClick={() => openExamStudyGuide()} rel='noopener noreferrer'>
            Study Guide
          </span>
          {` or continue to complete the remaining ${remainingCards} `}
          <a href={activeLearningUrl} rel='noopener noreferrer'>
            Active Learning
          </a>
          {` card${isMultpleCompletedCards} in `}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
          .
        </>
      )
    }

    if (isActiveLearningCompleted) {
      return (
        <>
          {'You finished '}
          <a href={activeLearningUrl} rel='noopener noreferrer'>
            Active Learning
          </a>
          {' in '}
          <a href={sectionUrl} rel='noopener noreferrer'>
            Section {chapterNo}.{sectionNo}
          </a>
          . Continue to keep working on the material
          {reviewFirstSectionUUID && (
            <>
              {' or start '}
              <a href={reviewChapterUrl} rel='noopener noreferrer'>
                Review {reviewChapterTitle}
              </a>
            </>
          )}
          .
        </>
      )
    }

    return (
      <>
        {`You reviewed ${completedQuestion} `}
        <a href={activeLearningUrl} rel='noopener noreferrer'>
          Active Learning
        </a>
        {` card${isMultpleCompletedCards} in `}
        <a href={sectionUrl} rel='noopener noreferrer'>
          Section {chapterNo}.{sectionNo}
        </a>
        {`. Continue to complete the remaining ${remainingCards}`}
        {` card${isMultpleRemainingCards}`}
        {reviewFirstSectionUUID && (
          <>
            {' or start '}
            <a href={reviewChapterUrl} rel='noopener noreferrer'>
                Review {reviewChapterTitle}
            </a>
          </>
        )}
          .
      </>
    )
  }

  const navigateToCourseUrl = () => (window.location.href = isActiveLearningCompleted
    ? sectionUrl
    : activeLearningUrl)

  const navigateToReviewChapterUrl = () =>
    (window.location.href = reviewChapterUrl)

  const data = {
    dataTestId: isActiveLearningCompleted
      ? 'activelearning-completed'
      : 'activelearning-inprogress',
    description: getDescription(),
    note,
    studyGuideNote,
    secondaryBtnTxt: examOpen || reviewFirstSectionUUID ? 'Start' : isStudyGuideOpen && 'Continue',
    handleSecondaryButton: () => {
      examOpen
        ? handleStartButtonClick()
        : reviewFirstSectionUUID
          ? navigateToReviewChapterUrl()
          : navigateToCourseUrl()
    },
    primaryBtnTxt: isStudyGuideOpen ? 'View' : 'Continue',
    handlePrimaryButton: () => {
      isStudyGuideOpen ? openExamStudyGuide() : navigateToCourseUrl()
    }
  }

  return (
    <CardInfo
      course={course}
      {...data}
    />
  )
}

export default ActiveLearningInProgressAndCompletedCard
