import React from 'react'
import Modal from 'react-bootstrap/Modal'
import {
  AlertModalParagraph,
  AlertModalFooterWrapper,
  ExploreButton,
  ReviewButton
} from './style'

const RemoveModal = (props) => {
  const { show, onHide, onRemove, courseName } = props
  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      centered
      className='removeModal'
      data-testid='remove-modal'
    >
      <AlertModalParagraph data-testid='modal-title'>
        Are you sure you want to remove {courseName}?
      </AlertModalParagraph>
      <AlertModalFooterWrapper data-testid='modal-footer'>
        <ExploreButton
          onClick={() => {
            onHide()
            onRemove()
          }}
          data-testid='remove-button'
        >
          Remove
        </ExploreButton>
        <ReviewButton onClick={() => onHide()} data-testid='cancel-button'>
          Cancel
        </ReviewButton>
      </AlertModalFooterWrapper>
    </Modal>
  )
}

export default RemoveModal
