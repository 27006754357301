import React from 'react'
import config from '../../config'
import WidgetState from './WidgetState'
import { PROGRESS_TYPE } from '../../Constants/courseCard'

const LectureCard = ({
  course,
  courseProgress,
  lectureInProgress,
  chapterNo,
  sectionNo
}) => {
  const { id } = course

  const {
    sectionData: {
      section_exe: sectionExe,
      section_uuid: sectionUuid
    },
    studentProgress
  } = courseProgress

  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const {
    id: lectureId,
    remainingTime,
    order,
    timeSpent,
    completed
  } = lectureInProgress

  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`
  const lectureUrl = `${sectionUrl}/${lectureId}`

  const { LECTURE_COMPLETE } = PROGRESS_TYPE

  const isMultiLectureCompleted = (multipleVideos) => {
    if (!multipleVideos) return

    const { videos } = multipleVideos
    const allVideosEmbedCode =
        videos.map(({ kalturaEmbedCode }) => kalturaEmbedCode)
    const completedActivity =
        Object.keys(studentProgress[LECTURE_COMPLETE])

    return allVideosEmbedCode.every(embedCode =>
      completedActivity.includes(embedCode))
  }

  const getNextActivity = () => {
    const {
      multi_lecture_videos: multipleVideos,
      active_learning: activeLearning,
      practice_terms: practiceTerms,
      practice_exercises: practiceExercises,
      quiz
    } = sectionExe
    const sectionNumber = `Section ${chapterNo}.${sectionNo}`

    const lectureCompleted = isMultiLectureCompleted(multipleVideos)
    if (!lectureCompleted && multipleVideos) {
      return `Lecture ${order + 1} of ${sectionNumber} Lectures`
    }
    if (activeLearning) return `${sectionNumber} Active Learning`
    if (practiceExercises) return `${sectionNumber} Practice Exercises`
    if (practiceTerms) return `${sectionNumber} Practice Terms`
    if (quiz) return `${sectionNumber} Quizzes`
  }

  // Lecture - Completed
  if (completed) {
    return (
      <div data-testid='lecture-completed'>
        <WidgetState
          description={`You completed Lecture ${order || ''} of Section
           ${chapterNo}.${sectionNo} Lectures.
            Continue to ${getNextActivity()}.`}
          primaryBtnTxt='Continue'
          primaryBtnFunction={
            () => { window.location.href = sectionUrl }
          }
        />
      </div>
    )
  }
  // Lecture - In progress
  return (
    <div data-testid='lecture-in-progress'>
      <WidgetState
        description={`
        You watched ${timeSpent} mins of Lecture ${order || ''}
         in Section ${chapterNo}.${sectionNo} Lectures.
          Continue to watch the remaining ${remainingTime} mins.
        `}
        primaryBtnTxt='Continue'
        primaryBtnFunction={
          () => { window.location.href = lectureUrl }
        }
      />
    </div>
  )
}

export default LectureCard
