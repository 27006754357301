import React, { useState, useEffect, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { removeRecommendedCoursesInLS } from '../../utilities/gguDegreeUtils'
import ThreeDotIcon from '../../assets/icons/course-slot-dropdown.svg'
import {
  SlotOptionsWrapper,
  OptionsIcon,
  OptionsDropDownWrapper,
  BackGroundOverlay,
  OptionsDropdown,
  CourseName,
  MenuOption,
  MenuOptionText,
  Chevrons
} from './styles'
import { AppContext } from '../ContextProvider/ContextProvider'

const SlotOptions = ({
  course,
  index,
  setShouldReloadPage,
  noOfCoursesInSlots,
  advisorApproved
}) => {
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const history = useHistory()
  const { courseId, courseNameText } = course || {}
  const { updateContext } = useContext(AppContext)

  useEffect(() => {
    const clickHandler = (e) => {
      const { path, target } = e

      const slotWrapper = path?.find((element) => (
        element.id === `slot-wrapper-${index}`
      ))

      if (path && !slotWrapper) {
        setIsOpen(false)
      }

      if (ref.current && !ref.current.contains(target)) {
        setIsOpen(false)
      }
    }

    window.addEventListener('click', clickHandler)

    return () => window.removeEventListener('click', clickHandler)
    // eslint-disable-next-line
  }, [])

  const onRemoveCourseClick = () => {
    setIsOpen(false)
    setShouldReloadPage(preVal => !preVal)
    removeRecommendedCoursesInLS([courseId])
    updateContext({ hideCatalogMenu: true })
  }

  const shouldRemoveOptionDisable = noOfCoursesInSlots < 2

  return (
    <SlotOptionsWrapper ref={ref} id={`slot-wrapper-${index}`}>
      <OptionsIcon
        src={ThreeDotIcon}
        alt='dropdown'
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <OptionsDropDownWrapper>
          <BackGroundOverlay onClick={() => setIsOpen(false)} />
          <OptionsDropdown>
            <CourseName>
              {courseNameText}
            </CourseName>
            <MenuOption
              data-testid='option-wrapper'
              onClick={() => history.push({
                pathname: `/catalog/${courseId}`,
                state: { directedFrom: '/recommended-courses' }
              })}
            >
              <MenuOptionText>
                Learn more
              </MenuOptionText>
              <Chevrons />
            </MenuOption>
            <MenuOption
              data-testid='option-wrapper'
              onClick={() => {
                history.push({
                  pathname: '/catalog',
                  search: `?replace=${courseId}`
                })
              }}
            >
              <MenuOptionText>
                Switch courses
              </MenuOptionText>
              <Chevrons />
            </MenuOption>
            <MenuOption
              data-testid='option-wrapper'
              disabled={shouldRemoveOptionDisable || advisorApproved}
              onClick={!shouldRemoveOptionDisable && !advisorApproved
                ? onRemoveCourseClick
                : undefined}
            >
              <MenuOptionText>
                Remove this course
              </MenuOptionText>
            </MenuOption>
          </OptionsDropdown>
        </OptionsDropDownWrapper>
      )}
    </SlotOptionsWrapper>
  )
}

SlotOptions.displayName = 'SlotOptions'
export default SlotOptions
