import React, { useContext, useEffect, useMemo, useState } from 'react'
import md5 from 'md5'
import api from '../../../api'
import { NPS_EXIT_PARTNER_STUDENT } from '../../../Constants/eventType'
import { MULTIPLE } from '../../../Constants/frequency'
import { Auth0Context } from '../../Auth0Provider/Auth0Provider'
import { AppContext } from '../../ContextProvider/ContextProvider'
import { useParams, useHistory } from 'react-router-dom'
import config from '../../../config'
import {
  Container,
  BackLink,
  PageTitle,
  CourseDescription,
  AvailableOptionsSection,
  OptionHeader,
  OptionDescription,
  OptionAvailability,
  UpcomingOptionsSection,
  ExpiredOption,
  ExpiredOptions,
  NoExitOptionsContainer,
  SurveyText,
  SurveyLink
} from './style'
import BackIcon from '../../../assets/icons/back-arrow-caret.svg'
import Dot from '../../../assets/icons/dot-icon.svg'
import {
  convertEasternTimeToLocalTime,
  getMonthDayFromDate,
  getDateStringWithYearLongMonth
} from '../../../utilities/dateTime'
import {
  ExitOptions,
  StudentStatus
} from '../../../Constants/accountArea'
import {
  showSelfServiceOptions,
  getCohortStatus,
  getStudentStatus
} from '../../../utilities/courseManagementUtils'
import { getLatestCohort, isStudioCohort } from '../../../utilities/course'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import {
  AMAZON_RELATIONSHIP,
  GGU_RELATIONSHIP,
  GUILD_RELATIONSHIP,
  ILLINOIS_TECH_BS,
  INSTRIDE,
  specialStatusRelationships
} from '../../../Constants/relationships'
import { isAfterTokensLaunch } from '../../../utilities/tokenUtils'
import WarningModal from './WarningModal'
import {
  getCourseOrderInCertificate,
  getCertificateWithPartnerDetails,
  getFirstCertificateCohort
} from '../../../utilities/certificate'
import {
  getFormPageNavigationData,
  getOptionDate,
  getOptionDescription,
  getOptionIcon
} from './coursePageUtil'
import AvailableOptionsList from './AvailableOptionsList'
import UpcomingOptionsList from './UpcomingOptionsList'

function CoursePage ({ location }) {
  const { courseName } = useParams()
  const { isCollegeSuccessCourse } = config
  const { user } = useContext(Auth0Context)
  const {
    courses, certificates, isContextLoading, studentData, tokens
  } = useContext(AppContext)
  const history = useHistory()
  const [GGUWarning, setGGUWarning] = useState({ show: false, option: '' })
  const course = courses.find(course => course.displayName === courseName) || {}
  const cohort = getLatestCohort(course)
  const isPostTokensCohort = isAfterTokensLaunch(cohort, tokens)
  const {
    id: cohortId,
    studentStatus: cohortStudentStatus,
    relationship,
    dateStart,
    duration,
    finalWithdrawalDate,
    finalDropDate,
    finalExamEndTime,
    isPartnerCohort
  } = cohort || {}

  const { name: courseFullName } = course

  const isGGUCourse = courseFullName?.toLowerCase()?.includes('ggu')
  const isPartnership = isPartnerCohort || relationship?.fields?.relationshipType.includes('partnership')
  const partnerName = relationship?.fields?.relationshipName
  const partnerDisplayName = relationship?.fields?.displayName || partnerName

  const isAmazon = isPartnership && partnerName === AMAZON_RELATIONSHIP
  const isGGU = isPartnership && partnerName === GGU_RELATIONSHIP
  const isInStride = isPartnership && partnerName === INSTRIDE
  const isIllinoisTechBS = isPartnership && partnerName === ILLINOIS_TECH_BS
  const isProfCert = course?.profCert
  const isIllinoisTechBSAndHasCmFlag = isIllinoisTechBS && config.hasCmPartnerUpdateFlag

  const isGuild = useMemo(
    () => isPartnership && partnerName === GUILD_RELATIONSHIP,
    [isPartnership, partnerName]
  )
  const isUniquePartner = config.hasCmPartnerUpdateFlag && isPartnership &&
  !isGuild && !isAmazon && !isInStride && !isIllinoisTechBS && !isGGU
  const isGuildProfCert = isGuild && isProfCert
  const isProfCertII = isProfCert && /\bII\b/.test(courseName)
  const isAmazonAndHasCmFlag = isAmazon && config.hasCmPartnerUpdateFlag

  const isGuildProfCertII = isGuild && isProfCertII &&
    config.hasCmPartnerUpdateFlag

  const isGuildProfCertAndHasCmFlag = isGuildProfCert &&
    config.hasCmPartnerUpdateFlag

  const showGuildExitOptions = useMemo(() => {
    if (!isGuild || !certificates?.length || !courses?.length || !partnerName) return false
    if (isGuildProfCertII) return true
    const certificateWithPartnerDetails = getCertificateWithPartnerDetails({
      certificates, courses, relationshipName: partnerName
    })
    const courseOrder = getCourseOrderInCertificate({
      certificate: certificateWithPartnerDetails, courseId: course.id, cohortId
    })
    const firstCourseCohort = getFirstCertificateCohort(certificateWithPartnerDetails)
    const firstCourseWithdrawalDate = firstCourseCohort?.finalWithdrawalDate
    return courseOrder > 0 && new Date() > new Date(firstCourseWithdrawalDate)
  }, [
    isGuild,
    certificates,
    courses,
    partnerName,
    course,
    cohortId,
    isGuildProfCertII
  ])

  const showOnlyTransfer = useMemo(
    () => isAmazon || showGuildExitOptions,
    [isAmazon, showGuildExitOptions]
  )

  const testAttempt = cohort?.testAttempt
  const isContractorEmployee = studentData?.contractorEmployee
  const isNotStudent = studentData?.notStudent
  const isOutlierStudent =
      !isContractorEmployee && !isNotStudent && !testAttempt

  useEffect(() => {
    const loadSatismeter = () => {
      window.satismeter({
        writeKey: config.REACT_APP_SATISMETER_WRITE_KEY,
        userId: md5(user.email),
        traits: {
          attempt_id: cohort?.attemptID,
          course_name: courseName,
          cohort_name: cohort?.name,
          outlier_id: studentData?.id,
          student_email: user.email,
          outlier_student: isOutlierStudent
        }
      })
    }

    loadSatismeter()
    // eslint-disable-next-line
  }, [])

  const handleSatismeterClick = async () => {
    await api.submitTrackedEvent({
      properties: {
        attempt_id: cohort?.attemptID,
        course_name: courseName,
        cohort_name: cohort?.name,
        outlier_id: studentData?.id,
        student_email: user.email,
        outlier_student: isOutlierStudent
      },
      event: NPS_EXIT_PARTNER_STUDENT,
      frequency: MULTIPLE
    })
    window.satismeter('track', {
      event: NPS_EXIT_PARTNER_STUDENT
    })
  }

  if (isContextLoading || !courses) return <LoadingSpinner />

  const studentStatus = getStudentStatus(cohortStudentStatus)
  const relationshipAdminEmail = relationship && relationship.fields.relationshipAdminEmail
  const isStudio = isStudioCohort(course)

  const isSelfServiceUser = showSelfServiceOptions(isPartnership, partnerName)
  const isRegularPartnerUser = isPartnership && !specialStatusRelationships.includes(partnerName)
  const isVip = course.vip
  const isStudentWithdrawn = studentStatus === 'Withdraw'
  const canExit = !(course.vip || isStudentWithdrawn ||
    isIllinoisTechBSAndHasCmFlag)
  const isCollegeSuccess = isCollegeSuccessCourse(course?.id)
  const { ENROLLED } = StudentStatus
  const AUDITING = 'Auditing'
  const isStudentEnrolled = studentStatus === ENROLLED
  const isStudentAuditing = studentStatus === AUDITING
  const courseDates = `${getMonthDayFromDate(dateStart, undefined, 'short')} -
      ${getMonthDayFromDate(finalExamEndTime?.split('T')[0], undefined, 'short')}`

  const cohortDurationText = isStudio ? 'Studio' : getCohortStatus(duration)

  const dropDateET = convertEasternTimeToLocalTime(finalDropDate)
  const withdrawDateET = convertEasternTimeToLocalTime(finalWithdrawalDate)

  const isDropDeadlinePassed = new Date() > dropDateET
  const isWithdrawDeadlinePassed = new Date() > withdrawDateET

  const canDrop = !isDropDeadlinePassed
  const dropDeadline = getDateStringWithYearLongMonth(
    dropDateET?.toISOString(), 'short'
  )

  const canWithdraw = !isWithdrawDeadlinePassed && isDropDeadlinePassed
  const withdrawDeadline = getDateStringWithYearLongMonth(
    withdrawDateET?.toISOString(), 'short'
  )

  const getExpiredOptions = () => {
    const { AUDIT, DROP, TRANSFER, WITHDRAW } = ExitOptions

    if (!isStudentEnrolled && !isStudentAuditing) return []
    if (isStudentEnrolled && !canDrop && canWithdraw && isGGU) return [DROP]
    if (isStudentEnrolled && !canDrop && !canWithdraw && isGGU) return [DROP, WITHDRAW]
    if (isStudentEnrolled && !canDrop && canWithdraw && showOnlyTransfer) return [TRANSFER]
    if (isStudentEnrolled && !canDrop && canWithdraw && isSelfServiceUser) return [AUDIT, DROP, TRANSFER]
    if (isStudentEnrolled && !canDrop && canWithdraw && isPartnership) return [AUDIT, DROP]
    if (isStudentEnrolled && !canDrop && !canWithdraw && isSelfServiceUser) return [AUDIT, DROP, TRANSFER, WITHDRAW]
    if (isStudentEnrolled && !canDrop && !canWithdraw && showOnlyTransfer) return [WITHDRAW, TRANSFER]
    if (isStudentEnrolled && !canDrop && !canWithdraw && isPartnership) return [AUDIT, DROP, WITHDRAW]
    if (isStudentAuditing && !canDrop && showOnlyTransfer) return [TRANSFER]
    if (isStudentAuditing && !canDrop && isSelfServiceUser) return [DROP, TRANSFER]
    if (isStudentAuditing && !canDrop && isPartnership) return [DROP]

    return []
  }

  const navigateToFormPage = (option) => {
    const navigationData = getFormPageNavigationData({
      option,
      cohort,
      courseName,
      partnerName,
      isPostTokensCohort,
      isStudentEnrolled
    })
    history.push({
      pathname: `${location.pathname}/${navigationData?.link}`,
      state: navigationData.state
    })
  }

  const optionParameters = {
    canDrop,
    canWithdraw,
    isGGU,
    showOnlyTransfer,
    isSelfServiceUser,
    isPartnership,
    isRegularPartnerUser,
    isGGUCourse,
    isPostTokensCohort,
    isStudentEnrolled,
    isStudentAuditing,
    isAmazon,
    isGuildProfCertII
  }

  const renderExpiredOption = (option, i) => {
    const optionDescription = getOptionDescription({
      option,
      isGGUCourse,
      isPostTokensCohort,
      isRegularPartnerUser
    })
    const optionIcon = getOptionIcon(option)
    const optionDate = getOptionDate({ option, dropDeadline, withdrawDeadline })

    return (
      <ExpiredOption key={i}>
        <OptionHeader>
          <img src={optionIcon} alt={`${option}-expired-icon`} />
          <span>{option}</span>
        </OptionHeader>
        <OptionDescription>
          {optionDescription}
        </OptionDescription>
        <OptionAvailability>
          Expired {optionDate}
        </OptionAvailability>
      </ExpiredOption>
    )
  }

  const NoExitOption = () => {
    return (
      <NoExitOptionsContainer>
        {isVip && (
          <>
            <h3>You’re not able to exit this course. </h3>
            <p>Because you’ve been granted VIP access to this cohort, you are not able to exit it using our normal processes. Please contact <a href='mailto:success@outlier.org'>success@outlier.org</a> if you believe you are seeing this message in error. </p>
          </>
        )}
        {isUniquePartner && (
          <>
            <h3>Do you want to exit your course?</h3>
            <p>
              Contact your school's Outlier administrator to discuss the available options
            </p>
          </>
        )}
        {isIllinoisTechBSAndHasCmFlag && (
          <>
            <h3>Change course enrollment</h3>
            <p>
              Contact <a href='mailto:success@outlier.org'>success@outlier.org</a> to request enrollment changes such as
              <br /> transferring cohorts, moving to audit, or dropping or withdrawing from a
              <br /> course.
            </p>
          </>
        )}
        {
          isStudentWithdrawn && (
            <>
              <h3>You’ve withdrawn from this course. </h3>
              <p>
              No further exit options are available at this time. You still have unlimited access to the course’s non-graded content and will receive a “W” on your transcript instead of a letter grade.
              If you have any questions, please reach out to <a href='mailto:success@outlier.org'>success@outlier.org</a>.
              </p>
            </>
          )
        }
      </NoExitOptionsContainer>
    )
  }

  const { state: { linkLabel, origin } = {} } = location || {}

  return (
    <Container>
      <BackLink onClick={() => history.push(origin || '/account/manage-courses')}>
        <img src={BackIcon} alt='back-icon' />
        <span>{linkLabel || 'account'}</span>
      </BackLink>
      <PageTitle>
        Manage course
      </PageTitle>
      <CourseDescription>
        {courseName}
        <div>
          <img src={Dot} alt='dot' />
          <span>{studentStatus}</span>
          <img src={Dot} alt='dot' />
          <span>{courseDates}</span>
          <img src={Dot} alt='dot' />
          <span>{cohortDurationText}</span>
        </div>
        {partnerDisplayName && (
          <span>{partnerDisplayName}</span>
        )}
      </CourseDescription>
      {(isCollegeSuccess && !isVip) || (isGuild && !showGuildExitOptions) ||
        (isProfCert && !isGGU && !isGuildProfCertII) ? (
          <NoExitOptionsContainer>
            <h3>You’re not able to exit this course.</h3>
            <p>
            Please contact{' '}
              <a href='mailto:success@outlier.org'>success@outlier.org</a> if you
            believe you are seeing this <br /> message in error.{' '}
            </p>
          </NoExitOptionsContainer>
        ) : isStudio ? (
          <AvailableOptionsSection>
            <p>
            Please contact your instructor about your options for exiting this course.<br />
            We do not manage Transfers, Drops, Audits, or Withdraws for Studio student.
            </p>
          </AvailableOptionsSection>
        ) : (isCollegeSuccess && isVip) || !canExit || isUniquePartner ? (
          <NoExitOption />
        )
          : (
            <>
              <AvailableOptionsList
                optionParameters={optionParameters}
                studentStatus={studentStatus}
                relationshipAdminEmail={relationshipAdminEmail}
                courseName={courseName}
                navigateToFormPage={navigateToFormPage}
                setGGUWarning={setGGUWarning}
                withdrawDeadline={withdrawDeadline}
                dropDeadline={dropDeadline}
              />
              <UpcomingOptionsList
                optionParameters={optionParameters}
                withdrawDeadline={withdrawDeadline}
                dropDeadline={dropDeadline}
              />
              {getExpiredOptions().length > 0 && (
                <UpcomingOptionsSection>
                  <h3>Expired options</h3>
                  <ExpiredOptions numberOfItems={getExpiredOptions().length}>
                    {getExpiredOptions().map((option, i) => renderExpiredOption(option, i))}
                  </ExpiredOptions>
                </UpcomingOptionsSection>
              )}
            </>
          )}
      {
        (isUniquePartner || isIllinoisTechBSAndHasCmFlag || isCollegeSuccess ||
          isGuildProfCertAndHasCmFlag || isAmazonAndHasCmFlag) &&
            <SurveyText>
          If you plan to exit this course, please <SurveyLink onClick={handleSatismeterClick}>fill out this survey</SurveyLink>.
          We want to know how we can do better!
            </SurveyText>
      }
      <WarningModal
        GGUWarning={GGUWarning}
        setGGUWarning={setGGUWarning}
        navigateToFormPage={navigateToFormPage}
      />

    </Container>
  )
}

export default CoursePage
