import React from 'react'
import { Spinner } from './style'

export default function LoadingSpinner () {
  return (
    <Spinner data-testid='loadingSpinner'>
      <div className='bounce1' />
      <div className='bounce2' />
      <div />
    </Spinner>
  )
}
