import styled from 'styled-components'

export const NoAccessWrapper = styled.div`
  width: 500px;
  margin: 0 auto;
  padding-top: 141px;

  h1 {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 51px;
  }
  p,
  li {
    color: #fff;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  a {
    color: #5fc4b8;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`
