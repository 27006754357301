import React from 'react'
import {
  AvailableOption,
  AvailableOptions,
  AvailableOptionsSection,
  Button,
  OptionAvailability,
  OptionDescription,
  OptionHeader
} from './style'
import { ExitOptions } from '../../../Constants/accountArea'
import { getEmailSubject } from '../../../utilities/courseManagementUtils'
import {
  getOptionDate,
  getOptionDescription,
  getOptionIcon
} from './coursePageUtil'
import ExceptionRequest from './ExeptionRequest'

const AvailableOptionsList = ({
  optionParameters,
  studentStatus,
  relationshipAdminEmail,
  courseName,
  navigateToFormPage,
  setGGUWarning,
  withdrawDeadline,
  dropDeadline
}) => {
  const {
    canDrop,
    canWithdraw,
    isGGU,
    showOnlyTransfer,
    isSelfServiceUser,
    isPartnership,
    isRegularPartnerUser,
    isGGUCourse,
    isPostTokensCohort,
    isStudentEnrolled,
    isStudentAuditing
  } = optionParameters || {}
  const openEmailClient = (option) => {
    const emailSubject = getEmailSubject(option, courseName)
    window.location.href =
      `mailto:${relationshipAdminEmail}?subject=${emailSubject}`
  }
  const handleGGUOptionClick = (option) => {
    setGGUWarning({ show: true, option })
  }
  const getAvailableOptions = () => {
    const { AUDIT, DROP, TRANSFER, WITHDRAW } = ExitOptions

    if (!isStudentEnrolled && !isStudentAuditing) return []
    if (isStudentEnrolled && canDrop && isGGU) return [DROP]
    if (isStudentEnrolled && canWithdraw && isGGU) return [WITHDRAW]
    if (isStudentEnrolled && canDrop && showOnlyTransfer) return [TRANSFER]
    if (isStudentEnrolled && canDrop && isSelfServiceUser) return [AUDIT, DROP, TRANSFER]
    if (isStudentEnrolled && canDrop && isPartnership) return [AUDIT, DROP]
    if (isStudentEnrolled && canWithdraw) return [WITHDRAW]
    if (isStudentAuditing && canDrop && showOnlyTransfer) return [TRANSFER]
    if (isStudentAuditing && canDrop && isSelfServiceUser) return [DROP, TRANSFER]
    if (isStudentAuditing && canDrop && isPartnership) return [DROP]

    return []
  }
  const handleClick = (option) => {
    if (isRegularPartnerUser) {
      openEmailClient(option)
    } else if (isGGU) {
      handleGGUOptionClick(option)
    } else {
      navigateToFormPage(option)
    }
  }
  const AvailableOptionItem = ({ option }) => {
    const optionDescription = getOptionDescription({
      option,
      isGGUCourse,
      isPostTokensCohort,
      isRegularPartnerUser
    })

    const optionIcon = getOptionIcon(option)
    const optionDate = getOptionDate({
      option,
      dropDeadline,
      withdrawDeadline
    })

    return (
      <AvailableOption
        grow={isStudentAuditing}
        isPartnership={isPartnership}
      >
        <OptionHeader>
          <img src={optionIcon} alt={`${option}-icon`} />
          <span>{option}</span>
        </OptionHeader>
        <OptionDescription>
          {optionDescription}
        </OptionDescription>
        {isRegularPartnerUser && (
          <OptionDescription>
            Begin an email request to your organization:
          </OptionDescription>
        )}
        <Button
          fixedwidth={!isPartnership} onClick={() => handleClick(option)}
        >
          {isRegularPartnerUser && 'Request'} {option}
        </Button>
        <OptionAvailability>
          Available until {optionDate}
        </OptionAvailability>
      </AvailableOption>
    )
  }

  const availableOptions = getAvailableOptions()

  if (availableOptions.length > 0) {
    return (
      <AvailableOptionsSection>
        <h3>Available options</h3>
        <p>
          {isRegularPartnerUser
            ? `
                   It’s ok if your needs for this course have changed.
                   Email your organization’s administrator to request a status update by using the options below.
                   You must contact your administrator before the listed deadline. 
                   `
            : `It’s ok if your needs for this course have changed.
                   Update your status using the options available here. `}
          <a
            href='https://outlierorg.zendesk.com/hc/en-us/articles/4415539695636'
          >
            Learn how to choose what’s right for you.
          </a>
        </p>
        <AvailableOptions>
          {availableOptions.map((option, i) => {
            return (
              <AvailableOptionItem
                key={i}
                option={option}
              />
            )
          })}
        </AvailableOptions>
      </AvailableOptionsSection>
    )
  } else {
    return (
      <ExceptionRequest
        studentStatus={studentStatus}
        relationshipAdminEmail={relationshipAdminEmail}
        courseName={courseName}
        isRegularPartnerUser={isRegularPartnerUser}
        navigateToFormPage={navigateToFormPage}
      />
    )
  }
}

AvailableOptionsList.displayName = 'AvailableOptionsList'
export default AvailableOptionsList
