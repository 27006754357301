import styled from 'styled-components'

export const Heading = styled.p`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 24px 0;
  text-transform: capitalize;
`

export const DegreesPlusContainer = styled.div`
  background: #1D1D1F;
  border-radius: 8px;
  padding: 25px;
  margin-bottom: 36px;
`

export const DegreesPlusItem = styled.div`
  display: flex;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  & img {
    margin-right: 16px;
  }

  & p {
    margin: 1px 0 0 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: #B1BFC5;
  }

  & a {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    text-transform: uppercase;
    margin-top: 1px;
  }
`
