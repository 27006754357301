import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CalendarDatePicker,
  StyledDatePicker
} from '../CatalogDetailPage/style'
import DatePickerContainerWrapper from '../CatalogDetailPage/DateComponent'

const DatePicker = ({ date, includeDates, changeHandler }) => {
  const [selectDateBtnClicked, setSelectDateBtnClicked] = useState(false)
  const [datePreviousValue, setDatePreviousValue] = useState(new Date())

  const calendarClickHandler = () => {
    const portal = document.querySelector('.react-datepicker__portal')
    if (!portal) return

    portal.classList.add('display-portal')
    portal.classList.remove('hide-portal')
  }

  const closeDatePickerModal = () => {
    const portal = document.querySelector('.react-datepicker__portal')
    if (!portal) return

    portal.classList.add('hide-portal')
    portal.classList.remove('display-portal')
    changeHandler(datePreviousValue)
  }

  const selectDateClickHandler = () => {
    const portal = document.querySelector('.react-datepicker__portal')
    if (!portal) return

    portal.classList.add('hide-portal')
    portal.classList.remove('display-portal')
    setSelectDateBtnClicked(true)
  }

  return (
    <CalendarDatePicker
      data-testid='calendar'
      onClick={() => {
        setDatePreviousValue(date)
        calendarClickHandler()
      }}
    >
      <StyledDatePicker
        name='date'
        selected={date}
        onChange={date => changeHandler(date)}
        dateFormat='MMM dd, yyyy'
        calendarContainer={DatePickerContainerWrapper(
          selectDateClickHandler,
          closeDatePickerModal
        )}
        disabled={!date}
        dateFormatCalendar='MMM yyyy'
        withPortal
        shouldCloseOnSelect={false}
        disabledKeyboardNavigation
        includeDates={includeDates}
        onCalendarClose={() => {
          if (!selectDateBtnClicked) {
            changeHandler(datePreviousValue)
          }
          setSelectDateBtnClicked(false)
        }}
        placeholderText='No cohorts found'
      />
      <span />
    </CalendarDatePicker>
  )
}

DatePicker.displayName = 'DatePicker'

DatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  includeDates: PropTypes.array,
  changeHandler: PropTypes.func
}

export default DatePicker
