import React, { useContext, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import config from '../../config'
import { AppContext } from '../ContextProvider/ContextProvider'
import WarningModal from '../WarningModal/WarningModal'
import {
  CardInfo,
  Chevrons,
  CourseIconV2,
  CourseSubtitle,
  CourseTitle,
  GGUCourseCard,
  GroupDescription,
  GroupHeader,
  GroupHeaderMessage,
  GroupHeaderMessageContainer,
  GroupHeaderMessageWrapper,
  GroupTitle,
  GroupWrapper,
  Required,
  StudentStatus
} from './style'
import {
  APPLIED_COMPUTING,
  BUSINESS_ADMINISTRATION,
  COUSE_REQUIREMENT_BUS_ADMIN,
  COURSE_REQUIREMENT_APP_COMP,
  SELECTED_COURSE_LS_KEY,
  COURSE_REQUIREMENT_LIB_STUD
} from '../../Constants/gguDegrees'
import { Link } from '../PageTitle/style'
import {
  getCurrentProspect,
  getGGUCourseStatus,
  getGGUDegreeInfoLink
} from '../../utilities/gguDegreeUtils'
import { GGU_V2_ENROLLMENT_PAGES } from '../../Constants/gguEnrollmentUrls'
import { COURSE_REGISTRATION_PAGE_SUBMITTED } from '../../Constants/eventType'

const CourseGroup = ({
  group,
  index,
  setShowViewMore,
  setCurrentPage,
  isRegistrationRedesign,
  currentCourseNumber,
  courseQuantity
}) => {
  const { sendEvent } = useSegmentEvents()
  const courseUUID = useRef()
  const history = useHistory()
  const location = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState({})
  const {
    catalogCourses,
    degreeData,
    outlierCourses,
    prospects,
    courseProjectedGrades,
    studentAttempts
  } = useContext(AppContext)
  const activeDegree = getCurrentProspect(prospects)

  const getGroupCourses = () => {
    const gguCoursesNotInCatalog = degreeData?.filter(course => {
      return !catalogCourses?.find(catalogCourse => {
        return catalogCourse.uuid?.uuid === course.courseId
      })
    }).map(course => (
      {
        requirementGroup: course.requirementGroup,
        courseName: course.courseNameText?.trim(),
        oneLineDescription: { descriptionText: '. . .' }
      }
    ))

    return catalogCourses
    ?.filter(course => (
      (course.courseName?.includes('- GGU') && course.uuid?.uuid) ||
      config.isCollegeSuccessCourse(course.uuid?.uuid)
    ))
    ?.map((course) => {
      const {
        courseNameText,
        requirementGroup
      } = degreeData?.find(degreeCourse => (
        course.uuid?.uuid === degreeCourse.courseId
      )) || {}

      if (!course?.oneLineDescription?.descriptionText) {
        course.oneLineDescription = { descriptionText: '. . .' }
      }

      return {
        ...course,
        courseName: courseNameText || course.courseName?.replace('- GGU', ''),
        requirementGroup
      }
    })
    .concat(gguCoursesNotInCatalog)
  }

  const getCouseRequirement = (degree) => {
    switch (degree) {
      case APPLIED_COMPUTING:
        return COURSE_REQUIREMENT_APP_COMP
      case BUSINESS_ADMINISTRATION:
        return COUSE_REQUIREMENT_BUS_ADMIN
      default: return COURSE_REQUIREMENT_LIB_STUD
    }
  }

  const onConfirm = () => {
    history.push({
      pathname: `/catalog/${courseUUID.current}`,
      search: location.search
    })
  }

  const onCourseCardClick = (courseId, courseName, isOthersGroup) => {
    const isCollegeSuccess = config.isCollegeSuccessCourse(courseId)

    if (!isOthersGroup || isCollegeSuccess) {
      if (isRegistrationRedesign) {
        localStorage.setItem(SELECTED_COURSE_LS_KEY, courseId)
        sendEvent({
          eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
          properties: {
            page_name: `Course Catalog Course ${currentCourseNumber}`,
            page_number: `4-${(currentCourseNumber * 2) + courseQuantity}`,
            [`course${currentCourseNumber}_selected`]: courseName
          }
        })
        setCurrentPage(GGU_V2_ENROLLMENT_PAGES.CATALOG_COURSE_DETAILS)
      }

      return history.push({
        pathname: `/catalog/${courseId}`,
        search: location.search
      })
    }

    courseUUID.current = courseId

    setModalTitle(`${courseName} is not required for your degree`)
    setShowModal(true)
  }

  const groupCourses = useMemo(() => (
    getGroupCourses()
    // eslint-disable-next-line
  ), [catalogCourses, degreeData])

  const { key } = group
  const isOthersGroup = index === 6
  const isElective = key === 'Elective'
  const requirement = getCouseRequirement(activeDegree?.degree)?.[key]

  const doesGroupHasCourses = groupCourses?.some(course => (
          course?.requirementGroup?.includes(key)
  )) || index === 6

  if (!doesGroupHasCourses) {
    if (!setShowViewMore) return null
    index === 0 && setShowViewMore(false)
    return null
  }

  return (
    <GroupWrapper
      data-testid='group-wrapper'
      key={index}
    >
      {showModal && (
        <WarningModal
          title={modalTitle}
          onConfirm={onConfirm}
          onCancel={() => setShowModal(false)}
          primaryButtonText='Return to Catalog'
          secondaryButtonText='Select Course'
          text1={`You’ve selected a course not required for ${activeDegree?.degree}.
            We advise you start with courses listed under Degree courses.
          `}
          text2={
            <>You can review your program’s course requirements&nbsp;
              <Link
                fontSize='16px'
                fontWeight='400'
                data-testid='degree-info-link'
                href={getGGUDegreeInfoLink(activeDegree?.degree)}
                target='_blank'
              >
                  here
              </Link>.
            </>
          }
        />
      )}
      <GroupHeaderMessageWrapper>
        <GroupHeader>
          <GroupTitle isOtherCoursesGroup={isOthersGroup}>
            {group.icon}
            {group.title}
          </GroupTitle>
          {requirement && <Required>{requirement} Required</Required>}
          {group.descriptionText && <GroupDescription>{group.descriptionText}</GroupDescription>}
        </GroupHeader>
        {isElective &&
          <GroupHeaderMessageContainer>
            <img src='images/icons/info-circle.svg' alt='info-icon' />
            <GroupHeaderMessage>
              Professional Certificate courses are not permitted to be taken in your first term with Degrees+. Only one Professional Certificate course may be taken per term.
            </GroupHeaderMessage>
          </GroupHeaderMessageContainer>}
      </GroupHeaderMessageWrapper>
      {groupCourses?.map((course, index) => {
        const { requirementGroup } = course || {}
        const isGroupMember = requirementGroup?.includes(key)

        if (!isOthersGroup && !isGroupMember) return null
        if (isOthersGroup && requirementGroup?.length) return null

        const {
          uuid: { uuid = '' } = {},
          courseName,
          oneLineDescription: { descriptionText = '' } = {},
          media: {
            courseIcon: { url: courseIconUrl = '' } = {},
            tileImage: { url: tileImageUrl = '' } = {}
          } = {},
          prerequisite
        } = course || {}
        const isCourseActive = outlierCourses?.some(course => course?.id === uuid)
        const cardStatus = getGGUCourseStatus({
          uuid,
          courseName,
          prospects,
          studentAttempts,
          outlierCourses,
          courseProjectedGrades,
          prerequisite
        })

        const courseIconSrc = courseIconUrl || config.courseIcon(uuid, true)

        return (
          <GGUCourseCard
            data-testid='course-card'
            key={index}
            src={
              tileImageUrl.includes('place-holder')
                ? '/images/default.jpg'
                : (tileImageUrl || '/images/default.jpg')
            }
            onClick={() => {
              if (!isCourseActive) return
              onCourseCardClick(uuid, courseName, isOthersGroup)
            }}
            isActive={isCourseActive}
          >
            <CourseIconV2 src={courseIconSrc} />
            <CardInfo>
              <CourseTitle>
                {courseName}
              </CourseTitle>
              <CourseSubtitle>
                {descriptionText}
              </CourseSubtitle>
              {!!cardStatus &&
                <StudentStatus
                  cardStatus={cardStatus}
                >
                  {cardStatus}
                </StudentStatus>}
            </CardInfo>
            {isCourseActive && <Chevrons data-testid='chevrons' />}
          </GGUCourseCard>
        )
      })}
    </GroupWrapper>
  )
}

CourseGroup.displayName = 'CourseGroup'

export default CourseGroup
