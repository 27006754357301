import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import concat from 'lodash/concat'
import get from 'lodash/get'
import map from 'lodash/map'
import groupBy from 'lodash/groupBy'
import flatMap from 'lodash/flatMap'
import utils from './index'
import api from '../api'
import { getAvailableCohorts, getCohortStartEndTime, getLatestStudentAttempt } from './cohort'
import {
  changeTimezone,
  convertToPTTime,
  dateToSecondsSinceEpoch,
  diffDays,
  getDateStringWithYearLongMonth,
  millisecondsToSeconds,
  secondsSinceEpoch
} from './dateTime'

import {
  getSectionData
} from './section'

import {
  NEEDS_REVIEWING,
  SIGNED_UP,
  PRE_DROP,
  ENROLLED,
  MELT,
  DROP,
  ADMINISTRATIVE_DROP,
  WITHDRAW,
  COMPLETED,
  AUDIT,
  NEEDS_REVIEWING_DEGREE,
  UNOFFICIAL_WITHDRAW
} from '../Constants/studentStatus'
import { HALL_PASS, REFUND, TRANSFERRED } from '../Constants/statusNote'
import { ESSAY, EXAM } from '../Constants/examType'
import config from '../config'
import { AUDITING } from '../Constants'
import { isRealExam } from './chapterUtils'
import { getIsVIP } from './user'

const NO_ACCESS_NOTES = [HALL_PASS, REFUND, TRANSFERRED]
const NO_ACCESS_STATUS = [MELT, DROP, ADMINISTRATIVE_DROP]
const ENROLLED_STATUSES = [SIGNED_UP, PRE_DROP, ENROLLED]

export const outlierCourses = [
  {
    id: 'b227c462-332c-40e0-8735-ea3af6f11661',
    name: 'Calculus I',
    displayName: 'Calculus I',
    description: 'The mathematics of change.',
    link: 'https://www.outlier.org/products/calculus-i'
  },
  {
    id: 'ckdf2158p00003g5milp6mvj8',
    name: 'Introduction to Astronomy',
    displayName: 'Intro to Astronomy',
    description: 'Our universe, explained.',
    link: 'https://www.outlier.org/products/intro-to-astronomy'
  },
  {
    id: 'ckdampe3b00003g5m6l85b1s5',
    name: 'Introduction to Statistics',
    displayName: 'Intro to Statistics',
    description: 'How data describes our world.',
    link: 'https://www.outlier.org/products/intro-to-statistics'
  },
  {
    id: 'ckiawlgvw00003h5o8c6u6rog',
    name: 'Introduction to Microeconomics',
    displayName: 'Intro to Microeconomics',
    description: 'Why small choices have big impact.',
    link: 'https://www.outlier.org/products/intro-to-microeconomics'
  },
  {
    id: 'ckgqsu5lf00003h5lzot6as6x',
    name: 'Introduction to Philosophy',
    displayName: 'Intro to Philosophy',
    description: 'The big questions, examined.',
    link: 'https://www.outlier.org/products/intro-to-philosophy'
  },
  {
    id: '1e2f466d-049a-41e7-af53-74afbfa9d87b',
    name: 'Intro to Psychology',
    displayName: 'Intro to Psychology',
    description: 'The science of the mind.',
    link: 'https://www.outlier.org/products/intro-to-psychology'
  },
  {
    id: 'ckp356yiy00003h5vpz3fbu1h',
    name: 'Precalculus',
    displayName: 'Precalculus',
    description: 'Master the building blocks of Calculus.',
    link: 'https://www.outlier.org/products/precalculus'
  },
  {
    id: 'ckw6tiqy100003e5xexynd0dz',
    name: 'College Algebra',
    displayName: 'College Algebra',
    description: 'Math rules everything around us.',
    link: 'https://www.outlier.org/products/college-algebra'
  },
  {
    id: 'ckzmyc33d00003e5z5ju9w077',
    name: 'Introduction to Astronomy V2',
    displayName: 'Intro to Astronomy',
    description: 'Our universe, explained.',
    link: 'https://www.outlier.org/products/intro-to-astronomy'
  }
]

/**
  It might be possible that student has purchased the course,
  but is not yet enrolled in a cohort
*/
export const isStudentEnrolled = (courses = []) => {
  if (isEmpty(courses)) return false

  const cohorts = courses.map(course => get(course, 'cohort.name', '')).filter(Boolean)
  return !isEmpty(cohorts)
}

export const getCohortStartSecondsSinceEpoch = (cohort) => {
  if (!cohort) return null
  const { dateStart } = cohort
  if (!dateStart) return null

  // Use the string date part and set the time to 00:00 AM UTC
  const date = new Date(dateStart + 'T00:00:00')
  // Convert date to PST time.
  const datePST = changeTimezone(date, 'America/Los_Angeles')

  return Date.parse(datePST)
}

export const getCourseTermState = (course, currentTime = Date.now()) => {
  if (!course) return 'noActiveAndUpcoming'
  let courseTermState = ''

  const { dateStart, dateEnd } = calculateCohortStartAndEndDates(course)

  const startTime = dateToSecondsSinceEpoch(dateStart)
  const endTime = dateToSecondsSinceEpoch(dateEnd)

  currentTime = millisecondsToSeconds(currentTime)

  if (startTime > currentTime) courseTermState = 'isUpcoming'
  if (currentTime < endTime &&
    currentTime >= startTime) courseTermState = 'inProgress'
  if (currentTime > endTime) courseTermState = 'termCompleted'

  return courseTermState
}

export const getCoursesProgress = async (courses) => {
  if (!courses || !courses.length) return []
  return Promise.all(courses.map(async (course) => {
    const { id } = course

    const studentProgress = await api.getStudentProgress(id)

    // student last active progress section data
    const sectionData = await getSectionData(course, studentProgress)

    return {
      courseId: id,
      studentProgress,
      sectionData
    }
  }))
}

export const hasCurrentCourseProgress = (coursesProgress, courseId) => {
  if (!coursesProgress) return false

  const currentCourse = coursesProgress.find(course => {
    return course.courseId === courseId
  })
  const { studentProgress } = currentCourse || {}
  return !isEmptyProgressObject(studentProgress)
}

export const isEmptyProgressObject = studentProgress => {
  const progress = cloneDeep(studentProgress)

  const isEmptyValue = Object.values(progress || {}).every(progressValue => {
    if (typeof progressValue !== 'object') return !progressValue

    const isEmptyProgress = isEmpty(progressValue)
    if (isEmptyProgress) return true

    return isEmptyProgressObject(progressValue)
  })

  return isEmptyValue
}

export const hasCourseProgress = coursesProgress => {
  if (!coursesProgress) return false

  return coursesProgress.some(course => {
    const { studentProgress, courseId } = course
    if (courseId === config.courseIds.collegesuccess) return false

    return !isEmptyProgressObject(studentProgress)
  })
}

export const hasStudentMadeProgresses = (courseInfo) => {
  if (!courseInfo) return null
  let progressHasBeenMade = false
  const {
    'assignment-progress': assignmentProgress,
    'concept-map-progress': conceptMapProgress,
    'concept-map-complete': conceptMapComplete,
    'exam-complete': examComplete,
    'guesswork-complete': guessworkComplete,
    'lecture-video-progress': lectureVideoProgress,
    'lecture-complete': lectureComplete,
    'orientation-active-learning-complete': orientationActiveLearningComplete,
    'orientation-lecture-video-progress': orientationLectureVideoProgress,
    'orientation-lecture-complete': orientationLectureComplete,
    'orientation-section-progress': orientationSectionProgress,
    'practice-exercises-complete': practiveExercisesComplete,
    'practice-term-complete': practiceTermComplete,
    'quiz-complete': quizComplete,
    'reading-progress': readingProgress,
    'reading-complete': readingComplete,
    sectionProgress
  } = courseInfo

  const progresses = [
    assignmentProgress,
    conceptMapProgress,
    conceptMapComplete,
    examComplete,
    guessworkComplete,
    lectureVideoProgress,
    lectureComplete,
    orientationActiveLearningComplete,
    orientationLectureVideoProgress,
    orientationLectureComplete,
    orientationSectionProgress,
    practiveExercisesComplete,
    practiceTermComplete,
    quizComplete,
    readingProgress,
    readingComplete,
    sectionProgress
  ]

  progresses.forEach(progress => {
    if (!progress) return
    if (Object.keys(progress).length !== 0) progressHasBeenMade = true
  })
  return progressHasBeenMade
}

export const getLatestCohort = (course) => {
  if (config.hasUseAttempt) return getLatestCohortUpdate(course)

  const { statusData, cohort } = course || {}
  if (!statusData || !statusData.length) return cohort

  const assignedCohort = statusData.find(cohorts => cohorts.id === cohort.id)
  if (assignedCohort) {
    const { studentStatus, statusNote } = assignedCohort

    /* Students with these notes and status do not have access to course materials anymore */
    const hasNoAccessToCourse =
      NO_ACCESS_NOTES.includes(statusNote) ||
      NO_ACCESS_STATUS.includes(studentStatus)

    if (!hasNoAccessToCourse) return { ...assignedCohort, name: assignedCohort.cohortName }
  }

  const sortedCohorts = statusData.sort((a, b) => {
    return new Date(b.dateStart) - new Date(a.dateStart)
  })

  const latest = sortedCohorts[0]
  return { ...latest, name: latest.cohortName }
}

export const getCoursesLatestCohort = (courses = [], inverse = false) => {
  const latestCohorts = courses.map(course => getLatestCohort(course))
  if (!latestCohorts.filter(Boolean).length) return {}

  const latestCohort = latestCohorts.sort((a, b) => {
    const cohortAEndDate = new Date(a?.cohortEndTime || a?.finalExamEndTime)
    const cohortBEndDate = new Date(b?.cohortEndTime || b?.finalExamEndTime)
    return inverse ? cohortAEndDate - cohortBEndDate : cohortBEndDate - cohortAEndDate
  })[0]

  return latestCohort
}

export const getLatestCohortUpdate = (course) => {
  const { statusData, cohort } = course || {}
  const isVIP = getIsVIP(course)
  const noAttempts = !statusData || !statusData.length
  if (noAttempts) return isVIP ? cohort : {}

  const sortedCohorts = statusData.sort((a, b) => {
    return new Date(b.dateStart) - new Date(a.dateStart)
  })
  if (sortedCohorts.length === 1) return createCohortObject(sortedCohorts[0])

  const inProgressCohort = getInProgressCohort(sortedCohorts)
  if (inProgressCohort) return createCohortObject(inProgressCohort)

  const completedCohorts = getCompletedAttempts(sortedCohorts)
  const recentlyGradedCohort = getRecentlyGradedCohort(completedCohorts)
  if (recentlyGradedCohort) return createCohortObject(recentlyGradedCohort)

  const upcomingCohort = getUpcomingCohort(sortedCohorts)
  if (upcomingCohort) return createCohortObject(upcomingCohort)

  const completedCohort = getCompletedCohort(completedCohorts)
  if (completedCohort) return createCohortObject(completedCohort)

  return {}
}

export const getInProgressCohort = (cohorts) => {
  const today = secondsSinceEpoch()

  return cohorts.find(cohort => {
    const { dateStart, finalExamEndTime } = cohort
    const startDate = dateToSecondsSinceEpoch(new Date(dateStart))
    const endDate = dateToSecondsSinceEpoch(new Date(finalExamEndTime))

    return startDate <= today && today <= endDate
  })
}

export const getRecentlyGradedCohort = (cohorts) => {
  const today = secondsSinceEpoch()

  return cohorts?.find(cohort => {
    const { dateGradesSubmitted } = cohort
    const gradedDate =
      dateGradesSubmitted &&
      dateToSecondsSinceEpoch(new Date(dateGradesSubmitted))
    const gradedInLastThreeDays = diffDays(today, gradedDate, false) <= 3

    return !dateGradesSubmitted || gradedInLastThreeDays
  })
}

export const getUpcomingCohort = (cohorts) => {
  const today = secondsSinceEpoch()

  return cohorts
    .sort((a, b) => {
      return new Date(a.dateStart) - new Date(b.dateStart)
    })
    .find((cohort) => {
      const { dateStart } = cohort
      const startDate = dateToSecondsSinceEpoch(new Date(dateStart))
      return startDate > today
    })
}

export const getCompletedCohort = (cohorts) => {
  const today = secondsSinceEpoch()

  return cohorts?.find(cohort => {
    const { dateGradesSubmitted } = cohort
    const gradedDate = dateToSecondsSinceEpoch(new Date(dateGradesSubmitted))
    return diffDays(today, gradedDate, false) > 3
  })
}

const createCohortObject = (cohort) => {
  return {
    ...cohort,
    name: cohort.cohortName
  }
}

const getCompletedAttempts = (statusData) => {
  return statusData.filter(cohort => {
    const { studentStatus } = cohort
    return studentStatus === COMPLETED
  })
}

export const hasAccessToTheCourse = (cohort) => {
  if (!cohort) return false
  const { studentStatus, statusNote } = cohort

  const hasAccessToTheCourse = !(
    NO_ACCESS_NOTES.includes(statusNote) ||
    NO_ACCESS_STATUS.includes(studentStatus)
  )
  return hasAccessToTheCourse
}

export const isSpecialTopicsCourse = (courseId) => {
  return checkCourseNames([
    'business', 'macroeconomics', 'psychology',
    'business.plus', 'macroeconomics.plus', 'psychology.plus'
  ], courseId)
}

export const isStudioCohort = (course) => {
  const latestCohort = course?.latestCohort ? course.latestCohort : getLatestCohort(course)
  return latestCohort?.relationship?.fields?.accessType === 'studio'
}

export const getCoursesWithAccess = (courses) => {
  if (!courses || !courses.length) return []

  const coursesWithAccess = courses.filter(course => {
    const latestCohort = getLatestCohort(course)
    if (!latestCohort) return false

    const { studentStatus, statusNote } = latestCohort
    /* Students with these notes and status do not have access to course materials anymore */
    const noAccessNotes = NO_ACCESS_NOTES.includes(statusNote)
    const noAccessStatus = NO_ACCESS_STATUS.includes(studentStatus)

    const noAccessToTheCourse = noAccessNotes || noAccessStatus
    return !noAccessToTheCourse
  })

  return coursesWithAccess
}

export function groupAndFilterCourses (courses) {
  if (!courses?.length) return []

  const modifiedCourses = courses.map((course) => {
    if (!course.statusData || !course.statusData.length) return course
    return {
      ...course,
      statusData: course.statusData.map((cohort) => {
        const { statusData, ...courseInfo } = course
        return { ...cohort, course: courseInfo }
      })
    }
  })

  const groupedCourses = groupBy(modifiedCourses, (course) =>
    course.displayName.split('-')[0].trim()
  )
  const combinedStatusData = map(groupedCourses, (course) => {
    // combine statusData from all courses and filter out attempts that do not have access
    const coursesStatusData = flatMap(course, 'statusData').filter((cohort) =>
      hasAccessToTheCourse(cohort)
    )
    return {
      ...course[0],
      statusData: coursesStatusData
    }
  })

  const coursesWithAccess = combinedStatusData
    .map((course) => {
      const latestCohort = getLatestCohortUpdate(course)
      if (!latestCohort.course) return { ...course, statusData: [] }
      return { ...latestCohort.course, statusData: [latestCohort] }
    })
    .filter((course) => course.statusData.length)
  return coursesWithAccess
}

function isEqualOrBeforeFinalDropDate (cohort) {
  return new Date() <= new Date(convertToPTTime(cohort.finalDropDate))
}

// no courses with audit or withdraw status if there is studentStatus
export const getExitCourses = (courses, isAuditForm) => {
  if (!courses || !courses.length) return []

  const exitCourses = courses.filter(course => {
    const latestCohort = getLatestCohort(course)
    if (!latestCohort) return false

    const { studentStatus } = latestCohort
    /* Student can withdraw or audit courses if they havent already passed the drop date */
    const isAuditOrWithdraw = ![WITHDRAW, AUDIT].includes(studentStatus || '')
    const hasPassedAudit = !isAuditForm || isEqualOrBeforeFinalDropDate(latestCohort)

    return isAuditOrWithdraw && hasPassedAudit
  })

  return exitCourses
}

export const getMoreCourses = (courses, outlierCourses) => {
  return outlierCourses.filter(outlierCourse => {
    const course = courses.find(c => c.id === outlierCourse.id)
    if (!course) return outlierCourse

    const cohort = getLatestCohort(course)
    if (!cohort) return outlierCourse

    const { studentStatus } = cohort
    const isEnrolled = (ENROLLED_STATUSES.includes(studentStatus) || !studentStatus)
    const isWithdrawn = [WITHDRAW].includes(studentStatus)
    const isAudit = [AUDIT].includes(studentStatus)

    const courseState = getCourseTermState(course)
    const completedCourse = courseState === 'termCompleted'

    if (completedCourse || isEnrolled || isWithdrawn || isAudit) return undefined

    return outlierCourse
  })
}

export const calculateCohortStartAndEndDates = course => {
  const cohort = getLatestCohort(course)

  const date = cohort.dateStart
  // On development instance, one of the courses had no data
  if (isEmpty(date)) return ''

  const dateStartWithTime = new Date(date + 'T00:00:00')
  const dateStart = changeTimezone(dateStartWithTime, 'America/Los_Angeles')
  const duration = get(course, 'cohort.duration', 0)
  const { finalExamEndTime } = cohort

  // If `finalExamEndTime` is not set in the airtable, use `cohortStartDate + duration`
  if (!finalExamEndTime) {
    let dateEnd = new Date(dateStart)
    dateEnd = new Date(dateEnd.setDate(dateStart.getDate() + 7 * duration))
    return { dateStart, dateEnd }
  }

  // finalExamEndTime has a time field, so we don't need to add a time field
  // unlike cohortStartDate
  const dateEnd = new Date(finalExamEndTime)

  return { dateStart, dateEnd }
}

export const shouldShowFinalGrade = (finalExamEndTime, cohortEndTime) => {
  const cohortEndDate = new Date(cohortEndTime * 1000)
  cohortEndDate.setDate(cohortEndDate.getDate() + 7)
  const cohortEndTimeAfterAweek = dateToSecondsSinceEpoch(cohortEndDate)

  // finalExamEndTime === cohortEndTime means no final deadline
  // so final grade should be shown after a week from cohort end time
  if (
    finalExamEndTime === cohortEndTime
  ) return secondsSinceEpoch() >= cohortEndTimeAfterAweek

  const maxTime = Math.max(finalExamEndTime, cohortEndTimeAfterAweek)

  return secondsSinceEpoch() >= maxTime
}

export function shouldShowCurrentGrade (cohortStartDate, finalExamEndTime, cohortEndTime) {
  const currentDate = secondsSinceEpoch()
  const cohortEndDate = new Date(cohortEndTime * 1000)
  cohortEndDate.setDate(cohortEndDate.getDate() + 7)
  const cohortEndTimeAfterAweek = dateToSecondsSinceEpoch(cohortEndDate)

  const maxTime = Math.max(finalExamEndTime, cohortEndTimeAfterAweek)

  return currentDate >= cohortStartDate && currentDate < maxTime
}

export const getCohortDatesInSeconds = course => {
  const { dateStart, dateEnd } = calculateCohortStartAndEndDates(course)
  const latestCohort = getLatestCohort(course)

  const { cohortEndTime } = latestCohort

  return {
    cohortStartTime: dateToSecondsSinceEpoch(dateStart),
    cohortEndTime: dateToSecondsSinceEpoch(new Date(cohortEndTime)),
    finalExamEndTime: dateToSecondsSinceEpoch(dateEnd)
  }
}

export const getProjectedFinalGradeText = (course) => {
  const {
    cohortStartTime,
    cohortEndTime,
    finalExamEndTime
  } = getCohortDatesInSeconds(course)

  // isAlreadyEnded is relative to projected grade reference date.
  const isAlreadyEnded = isProjectedGradeEndedCohort(finalExamEndTime)
  if (isAlreadyEnded) return ''

  const showCurrentGrade = config.hasInTimeGradeFlag &&
    shouldShowCurrentGrade(cohortStartTime, finalExamEndTime, cohortEndTime)

  if (showCurrentGrade) return 'Current grade'

  const showFinalGrade = shouldShowFinalGrade(finalExamEndTime, cohortEndTime)

  if (showFinalGrade) return 'Final grade'
  return ''
}

// we want to hide Final grade for already ended cohorts relative to
// projected grade reference date.
function isProjectedGradeEndedCohort (cohortEndDate) {
  return cohortEndDate <= config.projectedGradeReferenceDate
}

export const getLastRecommendedSectionId = (lastSection, chapters) => {
  if (!lastSection) return
  const { assignmentType } = lastSection
  const isExam = assignmentType === EXAM
  const isEssay = assignmentType === ESSAY
  if (isExam) {
    const exams = chapters.filter(chapter => chapter.type === EXAM)
    return exams.find(exam => exam.examNumber === lastSection.examNumber)?.chapter_uuid
  }
  if (isEssay) {
    let { title } = lastSection
    const assignments = chapters
      .filter(chapter => chapter.type === 'WritingAssignmentChapterRecord')

    const assignment = assignments.find(assignment => {
      // in some cases the title contains extra space or dashes
      title = title.replace(/\s/g, '').toLowerCase()
      assignment.title = assignment.title.replace(/-|\s/g, '').toLowerCase()
      return assignment.title.includes(title)
    })
    return assignment ? assignment.chapter_uuid : lastSection.chapterUuid || ''
  }
  const { title } = lastSection
  if (title.indexOf('.') === -1) return
  const sections = chapters
    .filter(chapter => chapter.type === 'chapter')
  const sectionNumber = title.split(' ').slice(-1)[0].split('.')
  const chapter = parseInt(sectionNumber[0])
  const section = parseInt(sectionNumber[1])
  const { section_uuid: sectionUUID } = sections?.[chapter - 1]?.sections?.[section - 1] || {}
  return sectionUUID
}

// Get Previously Scheduled Courses
export const getPreviouslyScheduled = (courses = [], activeCourses = []) => {
  if (!courses.length) return []

  const previousCourses = courses.filter((course) => {
    const additionalIds = config.getAdditionalCourseIdsById(course.id)
    return activeCourses.find((activeCourse) => additionalIds?.includes(activeCourse.id))
  }
  )

  const coursesWithStatus = previousCourses.map((item) => {
    const additionalCourseIds = config.getAdditionalCourseIdsById(item.id)
    const data = activeCourses.find((activeCourse) =>
      additionalCourseIds?.includes(activeCourse.id)
    )
    const { description = '' } = data || {}
    const latestCohort = getLatestCohort(item)
    const {
      studentStatus,
      dateStart,
      finalDropDate,
      cohortEndTime
    } = latestCohort
    const startDate = getDateStringWithYearLongMonth(dateStart)
    const dropDate = getDateStringWithYearLongMonth(finalDropDate)
    const beforeDropDate = isEqualOrBeforeFinalDropDate(latestCohort)
    return {
      ...item,
      status:
        studentStatus === 'Audit'
          ? 'Auditing'
          : getStateFromStatus(studentStatus, cohortEndTime),
      completedDate: studentStatus === COMPLETED ? startDate : '',
      finalDropDate: dropDate,
      beforeDropDate,
      description,
      previous: true
    }
  })

  const studentCourses = separateCoursesByStatus(coursesWithStatus)

  return Array.isArray(studentCourses) ? [] : [
    ...sortCoursesByDropDate(studentCourses.preAuditing),
    ...sortCoursesByDropDate(studentCourses.preEnrolled),
    ...sortCoursesByDropDate(studentCourses.postAuditing),
    ...sortCoursesByDropDate(studentCourses.postEnrolled),
    ...studentCourses.isCompleted,
    ...studentCourses.rest
  ]
}

// Get Catalog Courses
export const getCatalogCourses = ({
  catalogCourses = [],
  studentCourses = [],
  outlierCourses = []
} = {}) => {
  if (!catalogCourses.length) return []
  const previouslyScheduledCourses = getPreviouslyScheduled(
    studentCourses,
    catalogCourses
  )
  const previouslyScheduledCourseIds = previouslyScheduledCourses.map(
    course => course.id
  )
  const filteredCatalogCourses = catalogCourses.filter(course => {
    const additionalCourseIds = config.getAdditionalCourseIdsById(course.id) || [course.id]
    return !previouslyScheduledCourseIds.some(id => additionalCourseIds.includes(id))
  })
  return filteredCatalogCourses.filter((course, idx, self) => {
    const additionalCourseIds = config.getAdditionalCourseIdsById(course.id)
    const courseWithCohorts = outlierCourses.find(
      outlierCourse => outlierCourse.id === course.id
    )
    let coursesWithCohorts = [courseWithCohorts]
    if (additionalCourseIds?.length > 1) {
      coursesWithCohorts = outlierCourses.filter(outlierCourses =>
        additionalCourseIds.includes(outlierCourses.id))
    }
    const availableCohorts = courseWithCohorts
      ? getAvailableCohorts({
        courses: coursesWithCohorts,
        courseSelected: {
          ...courseWithCohorts,
          uuid: { uuid: courseWithCohorts.id }
        }
      })
      : []

    return availableCohorts?.length
  })
}

export const sortCoursesByDropDate = (arr) => {
  if (!arr?.length) return []
  return arr.sort(
    (a, b) => new Date(b.finalDropDate) - new Date(a.finalDropDate)
  )
}

export const separateCoursesByStatus = (coursesWithStatus) => {
  if (!coursesWithStatus?.length) return []

  const studentCourses = {
    preAuditing: [],
    preEnrolled: [],
    postAuditing: [],
    postEnrolled: [],
    isCompleted: [],
    rest: []
  }

  const enrolledStatuses = [ENROLLED, SIGNED_UP, PRE_DROP]

  coursesWithStatus.forEach((course) => {
    const { beforeDropDate, status } = course
    if (beforeDropDate && status === AUDITING) {
      studentCourses.preAuditing.push(course)
      return
    }

    if (beforeDropDate && enrolledStatuses.includes(status)) {
      studentCourses.preEnrolled.push(course)
      return
    }

    if (!beforeDropDate && status === AUDITING) {
      studentCourses.postAuditing.push(course)
      return
    }

    if (!beforeDropDate && enrolledStatuses.includes(status)) {
      studentCourses.postEnrolled.push(course)
      return
    }

    if (status === COMPLETED) return studentCourses.isCompleted.push(course)

    if (status === WITHDRAW) studentCourses.rest.push(course)
  })

  return studentCourses
}

export const getStateFromStatus = (status, currentDate, cohortEndTime) => {
  if (status === AUDIT && new Date() > new Date(cohortEndTime)) {
    return COMPLETED
  }
  return status
}

export const getCurrentCourse = courses => {
  if (!courses?.length) return null

  const detailsPagePath = utils.getDetailsPagePath()
  const courseIdFromUrl = detailsPagePath.slice(
    detailsPagePath.lastIndexOf('/') + 1
  )

  return courses.find(course => course.id === courseIdFromUrl)
}

export const isEnrolledCourse = course => {
  if (!course) return false

  const latestCohort = getLatestCohort(course)
  const { studentStatus } = latestCohort || {}

  return ENROLLED_STATUSES.includes(studentStatus)
}

export const getCourseAssets = (catalogCourses, courseId) => {
  if (!catalogCourses || catalogCourses.length === 0 || !courseId) return {}
  const catalogCourse = Array.isArray(catalogCourses) &&
    catalogCourses.find(({ uuid }) => {
      return uuid?.uuid === courseId
    })

  const courseImage = catalogCourse?.media?.tileImage?.url ||
      config.courseImage(courseId)
  const courseIcon = catalogCourse?.media?.courseIcon?.url ||
      config.courseIcon(courseId)
  const courseHeroImage = catalogCourse?.media?.heroImage?.url

  return {
    courseImage,
    courseIcon,
    courseHeroImage,
    longDescription: catalogCourse?.longDescription?.descriptionText
  }
}

export const addNewCourse = (courses) => {
  if (!courses?.length) return null

  const newCourses = {}
  courses.forEach((course) => {
    newCourses[course.courseId] = true
  })
  localStorage.setItem('newCourses', JSON.stringify(newCourses))
}

export const getFilteredCourses = (courses) => {
  if (!courses?.length) return null

  const { isCollegeSuccessCourse, hasCollegeSuccessFlag } = config
  const allCourses = [...courses]
  const isCollegeSuccessCourses = allCourses?.find((item) => {
    return isCollegeSuccessCourse(item?.id)
  })

  if (isCollegeSuccessCourses && !hasCollegeSuccessFlag) {
    const targetIndex = allCourses.findIndex((item) => {
      return isCollegeSuccessCourse(item?.id)
    })
    allCourses.splice(targetIndex, 1)
  }

  return allCourses
}

export const getCollegeSuccessEventData = (course) => {
  return {
    course: 'College success',
    audit: 'No',
    price: 0,
    time_stamp: new Date().getTime(),
    quantity: 1,
    start_date: new Date().getTime(),
    collegeSuccessEnrollment: true,
    collegeSuccessOptIn: false,
    cohort_length: 260,
    cohort: get(course, 'cohort.name', 'College Success')
  }
}

export function getCoursesWithConfigId (courses) {
  if (!courses?.length) return []
  return courses.filter(course =>
    Object.values(config.courseIds).includes(course.id)
  )
}

export const getAttemptToken = (tokens, attempt) => {
  return tokens?.find(token => {
    return attempt?.id === token?.attemptCohort?.id
  })
}

export const addTokensToCourses = (courses, tokens) => {
  if (!tokens?.length) return courses

  return courses?.map(course => {
    const latestCohort = getLatestCohort(course)
    const { id: latestCohortId } = latestCohort || {}
    const { cohort, statusData } = course

    if (cohort?.id === latestCohortId) {
      course.cohort = {
        ...cohort,
        token: { ...getAttemptToken(tokens, cohort) }
      }
    }

    course.statusData = statusData?.map(attempt => {
      if (attempt.id !== latestCohortId) return attempt

      return {
        ...attempt,
        token: { ...getAttemptToken(tokens, attempt) }
      }
    })
    return course
  })
}

export const getNeedsReviewingAttempts = attempts => {
  if (!attempts?.length) return []

  return attempts.filter(attempt => {
    const { Name: status } = attempt.fields?.studentStatus?.fields || {}
    return status === NEEDS_REVIEWING || status === NEEDS_REVIEWING_DEGREE
  })
}

export const hasNeedsReviewingDegreeStatus = (attempts, courseId) => {
  if (!attempts?.length || !courseId) return false

  return attempts.some(attempt => {
    const { studentStatus, course: attemptCourse } = attempt?.fields || {}
    return attemptCourse?.fields?.id === courseId &&
      studentStatus?.fields?.Name === NEEDS_REVIEWING_DEGREE
  })
}

export const hasUnofficalWithdrawStatus = (attempts, courseId) => {
  if (!attempts?.length || !courseId) return false

  const latestAttempt = getLatestStudentAttempt(attempts, courseId)
  return latestAttempt?.fields?.studentStatus?.fields?.Name === UNOFFICIAL_WITHDRAW
}

export const addCompletedCourses = (studentAttempts, currentCourses) => {
  const coursesToAdd = []
  if (!currentCourses || !studentAttempts) return []

  const completedStudentAttempts = studentAttempts.filter(attempt => {
    const {
      fields: {
        studentStatus: { fields: { Name: attemptStudentStatus } = {} } = {}
      } = {}
    } = attempt
    return attemptStudentStatus === COMPLETED
  })

  const coursesIds = currentCourses.map(course => course.id)
  completedStudentAttempts.forEach(attempt => {
    const { fields: { course: attemptCourse, cohort, studentStatus } } = attempt
    const { fields: { id: attemptCourseId } } = attemptCourse
    if (!coursesIds.includes(attemptCourseId)) {
      const course = {
        ...attemptCourse.fields,
        cohort: {
          id: cohort.id,
          cohortName: attemptCourse.fields.name,
          studentStatus: studentStatus.fields.name,
          ...cohort.fields
        }
      }
      course.statusData = [course.cohort]
      coursesToAdd.push(course)
      coursesIds.push(attemptCourseId)
    }
  })
  return coursesToAdd
}

export const checkCourseNames = (courseNames, courseId) => {
  if (!courseNames?.length || !courseId) return false

  return courseNames.some(courseName => (
    config.courseIdToName(courseId) === courseName
  ))
}

export const getSyllabusLink = (
  syllabuses, syllabusType = 'standard'
) => {
  if (!syllabuses?.length) return ''

  return syllabuses.find(
    syllabus => syllabus?.slug?.includes(syllabusType)
  )?.file?.url || ''
}

export const sortCoursesByProgress = (courses) => {
  if (!courses?.length) return []

  const inProgressCourses = []
  const completedCourses = []
  const toBeStarted = []

  const sortedCourses = sortBy(courses, [(course) => {
    const cohort = getLatestCohort(course)
    const { dateStart } = cohort
    return dateStart
  }, 'displayName'])

  sortedCourses.forEach(course => {
    const cohort = getLatestCohort(course)
    const currentTime = Date.now()
    const { startTime, endTime } = getCohortStartEndTime(cohort)
    if (currentTime < startTime) toBeStarted.push(course)
    if (currentTime < endTime &&
      currentTime > startTime) inProgressCourses.push(course)
    if (currentTime > endTime) completedCourses.push(course)
  })

  return concat(inProgressCourses, toBeStarted, completedCourses)
}

export const filterOutAdditionalOrientationChapters = (courseData) => {
  if (!courseData?.chapters) return courseData

  const { chapters } = courseData
  if (!chapters?.length) return courseData

  return {
    ...courseData,
    chapters: chapters.filter((chapter, index) => chapter.type !== 'orientation' || index === 0)
  }
}

export const filterGoogleDataAnalyticsIChapters = (chapters, courseId, dateStart) => {
  if (!chapters?.length) return []

  const isGoogleDataAnalyticsI = courseId === 'clgb29uhc00003b67wtuupjqy'
  const startsOnOrAfter14Jan2024 = new Date(dateStart) >= new Date('2024-01-14')

  if (!isGoogleDataAnalyticsI || !startsOnOrAfter14Jan2024) return chapters

  return chapters.filter(({ type }) => type !== EXAM)
}

export const addExamNumbersToCourseData = (courseData) => {
  if (!courseData?.chapters) return courseData

  const { chapters, course_uuid: courseUUID } = courseData
  const isSingleMidtermCourse = config.isCourseWithSingleMidtermExam(courseUUID)

  if (!chapters?.length) return courseData

  let examNumber = 0

  const examsLength = chapters.filter(isRealExam).length
  const chaptersWithExamNumbers = chapters.map(chapter => {
    if (!isRealExam(chapter)) return chapter

    examNumber += 1

    return {
      ...chapter,
      examNumber,
      isFinalExam: !isSingleMidtermCourse && examNumber === examsLength
    }
  })

  return {
    ...courseData,
    chapters: chaptersWithExamNumbers
  }
}

const getNumberOfExamsFromCohortSyllabus = syllabus => {
  if (!syllabus?.syllabusData) return 0

  const { syllabusData } = syllabus

  let examNumber = 0

  syllabusData.forEach(week => {
    const { sections = [] } = week

    sections.forEach(section => {
      const { assignmentType } = section
      if (assignmentType !== EXAM) return

      examNumber += 1
    })
  })

  return examNumber
}

export const addExamNumbersToCohortSyllabus = (syllabus, courseId) => {
  if (!syllabus?.syllabusData) return syllabus

  const { syllabusData } = syllabus

  let examNumber = 0
  const numberOfExams = getNumberOfExamsFromCohortSyllabus(syllabus)

  const updatedSyllabusData = syllabusData.map(week => {
    const { sections = [] } = week

    const updatedSections = sections.map(section => {
      const { assignmentType } = section
      if (assignmentType !== EXAM) return section

      examNumber += 1

      return {
        ...section,
        examNumber,
        // We do not want to treat the last exam as final exam if the course
        // has only one midterm exam.
        isFinalExam: !config.isCourseWithSingleMidtermExam(courseId) &&
          examNumber === numberOfExams
      }
    })

    return {
      ...week,
      sections: updatedSections
    }
  })

  return {
    ...syllabus,
    syllabusData: updatedSyllabusData
  }
}

export const sortCoursesByStartDate = courses => {
  if (!courses?.length) return []

  const sortedCourses = sortBy(
    courses,
    [(course) => {
      const dateStart = new Date(course?.cohort?.dateStart)
      return dateStart
    }]
  )

  return sortedCourses
}

export const isExtendedCohort = ({ courseName = '', duration }) => {
  return duration === 39 || courseName?.trim()?.toUpperCase()?.endsWith('EXT')
}

export const isInProgressBeforeCutOffDate = (params) => {
  const {
    dateStart, finalExamEndTime, cohortEndTime, duration, courseName
  } = params || {}
  if (isExtendedCohort({ courseName, duration })) return false

  const isStartingAfterCutOffDate =
    dateToSecondsSinceEpoch(new Date(dateStart)) >= config.hideQuizForAuditorsCutOffDate
  if (isStartingAfterCutOffDate) return false

  return isCohortInProgress(dateStart, finalExamEndTime, cohortEndTime)
}

export const isCohortInProgress = (dateStart, finalExamEndTime, cohortEndTime) => {
  if (!dateStart || !finalExamEndTime) return false

  const currentDate = Date.now()
  const startDate = new Date(dateStart).getTime()
  if (currentDate < startDate) return false

  const cohortEndDate = cohortEndTime ? new Date(cohortEndTime).getTime() : 0
  const extendedEndDate = new Date(finalExamEndTime).getTime()
  const maxEndDate = Math.max(cohortEndDate, extendedEndDate)
  return currentDate <= maxEndDate
}

export const filterOutGGUCourses = (courses) => {
  return courses.filter((course) => {
    const { name: courseName } = course
    return !courseName.includes('GGU')
  })
}

export const is39WeekCohort = (course) => {
  if (!course) return false
  const {
    cohort: { duration },
    name: courseName = ''
  } = course || {}
  return duration === 39 || courseName.endsWith('EXT')
}

export const isProfessionalCertificateCourse = (course) => {
  return course?.profCert
}

export const isFreeCourse = (courseId) => {
  return config.freeCourseIds.includes(courseId)
}

export const getPaidCourses = (courses) => {
  return courses?.filter((course) => !isFreeCourse(course.id))
}

export const areAllCoursesProfessionalCertificate = (courses) => {
  const paidCourses = getPaidCourses(courses)
  if (!paidCourses?.length) return false

  return paidCourses.every(isProfessionalCertificateCourse)
}
