export const COLOR_COMPLETED = '#969BFF'
export const COLOR_IN_PROGRESS = '#5FC4B8'
export const COLOR_NOT_ENROLLED = '#98A4AE'

export const MAIN_PAGE = 'main-page'
export const CERTIFICATE_PAGE = 'certificate-page'
export const COURSE_PAGE = 'course-page'

export const defaultCertificates = [
  'Business Foundation',
  'College Foundation',
  'Data Science Prep'
]

export const segmentEventUrl = 'student/academic-achievement-events'
