import React, { useEffect, useRef } from 'react'
import * as typeformEmbed from '@typeform/embed'
import { FORM_URLS as FORM_URL } from '../../data/constants'

const AudienceConsentForm = () => {
  const formRef = useRef(null)

  useEffect(() => {
    if (!formRef.current) return

    const embedForm = async () => {
      try {
        typeformEmbed.makeWidget(
          formRef.current,
          FORM_URL.audienceConsent,
          {
            onSubmit: ({ response_id: responseID }) => {
              console.log('Form submitted successfully! Response ID: ', responseID)
            }
          }
        )
      } catch (error) {
        console.error('Error when embedding form: ', error.message)
      }
    }

    embedForm()
  }, [formRef])

  return (
    <div id='embedded-typeform' ref={formRef} data-testid='audience-consent-form' />
  )
}

export default AudienceConsentForm
