import React from 'react'
import PropTypes from 'prop-types'

import {
  Content,
  Icon,
  LeftSectionText,
  ExtensionLink,
  ExtensionLinkIcon,
  ExtendedText,
  Wrapper
} from './style'

const ExtensionRow = props => {
  const {
    theme,
    leftSectionText,
    rightSectionText,
    isExtended,
    link
  } = props
  return (
    <Wrapper theme={theme}>
      <Icon theme={theme} />
      <Content>
        <LeftSectionText> {leftSectionText} </LeftSectionText>
        {rightSectionText && !isExtended && (
          <ExtensionLink href={link}>
            {rightSectionText}
            <ExtensionLinkIcon>
              &rarr;
            </ExtensionLinkIcon>
          </ExtensionLink>
        )}
        {isExtended && (
          <ExtendedText theme={theme}>
            Deadline extended
          </ExtendedText>
        )}
      </Content>
    </Wrapper>
  )
}

ExtensionRow.propTypes = {
  theme: PropTypes.string,
  leftSectionText: PropTypes.string,
  rightSectionText: PropTypes.string,
  isExtended: PropTypes.bool,
  link: PropTypes.string
}
ExtensionRow.displayName = 'ExtensionRow'
export default ExtensionRow
