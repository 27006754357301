import styled, { keyframes } from 'styled-components'

const skBouncedelay = keyframes`
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
`
export const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  text-align: center;
  transform: translate(-50%, -50%);
  & > div {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background-color: #fff;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${skBouncedelay} 1.4s infinite ease-in-out both;
    animation: ${skBouncedelay} 1.4s infinite ease-in-out both;

    &.bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    &.bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }
`
