import React, { useContext } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import NotEnrolled from '../NotEnrolled/NotEnrolled'
import config from '../../config'
import {
  isStudentEnrolled,
  calculateCohortStartAndEndDates,
  getProjectedFinalGradeText,
  getCourseTermState,
  getLatestCohort,
  getCourseAssets,
  hasUnofficalWithdrawStatus
} from '../../utilities/course'
import {
  isAuditCohort
} from '../../utilities/cohort'
import { getCourseSections } from '../../utilities/section'
import sum from 'lodash/sum'

import {
  CardWrapper,
  CardImageWrapper,
  CardImageTextWrapper,
  CourseTextWrapper,
  CourseDateWrapper,
  CourseStateWrapper,
  CardBodyInProgressWrapper,
  CardBodyToBeStartedWrapper,
  CardBodyTermCompletedWrapper,
  StyledRow,
  Container,
  Background,
  Progress,
  CardCourseDescriptionWrapper,
  CourseDescription,
  CourseIcon,
  CardCol,
  BrandWrap,
  AddLink,
  ProjectedFinalGradeText
} from './style'
import { AppContext } from '../ContextProvider/ContextProvider'
import { isUserAuditor } from '../../utilities/user'
import { DASHBOARD_COURSE_HIDDEN } from '../../Constants/CourseStatus'
import api from '../../api'

const getCourseDate = course => {
  const { dateStart, dateEnd } = calculateCohortStartAndEndDates(course)

  return addYearToStartAndEndDate(dateStart, dateEnd)
}

const addYearToStartAndEndDate = (dateStart, dateEnd) => {
  const dateStartString = dateStart.toLocaleString('en-us', { month: 'short', day: '2-digit' })
  const dateEndString = dateEnd.toLocaleString('en-us', { month: 'short', day: '2-digit' })
  const dateStartYear = dateStart.getFullYear()
  const dateEndYear = dateEnd.getFullYear()

  if (dateStartYear === dateEndYear) {
    return `${dateStartString} - ${dateEndString}, ${dateEndYear}`
  } else {
    return `${dateStartString}, ${dateStartYear} - ${dateEndString}, ${dateEndYear}`
  }
}

const termCompletedWrapper = () => (
  <CardBodyTermCompletedWrapper />
)

const inProgressWrapper = () => (
  <CardBodyInProgressWrapper />
)

const toBeStartedWrapper = () => (
  <CardBodyToBeStartedWrapper />
)

// Todo: Check for Edge cases
// Edge cases: When current date === (course start|end date)
const getProgressWrapper = course => {
  const date = get(course, 'cohort.dateStart')
  if (isEmpty(date)) return termCompletedWrapper()
  const currentTime = Date.now()

  const startTime = Date.parse(date)
  if (currentTime < startTime) return toBeStartedWrapper()

  const duration = get(course, 'cohort.duration', 0)
  const endTime = startTime + duration * 7 * 24 * 60 * 60 * 1000
  if (currentTime > endTime) return termCompletedWrapper()

  return inProgressWrapper()
}

const handleCourseClick = courseId => {
  resetNewCourses(courseId)
  window.location.href = config.courseBaseUrlById(courseId)
}

const resetNewCourses = (id) => {
  const newCourses = JSON.parse(localStorage.getItem('newCourses'))
  const isCourseClicked = newCourses?.[id]
  if (!isCourseClicked) return
  delete (newCourses[id])
  localStorage.setItem('newCourses', JSON.stringify(newCourses))
}

const getCourseProgress = (coursesProgress, key, courseSectionsLength) => {
  const percentage = 0
  if (!coursesProgress) return percentage

  const courseProgress = coursesProgress.find(courseProgress => courseProgress.courseId === key)
  const { studentProgress: { sectionProgress = {} } = {} } = courseProgress || {}
  const SectionProgressLength = Object.keys(sectionProgress)
    .length
  if (!SectionProgressLength) return percentage

  if (Object.keys(sectionProgress).length) {
    return sum(Object.values(sectionProgress)) / courseSectionsLength
  }
}

const getProgressText = course => {
  const courseTermState = getCourseTermState(course)

  if (courseTermState === 'isUpcoming') return 'Enrolled'
  if (courseTermState === 'termCompleted') return 'Term Completed'

  return 'In Progress'
}

const Courses = ({ list }) => {
  const { isStudentProgress, isCollegeSuccessCourse } = config
  const {
    courseCurrentGrades,
    courseProjectedGrades,
    coursesProgress,
    courseFeatures,
    hiddenCourses,
    catalogCourses,
    isGGUStudent,
    studentAttempts
  } = useContext(AppContext)

  const studentEnrolled = isStudentEnrolled(list)
  const onAddCourse = async (course) => {
    const key = DASHBOARD_COURSE_HIDDEN
    const body = {
      [key]: []
    }
    await api.setCourseFeatures(key, body)
    courseFeatures()
  }
  if (!studentEnrolled) {
    return <NotEnrolled />
  }

  return (
    <StyledRow>
      {list.map(function (course, key) {
        const { displayName = '', id, name: courseName } = course
        if (isEmpty(id)) return null
        const isUnofficialWithdraw = hasUnofficalWithdrawStatus(
          studentAttempts, id
        )

        if (isEmpty(id) || isUnofficialWithdraw) return null

        const courseDate = getCourseDate(course)
        const progressWrapper = getProgressWrapper(course)
        const progressText = getProgressText(course)
        const latestCohort = getLatestCohort(course)
        const STUDENT_STATUS_MAPPING = {
          Audit: 'Audit',
          Withdraw: 'W'
        }

        const isGGUCourse = courseName.includes('GGU')
        const upComingCourseState = progressText === 'Enrolled'
        const isCardDisabled = upComingCourseState && isGGUStudent && isGGUCourse

        const { studentStatus, name } = latestCohort
        const mappedStatus = STUDENT_STATUS_MAPPING[studentStatus]
        const projectedText = getProjectedFinalGradeText(course)
        const projectedGrade = mappedStatus || courseProjectedGrades?.[course.id]
        const currentGrade = courseCurrentGrades?.[course.id]
        const courseSections = getCourseSections(course)
        const progress = getCourseProgress(
          coursesProgress,
          id,
          courseSections.length
        )

        const {
          courseImage,
          courseIcon
        } = getCourseAssets(catalogCourses, id)
        const isCohortAudit = isAuditCohort(name)
        const isAuditStatus = isUserAuditor(latestCohort)
        const isAudit = isAuditStatus || isCohortAudit
        const newCourses = JSON.parse(localStorage.getItem('newCourses'))
        const showCourseDate = !isCollegeSuccessCourse(id)
        const {
          cohortName: latestCohortName,
          hideStudentGradeDisplay
        } = latestCohort || {}
        const isGGUCohort = latestCohortName?.toLowerCase().includes('ggu')
        const showCurrentGrade = projectedText === 'Current Grade'
        const grade = isAudit
          ? 'Audit' : showCurrentGrade
            ? currentGrade : projectedGrade
        const isGradeDisplayOff = hideStudentGradeDisplay &&
          config.hasInTimeGradeFlag
        return (
          <CardCol key={key} xs={12} sm={6} md={6} lg={6} tabIndex='0'>
            <CardWrapper
              data-testid='calculus'
              disabled={isCardDisabled}
              {...(!isCardDisabled ? {
                onClick: () => handleCourseClick(id)
              } : {})}
            >
              <CardImageWrapper src={courseImage}>
                {projectedText &&
                  !isGGUCohort && !isGradeDisplayOff &&
                    <ProjectedFinalGradeText data-testid='finalGrade'>
                      {projectedText}:{' '}
                      <b>
                        {grade}{typeof grade === 'number' && '%'}
                      </b>
                    </ProjectedFinalGradeText>}
                {newCourses?.[id] ? <BrandWrap>New</BrandWrap> : null}
                <CardImageTextWrapper>
                  <CardCourseDescriptionWrapper className='d-flex align-items-center'>
                    <CourseIcon src={courseIcon} />
                    <CourseDescription>
                      <CourseTextWrapper>{displayName}</CourseTextWrapper>
                      {showCourseDate &&
                        <CourseDateWrapper>{courseDate}</CourseDateWrapper>}
                    </CourseDescription>
                  </CardCourseDescriptionWrapper>
                  {!isStudentProgress &&
                    <CourseStateWrapper
                      isStudentProgress={isStudentProgress}
                      data-testid='progressText'
                    >
                      {progressText}{isAudit && ' — Audit'}
                    </CourseStateWrapper>}
                  {!showCourseDate &&
                    hiddenCourses?.includes(course?.id) &&
                      <AddLink
                        onClick={(e) => {
                          e.stopPropagation()
                          onAddCourse(course)
                        }}
                      >
                        Add to dashboard
                      </AddLink>}
                </CardImageTextWrapper>
                {isStudentProgress &&
                    progressText === 'In Progress' &&
                      <CourseStateWrapper
                        isStudentProgress={isStudentProgress}
                        data-testid='progressbar'
                      >
                        <Container>
                          <Background />
                          <Progress percent={progress} />
                        </Container>
                      </CourseStateWrapper>}
              </CardImageWrapper>
              {!isStudentProgress && progressWrapper}
            </CardWrapper>
          </CardCol>
        )
      })}
    </StyledRow>
  )
}

export default Courses
