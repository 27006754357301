import React from 'react'
import classNames from 'classnames'

const defaultStyle = {
  height: '48px',
  width: 'auto',
  padding: '16px 24px'
}

export default function CourseButton (props) {
  const {
    id,
    name,
    value,
    style,
    className,
    onClick,
    disabled,
    dataCypress,
    dataTestId,
    title,
    onMouseEnter,
    onMouseLeave,
    children
  } = props

  return (
    <button
      id={id}
      name={name}
      value={value}
      style={{
        ...defaultStyle,
        ...style
      }}
      className={typeof className === 'string' ? className : classNames({
        btn: true,
        ...className
      })}
      title={title}
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
      data-cy={dataCypress}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  )
}
