import React from 'react'
import { Container } from './style'
import config from '../../config'

export default function NotEnrolled () {
  const { whatsNextUpdate } = config

  return (
    <Container>
      {!whatsNextUpdate &&
        <div>
          You’re not enrolled in any courses yet!{' '}
          <a href='https://outlier.org'>
            Browse our course catalog at Outlier.org.
          </a>
        </div>}
      <div> If you don’t see your course within 24 hours of purchase, please email <a href='mailto:success@outlier.org'>success@outlier.org</a>.</div>
    </Container>
  )
}
