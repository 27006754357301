import { useCallback, useMemo } from 'react'
import useOverridableState from '../overridableStateHook/overridableStateHook'

const useLocalStorage = ({ identifier, initialValue }) => {
  const fullIdentifier = useMemo(() => `OUTLIER_${identifier}`, [identifier])

  const [value, setValue] = useOverridableState(
    useCallback(() => {
      const storedValue = localStorage.getItem(fullIdentifier)
      return storedValue ?? initialValue
    // eslint-disable-next-line
    }, [fullIdentifier])
  )

  const setterCallback = useCallback((value) => {
    setValue(value)
    localStorage.setItem(fullIdentifier, value)
    // eslint-disable-next-line
  }, [fullIdentifier]);

  return [value, setterCallback]
}

export default useLocalStorage
