export const ACTIVE_LEARNING_COMPLETE = 'active-learning-complete'
export const QUIZ_SECTION = 'quiz-section'
export const QUIZ_COMPLETE = 'quiz-complete'
export const READINGS_PROGRESS = 'reading-progress'
export const READINGS_COMPLETE = 'reading-complete'
export const ASSIGNMENT_PROGRESS = 'assignment-progress'
export const PRACTICE_EXERCISES_COMPLETE = 'practice-exercises-complete'
export const PRACTICE_TERM_COMPLETE = 'practice-term-complete'
export const ACTIVE_LEARNING = 'active_learning'
export const GUESSWORK = 'guesswork'
export const CALCULUS_LECTURE_VIDEOS = 'lecture'
export const LECTURE_VIDEOS = 'multi_lecture_videos'
export const LECTURE_COMPLETE = 'lecture-complete'
export const PRACTICE_EXERCISES = 'practice_exercises'
export const PRACTICE_TERMS = 'practice_terms'
export const QUIZ = 'quiz'
export const EXAM_COMPLETE = 'exam-complete'
export const READINGS = 'readings'
export const CONCEPT_MAP = 'conceptMap'
export const LECTURE = 'lecture'
export const PRACTICE_SETS = 'Practice Sets'
export const MULTI_LECTURE_VIDEOS = 'multi_lecture_videos'
export const PRACTICE_TERM = 'practice_terms'
export const PROBLEM_BANK = 'problem_bank'
export const LAST_ACTIVE_LEARNING_UUID = 'last-active-learning-uuid'
export const CONCEPT_MAP_COMPLETE = 'concept-map-complete'
export const STUDENT_ANSWERS = 'studentAnswers'

export const CHECKLIST_INTRO_VIEWED = 'checklist-intro-viewed'
