import styled from 'styled-components'
import mediaqueries from '../../../../mediaQueries'
import { Button as BootstrapButton } from 'react-bootstrap'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 15px;
  width: 600px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;

  ${mediaqueries.mobile`
    width: 525px;
  `}

  ${mediaqueries.mobile`
    width: 324px;
  `}

  @media (max-width: 320px) {
    width: 250px;
  }
`

export const StyledQuestionTextWrapper = styled.div`
  display: flex;
  align-self: flex-start;
`

export const StyledQuestionText = styled.span`
  line-height: 19.2px;
  font-size: 16px;
  margin-left: 12px;
  font-weight: 400;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Button = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;

  & > img {
    height: 22px;
    width: 22px;
    margin-bottom: 13px;
    cursor: pointer;
  }

  & > p {
    margin: 0;
    font-size: 15px;
    line-height: 17px;
    font-weight: 700;
    cursor: pointer;
  }
`

export const Desmos = styled.div`
  display: flex;
  flex-direction: column;

  & > div:first-child {
    height: 330px;
    width: 100%;
  }
`

export const GraphNote = styled.p`
  content: '';
  min-width: 100%;
  height: 41px;
  padding: 12px;
  background-color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;

  &:before {
    content: 'Use cursor to interact with graph';
    ${mediaqueries.tablet`
        content: 'Use two fingers to interact with graph';
    `}
  }
`

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-top: 30px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
`

export const QuestionTextContainer = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;

  & > div:first-child {
    flex: 1 !important;
  }

  & div#react-mathjax-preview {
    flex: 1;
    margin-left: ${props => props.expression ? '30px' : ''};
    margin-bottom: 30px;
  }
`

export const Separator = styled.hr`
  width: 100%;
  border-bottom: 1px solid rgba(233, 233, 233, 0.21);
  margin-top: 16px;
  margin-bottom: 16px;
`

export const StyledOptionWrapper = styled.div`
 display: flex;
 align-items: center;
 gap: 12px;
`

export const StyledOption = styled.div`
  flex-grow: 1;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  display: block;
  position: relative;
  background-color: ${props => props.isEyeOpened ? 'none' : '#0a2a2d'};
  ${props => props.checked && 'border: 1px solid #fff !important;'}
  ${props => props.isEyeOpened && 'border: 1px solid rgba(255, 255, 255, 0.1);'}
  ${props => props.isIncorrectAnswer && 'border: 1px solid #FFA463 !important;'}
  border-radius: 5px;

  ${props => !props.answered && !props.isEyeOpened && (
    `&:hover {
      background-color: #445052;
    }`
  )}

  & > input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: ${props => props.answered || props.isEyeOpened ? 'default' : 'pointer'};
  }

  & > label {
    margin-bottom: 0;
  }

  & > img {
    height: 30px;
    position: absolute;
    right: 7px;
    top: 6px;
    filter: invert(1)
  }
`

export const OptionIcon = styled.img`
  cursor: ${props => props.answered ? 'default' : 'pointer'};
`

export const StyledLabel = styled.div`
  width: 100%;
  padding: 24px 12px;
`

export const StyledCorrectAnswerText = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 6px;
  letter-spacing: 1px;
  font-weight: bold;
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 32px;

  .btn-secondary:disabled {
    background-color: #161618;
    border-color: #77b1af;
    color: #77b1af;
    cursor: default !important;
  }

  .btn-primary:focus {
    background-color: #5fc4b8 !important;
    color: #161618 !important;
    border-color: #5fc4b8 !important;
    opacity: 1;
  }

  .btn-secondary:focus {
    background-color: #000 !important;
    color: #5fc4b8 !important;
    border-color: #5fc4b8 !important;
    opacity: 1;
  }
`

const buttonStyle = `
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: 16px;
  padding-left: 24px;
  padding-right: 24px;
`

export const PrimaryButton = styled(BootstrapButton)`
  ${buttonStyle}
  background-color: #77B1AF;
  color: #161618;
  border: none;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '40px'};

  ${mediaqueries.mobile`
    width: 100%;
  `}

  &:active {
    background-color: #77B1AF !important;
    color: #161618 !important;
    border: 0 none !important;
  }

  &:focus {
    outline: 0;
    background-color: #77B1AF;
    color: #161618;
    border: none;
    box-shadow: unset;
    opacity: 1;
  }

  &:hover {
    background-color: #77B1AF;
    color: #161618;
    border: none;
  }

  &:disabled {
    color: #212529;
    background-color: #78BCB8;
    border-color: #78BCB8;
    pointer-events: none;
  }
`

export const SecondaryButton = styled(BootstrapButton)`
${buttonStyle}
  background-color: #1D1D1F;
  color: #77B1AF;
  border: 3px solid #77B1AF;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || '40px'};

  ${mediaqueries.mobile`
    width: 100%;
  `}

  &:active {
    background-color: #1D1D1F !important;
    color: #77B1AF !important;
    border: 3px solid #77B1AF !important;
  }

  &:focus {
    outline: 0;
    background-color: #1D1D1F;
    color: #77B1AF;
    border: 3px solid #77B1AF;
    box-shadow: unset;
    opacity: 1;
  }

  &:hover {
    color: #77B1AF;
    background-color: #1D1D1F;
    border: 3px solid #77B1AF;
  }

  &:disabled {
    background-color: #161618;
    border-color: #77b1af;
    color: #77b1af;
    pointer-events: none;
  }
`

export const FeedbackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;

  & > img {
    width: 20px;
    height: 20px;
  }

  & > span {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-left: 12px;
  }
`
