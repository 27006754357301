import { getLatestCohort, sortCoursesByStartDate } from './course'
import { getCertificateStatus } from '../Components/AchievementsTracker/utils'

/**
 * @param {Array} certificates
 * @returns returns an array of certificates that has courses or an empty array
 */
export const filterCertificates = (certificates = []) => {
  return certificates?.filter((certificate = {}) => {
    return certificate?.courses?.length !== 0
  })
}

export const getIsPurchasedCertificate = (certificates = []) => {
  const noOfEnrolledCertificates = certificates?.filter(certificate => {
    const certificateStatus = getCertificateStatus(certificate)
    return certificateStatus !== 'notEnrolled'
  }).length

  return noOfEnrolledCertificates > 0
}

/**
 * @param {Array} certificates
 * @returns {Array} of certificates that the student have purchased
 */
export const getPurchasedCertificates = (certificates = []) => {
  if (!certificates?.length) return []

  const purchasedCertificates = certificates?.filter((certificate = {}) => {
    return certificate?.isPurchased
  })

  return purchasedCertificates
}

/**
 * @param {Object}
 * @returns {Object} certificate corresponding to the relationshipName
 * @description The latest cohort for the certificate courses are obtained
 * from the student courses array. Those latest cohort values are added to the
 * cohort field in each of the certificate courses
 */

export const getCertificateWithPartnerDetails = params => {
  const { certificates, courses } = params || {}
  if (!certificates?.length || !courses?.length) return null

  const purchasedCertificates = getPurchasedCertificates(certificates)
  if (!purchasedCertificates?.length) return null

  const partnerCertificate = getPartnerCertificate(params)
  if (!partnerCertificate) return null

  partnerCertificate.courses = partnerCertificate.courses?.map(course => {
    const assignedCourse = courses.find(studentCourse => studentCourse.id === course.id)
    if (!assignedCourse) return course

    const latestCohort = getLatestCohort(assignedCourse)
    if (!latestCohort) return course

    course.cohort = { ...course.cohort, ...latestCohort }
    return course
  })

  return partnerCertificate
}

export const getPartnerCertificate = params => {
  const { certificates, courses, relationshipName } = params || {}
  if (!certificates?.length || !courses?.length || !relationshipName) return null

  const partnerCertificate = certificates.find(certificate => {
    const areAllPartnerCourses = certificate.courses.every(course => {
      const assignedCourse = courses.find(studentCourse => studentCourse.id === course.id)
      if (!assignedCourse) return false

      const latestCohort = getLatestCohort(assignedCourse)
      return latestCohort?.relationship?.fields?.relationshipName === relationshipName
    })
    return areAllPartnerCourses
  })

  return partnerCertificate
}

export const getCourseOrderInCertificate = params => {
  const { certificate, courseId, cohortId } = params || {}
  if (!certificate || !courseId || !cohortId) return -1

  const sortedCourses = sortCoursesByStartDate(certificate.courses || [])
  return sortedCourses.findIndex(course => {
    return course.id === courseId && course.cohort.cohortId === cohortId
  })
}

export const getFirstCertificateCohort = certificate => {
  if (!certificate) return null

  const sortedCourses = sortCoursesByStartDate(certificate.courses || [])
  return sortedCourses?.[0]?.cohort || null
}
