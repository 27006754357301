import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { ContinueButton, PageTitle, PageWrapper, SectionTitle } from './styles'
import RadioInput from './RadioInput'
import { AppContext } from '../ContextProvider/ContextProvider'
import api from '../../api'
import {
  setVisitedPages,
  getNextPage,
  removeRecommendedCoursesInLS,
  clearVisitedPages,
  setFiredSegmentEventsInLS,
  isSegmentEventInLS,
  removeSegmentEventFromLS
} from '../../utilities/gguDegreeUtils'
import ReturnToDashboard from './ReturnToDashboard'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import {
  COURSE_REGISTRATION_PAGEVIEW,
  COURSE_REGISTRATION_PAGE_SUBMITTED
} from '../../Constants/eventType'

const segmentEvent = {
  eventName: COURSE_REGISTRATION_PAGEVIEW,
  pageNumber: '1-1'
}

const StudyMode = ({ setIsLoading, showReturnToDashboard }) => {
  const { sendEvent } = useSegmentEvents()
  const { prospects, studentData, updateContext } = useContext(AppContext)
  const currentProspect = prospects[0]
  const [studyMode, setStudyMode] = useState(currentProspect.studyMode)
  const history = useHistory()
  const {
    pathname,
    state: { overrideVisitedPage } = {}
  } = useLocation()

  useEffect(() => {
    if (isSegmentEventInLS(segmentEvent)) return

    sendEvent({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      properties: {
        page_name: 'Start',
        page_number: '1-1'
      }
    })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (overrideVisitedPage) return

    const nextPage = getNextPage(pathname)
    if (!nextPage) return

    history.push(nextPage)
    // eslint-disable-next-line
  }, [])

  const updateStudyMode = async (e) => {
    setIsLoading(true)
    const result = await api.updateProspectData(currentProspect.prospectId, { studyMode })
    setIsLoading(false)

    if (!isEmpty(result)) {
      prospects[0] = { ...currentProspect, studyMode }
      updateContext({ prospects: [...prospects] })
      clearVisitedPages()
      setVisitedPages(pathname)
      removeRecommendedCoursesInLS(true)
      sendEvent({
        eventName: COURSE_REGISTRATION_PAGE_SUBMITTED,
        properties: {
          page_name: 'Start',
          page_number: '1-1',
          time_commitment: studyMode
        }
      })
      history.push('/expected-graduation')
      removeSegmentEventFromLS(segmentEvent)
    }
  }

  return (
    <PageWrapper>
      <PageTitle className='mb-2'>Hey, {studentData?.preferredName}</PageTitle>
      <SectionTitle className='mt-1 mb-4'>
        Let's get you registered for your upcoming semester!
      </SectionTitle>
      <SectionTitle className='mt-1 mb-4'>
        Please select whether you will be a full-time or part-time student this semester.
      </SectionTitle>
      <RadioInput
        className='mt-4' name='study-mode' label='Full-time' value='Full-time' checked={studyMode === 'Full-time'}
        onChange={e => setStudyMode(e.target.value)}
      >
        If you take courses full-time (3 courses per term), you can expect to earn your associate degree in around 2
        years.
      </RadioInput>
      <RadioInput
        className='mt-4' name='study-mode' label='Part-time' value='Part-time' checked={studyMode === 'Part-time'}
        onChange={e => setStudyMode(e.target.value)}
      >
        If you take courses part-time (1-2 courses per term), you can expect to earn your associate degree in around 4
        years. This may also affect the financial aid you will receive.
      </RadioInput>
      <ContinueButton className='mt-5' disabled={!studyMode} onClick={updateStudyMode}>Get Started</ContinueButton>
      {showReturnToDashboard && (
        <ReturnToDashboard updateContext={updateContext} />
      )}
    </PageWrapper>
  )
}

export default StudyMode
