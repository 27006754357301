import React from 'react'
import PropTypes from 'prop-types'
import { Content } from './style'
import { Button } from '../AddTokens/style'
import { Description } from '../OnboardingForm/style'
import { Header } from '../Tokens/style'
import { pretestPageStates } from '../../Constants/pretest'

const Introduction = ({ courseName, pretest, setCurrentState }) => {
  const { pretestLockoutDays } = pretest || {}

  return (
    <Content>
      <div>
        <Header>
            The {courseName} Pretest
        </Header>
        <Description>
            This pretest helps us better serve students. You can take it once
            every {pretestLockoutDays} days. (If results show that your skills
            may not be a fit for our graded {courseName} course yet, don't
            worry: we've got some great options for you.)
        </Description>
        <Button
          onClick={() => setCurrentState(pretestPageStates.TEST)}
          width='137px'
          data-testid='btn-take-pretest'
        >
           take the test
        </Button>
      </div>
    </Content>
  )
}

Introduction.displayName = 'Introduction'

Introduction.propTypes = {
  courseName: PropTypes.string,
  pretest: PropTypes.object,
  setCurrentState: PropTypes.func
}

export default Introduction
