import styled from 'styled-components'
import media from '../../../mediaQueries'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 112px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 48px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  ${media.mobile`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`

export const HeaderTitle = styled.h1`
  font-size: 36px;
  font-weight: 300;
  line-height: 42px;
`

export const HeaderText = styled.span`
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  text-align: right;
  color: #B1BFC5;
  text-transform: capitalize;
`

export const VideoPlayer = styled.div`
  width: 100%;
  height: 480px;

  ${media.tablet`
    height: 400px;
  `}

  ${media.mobile`
    height: 300px;
  `}

  ${media.smallMobile`
    height: 200px;
  `}
`
