import styled from 'styled-components'

export const NavBarItemSelect = styled.div`
  max-width: 250px;
  width: 170px;
  font-size: 15px;  
  margin-right: 20px;
  @media (max-width: 480px) {
    margin-right: 15px;
    width: 100px;
  }
  @media (max-width: 576px) {
    margin-right: 15px;
    width: 102px;
  }
`
