import React, { useState } from 'react'
import api from '../../../api'
import utils from '../../../utilities'
import { Spinner } from 'react-bootstrap'
import { EDIT_EMAIL_MODES } from '../../../Constants/accountArea'
import { ErrorMessage, InputField, InputLabel, SaveButton } from '../style'

const { CODE_VERIFICATION } = EDIT_EMAIL_MODES

function ChangeEmail (props) {
  const {
    userEmail, newEmail, setNewEmail, password, setPassword, setCurrentMode
  } = props

  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [serverError, setServerError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleUpdatedEmailChange = e => {
    setEmailError(false)
    setServerError(false)
    setNewEmail(e.target.value)
  }

  const handlePasswordChange = e => {
    setPasswordError(false)
    setPassword(e.target.value)
  }

  const areValidInputs = async () => {
    const isValidEmail = utils.validateEmail(newEmail)
    if (!isValidEmail) {
      setEmailError(true)
      return false
    }

    if (!password) {
      setPasswordError(true)
      return false
    }

    const result = await api.verifyPassword(userEmail, password)
    const { access_token: token } = result || {}
    const isValidPassword = !!token

    setPasswordError(!isValidPassword)
    return isValidPassword
  }

  const verifyEmailAndSendCode = async () => {
    setServerError('')
    setIsLoading(true)
    const validInputs = await areValidInputs()
    setIsLoading(false)
    if (!validInputs) return

    setIsLoading(true)
    const result = await api.verifyEmailAndSendCode({ newEmail })
    setIsLoading(false)
    const { success, message } = result || {}
    if (!success) return setServerError(message)

    setCurrentMode(CODE_VERIFICATION)
  }

  return (
    <>
      <InputLabel>Updated email</InputLabel>
      <InputField
        autoFocus
        type='email'
        value={newEmail}
        disabled={isLoading}
        className='fs-exclude'
        hasError={emailError || serverError}
        onChange={handleUpdatedEmailChange}
        data-testid='updated-email-input'
      />
      {serverError && (
        <ErrorMessage>{serverError}</ErrorMessage>
      )}
      {emailError && (
        <ErrorMessage>Please enter a valid email.</ErrorMessage>
      )}

      <InputLabel>Current password</InputLabel>
      <InputField
        type='password'
        value={password}
        disabled={isLoading}
        className='fs-exclude'
        hasError={passwordError}
        onChange={handlePasswordChange}
        data-testid='current-password-input'
      />
      {passwordError && (
        <ErrorMessage lastItem>
          Please enter the password you use to log in.
        </ErrorMessage>
      )}

      <SaveButton
        disabled={isLoading}
        onClick={verifyEmailAndSendCode}
        data-testid='verify-email-button'
      >
        {isLoading ? <Spinner animation='border' /> : 'save'}
      </SaveButton>
    </>
  )
}

export default ChangeEmail
