import React, { useContext } from 'react'
import config from '../../config'
import arrow from '../../assets/icons/arrow.svg'

import {
  ExtensionLinkIcon,
  ExtensionStateMessage,
  ExtensionIcon,
  ExtensionText,
  ExtensionStateWrapper,
  ExtensionExtendedText,
  ExtensionTextWrapper,
  ExtensionDeadlineTextWrapper
} from './style'
import {
  dateToSecondsSinceEpoch,
  diffDays,
  getISODateTimeString,
  secondsSinceEpoch,
  secondsToDateString
} from '../../utilities/dateTime'
import { AppContext } from '../ContextProvider/ContextProvider'
import { getLatestCohort } from '../../utilities/course'
import { ASSIGNMENT, EXAM } from '../../Constants/examType'
import {
  getCohortExamDates,
  getExamDatesFromCohortExamDates
} from '../../utilities/cohort'

export const THEME = {
  DANGER: 'danger',
  WARNING: 'warning'
}

export const currentTime = secondsSinceEpoch()
export const ONE_DAY = 24 * 60 * 60
export const THREE_DAYS = 3 * ONE_DAY

export const processExtensionData = (
  endTime,
  currentEndTime,
  examUuid,
  courseId,
  isLiveProctoringExam
) => {
  if (isLiveProctoringExam) return

  const timeDiff =
    dateToSecondsSinceEpoch(new Date(currentEndTime)) - currentTime
  // if End time > 3 days
  if (timeDiff > THREE_DAYS) return

  // if End time has passed
  if (timeDiff < 0) return

  const lessThanOneDayLeft = timeDiff < ONE_DAY
  const lessThanThreeDaysLeft = timeDiff < THREE_DAYS

  const theme = lessThanOneDayLeft ? THEME.DANGER : THEME.WARNING

  const link = lessThanThreeDaysLeft
    ? `${config.courseBaseUrlById(courseId)}/#/extension-request/` +
       examUuid
    : ''
  const isExtended = diffDays(
    dateToSecondsSinceEpoch(new Date(endTime)),
    dateToSecondsSinceEpoch(new Date(currentEndTime))
  ) > 0

  return {
    isExtended,
    link,
    theme
  }
}

const ExtensionState = ({
  exam,
  course,
  type
}) => {
  let examText = null
  let extensionData = null

  const { cohortOriginalDeadlines = [] } = useContext(AppContext) || {}
  if (!cohortOriginalDeadlines.length) return null

  const { id: courseId, vip } = course
  if (vip) return null

  const latestCohort = getLatestCohort(course)
  const currentCohortOriginalDeadline =
    cohortOriginalDeadlines.find(cohort => cohort?.id === latestCohort?.id)
  if (!currentCohortOriginalDeadline) return null

  const {
    relationship: { fields: { liveProctoring } = {} } = {}
  } = latestCohort || {}

  if (type === EXAM) {
    examText = 'Exam'

    const latestCohortExamDates = getCohortExamDates(latestCohort)
    const {
      startDate,
      endDate
    } = getExamDatesFromCohortExamDates({
      exam,
      cohortExamDates: latestCohortExamDates
    })

    if (startDate && endDate) {
      const endTime = getISODateTimeString(endDate)

      const originalCohortExamDates = getCohortExamDates(currentCohortOriginalDeadline)
      const {
        endDate: originalEndDate
      } = getExamDatesFromCohortExamDates({
        exam,
        cohortExamDates: originalCohortExamDates
      })

      const originalEndTime = getISODateTimeString(originalEndDate)

      extensionData = processExtensionData(
        originalEndTime,
        endTime,
        exam.chapter_uuid,
        courseId,
        liveProctoring
      )
    }
  }

  if (type === ASSIGNMENT) {
    examText =
      exam.title === 'Final Essay'
        ? 'Final Essay'
        : 'Assignment'

    const {
      id: assignmentId,
      chapter_uuid: assignmentUuid,
      lockTime: currentEndTime
    } = exam

    const { cohortMilestones: cohortOriginalMilestones } =
  currentCohortOriginalDeadline || {}
    const assignmentOriginalDeadline =
  cohortOriginalMilestones.find(milestone => milestone.id === assignmentId)
    const { lockTime: endTime } = assignmentOriginalDeadline
    extensionData = processExtensionData(
      endTime,
      secondsToDateString(currentEndTime),
      assignmentUuid,
      courseId
    )
  }

  if (extensionData) {
    const { isExtended, link, theme } = extensionData
    return (
      <ExtensionStateWrapper
        data-testid='extension-state'
        theme={theme}
        className='d-flex align-items-center justify-content-sm-between
         justify-content-md-start'
      >
        <ExtensionStateMessage>
          <ExtensionIcon theme={theme} />
          <ExtensionTextWrapper>
            <ExtensionText>
              {examText} closing soon!
            </ExtensionText>
            {link && !isExtended &&
              <ExtensionDeadlineTextWrapper>
                <a href={link}>Extend Deadline</a>
                <ExtensionLinkIcon src={arrow} />
              </ExtensionDeadlineTextWrapper>}
            {isExtended &&
              <ExtensionExtendedText theme={theme}>
              Deadline extended
              </ExtensionExtendedText>}
          </ExtensionTextWrapper>
        </ExtensionStateMessage>
      </ExtensionStateWrapper>
    )
  }
  return null
}

export default ExtensionState
