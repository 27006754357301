import styled from 'styled-components'

export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #25272B;
  padding: 24px;
  margin-top: 64px !important;
  margin-bottom: -64px !important;
`

export const Message = styled.p`
  font-size: 16px;
  line-height: 19px;
  color: white;
  font-weight: 400;
  text-align: center;
  margin: 0px;

  span {
    color: #5FC4B8;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;
  }
`
