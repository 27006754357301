import React from 'react'
// import PropTypes from 'prop-types'

const FreeForm = () => {
  return (
    <>Free Form</>
  )
}

FreeForm.displayName = 'FreeForm'

FreeForm.propTypes = {}

export default FreeForm
