import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import { SecondaryButton } from '../style'

export const StudyBlocksContainer = styled.section`
  padding: 0 var(--desktop-layout-padding);
  margin-bottom: 64px;

  ${mediaqueries.mobile`
    padding: 0;
  `}
`
export const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 22px 32px;
  width: 256px;
  height: 264px;
  border-radius: 4px;
  background-color: ${({ isToday }) => (isToday ? '#000000' : '#111114')};

  ${mediaqueries.mobile`
    width: 224px;
    height: 240px;
    padding: 16px 24px 32px;
  `}
`
export const DayHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const WeekDay = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;

  ${mediaqueries.mobile`
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
  `}
`
export const StudyBlocksSlideContainer = styled.div`
  margin-top: 24px;
  & .splide__track {
    margin: 0 calc(var(--mobile-layout-padding) * -1);
  }
`
export const SessionsContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const Session = styled.li`
  display: flex;
  align-items: center;
  gap: 24px;
`
export const SessionIcon = styled.span`
  display: flex;
  width: 16px;
  height: 16px;
`
export const SessionTime = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0px;
  color: ${({ isActive }) => (isActive ? '#ffffff' : '#bababb')};

  ${mediaqueries.mobile`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
  `}
`
export const TodayText = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 2px;
  color: #bababb;
  margin-right: 10px;

  ${mediaqueries.mobile`
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1.7px;
    margin-right: 0;
  `}
`
export const ActiveSessionIcon = styled.span`
  border-radius: 50%;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
`
export const UpcomingSessionIcon = styled.span`
  border-radius: 50%;
  border: 1px solid #bababb;
  width: 100%;
  height: 100%;
`
export const NoStudyBlocksContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 24px;
  gap: 8px;
  overflow: hidden;

  ${mediaqueries.mobile`
    margin: 24px calc(var(--mobile-layout-padding)* -1) 0;
    padding: 0 var(--mobile-layout-padding);
  `}
`
export const SkeletonTile = styled.div`
  --skeleton-width: 256px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: var(--skeleton-width);
  height: 264px;
  padding: 40px 32px;
  border-radius: 4px;
  background-color: #18181c;

  ${mediaqueries.mobile`
    transform: translateX(calc(var(--skeleton-width) * -1));
  `}

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 34px;
  }

  & li {
    display: flex;
    align-items: center;
    gap: 24px;
  }
`
export const SkeletonLine = styled.div`
  width: ${({ skeletonWidth }) => skeletonWidth || '100%'};
  height: 6px;
  border-radius: 3px;
  background-color: #27292f;
`
export const SkeletonCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #27292f;
`
export const NoStudyBlocksMessage = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  & p {
    font-size: 23px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    color: #bababb;
    margin: 0;
    text-align: center;

    ${mediaqueries.mobile`
      font-size: 17px;
      line-height: 24px;
      width: 175px;
    `}
  }
`
export const GetStartedButton = styled(SecondaryButton)`
  height: 48px;
  padding: 16px 24px;
  border-radius: 5px;
  line-height: 16px;

  &:hover {
    text-decoration: none;
  }
`
export const StudyBlocksMessage = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  margin-top: 24px;
  margin-bottom: 0;
  color: #bababb;

  ${mediaqueries.mobile`
    font-size: 13px;
    margin-top: 13px;
  `}
`
