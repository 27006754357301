import config from '../config'
import { levelUpCourses, recommendedCourses } from '../Constants/enrollment'
import { getLatestCohort } from './course'
import { AUDIT, COMPLETED } from '../Constants/studentStatus'

const ENROLL = 'enroll'

export const getOptionalCourses = (courses, courseName, studentCourses) => {
  const coursesNotTaken = courses.map(courseId => {
    const currentCourse = studentCourses.find(course => course.id === courseId)

    const isInputCourse = config.getCourseIds()[courseName].includes(courseId)

    const enrollmentType = isInputCourse ? AUDIT : ENROLL

    if (!currentCourse) return { id: courseId, type: enrollmentType }

    const latestCohort = getLatestCohort(currentCourse)
    if (!latestCohort) return { id: courseId, type: enrollmentType }

    const { studentStatus } = latestCohort
    if (studentStatus === COMPLETED) return null

    return { id: courseId, type: ENROLL }
  }).filter(course => !!course)

  return coursesNotTaken
}

export const getLevelUpCourses = (courseName, studentCourses) => {
  const courses = levelUpCourses[courseName]
  if (!courses) return []

  return getOptionalCourses(courses, courseName, studentCourses)
}

export const getRecommendedCourses = (courseName, studentCourses) => {
  const courses = recommendedCourses[courseName]
  if (!courses) return []

  return getOptionalCourses(courses, courseName, studentCourses)
}

export const getAlternativeCoursesDetails = (courses, activeCourses) => {
  if (!courses?.length) return []

  const filteredCourses = courses?.reduce((acc, currentCourse) => {
    const { id, type } = currentCourse
    const courseWithDetails = activeCourses.find(course => course.id === id)
    if (!courseWithDetails) return acc

    acc.push({ ...courseWithDetails, type })
    return acc
  }, [])

  return filteredCourses
}
