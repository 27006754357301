import React from 'react'
import {
  ModalWrapper,
  Modal,
  ModalHeader,
  Close,
  ModalBody,
  ConfirmationStatement,
  ReadPolicyLink
} from './style'

export default function RelationshipModal ({ relationshipType, showModal }) {
  const content = [
    {
      title: 'Why do I need to select a course for my scholarship?',
      description: `We want to make sure that you, as a scholarship recipient, 
      are getting the support you need throughout your course. 
      We also have slightly different policies for our scholarship students, 
      which you can read more about on.`
    },
    {
      title: 'Why do I need to select a course?',
      description: `We want to make sure that you, as a partner student, 
      are getting the support you need throughout your course. 
      We may also have slightly different policies for our partner 
      students depending on your organization.`
    }
  ]

  const { title, description } = content[
    relationshipType === 'scholarship' ? 0 : 1
  ]
  return (
    <ModalWrapper>
      <Modal>
        <ModalHeader>
          <h1>{title}</h1>
          <Close data-testid='close-btn' onClick={showModal} />
        </ModalHeader>
        <ModalBody>
          <ConfirmationStatement>
            <p>{description}</p>
          </ConfirmationStatement>
          {relationshipType === 'scholarship' && (
            <ReadPolicyLink>read policy</ReadPolicyLink>
          )}
        </ModalBody>
      </Modal>
    </ModalWrapper>
  )
}
