import React, { useState, useEffect, useContext, useMemo, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import config from '../../config'
import { AppContext } from '../ContextProvider/ContextProvider'
import api from '../../api'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import Modals from './Modals'
import {
  getCohortsStartDates,
  getAvailableCohorts,
  filterCohorts
} from '../../utilities/cohort'
import { getActiveTokens } from '../../utilities/tokenUtils'
import {
  changePTDateToLocalTime,
  getDateStringWithYearLongMonth
} from '../../utilities/dateTime'
import DatePickerContainerWrapper from './DateComponent'
import AttemptRelatedStatesComponent from './AttemptRelatedStatesComponent'
import PretestRelatedStatesComponent from './PretestRelatedStatesComponent'
import GGUStudentStatusMessage from './GGUStudentStatusMessage'
import ToolTip from './Tooltip'
import CatalogVideo from './CatalogVideo'
import {
  PageWrapper,
  HeroImage,
  GradientOverlay,
  OverrideCSSProperties,
  InfoWrapper,
  CourseTitle,
  TokensInfoWrapper,
  AddCourseTitle,
  FormWrapper,
  StyledLabel,
  ContentWrapper,
  StyledDatePicker,
  CalendarDatePicker,
  EndDate,
  CostWrapper,
  SubmitButtonWrapper,
  Forms,
  BreadCrumbComponent,
  SyllabusLink,
  SemesterTerm, DetailsLinkIcon
} from './style'
import TokensAndCart from '../CourseCatalog/TokensAndCart'
import CatalogState from '../CourseState/CatalogState'
import {
  TYPE_TEXT,
  AUDITED_TEXT,
  DURATION_TEXT
} from './utilities/tooltipTexts'
import { cohortTypes } from '../../Constants/cohort'
import * as studentStatus from '../../Constants/studentStatus'
import { getSyllabusLink } from '../../utilities/course'

function CatalogDetailPage ({
  currentCourseNumber,
  courseQuantity,
  setShowConfirmChangeModal,
  setCurrentPage
}) {
  const params = qs.parse(window.location.search?.slice(1))
  const isRegistrationRedesign = params?.gguEnrollmentV2 === 'true'
  const [displayModal, setDisplayModal] = useState(false)
  const [date, setDate] = useState()
  const [kalturaScriptLoaded, setKalturaScriptLoaded] = useState(false)
  const [datePreviousValue, setDatePreviousValue] = useState(new Date())
  const [selectDateBtnClicked, setSelectDateBtnClicked] = useState(false)
  const [cohortStartDates, setCohortStartDates] = useState([])
  const [selectedCohort, setSelectedCohort] = useState()
  const [availableCohorts, setAvailableCohorts] = useState()
  const [courseData, setCourseData] = useState(undefined)
  const [isUpdate, setUpdate] = useState(true)
  const [showAlternateStates, setShowAlternateStates] = useState(false)
  const [showPretestStates, setShowPretestStates] = useState(true)
  const [studentAuditedCourse, setStudentAuditedCourse] = useState()
  // This is just to trigger component rerender. Whenever there is a need to rerender the component, toggle the state
  const [shouldRerender, setShouldRerender] = useState(false)
  const Local = useLocation()
  const { type: enrollmentType, directedFrom } = Local.state || {}
  const isAudit = enrollmentType === studentStatus.AUDIT
  const [formRadioCheckedStates, setFormRadioCheckedStates] = useState({
    enrollChecked: !isAudit,
    auditChecked: isAudit,
    standardChecked: true,
    intensiveChecked: false
  })
  const filteredCohorts = useRef()

  const {
    courseProjectedGrades,
    isContextLoading,
    isGGUDegreeDataLoading,
    isActiveGGUStudent,
    catalogCourses,
    outlierCourses,
    tokens,
    courses,
    studentData,
    studentAttempts,
    pretestAttempts,
    pretestOverrides,
    prospects,
    currentSemester,
    isGGUStudentEnrolled,
    degreeData,
    canStudentEnrollInUPJCourses
  } = useContext(AppContext)

  const availableTokens = useMemo(() => {
    return getActiveTokens(tokens)
  }, [tokens])

  const { instrideId } = studentData || {}

  const {
    configureKalturaPlayer,
    getKalturaSettings: { PARTNER_ID, UICONF_ID, scriptUrl }
  } = config

  const history = useHistory()

  const [, courseIdFromUrl] = Local.pathname.slice(1).split('/')
  const courseNameFromURLid = config.courseIdToName(courseIdFromUrl)
  const additionalIds =
    config.additionalCourseIds[courseNameFromURLid] ||
    [config.courseIds[courseNameFromURLid]]

  const {
    media: {
      heroImage: { url = '' } = {},
      trailerVideo,
      tileImage: { url: tileImageUrl } = {}
    } = {},
    longDescription: { descriptionText = '' } = {},
    courseName = '',
    marketingPageUrl = null,
    prerequisite,
    uuid: {
      uuid = null
    } = {},
    isProfCert
  } = useMemo(() => {
    const course = catalogCourses?.find(course => (
      course.uuid?.uuid === courseIdFromUrl
    ))
    const { profCert: isProfCert } = outlierCourses.find(outlierCourse => (
      outlierCourse?.id === courseIdFromUrl
    )) || {}
    if (course) { course.isProfCert = isProfCert }

    if (course?.media?.heroImage === null) course.media.heroImage = { }
    if (course?.longDescription === null) course.longDescription = { }
    if (course?.uuid === null) course.uuid = { }

    return course || {}
  }, [courseIdFromUrl, catalogCourses, outlierCourses])

  const [standardSyllabus, intensiveSyllabus] = useMemo(() => {
    return [
      getSyllabusLink(courseData?.syllabus),
      getSyllabusLink(courseData?.syllabus, 'intensive')
    ]
  }, [courseData])

  const {
    enrollChecked,
    auditChecked,
    standardChecked,
    intensiveChecked
  } = formRadioCheckedStates

  const { ENROLL, AUDIT, SHORT, LONG } = cohortTypes

  const isGGUCourse = degreeData?.some(course => (
    course?.courseId === courseIdFromUrl
  ))

  useEffect(() => {
    resetStates()
    if (catalogCourses.length) {
      if (!courseName) history.push('/catalog')

      const kalturaScriptIsLoaded = document.querySelector('#Kaltura-Script')

      setKalturaScriptLoaded(!!kalturaScriptIsLoaded)

      !isContextLoading &&
        !kalturaScriptIsLoaded &&
        appendKalturaScriptToBody(scriptUrl)
    }

    const courseSelected = catalogCourses?.find(course => (
      course.uuid?.uuid === courseIdFromUrl
    ))

    const isCourseActive = outlierCourses?.some(course => course?.id === uuid)
    const enrollableCohorts = getAvailableCohorts({
      courses: outlierCourses,
      courseSelected,
      prospects,
      currentSemester,
      isActiveGGUStudent
    })

    if (!isCourseActive) return history.push('/catalog')

    if (!isContextLoading &&
      !enrollableCohorts?.length &&
      !isActiveGGUStudent
    ) return history.push('/catalog')

    setAvailableCohorts(enrollableCohorts)
    window.scrollTo(0, 0)

    if (!uuid) return
    Promise.all([api.getCoursesDetails([uuid])])
      .then((resp) => {
        setCourseData(resp[0].get(uuid))
      })
      .catch(() => {
        setCourseData('Error')
      })
    // eslint-disable-next-line
  }, [window.location.href, isContextLoading])

  useEffect(() => {
    if (!isContextLoading && kalturaScriptLoaded) {
      configureKalturaPlayer(trailerVideo?.kalturaEmbedCode, PARTNER_ID, UICONF_ID)
    }
  })

  useEffect(() => {
    if (!availableCohorts?.length) return
    const criteria = [auditChecked, intensiveChecked]

    const cohorts = filterCohorts(availableCohorts, criteria)

    const cohortsStartDate = getCohortsStartDates(cohorts)
    filteredCohorts.current = cohorts
    setCohortStartDates(cohortsStartDate)
    setDate(cohortsStartDate && cohortsStartDate[0])

    // eslint-disable-next-line
  }, [formRadioCheckedStates, availableCohorts])

  useEffect(() => {
    const cohort = filteredCohorts.current?.find((cohort) => {
      return changePTDateToLocalTime(cohort.dateStart)
        ?.toLocaleDateString() === date?.toLocaleDateString()
    })

    setSelectedCohort(cohort)
  }, [date])

  function appendKalturaScriptToBody (url) {
    const KalturaScript = document.createElement('script')
    KalturaScript.type = 'text/javascript'
    KalturaScript.id = 'Kaltura-Script'
    KalturaScript.src = url

    KalturaScript.onload = () => {
      setKalturaScriptLoaded(true)
    }

    document.body.appendChild(KalturaScript)
  }

  function resetStates () {
    setCourseData(null)
    setShowAlternateStates(false)
    setShowPretestStates(true)
    setDate(null)
    setCohortStartDates(null)
  }

  function appendCohortToUserCart () {
    if (!selectedCohort) return
    let userCart = JSON.parse(localStorage.getItem('userCart')) || []

    const { dateStart, duration, id, finalExamEndTime, course: courseId } = selectedCohort
    const cohortObj = {
      dateStart,
      finalExamEndTime,
      name: courseName,
      duration: duration >= 14 ? LONG : SHORT,
      id: courseId || uuid,
      cohortId: id,
      hasPretestRequirement: showPretestStates,
      status: enrollChecked ? ENROLL : AUDIT
    }

    userCart = [...userCart, cohortObj]

    localStorage.setItem('userCart', JSON.stringify(userCart))
  }

  function submitHandler (event) {
    const { audit: { checked = false } = {} } = event.target.elements

    if (checked) return setDisplayModal(true)

    appendCohortToUserCart()
    history.push({
      pathname: '/catalog',
      state: { isSidebarOpen: true }
    })
  }

  function letsDoItClickHandler () {
    appendCohortToUserCart()
    history.push({
      pathname: '/catalog',
      state: { isSidebarOpen: true }
    })
  }

  function calendarClickHandler () {
    const portal = document.querySelector('.react-datepicker__portal')
    if (!portal) return

    portal.classList.add('display-portal')
    portal.classList.remove('hide-portal')
  }

  function closeDatePickerModal () {
    const portal = document.querySelector('.react-datepicker__portal')
    if (!portal) return

    portal.classList.add('hide-portal')
    portal.classList.remove('display-portal')
    setDate(datePreviousValue) // resets calendar date to previously selected value
  }

  function selectDateClickHandler () {
    const portal = document.querySelector('.react-datepicker__portal')
    if (!portal) return

    portal.classList.add('hide-portal')
    portal.classList.remove('display-portal')
    setSelectDateBtnClicked(true)
  }

  function rerenderComponent () {
    setShouldRerender(prevState => !prevState)
  }

  if (
    isContextLoading ||
    !courseProjectedGrades ||
    (isActiveGGUStudent && isGGUDegreeDataLoading) ||
    (!courseData && !isGGUCourse)
  ) return <LoadingSpinner />

  if (courseData === 'Error') return null

  const cohortEndTime = selectedCohort?.finalExamEndTime
  const formattedCohortEndTIme = cohortEndTime
    ? `Ends ${getDateStringWithYearLongMonth(cohortEndTime, 'short')}`
    : ' '

  const userCart = JSON.parse(localStorage.getItem('userCart')) || []

  const alreadySelected = userCart.find((item) => additionalIds.includes(item.id))

  const imgUrl = (canStudentEnrollInUPJCourses || isActiveGGUStudent) && url

  const hideCatalogAddSection = (showAlternateStates && !showPretestStates) ||
  !canStudentEnrollInUPJCourses

  const syllabusLink = standardChecked ? standardSyllabus : intensiveSyllabus
  const isGGUStudentView = isActiveGGUStudent && !canStudentEnrollInUPJCourses

  return (
    <>
      <OverrideCSSProperties displayModal={displayModal} />
      {displayModal && (
        <Modals
          letsDoItClickHandler={letsDoItClickHandler}
          goBackClickHandler={() => setDisplayModal(!displayModal)}
        />
      )}
      <PageWrapper>
        <HeroImage data-testid='hero-image' imgUrl={imgUrl}> {/* for smaller screens   */}
          <GradientOverlay />
        </HeroImage>
        <ContentWrapper
          imgUrl={imgUrl}
          trailerVideo={trailerVideo || tileImageUrl}
          showAlternateStates={showAlternateStates}
          isGGUStudentView={isGGUStudentView}
        >
          {!isRegistrationRedesign && (
            <BreadCrumbComponent
              path={directedFrom || '/catalog'}
              title={(isGGUStudentView && !isGGUStudentEnrolled) ? 'Back' : 'CATALOG'}
              search={Local.search}
            />
          )}
          <InfoWrapper
            isGGUStudentView={isGGUStudentView}
          >
            <CourseTitle
              isGGUStudentView={isGGUStudentView}
            >
              {courseName.replace(' - GGU', '')}
            </CourseTitle>
            {canStudentEnrollInUPJCourses &&
              <TokensInfoWrapper>
                <TokensAndCart
                  backToPageName={courseName}
                  tokens={availableTokens?.length || 0}
                  items={0}
                  isParentRerendered={shouldRerender}
                  rerenderParent={rerenderComponent}
                />
              </TokensInfoWrapper>}
            {isGGUStudentView &&
              <SemesterTerm>
                {currentSemester.termName || ''}
              </SemesterTerm>}
          </InfoWrapper>
          <AttemptRelatedStatesComponent
            history={history}
            studentCourses={courses}
            isActiveGGUStudent={isActiveGGUStudent}
            type='type-1'
            studentAttempts={studentAttempts}
            additionalIds={additionalIds}
            showPretestStates={showPretestStates}
            showAlternateStates={() => {
              setShowAlternateStates(true)
            }}
            cohortStartDates={cohortStartDates}
            prospects={prospects}
          />
          {(() => {
            if (isGGUStudentView && !showAlternateStates) {
              return (
                <GGUStudentStatusMessage
                  courseData={courseData}
                  courseId={uuid}
                  prerequisite={prerequisite}
                  courseName={courseName?.replace(' - GGU', '')}
                  currentSemester={currentSemester}
                  isRegistrationRedesign={isRegistrationRedesign}
                  setShowConfirmChangeModal={setShowConfirmChangeModal}
                  setCurrentPage={setCurrentPage}
                  currentCourseNumber={currentCourseNumber}
                  courseQuantity={courseQuantity}
                  hasTrailerVideo={!!trailerVideo}
                  isProfCert={isProfCert}
                />
              )
            }

            if (hideCatalogAddSection) return null

            if (isUpdate && alreadySelected) {
              return (
                <CatalogState
                  reset={() => setUpdate(false)}
                  isParentRerendered={shouldRerender}
                  rerenderParent={rerenderComponent}
                  additionalIds={additionalIds}
                />
              )
            }

            return (
              <>
                {!showAlternateStates && (
                  <AddCourseTitle>Add course</AddCourseTitle>
                )}
                <FormWrapper
                  onSubmit={(e) => {
                    e.preventDefault()
                    submitHandler(e)
                  }}
                >
                  <Forms
                    showPretestStates={showPretestStates}
                    showAlternateStates={showAlternateStates}
                  >
                    {!showAlternateStates && (
                      <div>
                        <h3>
                        Type{' '}
                          <ToolTip
                            dataTestId='type-tooltip'
                            placement='right'
                            tooltipText={
                              studentAuditedCourse ? AUDITED_TEXT : TYPE_TEXT
                            }
                          />
                        </h3>
                        <StyledLabel htmlFor='enroll'>
                        Enroll (For Credit)
                          <input
                            type='radio'
                            name='enrollment'
                            id='enroll'
                            checked={enrollChecked}
                            readOnly
                            onClick={() => {
                            // onEnrollRadioBtnClick()
                              setFormRadioCheckedStates((prevState) => ({
                                ...prevState,
                                enrollChecked: true,
                                auditChecked: false
                              }))
                            }}
                          />
                          <span />
                        </StyledLabel>
                        <StyledLabel htmlFor='audit'>
                        Audit (Ungraded)
                          <input
                            type='radio'
                            name='enrollment'
                            id='audit'
                            checked={auditChecked}
                            disabled={studentAuditedCourse}
                            readOnly
                            onClick={() => {
                              setFormRadioCheckedStates((prevState) => ({
                                ...prevState,
                                enrollChecked: false,
                                auditChecked: true
                              }))
                            }}
                          />
                          <span />
                        </StyledLabel>
                      </div>
                    )}
                    {((!showPretestStates || auditChecked) && !showAlternateStates) && (
                      <>
                        <div>
                          <h3>
                          Duration{' '}
                            <ToolTip
                              dataTestId='duration-tooltip'
                              placement='right'
                              wideTooltip
                              tooltipText={DURATION_TEXT}
                            />
                          </h3>
                          <StyledLabel htmlFor='standard'>
                          14 Weeks (Standard)
                            <input
                              type='radio'
                              name='duration'
                              id='standard'
                              checked={standardChecked}
                              readOnly
                              onClick={() => {
                                setFormRadioCheckedStates((prevState) => ({
                                  ...prevState,
                                  intensiveChecked: false,
                                  standardChecked: true
                                }))
                              }}
                            />
                            <span />
                          </StyledLabel>
                          <StyledLabel htmlFor='intensive'>
                          7 Weeks (Intensive)
                            <input
                              type='radio'
                              name='duration'
                              id='intensive'
                              checked={intensiveChecked}
                              readOnly
                              onClick={() => {
                                setFormRadioCheckedStates((prevState) => ({
                                  ...prevState,
                                  intensiveChecked: true,
                                  standardChecked: false
                                }))
                              }}
                            />
                            <span />
                          </StyledLabel>
                        </div>
                        <div>
                          <h3>Start date</h3>
                          <CalendarDatePicker
                            data-testid='calendar'
                            onClick={() => {
                              setDatePreviousValue(date) // registers current calendar date value when user clicks on calendar component
                              calendarClickHandler()
                            }}
                          >
                            <StyledDatePicker
                              name='date'
                              selected={date}
                              onChange={(date) => setDate(date)}
                              dateFormat='MMM dd, yyyy'
                              calendarContainer={DatePickerContainerWrapper(
                                selectDateClickHandler,
                                closeDatePickerModal
                              )}
                              disabled={!date}
                              dateFormatCalendar='MMM yyyy'
                              withPortal
                              shouldCloseOnSelect={false}
                              disabledKeyboardNavigation
                              includeDates={cohortStartDates}
                              onCalendarClose={() => {
                                if (!selectDateBtnClicked) {
                                  setDate(datePreviousValue) // resets calendar date to previously selected value
                                }
                                setSelectDateBtnClicked(false)
                              }}
                              placeholderText='No cohorts found'
                            />
                            <span />
                          </CalendarDatePicker>
                          <EndDate data-testid='end-date'>
                            {formattedCohortEndTIme}
                          </EndDate>
                        </div>
                      </>
                    )}
                  </Forms>
                  {((!showPretestStates || auditChecked) && !showAlternateStates) && (
                    <div style={{ marginTop: '0px' }}>
                      <SyllabusLink
                        disabled={!syllabusLink}
                        onClick={() => {
                          if (!syllabusLink) return
                          window.open(syllabusLink, '_blank', 'noopener,noreferrer')
                        }}
                      >
                        preview {standardChecked ? '14wk' : '7wk'} syllabus <DetailsLinkIcon />
                      </SyllabusLink>
                      {!instrideId && <CostWrapper>cost: 1 token</CostWrapper>}
                      <SubmitButtonWrapper>
                        <button disabled={!date} type='submit'>
                          {' '}
                        ADD COURSE
                        </button>
                      </SubmitButtonWrapper>
                    </div>
                  )}
                </FormWrapper>
                {showPretestStates && !auditChecked && (
                  <PretestRelatedStatesComponent
                    courseData={courseData}
                    studentAttempts={studentAttempts}
                    setStudentAuditedCourse={() => setStudentAuditedCourse(true)}
                    additionalIds={additionalIds}
                    courseName={courseName}
                    url={Local.pathname}
                    pretestAttempts={pretestAttempts}
                    pretestOverrides={pretestOverrides}
                    showPretestStates={(show) => {
                      setShowPretestStates(show)
                    }}
                    studentCourses={courses}
                    courseProjectedGrades={courseProjectedGrades}
                  />
                )}
              </>
            )
          })()}
        </ContentWrapper>
        {(trailerVideo || tileImageUrl) && (
          <CatalogVideo
            descriptionText={descriptionText}
            marketingPageUrl={marketingPageUrl}
            tileImage={tileImageUrl}
            trailerVideo={trailerVideo}
          />
        )}
      </PageWrapper>
    </>
  )
}

CatalogDetailPage.displayName = 'CatalogDetailPage'
export default CatalogDetailPage
