import qs from 'qs'

export default {
  getUTMProperties,
  filterObject,
  isClickable,
  getRandomIntegerWithExclusion,
  getRandomInteger,
  getDetailsPagePath,
  pluralizeWord,
  isTheSameEmail,
  validateEmail,
  validateInput,
  parseBooleanObject,
  appendKalturaScriptToBody,
  roundNumber,
  shuffleArray
}

/**
 * @param {Object} obj
 * @returns {Object} parsed boolean object from string or false
 */
function parseBooleanObject (obj) {
  if (!obj) return false
  const booleanObj = {}
  Object.entries(obj).forEach(([name, value]) => {
    booleanObj[name] = value.toLowerCase() === 'true'
  })

  return booleanObj
}

function filterObject (object = {}, filterFunction) {
  if (!object || typeof object !== 'object') return {}
  if (typeof filterFunction !== 'function') return object

  return Object.entries(object)
    .filter(filterFunction)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
}

function validateEmail (email) {
  const validEmailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return validEmailPattern.test(email)
}

function isTheSameEmail (email1, email2) {
  if (!email1 || !email2) return false

  const lowerCaseEmail1 = email1.toLowerCase()
  const lowerCaseEmail2 = email2.toLowerCase()

  if (lowerCaseEmail1 === lowerCaseEmail2) return true

  const isTheSamePrefix = lowerCaseEmail1.replace(/\+.*@/, '@') === lowerCaseEmail2.replace(/\+.*@/, '@')
  const isTheSameDomain = lowerCaseEmail1.split('@')[1] === lowerCaseEmail2.split('@')[1]
  return isTheSamePrefix && isTheSameDomain
}

/**
 * @param {String} input should not be empty or whitespace
 * @returns {boolean} indicating the validity of the provided input
 */
function validateInput (input) {
  const validPattern = /[^\s]/
  return validPattern.test(input)
}

function pluralizeWord (count, noun, suffix = 's') {
  if (count === 1) return `${count} ${noun}`
  if (suffix.length < 3) return `${count} ${noun}${count === 1 ? '' : suffix}`

  const word = `${noun.substr(0, noun.length - 1)}${suffix}`
  return `${count} ${word}`
}

function getDetailsPagePath () {
  const { hash } = window.location
  return hash.slice(1).substr(0, hash.lastIndexOf('/') - 1)
}

function getRandomInteger (maxLength) {
  if (isNaN(maxLength)) return 0

  return Math.floor(Math.random() * maxLength)
}

function getRandomIntegerWithExclusion (maxLength, numberToExclude) {
  if (!maxLength || isNaN(maxLength) || maxLength <= 1) return 0
  if (isNaN(numberToExclude) || numberToExclude < 0) return 0

  const randomNumber = getRandomInteger(maxLength)
  if (randomNumber !== numberToExclude) return randomNumber

  return getRandomIntegerWithExclusion(maxLength, numberToExclude)
}

function isClickable (element) {
  return element instanceof HTMLButtonElement ||
    element instanceof HTMLAnchorElement ||
    element.parentElement instanceof HTMLAnchorElement
}

function shuffleArray (array) {
  if (!Array.isArray(array)) return []

  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * array.length)
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }

  return array
}

function appendKalturaScriptToBody (url, callBack) {
  const KalturaScript = document.createElement('script')
  KalturaScript.type = 'text/javascript'
  KalturaScript.id = 'Kaltura-Script'
  KalturaScript.src = url

  KalturaScript.onload = () => {
    if (!callBack) return
    callBack()
  }

  document.body.appendChild(KalturaScript)
}

function getUTMProperties () {
  const allowedKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
  const params = qs.parse(window.location.search.slice(1))

  const utmPropertiesArray = Object.keys(params)
    .filter(key => allowedKeys.includes(key))
    .map(key => ([key, params[key]]))

  const utmProperties = utmPropertiesArray
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
  return utmProperties
}

function roundNumber (number, decimals = 2) {
  if (isNaN(number)) return
  if (isNaN(decimals)) return number

  const roundedNumber = Math.round((number + Number.EPSILON) * 10 ** decimals) / 10 ** decimals
  return roundedNumber
}
