import active from '../assets/icons/icon-new-progress-active.svg'
import empty from '../assets/icons/icon-new-progress-empty.svg'
import correct from '../assets/icons/icon-progress-correct.svg'
import incorrect from '../assets/icons/icon-progress-incorrect.svg'
import correctOptionIcon from '../assets/icons/icon-new-progress-correct.svg'
import incorrectOptionIcon from '../assets/icons/icon-new-progress-incorrect.svg'
import unattemptedOptionIcon from '../assets/icons/icon-new-progress-incorrect-unattempted.svg'
import eyeOpen from '../assets/icons/icon-eye-opened.svg'
import eyeClose from '../assets/icons/icon-eye-closed.svg'
import back from '../assets/icons/icon-previous.svg'
import next from '../assets/icons/icon-next.svg'
import nextEnabled from '../assets/icons/icon-next-enabled.svg'

export const BACK = 'back'
export const NEXT = 'next'
export const NEXT_ENABLED = 'next enabled'

export const progressButtonStates = {
  ACTIVE: 'active',
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
  CORRECT_OPTION: 'correct-option',
  INCORRECT_OPTION: 'incorrect-option',
  UNATTEMPTED_OPTION: 'unattempted-option',
  EYE_OPEN: 'eye open',
  EYE_CLOSED: 'eye closed',
  DEFAULT: 'default',
  EMPTY: 'empty'
}

export const pretestPageStates = {
  DEFAULT: 'intro',
  TEST: 'pretest',
  COMPLETE: 'complete'
}

export const questionTypes = {
  MULTIPLE_CHOICE: 'Multiple Choice',
  TRUE_FALSE: 'True False',
  FREE_FORM: 'Free Form',
  TEXT_BLANKS: 'Text Blanks'
}

const {
  ACTIVE,
  DEFAULT,
  EMPTY,
  CORRECT,
  INCORRECT,
  EYE_OPEN,
  EYE_CLOSED,
  CORRECT_OPTION,
  INCORRECT_OPTION,
  UNATTEMPTED_OPTION
} = progressButtonStates

export const imageClassMap = {
  [ACTIVE]: active,
  [DEFAULT]: empty,
  [EMPTY]: empty,
  [CORRECT]: correct,
  [INCORRECT]: incorrect,
  [CORRECT_OPTION]: correctOptionIcon,
  [INCORRECT_OPTION]: incorrectOptionIcon,
  [UNATTEMPTED_OPTION]: unattemptedOptionIcon,
  [EYE_OPEN]: eyeOpen,
  [EYE_CLOSED]: eyeClose,
  [BACK]: back,
  [NEXT]: next,
  [NEXT_ENABLED]: nextEnabled
}

export const pretestResult = {
  PASS: 'pass',
  FAIL: 'fail'
}
