const MESSAGE_LIST = [
  'Hi there',
  'Welcome',
  'Hey',
  'Salutations',
  'Greetings',
  'Good to see you',
  'Happy [day of the week]'
]
export default MESSAGE_LIST
