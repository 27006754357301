import React from 'react'
import { PrimaryButton } from './QuestionComponent/style'
import utils from '../../utilities'

const SeeOptionsButton = () => {
  const openOptions = () => {
    window.location.hash = utils.getDetailsPagePath() + '/alternatives'
  }

  return (
    <PrimaryButton
      data-testid='btn-see-options'
      onClick={openOptions}
      width='150px'
    >
      see options
    </PrimaryButton>
  )
}

SeeOptionsButton.displayName = 'SeeOptionsButton'

export default SeeOptionsButton
