import React from 'react'
import question from '../../../assets/icons/question.svg'
import people from '../../../assets/icons/people.svg'
import commerce from '../../../assets/icons/commerce.svg'
import plus from '../../../assets/icons/plus-circle.svg'
import { Link } from '../style'

export const getSectionsData = (prospects) => {
  const { advisorUrl } = prospects?.[0] || {}

  return [
    {
      title: 'Help & Contact',
      icon: question,
      description: (
        <>
          Here are some resources to help you throughout your Degrees+ experience.
          If you're not sure who to contact, learn more about your&nbsp;
          <Link onClick={() => window.open('https://help.outlier.org/hc/en-us/articles/12445355586580', '_blank')}>
            Degrees+ Support Ecosystem
          </Link>&nbsp;
          or ask&nbsp;
          <Link onClick={() => window.open('https://help.outlier.org/hc/en-us/requests/new', '_blank')}>
            Student Support
          </Link>&nbsp;
          to point you in the right direction.
        </>
      ),
      tiles: [
        {
          tileTitle: 'Outlier Help Center',
          tileDescription: 'Find answers to frequently asked questions.',
          link: 'https://help.outlier.org/hc/en-us/sections/9105155499028-Degrees-'
        },
        {
          tileTitle: 'Advisor Contact',
          tileDescription: 'Get in touch with your advisor.',
          link: advisorUrl
        },
        {
          tileTitle: 'Student support',
          tileDescription: 'Have a question? Submit a request here.',
          link: 'https://help.outlier.org/hc/en-us/requests/new'
        }
      ]
    },
    {
      title: 'Community & Services',
      icon: people,
      tiles: [
        {
          tileTitle: 'myggu',
          tileDescription: 'Visit the myGGU portal.',
          link: 'https://portal.ggu.edu/'
        },
        {
          tileTitle: 'Disability resources',
          tileDescription: 'Disability resources for students.',
          mailTo: 'gguds@ggu.edu'
        },
        {
          tileTitle: 'Student services',
          tileDescription: 'Review GGU’s Student Services.',
          link: 'https://www.ggu.edu/experience/student-services/degrees-plus/index.gsp'
        },
        {
          tileTitle: 'Undergraduate Studies Policies & Resources',
          tileDescription: 'Policies and resources for undergraduate students.',
          link: 'https://www.ggu.edu/undergraduate/policies-and-resources/'
        },
        {
          tileTitle: 'Virtual Campus',
          tileDescription: 'Connect with other GGU students.',
          link: 'https://discord.gg/XsAb4k7FBd'
        }
      ]
    },
    {
      title: 'Financial Aid & Payments',
      icon: commerce,
      tiles: [
        {
          tileTitle: 'financial aid office',
          tileDescription: 'Contact the Office of Financial Aid.',
          link: 'https://www.ggu.edu/admissions/undergraduate/costs-and-aid/financial-aid'
        },
        {
          tileTitle: 'financing options',
          tileDescription: 'Discover your payment options.',
          link: 'https://www.ggu.edu/admissions/graduate/costs-and-aid/tuition-and-fees/financing-options/'
        },
        {
          tileTitle: 'make a payment',
          tileDescription: 'Review and make payments.',
          link: 'https://colleaguess.ggu.edu/Student/Finance/Payments'
        }
      ]
    },
    {
      title: 'Orientation',
      id: 'orientation',
      icon: plus,
      tiles: [
        {
          tileTitle: 'part 1',
          tileDescription: 'Get to know the Degrees+ program.',
          link: '/ggu-getting-started/part-1',
          _self: true
        },
        {
          tileTitle: 'part 2',
          tileDescription: 'Learn how registration and courses work.',
          link: '/ggu-getting-started/part-2',
          _self: true
        },
        {
          tileTitle: 'part 3',
          tileDescription: 'Learn how to be successful in your Degrees+ courses.',
          link: '/ggu-getting-started/part-3',
          _self: true
        }
      ]
    }
  ]
}
