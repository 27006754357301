import React from 'react'
import config from '../../config'
import WidgetState from './WidgetState'
import { PROGRESS_TYPE } from '../../Constants/courseCard'
import { CONCEPT_MAP, PRACTICE_TERMS } from '../../Constants/sectionType'
import { getNextAvailableSection } from '../../utilities/section'

const PracticeTermsCard = ({
  course,
  courseProgress,
  chapterUuid,
  chapterNo,
  sectionNo,
  conceptMap
}) => {
  const { id } = course
  const { studentProgress, sectionData } = courseProgress

  const {
    section_uuid: sectionUuid,
    section_exe: sectionExe
  } = sectionData

  // get course base url
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const { PRACTICE_TERM_COMPLETE, CONCEPT_MAP_PROGRESS } = PROGRESS_TYPE

  const practiceTermComplete =
    Object.keys(studentProgress[PRACTICE_TERM_COMPLETE])
  const isPracticeTermComplete = practiceTermComplete.includes(sectionUuid)

  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`
  const sectionNumber = `Section ${chapterNo}.${sectionNo}`

  const chapterInfo = {
    chapterUuid,
    chapterNo,
    sectionNo
  }

  const getNextActivity = () => {
    const {
      quiz
    } = sectionExe
    if (quiz) return `${sectionNumber} Quizzes`
    if (practiceTermComplete) {
      const { nextChapterNo, nextSectionNo } =
          getNextAvailableSection(course, chapterInfo)

      return `Section ${nextChapterNo}.${nextSectionNo}`
    }
  }

  const getPracticeActivities = () => {
    const {
      conceptMap,
      practice_terms: practiceTerms
    } = sectionExe

    const activities = []

    if (conceptMap) {
      activities.push({
        type: CONCEPT_MAP,
        ...conceptMap
      })
    }

    if (practiceTerms) {
      activities.push({
        type: PRACTICE_TERMS,
        ...practiceTerms
      })
    }

    return activities
  }

  // practiceTerms - In progress
  let stateData = {
    description: `You viewed ${sectionNumber} Practice Terms.
     Continue to complete the activity.`,
    dataTestId: 'practiceTerms-in-progress',
    primaryBtnFunction: () => { window.location.href = sectionUrl },
    primaryBtnTxt: 'Continue'
  }

  // practiceTerms - Completed
  if (isPracticeTermComplete) {
    const { nextSectionUrl } = getNextAvailableSection(course, chapterInfo)
    stateData = {
      description: `You completed ${sectionNumber} Practice Terms.
     Continue to ${getNextActivity()}.`,
      dataTestId: 'practiceTerms-completed',
      primaryBtnFunction: () => { window.location.href = nextSectionUrl },
      primaryBtnTxt: 'Continue'
    }
  }

  // handle Practice parts
  if (conceptMap) {
    const practiceActivities = getPracticeActivities()

    if (isPracticeTermComplete) {
      const lastPracticeActivityNo = practiceActivities.length
      const { nextSectionUrl } = getNextAvailableSection(course, chapterInfo)
      stateData = {
        description: `You completed Part ${lastPracticeActivityNo}
         of ${sectionNumber} Practice. Continue to ${getNextActivity()}.`,
        dataTestId: 'practice-parts-completed',
        primaryBtnFunction: () => { window.location.href = nextSectionUrl },
        primaryBtnTxt: 'Continue'
      }
    } else {
      const conceptMapsProgress =
        Object.keys(studentProgress[CONCEPT_MAP_PROGRESS])
      const conceptMapIndex = practiceActivities.findIndex(
        ({ uuid }) => conceptMapsProgress.includes(uuid)
      )

      stateData = {
        description: `You viewed Part ${conceptMapIndex + 1}
         of ${sectionNumber} Practice. Continue to complete the activity.`,
        dataTestId: 'practice-parts-in-progress',
        primaryBtnFunction: () => { window.location.href = sectionUrl },
        primaryBtnTxt: 'Continue'
      }
    }
  }

  const { dataTestId } = stateData

  return (
    <div data-testid={dataTestId}>
      <WidgetState
        {...stateData}
      />
    </div>
  )
}

export default PracticeTermsCard
